export const cn_simp = {
    "name": "中文 (简体)",
    "flagCode": "cn",
    "locale": "zh-simp",
    "project": {
        "name": "地图"
    },
    "Sidebar": {
        "Setting": "设置",
        "Map": "地图",
        "Cue": "排程",
        "ImportBookings": "批量导入订单",
        "ImportDeliveryBookings": "批量汇出订单",
        "Trip": "行程",
        "New_booking": "新订单",
        "City": "城市",
        "Chauffeur": "约车出行",
        "Delivery": "送货",
        "Shuttle": "穿梭线路",
        "Intercity": "专线直达",
        "Customer": "客户",
        "Driver": "司机",
        "REPORTS": "报告",
        "Corporate": "企业及代理",
        "mDispatcher": "个人合作伙伴",
        "Reservation": "日历",
        "withdrawalRequest": "提款请求",
        "Driver_Settlement": "司机结算",
        "Dashboard": "仪表板",
        "SmartData": "智能数据",
        "Smart_Data": "智能数据",
        "Campaign_Builder": "活动创建",
        "Company_operator": "供应商操作员",
        "Queuing_area": "排队区",
        "Withdrawal_Requests": "提款请求",
        "pickUp": "出发地",
        "dropOff": "目的地",
        "SignUpNotifications": "注册通知",
        "Payout": "对外付账",
        "Payout_label": "对外付账",
        "payout": {
            "Pay_To_Driver": "支付司机",
            "Payout_History_For_Driver": "支付司机记录",
            "Pay_To_Merchant": "支付商户",
            "Payout_History_For_Merchant": "支付商户记录"
        },
        "number_of_records_to_export": "汇出备注数目",
        "export": "汇出",
        "Airline": "航空公司",
        "driverPayout": {
            "Pay_to_driver": "支付司机",
            "Payout_history": "支付记录"
        },
        "Reports": {
            "Driver_Referral_history": "司机推荐乘客",
            "Driver_refers_Pax": "司机",
            "Driver_refers_Pax_Customers": "客户",
            "Driver_refers_Pax_Bookings": "订单",
            "Driver_refers_customer_Drivers": "司机推荐乘客（司机）",
            "Driver_refers_customer_Customers": "司机推荐乘客（客户）",
            "Driver_refers_customer_Bookings": "司机推荐乘客（订单）",
            "Passenger_refers_Passengers": "乘客推荐乘客",
            "Driver_refers_drivers_Referrer": "司机推荐司机（推荐人）",
            "Deletion_request": "删除请求",
            "Driver_refers_drivers_Referee": "司机推荐司机（被荐人）",
            "Driver_refers_Driver": "司机推荐司机",
            "Driver_refers_driver_Referrer": "推荐人",
            "Driver_refers_driver_Referee": "被荐人",
            "Referral_history": "推荐记录",
            "Booking_details": "订单详情",
            "Financial": "财务",
            "Financial_driver": "司机",
            "Financial_fleet": "出行运营商",
            "Financial_company": "车队供应商",
            "Financial_Driver": "财务 (司机)",
            "Financial_Fleet": "财务 (出行运营商)",
            "Financial_Company": "财务 (车队供应商)",
            "Financial_fleet_profit": "运营利润",
            "Financial_Company_Tooltip": "根据车队供应商分组的司机净收入报告",
            "Financial_Driver_ToolTip": "每位司机的净收入报告",
            "Financial_Fleet_Profit_ToolTip": "出行运营商利润",
            "Driver_activity": "司机的活动",
            "Revenue_Summary": "收入概况",
            "Revenue_fleet": "出行运营商",
            "Revenue_driver": "司机",
            "Revenue_monthly": "月报表",
            "Revenue_Summary_Fleet": "收入摘要 (出行运营商)",
            "Revenue_Summary_Driver": "收入摘要 (司机)",
            "Revenue_Summary_Monthly": "收入摘要 (月报)",
            "Total_adjustment": "车费调整",
            "Promotion": "促銷",
            "Incident_Cancellation": "事故和取消",
            "Booking_logs": "订单日志",
            "Rating": "评分",
            "Thumbs_rating": "赞评分",
            "Stars_rating": "星级评分",
            "Operator_logs": "操作员日志",
            "Daily": "日在线报表",
            "Daily_Driver": "司机日在线",
            "Daily_Car": "车辆日在线",
            "Daily_Driver_Car": "车辆司机日在线",
            "mDispatcher": "个人合作伙伴",
            "Corporate": "企业",
            "Driver_login_status": "司机登陆状态",
            "Car_activity": "车辆活动",
            "Partner": "个人合作伙伴",
            "Prepaid_top_up": "企业预付",
            "Affiliation": "联盟",
            "Affiliation_penalty_compensation": "罚款及补偿",
            "Affiliation_payout_history": "结算历史",
            "Affiliation_bookings": "联盟订单",
            "Booking_summary": "订单摘要",
            "Cash_Wallet": "现金钱包",
            "Credit_Wallet": "信用钱包",
            "Driver_wallet": "司机钱包",
            "Driver_Deposit": "司机存入",
            "Driver_cash_wallet": "现金钱包",
            "Driver_Deposit_old": "司机存入",
            "Document_expiry": "文件到期",
            "Settlement": "结算",
            "Unapproved_Driver": "未批准的司机",
            "Approved_Driver": "已批准的司机",
            "Pay_to_driver": "支付给司机",
            "Settlement_history": "结算历史",
            "Withdrawal_History": "司机提现",
            "creditWalletTooltip": "司机存款到信用钱包",
            "Incomplete_payment": "不完整付款",
            "Driver_withdrawal": "司机提现",
            "Incident_&_Cancellation": "事故和取消",
            "Passenger_wallet": "乘客钱包",
            "Accept_Cancel_rate": "司机表现",
            "Acceptance/Cancellation_rate": "司机表现",
            "driverDepositTooltip": "司机存款到信用钱包",
            "Customer_quest": "任务",
            "First_will_win": "先完成为胜",
            "Largest_will_win": "最大为胜",
            "redeemed": "兑换",
            "Code_#": "编码",
            "Quests": "任务",
            "Merchant_Report": "商户",
            "Merchant_Financial_Report": "财务",
            "Merchant_Cash_Report": "现金钱包",
            "Merchant_Credit_Report": "信用钱包",
            "Deletion_Request": "删除请求",
            "Driver_Rating": "客户评价司机",
            "Passenger_Rating": "司机评价客户",
            "Company_settlement": "供应商结算",
            "Export": "下载历史",
            "Third_Party_Booking": "API订单",
            "BookingDotCom": "Booking.com",
            "Third_Party_Booking_Bookingcom": "API订单(Booking.com)",
            "Third_Party_Booking_HolidayTaxis": "Third Party Booking (HolidayTaxis)",
            "Third_Party_Booking_Karhoo": "Third Party Booking (Karhoo)",
            "Third_Party_Booking_Hoppa": "Third Party Booking (Hoppa)"
        },
        "Settings": {
            "Fleet_info": "运营商信息",
            "Stripe_connect": "Stripe Connect",
            "stripe_title_connect": "启用您的 Stripe Connect 帐户以自动接收付款",
            "Payment_info": "账单信息",
            "Permission": "权限",
            "Driver_refer_driver": "司机推荐司机",
            "Show_referal_history": "司机App显示隐藏推荐历史",
            "companyTooltip": "车队供应商",
            "User": "用户",
            "General": "一般",
            "Regular": "标准计费",
            "Sharing": "街头拼车",
            "Flat": "固定计费",
            "Hourly": "按时计费(包车)",
            "Company": "车队供应商",
            "Dispatch": "调度",
            "Voice_SMS": "语音和短信",
            "Rate": "费率设置",
            "Car": "车辆",
            "Car_type": "车型",
            "Car_mgmt": "车辆管理",
            "Car_Make": "车厂和型号",
            "Stripe_Connect": "STRIPE CONNECT",
            "Voice_&_SMS": "语音和短信",
            "Street-sharing_rate": "街头拼车计费",
            "Partner_type": "个人合作伙伴类型",
            "Shift_template": "排班模板",
            "Driver_App": "司机App",
            "Shift_settings": "排班设置",
            "Operation": "运营",
            "Driver_Note": "司机备注",
            "Promotion_code": "促销代码",
            "Point_config": "点数配置",
            "Code_#": "编码",
            "Voucher_code": "代金券",
            "Campaign": "推广活动",
            "Queuing_area": "排队区域",
            "Referral_code": "推荐码",
            "City": "城市",
            "category": "类别",
            "3rd_party_location": "第三方地址",
            "Zone": "区域围栏",
            "App_banner": "App横幅",
            "App_banner_pegasus": "App横幅",
            "Flight_integration": "航班信息对接",
            "SMS_integration": "SMS对接",
            "Invoice_Setting": "账单",
            "Driver_refers_customer": "司机推荐乘客",
            "Pax_refers_Pax": "乘客推荐乘客",
            "Customer_refers_customer": "客户推荐客户",
            "Dynamic_Surcharge": "动态附加费",
            "Dynamic_Fare": "动态车费",
            "FlatZone": "固定计费围栏",
            "IntercityZone": "专线",
            "Intercity_Zone": "专线围栏",
            "Intercity_Rate": "专线车费",
            "Car_Color": "车辆颜色",
            "third_party_integration": "第三方对接",
            "bookingCom": "Booking.com",
            "HolidayTaxis": "HolidayTaxis",
            "Karhoo": "Karhoo",
            "Corporate": "Corporate",
            "KarhooIntegration": "Karhoo Integration",
            "holidaytaxis": "HolidayTaxis",
            "karhoo": "Karhoo",
            "hoppa": "Hoppa",
            "Hoppa": "Hoppa",
            "HoppaIntegration": "Hoppa Integration",
            "Third_Party_Integration_Hoppa": "3rd party Integration (Hoppa.com)",
            "titleHoppa": "Hoppa API Credentials",
            "noteHoppa": "Communicate with Hoppa’s technical team to provide you with client, and secret key to connect to their system.",
            "titleHoppaWebhook": "Webhook Configuration",
            "noteHoppaWebhook": "Provide the parameters below for Hoppa to configure. These credentials are used to communicate between both platforms. For any issues please contact your tech provider.",
            "Third_Party_Integration_Booking": "第三方对接(Booking.com)",
            "Third_Party_Integration_HolidayTaxis": "3rd party Integration (HolidayTaxis)",
            "Third_Party_Integration_Karhoo": "第三方对接(Karhoo.com)",
            "Third_Party_Integration_Mozio": "第三方对接(Mozio.com)",
            "invoiceBranding": "账单品牌",
            "titleInvoice": "自定义页眉和页脚信息",
            "titleheader": "页眉标题",
            "descriptionHeader": "页眉说明",
            "placehodelHeader": "例如，添加公司详细信息，如电子邮件、电话号码、地址、税号等。",
            "titleFooter": "页脚标题",
            "placehodelFooter": "例如，添加银行电汇信息或任何政策等",
            "descriptionFooter": "页脚说明",
            "titleBookingCom": "Booking.com API 凭证",
            "noteBookingCom": "与 booking.com 的技术团队沟通，为您提供客户端以及连接到他们系统的密钥。",
            "clientKey": "客户端密钥",
            "placeHolderClientKey": "输入客户端密钥",
            "secretKey": "密钥",
            "placeHolderSecretKey": "输入客户端密码",
            "titleBookingComWebhook": "Webhook 配置",
            "noteBookingComWebhook": "提供以下参数供 booking.com 进行配置。 这些凭据用于在两个运营商之间进行通信。 如有任何问题，请联系您的技术提供商。",
            "webhook": "运营商Webhook URL",
            "clientId": "运营商客户端密钥",
            "clientSecret": "运营商秘钥",
            "driver_app": "司机App",
            "Jobs_tab": "工作标签",
            "Email_config": "电邮配置",
            "Driver_fields": "司机字段",
            "Driver_document": "司机文件",
            "Selected": "Selected",
            "API_Management": "API管理"
        }
    },
    "APIKeySetting": {
        "APIKey": "API键",
        "APIKey_title": "不要与他人共享您的 API 密钥，也不要在浏览器或其他客户端代码中公开它。 为了保护您的帐户安全，我们还可能会自动禁用发现已公开泄露的任何 API 密钥。",
        "newKeyBtn": "创建新密钥",
        "updateApplication": "更新应用程序",
        "RegisterApp": "注册一个新的应用程序",
        "RegisterBtn": "注册申请",
        "appDescription": "应用说明",
        "appName": "应用程序名称",
        "clientKey": "客户端密钥",
        "secretKey": "密钥",
        "hostedEndPoint": "托管端点",
        "maxEndpoints": "您最多可以有 5 个端点",
        "addEndpoint": "添加端点",
        "updateEndpoint": "更新端点",
        "ManageWebhooks": "管理Webhook",
        "CreateWebhook": "创建Webhook",
        "subscriptionID": "订阅ID",
        "webhookURL": "Webhook URL",
        "signingSecret": "签名秘钥",
        "eventsToSend": "要发送的事件",
        "listeningFor": "事件聆听",
        "assignedDriver": "已安排司机",
        "bookingStatus": "预订状态",
        "driverLocation": "司机位置",
        "titleSigningSecret": "这个密钥是由你的合作伙伴提供的。 如果由于任何原因密钥已被泄露，您可以更新机密或删除 Webhook。",
        "notUsingHTTPS": "Webhook URL 未使用 HTTPS。",
        "invalidURL": "Webhook URL 无效。",
        "clientType": "Client Type",
        "individual": "Individual",
        "corporate": "Corporate",
        "signingSecretLength": "Signing secret must be at least 30 characters long.",
        "bookingCreated": "Booking Created",
        "partner": "Partner",
        "fleetManager": "Fleet Manager"
    },
    "messages": {
        "credits": {
            "406": "操作失败",
            "407": "操作失败",
            "416": "优惠券已被使用",
            "417": "您的帐户已失效， 请联系我们的管理员",
            "418": "付款时出现错误",
            "419": "促销代码无效！",
            "420": "此促销代码已应用于另一次行程，请使用其他码。",
            "421": "卡号无效。",
            "422": "过期日期无效。",
            "429": "邮政编码无效。",
            "430": "验证邮政编码不匹配",
            "431": "不支持邮政编码验证",
            "432": "CVV 无效。",
            "433": "CVV 不匹配",
            "434": "CVV 未验证",
            "435": "CVV发行人不参与",
            "436": "您的信用卡遭到拒绝",
            "437": "信用卡验证失败， 请检查您的信息并重试。",
            "439": "CVV 未处理",
            "441": "发行人无 CVV 数据",
            "443": "没有来自发行人/银行网络的数据",
            "445": "AVS系统无法处理",
            "446": "发卡银行不支持AVS",
            "447": "AVS不支持您的业务",
            "448": "由于多次尝试付款失败，该卡已被冻结，请更改您的付款方式或稍后重试",
            "452": "您的卡被拒绝， 请输入与您的电话号码在同一地区注册的另一张信用卡",
            "453": "不支持此支付网关",
            "454": "AVS 地址不匹配",
            "455": "AVS 邮政编码和地址不匹配",
            "458": "认证失败",
            "459": "银行卡不支持",
            "461": "不支持此卡",
            "462": "持卡人姓名无效",
            "463": "验证码无效",
            "464": "本行不支持生成和发送验证码",
            "470": "使用信用卡进行预订之前，请输入旅客的电子邮件地址",
            "474": "使用信用卡预订前请输入旅客姓名",
            "475": "电话号码为必填项",
            "524": "资金不足，该账户没有足够的资金来支付交易金额。",
            "911": "我们无法验证您的付款方式，请选择其他付款方式并重试。",
            "2000": "不兑现",
            "2001": "资金不足，该账户没有足够的资金来支付交易金额。",
            "2002": "超出限制",
            "2003": "持卡人活动超出限制",
            "2004": "过期卡",
            "2005": "无效的信用卡号码",
            "2006": "无效的到期日期",
            "2007": "没有账号",
            "2008": "卡帐号长度错误",
            "2009": "无此类发行人",
            "2010": "发卡机构拒绝 CVV",
            "2011": "需要语音授权，持卡人银行要求商户致电获取特殊授权码以完成本次交易",
            "2012": "已拒绝 - 可能丢失的卡",
            "2013": "已拒绝 - 可能被盗的卡",
            "2014": "被拒绝 - 涉嫌欺诈",
            "2015": "不允许交易",
            "2016": "重复交易",
            "2017": "持卡人停止付费",
            "2018": "持卡人停止所有付费",
            "2019": "无效交易",
            "2020": "违反",
            "2021": "违反安全规定",
            "2022": "已拒绝 - 可更新持卡人",
            "2023": "不支持此功能",
            "2024": "信用卡类型未启用",
            "2025": "设置错误 - 商户",
            "2026": "商户ID无效",
            "2027": "设置错误 - 金额",
            "2028": "设置错误 - 层次结构",
            "2029": "设置错误 - 卡",
            "2030": "设置错误 - 终端",
            "2031": "加密错误",
            "2032": "不允许附加费",
            "2033": "数据不一致",
            "2034": "不采取操作",
            "2035": "在III组版本批准部分金额",
            "2036": "找不到撤销授权",
            "2037": "已经撤销",
            "2038": "已拒绝，客户的银行不愿意接受该交易，需要联系其银行了解更多详情。",
            "2039": "授权码无效",
            "2040": "无效商店",
            "2041": "已拒绝 - 请致电要求批准",
            "2042": "客户端 ID 无效",
            "2043": "错误 - 请勿重试，请致电发卡机构",
            "2044": "拒绝 - 致电发卡人",
            "2045": "商户号码无效",
            "2046": "已拒绝，客户的银行不愿意接受该交易，需要联系其银行了解更多详情。",
            "2047": "致电发卡机构。此错误表示该卡已被持卡人报告丢失或被盗。",
            "2048": "无效金额",
            "2049": "SKU 编号无效",
            "2050": "无效信用计划",
            "2051": "信用卡号码与付款方式不符",
            "2052": "无效的III级购买",
            "2053": "信用卡报告丢失或被盗",
            "2054": "撤销金额与授权金额不符",
            "2055": "交易分部编号无效",
            "2056": "交易金额超过交易分割限额",
            "2057": "发卡机构或持卡人已设置限制",
            "2058": "商户未启用 MasterCard SecureCode。",
            "2060": "地址验证和卡安全码失败",
            "2061": "无效交易数据",
            "2062": "税额无效",
            "2064": "货币代码无效",
            "2078": "无效的安全支付数据",
            "2080": "用户凭证无效",
            "3000": "网关网络不可用 - 再试一次",
            "4001": "结算已拒绝",
            "4006": "交易金额超过允许限度",
            "81703": "该商户账户不接受信用卡类型。",
            "81706": "需要提供CVV",
            "81707": "CVV 必须是 3 或 4 位数字。",
            "81709": "需要提供到期日期。",
            "81710": "到期日期无效。",
            "81714": "需要信用卡号码。",
            "81715": "信用卡号码无效。",
            "81716": "信用卡号码必须为 12-19 位数字。",
            "81717": "信用卡号不是可接受的测试号。",
            "81718": "信用卡号码关联到某些预订，不能更新为不支持的信用卡类型。",
            "81723": "持卡人姓名太长。 最多 175 个字符。",
            "81809": "邮政编码不得超过 9 个字母或数字字符",
            "81813": "邮政编码只能包含字母、数字、空格和连字符",
            "91722": "需要支付方式令牌。",
            "91723": "更新现有令牌无效",
            "91730": "此商家帐户不支持验证。",
            "91734": "该商户账户不接受信用卡类型。",
            "91738": "指定的付款方式不是信用卡。",
            "91745": "信用卡更新参数无效。",
            "91826": "邮政编码无效，邮政编码必须是 5 或 9 位数字的字符串，可以选择用破折号或空格分隔。",
            "-1": "该客户帐户已被停用，请联系我们的管理员。"
        },
        "reBookingMsg": {
            "303": "您选择的时间必须晚于当前时间！",
            "304": "未找到此订单",
            "500": "某些订单信息无效或缺失，请检查并重试！"
        },
        "promoMsg": {
            "304": "促销代码无效",
            "306": "促销代码无效，这个促销活动可能适用于其他区域。",
            "413": "抱歉，此促销代码目前不可用",
            "414": "促销代码无效",
            "415": "促销代码无效",
            "416": "该促销代码已被使用。",
            "419": "抱歉，此促销代码目前不可用",
            "420": "抱歉，您已达到促销用途限制",
            "421": "抱歉，此促销代码已达到其发布的使用限制",
            "422": "此促销代码无法在您的帐户下使用",
            "423": "抱歉，您已达到有限的促销预算",
            "424": "促销代码无效，这个促销活动只适用于推荐客户",
            "425": "此促销代码已达到每天的使用限制。",
            "426": "您已达到每天的促销使用次数限制。",
            "427": "无法应用促销代码，该客户的个人资料不完整。",
            "428": "该客户已用完每月促销代码。",
            "430": "您已用完年度促销代码。",
            "431": "促销代码不能用于此付款方式。",
            "432": "此票价金额不符合使用促销代码的条件。",
            "433": "促销代码无法在选定时间使用。",
            "434": "抱歉，您已达到有限的促销预算。",
            "phoneInvalid": "请在应用促销代码之前输入乘客的电话号码"
        },
        "updateBookingMsg": {
            "303": "无法更新订单，请再试一次！",
            "304": "未找到此订单",
            "pickUpTime": "您选择的时间必须晚于当前时间！",
            "pickup": "该地区尚未开通服务",
            "destination": "某些订单信息无效或缺失，请检查并重试！",
            "dispatchType": "某些订单信息无效或缺失，请检查并重试！"
        },
        "sessionExpired": {
            "403": "您已注销，因为您的帐户已从其他设备登录或您的会话已过期"
        },
        "not_found": {
            "404": "您请求的页面无法找到"
        },
        "booking": {
            "303": "您选择的时间必须晚于当前时间！",
            "304": "未找到此订单",
            "305": "Booking.com 取消或完成订单； 请拒绝该订单以将其从排程中删除。",
            "306": "没有为所选车型套用车费，要对车型套用车费，请转至设置 > 车辆 > 车型。",
            "307": "Can not update booking. Please try again later.",
            "407": "额外目的地车费类型",
            "500": "某些订单信息无效或缺失，请检查并重试！",
            "upgroup_success": "订单已成功取消合组",
            "Please_Select_driver": "请选择司机",
            "assign_driver_vehicle_success": "Driver/Vehicle has been assigned successfully",
            "assign_driver_success": "成功安排司机",
            "detach_driver_success": "Driver has been detached successfully",
            "detach_vehicle_success": "Vehicle has been detached successfully",
            "Group_success": "订单已成功合组",
            "change_supplier_success": "Booking has been changed suppliers successfully",
            "change_supplier_partial": "Partial Success: Batch assignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
            "detach_driver_partial": "Partial Success: Batch assignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
            "assign_driver_partial": "Partial Success: Batch assignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
            "update_dispatch": "Update & Dispatch",
            "remove_booking_false": "删除订单失败",
            "remove_booking_success": "订单已成功删除",
            "GROUP_NOT_FOUND": "找不到组 ID",
            "NO_SHARING_BOOKINGS": "仅当客户的订单允许拼车时，您才可以进行对订单合组。",
            "DIFFERENT_CAR_TYPES": "您尝试合组的订单有不同的车型",
            "LIMIT_EXCEEDED_ON_SEATS_OR_LUGGAGE": "Booking cannot be grouped: Seat or luggage limit exceeded.",
            "INVALID_CAR_TYPE": "无法安排司机、检查司机的车辆座位和行李容量。",
            "VALIDATION_ERROR": "验证错误",
            "INTERNAL": "内部错误",
            "fare_gt_zero": "车费必须大于0！",
            "edit_total_fail": "修改总费用失败！",
            "complete_fail": "无法完成订单！",
            "cancle_fail": "取消此订单时出了点问题，请再试一次！",
            "incident_fail": "处理事故失败",
            "phone_invalid": "电话号码无效！",
            "add_psg_fail": "无法添加新乘客",
            "REMOVE_RECIPIENT_DELIVERED": "无法删除已送达的收件人",
            "REMOVE_LAST_ACTIVE_RECIPIENT": "无法删除最后一个生效收件人",
            "ORDER_HAS_BEEN_FINISHED": "订单已经完成",
            "end_booking_leave": "离开此页面将结束您的订单。",
            "update_booking_fail": "无法更新订单，请再试一次！",
            "EXTERNAL_ID_EXISTED": "External ID already exist on the system.",
            "area_service_unavailable": "该地区尚未开通服务",
            "pickupSameDestination": "出发地址必须与目的地不同。",
            "time_invalid": "您选择的时间必须晚于当前时间！",
            "psg_inactivate": "该用户已被停用，请联系我们的管理员",
            "data_incorect": "出了些问题，请稍后再试。",
            "preAuthorize": "请检查您的帐户，因为需要 {0} 的金额来保证您的订单",
            "booking_limited": "抱歉，您已达到每分钟/每天的最大订单数量，请稍后再试。",
            "minimumBookAhead": "预订接载时间必须距当前时间至少 {0} 小时 {1} 分钟",
            "exDoDiffDo": "额外目的地必须与目的地不同。",
            "recurringTimeRange": "日期超出范围，您只能提前 {0} 个月创建预订，如有任何问题请联系管理员。",
            "recipientsLimit": "最大收件人数量为{0}。",
            "maximumBookAhead": "预订出发时间不能迟于当前时间 {0} 天",
            "Please_select_or_add_a_card": "请选择或添加卡！",
            "Unable_to_process_by_fare_zero": "我们无法处理您的请求，请更改车型或订单类型，然后重试。 如果您需要任何帮助，请联系我们的客服",
            "Can_not_connect_to_server": "无法连接到服务器！",
            "Check_connection_or_contact_system_support": "请检查您的连接或联系客服寻求帮助！",
            "Create_new_booking_success": "已成功创建新订单！",
            "Create_new_recurring_booking_success": "已成功创建新订单！",
            "Update_booking_success": "已成功更新该订单！",
            "Booking_drop_of_success": "此订单已成功送达",
            "Cancel_booking_success": "已成功取消该订单！",
            "Booking_detail_not_found": "未找到订单详情！",
            "Check_promo_error": "检查促销代码错误！",
            "Invalid_format": "无效的格式",
            "Flight_not_found": "未找到航班！",
            "Add_new_credit_error": "添加新的信用卡出错！",
            "Socket_server_disconnected": "SOCKET服务器已断开连接！",
            "Please_reload_page_or_check_your_connection": "抱歉，无法处理您的请求，请再试一次。",
            "request_timeout": "抱歉，您的请求超时，请再试一次。",
            "Check_customer_error": "检查客户错误！",
            "Please_select_car_type": "请选择车型",
            "DropOff_country": "抱歉，您所请求的目的地不在此车型允许范围内！",
            "Different_Country": "接送地点必须位于 2 个不同的国家/地区。",
            "Please_enter_destination_for_ridesharing_booking": "请输入拼车订单目的地",
            "Extra_detination_must_be_difference_from_destination": "额外目的地必须与当前目的地不同",
            "Pickup_address_must_be_different_from_destination": "接客地址必须与目的地不同",
            "Best_price": "最佳票价",
            "You_may_be_charged_extra_fee_if_your_trip_excceds": "如果您的行程超过，您可能需要支付额外费用",
            "You_may_be_charged_extra_fee_if_your_trip_excceds_with_params": "如果您的行程超过 {0} {1}、{2} {3}，您可能需要支付额外费用",
            "Reject_booking_success": "此订单已被成功拒绝！",
            "Reject_fail": "拒绝此订单时出了点问题，请再试一次！",
            "Please_select_payment_method": "请选择付款方式",
            "Driver_signed_out_or_deactivated": "该司机刚刚注销或已停用",
            "reservation_booking_overlaps": "抱歉，此订单与司机已接受的另一订单重叠。",
            "driver_is_not_available": "抱歉，该司机目前不可用。",
            "seats_luggage_limit": "无法将订单分派给司机。 座位或行李超出限制。",
            "full_capacity": "该订单无法分派给司机，因为订单已达到最大载客量。",
            "marked_failed_ok": "该订单已被标记为失败。",
            "warning_markFail_lastRecipient": "这是该订单的最后一位接收者。 如果您继续将此订单标记为失败，则您将需要完成此订单。",
            "BOOKINGS_LIMIT_EXCEEDED": "每组最多只能分配 5 个订单",
            "bookingCanceled": "Booking.com 取消或完成订单； 请拒绝该订单以将其从排程中删除。"
        },
        "item_no_found": "没有找到任何项目",
        "commonMessages": {
            "greater_than_0": "请输入一个大于 0 的值。",
            "Required_field": "这是必填栏",
            "bankPhone_is_required": "电话号码无效",
            "bankPhone": "电话号码",
            "distance_from_equa_or_smaller": "距离“从”必须小于或等于“到”的距离。",
            "must_be_number": "请输入有效号码",
            "equal_or_greater_than_0": "请输入一个等于或大于 0 的值。",
            "must_be_integer": "请输入整数",
            "invalid_email_format": "请输入有效的电邮。",
            "greater_or_equa": "请输入一个大于或等于 {0} 的值。",
            "value_must_between": "请输入一个介于 {0} 和 {1} 之间的值。",
            "large_10x_markup_price": "修改后的价格不得超过预计价格的 10 倍。",
            "greater_than": "请输入一个大于 {0} 的值。",
            "less_than": "请输入一个小于 {0} 的值。",
            "less_or_equal": "请输入一个小于或等于 {0} 的值。",
            "driver_earnings_warning": "线下支付：司机收入不得超过收取的车费。",
            "limit_amount": "请输入小于或等于限制金额的值",
            "Integer_from_range": "请从{0} - {1}输入整数",
            "integer_greater_than_0": "请输入大于0的整数，（小于100，百分号和小数点分隔符为1个字符）",
            "Error_message": "错误！",
            "callcenter_newbooking_message": "通过电话<span class='bold'>{0}</span>建立新订单",
            "callcenter_existed_booking_message": "号码 <span class='bold'>{1}</span> 存在一项有效订单 <span class='bold'>#{0}</span>",
            "callcenter_close_newbooking_form_warning": "请在收到新客户请求之前关闭/完成您当前的订单",
            "Emergency_SOS_Title": "紧急SOS",
            "Please_enter_a_future_date": "请输入未来的日期",
            "Card_holder_is_required": "需持卡人",
            "Card_number_is_required": "卡号为必填项",
            "Expired_date_is_required": "到期日为必填项",
            "Credit_code_is_required": "请输入信用代码（最后一次验证）",
            "Expired_date_is_invalid": "到期日期无效。 请以 mm/yy 格式输入到期日期",
            "cvv_is_required": "需要提供CVV",
            "City_is_required": "城市为必填项",
            "State_is_required": "州/省为必填项",
            "Address_is_required": "地址为必填项",
            "Zipcode_is_required": "邮政编码为必填项",
            "Country_is_required": "国家/地区为必填项",
            "Over_query_limit": "地图服务错误，暂时无法加载。 请稍后再试。",
            "outOfInputQueueOrder": "订单数不能大于队列中司机的总数。",
            "Same_pickup_destination": "接载地址必须与目的地地址不同。",
            "Max_length": "最大长度为 {0} 个字符。",
            "choose_at_least_one": "请至少选择一个选项。",
            "choose_at_least_one_intercity_schedule": "请添加时间表或允许客户请求他们的首选时间。",
            "startDate_greater_than_endDate": "开始日期大于结束日期"
        },
        "trip": {
            "cancel_trip_success": "此订单已成功取消！",
            "data_incorect": "尚未指派任何司机， 从下拉列表中选择一个司机。 <br/> 如果没有可用的司机，请选择“自动调度”以稍后分配。",
            "INTERNAL": "内部错误。",
            "NOT_FOUND": "未找到行程或状态无效。",
            "SAME_DRIVER": "被派人必须与当前司机不同。",
            "OVERLAP": "抱歉，此订单与该司机接受的另一行程重叠。",
            "CANNOT_ASSIGN": "该司机无效（如司机未找到、未在线或未登录）。"
        },
        "car_mgmt": {
            "Search_car_color": "搜索车辆颜色",
            "Searching": "搜寻中"
        },
        "driver_note": {
            "Search_driver_note": "搜索司机备注",
            "Searching": "搜寻中"
        }
    },
    "errors": {
        "303": "找不到用户",
        "453": "网关不支持",
        "553": "抱歉， 客户订单进行中，请稍后重试。",
        "1007": "The period between the start date and the end date must be less than 60 days!!",
        "3001": "电话号码已存在",
        "3002": "出生日期无效",
        "3003": "到期日期无效",
        "3004": "找不到司机",
        "3005": "缺少司机结算信息（银行地址、城市、州、邮政编码）",
        "3006": "缺少银行账户信息（账户名称、账户类型、路由号码、账户号码、ssn）",
        "3007": "您无法编辑该司机，因为他正在工作。",
        "3008": "该司机已经登录。请让他登出以编辑他的信息。",
        "3009": "该司机已接受一项或多项预订。 在更新/更改司机信息之前，请先将订单分配给其他司机。",
        "3010": "连接调度失败。",
        "3011": "该司机在启用之前必须进行操作设置。",
        "3012": "该司机在启用之前必须进行操作设置。",
        "3013": "只能删除处于停用状态的项目。",
        "3014": "缺少必填字段。 请检查。",
        "3015": "出生年份无效。 请检查。",
        "3016": "无效的邮政编码。 请检查。",
        "3017": "无效的州/省。 请检查。",
        "3018": "帐户名无效。 请检查。",
        "3019": "路由号码无效。 请检查。",
        "3020": "需要生日。 请检查。",
        "3021": "ssn 号码无效。 请检查。",
        "3022": "司机的银行账户无法收到资金。",
        "3023": "登录的司机无法操作，请稍后再试。",
        "3026": "司机已不复存在。",
        "3027": "司机的银行账户未设立。 请前往司机模块设置他的银行账户。",
        "3028": "未能支付所选司机的费用。 请再试一次。",
        "3029": "清除司机收入数据失败，请再试一次。",
        "3032": "该用户名已存在于我们的系统中，请输入另一项。",
        "3033": "BSB 无效。 该号码应为 6 位数字，格式为 xxxxxx。",
        "3039": "需要组 ID。",
        "3040": "该电话号码已被 %{brandName} 司机使用（即电话号码不能重复）。 请输入另一号码",
        "3041": "用户名已存在。",
        "3044": "国民身份证重复。",
        "3045": "Driver already has a vehicle, Please refresh page",
        "3046": "The company of the vehicle and the company of the driver are different.",
        "4001": "您的车牌号已在系统中存在。 请输入另一项。",
        "4002": "到期日期无效",
        "4003": "删除前必须停用车辆",
        "4004": "未找到车辆",
        "4005": "这辆车已经停用",
        "4006": "该车正在使用中，无法删除或停用",
        "4007": "车牌号为必填项",
        "4009": "The vehicle is being assigned to a booking.",
        "4010": "The vehicle is being assigned to a driver.",
        "5001": "供应商已存在",
        "5004": "未找到供应商",
        "6001": "车型已存在",
        "6004": "未找到车辆型号",
        "6005": "车型正在使用中",
        "7001": "您的车辆品牌名称已存在于系统中。 请输入另一项。",
        "7004": "找不到车辆品牌",
        "8001": "车辆类型已存在",
        "8004": "未找到车辆类型",
        "9001": "固定计费路线存在",
        "9004": "未找到固定计费路线",
        "10001": "区域围栏已存在",
        "10004": "未找到区域围栏",
        "11001": "区域围栏已存在",
        "11002": "该区域与另一区域重叠。 请再画一次。",
        "11003": "区域围栏必须是多边形对象",
        "11004": "未找到区域围栏",
        "11005": "区域围栏正在使用中",
        "12001": "收件箱已存在",
        "12002": "请配置 SMS Twilio",
        "12004": "未找到收件箱",
        "12005": "没有与您的过滤器匹配的收件人。 请检查列表并重试。",
        "12006": "文件中不存在客户。",
        "13001": "该促销代码已存在于系统中。 请输入另一项",
        "13002": "无效日期",
        "13003": "私人",
        "13004": "未找到促销代码",
        "13005": "仅限新客户",
        "13006": "超过数量限制",
        "13007": "超出预算限额",
        "13008": "超过总使用限制",
        "13011": "此促销活动已停用或删除",
        "13012": "日期范围无效",
        "13013": "促销代码无效。 好像这个促销活动也适用于其他区域",
        "13014": "您选择的区域已被停用。",
        "14004": "未找到订单",
        "15002": "您的服务已停用，请联系我们的客服",
        "15004": "没有找到运营商",
        "16001": "您的固定计费价格包已存在于系统中，请输入另一项",
        "16004": "未找到价格包",
        "17001": "名称已存在",
        "17004": "未找到价格包",
        "18001": "名称已存在",
        "18002": "使用中的价格包",
        "18004": "未找到价格包",
        "19001": "您的价格包已在系统上存在，请输入另一项。",
        "19002": "使用中的价格包",
        "19004": "未找到价格包",
        "20001": "此用户名已存在于我们的系统上。请输入另一个。",
        "20002": "您的帐户停用。请联系我们的客服",
        "20003": "您输入的用户名或密码不正确。 请再试一次",
        "20004": "用户名未在系统中注册。",
        "20005": "该链接已过期",
        "20006": "当前密码不匹配。 请再试一次。",
        "20007": "用户#已存在",
        "20008": "This email already exists on our system. Please enter another one.",
        "21001": "您的权限名称已在系统中存在。 请输入另一项。",
        "21002": "无法停用此权限，因为它当前已套用给用户。",
        "21003": "角色启用",
        "21004": "未找到角色",
        "22001": "客户已存在",
        "22004": "未找到客户",
        "22005": "文件格式无效。",
        "24001": "电话号码无效",
        "25001": "该名称已存在于系统中。 请输入另一项。",
        "25002": "时间范围无效",
        "25004": "没有找到排班",
        "26001": "该名称已存在于系统中。 请输入另一项。",
        "26002": "此排班正在使用中，请在删除前取消套用",
        "26004": "找不到排班模板",
        "27001": "推广活动已存在",
        "27002": "推广活动已启动",
        "27004": "找不到推广活动",
        "28001": "城市已经存在",
        "28002": "城市正在启动",
        "28003": "城市正在使用中",
        "28004": "找不到城市",
        "29002": "电话号码已存在",
        "30001": "未找到排队区域",
        "30002": "使用中的排队区域",
        "30003": "您的排队区名称已在系统中存在。 请输入另一项",
        "30004": "排队区域不得相互重叠",
        "30005": "排队区坐标无效",
        "30006": "上车区域无效",
        "31001": "您的公司ID已存在于系统中。 请输入另一项。",
        "31002": "该用户名已存在于我们的系统中。 请输入另一项。",
        "31003": "未找到企业。",
        "31004": "未找到企业用户。",
        "31005": "只有停用的企业才能被删除。",
        "31006": "该电话属于某企业用户身上。请输入另一个电话。",
        "31007": "未找到企业乘客。",
        "31008": "未找到卡。",
        "31009": "该电话已存在于系统中。 请输入另一项。",
        "31010": "该企业已被停用， 请联系管理员获取更多信息",
        "31011": "此乘客不能被启用，因为它属于停用的企业帐户",
        "31013": "已存在同名企业",
        "32001": "您的服务名称已在系统中存在。 请输入另一项。",
        "32004": "无法提供服务",
        "33002": "时间范围不得相互重叠",
        "34001": "代码已存在",
        "34013": "日期范围无效",
        "36001": "名称已存在",
        "39001": "The document is required",
        "71001": "存在动态附加费",
        "71002": "该区域与另一区域重叠。 请再画一次。",
        "71003": "动态附加费区域必须是比运营区域小的多边形",
        "71004": "未找到动态附加费",
        "72001": "存在动态票价",
        "72002": "该区域与另一区域重叠。 请再画一次。",
        "72003": "动态票价必须是比运营区域小的多边形",
        "72004": "未找到动态票价",
        "74001": "区域已存在",
        "74002": "该区域与另一区域重叠。 请再画一次。",
        "74003": "区域必须是多边形",
        "74004": "未找到区域票价",
        "74005": "未找到区域票价",
        "80001": "App横幅已存在。",
        "80002": "找不到App横幅。",
        "80003": "只能删除处于停用状态的项目。",
        "81001": "文件上传为空。",
        "81002": "文件上传有大小限制。",
        "82002": "设置 Flightaware 时出现问题。 请稍后再试。",
        "90001": "专线区域围栏存在",
        "90002": "该区域与另一区域重叠。 请再画一次。",
        "90003": "专线必须是多边形围栏",
        "90004": "未找到专线区域围栏",
        "90005": "专线区域正在使用中",
        "91001": "已有专线价格包",
        "91002": "专线价格包正在使用中",
        "91003": "未找到专线价格包",
        "92001": "专线名称已存在",
        "92002": "未找到专线路线",
        "92003": "该专线路线已存在于其他路线中，请检查并重试",
        "92004": "这条专线路线上有一个生效的行程。",
        "93001": "系统中已存在司机备注。 请输入另一项。",
        "93002": "司机备注处于停用状态，可以删除。",
        "93003": "司机备注正在使用。",
        "93004": "未找到司机备注。",
        "94001": "您的车辆颜色名称已存在于系统中。 请输入另一项。",
        "94002": "未找到车辆颜色",
        "400000": "电话号码无效！",
        "400002": "A validation error has occurred.",
        "400003": "没有权限",
        "400005": "文件内容无效",
        "500000": "内部系统错误。",
        "500001": "请配置 SMS Twilio",
        "500002": "请求超时。 请稍后再试",
        "600001": "未找到位置",
        "600002": "该经度和纬度坐标已存在于系统中。 请添加另一个",
        "610001": "类别已存在",
        "610002": "未找到类别",
        "610003": "类别已存在于聚会地点",
        "700001": "类型正在使用中",
        "700002": "未找到个人伙伴",
        "700003": "您的个人伙伴类型名称已在系统中存在。 请输入另一项。",
        "800000": "该商店已分配给另一个商家帐户。 请选择另一项。",
        "800001": "商户的银行账户无法收到资金。",
        "800002": "商户的银行账户无法收到资金。",
        "undefined": "网络故障，请再试一次。",
        "completeBooking": {
            "113": "我们无法验证您的付款方式。 请选择其他付款方式并重试。",
            "450": "The balance is insufficient to pay. Please select another method",
            "451": "The balance is insufficient to pay. Please select another method",
            "521": "钱包没有足够的资金。"
        },
        "upload_file_fail": "文件无效，请检查并重试。",
        "upload_photo_fail": "文件无效，请检查并重试。",
        "missing_some_field": "字段无效，请检查并重试。"
    },
    "statusDisplay": {
        "supplierAccepted": "Supplier accepted",
        "delivery_supplierAccepted": "Supplier accepted",
        "completedWithoutService": "使用外部司机完成订单",
        "confirmed": "确认订单",
        "pending": "待办订单",
        "queue": "调度中",
        "offered": "调度中",
        "booked": "司机前往接载",
        "engaged": "行程中",
        "droppedOff": "送达",
        "pre": "订单已接受",
        "recurrent": "复发",
        "vacant": "",
        "vacantTime": "",
        "arrived": "抵达接载地",
        "completed": "已完成",
        "canceled": "取消",
        "noShow": "NOSHOW",
        "action": "等待处理",
        "incident": "事故",
        "pickupTimeExceeded": "超时",
        "canceledByCC": "控制中心取消",
        "canceledByPassenger": "乘客取消",
        "canceledByDriver": "被司机取消",
        "canceledByPartner": "已被合作伙伴取消",
        "canceledBymDispatcher": "已被合作伙伴取消",
        "canceledByCorporateAdmin": "由企业管理员取消",
        "canceledByAPI": "通过 API 取消",
        "canceledByWebBooking": "通过WEB预订取消",
        "canceledByTimeout": "超时取消",
        "accepted": "接受",
        "rejected": "拒绝",
        "canceledByCorpAd": "由企业管理员取消",
        "started": "开始",
        "intransit": "在途中",
        "empty": "空的",
        "notLive": "部分 - 非实时",
        "live": "部分 - 实时",
        "full": "完整",
        "delivering": "送货中",
        "delivery_booked": "司机正在前往发件人的路上",
        "delivery_arrived": "已到达并等待发件人",
        "shopForYou_booked": "司机在领取现金的路上",
        "shopForYou_arrived": "已抵达并等待领取现金",
        "collecting": "司机在取货途中",
        "confirmed_delivery": "已确认",
        "food_booked": "司机正在前往商家的路上",
        "food_collecting": "已到达并等待领取物品",
        "mart_collecting": "已到达并等待领取物品",
        "otwMerchant": "司机正在前往商家的路上",
        "arrivedAndWaitingToCollectItem": "已到达并等待领取物品",
        "canceledByAirlineAdmin": "被航空公司管理员取消",
        "partialCompleted": "部分完成",
        "failed": "失败的",
        "allocated": "已分配"
    },
    "tripTypes": {
        "scheduled": "预定行程",
        "requested": "请求行程",
        "preferred": "首选"
    },
    "login": {
        "Username": "用户名",
        "Password": "密码",
        "Remember_me": "记住账号",
        "Forgot_your_password": "忘记密码",
        "Login": "登录"
    },
    "invoice": {
        "createBtn": "创建账单",
        "searchPlInvoice": "搜索客户、联系人、发票号码",
        "searchPl": "搜索订单号、客户名称",
        "createInvoice": "创建账单",
        "invoicePeriod": "账单期限",
        "invoicePeriodNote": "您可以选择逐月的日期范围。",
        "viewEdit": "查看/编辑",
        "billingContact": "帐单联系人",
        "memo": "备忘录",
        "memoMessage": "给客户留言",
        "dueDate": "到期日",
        "updateInvoice": "更新账单",
        "chargeCustomer": "向客户收费",
        "invoiceNumber": "账单号码",
        "noItem": "项目",
        "issuedDate": "发布日期",
        "refId": "参考号",
        "pickupTime": "接载时间",
        "pickup": "接载地",
        "destination": "目的地",
        "customerName": "乘客姓名",
        "totalAmount": "Amount Due",
        "amountDue": "应付金额",
        "sendInvoice": "发送账单",
        "copyInvoice": "复制账单",
        "downloadPDF": "下载 PDF 格式",
        "paymentLink": "付款链接",
        "deleteInvoice": "删除账单",
        "onlySave": "保存账单",
        "saveSend": "保存并发送账单",
        "sendEmail": "发电子邮件",
        "addRecipients": "添加收件人",
        "editInvoice": "编辑账单",
        "check": "检查",
        "wire": "电汇",
        "digitalWallet": "数字钱包",
        "cash": "现金",
        "completedTime": "完成时间",
        "Status": "状态",
        "bookingStatus": "订单状态",
        "chargeBtn": "收费{0}",
        "pastDue": "逾期",
        "payCompleted": "此账单已支付成功！",
        "Delete": "删除账单？",
        "Delete_content": "此操作将永久删除该账单， 如有必要，您可以为这些订单创建一个新账单。",
        "viewInvoice": "查看账单",
        "pending": "待付款",
        "paid": "已付款",
        "CopyLink": "复制链接"
    },
    "cue": {
        "jobVisibility": "Job Visibility",
        "jobPubic": "Public",
        "jobPrivate": "Private",
        "jobSetPubic": "Set Public",
        "jobSetPrivate": "Set Private",
        "supplierAccepted": "Supplier accepted",
        "creationTime": "创建时间",
        "Pax": "人数#",
        "Luggage": "行李",
        "allocated": "已分配",
        "completedWithoutService": "使用外部司机完成订单",
        "shuttle": "穿梭",
        "farmIn": "接收外单",
        "chargeCode": "收费代码",
        "clientCaseMatter": "行程说明",
        "operatorNote": "内部备注",
        "farmOut": "外发订单",
        "roaming": "漫游",
        "FlightUpdate": "航班更新",
        "networkType": "网络类型",
        "InHouse": "本地订单",
        "carHailing": "叫车",
        "uploadBookings": "上传预订",
        "moreActions": "更多操作",
        "rideSharing": "拼车",
        "Rearrange": "重新整理",
        "GroupId": "群组 ID#",
        "Active": "进行中",
        "Finished": "已完成",
        "Home_affiliate": "归属/联盟",
        "Booking_type": "订单类型",
        "From": "从",
        "balanceWallet": "{0}：剩余 {1}",
        "partialWallet": "使用电子钱包支付部分费用",
        "To": "至",
        "Status": "状态",
        "Migration_Status": "迁移状态",
        "Migrated": "已迁移",
        "Select_Status": "选择状态",
        "Operator": "操作员",
        "Car_types": "车型",
        "Service_class": "Service class",
        "Booking_from": "预订自",
        "All": "全部",
        "Pending": "待办",
        "Dispatching": "调度中",
        "Waiting_for_process": "等待处理",
        "Confirmed_reservation": "确认订单",
        "Driver_on_the_way": "司机前往接载",
        "Arrived_and_waiting": "抵达接载地",
        "Passenger_on_board": "行程中",
        "Dropped_off": "送达",
        "combobox_select": "选择...",
        "Search_here": "在这里搜索",
        "Bookingid": "订单#",
        "Pickup_Drop_Off": "接载/送达",
        "Pickup_Drop_Off_Crew": "接送时间",
        "Pickup_Drop_Off_Local_Time": "操作时间",
        "Pickup_Location_Destination": "接送地点/目的地",
        "Passenger": "乘客",
        "Intercity_Route": "专线",
        "Airport_Pickup": "机场接机",
        "Airport_Pickup_Crew": "机场接机",
        "Flight_Status": "状态",
        "Payment": "支付",
        "Vehicle": "车辆",
        "Car_type": "车型",
        "Driver": "司机",
        "Estimated_Fare": "预计车费",
        "Notes": "备注",
        "Note": "备注",
        "Type": "类型",
        "Home": "本地",
        "Affiliate": "联盟",
        "now": "Now (Transport)",
        "reservation": "Reservation (Transport)",
        "recurring": "复发约车",
        "No_show": "NOSHOW",
        "Canceled_by_CC": "由控制中心取消",
        "Canceled_by_passenger": "乘客取消",
        "Canceled_by_partner": "已被合作伙伴取消",
        "Canceled_by_mDispatcher": "已被合作伙伴取消",
        "Canceled_by_Corporate_Admin": "已被企业管理员取消",
        "Canceled_by_API": "通过 API 取消",
        "canceled_By_Web_Booking": "通过WEB预订取消",
        "canceled_By_Time_Out": "超时取消",
        "Canceled": "取消",
        "Completed": "已完成",
        "Incident": "事故",
        "Payment_Method": "付款方式",
        "No_Phone": "没有电话",
        "VIP": "VIP",
        "Non_VIP": "非VIP",
        "Passenger_type": "乘客类型",
        "Error_load_booking": "无法加载订单，请重试或刷新页面！",
        "Edit_total_success": "总车费编辑成功！",
        "Edit_total_fail": "总车费必须等于或大于 {0}",
        "Time_out": "超时",
        "Ride_Service": "乘车服务",
        "Regulation": "常规",
        "Ride_Sharing": "拼车",
        "Unmatching": "不匹配",
        "Matching": "匹配",
        "all": "全部",
        "matching": "匹配",
        "unmatching": "不匹配",
        "regulation": "常规",
        "Support_service": "支持服务",
        "SOS": "SOS",
        "Non_SOS": "非SOS",
        "Corporate": "企业",
        "Individual": "个人",
        "On_curb": "在外等待",
        "Meet_Greet": "举牌服务",
        "Close": "关闭",
        "auto_refrest_cue": "自动刷新（{0} 秒）",
        "Ride_type": "乘坐类型",
        "Edit_total_note": "注：小计、技术费、预订费、税费和小费将在更改总金额时更新",
        "Booking": "订单",
        "Edit_fare": "编辑车费",
        "Adjust_price": "调整价格",
        "Total": "全部的",
        "Reason": "原因",
        "Please_enter_reason": "请输入原因。",
        "Save": "储存",
        "Cancel": "取消",
        "dmc": "预订面板",
        "corp": "预订面板 (CTM)",
        "Zone": "接送区",
        "mDispatcher": "合作伙伴",
        "streetSharing": "街头拼车",
        "Driver_No_Job_found": "等待任务...",
        "expandFilter": "显示过滤器",
        "collapseFilter": "隐藏过滤器",
        "clearSelectedItems": "清除所选项目",
        "Accepted": "接受",
        "Yes": "是",
        "No": "否",
        "Order_accepted": "订单已接受",
        "Confirmed": "确认",
        "spotTime": "现货时间",
        "ETA": "预计到达时间",
        "spareTime": "空余时间",
        "expectedTime": "预计接载时间",
        "Driver_on_the_way_to_sender": "司机正在前往发件人的路上",
        "Driver_on_the_way_to_recipient": "司机正在前往收件人的路上",
        "Driver_on_the_way_to_collect_cash": "司机在领取现金的路上",
        "Driver_on_the_way_to_collect_goods": "司机在取货途中",
        "Arrived_waiting_for_sender": "已到达并等待发件人",
        "Arrived_waiting_for_recipient": "已到达并等待收件人",
        "Arrived_waiting_to_collect_cash": "已抵达并等待领取现金",
        "Arrived_waiting_to_deliver_goods": "已到达并等待发货",
        "Delivered": "送货",
        "delivery": "送货",
        "Service": "服务",
        "intercity": "专线",
        "Transport": "出行约车",
        "About_Sender": "关于发件人",
        "About_Merchant": "关于商户",
        "About_Recipient": "关于收件人",
        "photos": "相片",
        "listOfItems": "项目清单",
        "cashOnPickUp": "取货付款",
        "Package_information": "包裹信息",
        "otwMerchant": "司机正在前往商家的路上",
        "arrivedAndWaitingToCollectItem": "已到达并等待领取物品",
        "Save_Filter": "保存过滤器",
        "add_favorite_filter": "添加过滤器",
        "favorite_filter_name": "名字",
        "list_favorite_filter": "管理过滤器",
        "favorite_filter_actions": "操作",
        "clear_all_filters": "清除所有过滤器",
        "Delete_Filter": "删除过滤器",
        "CONFIRM_DELETE_FILTER_TEMPLATE": "您确定要删除此过滤器吗？",
        "batchDelivery": "批量发货",
        "editFare": {
            "creditTransactionFee": "信用卡费用",
            "preferredAdjustment": "首选调整",
            "editFareFee": "编辑车费和费用",
            "markupPrice": "加价",
            "quotedPrice": "报价",
            "basicFare": "基本车费",
            "preferredEarning": "首选收入",
            "difference": "不同之处",
            "rushHourFee": "附加费",
            "serviceFee": "附加服务",
            "otherFees": "其他费用",
            "subTotal": "小计",
            "airportFee": "机场费",
            "meetDriverFee": "举牌",
            "deliveryFee": "快递费",
            "tollFee": "路桥费",
            "parkingFee": "Parking fee",
            "gasFee": "Gas fee",
            "techFee": "技术费",
            "tip": "小费",
            "bookingFee": "预订费",
            "tax": "税",
            "promoAmount": "促销金额",
            "etaFare": "全部的",
            "fleetService": "运营商服务",
            "Note_serviceFee": "(to edit additional service, visit outer list)",
            "startingFee": "Starting",
            "moveFeeInJourney": "Moving",
            "waitFeeInJourney": "Waiting (in-journey)"
        },
        "partialCompleted": "部分收款",
        "failed": "失败的",
        "Recurring": "复发#",
        "DragAndDrop": "拖放以重新排序",
        "bookingCom": "Booking.com",
        "syncingCompleted": "同步完成",
        "groupManifest": "Group Manifest",
        "assignSuppliers": "Assign Suppliers",
        "viewActions": "View Actions",
        "holidaytaxis": "HolidayTaxis",
        "karhoo": "Karhoo",
        "pickupTime": "Pick-up time",
        "AssignSupplier": "Assign Supplier",
        "sharedTransfer": "Shared Transfer (%{id})",
        "batchDeliveryGroup": "Batch Delivery (%{id})",
        "foundersFund": "Founders Fund",
        "zeroCommission": "Zero Commission",
        "unassignDriver": "Unassign Driver",
        "notUnassignDriver": "Cannot unassign driver, Please try again",
        "notAssignDriver": "Cannot assign driver, Please try again",
        "selectDriver": "Select Driver",
        "unassignVehicle": "Unassign Vehicle",
        "unassignAll": "Unassign All",
        "notUnassignAll": "Cannot unassign all, Please try again",
        "unassignAllSuccess": "The vehicle and driver have been successfully detached.",
        "unassign_all_partial": "Partial Success: Batch unassignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
        "notUnassignVehicle": "Cannot unassign vehicle, Please try again",
        "notAssignVehicle": "Cannot assign vehicle, Please try again",
        "selectVehicle": "Select Vehicle",
        "confirmUnassign": "Are you sure you want to unassign?"
    },
    "trip": {
        "Driver_vehicle": "司机的车辆",
        "tripId": "行程 ＃",
        "tripTypes": "行程类型",
        "Start_Time": "开始时间",
        "Seats_Status": "座位状态",
        "No_Of_Booking": "订单数量",
        "Vehicle": "司机的车辆",
        "Estimated_Fare": "车费",
        "capacityStatus": "座位状态",
        "Trip_Types": "行程类型",
        "scheduled": "预约行程",
        "requested": "请求行程",
        "preferred": "首选",
        "All": "全部",
        "Confirmed": "确认的",
        "Started": "开始",
        "In_Transit": "在途中",
        "Empty": "空的",
        "Partial_not_live": "部分 - 非实时",
        "Partial_live": "部分 - 实时",
        "Full": "满的",
        "Error_load_trip": "无法加载行程，请重试或刷新页面！"
    },
    "tripDetails": {
        "Trip_ID": "专线",
        "DRIVER_JOB": "司机信息",
        "BOOKINGS": "订单",
        "updateTrip": "更新行程",
        "cancelTrip": "取消行程",
        "note_cancel": "在取消此行程之前，请将所有订单分配给其他司机。",
        "TRIP_INFORMATION": "行程信息",
        "route": "线路",
        "startTime": "开始时间",
        "distance": "距离",
        "duration": "时间",
        "fare": "车费",
        "cartype": "专线车型",
        "seatStatus": "座位状态",
        "started": "开始",
        "intransit": "在途中",
        "empty": "空的",
        "notLive": "部分 - 非实时",
        "live": "部分 - 实时",
        "full": "满的",
        "noOfBooking": "订单数量",
        "luggage": "行李",
        "nameDriver": "姓名",
        "vehicleType": "车辆",
        "assignDriver": "指派司机",
        "pickUpAddress": "出发地",
        "destinationAddress": "目的地",
        "customerName": "姓名",
        "customerPhone": "电话",
        "customerNumber": "座位数",
        "customerLuggageNumber": "行李",
        "additionalService": "额外服务",
        "paymentBooking": "支付",
        "note": "备注",
        "CONFIRM_UPDATE": "确认更新",
        "CONFIRM_UPDATE_MESSAGE": "您确定要更新此行程吗？",
        "Yes": "是",
        "No": "否",
        "Update_info": "是的，更新信息"
    },
    "newbooking": {
        "Search_Vehicle": "Search for vehicle",
        "rideSharing_multi_point": "拼车订单仅允许 1 个上车点和 1 个下车点。",
        "Pick_up_time": "接载时间",
        "Drop_off_time": "送达时间",
        "All_Suppliers": "所有供应商",
        "Recipient": "收件人",
        "All": "全部",
        "New_Booking": "新订单",
        "GoogleMapRoute": "谷歌地图",
        "BOOKING_INFORMATION": "订单信息",
        "TIP_PROMO": "小费 - 促销",
        "Pick_up": "接载",
        "instructions": "指示",
        "instructions_placeholder": "在此列出您的说明...",
        "Enter_a_location": "输入地点",
        "Enter_a_location_delivery": "输入地点",
        "Enter_3rd_party_location": "输入第 3 方位置",
        "Enter_a_sender": "输入发件人",
        "Enter_a_recipient": "输入收件人",
        "Vehicle_Selection": "车辆选择",
        "Destination": "目的地",
        "Pickup_time": "接载时间",
        "Note": "备注",
        "note_for_driver": "司机注意事项",
        "affiliate_note": "联盟注意事项",
        "affiliate_note_placeholder": "输入联属操作员备注",
        "operator_note": "输入操作员的内部备注",
        "TRAVELER_INFORMATION": "乘客信息",
        "Traveler_type": "乘客类型",
        "Individual": "个人",
        "Corporation": "公司",
        "Phone_number": "电话号码",
        "First_name": "姓氏",
        "Last_name": "名字",
        "Email_address": "电子邮件地址",
        "Corp_id": "公司编号",
        "Manager_name": "经理姓名",
        "Manager_email": "经理的电子邮件",
        "Cost_centre": "成本中心",
        "Department": "部门",
        "Corp_division": "集团事业部",
        "Tip": "小费",
        "Promo_code": "促销代码",
        "Apply": "套用",
        "Promo": "促销：",
        "DISPATCH": "调度",
        "Auto_dispatch": "自动调度",
        "Assign_driver": "指派司机",
        "FarmOut": "外发订单",
        "Driver": "司机",
        "Driver_Supplier": "Supplier/Driver",
        "PAYMENT_METHOD": "付款方式",
        "OPERATOR_NOTE": "内部备注",
        "TRIP_ESTIMATE": "行程预估",
        "Choose_a_stored_card": "选择绑定的卡",
        "Choose_a_payment_method": "选择付款方式",
        "Use_new_card": "使用新卡",
        "Card_holder": "持卡人",
        "Card_number": "卡号",
        "Expired_date": "有效日期（mm/yy)",
        "ccv": "CVV",
        "Distance": "距离",
        "Duration": "时间",
        "Fare": "车费",
        "Route": "线路",
        "Create": "创建",
        "Cancel": "取消",
        "Meet_driver": "举牌",
        "Airline": "航空公司",
        "Flight": "航班 ＃",
        "FLY_INFO": "航班信息",
        "Ride_Sharing": "拼车",
        "Round_trip": "往返",
        "Car_type": "车型",
        "Address": "地址",
        "City": "城市",
        "State": "州",
        "Zip_code": "邮政编码",
        "Remove": "去除",
        "Client_case_matter": "客户案例/行程描述",
        "Charge_code": "费用代码/行程代码",
        "CANCEL_BOOKING": "关闭预订确认",
        "CANCEL_BOOKING_CONFIRM": "您想离开此订单吗？ 您将丢失所有订单数据。",
        "Search_here": "在这里搜索",
        "No_support_location": "抱歉该地区暂时未开通服务",
        "Closest_driver_message": "最近的司机大约在 {0} 之外",
        "No_cars_available": "调度半径内无可用车辆。",
        "processed_by_affiliate": "您的请求将由我们的合作伙伴处理",
        "FlightExample": "航班号，例如 AA1097",
        "Yes": "是",
        "No": "否",
        "dispatch_3rd": "第三方调度",
        "Company": "车队供应商",
        "Card_required": "请选择或添加卡！",
        "Tip_value": "N/A",
        "Calendar": "日历",
        "Extra_Destination": "额外目的地",
        "Driver_credit_limited_balance_warning": "该司机的余额小于或等于限额",
        "Meet_Greet_option": "举牌服务",
        "On_Curb_option": "在外等待",
        "Extra_Services": "额外服务",
        "Addl_Services": "额外服务",
        "Save": "保存",
        "Regular": "标准计费",
        "Please_fill_in_all_the_required_fields": "请填写所有必填字段。",
        "Please_select_weekdays": "请选择您希望进行此预订的复发日期",
        "Flat_rate": "固定计费",
        "All_company": "所有供应商",
        "Best_price": "最佳票价",
        "PromoTitle": "促销",
        "Show_more": "展示更多",
        "Warning_pending_payment": "该客户过去的订单付款未完成：%{fare}",
        "Warning_outstanding_balance": "欠款将在行程结束时一并计算。",
        "Not_available": "无法使用",
        "Warning_assigned_rate": "请为所选车型设置并套用价格包以继续。",
        "PaxLuggageNumber": "旅客/行李件数",
        "paxNumber": "乘客",
        "luggageNumber": "行李",
        "Date": "日期",
        "Single": "单独",
        "Recurring": "复发订单",
        "Date_range": "定制天数",
        "WeekDays": "复发几天",
        "Create_Route": "创建线路",
        "Pickup_Point": "接载",
        "Drag_To_Render": "拖放以重新排序",
        "DropOff_Point": "目的地",
        "Sender": "发件人",
        "Recipients": "收件人",
        "Sender_Infomation": "发件人信息",
        "Recipient_Infomation": "收件人信息",
        "Recipient_Name": "收件人姓名 *",
        "Recipient_Phone": "电话号码 *",
        "Room_floor_block": "房间、楼层和街区（可选）",
        "Details": "细节",
        "Package_Content": "包裹内容 *",
        "Order_ID": "订单号（例如 Shopify 订单号）",
        "Instructions_for_driver": "司机须知",
        "Cost_of_Goods": "货到付款 (COD)",
        "Enter_amount": "输入金额",
        "Additional_Information": "附加信息",
        "Insurance": "保险",
        "Special_Care": "特殊照护",
        "Delivery_Fee": "快递费",
        "Item_fees": "项目费用",
        "Tech_Fee": "技术费",
        "Tax": "税",
        "driverNotes": "司机注意事项",
        "rejectModal": "拒绝订单",
        "rejectModalBody": "您确定要拒绝此订单吗？",
        "rejectButton": "拒绝订单",
        "acceptModal": "接受订单",
        "acceptModalBody": "您确定要接受此订单吗？",
        "ConfirmedTo": "Confirmed to ({0})",
        "proceedAction": "Are you sure you want to proceed with this action?",
        "acceptButton": "接受订单",
        "denyModal": "拒绝订单",
        "denyModalBody": "您确定要拒绝此订单吗？",
        "denyButton": "拒绝订单",
        "Accept_booking_success": "此订单已成功接受！",
        "Deny_booking_success": "此订单已被成功拒绝！",
        "adjustFare": "调整车费",
        "totalFare": "总费用",
        "Profit": "利润",
        "DriverEarning": "司机收入",
        "Payout": "Payout",
        "totalPayout": "Total payout",
        "DriverPayOut": "支付司机",
        "confirmReject": "确认拒绝",
        "confirmCancelMSG": "您确定要拒绝此订单吗？ 您可能会受到服务商的处罚。",
        "reasonCancel3rd": "拒绝原因",
        "welcome_psg": "欢迎乘客",
        "AmountDue": "应付金额",
        "Fleet_Company": "Fleet/Company",
        "Search_driver_hydra": "我们会尽力尽快为您找到司机。",
        "ExternalID": "External ID",
        "viewRate": "View Rate",
        "PointToPoint": "Point to point",
        "Offer_driver": "Offer Driver",
        "Assign_driver_vehicle": "Assign Driver / Vehicle",
        "notify_via_email": "Notify Customer via Email"
    },
    "bookingDetailLogs": {
        "recipient": "Recipient",
        "updatedInfo": "Updated info",
        "updatedField": "Updated field",
        "valueBefore": "Value before",
        "valueAfter": "Value after",
        "location": "Location",
        "etaFare": "Fare",
        "dispatchType": "Dispatch",
        "drvInfo": "Driver",
        "vehicleTypeRequest": "Vehicle Request",
        "clientCaseMatter": "Trip description",
        "chargeCode": "Trip code",
        "driverEarningType": "Driver Earning Type",
        "editedDriverEarning": "Driver earning",
        "reasonEditFare": "Reason",
        "bookingReference": "External ID",
        "tip": "Tip",
        "type": "Service type",
        "promo": "Promo",
        "dispatch3rd": "Dispatch 3rd",
        "rideSharing": "Ride-Sharing",
        "notes": "Driver notes",
        "operatorNote": "Internal notes",
        "welcomeMessage": "Welcome Message",
        "flightNumber": "Flight Number",
        "fullName": "Full Name",
        "email": "Email",
        "cardMask": "Card mask",
        "paymentType": "Payment method",
        "recipients": "Recipients",
        "sender": "Sender",
        "pickupName": "Pickup Name",
        "pickupPhone": "Pickup Phone",
        "pickupEmail": "Pickup Email",
        "supplierEarningType": "Payout type",
        "editedSupplierEarning": "Payout value",
        "route": "Route name",
        "markupDifference": "Markup Difference",
        "selectedDates": "Selected Dates",
        "services": "Fleet services",
        "destinationAddress": "Destination location",
        "pickupAddress": "Pick-up location",
        "tripType": "Trip Type",
        "pickUpTime": "Pickup time",
        "paxNumber": "Passenger",
        "luggageNumber": "Luggage",
        "vehicle": "Vehicle",
        "minutesToArrive": "Expected driver arrival",
        "affiliateNote": "Affiliate Note",
        "packageRateName": "Package name",
        "supplierCompanies": "Company",
        "puPoints": "Pick-up location",
        "doPoints": "Destination location",
        "promoValue": "Promo value",
        "packageInfo": "Package info",
        "orderId": "Order Id",
        "cashOnDelivery": "Cash on delivery",
        "externalId": "External Id",
        "recurring": "Recurring",
        "batchDelivery": "Batch Delivery",
        "payout": "Payout",
        "locationDetails": "Location Details",
        "name": "Name",
        "phone": "Phone",
        "address": "Address",
        "basicFare": "Basic fare",
        "airportFee": "Airport fee",
        "bookingFee": "Booking fee",
        "creditTransactionFee": "Credit card fees",
        "fleetService": "Fleet service",
        "fleetServices": "Fleet services",
        "gasFee": "Gas fee",
        "meetDriverFee": "Meet driver fee",
        "moveFeeInJourney": "Moving fee",
        "otherFees": "Other fee",
        "parkingFee": "Parking fee",
        "promoAmount": "Promo amt",
        "rushHourFee": "Rush hour fee",
        "serviceFee": "Service fee",
        "startingFee": "Starting fee",
        "subTotal": "Sub total",
        "tax": "Tax",
        "techFee": "Tech fee",
        "tollFee": "Toll fee",
        "waitFeeInJourney": "Waiting (in-journey) fee"
    },
    "bookingdetail": {
        "settings": "Settings",
        "updateSuccessJobVisibilitySingle": "Update visibility successfully",
        "updateSuccessJobVisibilityMulti": "%{number} bookings has been updated successfully",
        "failApi": "Something went wrong!",
        "settingsTooltip": "Driver already assigned or trip is currently in progress.",
        "shareJobPublicly": "Share Job Publicly",
        "groupOder": "订单合组",
        "ExistingGroup": "现有组",
        "NewGroup": "新组",
        "Search_trip": "搜索行程#",
        "All": "全部",
        "creditTransactionFee": "信用卡费用",
        "partial_payment": "部分付款",
        "Write_off_debt": "注销欠款",
        "Booking_detail": "订单详情",
        "Order_detail": "订单详情",
        "Completed_Booking": "已完成订单",
        "Late_Cancel": "迟取消而罚款",
        "Cancel_Driver": "由司机取消",
        "No_Show": "NOSHOW",
        "Without_driver": "使用外部司机完成订单",
        "Complete_driver": "司机完成订单",
        "BOOKING_INFORMATION": "订单信息",
        "TIP_PROMO": "小费-促销",
        "Rearrange_Column": "重新整理列",
        "Pick_up": "出发地",
        "Enter_a_location": "输入地点",
        "Destination": "目的地",
        "Pickup_time": "接载时间",
        "Notes": "备注",
        "TRAVELER_INFORMATION": "乘客信息",
        "Traveler_type": "乘客类型",
        "Individual": "个人",
        "Corporation": "企业",
        "Phone_number": "电话号码",
        "First_name": "姓氏",
        "Last_name": "名字",
        "Email_address": "电子邮件地址",
        "Corp_id": "公司编号",
        "Manager_name": "经理姓名",
        "Manager_email": "经理的电子邮件",
        "Cost_centre": "成本中心",
        "Department": "部门",
        "Corp_division": "集团事业部",
        "Tip": "小费",
        "Promo_code": "促销代码",
        "Apply": "应用",
        "Promo": "促销",
        "DISPATCH": "调度",
        "Auto_dispatch": "自动调度",
        "Assign_driver": "指派司机",
        "Driver": "司机",
        "PAYMENT_METHOD": "支付方式",
        "Payment_methods": "支付方式",
        "pending_payment": "待付款",
        "TRIP_ESTIMATE": "行程预估",
        "Choose_a_stored_card": "选择绑定的卡",
        "Choose_a_payment_method": "选择付款方式",
        "OPERATOR_NOTE": "内部备注",
        "Use_new_card": "使用新卡",
        "Card_holder": "持卡人",
        "Card_number": "卡号",
        "Expired_date": "有效日期（mm/yy）",
        "ccv": "CVV",
        "Distance": "距离",
        "Duration": "时间",
        "Fare": "票价",
        "Route": "线路",
        "Meet_driver": "举牌",
        "Airline": "航空公司",
        "Flight": "航班 ＃",
        "FLY_INFO": "航班信息",
        "Ride_Sharing": "拼车",
        "Round_trip": "往返",
        "Car_type": "车型",
        "Address": "地址",
        "City": "城市",
        "State": "州",
        "Zip_code": "邮政编码",
        "Remove": "去除",
        "New_Booking": "新订单",
        "Clone_Booking": "Clone Booking",
        "New_Order": "新订单",
        "ViewTrackLink": "查看追踪链接",
        "driverLink": "Driver link",
        "ClickCopy": "Click to copy link",
        "ViewLog": "查看调度日志",
        "Message": "Message",
        "Update_Booking": "更新订单",
        "Cancel_Booking": "取消订单",
        "Complete_with_payment": "完成付款",
        "Incident": "事故",
        "CONFIRM_CANCEL": "确认取消",
        "CONFIRM_CANCEL_MESSAGE": "您确定要取消此订单吗？",
        "CONFIRM_UPDATE": "确认更新",
        "CONFIRM_UPDATE_MESSAGE": "您确定要更新此订单吗？",
        "CONFIRM_RESET_EDIT_FARE_MESSAGE": "您编辑的车费已重置，请检查后再继续。",
        "Close": "关闭",
        "Update_info": "更新订单",
        "Update_info_dispatch": "更新订单并调度",
        "Update_info_tooltip": "如果您打算将此订单重新分配给新司机，请选择“更新订单并调度”按钮。",
        "BOOKING": "订单",
        "DROPPED_OFF": "送达",
        "Payment_Type": "付款方式",
        "Total": "合计",
        "Next": "下一个",
        "Pay_here": "在此付款",
        "Complete_booking": "完成预订",
        "Complete_booking_tooltip": "请点击“更新订单”保存您的更改，然后再继续。",
        "Cancel": "取消",
        "Back": "返回",
        "Tax": "税",
        "Booking_fees": "预订费",
        "Tech_fees": "技术费",
        "Subtotal": "小计",
        "Other_fees": "其他费用",
        "Waiting_fee": "Waiting fee",
        "Toll_fees": "路桥费",
        "Heavy_traffic": "交通繁忙",
        "Rush_hour": "附加费",
        "Basic_fare": "基本车费",
        "Ok": "OK",
        "Input_reason": "输入原因",
        "Client_case_matter": "客户案例/行程描述",
        "Charge_code": "费用代码/行程代码",
        "Enter_3rd_party_location": "输入第 3 方位置",
        "Company": "车队供应商",
        "Check_promo_error": "检查促销代码错误！",
        "Add_new_credit_error": "添加新的信用卡错误！",
        "Can_not_get_fare_setting": "无法获取车费设置！",
        "Can_not_get_affilate_fare_setting": "无法获取联盟车费设置！",
        "Can_not_check_commisson": "无法检查佣金！",
        "No_flight_info": "没有航班信息！",
        "Socket_server_disconnected": "SOCKET服务器已断开连接！",
        "Please_reload_page_or_check_your_connection": "对不起。 无法处理您的请求。 请再试一次。",
        "View_Logs": "查看日志",
        "Bookingid": "订单#",
        "Calendar": "日历",
        "Extra_Destination": "额外目的地",
        "Meet_Greet_option": "举牌服务",
        "On_Curb_option": "在外等待",
        "Credit_Card": "信用卡",
        "Charge": "收费",
        "No_charge": "免费",
        "QR_Code_method_not_allow_error_message": "请选择其他付款方式，因为二维码无法在仪表板上付款！",
        "change_payment_method_note": "如果您想更改付款方式，请返回预订表格。",
        "Airport_Fee": "机场费",
        "Addi_service": "附加服务",
        "Additional_Services": "额外服务",
        "Minimum": "最低收费",
        "Search_here": "此处搜索",
        "No_Driver_Yet": "尚未指派司机",
        "Personal_Card": "个人卡",
        "Reject_booking": "拒绝订单",
        "Confirm_reject": "确认拒绝",
        "Confirm_reject_message": "您确定要拒绝此订单吗？",
        "Confirm_reject_booking_with_charge": "根据我们的取消政策，您将被收取报价车费的 {0}%。 您确定要拒绝此订单吗？",
        "Yes": "是",
        "No": "否",
        "shortTrip": "短行程",
        "Add_new_card": "添加新卡",
        "Require_customer_info": "请输入客户信息以继续。",
        "confirm_force_overlap": "确认",
        "confirm_force_overlap_message": "该订单与司机接受的其他订单重叠。 您确定要将其分配给该司机吗？",
        "select": "选择",
        "PICKUP_TIME_RANGE": "预计在 {0} 接载",
        "food": "食物",
        "mart": "市场",
        "Order_summary": "订单摘要",
        "addOnPrice": "调整后的价格",
        "applicableFee": "总计（适用）",
        "itemValue": "物品价值",
        "promotion_note_paymentMethod": "此订单正在应用促销代码，付款方式有限：%{method}。",
        "promotion_note_minimum_fare": "此订单正在应用促销代码，最低车费限制：%{fare}。",
        "promotion_note_schedules": "此订单正在应用促销代码，提货时间有限。",
        "edited_fare_note": "此为票价调整日志，仅供参考。 请访问报告以查看最终车费和费用。",
        "view_good_receipt": "查看收货单",
        "confirmRemoveGroupTransport": "您确定要从此清单中删除当前订单吗？",
        "confirmRemoveGroupDelivery": "您确定要从此群组中删除当前订单吗？",
        "confirmUngroupTransport": "您确定要取消此清单中所有订单的合组吗？",
        "confirmUngroupDelivery": "您确定要取消该组中所有预订的分组吗？",
        "view_delivery_receipt": "查看送货照片",
        "select_time": "选择时间",
        "InputReason": "输入原因",
        "viewOrder": "查看订单",
        "markFailed": "标记为失败",
        "corporateName": "公司名称",
        "sendMessage": "发信息",
        "AmountDueBefore": "之前到期金额",
        "AmountDueAfter": "之后到期金额",
        "chargeCustomer": "向客户收费",
        "pending": "未支付",
        "refundPayment": "Refund Payment",
        "refundableAmount": "Refundable amount",
        "refundReason": "Add refund reason",
        "refundBtn": "Refund {0}",
        "partial": "部分支付",
        "paid": "已支付",
        "ViewPaymentLogs": "查看付款日志",
        "pmActivity": "付款活动",
        "paidStatus": "付费状态",
        "paymentLog": "付款记录",
        "extraWaitFee": "Waiting fee Initial",
        "extraWaitTime": "Wait time (Initial)",
        "copied": "Copied",
        "warnningCancelCorporate": "Please note, you may incur a charge of amount in accordance with our cancellation policy."
    },
    "map": {
        "Enter_a_location": "输入地点",
        "All_company": "所有车队供应商",
        "All_cars": "所有车辆",
        "Show_all_cars": "显示所有车辆",
        "Hide_all_cars": "隐藏所有车辆",
        "Vacant": "空闲中",
        "Dispatching": "调度中",
        "Driver_on_the_way": "司机前往接载",
        "Arrived_and_waiting": "抵达接载地",
        "P_O_B": "行程中",
        "Dropped_off": "送达",
        "Waiting_for_process": "等待处理",
        "Unavailable": "暂停接单",
        "Total_Drivers": "总司机",
        "Estimate": "估计",
        "Destination": "目的地",
        "Pickup": "接载",
        "Start_point": "起点",
        "Passenger": "乘客",
        "Driver_name": "司机姓名",
        "Search_car_here": "搜索车辆/司机姓名",
        "Error_load_booking": "无法加载订单，请重试或刷新页面！",
        "Show_all": "显示所有",
        "driversLiveMap": "司机地图",
        "heatMap": "需求图",
        "Trip": "行程中",
        "traffic": "交通",
        "bicyclingLayers": "单车",
        "layer": "层",
        "trafficBicycling": "交通、自行车",
        "All_airline": "所有航空公司"
    },
    "customer": {
        "Customers": "顾客",
        "Points": "积分",
        "Migration_Status": "迁移状态",
        "ADD_CUSTOMER": "添加客户",
        "EDIT_CUSTOMER": "编辑客户",
        "Add": "添加",
        "Activate": "启用",
        "Deactivate": "停用",
        "Delete": "删除",
        "Import": "导入",
        "Download_template": "下载模板",
        "View_Message": "信息",
        "Send_Message": "发信息",
        "Export_to_excel": "导出至 Excel",
        "Filter": "过滤",
        "Apply": "应用",
        "Clear": "清除",
        "Name": "名称",
        "Phone_Number": "电话号码",
        "Email": "电子邮件",
        "Vip": "贵宾",
        "companies": "公司",
        "File": "文件",
        "Number_successful_imports": "成功导入数量：",
        "Number_skipped": "跳过的数量：",
        "Number_duplicate_phone_numbers": "重复电话号码的数量：",
        "Number_wrong_phone_numbers": "错误电话号码数量：",
        "Corporate_Name": "公司",
        "Registered_From": "注册自",
        "Registration_Date": "注册日期",
        "Registration_date_from": "注册日期自",
        "Registration_date_to": "注册日期至",
        "IOS_Last_Login_Version": "IOS最后登录版本",
        "Android_Last_Login_Version": "Android 最后登录版本",
        "Last_Active_Date": "最后活动日期",
        "Verification": "验证",
        "Support_ID": "服务ID",
        "Days_Since_Last_Active": "自上次活动以来的天数",
        "Completed_Bookings": "已完成订单",
        "Outstanding_amount": "未结金额",
        "Status": "状态",
        "Actions": "操作",
        "Action": "执行",
        "Inactive": "停用",
        "Active": "启用",
        "Edit": "编辑",
        "View": "查看",
        "ALERT_SELECT_ACTIVATE": "请选择要启用的客户。",
        "ALERT_SELECT_DEACTIVATE": "请选择要停用的客户。",
        "ALERT_SELECT_DELETE": "请选择要删除的客户。",
        "CONFIRM_DELETE": "您确定要删除该帐户吗？ 帐户删除后，客户可以使用空数据注册新的个人资料。",
        "CONFIRM_DELETE_MULTI": "您确定要删除这些客户吗？ 只有停用且不是通过App注册的用户才能删除。",
        "CONFIRM_DEACTIVATE_MULTI": "您想停用这些客户吗？",
        "CONFIRM_ACTIVATE_MULTI": "您想启用这些客户吗？",
        "CONFIRM_EXPORT_TO_EXCEL": "您确定要将所有数据导出为 .xlsx 文件吗？",
        "Add_card_top_up_success": "预付卡已充值成功",
        "Add_card_success": "卡已添加成功！",
        "Add_customer_success": "添加客户成功",
        "Update_customer_success": "更新客户成功",
        "Deleted_customer_success": "已成功删除客户",
        "Create_customer_fail": "创建客户失败",
        "Update_customer_fail": "更新客户失败",
        "ERROR_EXISTED_PHONE": "该电话已存在于系统中。 请输入另一项",
        "ERROR_INPUT_FIRSTNAME": "请输入姓名。",
        "ERROR_INPUT_PHONE": "请输入电话号码。",
        "ERROR_INPUT_VALIDPHONE": "请输入一个有效的电话号码。",
        "ERROR_INPUT_VALIDEMAIL": "请输入有效的电子邮件地址。",
        "CONFIRM_DELETE_CARD": "您确定要删除此卡吗？",
        "First_name": "姓氏",
        "Last_name": "名字",
        "Phone_number": "电话号码",
        "Email_address": "电子邮件地址",
        "Notes": "备注",
        "Account": "帐户",
        "Card_management": "卡片管理",
        "Home": "归属",
        "Affiliate": "联盟",
        "SHOW_PERSONAL_CARD": "显示信用卡",
        "ADD_PERSONAL_CARD": "添加个人信用卡",
        "SHOW_mDISPATCHER_CARD": "显示合作伙伴卡",
        "ADD_mDISPATCHER_CARD": "添加合作伙伴卡",
        "Personal_card": "个人卡",
        "mDispatcher_card": "合作伙伴卡",
        "Card_holder": "持卡人",
        "Card_number": "卡号",
        "Credit_type": "信用类型",
        "Credit_code": "信用代码",
        "Sms_verification": "短信验证",
        "Sms_verification_placeholder": "输入验证码",
        "Expired_date": "失效日期",
        "CVV": "CVV",
        "Address": "地址",
        "Country": "国家",
        "City": "城市",
        "State": "州",
        "Zip_code": "邮政编码",
        "postal_code": "邮政编码",
        "Required_fields": "必填字段",
        "Cancel": "取消",
        "Save": "保存",
        "ERROR_INPUT_CARD_HOLDER": "请输入持卡人信息",
        "ERROR_INPUT_CARD_NUMBER": "请输入卡号",
        "ERROR_INPUT_EXPIRED_DATE": "请输入到期日",
        "ERROR_VAILD_EXPIRED_DATE": "到期日无效",
        "ERROR_INPUT_CVV": "请输入CVV",
        "ERROR_INPUT_ADDRESS": "请输入地址",
        "ERROR_INPUT_CITY": "请输入城市",
        "ERROR_INPUT_ZIPCODE": "请输入邮政编码",
        "ERROR_INPUT_COUNTRY": "请选择国家",
        "Info_edit_error": "错误！",
        "Gender": "性别",
        "Select_Gender": "选择",
        "Select_Idtype": "选择 ID 类型",
        "Male": "男性",
        "Female": "女性",
        "Other": "其他",
        "Date_of_birth": "出生日期",
        "Street_address": "街道地址",
        "Select_Zone": "选择区域",
        "ERROR_GET_GATEWAYS": "获取网关错误",
        "Require_email": "添加新卡前请输入电子邮件",
        "Require_lastName": "添加新卡前请输入名字",
        "Require_phone_number": "使用信用卡预订前请输入乘客的电话号码",
        "IMPORT_CUSTOMER_SUCCESS": "导入客户成功",
        "No_card": "找不到信用卡",
        "No_Support": "该区域暂不支持添加新卡",
        "Card_Management": "信用卡管理",
        "Emergency_contacts": "紧急联系人",
        "DETAIL_CUSTOMER": "客户详情",
        "Select_Country": "选择国家",
        "Customer_ID": "ID# / 国家身份证#",
        "Credit_balance": "信用额度",
        "required_email_payment": "客户的电子邮件是必需的。 请更新个人资料以继续。",
        "Yes": "是",
        "No": "否",
        "WriteOffDebt": "注销欠款",
        "selectPayment": "请选择一种付款方式以关闭所有未付款的预订",
        "writeOffDebtSuccess": "成功核销欠款",
        "writeOffDebtNotes": "注：本次冲销应在所有未付款项全部付清后才能确认。",
        "totalCustomer": "客户总数",
        "Personal_Card": "个人卡",
        "INFO_ADD_BALANCE_SUCCESS": "乘客余额已成功更新",
        "INFO_UPDATE_POINT_SUCCESS": "乘客积分已成功更新",
        "currency": "货币",
        "balance": "结余",
        "changeBalance": "更改余额",
        "change": "变更",
        "WALLET_DEPOSIT": "钱包",
        "AccessDenied": "您没有访问此模块的权限！",
        "referral_code": "推荐码"
    },
    "importBookings": {
        "no": "否",
        "status": "状态",
        "pickupDate": "出行日期",
        "pickup": "出发地址",
        "dropoff": "目的地",
        "passenger": "乘客",
        "phone": "电话号码",
        "seats": "座位数",
        "luggages": "行李",
        "rideSharing": "拼车",
        "flightNumber": "航班号。",
        "notes": "备注",
        "externalId": "外部ID",
        "operatorNote": "内部备注",
        "clientCaseMatter": "行程描述",
        "chargeCode": "行程代码",
        "paymentType": "支付",
        "vehicleTypeRequest": "车型",
        "importBookings": "导入订单",
        "corporation": "企业及代理",
        "clickOrDropCsvFile": "单击或选择 .csv 文件",
        "importingFileForReview": "正在导入文件以供审阅...",
        "import": "导入",
        "submit": "提交",
        "downloadTemplate": "下载模板",
        "learnMore": "了解更多",
        "total": "总数",
        "valid": "有效的",
        "invalid": "无效的",
        "failed": "失败的",
        "processing": "处理中",
        "success": "成功",
        "confirm": "确认",
        "confirmMessage": "将创建订单，您确定要继续吗？",
        "create": "创建",
        "cancel": "取消",
        "close": "关闭",
        "leavingConfirmMessage": "您的工作未保存！ 您确定要离开吗？",
        "resultMessage": "${success} 订单已成功创建，${failed} 失败。",
        "limitExceededBookings": "最大导入文件大小：100 个预订",
        "delivery": {
            "no": "单号#",
            "pickupDate": "送货日期",
            "senderName": "发件人名称",
            "senderPhone": "发件人电话",
            "senderMail": "发件人电子邮件",
            "senderAddress": "发件人地址",
            "recipients": "收件人",
            "senderDistrictOptional": "区",
            "senderLocationDetails": "地点详情",
            "recipientName": "收件人姓名",
            "recipientPhone": "收件人电话",
            "recipientAddress": "收件人地址",
            "recipientDistrictOptional": "区",
            "recipientLocationDetailsOptional": "地点详情",
            "packageContent": "包裹内容",
            "orderNumber": "单号 ＃",
            "instructions": "司机须知",
            "cod": "货到付款项目价值",
            "rideShare": "拼车",
            "paymentType": "付款方式",
            "carType": "车型",
            "recipientLocationDetails": "地点详情",
            "operatorNote": "内部备注",
            "rideSharing": "批量发货"
        }
    },
    "merchant": {
        "INFO_ADD_BALANCE_SUCCESS": "商户余额已成功更新",
        "ADD_MERCHANT_USER": "添加商户账户",
        "EDIT_MERCHANT_USER": "编辑商户帐户",
        "MERCHANT_USER_DETAILS": "商户账户详细信息",
        "CASH_WALLET": "现金钱包",
        "CREDIT_WALLET": "信用钱包",
        "ADD_MERCHANT": "添加商户",
        "ACCOUNT_INFO": "帐户信息",
        "BILLING_ADDRESS": "银行信息",
        "user": {
            "fullName": "商户用户",
            "Phone_Number": "电话号码",
            "Email": "电子邮件",
            "storeName": "商号",
            "status": "状态",
            "actions": "操作",
            "First_name": "姓氏",
            "Last_name": "名字",
            "Select_store": "选择商户",
            "accountHolder": "账户持有人名称",
            "First_M_Last": "姓氏名字",
            "accountNumber": "帐号",
            "ibanNumber": "IBAN号码",
            "bankName": "银行名称",
            "IDtype": "身份证类型",
            "IDnumber": "ID# / 国家身份证#",
            "routingNumber": "汇款路径/中转/分行号码",
            "checkNumber": "支票号码",
            "ssn": "SSN/NI",
            "sortCode": "排序代码",
            "transitNumber": "中转号码",
            "institutionNumber": "机构编号",
            "birthDay": "生日",
            "address": "地址",
            "city": "城市",
            "state": "州",
            "postalCode": "邮政编码",
            "beneficiaryIDIC": "受益人 ID# / IC#",
            "relationshipOtherName": "与其关系？",
            "verificationDocumentFront": "验证文件（正面）",
            "verificationDocumentBack": "验证文件（背面）",
            "Verification_document_tooltip": "可接受的身份证明形式：\n  - 护照\n  - 驾驶执照\n  - 居留证身份证件\n  - 公民卡\n  - 身份证",
            "additionalDocumentFront": "附加文件（正面）",
            "additionalDocumentBack": "附加文件（背面）",
            "Additional_document_tooltip": "可接受的地址验证形式：\n  - 驾驶执照\n  - 水电费账单\n  - 政府发出的信函\n  - 金融机构的声明\n  - 选举登记册条目",
            "please_select": "请选择",
            "commission": "佣金",
            "ERROR_INPUT_VALIDPHONE": "请输入一个有效的电话号码。",
            "ERROR_EXISTED_PHONE": "该电话已存在于系统中。 请输入另一项",
            "Activate": "启用",
            "Deactivate": "停用",
            "ALERT_SELECT_ACTIVATE": "请选择要启用的用户。",
            "ALERT_SELECT_DEACTIVATE": "请选择要停用的用户。",
            "CONFIRM_ACTIVATE_MULTI": "您想启用这些用户吗？",
            "CONFIRM_DEACTIVATE_MULTI": "您想停用这些用户吗？",
            "RESET_PASSWORD": "您想重置密码吗？",
            "CONFIRM_RESET_PASSWORD": "您想重置密码吗？",
            "REMOVE_BANK_INFO": "确认删除银行信息",
            "CONFIRM_REMOVE_BANK_INFO": "您想删除银行信息吗？",
            "note_account_password_default": "注：对于新商户帐户，默认密码为“12345678”",
            "Cash_Balance": "现金余额",
            "Credit_Balance": "信用额度"
        },
        "Update_merchant_user_success": "商户用户已更新成功",
        "Update_merchant_user_fail": "更新商户用户失败",
        "Create_merchant_user_success": "新商户用户创建成功",
        "Create_merchant_user_fail": "创建商户用户失败",
        "Update_merchant_user_status_success": "商户用户已更新成功",
        "Update_merchant_user_status_fail": "更新商户用户失败",
        "Reset_password_merchant_user_success": "重置密码成功",
        "Reset_password_merchant_user_fail": "重置密码失败",
        "Deleted_merchant_bank_info_success": "成功删除银行信息",
        "Deleted_merchant_bank_info_fail": "删除银行信息失败"
    },
    "table_header": {
        "Items_per_page": "每页项目",
        "Page": "页",
        "Search_here": "在这里搜索",
        "Shown_Active": "显示 %{start} - %{end}（共 %{total}）",
        "Active": "%{active}启用",
        "OF": "的"
    },
    "driver": {
        "Driver": "Driver",
        "Add": "添加",
        "addDriver": "Add driver",
        "Detail": "Detail",
        "detailDriver": "Driver Details",
        "addNewVehicle": "Add Vehicle",
        "serviceType": "Service Type",
        "transport": "出行约车",
        "delivery": "Delivery",
        "stripeConnect": "Stripe Connect",
        "stripeConnectDes": "Add a stripe connect account for easier payouts",
        "bankAccount": "Banking Info",
        "bankAccountDes": "Drivers bank info to handle payouts",
        "supplierComission": "Supplier Commission",
        "supplierComissionDes": "Set commissions the supplier earns for every complete order.",
        "fleetCommission": "Fleet/Supplier commission",
        "fleetCommissionDes": "Set commissions the fleet or supplier earns for every complete order. If the driver is a supplier’s driver, the commission will be deducted from the supplier’s earning.",
        "operationZones": "Operation Zones",
        "operationZonesDes": "Zones that suppliers can receive jobs from:",
        "notes": "Notes",
        "notesDes": "Internal notes about this driver:",
        "searchNotes": "Add notes for driver",
        "marketplace": "Marketplace",
        "marketplaceDes": "Empower drivers to accept jobs they want to do.",
        "otherSettings": "Other Settings",
        "topDriver": "TOP Driver",
        "topDriverDes": "Prioritize top performers with the \"Top Driver\" tag, ensuring they get more rides.",
        "walletTransferBetweenDriver": "Wallet Transfers Between Drivers",
        "walletTransferBetweenDriverDes": "Enable drivers to transfer funds directly to each other’s wallets, facilitating easy distribution of credits by team leaders or ambassadors.",
        "rideSharing": "Ride-sharing",
        "rideSharingDes": "Enable drivers to perform ride-sharing jobs. Contact your account manager for this advance feature.",
        "individual_vehicle": "Individual vehicle",
        "company_vehicle": "Company vehicle",
        "ContactInfo": "Contact Info",
        "name": "Name",
        "expiryDate": "Expiry Date",
        "action": "Action",
        "editDocument": "Edit Document",
        "documentToUpload": "Document to upload",
        "addFiles": "Click or drag file to this area to upload.",
        "fileSize": "Maximum file size 10 MB",
        "fileSizeDes": "The file extension must be .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip",
        "removeOldFile": "Please remove the old file before uploading a new one.",
        "maxLengthFiles": "Please upload max length 1 file",
        "maxSizeFiles": "Please upload file size less than 10MBs",
        "typeFiles": "Please upload file type .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip",
        "maxAvatarSizeFiles": "Please upload avatar size less than 8MBs",
        "IFSCCode": "IFSC 代码",
        "Drivers": "司机",
        "default": "默认",
        "customize": "定制",
        "Activate": "启用",
        "Stripe_connect": "STRIPE CONNECT",
        "status_stripe": "状态：{0}",
        "stripeConnectStatus": "STRIPE CONNECT",
        "stripeStatus": "Stripe 账户状态",
        "Deactivate": "停用",
        "Delete": "删除",
        "acceptInDay": "司机一天可以接受的最大预订量",
        "acceptATime": "司机一次可以接受的预订总数",
        "Commission": "设置运营商佣金",
        "Title_Commission": "为所有司机设置佣金",
        "Confirm_Set_Commission": "您确定要为所有司机设置此佣金值吗？",
        "Commission_Successfully": "司机佣金已成功更新",
        "Apply": "套用",
        "Type": "类型",
        "Currency": "货币",
        "View_Message": "信息",
        "Send_Message": "发信息",
        "Driver_Name": "司机姓名",
        "Driver_ID": "ID# / 国家身份证#",
        "Phone_Number": "电话号码",
        "Driver_Type": "司机类型",
        "TOP": "顶级",
        "Company": "车队供应商",
        "City": "城市",
        "Driver_License_Number": "驾驶执照号码",
        "License_Plate": "牌照",
        "Car_Type": "车型",
        "Zone": "区域",
        "Registered_From": "注册自",
        "Registration_Date": "注册日期",
        "IOS_Last_Login_Version": "IOS最后登录版本",
        "Android_Last_Login_Version": "Android 最后登录版本",
        "Last_Active_Date": "最后活动日期",
        "Days_Since_Last_Active": "自上次活动以来的天数",
        "Completed_bookings": "已完成订单",
        "Status": "状态",
        "Actions": "执行",
        "Action": "操作",
        "Inactive": "停用",
        "Active": "启用",
        "Edit": "编辑",
        "View": "查看",
        "ALERT_SELECT_ACTIVATE": "请选择要启用的司机。",
        "ALERT_SELECT_DEACTIVATE": "请选择要停用的司机。",
        "ALERT_SELECT_DELETE": "请选择要删除的司机。",
        "CONFIRM_DELETE": "您想删除该司机吗？",
        "CONFIRM_DEACTIVATE_MULTI": "您确定要停用所选司机吗？ 他们连接的咪表也将被禁用。 只有当前没有订单的司机才能被停用。",
        "CONFIRM_ACTIVATE_MULTI": "您想启用这些司机吗？ 只有完全设置好的司机才能被启用。",
        "CONFIRM_DELETE_MULTI": "您想删除这些司机吗？ 只能删除处于停用状态的司机。",
        "CONFIRM_REMOVE_BANKING_INFOR": "您确定要删除银行信息吗？",
        "REMOVE_BANKING_INFOR": "删除银行信息",
        "ADD_DRIVER": "添加司机",
        "Avatar": "照片",
        "Individual_Driver": "出行 - 公司车辆",
        "Owner_Operator": "出行 - 个人车辆",
        "deliveryCompany": "送货 - 公司车辆",
        "deliveryIndividual": "送货 - 个人车辆",
        "CONTACT_INFO": "联系方式",
        "Home_address": "家庭地址",
        "Username": "用户名",
        "First_name": "姓氏",
        "Last_name": "名字",
        "Phone_number": "电话号码",
        "Email_address": "电子邮件地址",
        "Select_city": "选择城市",
        "State": "州",
        "Zip_code": "邮政编码",
        "Date_of_birth": "出生日期",
        "placeholderDate": "yyyy/mm/dd",
        "City_driver_want_to_drive": "司机想在何城市接单",
        "Driver_license_number": "驾驶执照号码",
        "Driver_license_state": "驾驶执照所在州/省",
        "Driver_license_expiry": "驾驶执照到期",
        "FOR_INSPECTOR": "给检查员",
        "Document": "文件",
        "Document_Id": "文件编号",
        "Link": "链接",
        "Notes": "备注",
        "UPLOAD_DOCUMENT": "上传文件",
        "Click_here_to_download": "点击下载",
        "Click_here_to_view": "点击查看",
        "EXTENSION_REQUIREMENT": "文件扩展名必须为 .jpg、.jpeg、.png、.doc、.docx、.xls、.xlsx、.pdf、.7z、.rar、.zip 且小于 5Mb。",
        "Invalid_file": "无效文件",
        "File_size_larger_than_5Mb": "文件大小必须大于 5Mb",
        "BANK_ACCOUNT": "银行账户",
        "Name_of_bank": "银行名称",
        "Name_of_account": "账户名称",
        "First_M_Last": "姓氏名字",
        "RTBnumber": "汇款线路/中转/分行号码",
        "bankName": "银行名",
        "Select_a_bank": "选择银行",
        "Account_number": "帐号",
        "IBAN_number": "IBAN号码",
        "Check_number": "支票号码",
        "Swift_code_number": "Swift 代码",
        "SSNNI": "SSN / NI",
        "sortCode": "排序代码",
        "Verification_document": "验证文件（正面）",
        "Additional_document": "附加文件（正面）",
        "Verification_document_back": "验证文件（背面）",
        "Additional_document_back": "附加文件（背面）",
        "others": "其他",
        "signedW9Form": "已签署的 W-9 表格",
        "driverLicense": "驾驶执照",
        "Verification_document_tooltip": "可接受的身份证明形式：\n  - 护照\n  - 驾驶执照\n  - 居留证身份证件\n  - 公民卡\n  - 身份证",
        "Additional_document_tootip": "可接受的地址验证形式：\n  - 驾驶执照\n  - 水电费账单\n  - 政府发出的信函\n  - 金融机构的声明\n  - 选举登记册条目",
        "isBankAccountOwner": "这是你的账户吗？",
        "yes": "是",
        "no": "否",
        "beneficiary_name": "收款人姓名",
        "beneficiary_idic": "受益人身份证/身份证",
        "relationship": "关系",
        "select_relationship": "选择关系",
        "spouse": "配偶",
        "daughterOrSon": "女儿/儿子",
        "brotherOrSister": "兄弟姐妹",
        "relative": "有相关",
        "friends": "朋友们",
        "other": "其他",
        "relationshipOtherName": "与他人的关系？",
        "OPERATION": "运营",
        "CAR_INSURANCE": "车辆保险",
        "Policy_number": "保单号码",
        "Effective_date": "生效日期",
        "Expired_date": "到期日期",
        "Select_company": "选择车队供应商",
        "All_company": "所有车队供应商",
        "All_Car_Type": "所有车型",
        "Car": "车辆",
        "Existing_car": "现有车辆",
        "New_car": "新车辆",
        "License_plate": "牌照",
        "Select_license_plate": "选择车牌",
        "Select_Car_Type": "选择车型",
        "vehicleOwner": "车主姓名",
        "Make": "厂家",
        "Select_Car_Make": "选择车辆品牌",
        "Model": "型号",
        "Select_Car_Model": "选择车型",
        "Year": "年",
        "Select_Year": "选择年份",
        "Color": "颜色",
        "Passengers": "乘客",
        "Luggage": "行李",
        "License_expiry": "许可证到期",
        "Shift": "排班",
        "Select_Shift_Template": "选择排班模板",
        "Fleet_commission": "运营商佣金（报告）",
        "Company_commission": "供应商佣金（报告）",
        "rideHailing": "街道打车",
        "intercity": "专线",
        "food": "送餐",
        "mart": "市场",
        "streetSharing": "拦车拼车",
        "Shuttle": "穿梭",
        "parcel": "包裹",
        "driverInsurance": "司机保险",
        "Percentage": "百分比",
        "Amount": "金额",
        "transaction": "交易",
        "Terminal_ID": "终端ID",
        "Auth_key": "授权键",
        "CREDIT_DEPOSIT": "信用钱包",
        "Credit_Balance": "信用额度",
        "Balance": "余额",
        "Change_balance": "更改余额",
        "Select_balance": "选择余额",
        "Adjust_amount": "调整金额",
        "Adjust_value": "调整值",
        "New_balance": "新的余额",
        "New_point": "新点数",
        "Enter_reason": "输入原因",
        "ERROR_INPUT_BALANCE": "请选择余额。",
        "ERROR_INPUT_AMOUNT": "请输入该值。",
        "ERROR_INPUT_REASON": "请输入原因。",
        "ERROR_INPUT_VALID_AMOUNT": "请输入一个大于或等于 0 的值。",
        "INFO_ADD_BALANCE_SUCCESS": "司机余额已成功更新",
        "INFO_ADD_CASH_BALANCE_SUCCESS": "司机现金余额已成功更新",
        "Force_meter": "强制咪表",
        "Force_meter_tooltip": "司机App必须连接咪表，适用于Pulsar和Centrodyne的咪表",
        "ERROR_EXISTED_PHONE": "该电话已存在于系统中。 请输入另一项",
        "TOP_driver": "顶级司机",
        "Ride_sharing": "拼车",
        "Note_password": "注意：新司机帐户，默认密码为“12345678”",
        "Required_fields": "必填字段",
        "Cancel": "取消",
        "Save": "保存",
        "SaveAndInProgress": "保存并进行中",
        "SaveAndDeactivate": "保存并停用",
        "ERROR_REQUIRE_": "请输入",
        "ERROR_INPUT_AVATAR": "请上传头像。",
        "ERROR_INPUT_DRIVER_TYPE": "请选择司机类型。",
        "ERROR_INPUT_DOCUMENT": "请上传",
        "ERROR_INPUT_USERNAME": "请输入用户名。",
        "ERROR_INPUT_VALID_USERNAME": "用户名只能包含小写字母和数字，长度必须在 3 到 20 个字符之间",
        "ERROR_INPUT_PHONE": "请指定有效的电话号码。",
        "ERROR_INPUT_VALID_EMAIL": "请输入有效的电子邮件。",
        "ERROR_INPUT_EMAIL": "请输入电子邮件。",
        "ERROR_INPUT_FIRSTNAME": "请输入姓氏。",
        "ERROR_INPUT_LASTNAME": "请输入名字。",
        "ERROR_INPUT_ADDRESS": "请输入家庭住址。",
        "ERROR_INPUT_CITY": "请输入城市。",
        "ERROR_INPUT_STATE": "请输入状态。",
        "ERROR_INPUT_ZIPCODE": "请输入邮政编码。",
        "ERROR_INPUT_BIRTHDAY": "请输入生日。",
        "ERROR_INPUT_NAME_OF_BANK": "请输入银行名称。",
        "ERROR_INPUT_NAME_OF_ACCOUNT": "请输入账户持有人姓名。",
        "ERROR_INPUT_ROUTING_NUMBER": "请输入路由号码。",
        "ERROR_INPUT_BANK_NAME": "请选择银行。",
        "ERROR_INPUT_ACCOUNT_NUMBER": "请输入帐号",
        "ERROR_INPUT_CHECK_NUMBER": "请输入支票号码。",
        "ERROR_INPUT_SSN": "请输入SSN。",
        "ERROR_INPUT_DocumentId": "请输入文档ID。",
        "ERROR_INPUT_SORTCODE": "请输入排序代码。",
        "ERROR_INPUT_COMPANY": "请选择要启用的供应商。",
        "ERROR_INPUT_LICENSE_PLATE": "请选择车牌。",
        "ERROR_INPUT_ZONE": "请选择要启用的区域。",
        "ERROR_INPUT_SHIFT": "请选择班次来启用。",
        "ERROR_ACCOUNT_NUMBER_ONLY_NUMBERS": "这必须仅包含数字",
        "ERROR_SSN_ONLY_NUMBERS": "这必须仅包含数字",
        "ERROR_SSN_LENGTH": "SSN 必须至少有 4 个字符",
        "ERROR_COMMISSION_NUMBERS": "请输入有效号码",
        "INFO_DEACTIVAED_SUCCESS": "司机已成功停用。",
        "INFO_ACTIVAED_SUCCESS": "司机已成功启用。",
        "INFO_DEACTIVAED_SUCCESS_BUT_HARDWARE": "司机已停用，但由于某些事故原因，咪表无法关闭",
        "INFO_MULTIDEACTIVAED_SUCCESS": "{0}选定的司机已成功停用",
        "INFO_MULTIDEACTIVAED_HARDWARE_SUCCESS": "{0}选定的司机已成功停用。 {1}连接的咪表被禁用",
        "INFO_MULTIDEACTIVAED_SUCCESS_BUT_HARDWARE": "[3]选定的司机已成功停用。 [2]连接的咪表被禁用。 [1]不是由于一些意想不到的原因",
        "INFO_MULTIACTIVAED_SUCCESS": "{0}选定的司机已成功启用",
        "DRIVER_UPDATE_SUCCESS": "司机已成功更新。",
        "DRIVER_CREATE_SUCCESS": "司机信息已成功添加",
        "Expiry_Date": "到期日",
        "Case_number": "案件编号",
        "Export_to_csv": "导出为 CSV",
        "CONFIRM_EXPORT_TO_CSV": "您确定要导出 .csv 格式的所有数据吗？",
        "Ride_sharing_tooltip": "拼车是一项高级功能，请联系我们的支持团队以获取更多信息",
        "In_Review": "审查中",
        "Reset_password_success": "重置密码成功",
        "Topup_note": "（启用司机后可以充值司机余额）",
        "cash_balance": "现金余额",
        "cash_wallet": "现金钱包",
        "total_balance": "总余额",
        "total_cash_balance": "现金余额总额",
        "total_credit_balance": "信用余额总额",
        "banking_status": "银行状况",
        "bankingStatus": {
            "verified": "已验证",
            "not_verified": "未经审核的"
        },
        "INVALID_FILE_SIZE": "文件大小必须小于 8Mb。",
        "totalDrivers": "总司机数",
        "profileStatus": "个人资料状态",
        "profile_completed": "个人资料已完成",
        "profile_incomplete": "个人资料不完整",
        "profile_inProgress": "个人资料正在进行中",
        "in_review_completed": "审核中（个人资料已完成）",
        "in_review_incomplete": "审核中（个人资料不完整）",
        "in_review_inProgress": "审核中（个人资料正在进行中）",
        "CASH_WALLET_DEPOSIT": "现金钱包",
        "payoutSelect": "付款（付款后将发送给司机电邮和App收件箱）",
        "Confirm_update_cash_balance": "更改现金余额",
        "Confirm_update_cash_balance_msg": "您确定要更改司机余额吗？",
        "cashBalance": "现金余额",
        "cashBalance_unavailable": "0.00",
        "cashBalance_active": "正数",
        "cashBalance_negative": "负数",
        "creditBalance": "信用余额",
        "ERROR_INPUT_DOCUMETID": "请输入文档编号。",
        "ERROR_INPUT_EXPIRYDATE": "请输入到期日期。",
        "driverType": "司机类型",
        "tooltip_in_review_completed": "发送通知给完成个人资料的“审核中”司机。",
        "tooltip_in_review_incomplete": "发送通知给尚未完成个人资料的“审核中”司机。",
        "tooltip_in_review_inProgress": "发送通知给已完成个人资料但需要额外验证的“审核中”司机。",
        "tooltip_active": "发送通知给“启用”司机。",
        "tooltip_inactive": "发送通知给已重新启用帐户的司机。",
        "active": "启用",
        "inactive": "停用",
        "inReviewInProgress": "审查中",
        "Market_place": "订单市集",
        "Market_place_tooltip": "司机抢单，接受合适自己的订单",
        "selectDriverType": "选择司机类型",
        "PlsSelectDriverType": "请选择司机类型",
        "emergencyContacts": "Emergency Contacts",
        "switchVehicle": "Switch to any vehicle",
        "switchVehicleNote": "Allow driver to switch to any vehicle of their assigned supplier company.",
        "vehicleModel": "Model",
        "licensePlateNumber": "Vehicle Plate",
        "addVehicle": "Add Vehicle",
        "newVehicle": "New Vehicle",
        "linkVehicle": "Link Vehicle",
        "modal": "Modal",
        "selectVehicle": "Select Vehicle",
        "selectVehicleNote": "Enable driver to select or change vehicle for a booking.",
        "Warning_change_company": "Warning: Reassigning this driver to another company will unlink all associated vehicles."
    },
    "corporate": {
        "User_Name": "用户名",
        "Edit_Balance": "编辑余额",
        "Corporates": "企业或代理",
        "Supplier": "供应商",
        "CarTypes": "车型",
        "Add": "添加",
        "Balance": "余额",
        "Activate": "启用",
        "Deactivate": "停用",
        "Delete": "删除",
        "Company_Name": "公司名称",
        "Admin_Name": "管理员名称",
        "Username": "用户名",
        "Phone_Number": "电话号码",
        "importBooking": "能够导入预订",
        "Email": "电子邮件",
        "Registered_From": "注册自",
        "Registration_Date": "注册日期",
        "Status": "状态",
        "Actions": "操作",
        "Action": "操作",
        "Inactive": "停用",
        "Active": "启用",
        "In_Review": "审查中",
        "Edit": "编辑",
        "View": "查看",
        "ADD_CORPORATE_CARD": "添加公司卡",
        "selectCountry": "选择国家",
        "CONFIRM_DELETE_CARD": "您确定要删除此卡吗？",
        "ALERT_SELECT_ACTIVATE": "请选择要启用的公司。",
        "ALERT_SELECT_DEACTIVATE": "请选择要停用的公司。",
        "ALERT_SELECT_DELETE": "请选择要删除的公司。",
        "CONFIRM_DELETE": "您想删除该公司吗？",
        "CONFIRM_DELETE_USER": "您想删除该公司用户吗？",
        "CONFIRM_DEACTIVATE_MULTI": "您想停用这些公司吗？",
        "CONFIRM_ACTIVATE_MULTI": "您想启用这些公司吗？",
        "CONFIRM_DELETE_MULTI": "你想删除这些公司吗？ **只有停用的企业才能被删除。",
        "ERROR_INPUT_PHONE": "请指定有效的电话号码。",
        "ERROR_INPUT_VALIDPHONE": "请输入一个有效的电话号码。",
        "ERROR_EXISTED_PHONE": "该电话已存在于系统中。 请输入另一项",
        "Account": "帐户",
        "Corp_traveler": "企业乘客",
        "Payment_methods": "支付方式",
        "Corp_operators": "企业操作员",
        "Pricing": "价格",
        "COMPANY_INFORMATION": "公司信息",
        "Company_name": "公司名称",
        "Company_ID": "公司 ID（用于公司用户注册）",
        "Company_address": "公司地址",
        "Invoice_email": "发票电子邮件",
        "Confirmation_email": "确认邮件",
        "colorCodeBooking": "色标",
        "ADMIN_ACCOUNT": "管理员帐户",
        "First_name": "姓氏",
        "Last_name": "名字",
        "Commission": "佣金",
        "commissionBooking": "从 app/pwa 进行预订获取佣金",
        "Required_fields": "必填字段",
        "Save": "保存",
        "Cancel": "取消",
        "Reset_password": "重设密码",
        "Employee_Name": "员工姓名",
        "Created_Date": "创建日期",
        "Add_corporate_card": "添加公司卡",
        "Corp_Users": "企业用户",
        "Pricing_Default": "默认（与普通乘客相同的定价计划）",
        "Pricing_Discount": "百分比折扣（仅适用于企业用户）",
        "Markup_By_Percent": "按百分比加价（仅适用于企业用户）",
        "Pricing_Add_different_rates": "套用不同的价格包",
        "ERROR_INPUT_COMPANYNAME": "请输入公司名称。",
        "ERROR_INPUT_USERNAME": "请输入用户名。",
        "ERROR_INPUT_FIRSTNAME": "请输入姓名。",
        "ERROR_INPUT_EMAIL": "请输入电子邮件。",
        "ERROR_INPUT_VALID_EMAIL": "请输入有效的电子邮件地址。",
        "ERROR_INPUT_VALID_NUMBER": "请输入有效号码",
        "ERROR_INPUT_VALID_RANGE_AMOUNT": "请输入一个介于 {0} 和 {1} 之间的值。",
        "INFO_ADD_CORPORATE_SUCCESS": "帐户 {0} 已成功创建。 请检查电子邮件 {1} 以获取登录凭据详细信息。",
        "INFO_UPDATE_CORPORATE_SUCCESS": "公司信息已更新成功。",
        "INFO_UPDATE_PRICING_SUCCESS": "定价已成功更新",
        "INFO_RESET_PASSWORD_SUCCESS": "帐户 {0} 已成功重置。 请检查电子邮件 {1} 以获取登录凭据详细信息。",
        "COMFIRM_RESET_PASSWORD": "您想重置密码吗？",
        "Traveler_signature": "乘客签名",
        "Parcel_Delivery": "启用送货",
        "Tracking_log": "追踪日志",
        "Rating": "评分",
        "Email_address": "电子邮件地址",
        "Assistant_email": "助理邮箱",
        "Corp_PO": "公司订单号",
        "Cost_centre": "成本中心",
        "Corp_division": "集团事业部",
        "Corp_id": "公司编号",
        "Department": "部门",
        "Manager_email": "经理的电子邮件",
        "Manager_name": "经理姓名",
        "Corporate_card": "公司卡",
        "Direct_invoicing": "月结",
        "Corporate_Prepaid": "企业预付",
        "Cash": "现金",
        "Car_Type_ID": "车型 ID",
        "App_Display_Name": "App显示名称",
        "Normal_Fare": "标准计费",
        "Flat_Fare": "固定计费",
        "Hourly_Daily_Rate": "按时计费",
        "Delivery_Rate": "送货",
        "Assign_rate": "套用",
        "Zone": "区",
        "Rate": "价格包",
        "Zone_Rate": "区域 - 价格包",
        "Please_select_rate": "请选择价格包",
        "Paid_by": "由...支付",
        "Enter_new_credit_card": "输入新信用卡",
        "TOP_UP": "充值",
        "Top_up": "充值",
        "Topup_amount": "充值金额",
        "Currency": "货币",
        "ERROR_INPUT_AMOUNT": "请输入有效金额。",
        "VAT_number": "增值税号",
        "Gender": "性别",
        "Select_Gender": "选择",
        "Male": "男性",
        "Female": "女性",
        "Date_of_birth": "出生日期",
        "Street_address": "街道地址",
        "City": "城市",
        "State": "州",
        "Zip_code": "邮政编码",
        "Country": "国家",
        "adding_new_card": "您正在添加一张新卡以使用 %{zoneName} 区域的服务",
        "No_Support": "该区域暂不支持添加新卡",
        "Job_title": "职称",
        "required_phone_number_payment": "需要提供公司电话号码。 请更新个人资料以继续。",
        "Yes": "是",
        "No": "否",
        "AddCardBeforeTopup": "未找到信用卡。 请添加新卡以继续。",
        "flightNumber": "航班号",
        "Company_Type": "公司类型",
        "Standard": "标准企业",
        "Airline": "航空公司",
        "Branding": "品牌",
        "bgImage": "背景图片 (1600x900)",
        "logoImage": "形象标识",
        "aliasTitle": "别名 (URL) 参数",
        "importChauffeurBooking": "能够导入出行约车订单",
        "isCorporateVip": "Corporate VIP",
        "isCorporateVipToolTip": "Enable this setting to automatically assign VIP status to all corporate travelers upon creation, you may turn it off for each individual user.",
        "qrTitle": "QR Web booking link",
        "qrNote": "Your personalized partner booking link is here! Display it prominently in bars, hotel receptions, guest rooms, and on your website—anywhere your customers might need a ride or delivery. Consider designing standees with your unique QR code, allowing customers a quick and easy scan to book their journey!"
    },
    "airline": {
        "Airlines": "航空公司",
        "Add": "添加",
        "Activate": "启用",
        "Deactivate": "停用",
        "Delete": "删除",
        "Company_Name": "公司名称",
        "Admin_Name": "管理员名称",
        "Username": "用户名",
        "Phone_Number": "电话号码",
        "Email": "电子邮件",
        "Registered_From": "注册自",
        "Registration_Date": "注册日期",
        "Status": "状态",
        "Actions": "操作",
        "Action": "操作",
        "Inactive": "停用",
        "Active": "启用",
        "In_Review": "审查中",
        "Edit": "编辑",
        "View": "查看",
        "ADD_CORPORATE_CARD": "添加航空卡",
        "selectCountry": "选择国家",
        "CONFIRM_DELETE_CARD": "您确定要删除此卡吗？",
        "ALERT_SELECT_ACTIVATE": "请选择要启用的航空公司。",
        "ALERT_SELECT_DEACTIVATE": "请选择要停用的航空公司。",
        "ALERT_SELECT_DELETE": "请选择要删除的航空公司。",
        "CONFIRM_DELETE": "您想删除该航空公司吗？",
        "CONFIRM_DELETE_USER": "您想删除该航空公司用户吗？",
        "CONFIRM_DEACTIVATE_MULTI": "您确定要停用这些公司吗？",
        "CONFIRM_ACTIVATE_MULTI": "您确定要启用这家公司吗？",
        "CONFIRM_DELETE_MULTI": "您确定要删除该公司吗？\n  ** 只有停用的公司才能被删除。",
        "ERROR_INPUT_PHONE": "请指定有效的电话号码。",
        "ERROR_INPUT_VALIDPHONE": "请输入一个有效的电话号码。",
        "ERROR_EXISTED_PHONE": "该电话已存在于系统中。 请输入另一项",
        "Airline": "航空公司",
        "Crew": "空服人员",
        "Payment_methods": "支付方式",
        "Airline_operators": "航空公司运营商",
        "Pricing": "价钱",
        "COMPANY_INFORMATION": "公司信息",
        "Company_name": "公司名称",
        "Company_ID": "公司编号",
        "Company_address": "公司地址",
        "Invoice_email": "发票电子邮件",
        "Confirmation_email": "确认邮件",
        "colorCodeBooking": "色标",
        "ADMIN_ACCOUNT": "管理员帐户",
        "First_name": "姓氏",
        "Last_name": "名字",
        "Commission": "佣金",
        "Required_fields": "必填字段",
        "Required_fields_crews": "注意：对于新的空服帐户，默认密码为“12345678”",
        "Save": "储存",
        "Cancel": "取消",
        "Reset_password": "重设密码",
        "Crew_Name": "空服人员",
        "Created_Date": "创建日期",
        "Add_corporate_card": "添加航空卡",
        "Corp_Users": "用户",
        "Pricing_Default": "默认",
        "Pricing_Discount": "按百分比折扣",
        "Markup_By_Percent": "按百分比加价",
        "Pricing_Add_different_rates": "套用不同的价格包",
        "ERROR_INPUT_COMPANYNAME": "请输入公司名称。",
        "ERROR_INPUT_USERNAME": "请输入用户名。",
        "ERROR_INPUT_FIRSTNAME": "请输入姓名。",
        "ERROR_INPUT_EMAIL": "请输入电子邮件。",
        "ERROR_INPUT_VALID_EMAIL": "请输入有效的电子邮件地址。",
        "ERROR_INPUT_VALID_NUMBER": "请输入有效号码",
        "ERROR_INPUT_VALID_RANGE_AMOUNT": "请输入一个介于 {0} 和 {1} 之间的值。",
        "INFO_ADD_CORPORATE_SUCCESS": "帐户 {0} 已成功创建。",
        "INFO_UPDATE_CORPORATE_SUCCESS": "航空公司信息已更新成功。",
        "INFO_UPDATE_PRICING_SUCCESS": "定价已成功更新",
        "INFO_RESET_PASSWORD_SUCCESS": "帐户 {0} 已成功重置。",
        "COMFIRM_RESET_PASSWORD": "您想重置密码吗？",
        "Traveler_signature": "乘客签名",
        "Tracking_log": "追踪日志",
        "Rating": "评分",
        "Email_address": "电子邮件地址",
        "Assistant_email": "助理邮箱",
        "Corp_PO": "公司订单号",
        "Cost_centre": "成本中心",
        "Corp_division": "集团事业部",
        "Corp_id": "公司编号",
        "Department": "部门",
        "Manager_email": "经理的电子邮件",
        "Manager_name": "经理姓名",
        "Corporate_card": "航空卡",
        "Direct_invoicing": "月结",
        "Corporate_Prepaid": "航空公司预付",
        "Cash": "现金",
        "Car_Type_ID": "车型 ID",
        "App_Display_Name": "App显示名称",
        "Normal_Fare": "标准计费",
        "Flat_Fare": "固定计费",
        "Hourly_Daily_Rate": "按时计费",
        "Delivery_Rate": "送货",
        "Assign_rate": "套用价格包",
        "Zone": "区",
        "Rate": "价格包",
        "Zone_Rate": "区域 - 价格包",
        "Please_select_rate": "请选择费率",
        "Paid_by": "由...支付",
        "Enter_new_credit_card": "输入新信用卡",
        "TOP_UP": "充值",
        "Top_up": "充值",
        "Topup_amount": "充值金额",
        "Currency": "货币",
        "ERROR_INPUT_AMOUNT": "请输入有效金额。",
        "VAT_number": "增值税号",
        "Gender": "性别",
        "Select_Gender": "选择",
        "Male": "男性",
        "Female": "女性",
        "Date_of_birth": "出生日期",
        "Street_address": "街道地址",
        "City": "城市",
        "State": "州",
        "Zip_code": "邮政编码",
        "Country": "国家",
        "adding_new_card": "您正在添加一张新卡以使用 %{zoneName} 区域的服务",
        "No_Support": "该区域暂不支持添加新卡",
        "Job_title": "职称",
        "required_phone_number_payment": "需要提供公司电话号码。 请更新个人资料以继续。",
        "Yes": "是",
        "No": "否",
        "AddCardBeforeTopup": "未找到信用卡。 请添加新卡以继续。"
    },
    "message": {
        "Add": "添加",
        "Delete": "删除",
        "Items_per_page": "每页项目",
        "Page": "页",
        "Search_here": "在这里搜索",
        "Shown_Active": "显示了 {0} - {1} 个（共 {2} 个）",
        "Date": "日期",
        "Subject": "主题",
        "To": "至",
        "Type": "类型",
        "Result": "结果",
        "Actions": "操作",
        "New_message": "新消息",
        "MESSAGE_DETAILS": "信息详情",
        "All": "全部",
        "Company": "车队供应商",
        "Active": "启用",
        "In_Active": "停用",
        "In_Review": "审查中",
        "Send_to": "发给",
        "All_drivers": "所有司机",
        "All_customers": "所有客户",
        "All_merchants": "所有商户",
        "Customized_driver": "选择司机",
        "Customized_customer": "选择客户",
        "Customized_merchant": "选择商户",
        "Receiver_list": "收件人列表",
        "Content": "内容",
        "Required_fields": "必填字段",
        "Search_driver_name_driver_phone_number": "搜索司机姓名、司机电话号码",
        "Search_category_name": "搜索类别名称",
        "Search_customer_name_customer_phone_number": "搜索客户姓名、客户电话",
        "Search_merchant_name_merchant_phone_number": "搜寻商户名称、商户电话号码",
        "Search_car_type": "搜索车型",
        "Searching": "正在寻找...",
        "No_results": "没有结果",
        "Send": "发送",
        "Cancel": "取消",
        "SMS_failed_Inbox_succeeded": "短信失败，收件箱成功",
        "Failed": "失败",
        "Succeeded": "成功",
        "CONFIRM_DELETE": "您想删除此消息吗？",
        "CONFIRM_DELETE_MULTI": "您想删除这些消息吗？",
        "ALERT_SELECT_DELETE": "请选择要删除的消息。",
        "ERROR_INPUT_RECEIVERLIST": "请至少选择一位司机发送消息",
        "ERROR_INPUT_CUSTOMER_RECEIVERLIST": "请至少选择一位客户发送消息",
        "ERROR_INPUT_SUBJECT": "请输入主题",
        "ERROR_INPUT_CONTENT": "请输入内容",
        "ERROR_INPUT_LEAST_ONE_SUBJECT_CONTENT": "请设置至少一种语言才能继续",
        "ERROR_INPUT_SUBJECT_MULTI_LANGUAGE": "请输入 {0} 语言的主题",
        "ERROR_INPUT_CONTENT_MULTI_LANGUAGE": "请输入{0}语言的内容",
        "ERROR_INPUT_URL_MULTI_LANGUAGE": "请输入 {0} 语言的网址",
        "ERROR_INPUT_TITLE_MULTI_LANGUAGE": "请输入 {0} 语言的标题",
        "ERROR_INPUT_BODY_MULTI_LANGUAGE": "请输入 {0} 语言的正文",
        "import_fail_success_driver": "%{success} 个司机已成功导入，%{fail} 个失败。",
        "import_fail_success_passenger": "%{success} 个客户已成功导入，%{fail} 个客户失败。",
        "import_fail_success_merchant": "%{success} 个商户已成功导入，%{fail} 个商户导入失败。",
        "CONFIRM_SEND_SUCCESS": "您的信息已发送。 这可能需要几分钟的时间。 系统正在处理您的请求，请稍候。",
        "Load_message_and_sms_fail": "加载消息和SMS详细信息时发生错误",
        "Update_message_and_sms_fail": "更新消息和SMS详细信息时发生错误",
        "Update_message_and_sms_success": "消息和SMS详细信息已成功更新",
        "Driver_List": "司机列表",
        "Car_Type_List": "车型列表",
        "Search_vehicle_type_name": "搜索车辆类型名称",
        "ALERT_PROCESSING_DELETE": "您的请求正在处理中。 这可能需要几秒钟",
        "Customer": "客户",
        "Driver": "司机",
        "StatusOfCustomer": "客户状态",
        "StatusOfDriver": "司机状态",
        "StatusOfMerchant": "商户状态",
        "SendTo": "发给",
        "DownloadReceiverList": "下载接收者列表",
        "warningInputChangeOver": "最近 2 个输入之间的相对变化超过 20%。 请考虑！",
        "NoteDownloadReveverList": "1 个月后，将禁用下载接收者列表。",
        "Load_voip_fail": "加载 VOIP 详细信息时发生错误。",
        "Update_voip_success": "VOIP 详细信息已成功更新。",
        "Update_voip_fail": "更新 VOIP 详细信息时发生错误。",
        "expiryDate": "发送给新用户直到",
        "expiryDate_placeholder": "到期日期（月/日/年）",
        "NA": "不适用",
        "PleaseSelectStatusOfDriver": "请选择司机状态",
        "versionApp": "App版本",
        "platform": "运营商",
        "contentType": "内容类型",
        "url": "网址",
        "title": "标题",
        "contentId": "内容ID",
        "body": "内容",
        "image_notification": "图像",
        "platformItem": {
            "all": "全部",
            "iOS": "iOS",
            "android": "Android"
        },
        "comparisonVersionApp": {
            "is": "是",
            "is_not": "不是",
            "is_one_of": "是其中之一",
            "is_not_one_of": "不是其中之一",
            "greater_or_equal": "大于或等于",
            "less_or_equal": "小于或等于"
        },
        "contentTypeItem": {
            "html": "HTML",
            "link": "链接"
        },
        "Please_input": "此字段是必需的"
    },
    "fleetInfo": {
        "Fleet_name": "运营商名字",
        "Phone_number": "电话号码",
        "Email_address": "电子邮件地址",
        "Country": "国家",
        "Time_zone": "时区",
        "Address": "地址",
        "Website": "网站",
        "Currency": "货币",
        "Distance_unit": "距离单位",
        "Load_fleet_info_fail": "加载运营商信息时发生错误"
    },
    "generalSetting": {
        "alertNotifications": {
            "title": "Alert notifications",
            "emails": "Emails",
            "emailsPlaceholder": "Enter email",
            "slack": "Slack Channel (Instructions)",
            "slackPlaceholder": "Slack POST URL",
            "discord": "Discord Channel (Instructions)",
            "discordPlaceholder": "Enter Discord Channel",
            "telegram": "Telegram Channel (Instructions)",
            "telegramPlaceholder": "Enter Telegram Channel"
        },
        "showFare": {
            "title": "司机显示票价",
            "totalFare": "总票价",
            "estimateGross": "预估总收入"
        },
        "carHailing": "街头打车",
        "regular": "标准",
        "cancelTimeout": "自动取消超时订单",
        "allowStripePaymentLink": "允许生成 Stripe 付款链接",
        "flat": "固定",
        "techFee": "每笔交易的技术费",
        "customizeLogo": "自定义仪表板徽标",
        "logo": "标识",
        "customerApp": "客户App",
        "hourly": "按时",
        "shuttle": "穿梭",
        "parcel": "包裹",
        "freeCancelTime": "每天免费取消次数",
        "AM": "上午",
        "PM": "下午",
        "RUSH_HOUR": "附加费",
        "Name": "名称",
        "Date": "日期",
        "Mon": "周一",
        "Tue": "周二",
        "Wed": "周三",
        "Thu": "周四",
        "Fri": "周五",
        "Sat": "周六",
        "Sun": "周日",
        "Time_range": "时间范围",
        "From": "从",
        "To": "至",
        "Rush_hour_time_calculation": "附加费时间计算",
        "Rush_hour_surcharge": "附加费",
        "Required_fields": "必填字段",
        "Save": "保存",
        "Cancel": "取消",
        "MDISPATCHER_CANCELLATION": "App / 个人合作伙伴 / WEB取消订单",
        "Book_now": "实时用车",
        "MDISPATCHER_CANCELLATION_TOOLTIP": "App / 个人合作伙伴 / WEB取消订单",
        "Book_now_toolTip": "如果实时订单在以下时间内取消，则需支付取消费",
        "Book_later_toolTip": "如果在接载时间之前取消预订，则需支付取消费",
        "Drv_Book_now_toolTip": "如果实时订单在接受时间后的规定时间内取消，则需支付取消费",
        "Drv_Book_later_toolTip": "如果预订订单在预计接载时间之前的规定时间内取消，则需支付取消费",
        "Free_cancel": "免费取消期限",
        "Hide_cancel_btn": "在设定时间后隐藏取消按钮",
        "Driver_cancellation": "司机取消",
        "Time_day": "每天次数",
        "Duration": "持续时间",
        "Times": "时间",
        "Book_later": "预约订单",
        "Intercity": "专线",
        "Intercity_tooltip": "如果在接送时间之前的规定时间内取消专线订单，则需支付取消费。",
        "MDISPATCHER_NO_SHOW": "NOSHOW",
        "AIRPORT_FEE": "机场费",
        "From_airport": "接机",
        "To_airport": "送机",
        "MEET_DRIVER": "司机举牌",
        "On_curb": "在外等候",
        "Meet_Greet": "司机举牌",
        "Add": "添加",
        "Edit": "编辑",
        "DELETE_SURCHARGE": "删除附加费",
        "DELETE_SERVICE": "删除服务",
        "CHANGE_APPLY_SERVICE_FEES": "变更适用服务费",
        "DELETE_CONFIRM": "你确定要删除这个项目吗？",
        "DELETE_SURCHARGE_CONFIRM": "您确定要删除所选的附加费吗？",
        "DELETE_ADDITIONAL_SERVICES_CONFIRM": "您确定要删除所选服务吗？",
        "CHANGE_APPLY_SERVICE_FEES_CONFIRM_CUSTOM": "您确定要对不同的区域应用不同的费用吗？",
        "CHANGE_APPLY_SERVICE_FEES_CONFIRM_ALL": "您确定要对所有区域应用相同的费用吗？",
        "EDIT_SERVICE_FEES": "编辑服务费",
        "EDIT_SERVICE_FEES_CONFIRM": "您确定要离开而不保存 %{currentZone} 的设置吗？",
        "HEAVY_TRAFFIC": "交通繁忙",
        "Heavy_traffic_surcharge": "交通繁忙附加费",
        "TOLL_FEE": "路桥费",
        "parkingFee": "Parking fee",
        "gasFee": "Gas fee",
        "OTHER_FEES": "其他费用",
        "Can_edit": "能够编辑",
        "Can_add_note": "可以添加备注",
        "Other_fees_amount": "其他费用金额",
        "TAX": "税",
        "Tax_surcharge": "附加税",
        "TIP": "小费",
        "Default_tip": "默认小费",
        "RESERVATION_REMINDER": "预约提醒",
        "Driver_app_SMS_alert_before": "司机App - 提前短信和提醒",
        "Pax_app_alert_before": "乘客app -之前提醒",
        "Confirm_driver_info_via_email_before": "之前向客户发送电子邮件/短信预订确认",
        "Confirm_driver_info_via_email_before_hint": "系统将根据您设定的时间，在接送时间之前向客户发送预订确认信息。",
        "Show_request_tab_on_driver_app": "在司机App上显示“请求”选项卡",
        "Show_preferred_tab_on_driver_app": "在司机App上显示“首选”选项卡",
        "Sound_notification": "声音通知",
        "Advance_information": "企业乘客额外信息",
        "Load_general_fail": "加载常规设置时发生错误",
        "Update_general_success": "常规设置已成功更新",
        "Update_general_fail": "更新常规设置时发生错误",
        "Time_over_lap": "时间范围不得相互重叠",
        "Allow_passenger_to_register_as_corp_traveler": "允许乘客注册为企业用户",
        "Not_Allow_Make_Booking_Without_Assign_Fare": "如果没有配置价格包，则不在 PWA 和App上显示车型（v4.6.60 及更高版本）",
        "Not_Allow_show_car_type_on_cc": "如果没有配置价格包，则不会在控制中心上显示车型",
        "Disable_Direct_Invoicing_Receipt_Email": "禁用月结收据",
        "Additional_services": "额外服务",
        "Edit_additional_services": "编辑附加服务",
        "Service_Fee_By_Currencies": "服务费",
        "Service_type": "服务类型",
        "Optional": "可选的",
        "Compulsory": "强制",
        "Vehicle_type": "车辆类型",
        "Adding_additional_service_success": "添加服务成功",
        "Update_additional_service_success": "更新服务成功",
        "Delete_additional_service_success": "服务已删除成功",
        "Adding_additional_service_fail": "添加服务失败",
        "Update_additional_service_fail": "更新服务失败",
        "Other_settings": "其他设置",
        "Operator_assign_error": "套用操作员错误",
        "Create_surcharge_success": "创造附加费成功",
        "Update_surcharge_success": "更新附加费成功",
        "Delete_surcharge_success": "删除附加费成功",
        "Create_surcharge_fail": "创建附加费失败",
        "Update_surcharge_fail": "更新附加费失败",
        "Delete_surcharge_fail": "删除附加费失败",
        "Get_surcharge_detail_fail": "获取附加费细节失败",
        "Repeat": "复发",
        "DayOfWeek": "星期几",
        "SingleDay": "单日",
        "Yearly": "每年",
        "Backup_phone_number": "备用电话号码",
        "Backup_phone_number_hint": "司机和乘客不在操作区域时直接拨打此号码",
        "Apply_countdown_clock": "应用倒计时",
        "No_show_duration_tooltip": "在司机 App上点击“到达”按钮后，设置NOSHOW倒计时按钮",
        "Limit_radius": "限制半径",
        "Limit_radius_tooltip": "司机可以将在此半径范围内的订单报告为NOSHOW",
        "Limit_radius_arrive_pickup": "限制半径以让司机点击到达和接载订单",
        "Booking_validation": "订单验证",
        "Invalid_period_of_time": "无效时间段",
        "hide_Provider_Filter_From_Corporate_Board": "从公司管理页面隐藏服务商过滤器",
        "customer_email_is_optional": "乘客App的客户电子邮件是可选的",
        "disable_hourly_rate": "当按时计费和固定收费均可用时禁用按时计费",
        "customer_gender_is_compulsory": "乘客App上必须输入客户性别",
        "SHOW_MAP_STATUS": "显示地图状态",
        "Pay_to": "付款至",
        "EMERGENCY_SOS": "紧急SOS",
        "customer_address_is_compulsory": "乘客App上必须提供客户地址",
        "customer_id_is_compulsory": "乘客App必须提供客户的 ID#/国家 IC#",
        "allowPassengerToUploadDocument": "允许乘客上传 ID# / 国家 IC# 文件",
        "compulsoryNationalIcDocumentInPaxApp": "乘客App上必须提供客户的 ID# / 国家 IC# 文件",
        "doNotAllowAddDriverWhenNationalIdDuplicated": "当身份证重复时不允许操作员添加司机",
        "forceCustomerToUseLiveLocation": "强制用户使用实时位置",
        "disableMarketPlace": "新司机不可使用订单市集",
        "dontShowSecondStageImmediately": "隐藏第 2 阶段注册",
        "compulsoryCarInsurance": "车辆保险是强制性的",
        "marketPlace": "订单市集",
        "Enable": "可以",
        "Disable": "禁用",
        "showTotalFareWithoutPromotionOnDriverApp": "在司机App上显示无促销的总票价",
        "showTotalFareWithoutPromotionOnDriverApp_hint": "出行约车：在司机付款之前应用此设置。 <br/> - 送货和专线：在司机开始工作之前应用此设置。",
        "hideFareOnPaxAppForAllCorporateBookings": "在乘客App上隐藏所有企业订单的票价",
        "hidePaxNameAvatarOnDispatchScreen": "司机调度屏幕上隐藏客户姓名和头像",
        "showFullCustomerNameOnDriverApp": "在司机App显示完整的客户姓名",
        "hideDriverLogoutBtn": "隐藏司机App上的注销按钮",
        "showMeetAndGreetButtonForEveryBooking": "Show Meet & Greet button for every booking",
        "settings": "设置",
        "Default_value": "默认值",
        "limitTopupAmount": {
            "valueByCurrencies": "警告金额",
            "minimumByCurrencies": "最低充值金额",
            "maximumByCurrencies": "最大充值金额",
            "title": "最小和最大充值金额"
        },
        "driverApp": "司机App",
        "passengerApp": "乘客App",
        "Default_Topup_Values": "默认充值金额",
        "service_fees": "服务费",
        "commission_fleet": "默认运营商佣金",
        "apply_all": "所有操作区均相同",
        "apply_custom_zone": "区分所有运营区域",
        "apply": "套用",
        "tollFeeDriverCanInput": "限制司机可以输入金额",
        "unlimited": "无限",
        "limited": "有限",
        "sameFee": "费用相同",
        "customizedAirportfee": "定制机场费",
        "noteAirportFeeModal": "注意：机场费仅适用于列表中的机场。",
        "intercity_schedule_trip_time_range": "为确保预订时间的顺序，请确保首选/请求行程的时间范围大于预定行程",
        "airportFee": {
            "tooltipApply": "默认机场费将适用于每次往返机场的行程。 如果定制机场费用，则该地点将适用新的费用。",
            "titleSearchAiportZone": "请搜索您的机场并添加您的费用",
            "airport": "机场",
            "currency": "货币",
            "fromAirportFee": "接机费",
            "toAirportFee": "送机费",
            "action": "操作"
        },
        "Yes": "是",
        "No": "否",
        "tipPaymentMethod": "乘客可以在下单后向司机小费的付款方式",
        "tipDefaultValues": "完成订单后小费的默认值",
        "creadit": "信用卡（个人卡/公司卡）",
        "wallet": "钱包",
        "tnGeWallet": "TnG电子钱包",
        "vippseWallet": "VIPPS",
        "DurationRegularBooking": "持续时间（标准订单）",
        "googlePay": "Google Pay",
        "applePay": "Apple Pay",
        "DurationRegularBookingToolTip": "司机点击司机App的“到达”按钮后，将显示“NOSHOW”按钮。",
        "noShowCountDown": {
            "customizedNoShow": "针对特定地点的定制（例如机场...）",
            "customizedNoShowTitle": "对特定地点（例如机场...）NOSHOW倒计时持续时间"
        },
        "maxWaitTime": "Max extra wait time",
        "maxWaitTimeToolTip": "This is the maximum extra waiting time that will be charged to the customer. You have the flexibility to customize the per-minute fee in your rate settings.",
        "maxWaitTimePlaceholder": "Maximum charge wait time",
        "HEAT_MAP": "需求图",
        "heatMap": {
            "defaultMode": "默认模式",
            "radius": "半径",
            "intensity": "强度",
            "opacity": "不透明度",
            "requestedBookings": "请求订单",
            "activePassengers": "活跃乘客",
            "completedBookings": "已完成订单",
            "timeoutBookings": "超时订单",
            "tooltipRadius": "每个数据点的影响半径（以像素为单位）。",
            "tooltipIntensity": "热图的最大强度。",
            "tooltipOpacity": "热图的不透明度，表示为 0 到 1 之间的数字。"
        },
        "schedule_trip": "安排行程",
        "driver_starts_trip_before_set_time": "司机在设定时间之前开始行程",
        "request_trip": "请求行程",
        "preferred_trip": "首选行程",
        "time_range": "请求的时间范围和首选行程",
        "working_time": "工作时间",
        "hour": "小时",
        "hours": "小时",
        "minutes": "分钟",
        "indicate_pickup_time_in_advance_to_customer": "提前向客户告知接送时间。",
        "customers_can_select_start_time_and_request_intercity_trip": "客户可以选择出发时间并请求专线。 司机还可以根据自己的时间表创建自己喜欢的行程。 示例：1 小时的时间范围允许客户和司机创建从上午 8:00 - 9:00、9:00 - 10:00 或 10:00 - 11:00 开始的专线 。",
        "this_setting_limits_customers_to_create_request_trip_within_working_hours_only": "此设置限制客户只能在工作时间内创建请求行程。",
        "face_mask_verify": "口罩验证",
        "faceMask_allZones": "所有区域",
        "faceMask_customized": "定制专区",
        "turn_on_verify": "开启验证",
        "force_to_wear_face_mask": "强制戴口罩",
        "time_range_to_verify": "请勿再次验证",
        "customizedFaceMaskZone": "定制口罩验证",
        "zoneOperation": "运营区域",
        "FaceMaskCustomized": "定制",
        "time": "时间",
        "day": "天",
        "days": "天",
        "allow_driver_to_skip_verification_after": "允许司机在之后跳过验证",
        "driver_need_to_verify": "当司机需要验证时",
        "open_app": "打开应用程序",
        "start_reservation": "开始预订",
        "choose_at_least_one": "您必须至少选择一项操作才能继续。",
        "face_mask_customized_fail": "您必须至少选择一项操作才能继续 ({0})。",
        "vehicleAgeLimit": "车龄限制",
        "vehicleAgeLimit_years": "年",
        "founderFund_firstBooking": "创始人基金：仅限首次预订",
        "distance_diversity": "距离分集",
        "Add_Surcharge_Distance": "添加",
        "from": "从",
        "to": "到",
        "surchargeValue": "附加费",
        "actions": "操作",
        "please_enter_distance_range_valid": "距离 %{name} 无效！",
        "please_enter_distance_to": "请输入距离“到”",
        "please_enter_surchargeValue": "请为距离范围 %{name} 输入距离“附加费”",
        "please_enter_surchargeValue_is_number": "请输入距离范围 %{name} 的有效数字“附加费”",
        "Update_service_fee_success": "服务费已成功更新",
        "Update_service_fee_fail": "更新服务费用时发生错误",
        "Update_fleet_commission_success": "默认运营商佣金已成功更新",
        "Update_fleet_commission_fail": "更新默认运营商佣金时发生错误",
        "RESERVATION_POLICY": "预订政策",
        "default_expected_pickup_time": "默认预计接载时间",
        "default_expected_pickup_time_hint": "您期望司机接载时间的默认值。",
        "same_original_pickup_time": "与原定接送时间相同",
        "quarter_before": "15分钟前",
        "half_before": "30分钟前",
        "quarter_after": "15分钟后",
        "half_after": "30分钟后",
        "custom": "定制",
        "min_before": "分钟之前",
        "min_after": "分钟后",
        "TransactionFee": "交易费",
        "creditCardPercent": "信用卡/预付卡",
        "directBilling": "月结",
        "externalCard": "External Card"
    },
    "social": {
        "pax_app": "乘客App",
        "driver_app": "司机App",
        "default": "默认",
        "customized": "定制",
        "html": "HTML",
        "about": "关于",
        "email": "电子邮件支持",
        "enablePhone": "电话号码",
        "phone": "",
        "policy": "链接隐私政策",
        "termOfUse": "链接使用条款",
        "site": "网站",
        "logoDriverIOS": "标志-IOS",
        "logoDriverAndroid": "标志 - 安卓",
        "htmlUrl": "HTML 文件",
        "Update_fail": "更新社交时发生错误",
        "Update_success": "社交已更新成功"
    },
    "user": {
        "Show_per_page": "每页项目",
        "Page": "页",
        "Search_here": "在这里搜索",
        "show_pages": "显示了 {0} - {1} 个（共 {2} 个）",
        "Username": "用户名",
        "Name": "名称",
        "Phone_number": "电话号码",
        "Email": "电子邮件",
        "Permission": "权限",
        "Status": "状态",
        "Actions": "操作",
        "Active": "启用",
        "Inactive": "停用",
        "Edit": "编辑",
        "Deactivate": "停用",
        "Delete": "删除",
        "Add": "添加",
        "ADD_USER": "添加用户",
        "First_name": "姓氏",
        "Last_name": "名字",
        "AgendId": "用户#",
        "Email_address": "电子邮件地址",
        "Address": "地址",
        "Required_fields": "必填字段",
        "Save": "保存",
        "Cancel": "取消",
        "EDIT_USER": "编辑用户",
        "Yes": "是",
        "DELETE_USER": "删除用户",
        "DETAIL_USER": "详细用户",
        "DELETE_CONFIRM": "您确定要删除所选用户吗？",
        "Update_user_fail": "更新用户时发生错误",
        "Update_user_success": "用户已更新成功",
        "Create_user_fail": "创建用户时发生错误",
        "Create_user_success": "帐户 {0} 已成功创建。 请检查电子邮件 {1} 以获取登录凭据详细信息。",
        "Load_user_fail": "加载用户时发生错误",
        "Update_user_status_fail": "更新用户状态时发生错误",
        "Delete_user_fail": "删除用户时发生错误",
        "Delete_user_success": "该用户已成功删除",
        "CONFIRM_RESET_PASSWORD": "您想重置密码吗？",
        "RESET_PASSWORD": "您想重置密码吗？",
        "Reset_password": "重设密码",
        "Select_permission": "选择权限",
        "Reset_password_success": "重置密码成功"
    },
    "report": {
        "query": {
            "errorMessage": {
                "bookFrom": "请选择订单",
                "paymentMethod": "请选择付款方式",
                "bookingType": "请选择订单类型",
                "ratingType": "请选择评级类型",
                "stars": "请选择评级类型",
                "type": "类型",
                "transactionType": "请选择交易类型",
                "serviceType": "请选择服务类型",
                "rideService": "请选择乘车服务",
                "invalidEmail": "请输入有效的电子邮件地址。"
            },
            "search": {
                "driverReferalPax": "搜索：司机姓名、电话号码",
                "passengerRefersPassengers": "搜索：客户姓名、电话号码",
                "driverReferalPaxDetail": "搜索：乘客姓名",
                "bookingDetails": "搜索：訂單ID，操作員，客戶電話，客戶姓名，司機姓名，駕駛證/許可證，車牌，排隊區域，備注",
                "financial": "搜索：司机姓名、电话",
                "completedTime": "完成时间",
                "collectedTime": "采集时间",
                "financialDriver": "搜索：订单 ID、司机姓名、ID#/国家身份证#",
                "carActivity": "搜索： 车牌",
                "affiliation": "搜索：订单 ID",
                "prepaidTopUp": "搜索：供应商名称、充值ID、交易ID",
                "operatorLogs": "搜索：操作员名称、用户名、描述",
                "corporate": "搜索：订单 ID、司机姓名、公司用户、客户案例、费用代码",
                "bookingLogs": "搜索：订单 ID、客户姓名、支持 ID、司机姓名、ID#/国家 IC#",
                "rating": "搜索：订单 ID、客户电话、客户姓名、车牌号",
                "mDispatcher": "搜索：订单 ID、合作伙伴名称",
                "totalAdjustment": "搜索：订单 ID",
                "incidentCancellation": "搜索：订单 ID、客户电话、支持 ID、客户姓名",
                "acceptanceCancellationRate": "搜索：司机姓名、电话号码",
                "revenueDriver": "搜索： 司机姓名",
                "dailyDriver": "搜索：司机电话、司机姓名、ID# / 国家 IC#",
                "dailyCar": "搜索：车牌、许可",
                "dailyDriverCar": "Search:  Driver phone, Driver name, ID# / National IC#, License plate",
                "driverLoginStatus": "Search:  Driver phone, Driver name, ID# / National IC#, License plate",
                "bookingSummary": "搜索：操作员名、用户名、订单 ID",
                "creditWallet": "搜索：供应商名称、司机姓名、订单 ID、交易 ID、操作员",
                "cashWallet": "搜索：供应商名称、司机姓名、订单 ID、电话号码",
                "settlement": "搜索：司机姓名、ID# / 国家 IC#",
                "driverRating": "搜索：订单 ID、客户电话、客户姓名、车牌号、支持 ID",
                "driverWithdrawal": "司机姓名、电话、供应商、交易 ID、参考编号",
                "penaltyCompensation": "搜索：订单 ID、司机姓名、乘客姓名",
                "incompletePayment": "搜索：订单 ID、支持 ID、客户姓名",
                "passengerWallet": "搜索：订单 ID、交易 ID、客户姓名",
                "driverDeposit": "搜索：供应商名称、司机姓名、订单 ID、操作员",
                "refund": "搜索：订单 ID、行程 ID、支持 ID、客户姓名",
                "driverRefersCustomerBookings": "搜索：推荐代码、支持 ID",
                "driverHistory": "搜索：付款 ID",
                "merchantHistory": "搜索：付款 ID",
                "firstWillWin": "搜索：客户姓名、客户电话、客户 ID",
                "firstWillWinDriver": "搜索：司机姓名、司机电话、用户名",
                "merchant": "搜索： 商店",
                "merchantCashTransactionHistory": "搜索：订单 ID、交易 ID、商户名称",
                "merchantCreditTransactionHistory": "搜索：订单 ID、交易 ID、商户名称",
                "deleteAccountRequest": "搜索： 电话",
                "affiliationPayoutHistory": "搜索：付款 ID",
                "thirdPartyBooking": "搜索：订单 ID、外部 ID"
            },
            "common": {
                "all": "全部"
            },
            "today": "今天",
            "sendDownloadLink": "收件人电子邮件地址",
            "generaltingYourExcel": "请注意，生成 Excel 文件可能需要一些时间。 一旦准备好，我们会立即通过电子邮件发送给您。",
            "messageSuccess": "文件正在进行中。 准备好后您会收到一封电子邮件。 跟踪进度或直接从报告/导出历史记录下载",
            "thisMonth": "这个月",
            "lastMonth": "上个月",
            "past30Days": "过去 30 天",
            "thisYear": "今年",
            "custom": "自定义",
            "customize": "定制",
            "thisWeek": "本周",
            "past7Days": "过去 7 天",
            "weekly": "每周",
            "monthly": "每月",
            "allTime": "全部时间",
            "tomorrow": "明天",
            "nextWeek": "下周",
            "lastWeek": "上周",
            "nextMonth": "下个月",
            "yesterday": "昨天",
            "fromDate": "从日期",
            "toDate": "至日期",
            "driver": "司机：%{name}",
            "customer": "乘客姓名: %{name}",
            "referralCode": "推荐码：%{name}",
            "referer": "推荐人姓名:%{name}",
            "referee": "被推荐人员：%{name}",
            "usedPromoCode": "促销：%{name}",
            "voucherCode": "代码: %{name}",
            "city": "城市",
            "company": "车队供应商",
            "company_report": "供应商：%{name}",
            "campaign_report": "活动：%{name}",
            "operator": "操作员",
            "farmType": "供应类型",
            "carLicensePlate": "车辆车牌: %{name}",
            "settlementType": "类型",
            "bookingService": "网络类型",
            "payoutOption": "支付选项",
            "currency": "货币",
            "bookingFrom": "预订自",
            "thirdPartyAction": "操作",
            "paymentMethod": "付款方式",
            "paymentStatus": "支付状态",
            "bookingType": "订单类型",
            "adjustPrice": "调整价格",
            "intercityRoute": "专线",
            "ratingType": "评级类型",
            "stars": "评分",
            "driverRating": "司机评级",
            "vehicleRating": "车辆评级",
            "mDispatcherType": "合作伙伴类型",
            "mDispatcher": "合作伙伴名称",
            "campaign": "活动",
            "promocode": "促销代码",
            "canceledBy": "取消日期：",
            "timezone": "时区",
            "month": "月",
            "year": "年",
            "view": "查看",
            "action": "操作",
            "status": "状态",
            "module": "模块",
            "driverType": "类型",
            "vehicleType": "车型",
            "serviceType": "服务类型",
            "expireDate": "到期日",
            "rideSharing": "拼车",
            "rideService": "乘车服务",
            "transactionType": "交易类型",
            "completeFromCC": "由客服完成",
            "withDrawalTransactionType": "交易类型",
            "bookingStatus": "订单状态",
            "withdrawalStatus": "状态",
            "originalStatus": "原始付款状态",
            "currentStatus": "当前付款状态",
            "tip": "小费",
            "type": "类型",
            "userType": "用户类型",
            "eventType": "事件类型",
            "eventState": "事件状态",
            "eventName": "活动名称",
            "merchantType": "商户类型",
            "merchantService": "商户服务",
            "storeName": "店铺",
            "merchant": "商号",
            "event": "事件名称：%{name}",
            "store": "商店名称：%{name}",
            "selectedMerchant": "商家名称}",
            "associatedApps": "关联应用程序",
            "settlement": "清算",
            "rideServiceItem": {
                "all": "全部",
                "regulation": "常规",
                "rideSharing": "拼车"
            },
            "actionItem": {
                "all": "全部",
                "login": "登录",
                "logout": "登出",
                "bookIn": "可用的",
                "bookOff": "无法使用",
                "deny": "拒绝",
                "ignore": "忽略",
                "accept": "接受",
                "startTrip": "在途中",
                "updateBooking": "更新",
                "arrive": "到达",
                "pickup": "接载",
                "drop": "送达",
                "complete": "完成",
                "noShow": "NOSHOW",
                "rqJob": "调度",
                "cancel": "取消"
            },
            "loginStatusItem": {
                "all": "全部",
                "online": "已登录",
                "offline": "未登录"
            },
            "payoutType": "支付类型",
            "affiliationPayoutTypeItem": {
                "auto": "自动付款",
                "manual": "手动支付"
            },
            "affiliationSettlementItem": {
                "payable": "应付",
                "receivable": "应收账款"
            },
            "affiliationPaymentMethod": {
                "online": "在线的",
                "directInvoicing": "月结"
            },
            "payoutPaymentStatusItem": {
                "paid": "已付",
                "pending": "待付"
            },
            "farmTypeItem": {
                "all": "全部",
                "farmIn": "接收外单",
                "farmOut": "订单外发",
                "roaming": "漫游",
                "affiliationOwner": "联盟服务商",
                "farmInAndAffiliationOwner": "接受联盟供应",
                "farmOutAndAffiliationOwner": "发出联盟服务商"
            },
            "settlementDriverTypeItem": {
                "all": "所有司机",
                "individual": "公司车辆"
            },
            "shortTrip": {
                "all": "全部",
                "shortTrip": "短行程",
                "nonShortTrip": "非短行程"
            },
            "paymentMethodItem": {
                "B2BTerminal": "B2B终端",
                "webBooking": "网上预订",
                "pendingPayment": "待付款",
                "all": "全部",
                "cash": "现金",
                "cashBySender": "发件人付现",
                "cashByRecipient": "收件人付现",
                "personalCard": "个人卡",
                "corporateCard": "企业卡",
                "qrCodePayment": "QR码付款",
                "directBilling": "月结",
                "externalCard": "外部卡",
                "mDispatcherCard": "合作伙伴卡",
                "corporatePrepaid": "企业预付",
                "canceled": "取消",
                "noShow": "NOSHOW",
                "incident": "事故",
                "fleetCard": "外部卡",
                "applePay": "Apple Pay",
                "creditCardExternal": "信用卡",
                "paxWallet": "钱包",
                "tngeWallet": "TnG电子钱包",
                "vippseWallet": "VIPPS",
                "zainCash": "ZAINCASH",
                "xendit": "Xendit",
                "FIB": "FIB",
                "SantimPay": "SantimPay",
                "gCash": "GCASH",
                "completedWithoutService": "使用外部司机完成订单",
                "BankOfGeorgia": "Georgia银行",
                "partialCompleted": "部分付款",
                "failed": "失败的",
                "googlePay": "Google Pay",
                "paymentLink": "支付链接",
                "easyPaisa": "Easy Paisa",
                "jazzCash": "Jazz Cash"
            },
            "paymentStatusItem": {
                "all": "全部",
                "fullPayment": "全额付款",
                "partialPayment": "部分付款",
                "pendingPayment": "待付款",
                "partialRefund": "退还部分款项",
                "fullRefund": "全额退款"
            },
            "companySettlementTypeItem": {
                "all": "全部",
                "fleetOweCompany": "运营商欠供应商",
                "companyOweFleet": "供应商欠运营商"
            },
            "bookingStatusItem": {
                "all": "全部",
                "completed": "完成",
                "incident": "事故",
                "paxNoShow": "乘客NOSHOW",
                "driverNoShow": "司机NOSHOW",
                "rejected": "拒绝",
                "canceledByTimeOut": "因超时而取消",
                "canceledByTimeOutRejected": "因超时取消（拒绝）",
                "canceledByBookingBoard": "被预订平台取消",
                "canceledByCustomer": "被客户取消",
                "canceledBySupplier": "被供应商取消"
            },
            "bookingFromItem": {
                "all": "全部",
                "paxApp": "乘客App",
                "mDispatcher": "个人伙伴",
                "kiosk": "自助机",
                "cc": "控制中心",
                "webBooking": "网上预订",
                "carHailing": "网约车",
                "streetSharing": "街道街头拼车",
                "dashboard": "预订板",
                "corp": "预订板 (CTM)",
                "dmc": "预订板 (DMC)",
                "Boost_web_link": "BOOST链接"
            },
            "thirdPartyActionItem": {
                "all": "全部",
                "searched": "已搜索",
                "allocated": "已分配",
                "updated": "更新",
                "cancelled": "取消"
            },
            "bookingTypeItem": {
                "batchDelivery": "批量发货",
                "all": "全部",
                "now": "实时订单（出行）",
                "reservation": "预约订单（出行）",
                "intercity": "专线",
                "delivery": "送货",
                "shuttle": "穿梭"
            },
            "adjustPrices": {
                "all": "全部",
                "normalPrice": "正常价格",
                "addonPrice": "附加价格"
            },
            "bookingServiceItem": {
                "all": "全部",
                "roaming": "漫游",
                "homeFleet": "对外发单",
                "localBooking": "本地预订",
                "farmIn": "接收外单",
                "farmInAndAffiliationOwner": "提供服务和联盟服务商",
                "provideService": "提供服务和联盟服务商"
            },
            "ratingTypeItem": {
                "all": "全部",
                "like": "喜欢",
                "dislike": "不喜欢"
            },
            "starsItem": {
                "all": "全部",
                "star1": "1 星",
                "star2": "2 星",
                "star3": "3 星",
                "star4": "4 星",
                "star5": "5 星"
            },
            "incidentCanceledTypes": {
                "all": "全部",
                "incident": "事故",
                "canceled": "取消"
            },
            "canceledByItem": {
                "all": "全部",
                "CorpAD": "企业管理员",
                "passenger": "乘客",
                "driver": "司机",
                "merchant": "商号",
                "CC": "控制中心",
                "mDispatcher": "个人伙伴",
                "Partner": "个人伙伴",
                "webBooking": "网上预订",
                "timeout": "超时"
            },
            "bookingSummaryActionItem": {
                "all": "全部",
                "create": "创建",
                "update": "更新",
                "cancel": "取消",
                "complete": "完成",
                "incident": "事故"
            },
            "transactionTypeItem": {
                "bankAccount": "转入银行账户",
                "sentToCustomer": "发送给客户",
                "receivedFromDriver": "收到司机发来的信息",
                "sentToDriver": "发送给司机",
                "creditWallet": "转移至信用钱包",
                "netEarning": "净收入",
                "referralEarning": "推荐收入",
                "all": "全部",
                "topUp": "充值",
                "editBalance": "编辑余额",
                "bookingDeduction": "订单扣除",
                "bookingPromo": "预订促销",
                "topUpGCash": "通过GCash充值",
                "cashWallet": "现金钱包",
                "topUpMOLPay": "通过 Razer Pay 充值",
                "topUpZainCash": "通过 ZainCash 充值",
                "topUpXendit": "Top up by Xendit",
                "topUpFIB": "Top up by FIB",
                "topUpSantimPay": "Top up by SantimPay",
                "topUpTelebirr": "通过 Telebirr 充值",
                "topUpKsher": "由 Ksher 充值",
                "topUpWallet": "{0}充值",
                "TnG": "通过 TnG 充值",
                "credit": "通过信用卡充值",
                "ride": "用车",
                "tipAfterCompleted": "用车后小费",
                "debtWriteOff": "欠款冲销",
                "refund": "退款",
                "excessOffsetAmount": "超过现金信用",
                "excessDeductionAmount": "超过现金借记",
                "cashExcess": "超过现金借记",
                "gateway": "从网关充值",
                "driverTopUpForPax": "超过现金借记",
                "updatedBalance": "更新余额",
                "partialEarning": "收入",
                "payout": "支出",
                "payoutManual": "付款手册",
                "payoutDriver": "付款",
                "itemValue": "项目值",
                "redeemVoucher": "兑换码#",
                "topUpReferrer": "推荐奖励",
                "driverInsurance": "司机保险",
                "cancellationPenalty": "取消罚款",
                "WingBank": "转账至 Wingbank 账户",
                "customerDebt": "客户欠款",
                "googlePay": "通过 Google Pay 充值",
                "applePay": "通过 Apple Pay 充值",
                "merchantCommission": "商户佣金",
                "eWallet": "Transferred to external e-wallet",
                "reversed": "Withdrawal reversed"
            },
            "moduleItem": {
                "all": "全部",
                "bookings": "预订",
                "customers": "客户",
                "dispatch": "调度",
                "general": "一般",
                "promotion": "促销",
                "quest": "任务",
                "rate": "价格包",
                "car": "车辆",
                "city": "城市",
                "company": "供应商",
                "drivers": "司机",
                "driverSettlement": "司机结算",
                "login": "登录",
                "logout": "登出",
                "mDispatcher": "伙伴",
                "mDispatcherType": "合作伙伴类型",
                "operation": "运营",
                "partners": "合作伙伴",
                "permission": "权限",
                "queuingArea": "排队区",
                "report": "报告",
                "shift": "排班",
                "user": "用户",
                "voiceSms": "语音和短信",
                "intercityZone": "区域（专线）",
                "Payout": "支付",
                "code#": "代码 ＃",
                "ApiManagement": "API管理"
            },
            "serviceTypeItem": {
                "all": "全部",
                "oneWay": "单程",
                "fromAirport": "接机",
                "toAirport": "送机",
                "hourly": "按时计费",
                "roundTrip": "往返",
                "fromAirportHourlyDaily": "接机 - 按时计费",
                "fromAirportRoundTrip": "接机 - 往返",
                "toAirportHourlyDaily": "送机 - 按时计费",
                "toAirportRoundTrip": "送机 - 往返"
            },
            "merchantTransactionTypeItem": {
                "all": "全部",
                "editBalance": "编辑余额",
                "payout": "支付",
                "earning": "收入",
                "itemValue": "物品价值",
                "orderDeduction": "订单扣除"
            },
            "withdrawalTransactionType": {
                "WingBank": "转入Wingbank账户",
                "bankAccount": "转入银行账户",
                "cashWallet": "转入现金钱包",
                "creditWallet": "转移至信用钱包"
            },
            "payoutDriver": {
                "driversHaveBankAccount": "司机有银行账户",
                "driversDontHaveBankAccount": "司机没有银行账户",
                "payoutEndTime": "支付结束时间",
                "driverHaveInvalidBankAccount": "司机的银行账户无效"
            },
            "payoutMerchant": {
                "HaveBankAccount": "商户有银行账户",
                "DontHaveBankAccount": "商户没有银行账户",
                "HaveInvalidBankAccount": "商户银行账户无效",
                "payoutEndTime": "支付结束时间"
            }
        },
        "error": {
            "23005": "由于优化系统性能，您只能查看 %{interval} %{unitLabel} 内的报告，请调整您的日期范围"
        },
        "result": {
            "idPermitType": "证件类型",
            "noResultFound": "没有找到结果",
            "page": "页",
            "itemsPerPage": "每页项目",
            "showPages": "显示了 %{from} - %{to}，共 %{total}",
            "exportToExcel": "导出至 Excel",
            "exportToCSV": "导出为 csv",
            "exportToPaymentFile": "导出至付款文件",
            "pay": "支付",
            "paySelected": "支付选择",
            "payAll": "全部付清",
            "invalidBankAccount": "银行账户无效",
            "validBankAccount": "有效的银行账户",
            "bookingDetails": {
                "successSendMessage": "您的留言已发送",
                "packageName": "包裹名字",
                "transactionId": "交易ID",
                "proofOfFailure": "失败证明",
                "recipientsNumber": "收件人人数",
                "recipientsInfo": "收件人信息",
                "creditTransactionFee": "信用卡费用",
                "carTypeRequest": "请求的车型",
                "bookingId": "订单#",
                "bookingStatus": "订单状态",
                "tripId": "行程＃",
                "bookFrom": "预订自",
                "bookingService": "网络类型",
                "bookingType": "订单类型",
                "serviceType": "服务类型",
                "operator": "操作员",
                "customerPhone": "乘客电话号码",
                "customerName": "乘客姓名",
                "customerIdNumber": "乘客 ID# / IC#",
                "corpDivision": "处",
                "corpDept": "部门",
                "chargeCode": "收费代码",
                "tripDescription": "行程描述",
                "rideSharing": "拼车",
                "queueName": "排队区",
                "bookingTime": "创建时间",
                "pickupTime": "接载时间",
                "expectedPickupTime": "预计接载时间",
                "dispatchTime": "调度时间",
                "onBoardTime": "上车时间",
                "droppedOffTime": "送达时间",
                "onBoardDuration": "行程持续时间",
                "dispatch": "调度 ＃",
                "completedTime": "完成时间",
                "driverName": "司机姓名",
                "driverIdPermit": "ID# / 国家身份证#",
                "driverLicenseNumber": "驾驶执照号码",
                "companyName": "供应商",
                "carType": "车型",
                "licensePlate": "车牌",
                "intercityRoute": "专线",
                "pickupLocation": "出发地址",
                "geoPickup": "出发地址经纬度",
                "extraDestination": "额外目的地",
                "originDestination": "原目的地",
                "originEstDistance": "原始估计距离",
                "destination": "实际目的地",
                "geoDestination": "实际目的地经纬度",
                "pickupFrom": "出发地址",
                "distanceTour": "行程距离",
                "internalNotes": "内部备注",
                "notes": "备注",
                "basicFareCalculator": "基本车费计算器",
                "basicFare": "基本车费",
                "editFare": "编辑基本车费",
                "oldBasicFare": "原基本车费",
                "airportFee": "机场费",
                "meetDriver": "举牌",
                "rushHour": "附加费",
                "surchargeParameter": "动态附加费",
                "dynamicFare": "动态票价",
                "heavyTraffic": "交通繁忙",
                "tollFee": "路桥费",
                "parkingFee": "Parking fee",
                "gasFee": "Gas fee",
                "otherFees": "其他费用",
                "subtotal": "小计",
                "subTotalFF": "小计 FF",
                "techFee": "技术费",
                "serviceFee": "额外服务",
                "fleetServiceFee": "车队服务",
                "bookingFee": "预订费",
                "salesTax": "销售税",
                "tipAmt": "小费金额",
                "promoCode": "促销代码",
                "promoAmt": "促销金额",
                "requestedTotalAmount": "要求的总金额",
                "total": "总金额",
                "totalNotRounded": "回合（总金额）",
                "actualReceived": "实际收到",
                "differenceAmt": "差异（收到）",
                "customerDebt": "客户欠款",
                "markupDifference": "金额差别",
                "receivedWhenDropOff": "下车时收到",
                "refundAmount": "退款金额",
                "requestedPaymentMethod": "请求的付款方式",
                "paidBy": "由...支付",
                "splitPayment": "分账付款",
                "paidByWallet": "通过钱包支付",
                "paidByOtherMethod": "通过其他方式支付",
                "cashReceived": "收到现金",
                "transferredChangeByWallet": "通过钱包转账找零",
                "returnedChangeByCash": "现金找零",
                "instructions": "指示",
                "additionalServiceName": "姓名",
                "additionalServiceValue": "价值",
                "sendReceiptHeader": "收据页眉",
                "sendReceipt": "发送收据",
                "send": "发送",
                "cancel": "取消",
                "sentReceiptSuccess": "收据已成功发送",
                "sentReceiptFailed": "目前无法发送收据。 请再试一次。",
                "coRider": "拼车者",
                "coriderName": "姓名",
                "stopAddress": "额外目的地",
                "stopGeo": "纬度/经度",
                "addonPrice": "客户报价",
                "oldSubTotal": "调整前小计",
                "view_good_receipt": "货物收据",
                "view_delivery_receipt": "发货照片",
                "view": "查看",
                "tipAfterCompletedDetails": "已转移：{0}{1}； 交易费用：{0}{2}",
                "fleetMarkup": "利润",
                "editedDriverEarning": "编辑司机收入",
                "driverEarning": "司机收入",
                "pickUpZoneName": "接送区",
                "dynamicFactor": "Dynamic fare (factor)"
            },
            "financial": {
                "driverName": "司机姓名",
                "driverPhone": "电话号码",
                "driverUserName": "用户名",
                "companyName": "供应商",
                "bookingList": "订单列表",
                "totalFare": "不含促销的总票价",
                "netEarning": "净利润",
                "showBookingList": "显示",
                "referralEarning": "推荐收入",
                "totalEarningNet": "总收入（净额）",
                "totalEarningNetTooltip": "将所有费用退还给运营商后您赚取的钱。 总收入（净收入）=净收入+行程后小费",
                "totalEarningNetTooltipRefferal": "将所有费用退还给运营商后您赚取的钱。 总收入（净收入）=推荐收入+净收入+乘车后小费",
                "totalEarningGross": "总收入（毛额）",
                "totalEarning": "总收入",
                "totalEarningGrossTooltip": "您通过订单车费和小费赚取的钱。 总收入（毛收入） = 毛收入 + 行程后小费",
                "totalEarningGrossTooltipRefferal": "您通过订单车费和小费赚取的钱。 总收入（毛收入）= 推荐收入 + 毛收入 + 乘车后小费",
                "note": "备注",
                "shortTrip": "短行程"
            },
            "financialDriver": {
                "supplierAmount": "Supplier commission",
                "payoutType": "支付类型",
                "date": "日期",
                "driverTipAfterCompleted": "行程后小费",
                "bookingId": "订单ID",
                "paidBy": "由...支付",
                "bookFrom": "预订自",
                "bookingType": "订单类型",
                "companyName": "供应商",
                "driverName": "司机",
                "driverIdPermit": "ID# / 国家身份证#",
                "totalFare": "不含促销的总票价",
                "subtotal": "小计",
                "airportFee": "机场费",
                "meetDriver": "举牌",
                "tollFee": "路桥费",
                "parkingFee": "Parking fee",
                "gasFee": "Gas fee",
                "rushHour": "高峰时间",
                "transactionFee": "交易费",
                "techFee": "技术费",
                "salesTax": "销售税",
                "tipAmt": "小费金额",
                "bookingFee": "预订费",
                "commission": "佣金",
                "deduction": "扣除",
                "promoAmt": "促销金额",
                "ridePayment": "乘车支付",
                "driverTax": "司机税",
                "grossEarning": "毛收入",
                "driverDeduction": "司机扣除",
                "netEarning": "净利润",
                "payToDriver": "支付给司机",
                "payToFleet": "支付给运营商",
                "totalNotRounded": "回合（总金额）",
                "fleetServiceFee": "运营商服务费",
                "editedDriverEarning": "编辑司机收入",
                "stripeFee": "Stripe Fee"
            },
            "financialCompany": {
                "companyName": "供应商",
                "homeFleetNetEarning": "运营商净利润",
                "affiliateNetEarning": "联营公司净利润",
                "totalNetEarning": "司机净收入总额",
                "status": "状态",
                "companyAmount": "供应商利润"
            },
            "companySettlement": {
                "companyName": "供应商",
                "pendingSettlement": "待结算",
                "viewDetails": "查看详情",
                "settlementType": "类型"
            },
            "companySettlementDetails": {
                "bookId": "订单ID",
                "dateTime": "时间日期",
                "originalPaymentStatus": "原始付款状态",
                "subTotal": "小计",
                "fleetAmount": "运营商金额",
                "driverAmount": "司机金额",
                "companyAmount": "供应商金额",
                "companyOweFleet": "供应商欠运营商",
                "fleetOweCompany": "运营商欠供应商",
                "notes": "备注"
            },
            "financialProfit": {
                "profit": "利润",
                "bookingId": "订单#",
                "subTotal": "小计",
                "subTotalFF": "小计 FF",
                "fleetCommission": "运营商佣金",
                "techFee": "技术费",
                "promotion": "促销",
                "markupDifference": "金额差异",
                "Profit": "利润",
                "currency": "货币",
                "fleetServiceFee": "运营商服务（付运营商）",
                "fleetServiceFeeTooltip": "运营商从服务费中收取佣金的金额"
            },
            "promotion": {
                "date": "日期",
                "promoCode": "促销代码",
                "users": "用户",
                "uses": "用途",
                "checked": "已检查",
                "applied": "应用",
                "totalPromoAmount": "促销总额",
                "totalReceipt": "总收据",
                "numberOfUsers": "用户数量",
                "numberOfPromoCodeAppliance": "促销代码设备数量",
                "numberOfSuccessfulBookings": "成功订单数量",
                "numberOfCompletedBookings": "已完成订单数量",
                "numberOfCompletedUsers": "已完成用户数",
                "numberOfUsersApplyPromotion": "申请促销的用户数",
                "numberOfUsersApplyPromotionAndCompleteBooking": "申请促销并完成预订的用户数",
                "numberOfPromoCodeApplianceTooltip": "所有用户使用促销代码的数量",
                "numberOfSuccessfulBookingsTooltip": "使用促销代码成功创建的订单数量",
                "numberOfCompletedBookingsTooltip": "使用促销代码完成的订单数量"
            },
            "export": {
                "createdDate": "日期",
                "sessionName": "会话名称",
                "download": "下载",
                "delete": "删除",
                "percent": "百分",
                "status": "状态",
                "name": "模块名称"
            },
            "redeemed": {
                "date": "日期",
                "code": "代码",
                "customers": "顾客",
                "phone": "电话号码",
                "value": "价值",
                "currency": "货币",
                "campaignName": "推广活动"
            },
            "carActivity": {
                "plateNumber": "车辆车牌",
                "vehicleType": "车型",
                "companyName": "供应商",
                "day1st": "1日",
                "day2nd": "2日",
                "day3rd": "3日",
                "day4th": "4日",
                "day5th": "5日",
                "day6th": "6日",
                "day7th": "7日",
                "day8th": "8日",
                "day9th": "9日",
                "day10th": "10日",
                "day11th": "11日",
                "day12th": "12日",
                "day13th": "13日",
                "day14th": "14日",
                "day15th": "15日",
                "day16th": "16日",
                "day17th": "17日",
                "day18th": "18日",
                "day19th": "19日",
                "day20th": "20日",
                "day21st": "21日",
                "day22nd": "22日",
                "day23rd": "23日",
                "day24th": "24日",
                "day25th": "25日",
                "day26th": "26日",
                "day27th": "27日",
                "day28th": "28日",
                "day29th": "29日",
                "day30th": "30日",
                "day31st": "31日",
                "operationDays": "运营日",
                "activeCars": "启用车辆总数："
            },
            "carActivityLogsTitle": "日志",
            "carActivityLogs": {
                "licensePlate": "车辆车牌",
                "companyName": "供应商",
                "bookingId": "订单ID",
                "driverName": "司机姓名",
                "driverIDNumber": "ID# / 国家身份证#",
                "date": "受理时间",
                "bookFrom": "预订自"
            },
            "affiliation": {
                "date": "接机时间",
                "bookingId": "订单ID",
                "bookingType": "订单类型",
                "homeFleet": "运营商",
                "providerFleet": "供应商车队",
                "driver": "司机",
                "passenger": "乘客",
                "paidBy": "由...支付",
                "airportFee": "机场费",
                "meetDriver": "举牌",
                "rushHour": "附加费",
                "tollFee": "路桥费",
                "parkingFee": "Parking fee",
                "gasFee": "Gas fee",
                "heavyTraffic": "交通繁忙",
                "otherFees": "其他费用",
                "provider": "供应商",
                "basicFareProvider": "基本车费提供商",
                "subTotalProvider": "供应商小计",
                "techFeeProvider": "技术费提供商",
                "bookingFeeProvider": "预订费提供商",
                "taxValueProvider": "税值提供者",
                "tipProvider": "小费提供者",
                "totalFareProvider": "总票价提供商",
                "basicFare": "基本车费",
                "subtotal": "小计",
                "techFee": "技术费",
                "bookingFee": "预订费",
                "tax": "税",
                "tip": "小费",
                "totalFare": "总票价",
                "payoutInLocalCurrency": "以当地货币计算的乘车收入",
                "payoutInUSD": "乘车收入（美元）",
                "fleetCommInUSD": "车队佣金（美元）",
                "bookingFeeInUSD": "预订费以美元计算",
                "totalPayoutInUSD": "总支出（美元）",
                "subTotal": "小计",
                "bookingStatus": "订单状态",
                "vehicle": "车辆",
                "customerPhone": "客户电话号码",
                "driverPhone": "司机电话号码",
                "total": "全部的",
                "totalInUSD": "总票价（美元）",
                "supplierPayout": "供应商付款",
                "supplierPayoutInUSD": "供应商付款（美元）",
                "buyerPayout": "买家佣金",
                "buyerPayoutInUSD": "佣金（美元）",
                "buyerCommission": "佣金",
                "buyerCommissionInUSD": "佣金（美元）",
                "receivableInUSD": "应收账款（美元）",
                "payableInUSD": "应付金额（美元）",
                "fleetMarkupInUSD": "利润（美元）",
                "paymentMethod": "付款方式",
                "paymentStatus": "支付状态"
            },
            "prepaidTopUp": {
                "date": "日期时间",
                "topUpId": "充值ID",
                "transactionId": "交易ID",
                "name": "名称",
                "company": "供应商",
                "paidBy": "由...支付",
                "amount": "数量",
                "newAmount": "新余额",
                "transactionType": "交易类型"
            },
            "operatorLogs": {
                "date": "日期",
                "operator": "操作员",
                "username": "用户名",
                "module": "模块",
                "description": "描述"
            },
            "corporate": {
                "date": "日期",
                "bookingId": "订单ID",
                "operatorName": "企业操作员",
                "corporateName": "供应商",
                "corpTraveler": "企业用户",
                "travelerSignature": "乘客签名",
                "corpId": "公司编号",
                "corpDivision": "集团事业部",
                "costCentre": "成本中心",
                "corpPO": "公司采购订单",
                "managerName": "经理姓名",
                "managerEmail": "经理的电子邮件",
                "title": "职衔",
                "department": "部门",
                "clientCaseMatter": "客户案例/行程描述",
                "chargeCode": "费用代码/行程代码",
                "driverName": "司机",
                "pickupLocation": "出发地",
                "destination": "目的地",
                "total": "总金额",
                "subtotal": "小计",
                "subTotalFF": "小计 FF",
                "commission": "佣金",
                "commissionBooking": "从 app/pwa 进行预订申请佣金",
                "paidBy": "由...支付",
                "statusDisplay": "状态",
                "flightNumber": "航班号"
            },
            "bookingLogs": {
                "bookingId": "订单ID",
                "bookingService": "网络类型",
                "bookingType": "订单类型",
                "driverName": "司机",
                "driverIdPermit": "ID# / 国家身份证#",
                "customerName": "乘客",
                "pickupTime": "接机时间",
                "onBoardTime": "上车时间",
                "droppedOffTime": "送达时间",
                "pickupLocation": "上车位置",
                "destination": "目的地",
                "dispatchHistory": "调度历史",
                "otwDistance": "往出发地距离",
                "distanceTour": "行程距离",
                "duration": "行程时长",
                "paidBy": "由...支付",
                "bookFrom": "预订自",
                "map": "地图",
                "showDispatchHistory": "显示",
                "showMap": "显示",
                "paymentActivities": "支付活动"
            },
            "rating": {
                "date": "日期",
                "bookingId": "订单ID",
                "driverName": "司机",
                "driverIdPermit": "ID# / 国家身份证#",
                "customerName": "乘客姓名",
                "customerPhone": "客户电话",
                "like": "喜欢",
                "dislike": "反感",
                "notes": "评论",
                "stars": "评分",
                "vehicleRating": "车辆评级",
                "driverRating": "司机评级",
                "plateNumber": "车牌号码"
            },
            "mDispatcher": {
                "date": "日期",
                "bookingId": "订单ID",
                "mDispatcherName": "合作伙伴名称",
                "mDispatcherType": "合作伙伴类型",
                "subtotal": "小计",
                "commission": "佣金",
                "paidBy": "由...支付"
            },
            "totalAdjustment": {
                "date": "日期",
                "bookingId": "订单ID",
                "oldAmount": "全部的",
                "type": "类型",
                "markupDifference": "金额差别",
                "newAmount": "编辑总计/加价",
                "username": "操作员",
                "reason": "原因"
            },
            "incidentCancellation": {
                "date": "日期",
                "bookingId": "订单ID",
                "customerName": "乘客姓名",
                "customerPhone": "客户电话",
                "bookFrom": "预订自",
                "canceledBy": "取消日期：",
                "type": "类型",
                "reason": "原因",
                "reasonCode": {
                    "0": "我找到了另一种行程选择",
                    "1": "司机距离太远",
                    "2": "我输入了错误的位置",
                    "3": "我等了太久",
                    "4": "司机要求取消",
                    "5": "我改变了计划",
                    "6": "我想改变位置",
                    "101": "大部分或全部商品不可用",
                    "102": "我们现在太忙了",
                    "103": "我们现在关门了",
                    "104": "我们即将关门"
                },
                "canceller": "取消人"
            },
            "acceptanceCancellationRate": {
                "companyId": "供应商ID",
                "driverId": "司机 ID",
                "driverName": "司机姓名",
                "phoneNumber": "电话号码",
                "company": "供应商",
                "acceptanceRate": "接单率",
                "cancellationRate": "取消率",
                "rating": "评分"
            },
            "revenueFleet": {
                "date": "日期",
                "fareRevenue": "车费收入",
                "trip": "行程",
                "avgFareTrip": "平均车费/行程",
                "drivers": "# 司机",
                "avgTripPerDriver": "平均行程/司机",
                "dispatcher": "# 调度员"
            },
            "revenueDriver": {
                "date": "日期",
                "driverName": "司机",
                "fareRevenue": "车费收入",
                "trip": "＃ 行程",
                "avgFareTrip": "平均车费/行程"
            },
            "revenueMonthly": {
                "month": "月",
                "trip": "已完成行程",
                "subtotal": "小计",
                "tipAmt": "小费",
                "techFee": "技术费",
                "salesTax": "税",
                "partnerCommission": "合作伙伴佣金/ODD",
                "totalFare": "不含促销的总票价",
                "netEarning": "净利润",
                "fleetServiceFee": "运营商服务费"
            },
            "dailyDriver": {
                "driverName": "司机",
                "driverIDPermit": "ID# / 国家身份证#",
                "driverPhone": "电话",
                "companyName": "供应商",
                "workingHoursOnline": "在线（小时）",
                "workingHoursAvailable": "接单（小时）",
                "driverWorkingHours": "司机工作时间",
                "distance": "司机行驶距离",
                "CC_activity": "控制中心活动",
                "fare_summary": "车费概况",
                "workingHoursNotAvailable": "不接单（小时）",
                "workingHoursPob": "载客（小时）",
                "workingHoursPobToolTip": "载客时长",
                "workingHoursAccepted": "接单",
                "workingHoursReject": "拒绝",
                "workingHoursIgnore": "忽略",
                "workingHoursCancel": "取消",
                "completedTripsTotal": "合计",
                "completedTripsCc": "来自控制中心",
                "completedTripsCarHailing": "从网约车",
                "completedTripsStreetSharing": "来自街头拼车",
                "completedTripsPaxApp": "来自乘客App",
                "completedTripsWebBooking": "从网上预订",
                "completedTripsMDispatcher": "来自合作伙伴",
                "completedTripsKiosk": "从自助机",
                "completedTripsApi": "来自API",
                "ccActivityDispatchedTrips": "调度行程",
                "ccActivityAccepted": "接单",
                "ccActivityReject": "拒绝",
                "ccActivityIgnore": "忽略",
                "ccActivityCancelByPassenger": "被乘客取消",
                "ccActivityCancelByDriver": "被司机取消",
                "ccActivityCancelByCc": "控制中心取消",
                "ccActivityCancelByAPI": "通过 API 取消",
                "ccActivityNoShow": "NOSHOW",
                "fareSummaryTotalCash": "现金",
                "fareSummaryTotalCreditCard": "信用卡",
                "fareSummaryTotalExternalCard": "外置卡",
                "fareSummaryTotalPaxWallet": "钱包",
                "fareSummaryTotalDirectBilling": "月结",
                "fareSummaryTotalQrCode": "二维码",
                "fareSummaryTotalPrePaid": "企业预付",
                "fareSummaryTotalApplePay": "Apple Pay",
                "fareSummaryTotalTngeWallet": "TNG电子钱包",
                "fareSummaryTotalVippseWallet": "VIPPS",
                "fareSummaryTotal": "全部的",
                "ratingNotes": "备注",
                "ratingDislike": "不喜欢的数量",
                "ratingLike": "喜欢的数量",
                "fareDetailsBasicFare": "基本车费",
                "fareDetailsAirportFee": "机场费",
                "fareDetailsRushHour": "附加费",
                "fareDetailsHeavyTraffic": "交通繁忙",
                "fareDetailsOtherFees": "其他费用",
                "fareDetailsSubTotal": "小计",
                "fareDetailsSubTotalFF": "小计 FF",
                "fareDetailsTechFee": "技术费",
                "fareDetailsBookingFee": "预订费",
                "fareDetailsSalesTax": "销售税",
                "fareDetailsTip": "小费",
                "fareDetailsPromoAmount": "促销金额",
                "fareDetailsTotalAmt": "总金额",
                "availableDistance": "可用的",
                "otwDistance": "往出发地",
                "otwDistanceToolTip": "往出发地距离",
                "pobDistance": "载客",
                "pobDistanceToolTip": "载客距离",
                "ratingStars1": "1星",
                "ratingStars2": "2星",
                "ratingStars3": "3星",
                "ratingStars4": "4星",
                "ratingStars5": "5星",
                "ratingAvg": "平均的",
                "ssnNumber": "社会安全号#",
                "driverEmail": "电子邮件地址",
                "driverAddress": "家庭地址",
                "fareDetailsAddonPrice": "客户报价",
                "fareDetailsOldSubTotal": "调整前小计"
            },
            "dailyCar": {
                "carInfo": "车辆信息",
                "fareDetails": "车费详情",
                "completedTrips": "已完成行程",
                "carInfoCompanyName": "供应商",
                "carInfoPermission": "权限",
                "carInfoVehiclePlateNumber": "车牌号",
                "carInfoVhcId": "车辆 ＃",
                "carInfoCarType": "车型",
                "completedTripsTotal": "全部的",
                "completedTripsCc": "来自控制中心",
                "completedTripsCarHailing": "从网约车",
                "completedTripsStreetSharing": "来自街头拼车",
                "completedTripsPaxApp": "来自乘客App",
                "completedTripsWebBooking": "来自网络预订",
                "completedTripsMDispatcher": "来自合作伙伴",
                "completedTripsKiosk": "从自助机",
                "completedTripsAPI": "来自API",
                "fareDetailsBasicFare": "基本车费",
                "fareDetailsAirportFee": "机场费",
                "fareDetailsMeetDriver": "举牌",
                "fareDetailsRushHour": "附加费",
                "fareDetailsHeavyTraffic": "交通繁忙",
                "fareDetailsTollFee": "路桥费",
                "fareDetailsParkingFee": "Parking fee",
                "fareDetailsGasFee": "Gas fee",
                "fareDetailsOtherFees": "其他费用",
                "fareDetailsSubTotal": "小计",
                "fareDetailsSubTotalFF": "小计 FF",
                "fareDetailsTechFee": "技术费",
                "fareDetailsBookingFee": "预订费",
                "fareDetailsSalesTax": "销售税",
                "fareDetailsTip": "小费",
                "fareDetailsPromoAmount": "促销金额",
                "fareDetailsTotalAmt": "总金额",
                "fareDetailsAddonPrice": "客户报价",
                "fareDetailsOldSubTotal": "调整前小计"
            },
            "dailyDriverCar": {
                "driverInfo": "司机信息",
                "driverName": "司机姓名",
                "driverIDPermit": "ID# / 国家身份证#",
                "driverPhone": "电话号码",
                "companyName": "供应商",
                "actionInfo": "操作",
                "date": "日期",
                "action": "操作",
                "bookingId": "订单ID",
                "carInfo": "车辆信息",
                "permission": "权限",
                "licensePlate": "车牌",
                "carId": "车辆#",
                "carType": "车型"
            },
            "driverLoginStatus": {
                "driver": "司机",
                "driverIdPermit": "ID# / 国家身份证#",
                "phone": "电话号码",
                "username": "用户名",
                "company": "供应商",
                "carType": "车型",
                "licensePlate": "牌照",
                "status": "状态"
            },
            "bookingSummary": {
                "date": "日期",
                "operator": "操作员",
                "username": "用户名",
                "action": "操作",
                "bookingId": "订单ID"
            },
            "cashWallet": {
                "bankAccount": "转入银行账户",
                "creditWallet": "转移至信用钱包",
                "tipAfterCompleted": "完成后提示",
                "netEarning": "净利润",
                "referralEarning": "推荐收入",
                "receiverAccount": "收款账户",
                "dateAndTime": "日期时间",
                "driverName": "司机姓名",
                "phoneNumber": "电话号码",
                "userName": "用户名",
                "company": "供应商",
                "type": "类型",
                "bookingId": "订单#",
                "operator": "操作员",
                "reason": "原因",
                "amount": "金额",
                "newAmount": "新余额",
                "currency": "货币",
                "topUpGCashReason": "通过 Gcash 充值",
                "topUpReason": "通过信用卡充值 *{0}",
                "cashWalletReason": "从现金钱包充值",
                "topUpMOLPayReason": "Razer Pay [ {0} ]",
                "chargedAmount": "收费金额",
                "differentPercent": "折扣/额外费用 (%)",
                "operatorName": "操作员",
                "sendPayout": "操作",
                "sendPayoutTitle": "重新发送付款电子邮件",
                "payoutReason": "{0}（付款 {1} {2}）"
            },
            "creditWallet": {
                "receiverType": "接收人类型",
                "receiverName": "接收人姓名",
                "receiverPhone": "接收人电话号码",
                "senderPhone": "发送人电话号码",
                "senderName": "发送人名称",
                "sentToCustomer": "发送给客户",
                "sentToDriver": "发送给司机",
                "dateAndTime": "日期时间",
                "driverName": "司机姓名",
                "phoneNumber": "电话号码",
                "userName": "用户名",
                "company": "供应商",
                "type": "类型",
                "bookingId": "订单#",
                "operator": "操作员",
                "reason": "原因",
                "amount": "数量",
                "newAmount": "新余额",
                "currency": "货币",
                "transactionId": "交易ID",
                "topUpGCashReason": "通过 Gcash 充值",
                "topUpReason": "通过信用卡充值 *{0}",
                "cashWalletReason": "从现金钱包充值",
                "topUpMOLPayReason": "Razer Pay [ {0} ]",
                "topUpWalletReason": "{0} [ {1} ]",
                "chargedAmount": "收费金额",
                "differentPercent": "折扣/额外费用 (%)",
                "cashExcessReason": "客户必须支付{0}； 收到现金{1}",
                "referralEarningReason": "客户 {0} {1} 完成了首次预订 {2}",
                "referralEarningReason_2": "客户{0} {1}已完成预订{2}",
                "insurance": "司机保险"
            },
            "passengerWallet": {
                "dateTime": "日期时间",
                "customerName": "乘客姓名",
                "phoneNumber": "电话号码",
                "type": "类型",
                "bookingId": "订单#",
                "changedAmount": "变更金额",
                "amount": "变更金额",
                "newBalance": "新余额",
                "currency": "货币",
                "transactionId": "交易ID",
                "Note": "备注",
                "credit": "通过信用卡充值 *{0}",
                "TnG": "由 TnG #{0} 充值",
                "MOLPay": "从 [RazerPay - #{0}] 充值",
                "gatewayName": "从 [{0} - #{1}] 充值",
                "cashExcess": "客户必须支付 {0}，给予现金 {1}",
                "editBalance": "编辑余额",
                "ride": "行程",
                "topUp": "充值",
                "topUpReferee": "推荐奖励 ({0})",
                "topUpReferrer": "新被推荐人 (#{0})：{1} ({2})"
            },
            "recipientsInfo": {
                "order": "号码",
                "orderId": "号码#",
                "name": "收件人姓名",
                "phone": "收件人电话#",
                "address": "收件人地址",
                "deliveryFee": "快递费",
                "ItemValue": "商品价值（货到付款）",
                "status": "状态",
                "Performer": "执行人",
                "Reason": "原因",
                "proofOfFailure": "失败证明",
                "referenceNumber": "参考编号"
            },
            "dispatchHistory": {
                "performer": "执行人",
                "name": "姓名",
                "action": "操作",
                "date": "日期",
                "location": "司机所在位置",
                "estimate": "车辆到出发地估计",
                "passenger": "顾客",
                "detachVehicle": "Detach Vehicle",
                "assignVehicle": "Assign Vehicle"
            },
            "payToDriver": {
                "driverName": "司机",
                "totalUnsettledTransactions": "未结算交易总额",
                "totalUnsettledAmount": "未结算总额",
                "actions": "操作",
                "pay": "支付",
                "remove": "消除",
                "clearSuccess": "选定司机的收入已成功结清。",
                "clearFailed": "清除司机收入数据失败。 请再试一次。",
                "selectDriver": {
                    "title": "",
                    "body": "请选择支付司机。",
                    "buttonTitle": "好"
                },
                "confirmClear": {
                    "title": "",
                    "body": "您确定要清除该司机的收入数据吗？ 此操作无法撤消。",
                    "buttonTitle": "是",
                    "closeButtonText": "否"
                },
                "confirmPay": {
                    "body": "您确定要向选定的司机付款吗？",
                    "buttonTitle": "是",
                    "closeButtonText": "否"
                },
                "paymentSuccessfully": "付款成功支付给司机"
            },
            "customerQuest": {
                "name": "姓名",
                "phoneNumber": "电话号码",
                "supportId": "支持 ID",
                "ranking": "排行",
                "points": "积分",
                "numberOfReservation": "预约数量",
                "driver": "司机",
                "customer": "顾客",
                "firstWillWin": "先者为胜",
                "firstWillWinLuckyDraw": "先者为胜（幸运抽奖）",
                "largestWillWin": "最大者获胜（排行榜）",
                "qualified": "合格的",
                "notQualified": "不合格",
                "userName": "用户名",
                "completedDate": "完成日期",
                "isQualified": "状态",
                "ticket": "票",
                "numOfComplete": "已完成数量",
                "bookingDistance": "载客距离",
                "numberOfIntercity": "专线数量",
                "numberOfDelivery": "发货数量",
                "pleaseSelectEventName": "请选择活动名称。",
                "numberOfQualifiedSetting": "合格数（设）：",
                "numberOfActualQualified": "实际合格人数：",
                "driverRating": "司机评级",
                "acceptanceRating": "接单评级",
                "cancellationRating": "取消评级",
                "period": "时期："
            },
            "payoutToDriver": {
                "IFSCCode": "IFSC 代码",
                "payoutID": "付款ID",
                "driverName": "司机",
                "driverPhone": "电话号码",
                "companyName": "供应商",
                "period": "时期",
                "driverId": "ID# / 国家身份证#",
                "cashWalletBalance": "现金钱包余额",
                "creditBalance": "信用额度",
                "holdAmount": "保留金额",
                "minPayout": "最少支付",
                "enterMinimumPayoutAmount": "输入最低付款金额",
                "payoutAmount": "支付金额",
                "payoutDate": "付款日期",
                "actions": "操作",
                "pay": "支付",
                "remove": "消除",
                "payoutEndTime": "支付结束时间",
                "clearSuccess": "选定司机的收入已成功结清。",
                "clearFailed": "清除司机收入数据失败。 请再试一次。",
                "amountKeepInDriverCashWallet": "司机现金钱包中保留的金额",
                "selectDriver": {
                    "title": "",
                    "body": "请选择支付司机。",
                    "buttonTitle": "OK"
                },
                "confirmClear": {
                    "title": "",
                    "body": "您确定要清除该司机的收入数据吗？ 此操作无法撤消。",
                    "buttonTitle": "是",
                    "closeButtonText": "否"
                },
                "confirmPay": {
                    "body": "您确定要向选定的司机付款吗？ <br> - 司机数量：<b>%{drivers}</b> <br> - 每个司机的最低支出：%{currency} <b>%{min}</b> <br> - 保留 金额：%{currency}<b> %{hold}</b> <br> - 支出总额：%{currency} <b>%{total}</b>",
                    "buttonTitle": "是",
                    "closeButtonText": "否"
                },
                "paymentSuccessfully": "付款成功支付给司机",
                "paymentCompleted": "付款已完成：<br> - 付款成功：%{success} <br> - 失败：%{failed} <br> - 成功付款总额：%{currency} %{total}",
                "noteSelectDrivers": "注意：选择有银行账户付款的司机。",
                "sendPayout": "发送付款成功通知至电子邮件地址",
                "accountNumber": "帐号",
                "iBanNumber": "IBAN号码",
                "accountHoldName": "账户持有人名称",
                "bankName": "银行名",
                "selelctedDriverHasBeenPaid": "选定的司机已付款",
                "selelctedDriverHasBeenFailed": "所选司机失败",
                "selelctedDriverHasBeenUpdateAsInvalid": "所选司机已更新为“无效银行帐户”",
                "selelctedDriverHasBeenUpdateAsValid": "所选司机已更新为“有效银行帐户”",
                "isBankAccountOwner": "受益人与司机相同",
                "isBankAccountOwner_true": "是",
                "isBankAccountOwner_false": "否",
                "beneficiaryIDIC": "受益人 ID# / IC#",
                "bankRelationship": "关系",
                "bankRelationship_1": "配偶",
                "bankRelationship_2": "女儿/儿子",
                "bankRelationship_3": "兄弟姐妹",
                "bankRelationship_4": "有关系的",
                "bankRelationship_5": "朋友们",
                "bankRelationship_6": "其他：{0}"
            },
            "payoutToMerchant": {
                "payoutId": "付款ID",
                "merchantName": "商家名称",
                "phone": "电话号码",
                "accountNumber": "帐号",
                "iBanNumber": "IBAN号码",
                "bankAccountHolder": "账户持有人名称",
                "bankName": "银行名",
                "pendingAmount": "待付款金额",
                "payoutAmount": "支付金额",
                "payoutDate": "付款日期",
                "holdAmount": "保留金额",
                "minPayout": "最少支付",
                "selelctedMerchantHasBeenPaid": "所选商户已付款",
                "selelctedMerchantHasBeenFailed": "所选商户已失败",
                "pay": "支付",
                "selectMerchant": {
                    "title": "警告",
                    "body": "请选择商户进行支付。",
                    "buttonTitle": "好的"
                },
                "confirmPay": {
                    "body": "您确定要向选定的商户付款吗？ <br> - 商家数量：<b>%{merchants}</b> <br> - 每个司机的最低支出：%{currency} <b>%{min}</b> <br> - 保留 金额：%{currency}<b> %{hold}</b> <br> - 支出总额：%{currency} <b>%{total}</b>",
                    "buttonTitle": "是",
                    "closeButtonText": "否"
                },
                "sendPayout": "发送付款成功通知至电子邮件地址",
                "HasBeenUpdateAsInvalid": "所选商户已更新为“银行账户无效”",
                "HasBeenUpdateAsValid": "所选商户已更新为“有效银行账户”"
            },
            "settlementHistory": {
                "date": "日期",
                "driverName": "司机姓名",
                "dateRange": "日期范围",
                "totalTransaction": "已结算交易总额",
                "paidAmount": "总结算金额"
            },
            "settlement": {
                "driverName": "司机",
                "driverIdPermit": "ID# / 国家身份证#",
                "totalBookings": "总订单",
                "total": "总计",
                "approved": "经核准的",
                "acct": "ACCT",
                "gross": "总值",
                "percent": 0.03,
                "net": "净"
            },
            "documentExpiry": {
                "driverName": "司机姓名",
                "driverPhone": "司机电话",
                "company": "供应商",
                "documentName": "文件名称",
                "expiredDate": "过期日期",
                "status": "状态",
                "statusStr": {
                    "NA": "不适用",
                    "expired": "已到期",
                    "active": "启用",
                    "expireSoon": "即将到期"
                }
            },
            "referralDetail": {
                "bookingId": "订单#",
                "completedDate": "完成时间",
                "paidBy": "由...支付",
                "bookFrom": "预订自",
                "driverName": "司机姓名",
                "driverIdPermit": "ID# / 国家身份证#",
                "companyName": "供应商",
                "Passenger": "乘客",
                "Customer": "顾客",
                "subtotal": "小计",
                "subTotalFF": "小计 FF",
                "subtotalWithPromo": "促销小计",
                "referralEarning": "推荐收入",
                "Amount": "数量",
                "Note": "备注",
                "Currency": "货币",
                "PercentOfSubTotal": "小计的 {0}%",
                "max": "最大{0}",
                "referrerInfo": "司机介绍顾客",
                "phoneReferral": "司机电话#",
                "driverNumber": "ID# / 国家身份证#",
                "referralCode": "推荐码"
            },
            "referralHistory": {
                "driverName": "司机姓名",
                "phoneNumber": "电话号码",
                "companyName": "供应商",
                "referralCode": "推荐码",
                "commission": "佣金总额",
                "expectedReferralEarning": "期望获得推荐收入",
                "activePaxTooltip": "已完成一趟订单以上且已向司机兑换推荐码的乘客。",
                "referredPaxTooltip": "已向司机兑换推荐码但尚未完成订单的乘客。",
                "activePax": "活跃客户总数",
                "referredPax": "推荐客户总数",
                "detailFields": {
                    "passengerId": "乘客证件",
                    "passengerName": "乘客姓名",
                    "firstBookingDate": "第一个完成预订日期",
                    "firstBookingDateGMT": "第一个完成预订日期（格林威治标准时间）",
                    "passengerPhone": "客户电话号码",
                    "appliedDate": "推荐日期",
                    "referredByDriver": "由司机推荐",
                    "driverPhone": "司机电话号码",
                    "referralCode": "推荐码"
                },
                "showDetail": "展示",
                "detail": "细节"
            },
            "paxReferralHistory": {
                "customerName": "乘客姓名",
                "phoneNumber": "电话号码",
                "referralCode": "推荐码",
                "commission": "佣金总额",
                "activePaxTooltip": "已兑换推荐代码并至少成功完成一次预订的乘客。",
                "referredPaxTooltip": "已兑换推荐代码但尚未预订的乘客。",
                "activePax": "活跃客户总数",
                "referredPax": "推荐客户总数",
                "detailFields": {
                    "passengerId": "乘客证件",
                    "passengerName": "乘客姓名",
                    "firstBookingDate": "第一个完成预订日期",
                    "firstBookingDateGMT": "第一个完成预订日期（格林威治标准时间）"
                },
                "showDetail": "展示",
                "detail": "细节"
            },
            "driverRefersDriver": {
                "companyName": "供应商",
                "phoneNumber": "电话号码",
                "username": "用户名",
                "referralCode": "推荐码",
                "referrer": "推荐人",
                "numberOfReferees": "被推荐人员人数",
                "activeReferees": "活跃被推荐人",
                "referee": "被推荐人",
                "refereePhone": "被推荐人电话",
                "refereeUsername": "被推荐人用户名",
                "referredDate": "推荐日期",
                "activatedDate": "启用日期",
                "referrerByDriver": "推荐人",
                "referrerPhone": "推荐人电话号码",
                "referralStatus": "推荐状态",
                "lifeTimeBookings": "终身订单",
                "inReview": "审核中",
                "activated": "活性"
            },
            "driverWithdrawal": {
                "transferToEWallet": "Transferred to external e-wallet *{0}",
                "channelCode": "Channel code",
                "channelName": "Channel name",
                "reversed": "Reversed",
                "currentBalanceTooltip": "提款请求前司机现金钱包的当前余额",
                "paymentTime": "付款时间",
                "requestTime": "请求时间",
                "referenceId": "参考 ＃",
                "transactionId": "交易ID",
                "description": "描述",
                "transferAmount": "转账金额",
                "currency": "货币",
                "approved": "得到正式认可的",
                "rejected": "拒绝",
                "failed": "失败的",
                "reason": "原因"
            },
            "penaltyCompensation": {
                "penaltyAmount": "惩罚",
                "totalCollect": "需要用当地货币支付",
                "totalCollectInUSD": "需要用美元支付",
                "compensationAmount": "补偿",
                "compensationAmountInUSD": "补偿（美元）",
                "penaltyReason": "处罚理由",
                "estimatedPrice": "预估车费",
                "estimatedPriceInUSD": "预计车费（美元）",
                "penaltyAmountInUSD": "罚款（美元）",
                "amount": "数量",
                "amountInUSD": "金额（美元）"
            },
            "imcompletePayment": {
                "bookingId": "订单ID",
                "bookFrom": "预订自",
                "bookingTime": "预订日期/时间",
                "completedTime": "预订完成的日期/时间",
                "customerName": "乘客姓名",
                "requestedPaymentMethod": "请求的付款方式",
                "originalPaymentStatus": "原始付款状态",
                "currentPaymentStatus": "当前付款状态",
                "updatedDateTime": "余额收款日期/时间",
                "totalAmt": "总金额",
                "originalOutstandingAmount": "原始未偿还金额",
                "currentOutstandingAmount": "当前未偿还金额",
                "outstandingAmountPaidBy": "未付金额收集者",
                "writeOffDebtMethod": "未付金额收集者",
                "reason": "原因",
                "customerPhone": "电话号码",
                "amountCollected": "收集金额",
                "partialPaymentAmount": "送达时支付部分费用"
            },
            "driverDeposit": {
                "dateAndTime": "日期时间",
                "userName": "用户名",
                "type": "类型",
                "newAmount": "新余额",
                "topUpGCashReason": "通过 Gcash 充值",
                "topUpReason": "通过信用卡充值 *{0}",
                "cashWalletReason": "从现金钱包充值",
                "topUpMOLPayReason": "Razer Pay [ {0} ]"
            },
            "refund": {
                "fullRefund": "全额退款",
                "partialRefund": "退还部分款项",
                "tripId": "行程ID",
                "route": "路线",
                "refundAmount": "退款金额",
                "refundTime": "退款时间",
                "paymentStatus": "支付状态"
            },
            "merchant": {
                "store": "店铺",
                "name": "姓名",
                "phoneNumber": "电话号码",
                "bookingId": "订单#",
                "totalOrders": "订单总数",
                "completedOrders": "已完成订单",
                "canceledOrders": "取消订单",
                "incidentOrders": "事故",
                "revenue": "收入",
                "commission": "佣金",
                "earning": "收入",
                "food": "食物",
                "mart": "市场",
                "preferredMerchant": "首选商户",
                "regularMerchant": "正规商户",
                "companyName": "Company"
            },
            "merchantCashTransactionHistory": {
                "companyName": "Company",
                "dateTime": "日期时间",
                "merchantUserName": "商户用户",
                "merchantUserPhone": "电话号码",
                "merchantUserEmail": "电子邮件",
                "merchantName": "商人",
                "transactionType": "类型",
                "bookId": "订单#",
                "transactionId": "交易ID",
                "operatorName": "操作员",
                "reason": "原因",
                "amount": "数量",
                "newBalance": "新余额",
                "currencyISO": "货币"
            },
            "merchantCreditTransactionHistory": {
                "dateTime": "日期时间",
                "merchantUserName": "商户用户",
                "merchantUserPhone": "电话号码",
                "merchantUserEmail": "电子邮件",
                "merchantName": "商人",
                "transactionType": "类型",
                "bookId": "订单#",
                "transactionId": "交易ID",
                "operatorName": "操作员",
                "reason": "原因",
                "amount": "数量",
                "newBalance": "新余额",
                "currencyISO": "货币"
            },
            "deleteAccountRequest": {
                "date": "日期",
                "phone": "电话",
                "username": "用户名",
                "supportId": "支持 ID",
                "requestFrom": "请求来自",
                "associatedApps": "关联应用程序",
                "reason": "原因"
            },
            "affiliationPayoutHistory": {
                "payoutId": "付款ID",
                "dateTime": "日期时间",
                "payoutType": "类型",
                "accountHolderName": "账户持有人名称",
                "bankName": "银行名",
                "accountNumber": "帐号",
                "totalPayout": "付款总额（美元）",
                "viewDetails": "查看详情",
                "notes": "备注",
                "bookId": "订单#",
                "transactionType": "交易类型",
                "transactionId": "交易ID（自动转账）",
                "settlement": "结算",
                "paymentMethod": "类型",
                "networkType": "网络类型"
            },
            "thirdPartyBooking": {
                "date": "日期",
                "bookingId": "订单ID",
                "externalId": "外部ID",
                "psgPhone": "乘客电话号码",
                "psgName": "乘客姓名",
                "pickupLocation": "接人的地方",
                "pickupLocationLatLong": "上车地点纬度/经度",
                "dropoffLocation": "送达地点",
                "dropoffLocationLatLong": "下车地点纬度/经度",
                "pickupTime": "接机时间",
                "numOfPassenger": "乘客人数",
                "action": "操作"
            }
        }
    },
    "zoneSetting": {
        "Add": "添加",
        "Import": "导入",
        "Name": "名称",
        "Status": "状态",
        "Active": "启用",
        "Inactive": "停用",
        "Deactivate": "停用",
        "Activate": "启用",
        "Delete": "删除",
        "Cancel": "取消",
        "Input_zone_name": "输入区域名称",
        "Save_zone": "保存区",
        "Clear_draw": "清除",
        "Edit": "编辑",
        "Delete_zone_success": "区域已成功删除",
        "Delete_zone_fail": "删除区域时发生错误",
        "Update_zone_fail": "更新区域时发生错误",
        "Update_zone_success": "区域已成功更新",
        "Create_zone_fail": "创建区域时发生错误",
        "Create_zone_success": "新区域已成功创建",
        "Draw_zone": "请在地图上画出区域",
        "Import_zones_successful": "导入区域成功",
        "Import_zones_fail": "导入区域失败",
        "DELETE_ZONE": "删除区域",
        "ZONE_CONFIRM": "您确定要删除所选区域吗？",
        "Name_zone_required": "名称和区域为必填项！",
        "Select_file": "选择文件",
        "No": "否",
        "Yes": "是",
        "Import_zones_intercity_successful": "[{0}] 区域已成功导入，[{1}] 失败。",
        "Import_zones_intercity_successful_failed": "[{0}] 区域已成功导入，[{1}] 失败。",
        "Flat_zone": "固定计价区",
        "Json_file": "Json文件",
        "Import_zones": "导入区域",
        "timezone": "时区"
    },
    "companySetting": {
        "DELETE_COMPANY": "删除供应商",
        "DELETE_CONFIRM": "您想删除供应商吗？",
        "DETAIL_COMPANY": "供应商详情",
        "commissionValue": "公司佣金",
        "connectStatus": "STRIPE CONNECT",
        "connectStripe": "链接",
        "stripeConnectId": "STRIPE帐户 ID",
        "loginStripe": "仪表板",
        "disconnectStripe": "断开",
        "Yes": "是",
        "No": "否",
        "Cancel": "取消",
        "EDIT_COMPANY": "编辑供应商",
        "ADD_COMPANY": "添加供应商",
        "Name": "姓名",
        "Required_fields": "必填字段",
        "Save": "保存",
        "Actions": "操作",
        "Edit": "编辑",
        "Add": "添加",
        "Show_per_page": "每页项目",
        "Page": "页",
        "show_pages": "显示了 {0} - {1} 个（共 {2} 个）",
        "Create_company_success": "新供应商创建成功",
        "Create_company_fail": "创建供应商时出现错误",
        "Update_company_success": "供应商已更新成功",
        "Update_company_fail": "更新供应商时发生错误",
        "Load_company_fail": "加载供应商时发生错误",
        "Delete_company_success": "供应商已删除成功",
        "Delete_company_fail": "删除供应商时发生错误",
        "hideFare": "始终隐藏票价",
        "Broadcast_orders": "Broadcast all orders",
        "paymentMethodsApply": "支持付款方式",
        "emailsNotification": "Emails Notification",
        "CopySignUpLink": "Copy sign up link",
        "operationZone": "Operation Zone",
        "delayBroadcast": "Show order after",
        "releaseTime": "Release Time",
        "advanceBookingVisibility": "Advance booking visibility",
        "advanceBookingVisibilityTooltip": "Set the number of days before the pickup date that a supplier can see the booking. This ensures that bookings are released to drivers in a timely manner, based on each supplier."
    },
    "dispatchSetting": {
        "GENERAL": "一般",
        "Offer_interval": "请求间隔（司机）",
        "Offer_interval_merchant": "请求间隔（商户）",
        "Offer_interval_merchant_toolTip": "商户接受订单的最长时间",
        "Offer_interval_toolTip": "司机接受预订的最长时间",
        "soundMarketplace": "声音",
        "sound": "声音",
        "Auto_re_dispatch_interval_toolTip": "2轮调度之间的休息时间",
        "Maximum_auto_dispatch_duration_toolTip": "向司机发送预订的最长时间",
        "Seconds": "秒",
        "Auto_re_dispatch_interval": "自动重新调度间隔",
        "Maximum_auto_dispatch_duration": "最大自动调度持续时间",
        "Time_out": "超时",
        "mins": "分钟",
        "minTimeBeforeReservation": "不允许司机在接载时间之前开始预订",
        "IN_ADVANCE": "预约",
        "Assign_reservation_to_driver": "将订单指派给司机",
        "Dispatcher_confirms_job_for_driver": "调度员为司机确认订单",
        "Dispatcher_confirms_job_for_offline_driver": "调度员为离线司机确认订单",
        "Broadcast_orders": "Broadcast orders to all companies",
        "Broadcast_orders_tooltip_1": "Enable this setting if you just want to broadcast all trips to all company suppliers. \n Keep in mind you need to enable broadcast mode on in each company’s profile.",
        "Minimum_book_ahead": "最少提前预订",
        "Maximum_book_ahead": "最多提前预订 - 单笔订单",
        "Maximum_period_recurring_oders_fleet": "复发订单的最长期限（运营商）",
        "Maximum_period_recurring_oders_corp": "复发订单的最长期限（企业）",
        "Day": "天",
        "Week": "周",
        "Month": "月",
        "hrs": "小时",
        "Driver_cancellation_duration": "司机取消时长",
        "Mins": "分钟",
        "Maximum_Day": "司机一天可以接受的最大预订量。",
        "Maximum_Accept_Reservation": "司机一次可以接受的预订总数。",
        "Local_notification_before": "之前本地通知",
        "Dispatch_mode": "调度方式",
        "Manual": "手动",
        "Immediate_auto_dispatch": "立即自动调度",
        "Delayed_auto_dispatch": "延迟自动调度",
        "Auto_dispatch_before_pickup_time": "接载时间前自动调度",
        "Required_fields": "必填字段",
        "Shuttle": "穿梭",
        "Shuttle_Dispatch_mode": "调度方式",
        "Dispatch_setting_Shuttle_Dispatch_mode_tooltip": "手动模式：一旦创建预订，预订将被保存并保留在指挥中心的排程上，处于“待处理”状态。 在预订详细信息中点击“更新预订”后，预订将发送给您指定的司机或在没有司机分配的情况下发送给所有司机。 它将集成到司机设备日历中并提供提醒。 <br>- 延迟模式：一旦创建预订，预订将被保存并保留在指挥中心的排程上，处于“待处理”状态。 预订的车辆将在接载时间的“提前处理”时间值之前自动调度至指定司机，或在没有司机指定的情况下，自动调度至最近的可用司机。 <br>- 立即自动调度模式：一旦创建预订，预订将自动调度给所有司机。 它将集成到司机设备日历中并提供提醒。",
        "Save": "保存",
        "Process_in_advance": "提前处理",
        "Save_dispatch_success": "调度已成功更新",
        "Save_dispatch_fail": "更新调度时出现错误",
        "Dispatch_setting_general_tooltip": "适用于实时订单，预约订单将延迟自动调度",
        "Dispatch_setting_Assign_reservation_to_driver_tooltip": "启用此选项后，将自动为司机接受预约订单",
        "Dispatch_setting_Driver_cancellation_duration_tooltip": "时长是从接载时间到司机取消预订的时间",
        "Dispatch_setting_Dispatch_mode_tooltip": "手动模式：一旦创建预订，订单将被保存并保留在指挥中心的排程上，处于“待处理”状态。 在预订详细信息中点击“更新预订”后，预订将发送给您指定的司机或在没有司机分配的情况下发送给所有司机。 它将集成到司机设备日历中并提供提醒。 <br>- 延迟模式：一旦创建预订，预订将被保存并保留在指挥中心的 排程上，处于“待处理”状态。 预订的车辆将在接载时间的“提前处理”时间值之前自动调度至指定司机，或在没有司机指定的情况下，自动调度至最近的可用司机。 <br>- 立即自动调度模式：一旦创建预订，预订将自动调度给所有司机。 它将集成到司机设备日历中并提供提醒。",
        "Notification_nearby_drivers_before_pickup_time": "在接载时间之前通知附近的司机",
        "Dispatch_setting_Maximum_Day_tooltip": "这是为了限制司机在同一天预约过多。 适用于市场和专线。",
        "Maximum_Accept_Reservation_tooltip": "司机接受的订单数量不能超过此数量。 这限制了司机一次接受太多。 适用于市场和专线。",
        "Notification_nearby_drivers_before_pickup_time_tooltip": "如果客户的预订在规定时间之前没有被任何司机接受，则在接载时间之前，系统将通知附近的司机。 这有望促使他们检查市场以接受预订。",
        "marketPlace": "MARKET PLACE",
        "howFarDriverSeeBookingMarketplace": "How far out the driver can see bookings in the marketplace",
        "shortNoticeOrder": "Short notice order",
        "shortNoticeOrderTooltip": "Set the time frame for bookings to be considered urgent and visible to all tiers immediately."
    },
    "voiceSMSSetting": {
        "ACCOUNT_SETTINGS": "帐号设定",
        "Account_SID": "账户SID",
        "Auth_Token": "身份验证令牌",
        "Application_ID": "应用程序ID",
        "SMS_number_1": "短信号码1",
        "SMS_number_2": "短信号码2",
        "SMS_SETTINGS": "短信设置",
        "MASKED_PHONE_SETTINGS": "屏蔽电话设置",
        "SMS_MARKETING_SETTINGS": "短信营销设置",
        "Phone_number": "电话号码",
        "Save": "保存",
        "voiceSMS": "TWILIO",
        "DRIVER_APP": "司机App",
        "PASSENGER_APP": "乘客App",
        "allowDriverToCallCC": "允许司机通过以下方式呼叫服务台：",
        "allowDriverToCallHelpdesk": "允许司机通过以下方式呼叫服务台：",
        "allowDriverToCallPassenger": "允许司机通过以下方式呼叫乘客：",
        "allowPassengerToCallCC": "允许乘客通过以下方式致电服务台：",
        "allowPassengerToCallHelpdesk": "允许乘客通过以下方式致电服务台：",
        "allowPassengerToCallDriver": "允许乘客通过以下方式呼叫司机：",
        "voip": "VOIP",
        "mobile": "手机",
        "bookingSMS": "留言模板",
        "bookingSMSTemplate": {
            "name": "名称",
            "content": "内容",
            "action": "操作",
            "DELETE": "删除消息模板",
            "DELETE_CONFIRM": "您确定要删除所选的消息模板吗？",
            "EDIT": "编辑消息模板",
            "ADD": "添加消息模板",
            "DETAIL": "详细信息模板",
            "Update_success": "消息模板已更新成功",
            "Create_success": "消息模板创建成功",
            "Delete_success": "消息模板已成功删除",
            "Delete_fail": "删除消息模板时发生错误",
            "Load_fail": "加载消息模板时发生错误",
            "Update_fail": "更新消息模板时发生错误",
            "Create_fail": "创建消息模板时发生错误"
        },
        "bookingSMSModal": {
            "title": "添加短信模板",
            "save": "保存",
            "cancel": "取消",
            "titleMessage": "发信息",
            "template": "模板",
            "phoneNumber": "电话号码",
            "content": "内容",
            "messageType": "消息类型",
            "inbox": "收件箱",
            "sms": "短信",
            "sendTo": "发给",
            "passenger": "乘客",
            "driver": "司机"
        }
    },
    "regularSetting": {
        "extraWaitTime": "Extra wait time",
        "extraWaitTimeTooltip": "Fee to compensate the driver when he has to wait additional time for the customer till they are onboard.",
        "extraWaitTimeTooltip2": "To set the maximum wait time, go to general settings.",
        "fareWaitTimeLater": "Fare per minute (Reservation)",
        "fareWaitTimeNow": "Fare per minute (Now)",
        "serviceType": "服务类型",
        "transport": "出行约车",
        "delivery": "送货",
        "Fer_Stop": "停站费",
        "First_Stop": "第一站费用",
        "Each_Stop": "每个额外站点的费用",
        "Name": "姓名",
        "Pay_to_driver_Cash": "支付给司机（现金预订）",
        "Last_Update": "最后更新",
        "Status": "状态",
        "Required_fields": "必填字段",
        "Yes": "是",
        "No": "否",
        "Cancel": "取消",
        "Save": "保存",
        "Actions": "操作",
        "Edit": "编辑",
        "Add": "添加",
        "Show_per_page": "每页项目",
        "Page": "页",
        "show_pages": "显示了 {0} - {1} 个（共 {2} 个）",
        "DELETE_CONFIRM": "您想删除吗？",
        "ADD_REGULAR_RATE": "添加标准价格包",
        "EDIT_REGULAR_RATE": "编辑标准价格包",
        "DETAIL_REGULAR_RATE": "详细标准价格包",
        "DELETE_REGULAR_RATE": "删除标准价格包",
        "Delete_confirm_regular_rate": "您想删除该项目吗？",
        "STARTING": "起步",
        "Starting_Now": "起步（实时）",
        "Starting_Reservation": "起步（预约）",
        "FIRST_DISTANCE_FEE": "首程费",
        "First_distance_fee": "第一个距离",
        "From": "从",
        "To": "到",
        "Fee_for_first_distance": "首段距离费用",
        "SECOND_DISTANCE_FEE": "第二距离费",
        "Second_distance_fee": "第二距离",
        "AFTER_SECOND_DISTANCE": "第二个距离后",
        "Fee_after_second_distance": "第二次距离后的费用",
        "PER_MINUTE": "每分钟",
        "Fee_per_minute": "每分钟票价",
        "mi": "英里",
        "hour": "小时",
        "MINIMUM": "最低收费",
        "Minimum_Now": "最低（实时）",
        "Minimum_Reservation": "最低（预订）",
        "PAX_MDISPATCHER_CANCELLATION": "旅客/合作伙伴取消",
        "On_demand": "乘客/合作伙伴取消 - 实时",
        "Penalty": "罚款金额",
        "In_advance": "旅客/合作伙伴取消 - 预约",
        "PAX_MDISPATCHER_NO_SHOW": "乘客/伙伴NOSHOW",
        "Driver_cancel_demand": "司机取消政策 - 实时",
        "Driver_cancel_advance": "司机取消政策 - 预约",
        "Assign_car_type": "指定车辆类型",
        "Select_car_type": "选择车型",
        "Active": "启用",
        "Inactive": "停用",
        "Deactivate": "停用",
        "DELETE_FLATE_RATE": "删除固定计费",
        "Delete_confirm_flat_rate": "您想删除该项目吗？",
        "EDIT_FLAT_RATE": "编辑固定计费",
        "ADD_FLAT_RATE": "添加固定计费",
        "DELETE_FLAT_RATE": "删除固定计费",
        "DELETE_HOURLY_RATE": "删除小时计费",
        "Delete_confirm_hour_rate": "您想删除该项目吗？",
        "EDIT_HOURLY_RATE": "编辑按时计费",
        "ADD_HOURLY_RATE": "添加按时计费",
        "DELETE_FLAT_ROUTE": "删除固定收费路线",
        "DELETE_ROUTE_CONFIRM": "您确定要删除路线吗？",
        "EDIT_ROUTE": "编辑路线",
        "ADD_ROUTE": "添加路线",
        "Route_name": "路线名称",
        "Route_type": "路线类型",
        "Zone_to_zone": "区域到区域",
        "Zipcode_to_zipcode": "邮政编码到邮政编码",
        "First_location": "第一个地点",
        "Second_location": "第二个地点",
        "First_location_zip_note": "*注意：您可以使用“,”符号同时输入多个邮政编码。 对于连续的代码，请使用'->'符号而不是一一输入（例如：90001 -> 90099, 90101, 90102, 90103, 90174, 90185）",
        "Second_location_zip_note": "*注意：您可以使用“,”符号同时输入多个邮政编码。 对于连续的代码，请使用'->'符号而不是一一输入（例如：90001 -> 90099, 90101, 90102, 90103, 90174, 90185）",
        "SINGLE_TRIP_SETUP": "单程设置",
        "Route_1": "路线1（第一个地点->第二个地点）",
        "Route_2": "路线2（第二个地点->第一个地点）",
        "Limitation": "限制",
        "Unlimited": "无限",
        "Limited": "有限",
        "Time_coverage": "时间覆盖范围",
        "Extra_time": "额外的时间",
        "ROUND_TRIP_SETUP": "往返设置",
        "Round_trip": "往返",
        "ROUTES": "路线",
        "Route_1_Fare": "路线1票价",
        "Route_2_Fare": "路线2票价",
        "Round_Trip_Fare": "往返票价",
        "Select_a_zone": "选择一个区域",
        "minute": "分钟",
        "VIEW_REGULAR_RATE": "查看详细标准价格包",
        "VIEW_FLAT_RATE": "查看固定计费价格包",
        "VIEW_HOURLY_RATE": "查看按时计费价格包",
        "HOURLY_PACKAGE_RATE": "包车价格名字",
        "Package_name": "价格包名字",
        "Package_duration": "套餐时长",
        "PackageId": "Package ID",
        "Base_rate": "基本费率",
        "Distance_coverage": "距离覆盖",
        "Extra_duration": "额外时长",
        "Extra_distance": "额外距离",
        "DELETE_HOURLY_PACKAGE": "删除按时计费价格包",
        "DELETE_PACKAGE_CONFIRM": "您想删除价格包吗？",
        "EDIT_PACKAGE": "编辑包",
        "VIEW_PACKAGE": "查看价格包详情",
        "ADD_PACKAGE": "添加包",
        "Fee_per_extra_duration": "每延长一段时间的费用",
        "Fee_per_extra_distance": "每额外距离收费",
        "hours": "小时",
        "days": "天",
        "Update_flat_rate_success": "固定计费价格包已成功更新",
        "Update_flat_rate_fail": "更新固定计费时发生错误",
        "Created_flat_rate_success": "固定计费已成功创建",
        "Created_flat_rate_fail": "创建固定计费时发生错误",
        "Update_flat_rate_route_success": "固定计费路线已成功更新",
        "Update_flat_rate_route_fail": "更新固定计费路线时发生错误",
        "Created_flat_rate_route_success": "固定计费路线已成功创建",
        "Created_flat_rate_route_fail": "创建固定计费路线时发生错误",
        "Loading_flat_rate_fail": "加载固定计费详细信息时发生错误",
        "Delete_flat_rate_route_fail": "删除固定计费路线时发生错误",
        "Delete_flat_rate_success": "固定计费已成功删除",
        "Delete_flat_rate_fail": "删除固定计费时发生错误",
        "Update_intercity_rate_success": "专线价格包已成功更新",
        "Update_intercity_rate_fail": "更新专线价格包时发生错误",
        "Created_intercity_rate_success": "专线价格包创建成功",
        "Created_intercity_rate_fail": "创建专线价格包时出现错误",
        "Update_intercity_rate_route_success": "专线价格包路线已成功更新",
        "Update_intercity_rate_route_fail": "更新专线价格包路线时发生错误",
        "Created_intercity_rate_route_success": "专线价格包路线创建成功",
        "Created_intercity_rate_route_fail": "创建专线价格包路线时发生错误",
        "Loading_intercity_rate_fail": "加载专线价格包详细信息时发生错误",
        "Delete_intercity_rate_route_fail": "删除专线价格包路线时发生错误",
        "Delete_intercity_rate_success": "专线价格包已成功删除",
        "Delete_intercity_rate_fail": "删除专线价格包时出现错误",
        "Update_regular_rate_success": "标准价格包已成功更新",
        "Update_regular_rate_fail": "更新标准价格包时发生错误",
        "Create_regular_rate_success": "标准价格包已成功创建",
        "Create_regular_rate_fail": "创建标准价格包时发生错误",
        "Update_hourly_rate_success": "小时价格包已成功更新",
        "Update_hourly_rate_fail": "更新每小时价格包时发生错误",
        "Create_hourly_rate_success": "小时价格包已成功创建",
        "Create_hourly_rate_fail": "创建小时价格包时发生错误",
        "Update_hourly_rate_package_success": "小时价格包套餐已成功更新",
        "Update_hourly_rate_package_fail": "更新每小时价格包包时发生错误",
        "Create_hourly_rate_package_success": "小时价格包已成功创建",
        "Create_hourly_rate_package_fail": "创建小时价格包包时发生错误",
        "Load_hourly_rate_fail": "加载每小时价格包详细信息时发生错误",
        "Update_hourly_status": "更新每小时价格包状态时发生错误",
        "Delete_hourly_fail": "删除小时价格包时发生错误",
        "Delete_hourly_success": "小时价格包已成功删除",
        "Loading_regular_rate_fail": "加载标准价格包时发生错误",
        "Update_regular_status_fail": "更新标准价格包状态时发生错误",
        "Delete_regular_rate_success": "标准价格包已成功删除",
        "Delete_regular_rate_fail": "删除标准价格包时发生错误",
        "Please_go_to_Car_Type_settings": "要将此费率应用于任何车型，请转至“设置”>“车辆”>“车型”。",
        "Car_type_filter": "车型过滤器",
        "Delete_package_error": "删除包时出错！",
        "Pax_mDispatcher_cancellation_tooltip": "适用于乘客的取消政策",
        "Penalty_tooltip": "将向客户收取的金额。 除了罚款之外，您还可以添加税费或技术费。",
        "Pax_mDispatcher_no_show_tooltip": "适用于乘客的误机政策。 如果司机在上车地点没有看到乘客时点击“NOSHOW”，则将收取此费用。",
        "detail_route": "线路详情",
        "detail_package": "详细套餐",
        "THIRD_DISTANCE_FEE": "第三距离费",
        "AFTER_DISTANCE_FEE": "第三次距离费后",
        "Loading_delivery_rate_fail": "加载送货计费详细信息时发生错误",
        "Update_delivery_rate_fail": "更新送货计费时发生错误",
        "Update_delivery_rate_success": "送货计费已成功更新",
        "Created_delivery_rate_success": "送货计费已成功创建",
        "Created_delivery_rate_fail": "创建送货计费时发生错误",
        "Starting_fee": "起始费",
        "First_distance": "第一个距离",
        "Second_distance": "第二距离",
        "Third_distance": "第三距离",
        "Fee_for_second_distance": "第二距离费用",
        "Fee_for_third_distance": "第三距离费用",
        "Fee_after_third_distance": "第三次距离后的费用",
        "Assign_rate_to_car_type": "为车型指定费率",
        "car_type": "车型",
        "PRICE_ADJUSTABLE": "价格可调",
        "minimum_Percent_Adjust": "预计票价的最低百分比",
        "maximum_Percent_Adjust": "预计票价的最大百分比",
        "Amount_Step": "价格可调",
        "Tax": "税",
        "TechFee": "技术费",
        "Pay_to_driver": "支付给司机",
        "Pay_to_driver_tooltip": "补偿司机的选项：\n  - 固定金额：司机将收到的固定金额。\n  - 佣金：司机设定的佣金适用于罚款金额。",
        "Fix_amount": "固定值",
        "Commission": "佣金",
        "fareFlat": "固定计费",
        "flatRoute": "固定计费线路",
        "Mode": "Mode",
        "speedThresholdKM": "Speed threshold (km/h)",
        "speedThresholdMI": "Speed threshold (mi/h)",
        "timeAndMileage": "Time and mileage",
        "timeOrMileage": "Time or mileage",
        "perDistanceKM": "Per kilometer",
        "perDistanceMI": "Per mile",
        "perTime": "Per minute"
    },
    "flatRateSettings": {
        "flatZoneNote": "您在此处创建的区域仅用于设置您的固定计费。"
    },
    "deliverySetting": {
        "Name": "姓名",
        "Last_Update": "最后更新",
        "Status": "状态",
        "Actions": "操作"
    },
    "sharingSetting": {
        "EDIT_RATE": "编辑街头拼车计费",
        "ADD_RATE": "添加街头拼车计费",
        "VIEW_RATE": "查看街头拼车计费",
        "Name": "姓名",
        "startingFee": "起步价",
        "minimumFee": "最低票价",
        "Update_streetSharing_rate_fail": "更新街头拼车计费时发生错误",
        "Update_streetSharing_rate_success": "街头拼车费率已成功更新",
        "Created_streetSharing_rate_success": "街头拼车计费创建成功",
        "Created_streetSharing_rate_fail": "创建街头拼车计费时发生错误",
        "Loading_streetSharing_rate_fail": "加载街头拼车计费详细信息时发生错误",
        "Delete_streetSharing_rate_success": "街头拼车计费已成功删除",
        "Delete_streetSharing_rate_fail": "删除街头拼车计费时发生错误",
        "Delete_streetSharing_rate": "删除街头拼车计费",
        "Assign_Car": "指定车辆类型"
    },
    "carTypeSetting": {
        "newService": "New Service",
        "editService": "Edit Service",
        "serviceInfo": "Service Info",
        "name": "Name",
        "namePH": "Car-type name",
        "enterNumber": "Enter a number",
        "appDisplayName": "App display name",
        "shortDescription": "Short description",
        "maxPassengers": "Max passengers",
        "maxLuggage": "Max luggages",
        "vehiclePhoto": "Vehicle Photos",
        "iconShowList": "Icon to show in list",
        "imageShowWeb": "Image to show on web",
        "carPinOnMap": "Car PIN on map",
        "recommendedSize": "Recommended size:",
        "serviceSetting": "Service Settings",
        "serviceType": "Service type",
        "reservation": "Reservation",
        "onDemand": "On-demand",
        "destinationSetting": "Destination setting",
        "requireDestination": "Require destination",
        "extraDestination": "Extra destination",
        "calculatorFareMode": "Calculate fare mode",
        "hideDestinationOnDemand": "On-demand",
        "meteredFare": "Metered fare",
        "quotedFare": "Quoted fare",
        "availableZones": "Available Zones",
        "dispatchAssociation": "Dispatch Association",
        "Save": "Save",
        "Edit": "Edit",
        "Cancel": "Cancel",
        "validateName": "Please input name",
        "validateAppDisplayName": "Please input app display name",
        "validateSelectImage": "Please select an image",
        "updateSuccess": "Update successfully",
        "createSuccess": "Create successfully",
        "failApi": "Something went wrong!",
        "validateRequire": "Please specify passenger count and double-check luggage."
    },
    "carSetting": {
        "Details": "Details",
        "vehicleInfo": "Vehicle Info",
        "Documents": "Documents",
        "vehicle": "Vehicle",
        "addVehicle": "Add Vehicle",
        "detailVehicle": "Vehicle Details",
        "additionalVehicleTitle": "Additional Vehicle Information",
        "Phone_Number_Tooltip": "Enter a vehicle-specific phone number for customer use. If not provided, calls will default to the driver’s personal number. Ideal for suppliers who equip vehicles with dedicated contact phones.",
        "discardChange": "Discard changes",
        "model_name_is_required": "型号名称为必填项",
        "DELETE_CAR": "删除车辆",
        "DELETE_CONFIRM": "您想删除选定的车辆吗？",
        "Yes": "是",
        "Cancel": "取消",
        "EDIT_CAR": "编辑车辆",
        "CREATE_CAR": "创造车辆",
        "Plate_Number": "车牌号码",
        "Car_Type": "车型",
        "Select_car_type": "选择车型",
        "Make": "制作",
        "Select_car_make": "选择车辆品牌",
        "Model": "模型",
        "Select_a_car_model": "选择车型",
        "Year": "年",
        "Select_year": "选择年份",
        "Hardware_Meter": "硬件仪表",
        "VIN": "车辆识别码 (VIN)",
        "Company": "供应商",
        "Select_company": "选择供应商",
        "Max_Passengers": "最大载客量",
        "Max_Luggage": "最大行李数",
        "Color": "颜色",
        "License_expiry": "许可证到期",
        "Required_fields": "必填字段",
        "Save": "保存",
        "Add": "添加",
        "Car_Model": "车款",
        "Created_Date": "创建日期",
        "Status": "状态",
        "Actions": "操作",
        "Show_per_page": "每页项目",
        "Page": "页",
        "show_pages": "显示了 {0} - {1} 个（共 {2} 个）",
        "Active": "启用",
        "activeVehicle": "Active vehicle",
        "Inactive": "停用",
        "Edit": "编辑",
        "Deactivate": "停用",
        "Activate": "启用",
        "Delete": "删除",
        "Name": "姓名",
        "MODEL_LIST": "型号列表",
        "Model_Name": "型号名称",
        "Add_Model": "添加型号",
        "DELETE_CAR_MODEL": "删除车型",
        "DELETE_CONFIRM_MODEL": "您想删除车型吗？",
        "DELETE_CAR_MAKE": "删除车辆品牌",
        "DELETE_CONFIRM_CAR_MAKE": "您想删除该车辆品牌吗？",
        "EDIT_CAR_MAKE": "编辑车辆品牌",
        "CREATE_CAR_MAKE": "添加车辆品牌",
        "Assign_Rate": "分配率",
        "ASSIGN_RATE": "分配率",
        "Car_type_ID": "车型ID",
        "App_Display_Name": "应用程序显示名称",
        "Affiliate_car_type": "附属车型",
        "Regular_rate": "标准计费",
        "Zone": "区域",
        "Rate": "价格包",
        "Zone_Rate": "区域 - 费率",
        "Flat_Fare": "固定计费",
        "Flat_Delivery": "送货固定收费",
        "Select_flat_rate": "选择固定计费",
        "Hourly_daily_rate": "按时计费",
        "Dispatch_To": "调度到",
        "Normal_Fare": "标准计费",
        "Upload_an_image": "上传图片",
        "DETAIL_CAR": "车辆细节",
        "DELETE_MULTI_CAR": "删除选定的车辆",
        "DELETE_MULTI_CAR_CONFIRM": "是否要删除这些车辆？只有处于停用状态的车辆才能删除吗？",
        "DETAIL_CAR_MAKE": "车辆制造商",
        "Car_Make": "车辆制造商",
        "Create_car_success": "新车已创建成功",
        "Update_car_success": "该车已更新成功。",
        "Update_car_fail": "更新车辆时出现错误",
        "Create_car_fail": "创建车辆时发生错误",
        "Load_car_fail": "装车时发生错误",
        "Delete_car_fail": "删除车辆时发生错误",
        "Delete_car_success": "车辆已成功删除",
        "Change_cars_status_success": "所有车辆状态已更改成功",
        "Change_cars_status_fail": "更改车辆状态时发生错误",
        "Delete_cars_success": "所有选定的车辆已成功删除",
        "Delete_cars_fail": "删除车辆时发生错误",
        "Update_car_type_success": "车型已成功更新",
        "Update_car_type_fail": "更新车型时发生错误",
        "Load_car_type_details_fail": "无法加载车型详细信息",
        "Update_car_make_fail": "更新车辆品牌时发生错误",
        "Update_car_make_success": "车辆品牌已成功更新",
        "Create_car_make_fail": "创建车辆品牌时发生错误",
        "Create_car_make_success": "新车辆品牌已成功创建",
        "Update_car_model_fail": "更新车型时出现错误",
        "Update_car_model_success": "车型已更新成功",
        "Create_car_model_fail": "创建车款时出现错误",
        "Create_car_model_success": "新车型已成功创建",
        "Loading_car_model_fail": "加载车款时发生错误",
        "Delete_car_make_success": "车辆品牌已成功删除",
        "Delete_cat_make_fail": "删除车辆品牌时发生错误",
        "Delete_regular_rate_success": "删除标准价格包成功",
        "Delete_regular_rate_fail": "删除标准价格包错误",
        "Update_status_error": "更新状态错误",
        "Case_number": "案件编号",
        "Phone_Number": "电话号码",
        "ERROR_INPUT_VALIDPHONE": "请输入一个有效的电话号码。",
        "Car_type_filter": "车用滤清器",
        "No": "否",
        "Intercity_Rate": "专线价格",
        "Select_intercity_rate": "选择专线价格包",
        "Delivery_Rate": "送货计费",
        "Select_delivery_rate": "选择送货价格包",
        "vehicleOwner": "车主姓名",
        "EDIT_CAR_COLOR": "编辑车辆颜色",
        "DETAIL_CAR_COLOR": "车辆颜色细节",
        "CREATE_CAR_COLOR": "添加车辆颜色",
        "Car_Color": "车辆颜色",
        "Update_car_color_fail": "更新车辆颜色时发生错误。",
        "Update_car_color_success": "车辆颜色已成功更新。",
        "Create_car_color_fail": "创建车辆颜色时发生错误。",
        "Create_car_color_success": "新车颜色已创建成功",
        "Delete_car_color_success": "车辆颜色已成功删除。",
        "Delete_car_color_fail": "删除车辆颜色时发生错误。",
        "DELETE_CAR_COLOR": "删除车辆颜色",
        "DELETE_CONFIRM_CAR_COLOR": "您想删除此车辆颜色吗？",
        "sharing_Rate": "街头拼车计费",
        "bookingAffiliate": "Booking.com 联盟车型",
        "streetSharing": "街头拼车"
    },
    "operationSetting": {
        "Driver": "司机",
        "Search_driver_name": "搜索司机名称",
        "Company": "供应商",
        "License_plate": "牌照",
        "Select_license_plate": "选择车牌",
        "Shift": "排班",
        "Zone": "区",
        "Required_fields": "必填字段",
        "Save": "保存",
        "No": "不。",
        "Car_Type": "车型",
        "Plate_Number": "车牌号码",
        "Unassign": "取消分配",
        "Please_choose_driver": "请选择司机。",
        "Please_choose_company": "请选择供应商。",
        "Please_license_plate": "请选择车牌。",
        "Please_choose_shift": "请选择班次。",
        "Please_choose_zone": "请选择区域。",
        "CONFIRM_UNASSIGN": "您想取消分配该司机吗？",
        "Assign_success": "运营已成功套用",
        "Unassign_success": "司机已成功取消套用。"
    },
    "paymentMethod": {
        "cash": "现金",
        "easyPaisa": "Easy Paisa",
        "jazzCash": "Jazz Cash",
        "cashBySender": "发件人付现",
        "cashByRecipient": "收件人付现",
        "personalCard": "个人卡",
        "corporateCard": "企业卡",
        "qrCodePayment": "二维码支付",
        "directBilling": "月结",
        "externalCard": "外置卡",
        "mDispatcherCard": "合作伙伴卡",
        "corporatePrepaid": "企业预付费",
        "fleetCard": "外置卡",
        "applePay": "Apple Pay",
        "creditExternalCard": "信用卡",
        "googlePay": "Google Pay",
        "wallet": "钱包",
        "BankOfGeorgia": "Georgia银行",
        "tnGeWalletlet": "TnG电子钱包",
        "paymentMethod_1": "现金",
        "paymentMethod_2": "个人卡",
        "paymentMethod_3": "合作伙伴卡",
        "paymentMethod_4": "企业卡",
        "paymentMethod_5": "月结",
        "paymentMethod_6": "外置卡",
        "paymentMethod_7": "企业预付费",
        "paymentMethod_8": "二维码支付",
        "paymentMethod_9": "Apple Pay",
        "paymentMethod_11": "航空支付",
        "paymentMethod_12": "信用卡",
        "paymentMethod_13": "钱包",
        "paymentMethod_14": "TnG电子钱包",
        "paymentMethod_16": "发件人付现",
        "paymentMethod_17": "收件人付现",
        "paymentMethod_19": "Boost电子钱包",
        "paymentMethod_20": "VIPPS",
        "paymentMethod_21": "ZainCash",
        "paymentMethod_23": "Google Pay",
        "paymentMethod_25": "Easy Paisa",
        "paymentMethod_26": "Jazz Cash",
        "vippseWallet": "VIPPS",
        "paymentMethod_credit": "个人卡",
        "paymentMethod_razerPay": "Razerpay",
        "paymentMethod_gCash": "GC现金",
        "paymentMethod_zainCash": "ZainCash",
        "paymentMethod_xenDit": "Xendit",
        "paymentMethod_FIB": "FIB",
        "paymentMethod_SantimPay": "SantimPay",
        "paymentMethod_ksher": "Ksher",
        "paymentMethod_paydunya": "PayDunya",
        "paymentMethod_fawry": "Fawry",
        "paymentMethod_BankOfGeorgia": "Bank Of Georgia",
        "paymentLink": "付款链接",
        "paymentMethod_xendit": "Xendit",
        "paymentMethod_easyPaisa": "Easy Paisa",
        "paymentMethod_jazzCash": "Jazz Cash",
        "paymentMethod_flooz": "Flooz",
        "paymentMethod_tmoney": "Tmoney",
        "paymentMethod_corporateCard": "Corporate card"
    },
    "changePassword": {
        "Change_password": "更改密码",
        "Current_password": "当前密码",
        "New_password": "新密码",
        "Confirm_password": "确认密码",
        "ERROR_INPUT_PASSWORD": "请输入密码",
        "ERROR_INPUT_VALID_PASSWORD": "密码必须包含至少 8 个字符、1 个数字和 1 个特殊字符",
        "ERROR_INPUT_MATCH_PASSWORD": "密码不匹配。 请再试一次",
        "Save": "保存",
        "Cancel": "取消"
    },
    "userProfile": {
        "My_account": "我的账户",
        "Username": "用户名",
        "First_name": "姓氏",
        "Last_name": "名字",
        "Phone_number": "电话号码",
        "Email_address": "电子邮件地址",
        "Address": "地址",
        "Save": "保存",
        "Edit_profile": "编辑个人资料",
        "Change_password": "更改密码",
        "ERROR_INPUT_USERNAME": "请输入用户名。",
        "ERROR_INPUT_FIRSTNAME": "请输入姓名。",
        "ERROR_INPUT_VALID_EMAIL": "请输入有效的电子邮件。",
        "Required_fields": "必填字段"
    },
    "General": {
        "searchQueuingDriver": "搜索：司机姓名、电话号码",
        "search": "搜索",
        "User": "用户",
        "My_Account": "我的账户",
        "Sign_out": "登出",
        "My_site": "我的网站",
        "Command_center": "控制中心",
        "Notifications": "通知",
        "Show_all": "显示所有",
        "SOS_Notifications": "求救通知",
        "You_dont_have_any_message": "您还没有任何留言",
        "Mon": "周一",
        "Tue": "周二",
        "Wed": "周三",
        "Thu": "周四",
        "Fri": "周五",
        "Sat": "周六",
        "Sun": "周日",
        "Now": "现在",
        "NowComplete": "Now (Acttual Pickup Time)",
        "hrs": "小时",
        "patmentMethod_1": "现金",
        "patmentMethod_2": "个人卡",
        "patmentMethod_3": "合作伙伴卡",
        "patmentMethod_4": "公司卡",
        "patmentMethod_5": "月结",
        "patmentMethod_6": "外置卡",
        "patmentMethod_7": "企业预付",
        "patmentMethod_8": "二维码",
        "patmentMethod_9": "Apple Pay",
        "patmentMethod_10": "Apple Pay",
        "patmentMethod_12": "信用卡",
        "patmentMethod_13": "钱包",
        "patmentMethod_14": "TnG电子钱包",
        "patmentMethod_16": "发件人付现",
        "patmentMethod_17": "收件人付现",
        "patmentMethod_19": "BOOST",
        "patmentMethod_20": "VIPPS",
        "patmentMethod_23": "谷歌游戏",
        "patmentMethod_24": "支付链接",
        "patmentMethod_25": "Easy Paisa",
        "patmentMethod_26": "Jazz Cash",
        "patmentMethod_ZainCash": "ZainCash",
        "patmentMethod_Xendit": "Xendit",
        "patmentMethod_FIB": "FIB",
        "patmentMethod_SantimPay": "SantimPay",
        "patmentMethod_GCash": "GCASH",
        "patmentMethod_cash": "现金",
        "patmentMethod_credit": "个人卡",
        "patmentMethod_mDispatcher": "合作伙伴卡",
        "patmentMethod_corpCredit": "公司卡",
        "patmentMethod_directBilling": "月结",
        "patmentMethod_fleetCard": "外置卡",
        "patmentMethod_prepaid": "企业预付",
        "patmentMethod_QRCode": "二维码",
        "patmentMethod_applePay": "Apple Pay",
        "patmentMethod_easyPaisa": "Easy Paisa",
        "patmentMethod_jazzCash": "Jazz Cash",
        "patmentMethod_prePaid": "Corporate Prepaid",
        "patmentMethod_GooglePay": "Google Pay",
        "patmentMethod_creditCardExternal": "信用卡",
        "patmentMethod_PaxWallet": "钱包",
        "patmentMethod_TnGeWallet": "TnG电子钱包",
        "patmentMethod_boosteWallet": "BOOST",
        "patmentMethod_Vipps": "VIPPS",
        "patmentMethod_exZainCash": "ZainCash",
        "patmentMethod_exGCash": "GCASH",
        "patmentMethod_exPayTM": "PayTM",
        "patmentMethod_exFlooz": "Flooz",
        "patmentMethod_exTmoney": "Tmoney",
        "patmentMethod_exTelebirr": "GCash",
        "paymentMethod_exzainCash": "ZainCash",
        "paymentMethod_exXendit": "Xendit",
        "paymentMethod_exFIB": "FIB",
        "paymentMethod_exSantimPay": "SantimPay",
        "patmentMethod_exKsher": "Ksher",
        "paymentMethod_paydunya": "PayDunya",
        "paymentMethod_exfawry": "Fawry",
        "patmentMethod_exBankOfGeorgia": "Bank Of Georgia",
        "patmentMethod_exPayDunya": "PayDunya",
        "patmentMethod_21": "ZainCash",
        "patmentMethod_paymentLink": "支付链接",
        "patmentMethod_card": "个人卡",
        "patmentMethod_corporateCard": "企业卡",
        "patmentMethod_paxWallet": "Wallet",
        "patmentMethod_externalWallet": "External Wallet",
        "patmentMethod_googlePay": "Google Pay",
        "patmentAirlineMethod_5": "月结",
        "patmentAirlineMethod_4": "航空卡",
        "patmentAirlineMethod_7": "航空公司预付",
        "Name": "姓名",
        "Delete": "删除",
        "App_SOS_dialog_form_message_1": "{0}紧急求救！",
        "App_SOS_dialog_form_message_2": "我需要帮助",
        "App_SOS_dialog_form_message_3": "预订：{0}，司机：{1}，乘客：{2}，车牌：{3} ({4})",
        "mins": "分钟",
        "Credit_Card": "信用卡",
        "ALL": "全部",
        "NotFound": "您请求的页面无法找到",
        "ServerError": "出了些问题",
        "AccessDenied": "您没有访问此模块的权限！",
        "Retry": "重试",
        "Can_not_get_data": "无法获取数据"
    },
    "shiftSettings": {
        "Add": "添加",
        "Show_per_page": "每页项目",
        "Page": "页",
        "Name": "姓名",
        "Time_Range": "时间范围",
        "Actions": "操作",
        "show_pages": "显示了 {0} - {1} 个（共 {2} 个）",
        "Edit": "编辑",
        "Delete": "删除",
        "EDIT_SHIFT_SETTING": "编辑班次设置",
        "ADD_SHIFT_SETTING": "添加班次设置",
        "DETAIL_SHIFT_SETTING": "详细班次设置",
        "DELETE_SHIFT_SETTING": "删除班次设置",
        "Delete_confirm_shift_setting": "您想删除该项目吗？",
        "Required_fields": "必填字段",
        "Save": "保存",
        "Cancel": "取消",
        "From": "从",
        "To": "到",
        "Edit_shift_template": "编辑排班模板",
        "Add_shift_template": "添加排班模板",
        "Detail_shift_template": "轮班模板详细",
        "Delete_shift_template": "删除轮班模板",
        "Delete_confirm_shift_template": "你想删除这个项目吗？",
        "Working_time": "工作时间",
        "Create_shift_settings_success": "新排班设置已成功创建",
        "Create_shift_settings_fail": "创建排班设置时发生错误",
        "Update_shift_settings_success": "排班设置已成功更新",
        "Update_shift_settings_fail": "更新排班设置时发生错误",
        "Delete_shift_settings_success": "排班设置已成功删除",
        "Delete_shift_settings_fail": "删除排班设置时发生错误",
        "Create_shift_template_success": "新的排班模板已成功创建",
        "Create_shift_template_fail": "创建轮班模板时发生错误",
        "Update_shift_template_success": "排班模板已成功更新",
        "Update_shift_template_fail": "更新排班模板时发生错误",
        "Delete_shift_template_success": "排班模板已成功删除",
        "Delete_shift_template_fail": "删除排班模板时发生错误",
        "Yes": "是",
        "No": "否"
    },
    "promotionSettings": {
        "Detail_campaign": "促销活动详情",
        "Edit_campaign": "编辑推广活动",
        "Add_campaign": "添加推广活动",
        "Delete_campaign": "删除推广活动",
        "Delete_confirm_campaign": "您是否要删除选定的推广活动？",
        "Add": "添加",
        "Corporate_list": "企业名单",
        "permittedCorporate": "获许可企业名单",
        "CorporateCustomers": "企业客户",
        "auto_add_corporate": "自动添加到企业用户的促销列表",
        "Show_per_page": "每页项目",
        "Page": "页",
        "Name": "姓名",
        "Actions": "操作",
        "Private": "私人的",
        "show_pages": "显示了 {0} - {1} 个（共 {2} 个）",
        "Edit": "编辑",
        "Delete": "删除",
        "Send_to_inbox": "发送至收件箱",
        "Save": "保存",
        "Duplicate": "复制",
        "Cancel": "取消",
        "Required_fields": "必填字段",
        "Promotion_code": "促销代码",
        "Promotion_type": "促销类型",
        "Campaign": "活动",
        "Type": "类型",
        "Value": "价值",
        "keepMinFee": "促销后的总票价必须大于最低票价",
        "Zone": "区域",
        "paymentMethodsApply": "支付方式",
        "schedules": "时间表",
        "minimumEstFareApply": "应用代码的最低预估总票价",
        "quantityLimitPerYear": "最大使用次数/用户/年",
        "termAndCondition": "条款和条件",
        "Valid_from": "有效期自",
        "Valid_to": "有效",
        "Total_uses_user": "总使用量/用户",
        "Released_quantity": "发布数量",
        "Number_of_sending_inbox": "发送收件箱数量",
        "Status": "状态",
        "Activate": "启用",
        "Deactivate": "停用",
        "Add_promotion_code": "添加促销代码",
        "Edit_promotion_code": "编辑促销代码",
        "Detail_promotion_code": "详细促销代码",
        "Delete_promotion_code": "删除促销代码",
        "Delete_confirm_promotion_code": "您想删除选定的促销代码吗？",
        "New_customer": "新客户",
        "Budget_per_user": "预算/用户（整个促销期间）",
        "Budget_per_user_day": "预算/用户/天",
        "Budget_per_user_month": "预算/用户/月",
        "Budget_per_user_year": "预算/用户/年",
        "Unlimited": "无限",
        "Limited": "有限",
        "Total_uses_per_user": "每个用户的最大使用次数（整个促销期间）",
        "Send_an_in_box_automatically_to_new_customers": "自动发送到新客户App收件箱",
        "Notes": "备注",
        "Public": "民众",
        "Create_campaign_success": "新推广活动已成功创建",
        "Create_campaign_fail": "创建推广活动时发生错误",
        "Update_campaign_success": "推广活动已成功更新",
        "Update_campaign_fail": "更新推广活动时发生错误",
        "Delete_campaign_success": "促销活动已成功删除",
        "Delete_campaign_fail": "删除促销活动时发生错误",
        "Loading_campaign_fail": "加载推广活动时发生错误",
        "Update_campaign_status_success": "推广活动状态已成功更新",
        "Update_campaign_status_fail": "更新推广活动状态时发生错误",
        "Loading_promotion_code_fail": "加载促销代码时发生错误",
        "Select_campaign": "选择促销活动",
        "Create_promotion_code_success": "新的促销代码已成功创建",
        "Create_promotion_code_fail": "创建促销代码时发生错误",
        "Update_promotion_code_status_success": "促销代码状态已成功更新",
        "Update_promotion_code_status_fail": "更新促销代码状态时发生错误",
        "Delete_promotion_code_confirmation": "你想删除这些促销代码吗？",
        "Just_delete_inactive": "** 只能删除处于停用状态的促销代码。",
        "Send_inbox_confirmation": "您确定要将此促销代码发送到乘客收件箱吗？",
        "Send_inbox_success": "促销代码已成功发送。",
        "Send_inbox_fail": "促销代码发送失败。 请再试一次！",
        "Confirmation": "确认",
        "Delete_promotion_codes_success": "促销代码已成功删除",
        "Delete_promotion_codes_fail": "删除促销代码时发生错误",
        "Delete_multiple_promotion_codes_success": "促销代码已成功删除",
        "Delete_multiple_promotion_codes_fail": "删除促销代码时发生错误",
        "Delete_promotion_code_success": "促销代码已成功删除",
        "Delete_promotion_code_fail": "删除促销代码时发生错误",
        "Update_multiple_promotion_code_status_success": "促销代码状态已成功更新",
        "Update_multiple_promotion_code_status_fail": "删除促销代码状态时发生错误",
        "Search_here": "在这里搜索",
        "Please_input_campaign_name": "请输入推广活动名称",
        "Please_input_promotion_code": "请输入促销代码",
        "Please_input_promotion_code_at_least_4_characters": "请输入至少 4 个字符。",
        "Please_select_campaign": "请选择推广活动",
        "Please_input_value": "请输入值",
        "Please_select_date": "请选择日期",
        "Please_add_schedules": "请添加时间表",
        "Please_add_corporate": "请添加企业",
        "Please_choose_at_least_one_customer": "请至少选择一位客户。",
        "This_campaign_name_has_been_existing_on_the_system_Please_input_another_one": "此推广活动名称已存在于系统中。请输入另一个。",
        "This_promotion_code_has_been_existing_on_the_system_Please_input_another_one": "该促销代码已存在于系统中。 请输入另一项。",
        "Invalid_file": "文件内容无效",
        "File_size_larger_than_5Mb": "文件大小大于 5Mb",
        "Import_promotions_success": "文件已成功导入",
        "Import_Promotions": "导入文件",
        "Number_successful_imports": "导入商品",
        "Number_skipped": "跳过项目",
        "File": "文件",
        "Phone_Numbers": "电话号码",
        "Import_Promotion_code_success": "促销代码 <b>{0}</b> 已成功导入",
        "Import_Promotion_code_fail": "促销代码<b>{0}</b>导入失败。 请再试一次",
        "Customer_type": "客户类型",
        "All": "全部",
        "Referred_customers": "推荐客户",
        "Common_customers": "普通客户",
        "Operation_zone": "运营区域",
        "totalUsesLimitPerDay": "最大使用次数/用户/天",
        "quantityLimitPerDay": "最大数量/天",
        "quantityLimitPerMonth": "最大数量/用户/月",
        "applyVerifiedCustomers": "仅限经过验证的马来西亚客户申请",
        "applyWhenBudgetLimit": "用户可以应用代码直到预算限制结束",
        "applyVerifiedCustomersTooltip": "此促销活动仅适用于已验证国民身份证并将身份证上传到系统的马来西亚人。",
        "valueLimitPerUse": "最大价值/使用（适用于应用程序v48及以上）",
        "Promotion_Services": "服务",
        "transport": "出行约车",
        "shuttle": "穿梭",
        "parcel": "包裹",
        "food": "送餐",
        "mart": "市场",
        "intercity": "专线",
        "addSchedule": "添加时间表",
        "editSchedule": "编辑日程",
        "date": "日期",
        "Time_range": "时间范围",
        "startTime": "开始时间",
        "endTime": "时间结束",
        "applyForOtherDays": "申请其他日期",
        "scheduleTimeOverlap": "计划时间不得相互重叠",
        "schedule_startDate_greater_than_endDate": "开始日期大于结束日期",
        "scheduleTimeDuplicate": "安排时间不得重复",
        "promoIcon": "促销图标",
        "bannerImage": "横幅图片",
        "Upload_an_image": "上传图片",
        "Click_here_to_view": "点击此处查看。"
    },
    "quest_event": {
        "name": "姓名",
        "userType": "用户类型",
        "carType": "车型",
        "operationZones": "运营区域",
        "type": "事件类型",
        "isLuckyDraw": "幸运抽奖轮",
        "start_date": "开始日期",
        "end_date": "结束日期",
        "numOfQualify": "合格人数",
        "event_progress": "活动进展",
        "isActive": "状态",
        "Actions": "操作",
        "passenger": "用户",
        "driver": "司机",
        "first_will_win": "先者获胜",
        "largest_will_win": "最大者获胜（排行榜）",
        "not_start": "未启动",
        "active": "启用",
        "closed": "关闭",
        "event_details": "任务详情",
        "add_event": "添加任务",
        "limited": "有限的",
        "unlimited": "无限",
        "eventImage": "活动图片",
        "sharedImage": "共享图像",
        "qualifiedImage": "合格形象",
        "expiredImage": "过期图片",
        "luckyDrawImage": "抽奖图片",
        "Upload_an_image": "上传图片",
        "Required_Image": "图像扩展名（.jpg、.png、.gif）",
        "ok": "好的",
        "eventId": "事件ID",
        "passengerMetric": {
            "booking_distance": "预订距离",
            "booking_time": "预订时间",
            "booking_amount_spent": "预订花费金额",
            "num_of_ondemand": "实时数量",
            "num_of_reservation": "预约数量",
            "num_of_intercity": "专线班次",
            "num_of_delivery": "发货数量",
            "num_of_referral": "推荐数量",
            "num_of_sharing": "分享数量",
            "topup_wallet_amount": "充值钱包金额",
            "num_of_complete_wallet": "完整订单数量（钱包）",
            "num_of_complete_booking_by_referee": "完整订单数量（由被推荐人提供）",
            "number_of_complete_booking": "完整订单数量",
            "number_of_food_mart": "食品数量/集市"
        },
        "driverMetric": {
            "booking_distance": "工作距离",
            "booking_time": "工作时间",
            "num_of_referral": "推荐数量",
            "num_of_complete": "完整订单数量（唯一客户）",
            "num_of_referral_driver": "推荐人数（司机）",
            "num_of_accept": "接受预订人数",
            "num_of_receive": "接收订单数量",
            "num_of_cancel": "司机取消预订次数",
            "sum_of_rating": "评级星级总数",
            "total_rating": "总评分次数",
            "earnings_amount": "盈利金额",
            "num_of_complete_wallet": "完整订单数量（钱包）",
            "num_of_complete_booking_by_referee": "完整订单数量（由被推荐人提供）",
            "available_time": "在线时间"
        },
        "Click_here_to_view": "点击此处查看。",
        "view": "点击此处查看。",
        "metricType": "类型",
        "task": "任务",
        "value": "价值",
        "quantity": "数量",
        "quests_list": "任务",
        "rankImages": "排名图像（点）",
        "qualifiedPrizeOrder": "合格奖品",
        "luckyDrawPrizeOrder": "幸运抽奖奖品",
        "reward": "物品",
        "from": "从",
        "to": "到",
        "image": "图像",
        "Add_Prize": "添加",
        "bannerText": "横幅文字",
        "buttonText": "按钮文字",
        "bannerLink": "横幅链接",
        "shortLink": "短链接",
        "buttonLinkDesktop": "按钮链接（桌面）",
        "buttonLinkAndroid": "按钮链接（Android）",
        "buttonLinkiOS": "按钮链接 (iOS)",
        "termsURL": "条款及条件链接",
        "metadataTitle": "元数据标题",
        "metadataDescription": "元数据描述",
        "isDarkMode": "深色模式",
        "isActive_details": "启用",
        "Required_fields": "必填字段",
        "change": "变更",
        "Please_input_name": "请输入姓名",
        "Please_select_date": "请选择日期",
        "endDate_invalid": "结束日期无效",
        "startDate_greater_than_endDate": "开始日期大于结束日期",
        "Please_input_termsURL": "请输入条款及细则链接",
        "Please_input_metadataTitle": "请输入元数据标题",
        "Please_input_metadataDescription": "请输入元数据描述",
        "Please_input_bannerText": "请输入横幅文字",
        "Please_input_buttonText": "请输入按钮文字",
        "Please_input_bannerLink": "请输入横幅链接",
        "Please_input_shortLink": "请输入短链接",
        "Please_input_buttonLinkDesktop": "请输入按钮链接（桌面）",
        "Please_input_buttonLinkAndroid": "请输入按钮链接（Android）",
        "Please_input_buttonLinkiOS": "请输入按钮链接（iOS）",
        "the_range_1_to_5": "请为 %{name}! 输入 1 到 5 范围内的值",
        "the_range_1_to_100": "请为 %{name}! 输入 1 到 100 范围内的值",
        "task_is_empty": "请输入%{name}的任务！",
        "task_limit_50_characters": "%{name}! 的任务最大长度为 50 个字符！",
        "please_input_points": "请输入%{name}的分数！",
        "please_input_value": "请输入 %{name} 的值！",
        "value_is_invalid": "%{name}，值必须是数字",
        "please_upload_image": "请上传商品 %{name} 的图片！",
        "rank_invalid": "排名 %{name} 无效！",
        "rank_invalid_to": "排名“至”必须是数字",
        "deepLink": "深层链接",
        "point_per_task": "每个任务的分数",
        "points": "积分",
        "points_per_some_think": "每%{单位}的分数",
        "criterias": "标准（可选）",
        "criterias_list": "标准",
        "criteriaType": "类型",
        "criteriaValue": "价值",
        "acceptance_rating": "接单等级（大于或等于）",
        "cancellation_rating": "取消评级（小于或等于）",
        "driver_rating": "司机评级（大于或等于）",
        "please_upload_image_rank": "请上传排名 %{name} 的图片！",
        "Update_event_success": "任务已成功更新",
        "Update_event_fail": "更新任务时发生错误",
        "Create_event_success": "新任务已成功创建",
        "Create_event_fail": "创建任务时发生错误",
        "suspend_list": "超时名单"
    },
    "voucherSettings": {
        "Search_here": "在这里搜索",
        "Voucher_code": "代码 ＃",
        "Campaign": "活动",
        "Type": "类型",
        "Value": "价值",
        "Valid_from": "有效期自",
        "Valid_to": "有效",
        "isActive": "状态",
        "Activate": "启用",
        "Deactivate": "停用",
        "Actions": "操作",
        "Active": "启用",
        "Inactive": "停用",
        "redeemed": "已兑换",
        "expired": "已到期",
        "Add_Voucher_code": "添加代码",
        "Add": "添加",
        "Show_per_page": "每页项目",
        "Page": "页",
        "Name": "姓名",
        "show_pages": "显示了 {0} - {1} 个（共 {2} 个）",
        "Edit": "编辑",
        "Delete": "删除",
        "Save": "保存",
        "Duplicate": "复制",
        "Cancel": "取消",
        "Required_fields": "必填字段",
        "Detail_voucher_code": "详细优惠券代码",
        "Delete_voucher_code": "删除优惠券代码",
        "Confirmation": "确认",
        "Delete_confirm_voucher_code": "您想删除选定的优惠券代码吗？",
        "Delete_voucher_code_confirmation": "您想删除这些优惠券代码吗？",
        "Just_delete_inactive": "** 只能删除处于停用状态的优惠券代码。",
        "Loading_voucher_code_fail": "加载优惠券代码时发生错误",
        "Select_campaign": "选择活动",
        "Create_voucher_code_success": "新优惠券代码已成功创建",
        "Create_voucher_code_fail": "创建优惠券代码时发生错误",
        "Update_voucher_code_status_success": "优惠券代码状态已成功更新",
        "Update_voucher_code_status_fail": "更新优惠券代码状态时发生错误",
        "Delete_voucher_codes_success": "优惠券代码已成功删除",
        "Delete_voucher_codes_fail": "删除优惠券代码时发生错误",
        "Delete_multiple_voucher_codes_success": "优惠券代码已成功删除",
        "Delete_multiple_voucher_codes_fail": "删除优惠券代码时发生错误",
        "Delete_voucher_code_success": "优惠券代码已成功删除",
        "Delete_voucher_code_fail": "删除优惠券代码时发生错误",
        "Update_multiple_voucher_code_status_success": "优惠券代码状态已成功更新",
        "Update_multiple_voucher_code_status_fail": "删除优惠券代码状态时发生错误",
        "Please_input_voucher_code": "请输入优惠券代码",
        "Please_input_voucher_code_at_least_4_characters": "请输入至少 4 个字符。",
        "Please_select_campaign": "请选择活动",
        "Please_input_value": "请输入值",
        "Please_select_date": "请选择日期",
        "Invalid_file": "文件内容无效",
        "File_size_larger_than_5Mb": "文件大小大于 5Mb",
        "Import_vouchers_success": "文件已成功导入",
        "Import_vouchers": "导入文件",
        "Number_successful_imports": "导入商品",
        "Number_skipped": "跳过项目",
        "File": "文件",
        "Phone_Numbers": "电话号码",
        "Import_voucher_code_success": "优惠券代码 <b>{0}</b> 已成功导入",
        "Import_voucher_code_fail": "优惠券代码 <b>{0}</b> 导入失败。 请再试一次",
        "All": "全部"
    },
    "pointConfig": {
        "point_config": "点配置",
        "Convert_spent_amount_to_points": "将消费金额转换为积分",
        "Convert_topup_amount_to_points": "将充值金额转换为积分",
        "Redeem_points_to_voucher": "积分兑换优惠券",
        "others": "其他",
        "Online_Payment": "网上支付",
        "transport": "出行约车",
        "parcel": "包裹",
        "food": "食物",
        "mart": "市场",
        "delivery": "送货",
        "intercity": "专线",
        "applyCash": "申请现金",
        "point": "观点",
        "vourcherName": "姓名",
        "pointsToRedeem": "积分兑换",
        "vourcherValue": "券的价值",
        "validTime": "有效时间",
        "month": "月份",
        "day": "天",
        "termAndCondition": "条款和条件",
        "Actions": "操作",
        "add_voucher": "添加",
        "instructionURL": "说明链接",
        "displayName": "在超级应用上显示名称",
        "isActive": "启用",
        "EDIT_VOUCHER": "更新优惠券",
        "DETAIL_VOUCHER": "优惠券详情",
        "CREATE_VOUCHER": "添加优惠券",
        "update_successfully": "点配置设置已成功更新",
        "Create_voucher_successfully": "新优惠券创建成功",
        "Create_voucher_fail": "创建优惠券时发生错误",
        "Update_voucher_successfully": "优惠券已成功更新",
        "Update_voucher_fail": "更新优惠券时发生错误",
        "Delete_voucher_successfully": "优惠券已成功删除",
        "Delete_voucher_fail": "删除凭证时发生错误",
        "Confirmation": "确认",
        "Delete_voucher_confirmation": "您想删除此优惠券吗？",
        "Please_enter_value_least_one": "需要转化率！ 设置消费金额或充值金额以继续。",
        "Please_enter_value_is_number_spentAmount": "请在“转换消费金额”服务“{0}”中输入有效金额。",
        "Please_enter_value_is_number_topUp": "请在“转换充值金额”方法“{0}”中输入有效金额。"
    },
    "resetPassword": {
        "New_Password": "新密码",
        "New_Comfirm_Password": "新确认密码",
        "Save": "保存",
        "INFO_SUCCESS": "重置密码链接已发送至您的电子邮件",
        "TOKEN_EXPIRED": "您的重置密码令牌已过期",
        "Back_To_Login_Page": "返回登录页面"
    },
    "permission": {
        "editBooking": "编辑外卖预订",
        "companyOperator": "供应商操作员",
        "Third_Party_Integration_Booking": "第三方集成（Booking.com）",
        "Third_Party_Integration_HolidayTaxis": "3rd party Integration (HolidayTaxis)",
        "supplierPermission": "Supplier permission",
        "Hint_edit_booking": "允许供应商编辑外发订单。",
        "View": "查看",
        "Actions": "操作",
        "EditFare": "编辑票价",
        "EditDriverEarning": "编辑司机收入",
        "EditTotal": "编辑总计",
        "ImportBookings": "导入出行订单",
        "ImportDeliveryBookings": "导入送货订单",
        "ShowFare": "显示票价",
        "delete_permission": "删除权限",
        "DELETE_CONFIRM": "您确定要删除所选权限吗！",
        "Yes": "是",
        "No": "否",
        "Cancel": "取消",
        "DRIVERS": "司机",
        "Driver_list": "司机列表",
        "Enter_to_search": "输入搜索",
        "Permitted_driver_list": "允许的司机名单",
        "Save": "保存",
        "CAR": "车辆",
        "Car_list": "车清单",
        "Permitted_car_list": "允许车辆清单",
        "Edit_permission": "编辑权限",
        "Add_permission": "添加权限",
        "Detail_permission": "详细权限",
        "Name": "名称",
        "Driver_Car": "司机/车辆",
        "All": "全部",
        "Company": "车队供应商",
        "By_specific": "按特定",
        "Drivers": "司机",
        "Cars": "车辆",
        "Show_per_page": "每页项目",
        "Page": "页",
        "show_pages": "显示了 {0} - {1} 个（共 {2} 个）",
        "Delete": "删除",
        "Edit": "编辑",
        "Status": "状态",
        "Action": "操作",
        "Inactive": "停用",
        "Active": "启用",
        "Update_status_error": "更新状态错误",
        "All_tooltip": "所有司机都将显示在地图、提示、司机、运营和报告中。 所有车辆都将显示在 Car mgmt 上",
        "Company_tooltip": "该供应商获得许可的司机将显示在地图、提示、司机、运营和报告中。 该公司允许使用的车辆将显示在车辆管理",
        "By_specific_tooltip": "此列表中允许的司机将显示在“司机”，“运营”中。 此列表中允许的车辆将显示在车辆管理",
        "Full_permissions": "完整权限",
        "ChangeBalance": "更改信用余额",
        "ChangeCashBalance": "更改现金余额",
        "TotalBalance": "总余额",
        "WriteOffDebt": "注销欠款",
        "topupPaxWallet": "改变余额",
        "SeFleetCommission": "设置车队佣金（所有司机）",
        "EditFleetCommission": "编辑车队佣金",
        "EditBalance": "编辑余额",
        "ViewHeatMap": "查看热图",
        "Delete_permission_success": "权限已删除成功",
        "Delete_permission_fail": "删除权限时发生错误",
        "Create_permission_fail": "创建新权限时发生错误",
        "Create_permission_success": "新权限已创建成功",
        "Update_permission_fail": "更新权限时发生错误",
        "Update_permission_success": "权限已更新成功",
        "No_action_selected": "请在操作列表中至少勾选一项操作",
        "Required_fields": "必填字段",
        "sendSMS": "发送消息（短信）",
        "sendInbox": "发送消息（收件箱）",
        "PayToDriver": "查看“支付给司机”",
        "PayoutHistory": "查看“付款历史记录”",
        "Pay": "支付司机费用",
        "ActivateDriver": "启用司机",
        "Export": "导出",
        "Export_csv": "导出.csv",
        "Street-sharing_rate": "街头拼车计费"
    },
    "thirdPartySettings": {
        "category": "类别",
        "Not_over_limit_subs": "您不能创建超过 10 个子位置",
        "Show_per_page": "每页项目",
        "Page": "页",
        "show_pages": "显示了 {0} - {1} 个（共 {2} 个）",
        "Delete": "删除",
        "Edit": "编辑",
        "Status": "状态",
        "Actions": "操作",
        "Action": "操作",
        "Inactive": "停用",
        "Active": "启用",
        "website": "网站",
        "Check_Google_Address": "从纬度和经度检查 Google 地址",
        "Delete_Category_success": "类别已成功删除",
        "Delete_category_fail": "删除类别时发生错误",
        "availableTime": "接单时间",
        "Please_choose_at_least_one_category": "请至少选择一个类别",
        "Please_input_category_name": "请输入类别名称",
        "Create_category_success": "类别创建成功",
        "Create_category_fail": "创建类别时发生错误",
        "Update_third_party_success": "位置已成功更新",
        "Update_category_fail": "更新类别时发生错误",
        "All_Category": "所有类别",
        "Delete_Category": "编辑类别",
        "Edit_Category": "编辑类别",
        "Add_Category": "添加类别",
        "Add_Third_Party_location": "添加第三方位置",
        "Add": "添加",
        "Edit_Third_Party_location": "编辑第三方地点",
        "Location_Not_Found": "未找到位置",
        "Create_third_party_success": "位置已成功创建",
        "Create_third_party_fail": "创建位置时发生错误",
        "Update_third_party_fail": "更新位置时发生错误",
        "Alias": "别名",
        "Address": "地址",
        "Latitude": "纬度",
        "Longitude": "经度",
        "Please_input_address": "请输入地址",
        "Please_input_name": "请输入地点名称",
        "Please_input_latitude": "请输入纬度",
        "Please_input_longitude": "请输入经度",
        "Save": "保存",
        "Cancel": "取消",
        "Required_fields": "必填字段",
        "Last_update": "最后更新",
        "Username": "用户名",
        "locationType": "位置类型",
        "single": "单一地点",
        "multiple": "多个地点",
        "AddMore": "添加更多",
        "locationList": "地点列表",
        "instructionLink": "说明链接",
        "location": "地点",
        "checkOnMap": "查看地图",
        "Remove": "消除",
        "placeType": "类型",
        "all": "全部",
        "PU": "出发地",
        "DO": "目的地",
        "namePlaceholder": "例如 1号航站楼",
        "instructionLinkPlaceholder": "包含完整说明的 Html 页面",
        "instructionLinkTooltip": "请输入说明页面的链接。 乘客可以按照此链接上的说明找到上车地点。",
        "longitudePlaceholder": 108.1837969,
        "latitudePlaceholder": 16.0393381,
        "Error_message_lat_in_range": "纬度必须介于 -90.0 和 90.0 之间",
        "Error_message_lng_in_range": "经度必须介于 -180.0 和 180.0 之间。",
        "Delete_third_party_success": "位置已成功删除",
        "Delete_third_party_fail": "删除位置时发生错误",
        "Delete_location": "删除位置",
        "Note_guide": "注意：在地图上搜索时，您的位置将很快用图钉标记出来。"
    },
    "city": {
        "DELETE_CITY": "删除城市",
        "DELETE_CONFIRM": "您确定要删除所选城市吗？",
        "Yes": "是",
        "No": "否",
        "Cancel": "取消",
        "EDIT_CITY": "编辑城市",
        "ADD_CITY": "添加城市",
        "Name": "姓名",
        "Required_fields": "必填字段",
        "Save": "保存",
        "Add": "添加",
        "Show_per_page": "每页项目",
        "Page": "页",
        "show_pages": "显示了 {0} - {1} 个（共 {2} 个）",
        "Delete": "删除",
        "Edit": "编辑",
        "DETAIL_CITY": "详细城市",
        "Update_city_success": "城市已更新成功",
        "Create_city_success": "新城市创建成功",
        "Delete_city_success": "城市已删除成功",
        "cityName": "城市名",
        "Service": "服务",
        "serviceTransportation": "出行约车",
        "serviceAssistant": "跑腿",
        "serviceDelivery": "送货",
        "isShowDriverSignUp": "在提供商注册中显示",
        "note_isShowDriverSignUp": "(从4.6.46及以上版本开始申请新的注册模块)"
    },
    "jobTab": {
        "DELETE_JOBTAB": "删除作业选项卡",
        "DELETE_CONFIRM": "您确定要删除选定的“作业”选项卡吗？",
        "Yes": "是",
        "No": "否",
        "Cancel": "取消",
        "EDIT_JOBTAB": "编辑工作标签",
        "ADD_JOBTAB": "添加工作标签",
        "Name": "姓名",
        "Required_fields": "必填字段",
        "Save": "保存",
        "Add": "添加",
        "Show_per_page": "每页项目",
        "Page": "页",
        "show_pages": "显示了 {0} - {1} 个（共 {2} 个）",
        "Delete": "删除",
        "Edit": "编辑",
        "DETAIL_JOBTAB": "详细工作标签",
        "Update_JOBTAB_success": "职位标签已成功更新",
        "Create_JOBTAB_success": "新工作标签已成功创建",
        "Delete_JOBTAB_success": "工作标签已成功删除",
        "JOBTABName": "工作标签名称",
        "jobsLink": "关联"
    },
    "driverNote": {
        "name": "司机注意事项",
        "DELETE": "删除司机备注",
        "DELETE_CONFIRM": "您确定要删除所选的司机备注吗？",
        "EDIT": "编辑司机备注",
        "ADD": "添加司机备注",
        "DETAIL": "详细司机注意事项",
        "Update_success": "司机备注已成功更新",
        "Create_success": "新的司机备注已成功创建",
        "Delete_success": "司机备注已成功删除",
        "Delete_fail": "删除司机备注时发生错误",
        "Load_fail": "加载司机备注时发生错误",
        "Update_fail": "更新司机备注时发生错误",
        "Create_fail": "创建司机备注时发生错误"
    },
    "flightIntegration": {
        "Username": "用户名",
        "provider": "航班提供商",
        "Key": "API密钥",
        "apiId": "API ID",
        "Save": "保存",
        "Update_flight_integration_success": "航班集成已成功更新",
        "Update_flight_integration_fail": "更新航班集成时发生错误"
    },
    "smsIntegration": {
        "provider": "短信提供商",
        "apiToken": "API令牌",
        "subAccountId": "子账户ID",
        "senderIdSource": "发件人 ID（来源）：",
        "apiKey": "API密钥",
        "username": "用户名",
        "shortCode": "短代码",
        "key": "钥匙",
        "webService": "网络服务",
        "bearerToken": "不记名令牌",
        "keyId": "钥匙编号",
        "email": "电子邮件",
        "appKey": "应用程序密钥",
        "sender": "发件人",
        "password": "密码",
        "maskedPhone": "屏蔽电话号码",
        "senderId": "发件人ID",
        "webservice": "网络服务",
        "brandname": "品牌",
        "appSecret": "应用秘密",
        "consumerKey": "消费者钥匙",
        "requestUrl": "请求网址",
        "authId": "授权ID",
        "authToken": "身份验证令牌",
        "phloId": "PHLO ID",
        "dlcNumber": "10DLC 编号",
        "app_key": "应用程序密钥",
        "api_key": "API密钥",
        "accountSID": "账户SID",
        "applicationId": "应用程序ID",
        "phoneNumber": "短信号码1",
        "phoneNumber2": "短信号码2",
        "SenderID_1": "SenderID 1",
        "SenderID_2": "SenderID 2",
        "smsMarketingNumber": "短信制作电话号码",
        "updateSMSSuccess": "短信集成已成功更新",
        "Deleted_success": "The SMS has been deleted successfully",
        "receiverCountries": "Receiver Countries",
        "error": {
            "ERROR_CREATE": "The errors occurred while creating sms",
            "COUNTRY_EXISTED": "Country already exist on system",
            "ERROR_DELETE": "Failed to delete SMS",
            "ERROR_UPDATE": "Failed to update SMS"
        },
        "identifierId": "Identifier ID"
    },
    "mDispatcher": {
        "mDispatchers": "伙伴",
        "Active": "启用",
        "Inactive": "停用",
        "Deactivate": "停用",
        "Activate": "启用",
        "Delete": "删除",
        "Save": "保存",
        "Edit": "编辑",
        "ADD_MDISPATCHER": "添加合作伙伴",
        "EDIT_MDISPATCHER": "编辑合作伙伴",
        "Phone_number": "电话号码",
        "first_name": "姓氏",
        "last_name": "名字",
        "email": "电子邮件",
        "address": "地址",
        "mDispatcher_type": "合作伙伴类型",
        "mDispatcher_type_toolTip": "如需添加更多合作伙伴类型，请前往“设置”>“合作伙伴类型”",
        "queuing_area_toolTip": "要添加更多排队区，请进入排队区菜单。",
        "mDispatcher": "伙伴",
        "card_management": "信用卡管理",
        "Select_mDispatcher_type": "选择合作伙伴类型",
        "Amount": "金额",
        "Percent": "百分比",
        "Yes": "是",
        "Required_fields": "必填字段",
        "Cancel": "取消",
        "commission_type": "佣金类型",
        "commission_value": "佣金价值",
        "Name": "名称",
        "Phone_Number": "电话号码",
        "queuing_area": "排队区",
        "mdispatcher_type": "合作伙伴类型",
        "registered_from": "注册自",
        "registration_date": "注册日期",
        "status": "状态",
        "actions": "操作",
        "Select_queue_area": "选择排队区域",
        "for_new_mDipatcher_account_password_default_is_password": "对于新的合作伙伴帐户，默认密码为“12345678”",
        "Create_mDispatcher_success": "新合作伙伴创建成功",
        "Create_mDispatcher_fail": "创建合作伙伴时发生错误",
        "Load_mDispatcher_fail": "加载合作伙伴时发生错误",
        "Update_mDispatcher_success": "合作伙伴已成功更新",
        "Update_mDispatcher_fail": "更新合作伙伴时发生错误",
        "Update_mDispatcher_status_success": "合作伙伴状态已成功更新",
        "Update_mDispatcher_status_fail": "更新合作伙伴状态时发生错误",
        "Update_mDispatcher_statuses_success": "合作伙伴状态已成功更新",
        "Update_mDispatcher_statuses_fail": "更新合作伙伴状态时发生错误",
        "Delete_mDispatcher_success": "合作伙伴已成功删除",
        "Delete_mDispatcher_fail": "删除合作伙伴时发生错误",
        "Delete_mDispatchers_success": "合作伙伴已成功删除",
        "Delete_mDispatchers_fail": "删除合作伙伴时发生错误",
        "Do_you_want_to_delete_these_mDipatchers": "您想删除这些合作伙伴吗？",
        "Only_mDistcher_in_the_inactive_status_can_be_deleted": "***只能删除处于停用状态的伙伴",
        "No_card": "没有找到卡",
        "Reset_Password": "重设密码",
        "Reset_password_success": "合作伙伴密码已成功重置",
        "Reset_password_fail": "重置合作伙伴密码时发生错误",
        "Do_you_want_to_reset_password": "您想重置密码吗？",
        "ERROR_EXISTED_PHONE": "该电话已存在于系统中。 请输入另一项",
        "ERROR_INPUT_VALIDPHONE": "请输入一个有效的电话号码。",
        "Partner_detail": "合作伙伴详情",
        "ALERT_SELECT_ACTIVATE": "请选择合作伙伴进行启用。",
        "ALERT_SELECT_DEACTIVATE": "请选择要停用的合作伙伴。",
        "CONFIRM_DEACTIVATE_MULTI": "您想停用这些合作伙伴吗？",
        "CONFIRM_ACTIVATE_MULTI": "您想启用这些合作伙伴吗？",
        "ERROR_COMMISSION_NUMBERS": "请输入有效号码",
        "No_Support": "该区域暂不支持添加新卡",
        "Add": "添加",
        "Default_zone": "默认区域",
        "required_email_payment": "需要合作伙伴的电子邮件。 请更新个人资料以继续。",
        "titleGuestQR": "合作伙伴嘉宾-二维码链接",
        "questQRContent": "您的个性化合作伙伴预订链接就在这里！ 将其显着地展示在酒吧、酒店接待处、客房和您的网站上（任何客户可能需要乘车或送货的地方）。 考虑使用您独特的二维码设计，让客户可以快速轻松地扫描以预订旅程！",
        "View_Guest_QR": "查看乘客二维码"
    },
    "mDispatcherType": {
        "Name": "姓名",
        "Required_fields": "必填字段",
        "Save": "保存",
        "Cancel": "取消",
        "No": "否",
        "Add": "添加",
        "Show_per_page": "每页项目",
        "Page": "页",
        "show_pages": "显示了 {0} - {1} 个（共 {2} 个）",
        "Delete": "删除",
        "Edit": "编辑",
        "DELETE_MDISPATCHER_TYPE": "删除合作伙伴类型",
        "DELETE_CONFIRM": "您确定要删除合作伙伴类型吗？",
        "Yes": "是",
        "EDIT_MDISPATCHER_TYPE": "编辑合作伙伴类型",
        "ADD_MDISPATCHER_TYPE": "添加合作伙伴类型",
        "DETAIL_MDISPATCHER_TYPE": "详细合作伙伴类型",
        "Actions": "操作",
        "Delete_mDispatcherType_success": "合作伙伴类型已成功删除",
        "Delete_mDispatcherType_fail": "删除合作伙伴类型时发生错误",
        "Load_mDispatcherType_fail": "加载合作伙伴类型时发生错误",
        "Create_mDispatcherType_success": "新合作伙伴类型已成功创建",
        "Create_mDispatcherType_fail": "创建合作伙伴类型时发生错误",
        "Update_mDispatcherType_success": "合作伙伴类型已成功更新",
        "Update_mDispatcherType_fail": "更新合作伙伴类型时发生错误"
    },
    "queuingArea": {
        "queuing_area_info": "排队区信息",
        "queuingDrivers": "启用的司机",
        "Name": "姓名",
        "Required_fields": "必填字段",
        "Save": "保存",
        "Cancel": "取消",
        "Yes": "是",
        "No": "否",
        "Add": "添加",
        "Show_per_page": "每页项目",
        "Enter_to_search": "输入搜索",
        "Page": "页",
        "show_pages": "显示了 {0} - {1} 个（共 {2} 个）",
        "Delete": "删除",
        "Edit": "编辑",
        "Active": "启用",
        "Inactive": "停用",
        "Deactivate": "停用",
        "Activate": "启用",
        "drivers": {
            "order": "命令",
            "Driver_name": "司机姓名",
            "Phone_Number": "电话号码",
            "driverLicenseNumber": "牌照",
            "Car_Type": "车型",
            "checkInTime": "登记时间",
            "duration": "期间",
            "timestampScore": "时间戳分数",
            "changeOrderDriver": "改变顺序",
            "detailOrderDriver": "司机订单",
            "Yes": "是",
            "No": "否",
            "insert": "插入",
            "refresh": "刷新",
            "insertDriver": "将司机插入队列",
            "orderDriver": "订单#",
            "reason": "原因",
            "removeDriver": "从队列中删除司机",
            "removeDriverConfirm": "您想从队列中删除该司机吗？",
            "driverNotStay": "该司机没有停留在排队区域内。 请稍后再试。",
            "overOrder": "订单号不能大于队列中司机的总数。",
            "noDriverFound": "没有找到司机",
            "driverUpdateErrRs": "司机不在排队区。 请稍后再试。",
            "orderUpdateErrRs": "订单号不能大于队列中司机的总数。",
            "removeDriverSuccess": "司机已成功被移出排队区。",
            "removeDriverFail": "从队列区域删除司机时发生错误。",
            "operationInsertFailRs": "司机不存在或不可用。",
            "versionInsertFailRs": "要应用此功能，司机需要将其司机应用程序升级到最新版本。",
            "queueInsertFailRs": "队列不存在或司机不在队列区域。 请稍后再试。",
            "vehicleInsertFailRs": "队列不支持司机的车型。",
            "orderInsertFailRs": "订单号不能大于队列中司机的总数。",
            "positionInsertFailRs": "将司机插入队列区域时发生错误。",
            "insertDriverSuccess": "司机已成功插入队列区。",
            "changeOrderSuccess": "司机位置已成功更改。",
            "insertDriverFail": "从队列区域插入司机时发生错误。",
            "changeOrderFail": "从队列区域更改司机位置时发生错误。",
            "selectToInsert": "搜索：司机姓名、电话号码",
            "updateBy": "由操作员更新",
            "changeOrder": "改变顺序",
            "remove": "消除"
        },
        "keepDriverPlaceCancelNoShow": "取消预订/NOSHOW时保留司机的位置",
        "keepDriverPlaceUnavailable": "当司机不在时，保留司机的位置",
        "range": "队列范围",
        "queueRangeTooltip": "这是司机在司机App上看到其排队位置编号的方式。例如：\n 将队列范围设置为1：司机将看到他们在队列中的位置为单个数字：1、2、3、4等。\n 将队列范围设置为5：司机将在队列中看到自己的位置，范围为1-5、6-10、11-15、16-20等。\n 将队列范围设置为10：司机将在队列中看到自己的位置：1-10、11-20、21-30、31-40等。",
        "queueCanceledTooltip": "“- 当乘客取消或NOSHOW时，如果司机在设定时间内返回队列，司机将保持在最前面的位置。”\n  - 如果司机在完成短途预订后在规定时间内返回队列，则司机的位置将被设置为高优先级。",
        "DELETE_QUEUING_AREA": "删除排队区",
        "DELETE_CONFIRM": "您想删除排队区吗？",
        "EDIT_QUEUING_AREA": "编辑排队区",
        "ADD_QUEUING_AREA": "添加排队区",
        "DETAIL_QUEUING_AREA": "详细排队区",
        "Queuing_area": "排队区",
        "Address": "地址",
        "Enter_a_location": "输入地点",
        "Entered_Address_is_incorrect": "系统无法确定您的位置。 请使用建议的地址。",
        "Not_supported_zone": "该队列区域不在受支持的区域内",
        "Active_mDispatcher_list": "启用的合作伙伴名单",
        "Queue_list": "队列列表",
        "Allow_radius": "允许半径",
        "Min_drivers_required": "# 所需的最少司机",
        "Open_queuing_area": "开放排队区",
        "Auto_dispatch": "自动调度给队列中的司机（无需使用合作伙伴应用程序）",
        "Active_car_list": "启用的车辆列表",
        "mDispatcher": "合作伙伴",
        "Google_Address": "谷歌地址",
        "Status": "状态",
        "Update_status_error": "更新状态错误",
        "driver_checkin_area": "司机报到区",
        "EDIT_POLYGONS": "编辑区域",
        "pax_pickup_area": "乘客上车区",
        "pickup_area": "接送区",
        "your_chosen_polygon": "您选择的多边形",
        "other_queues": "其他队列",
        "operation_zones": "运营区域",
        "delete_pickup_area_confirm": "您确定要删除此接送区域吗？",
        "delete_pickup_area": "删除接送区域",
        "please_choose_driver_check_in_area": "请选择司机报到区域。",
        "please_choose_pick_up_area": "请选择接送区域。",
        "Load_queuing_area_fail": "加载排队区域时发生错误",
        "Create_queuingArea_success": "排队区创建成功",
        "Update_queuingArea_success": "排队区已成功更新",
        "Delete_queuingArea_success": "排队区已成功删除",
        "Delete_queuingArea_fail": "删除排队区时发生错误",
        "Create_queuingArea_fail": "创建排队区时发生错误",
        "Load_queuingArea_fail": "加载排队区域时发生错误",
        "Update_queuingArea_fail": "更新排队区域时发生错误"
    },
    "appBanner": {
        "Save": "保存",
        "Pax_app": "乘客App",
        "Apply_to": "适用于",
        "Please_select_view": "请选择视图",
        "Select_Type": "请选择横幅类型",
        "Home_view": "首页",
        "Other_view": "其他",
        "Banner_1": "横幅 #1",
        "Upload_an_image": "未选择图像",
        "Action": "操作",
        "NA": "不适用",
        "Link_to_website": "链接到网站",
        "Make_a_call": "打个电话",
        "Send_an_email": "发送电子邮件",
        "Banner_2": "横幅 #2",
        "Banner_3": "横幅#3",
        "Driver_app": "司机App",
        "pax": "乘客App",
        "driver": "司机App",
        "Name": "姓名",
        "viewType": "类型",
        "status": "状态",
        "image": "图像",
        "html": "超文本标记语言",
        "Delete_Banner": "删除横幅",
        "Delete_confirm_banner": "您确定要删除选定的横幅吗？",
        "Delete_banner_success": "横幅已成功删除",
        "Delete_banner_fail": "删除横幅时发生错误",
        "edit_banner": "编辑横幅",
        "detail_banner": "详细横幅",
        "create_banner": "添加横幅",
        "note_1": "* 文件扩展名必须为 .jpg、.jpeg、.png、.gif，且小于 %{limit}MB。",
        "note_2": "* 图像的长宽比应为 16:9。",
        "note_3": "* 其他视图包括收件箱、推荐朋友、收据、我的订单、促销代码、添加信用卡/付款方式、个人资料、等待流程/确认预订、已完成视图。",
        "Update_app_banner_success": "应用横幅已成功更新",
        "Update_app_banner_fail": "无法更新应用横幅！",
        "Create_app_banner_success": "应用横幅已成功创建",
        "Create_app_banner_fail": "创建应用横幅失败！",
        "Note": "请使用 .png 格式的文件（透明背景，大小 400:200px）以获得更好的应用性能",
        "Limit_size_upload": "文件大小必须小于 8Mb。",
        "display_duration": "显示时长",
        "playback_settings": "播放设置",
        "display_banner_again_after": "之后再次显示横幅",
        "stop_display_banner_after": "之后停止显示横幅",
        "enable_playback": "启用播放",
        "Update_playback_success": "播放设置已成功更新",
        "Update_playback_fail": "更新播放设置失败！",
        "language": "语言",
        "single": "单一语言",
        "multi": "多种语言",
        "es-ES": "Spanish",
        "en-US": "English",
        "fr-FR": "French",
        "id-ID": "Bahasa indonesia",
        "vi-VN": "Vietnamese",
        "ur-PK": "Urdu",
        "ms-MY": "Malaysia",
        "ar-AR": "Arabic",
        "nb-NO": "Norwegian",
        "en-GB": "English (United Kingdom)",
        "am-ET": "Amharic",
        "ro-RO": "Romanian",
        "version": "App版本",
        "comparisonVersionApp": {
            "is": "是",
            "is_not": "不是",
            "is_one_of": "是其中之一",
            "is_not_one_of": "不是其中之一",
            "greater_or_equal": "大于或等于",
            "less_or_equal": "小于或等于"
        },
        "platform": "平台",
        "platformItem": {
            "all": "全部",
            "iOS": "iOS系统",
            "android": "安卓"
        },
        "userStatus": "用户状态",
        "Active": "启用",
        "Inactive": "停用",
        "in_review_completed": "审核中（简介已完成）",
        "in_review_incomplete": "审核中（个人资料不完整）",
        "in_review_inProgress": "审核中（简介正在进行中）",
        "zones": "区域"
    },
    "reservation": {
        "name": "预订",
        "company": "供应商",
        "carType": "车型",
        "licensePlate": "车牌：%{name}",
        "driver": "司机：%{姓名}",
        "showAllDrivers": "显示所有司机",
        "available": "可用的",
        "reserved": "被预订",
        "search_holder": "供应商、司机姓名、车型、车牌",
        "all": "全部",
        "today": "今天",
        "thisWeek": "本星期",
        "day": "天",
        "week": "周",
        "bookingID": "订单ID",
        "tripId": "行程ID",
        "pickupTime": "接载时间",
        "pickupLocation": "上客位置",
        "monday": "周一",
        "tuesday": "周二",
        "wednesday": "周三",
        "thursday": "周四",
        "friday": "周五",
        "saturday": "周六",
        "sunday": "周日",
        "overlapped": "重叠",
        "noData": "没有找到任何数据。",
        "bookingDetails": "预订详情",
        "reservedHint": "预订已确认",
        "availableHint": "没有任何已确认的预约订单",
        "overlappedHint": "两个或多个已确认的预订重叠"
    },
    "dashboard": {
        "API": "API",
        "cc": "控制中心",
        "dmc": "预订板",
        "streetSharing": "街头拼车",
        "kiosk": "自助机",
        "mDispatcher": "合作伙伴",
        "paxApp": "乘客App",
        "webBooking": "网上预订",
        "carHailing": "网约车",
        "acceptance": "接单",
        "booking_com": "Booking.com",
        "completed": "完成",
        "noShow": "NOSHOW",
        "canceled": "取消",
        "incident": "事故",
        "rate1": "1 星",
        "rate2": "2 星",
        "rate3": "3 星",
        "rate4": "4 星",
        "rate5": "5 星",
        "Export_to_Excel": "导出到 Excel",
        "Profit": "利润",
        "day_change": "每日变化",
        "week_change": "每周变化",
        "month_change": "每月变化",
        "Booking_requests": "预订请求",
        "Complete_bookings": "完成订单",
        "New_customers": "新客户",
        "Booking_dashboard": "预订板",
        "others": "其他的",
        "Revenue_booking": "利润及完成订单",
        "Profit_finish_booking": "利润及完成订单",
        "Booking_request": "建立订单",
        "Finished_booking": "完成订单",
        "failed": "失败的",
        "partialCompleted": "部分完成",
        "Finished_booking_char_header": "已完成预订 ({0})",
        "Bookings": "预订 ({0})",
        "Ratings": "评分 ({0})",
        "Daily": "日常的",
        "Weekly": "每周",
        "Monthly": "每月",
        "Total_customer": "客户总数{0}",
        "ProfitToolTips": "扣除所有费用后车队赚到的钱",
        "RevenueToolTips": "车队在选定期间实际收到的钱，包括促销、司机扣除、税收等。",
        "ProfitChangeToolTips": "与上期相比机队利润变化百分比",
        "BookingRequestsToolTips": "选定时间段内创建的实时订单和订单总数",
        "PercentageOfAcceptanceToolTips": "所选时间段内接受订单的百分比",
        "BookingChangeHint": "与上一期间相比，已创建预订的变化百分比",
        "CompletedBookingHint": "选定期间内完成的实时订单和预订总数",
        "PercentageOfSatisfactionHint": "选定时间段内的满意度百分比",
        "CompleteBookingDailyChangeHint": "与上一时期相比，已完成预订的变化百分比",
        "NewCustomersHint": "选定期间内注册的新客户总数",
        "CustomerChangeHint": "与上期相比客户数量及变化比例",
        "TotalCustomer": "系统内客户总数",
        "ProfitAndFinishedBookingHint": "选定期间机队的利润和完成预订量",
        "BookingRequestHint": "选定时间段内创建的实时订单和预订总数",
        "FinishedBookingChartsHint": "选定时间段内实时订单和已关闭的预订总数，包括取消、未入住、事件和已完成",
        "Customer_count": "{0} 客户数量",
        "Revenue": "收入"
    },
    "referral": {
        "first_package": "第一个套餐（即第一个完成的 Pax 预订）",
        "driver_refers_to_pax": "司机介绍客户",
        "driver_will_get": "司机将得到",
        "money": "钱",
        "amount": "金额",
        "percent": "百分",
        "settlement": "佣金（每笔已完成预订小计金额的百分比）",
        "pax_will_get": "乘客获得",
        "promotion_code": "促销代码",
        "select_a_promotion": "选择促销代码",
        "maximum": "最大限度",
        "second_package": "第二套餐",
        "from": "从",
        "to": "到",
        "After_2nd_package": "第二套餐后",
        "from_end_value_of_above_package": "从上述最后一个套餐价值 + 1",
        "Expiration_date": "截止日期",
        "Never": "绝不",
        "Customized_date": "定制日期",
        "limited": "有限的",
        "unlimited": "无限",
        "Valid_from": "有效期自",
        "Valid_to": "有效",
        "Invitation_limit": "被推荐人员限制/车手",
        "Status": "状态",
        "Active": "启用",
        "Inactive": "停用",
        "note": "* 请注意，一旦活动开始，任何奖励价值的更新都只会影响新客户。",
        "Required_fields": "必填字段",
        "refferal_update_successfully": "推荐设置已成功更新",
        "distributeIncentiveToDriver": "向司机发放奖励",
        "Immediate": "即时",
        "Periodic": "定期（手动）",
        "Period": "时期",
        "startDate": "开始日期",
        "nextDistributionDate": "下次分发日期",
        "oneMonth": "1个月",
        "threeMonth": "3个月",
        "sixMonth": "6个月",
        "passenger_refers_to_pax": "客户指的是客户",
        "referer_will_get": "推荐人将获得",
        "referee_will_get": "被推荐人将获得",
        "promo": "推广",
        "Pax_invitation_limit": "邀请限制/用户",
        "promoPaidBy": "促销费用由",
        "fleet": "运营商",
        "driver": "司机",
        "promoCode": "促销代码",
        "isSendInbox": "发送收件箱",
        "isDefaultCode": "在应用程序上自动应用",
        "autoApplyOnWeb": "网上自动申请",
        "isDefaultCode_hint": "将促销代码添加到客户列表中，并在客户创建订单时自动应用于预订",
        "Actions": "操作",
        "add_promoCode": "添加",
        "Send_an_in_box_automatically_to_new_customers": "发送给客户App收件箱",
        "set_default_promo_code": "在App上自动套用",
        "EDIT_PROMO_CODE": "编辑促销代码",
        "DETAIL_PROMO_CODE": "促销代码详细信息",
        "CREATE_PROMO_CODE": "创建促销代码",
        "DELETE_PROMO_CODE": "删除促销代码",
        "DELETE_CONFIRM_PROMO_CODE": "您想删除此促销代码吗？",
        "ERROR_INPUT_SOURCE": "请至少选择一项。"
    },
    "upload_document": {
        "NO_FILE_CHOSEN": "没有选中任何文件",
        "Limit_size_upload": "文件大小超出允许的限制 {0}Mb。"
    },
    "payment_info": {
        "member_since": "会员自",
        "package_name": "包名字",
        "last_payment": "最后一次付款",
        "credit_card": "信用卡",
        "last_digits": "最后 4 位数字",
        "default": "默认",
        "add_new_card": "添加新卡",
        "history": "历史",
        "transaction_date": "交易日期",
        "transaction_id": "交易ID",
        "billing_cycle": "结算周期",
        "amount": "数量",
        "payment_history": "账单历史记录",
        "card_information": "卡信息",
        "card_holder": "持卡人",
        "card_number": "卡号",
        "expiration_date": "截止日期",
        "cvv": "CVV"
    },
    "search_by": {
        "group_id": "组号",
        "booking_id": "订单#",
        "recurring_id": "复发订单#",
        "ExternalID": "外部单号#",
        "tripId": "行程#",
        "driver_name": "司机姓名",
        "driver_phone_number": "司机电话号码",
        "license_plate": "牌照",
        "vehicle": "车辆",
        "operator": "操作员",
        "passenger_email": "旅客邮箱",
        "passenger_name": "乘客姓名",
        "passenger_phone_number": "乘客电话号码",
        "destination": "目的地",
        "pick_up": "接载",
        "flight_number": "航班 ＃",
        "book_from": "预订自",
        "note": "备注",
        "email": "电子邮件",
        "phone": "电话"
    },
    "withdrawal": {
        "transferToEWallet": "Transferred to external e-wallet *{0}",
        "channelCode": "Channel code",
        "channelName": "Channel name",
        "reversed": "Reversed",
        "requestTime": "请求时间",
        "referenceId": "参考 ＃",
        "driverName": "司机姓名",
        "phoneNumber": "电话号码",
        "company": "供应商",
        "description": "描述",
        "requestAmount": "请求金额",
        "transferAmount": "转账金额",
        "currentBalance": "当前余额",
        "currency": "货币",
        "action": "操作",
        "approve": "批准",
        "reject": "拒绝",
        "transferToBankAccount": "转入银行帐户 *{0}",
        "transferToWingBank": "已转移至 Wing 银行 ID *{0}",
        "transferToCreditWallet": "转移至信用钱包",
        "reason": "原因",
        "search": "司机姓名、电话、供应商、交易 ID、参考编号",
        "message": {
            "approve": "您确定要向该司机支付 {0} 的费用吗",
            "approveMulti": "您确定要向选定的司机付款吗？",
            "approveSuccess": "所选请求已付款",
            "approveFail": "所选请求未成功。 请再试一次。",
            "approveMultiSuccess": "已支付 {1} 个所选请求中的 {0} 个。",
            "approveMultiFail": "{1} 个选定请求中的 {0} 个未成功。 请再试一次。",
            "buttonConfirmTitle": "是",
            "closeButtonText": "否",
            "pleaseInputReason": "请输入原因",
            "rejectSuccess": "司机的请求被拒绝",
            "rejectFail": "无法拒绝所选请求"
        },
        "getDataFailure": "无法获取数据",
        "rejectRequest": "拒绝请求"
    },
    "dynamicSurcharge": {
        "operationZone": "运营区域",
        "activeSurcharge": "启用附加费",
        "surcharge": "附加费",
        "surchargeName": "附加费名称",
        "parameter": "参数（例如 1.x）",
        "pickupPoint": "接送区",
        "dropOffPoint": "下车区",
        "delete_surcharge_success": "附加费已成功删除",
        "delete_surcharge_fail": "删除附加费时发生错误",
        "update_surcharge_fail": "更新附加费时发生错误",
        "update_surcharge_success": "附加费已成功更新",
        "create_surcharge_fail": "创建附加费时发生错误",
        "create_surcharge_success": "附加费已成功创建",
        "active_surcharge_fail": "启用附加费时发生错误",
        "active_surcharge_success": "附加费已成功启用",
        "deactive_surcharge_fail": "停用附加费时发生错误",
        "deactive_surcharge_success": "附加费已成功停用",
        "delete_surcharge": "删除附加费",
        "delete_confirm_message": "您确定要删除所选的附加费吗？",
        "note": "*注意：这是您的基本车费所乘的金额。 示例：当参数为 1.5x 时，票价 20 美元将变为 30 美元"
    },
    "dynamicFare": {
        "operationZone": "运营区域",
        "activeFare": "启用票价",
        "fare": "票价",
        "fareName": "票价名称",
        "factor": "乘以因子",
        "fix_amount": "固定值",
        "parameter": "参数（例如 1.x）",
        "delete_fare_success": "票价已成功删除",
        "delete_fare_fail": "删除票价时发生错误",
        "update_fare_fail": "更新票价时发生错误",
        "update_fare_success": "票价已成功更新",
        "create_fare_fail": "创建票价时发生错误",
        "create_fare_success": "票价已成功创建",
        "active_fare_fail": "启用票价时发生错误",
        "active_fare_success": "票价已成功启用",
        "deactive_fare_fail": "停用票价时发生错误",
        "deactive_fare_success": "票价已成功停用",
        "delete_fare": "删除票价",
        "delete_confirm_message": "您确定要删除所选票价吗？",
        "note": "*注意：这是您的基本车费所乘的金额。 示例：当参数为 1.5x 时，票价 20 美元将变为 30 美元",
        "note_amount": "*注意：这是您的基本车费将被添加的金额。 示例：当设定金额为 5 美元时，票价 20 美元将变为 25 美元"
    },
    "notifications": {
        "search": "搜索预订 ID、外部 ID",
        "searchProviderName": "搜索供应商名称",
        "statusItem": {
            "all": "所有通知",
            "canceledByDriver": "出行约车 - 司机取消",
            "canceledByPartner": "联属网络营销 - 已被合作伙伴取消",
            "reservationReminder": "预约提醒",
            "localPending": "本地 - 待定",
            "reservationIsNotStarted": "预约尚未开始",
            "partnerRequestsCars": "合作伙伴请求车辆",
            "timeout": "超时",
            "noShow": "联盟 - NOSHOW",
            "affiliatePending": "联盟 - 待办",
            "affiliatePaymentFailed": "会员付款失败",
            "affiliateTimeout": "联盟超时",
            "flightApi": "航班API",
            "DebtWriteOff": "欠款注销",
            "driverCancelTrip": "专线 - 司机取消",
            "minimumSeatRequired": "所需最少座位数",
            "newFarmInJobFrom3rd": "Booking.com - 新预订",
            "canceledJobBy3rd": "Booking.com - 已取消",
            "updatedJobBy3rd": "Booking.com - 更新",
            "newFarmInJobFromHolidayTaxis": "HolidayTaxis - New booking",
            "canceledJobByHolidayTaxis": "HolidayTaxis - Canceled",
            "updatedJobByHolidayTaxis": "HolidayTaxis - Updated",
            "newFarmInJobFromKarhoo": "Karhoo - New booking",
            "canceledJobByKarhoo": "Karhoo - Canceled",
            "updatedJobByKarhoo": "Karhoo - Updated"
        },
        "sos": {
            "sosSearch": "搜索预订 ID、乘客姓名、司机姓名",
            "statusItem": {
                "all": "全部",
                "driver": "司机",
                "passenger": "乘客",
                "crew": "机组人员"
            }
        }
    },
    "smartData": {
        "No": "NO",
        "driverName": "姓名",
        "phone": "电话",
        "rating": "评分",
        "completed": "完成",
        "noShow": "NOSHOW",
        "incident": "事故",
        "canceled": "被司机取消",
        "accepted": "接单",
        "reject": "拒绝",
        "ignore": "忽略",
        "earning": "收入",
        "query": {
            "top20": "前20名",
            "top50": "前 50 名",
            "top100": "前100名",
            "bottom20": "后 20 名",
            "bottom50": "后 50 名",
            "bottom100": "后 100 名"
        },
        "status": {
            "canceled": "被司机取消",
            "noShow": "NOSHOW",
            "incident": "事故",
            "completed": "完成",
            "earning": "收入",
            "reject": "拒绝",
            "ignore": "忽略",
            "accepted": "接单"
        }
    },
    "Reject": "NOSHOW",
    "Do not allow Driver to start reservat": "联系",
    "intercityRate": {
        "Delete_intercity_rate": "删除专线价格包",
        "edit_intercity_rate": "编辑专线价格包",
        "view_intercity_rate": "查看专线价格包",
        "add_intercity_rate": "添加专线价格包",
        "Delete_confirm_intercity_rate": "您想删除该项目吗？",
        "Update_intercity_rate_success": "专线价格包已成功更新",
        "Update_intercity_rate_fail": "更新专线价格包时发生错误",
        "Created_intercity_rate_success": "专线价格包创建成功",
        "Created_intercity_rate_fail": "创建专线价格包时出现错误",
        "Update_intercity_rate_route_success": "专线价格包路线已成功更新",
        "Update_intercity_rate_route_fail": "更新专线时发生错误",
        "Created_intercity_rate_route_success": "专线价格包路线创建成功",
        "Created_intercity_rate_route_fail": "创建专线时出现错误",
        "Loading_intercity_rate_fail": "加载专线价格包详细信息时发生错误",
        "Delete_intercity_rate_route_fail": "删除专线价格包路线时发生错误",
        "Delete_intercity_rate_success": "专线价格包已成功删除",
        "Delete_intercity_rate_fail": "删除专线价格包时出现错误"
    },
    "intercityRoute": {
        "alias": "别名",
        "routeNameHint": "如需更改路线名称，请进入设置 > 区域 > 专线，并相应更改城市区域名称。",
        "pricePerSeat": "每个座位的价格（不含税）",
        "distance": "距离",
        "duration": "时长",
        "cancellationPenalty": "每个席位的罚款金额",
        "noShow": "乘客/伙伴NOSHOW",
        "penalty": "每个席位的罚款金额",
        "no_show_tooltip": "每个座位将向客户收取的费用。 除了罚款外，如果适用，客户还将缴纳税款或技术费",
        "firstLocation": "第一个地点",
        "secondLocation": "第二个地点",
        "routeOneSetup": "设置路线1",
        "routetwoSetup": "设置路线2",
        "schedules": "时间表",
        "date": "日期",
        "time": "时间",
        "editSchedule": "编辑日程",
        "addSchedule": "添加日程",
        "requestTimeEnable": "允许客户设置请求时间",
        "extraFee": "附加费",
        "hideRouteEnable": "隐藏该路线",
        "timeRange": "时间范围",
        "workingTime": "工作时间",
        "applyForOtherDays": "申请其他日期",
        "limitSeatLuggage": "限制座位/行李",
        "warningMinimumSeats": "所需最少座位数",
        "warningMinimumSeatsHint": "行程所需的最低座位数",
        "allowStartLessMinRequiredSeats": "无论最低座位要求如何，司机都会开始行程",
        "allowStartLessMinRequiredSeatsHint": "即使请求的座位数少于所需的最低座位数，也允许司机开始行程",
        "freeLuggagePerSeat": "每个座位免费行李",
        "freeLuggagePerSeatHint": "每个座位包含的免费行李数量",
        "allowToAddExtraLuggage": "添加额外行李",
        "allowToAddExtraLuggageHint": "允许乘客添加额外的行李",
        "feePerExtraLuggage": "每件额外行李的费用",
        "Delete_intercity_route": "删除专线",
        "Delete_confirm_intercity_route": "您想删除这条路线吗？",
        "Update_intercity_route_fail": "更新专线时发生错误",
        "Update_intercity_route_success": "专线已成功更新",
        "created_intercity_route_success": "专线创建成功",
        "created_intercity_route_fail": "创建专线时出现错误",
        "Delete_intercity_route_success": "专线删除成功",
        "Delete_intercity_route_fail": "删除专线时出现错误",
        "penalty_less_than_or_equal_to_price": "请输入小于或等于每个座位价格的值。",
        "scheduleTimeOverlap": "计划时间不得相互重叠",
        "allows_adding_extra": "当客户请求车队未安排的时间时，允许添加额外费用。",
        "this_is_to_limit_users": "这是为了限制用户（即客户、司机、操作员）在此路线上创建新的订单或行程。 现有订单或行程仍保留"
    },
    "dayOfWeek": {
        "0": "周日",
        "1": "周一",
        "2": "周二",
        "3": "周三",
        "4": "周四",
        "5": "周五",
        "6": "周六"
    },
    "intercityBooking": {
        "route": "路线",
        "departureDate": "出发日期",
        "departureTime": "出发时间",
        "Departure": "出发",
        "Arrival": "到达",
        "RefundFare": "退票费",
        "intercityZoneNote": "您在此处创建的区域仅用于设置您的专线价格包。",
        "zoneNameHint": "请保持您的区域名称简短、清晰，以便您的客户可以轻松搜索和选择。",
        "zoneUploadNote_2": "* 建议图片分辨率为1095x360，文件大小小于5MB。",
        "Complete_booking": "完成预订",
        "ConfirmCancel": "确认取消"
    },
    "deliveryRate": {
        "Delete_delivery_rate": "删除送货计费",
        "edit_delivery_rate": "编辑送货计费",
        "view_delivery_rate": "查看送货计费",
        "add_delivery_rate": "添加送货计费",
        "Delete_confirm_delivery_rate": "您想删除该项目吗？",
        "Update_delivery_rate_success": "送货计费已成功更新",
        "Update_delivery_rate_fail": "更新送货计费时发生错误",
        "Created_delivery_rate_success": "送货计费已成功创建",
        "Created_delivery_rate_fail": "创建送货计费时发生错误",
        "Update_delivery_rate_route_success": "送货计费路线已成功更新",
        "Update_delivery_rate_route_fail": "更新送货路线时发生错误",
        "Created_delivery_rate_route_success": "送送货计价路线已成功创建",
        "Created_delivery_rate_route_fail": "创建送货路线时发生错误",
        "Loading_delivery_rate_fail": "加载送货计费详细信息时发生错误",
        "Delete_delivery_rate_route_fail": "删除送货计价路线时发生错误",
        "Delete_delivery_rate_success": "送货计费包已成功删除",
        "Delete_delivery_rate_fail": "删除送货计费时发生错误",
        "distance_km": "每公里费用",
        "distance_mile": "每英里费用",
        "flat": "固定费用",
        "startingFee": "起始费",
        "feeFirstDistance": "费首距离",
        "feeSecondDistance": "费秒距离",
        "feeThirdDistance": "费第三距离",
        "feeAfterThirdDistance": "第三次距离后的费用",
        "feePerMinute": "每分钟票价",
        "feeMinimum": "最低费用",
        "feeFirstStop": "第一站费用",
        "feeExtraStop": "每个额外站点的费用"
    },
    "pwaSettings": {
        "pwa": "渐进式网页应用",
        "iconName": "图标和名称",
        "logoBackgroundColor": "标志、背景和颜色",
        "longName": "长名字",
        "shortName": "简称",
        "icon192": "主屏幕上的图标 (192x192)",
        "icon512": "启动屏幕上的图标",
        "logo": "徽标 (300x300)",
        "logoMenu": "徽标菜单 (300x300)",
        "backgroundImage": "背景图片 - 出行约车 (1024x500)",
        "backgroundImageDelivery": "背景图片 - 送货 (1024x500)",
        "mainColor": "主色",
        "secondaryColor": "次要颜色",
        "showMainColor": "显示主颜色而不是背景图像",
        "success": "PWA 设置已成功更新",
        "failed": "无法更新 PWA 设置。 请再试一次！",
        "errorUploadFileSize": "所需尺寸为 {0}x{0} 像素。",
        "hideWBInstallAppBtn": "隐藏安装应用程序按钮",
        "enableBookNow": "现在",
        "enableGuestCheckout": "客人结帐",
        "enableBookLater": "之后",
        "enableHideRecipient": "隐藏收件人信息（包裹内容、递送说明）",
        "pwaLink": "乘客 PWA",
        "partnerLink": "合作伙伴 PWA",
        "pwaDriverLink": "PWA for Driver",
        "providerSignUp": "Driver sign-up link",
        "AdsTracking": "Ads Tracking",
        "googleId": "Google Tag ID",
        "placeholdergoogleId": "Enter Google Tag ID",
        "metaId": "Meta Pixel ID",
        "placeholdermetaId": "Enter Meta Pixel ID"
    },
    "emailConfig": {
        "emailServer": "电子邮件服务器",
        "default": "默认",
        "customized": "定制",
        "fromName": "来自名字",
        "email": "来自电子邮件",
        "replyTo": "回复",
        "userAuth": "用户名",
        "password": "密码",
        "smtpServer": "SMTP服务器",
        "port": "SMTP端口",
        "isAuthenticated": "已认证",
        "option": "启用选项",
        "none": "无",
        "tls": "TLS",
        "ssl": "SSL",
        "save": "保存",
        "cancel": "取消",
        "success": "更新邮件服务器成功",
        "fail": "更新电子邮件服务器失败",
        "loadFail": "加载电子邮件服务器详细信息失败"
    },
    "driverFields": {
        "Load_fail": "",
        "Edit": "编辑",
        "View": "查看",
        "Cancel": "取消",
        "show": "展示",
        "hide": "隐藏",
        "EDIT_DRIVER_FIELDS": "编辑司机字段",
        "DETAIL_DRIVER_FIELDS": "详细司机字段",
        "ADD_DRIVER_FIELDS": "添加司机字段",
        "fieldKey": "字段键",
        "fieldName": "字段名称",
        "availableCC": "在指挥中心可用",
        "availableDrvSignUp": "可在司机注册中找到",
        "availableCompanyCar": "公司车辆 - 出行约车司机",
        "deliveryCompany": "公司车辆 - 送货司机",
        "availableIndividualCar": "个人车辆 - 出行约车司机",
        "deliveryIndividual": "个人车辆 - 送货司机",
        "required": "必需的",
        "Update_fail": "更新司机字段时发生错误",
        "Update_success": "司机字段已成功更新",
        "FieldKey": "字段键",
        "FieldName": "字段名称（美式英语）",
        "CommandCenter": "可在指挥中心使用",
        "SignUp": "可在司机注册中使用",
        "CompanyTransport": "公司车辆 - 约车出行",
        "CompanyDelivery": "公司车辆 - 送货",
        "IndividualTransport": "个人车辆 - 约车出行",
        "IndividualDelivery": "个人车辆 - 送货",
        "Require": "必需的",
        "Actions": "操作",
        "fieldType": "字段类型",
        "default": "默认",
        "customized": "定制"
    },
    "driverDocument": {
        "Load_fail": "",
        "Edit": "编辑",
        "View": "查看",
        "Cancel": "取消",
        "show": "展示",
        "hide": "隐藏",
        "EDIT_DRIVER_DOCUMENT": "编辑司机文档",
        "DETAIL_DRIVER_DOCUMENT": "详细司机文档",
        "ADD_DRIVER_DOCUMENT": "添加司机文档",
        "documentKey": "文档密钥",
        "documentName": "文件名称",
        "documentNameDetail": {
            "zh-HK": "繁体中文",
            "it-IT": "Standard Italian",
            "es-ES": "Spanish",
            "en-US": "American English",
            "fr-FR": "Standard French",
            "zh-CN": "简体中文",
            "id-ID": "Bahasa Indonesia",
            "vi-VN": "Vietnamese",
            "ur-PK": "Urdu (Islamic Republic of Pakistan)",
            "ms-MY": "Malay (Malaysia)",
            "ar-AR": "Arabic",
            "en-GB": "English (United Kingdom)",
            "nb-NO": "Norwegian",
            "am-ET": "Amharic",
            "ro-RO": "Romanian",
            "th-TH": "Thai",
            "ta-IN": "Tamil",
            "hi-IN": "Hindi",
            "te-IN": "Telugu",
            "ka-GE": "Georgian",
            "ru-RU": "Russian",
            "kn-IN": "Kannada"
        },
        "isIndividual": "公司车辆 - 约车出行司机",
        "deliveryCompany": "公司车辆 - 送货司机",
        "isOwner": "个人车辆 - 约车出行司机",
        "deliveryIndividual": "个人车辆 - 送货司机",
        "availableCC": "在指挥中心可用",
        "availableDrvSignUp": "可在司机注册中找到",
        "isRequired": "必需的",
        "isExpiredDate": "到期日期 - 指挥中心",
        "expiredDateForDrvSignUp": "到期日 - 司机注册",
        "requireExpiredDate": "需要到期日",
        "requireExpiredDateForDrvSignUp": "需要到期日",
        "requireDocumentId": "需要文档 ID",
        "documentId": "文档 ID - 指挥中心",
        "documentIdForDrvSignUp": "文档 ID - 司机注册",
        "Update_fail": "更新司机文档时出现错误",
        "Update_success": "司机文档已更新成功",
        "DocumentKey": "文档密钥",
        "DocumentName": "文件名称（美式英语）",
        "CommandCenter": "可在指挥中心使用",
        "SignUp": "可在司机注册中使用",
        "CompanyTransport": "公司车辆 - 约车出行",
        "CompanyDelivery": "公司车辆 - 送货",
        "IndividualTransport": "个人车辆 - 约车出行",
        "IndividualDelivery": "个人车辆 - 送货",
        "Require": "必需的",
        "Actions": "操作"
    },
    "holidayTaxis": {
        "title": "HolidayTaxis API Credentials",
        "note": "Communicate with HolidayTaxis's technical team to provide you with api key to connect to their system.",
        "apiKey": "API key",
        "placeHolderApiKey": "Enter api key"
    },
    "trailNotes": {
        "maxLengthFiles": "Maximum 3 files per comment",
        "maxSizeFiles": "Maximum 10MB per file",
        "typeFiles": "File type supported: pdf, doc, docx, csv, xls, xlsx, jpeg, jpg, png, webp, txt, note",
        "maxComments": "Maximum 20 comments per booking",
        "bookingActivity": "Booking Activity",
        "viewAll": "View all",
        "operator": "Operator",
        "driver": "Driver",
        "passenger": "Customer",
        "pHInput": "Add a new comment. You can click save to save the note. You can also drag an drop multiple files here.",
        "addFiles": "Add files",
        "save": "Save",
        "inputNotes": "Please input text",
        "successDeleteTrailNotes": "Delete trail notes successfully",
        "successCreateTrailNotes": "Create trail notes successfully",
        "deleteTrailNote": "Delete a trail note",
        "msgDeleteTrailNote": "Are you sure you want to delete this?"
    },
    "supplier": {
        "permissionRequired": "Operator need to select a permission",
        "offerDetail": "Offer Detail",
        "orderDetail": "Order Detail",
        "bookingNumber": "Booking Number",
        "date": "Date",
        "pickupLocation": "Pick up Location",
        "dropoffLocation": "Drop off Location",
        "passenger": "Passenger",
        "luggage": "Luggage",
        "pickupService": "Pickup Service",
        "pickupSignage": "Pickup Signage",
        "flightNumber": "Flight Number",
        "departure": "Departure",
        "arrival": "Arrival",
        "terminal": "Terminal",
        "gate": "Gate",
        "meetGreet": "Meet & Greet",
        "specialRequests": "Special Requests",
        "additionalServices": "Additional Services",
        "price": "Price",
        "prices": "Prices",
        "guest": "Guest",
        "serviceClass": "Service Class",
        "distance": "Distance",
        "duration": "Duration",
        "acceptOffer": "Accept Offer",
        "updateBooking": "Update Booking",
        "package": "Package",
        "route": "Route",
        "assignedChauffer": "Assigned Chauffeur",
        "assignedVehicle": "Assigned Vehicle",
        "logs": "Logs",
        "link": "Links",
        "desLink": "Click to copy link",
        "trackLink": "Track link",
        "driverLink": "Driver link",
        "close": "Close",
        "links": "Links",
        "linksDes": "Click to copy link",
        "status": "Status",
        "rating": "Rating",
        "permissions": "Permissions",
        "permissionsNote": "Assign different levels of authorization to your team. You can choose whether your operators have visibility of open offers or only assignments already accepted.",
        "owner": "Owner",
        "ownerNote": "Full Access",
        "manageUsers": "Manage Users",
        "manageUsersNote": "Add/remove new operators",
        "manageDrivers": "Manage Drivers",
        "manageDriversNote": "Add/remove drivers",
        "manageVehicles": "Manage Vehicles",
        "manageVehiclesNote": "Add/remove vehicles",
        "mapView": "Live Map",
        "mapViewNote": "Track drivers and active trips live on the map.",
        "calendarView": "Calendar View",
        "calendarViewNote": "View calendar of all assigned trips.",
        "bookingView": "Booking View",
        "bookingViewNote": "View and manage current bookings, assign drivers, and allocate vehicles.",
        "offersView": "Offers View",
        "offersViewNote": "Accept new job offers.",
        "pricing": "Pricing",
        "pricingNote": "View trip prices",
        "userDetail": "User Detail",
        "addOperator": "Add Operator",
        "updateOperator": "Update Operator",
        "supplier": "Supplier",
        "zone": "Zone",
        "emails": "Emails",
        "email": "Email",
        "phoneNumber": "Phone Number",
        "Deactivate": "Deactivate",
        "Activate": "Activate",
        "ALERT_SELECT_DEACTIVATE": "Please select supplier to deactivate",
        "ALERT_SELECT_ACTIVATE": "Please select supplier to activate",
        "CONFIRM_DEACTIVATE_MULTI": "Do you want to deactivate these suppliers?",
        "CONFIRM_ACTIVATE_MULTI": "Do you want to activate these suppliers?",
        "Update_supplier_user_status_success": "Supplier status has been updated successfully",
        "Update_supplier_user_status_fail": "The errors occurred while updating supplier status",
        "agent": "Agent",
        "Supplier": "Supplier",
        "addSupplier": "Add Supplier",
        "detailSupplier": "Supplier Detail",
        "addUser": "Add User",
        "Add": "Add",
        "ContactInfo": "Contact Info",
        "policy": "Policy",
        "users": "Users",
        "setting": "Setting",
        "zones": "Zones",
        "actions": "Actions",
        "companyName": "Company Name",
        "corporationType": "Corporation Type",
        "country": "Country",
        "ADMIN_INFORMATION": "Admin Information",
        "firstName": "First Name",
        "lastName": "Last Name",
        "commissionValue": "Fleet commission",
        "commissionValueDes": "Set commissions the fleet earns for every complete order.",
        "paymentMethodsApplyDes": "Job specific payment methods that suppliers can process:",
        "bookingSettings": "Booking Settings",
        "bookingSettingsDes": "Configure job visibility, release times, and delays for efficient supplier operations.",
        "advanceBookingVisibilityTooltip": "Specify how many days in advance suppliers can see the orders.",
        "delayBroadcastToolTip": "Set a delay time from the release time if set or the start of the day (00:00). This delay controls when the jobs become visible to the suppliers after the initial release time.",
        "releaseTimeToolTip": "Set the time of day when jobs are released to suppliers, starting from the first day they can see bookings. Ensure this time allows suppliers enough preparation time.",
        "otherSettings": "Other Settings",
        "hideFare": "Driver - Always hide fare",
        "hideFareDes": "Hide the fare for all drivers of this supplier.",
        "supplierJobRequired": "Supplier Job Acceptance Required",
        "supplierJobRequiredDes": "Suppliers must acknowledge and accept jobs before they can assign a driver/vehicle or allow the job to be viewed by drivers in the marketplace.",
        "supplierApproval": "Suppliers Driver/Vehicle Approval",
        "supplierApprovalDes": "Allow supplier operators to activate drivers and vehicles within their fleet.",
        "emailsApply": "Email Notifications",
        "emailsApplyDes": "Emails to send new jobs, booking updates.",
        "address": "Address",
        "zipCode": "Zip/Postal Code",
        "city": "City",
        "state": "State/Province",
        "tax": "Tax Identification Number",
        "businessRegistration": "Business Registration Number",
        "vatNumber": "VAT ID/Number",
        "mobile": "Mobile",
        "editOperator": "Edit Operator",
        "searchEmail": "Search Email",
        "acceptOfferSuccess": "Accept offer successfully",
        "acceptOfferFail": "Booking not found or another supplier has accepted the booking",
        "driverPayout": "Driver payout",
        "payout": "Payout",
        "totalPayout": "Total payout",
        "totalPayoutDes": "The total payout includes the base payout amount plus any additional fees you have chosen to allocate to your providers.",
        "supplierPayout": "Supplier payout",
        "driverTip": "Driver tip",
        "cancel": "Cancel",
        "save": "Save",
        "activeSupplier": "Active supplier",
        "userName": "Username",
        "bookingCompletedView": "Completed Bookings",
        "bookingCompletedViewNote": "View the history of all completed bookings.",
        "driverEarning": "Driver earning"
    },
    "noResultFound": "No results found"
};
