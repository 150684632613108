export const en_gb = {
    "name": "English (UK)",
    "flagCode": "gb",
    "locale": "en-GB",
    "project": {
        "name": "QUp New CC"
    },
    "Sidebar": {
        "Setting": "Settings",
        "Map": "Map",
        "Cue": "Cue",
        "ImportBookings": "Import Transport Bookings",
        "ImportDeliveryBookings": "Import Delivery Bookings",
        "Trip": "Trip",
        "New_booking": "New Booking",
        "City": "City",
        "Chauffeur": "Transport",
        "Delivery": "Delivery",
        "Shuttle": "Shuttle",
        "Intercity": "Intercity",
        "Customer": "Customers",
        "Driver": "Drivers",
        "REPORTS": "Reports",
        "Corporate": "Corporate",
        "mDispatcher": "Partner",
        "Reservation": "Calendar",
        "withdrawalRequest": "Withdrawal requests",
        "Driver_Settlement": "Driver Settlement",
        "Dashboard": "Analytics",
        "SmartData": "Smart data",
        "Smart_Data": "Smart data",
        "Campaign_Builder": "Campaign builder",
        "Company_operator": "Company operator",
        "Queuing_area": "Queuing area",
        "Withdrawal_Requests": "Withdrawal Requests",
        "pickUp": "Pick up",
        "dropOff": "Drop off",
        "SignUpNotifications": "Sign Up Notifications",
        "Payout": "Payout",
        "Payout_label": "Payout",
        "payout": {
            "Pay_To_Driver": "Pay to Drivers",
            "Payout_History_For_Driver": "Payout Driver History",
            "Pay_To_Merchant": "Pay to Merchants",
            "Payout_History_For_Merchant": "Payout Merchant History"
        },
        "number_of_records_to_export": "Number of records to export",
        "export": "Export",
        "Airline": "Airline",
        "driverPayout": {
            "Pay_to_driver": "Pay to Drivers",
            "Payout_history": "Payout History"
        },
        "Reports": {
            "Driver_Referral_history": "Driver refers customer",
            "Driver_refers_Pax": "Drivers",
            "Driver_refers_Pax_Customers": "Customers",
            "Driver_refers_Pax_Bookings": "Bookings",
            "Driver_refers_customer_Drivers": "Driver refers customer (Drivers)",
            "Driver_refers_customer_Customers": "Driver refers customer (Customers)",
            "Driver_refers_customer_Bookings": "Driver refers customer (Bookings)",
            "Passenger_refers_Passengers": "Customer refers customer",
            "Driver_refers_drivers_Referrer": "Driver refers drivers (Referrer)",
            "Deletion_request": "Deletion request",
            "Driver_refers_drivers_Referee": "Driver refers drivers (Referee)",
            "Driver_refers_Driver": "Driver refers driver",
            "Driver_refers_driver_Referrer": "Referrer",
            "Driver_refers_driver_Referee": "Referee",
            "Referral_history": "Referral history",
            "Booking_details": "Booking details",
            "Financial": "Financial",
            "Financial_driver": "Driver",
            "Financial_fleet": "Fleet",
            "Financial_company": "Company",
            "Financial_Driver": "Financial (Driver)",
            "Financial_Fleet": "Financial (Fleet)",
            "Financial_Company": "Financial (Company)",
            "Financial_fleet_profit": "Fleet profit",
            "Financial_Company_Tooltip": "Net earning report of drivers, grouped by company",
            "Financial_Driver_ToolTip": "Net earning report of each driver",
            "Financial_Fleet_Profit_ToolTip": "Profit of fleet",
            "Driver_activity": "Driver's activity",
            "Revenue_Summary": "Revenue summary",
            "Revenue_fleet": "Fleet",
            "Revenue_driver": "Driver",
            "Revenue_monthly": "Monthly",
            "Revenue_Summary_Fleet": "Revenue Summary (Fleet)",
            "Revenue_Summary_Driver": "Revenue Summary (Driver)",
            "Revenue_Summary_Monthly": "Revenue Summary (Monthly)",
            "Total_adjustment": "Total adjustment",
            "Promotion": "Promotion",
            "Incident_Cancellation": "Incident & Cancellation",
            "Booking_logs": "Booking logs",
            "Rating": "Rating",
            "Thumbs_rating": "Rating",
            "Stars_rating": "Rating",
            "Operator_logs": "Operator logs",
            "Daily": "Daily",
            "Daily_Driver": "Daily Driver",
            "Daily_Car": "Daily Car",
            "Daily_Driver_Car": "Daily Driver Car",
            "mDispatcher": "Partner",
            "Corporate": "Corporate",
            "Driver_login_status": "Driver login status",
            "Car_activity": "Car's activity",
            "Partner": "Partner",
            "Prepaid_top_up": "Corporate prepaid",
            "Affiliation": "Affiliation",
            "Affiliation_penalty_compensation": "Penalty/Compensation",
            "Affiliation_payout_history": "Settlement history",
            "Affiliation_bookings": "Bookings",
            "Booking_summary": "Booking summary",
            "Cash_Wallet": "Cash wallet",
            "Credit_Wallet": "Credit wallet",
            "Driver_wallet": "Driver wallet",
            "Driver_Deposit": "Driver deposit",
            "Driver_cash_wallet": "Cash Wallet",
            "Driver_Deposit_old": "Driver deposit",
            "Document_expiry": "Document expiry",
            "Settlement": "Settlement",
            "Unapproved_Driver": "Unapproved driver",
            "Approved_Driver": "Approved driver",
            "Pay_to_driver": "Pay to Driver",
            "Settlement_history": "Settlement history",
            "Withdrawal_History": "Driver withdrawal",
            "creditWalletTooltip": "Driver deposits to credit wallet",
            "Incomplete_payment": "Incomplete payment",
            "Driver_withdrawal": "Driver Withdrawal",
            "Incident_&_Cancellation": "Incident & Cancellation",
            "Passenger_wallet": "Passenger wallet",
            "Accept_Cancel_rate": "Driver performance",
            "Acceptance/Cancellation_rate": "Driver performance",
            "driverDepositTooltip": "Driver deposits to credit wallet",
            "Customer_quest": "Quest",
            "First_will_win": "First will win",
            "Largest_will_win": "Largest will win",
            "redeemed": "Code #",
            "Code_#": "Code #",
            "Quests": "Quest",
            "Merchant_Report": "Merchant",
            "Merchant_Financial_Report": "Financial",
            "Merchant_Cash_Report": "Cash wallet",
            "Merchant_Credit_Report": "Credit wallet",
            "Deletion_Request": "Deletion request",
            "Driver_Rating": "Customer rates driver",
            "Passenger_Rating": "Driver rates customer",
            "Company_settlement": "Company settlement",
            "Export": "Export History",
            "Third_Party_Booking": "3rd party Booking",
            "BookingDotCom": "Booking.com",
            "Third_Party_Booking_Bookingcom": "Third Party Booking (Booking.com)",
            "Third_Party_Booking_HolidayTaxis": "Third Party Booking (HolidayTaxis)",
            "Third_Party_Booking_Karhoo": "Third Party Booking (Karhoo)",
            "Third_Party_Booking_Hoppa": "Third Party Booking (Hoppa)"
        },
        "Settings": {
            "Fleet_info": "Fleet info",
            "Stripe_connect": "Stripe Connect",
            "stripe_title_connect": "Activate your Stripe Connect account to receive your payout automatically.",
            "Payment_info": "Billing info",
            "Permission": "Permission",
            "Driver_refer_driver": "Driver refer driver",
            "Show_referal_history": "Show/hide referral history on driver app",
            "companyTooltip": "Supplier company",
            "User": "User",
            "General": "General",
            "Regular": "Regular",
            "Sharing": "Street Sharing",
            "Flat": "Flat",
            "Hourly": "Hourly - Daily",
            "Company": "Company",
            "Dispatch": "Dispatch",
            "Voice_SMS": "Voice & SMS",
            "Rate": "Rate",
            "Car": "Car",
            "Car_type": "Car type",
            "Car_mgmt": "Car mgmt",
            "Car_Make": "Make & Model",
            "Stripe_Connect": "Stripe connect",
            "Voice_&_SMS": "Voice & SMS",
            "Street-sharing_rate": "Street sharing rate",
            "Partner_type": "Partner type",
            "Shift_template": "Shift template",
            "Driver_App": "Driver App",
            "Shift_settings": "Shift settings",
            "Operation": "Operation",
            "Driver_Note": "Driver note",
            "Promotion_code": "Promotion code",
            "Point_config": "Point config",
            "Code_#": "Code #",
            "Voucher_code": "Code #",
            "Campaign": "Campaign",
            "Queuing_area": "Queuing area",
            "Referral_code": "Referral code",
            "City": "City",
            "category": "Category",
            "3rd_party_location": "3rd party location",
            "Zone": "Zone",
            "App_banner": "App banner",
            "App_banner_pegasus": "App banner (Pegasus)",
            "Flight_integration": "Flight Integration",
            "SMS_integration": "SMS Integration",
            "Invoice_Setting": "Invoice",
            "Driver_refers_customer": "Driver refers customer",
            "Pax_refers_Pax": "Customer refers customer",
            "Customer_refers_customer": "Customer refers customer",
            "Dynamic_Surcharge": "Dynamic Surcharge",
            "Dynamic_Fare": "Dynamic Fare",
            "FlatZone": "Flat rate",
            "IntercityZone": "Intercity",
            "Intercity_Zone": "Intercity Zone",
            "Intercity_Rate": "Intercity Rate",
            "Car_Color": "Car color",
            "third_party_integration": "3rd party integration",
            "bookingCom": "Booking.com",
            "HolidayTaxis": "HolidayTaxis",
            "Karhoo": "Karhoo",
            "Corporate": "Corporate",
            "KarhooIntegration": "Karhoo Integration",
            "holidaytaxis": "HolidayTaxis",
            "karhoo": "Karhoo",
            "hoppa": "Hoppa",
            "Hoppa": "Hoppa",
            "HoppaIntegration": "Hoppa Integration",
            "Third_Party_Integration_Hoppa": "3rd party Integration (Hoppa.com)",
            "titleHoppa": "Hoppa API Credentials",
            "noteHoppa": "Communicate with Hoppa’s technical team to provide you with client, and secret key to connect to their system.",
            "titleHoppaWebhook": "Webhook Configuration",
            "noteHoppaWebhook": "Provide the parameters below for Hoppa to configure. These credentials are used to communicate between both platforms. For any issues please contact your tech provider.",
            "Third_Party_Integration_Booking": "3rd party Integration (Booking.com)",
            "Third_Party_Integration_HolidayTaxis": "3rd party Integration (HolidayTaxis)",
            "Third_Party_Integration_Karhoo": "3rd party Integration (Karhoo.com)",
            "Third_Party_Integration_Mozio": "3rd party Integration (Mozio.com)",
            "invoiceBranding": "Invoice Branding",
            "titleInvoice": "Customize your company header and footer information.",
            "titleheader": "Header Title",
            "descriptionHeader": "Header Description",
            "placehodelHeader": "For example add company details like email, phone number, address, tax number, etc.",
            "titleFooter": "Footer Title",
            "placehodelFooter": "For example, add bank wire information, or any policy etc",
            "descriptionFooter": "Footer Description ",
            "titleBookingCom": "Booking.com API Credentials",
            "noteBookingCom": "Communicate with booking.com’s technical team to provide you with client, and secret key to connect to their system.",
            "clientKey": "Client Key",
            "placeHolderClientKey": "Enter client key",
            "secretKey": "Secret Key",
            "placeHolderSecretKey": "Enter client secret",
            "titleBookingComWebhook": "Webhook Configuration",
            "noteBookingComWebhook": "Provide the parameters below for booking.com to configure. These credentials are used to communicate between both platforms. For any issues please contact your tech provider.",
            "webhook": "Fleet Webhook URL",
            "clientId": "Fleet Client Key",
            "clientSecret": "Fleet Secret Key",
            "driver_app": "Driver App",
            "Jobs_tab": "Jobs Tab",
            "Email_config": "Email config",
            "Driver_fields": "Driver fields",
            "Driver_document": "Driver document",
            "Selected": "Selected",
            "API_Management": "API management"
        }
    },
    "APIKeySetting": {
        "APIKey": "API Keys",
        "APIKey_title": "Do not share your API key with others, or expose it in the browser or other client-side code. In order to protect the security of your account, Good Journey may also automatically disable any API key that we've found has leaked publicly.",
        "newKeyBtn": "Create new key",
        "updateApplication": "Update Application",
        "RegisterApp": "Register a new application",
        "RegisterBtn": "Register Application",
        "appDescription": "App Description",
        "appName": "App Name",
        "clientKey": "Client Key",
        "secretKey": "Secret Key",
        "hostedEndPoint": "Hosted endpoints",
        "maxEndpoints": "You can have a max of 5 endpoints",
        "addEndpoint": "Add Endpoint",
        "updateEndpoint": "Update Endpoint",
        "ManageWebhooks": "Manage Webhooks",
        "CreateWebhook": "Create webhooks",
        "subscriptionID": "Subscription ID",
        "webhookURL": "Webhook URL",
        "signingSecret": "Signing secret",
        "eventsToSend": "Events to send",
        "listeningFor": "Listening For",
        "assignedDriver": "Assigned Driver",
        "bookingStatus": "Booking Status",
        "driverLocation": "Driver Location",
        "titleSigningSecret": "This secret is provided by your partner. If for any reason the secret has been compromised, you can update the secret, or delete the web hook.",
        "notUsingHTTPS": "Webhook URL is not using HTTPS.",
        "invalidURL": "Webhook URL is invalid.",
        "clientType": "Client Type",
        "individual": "Individual",
        "corporate": "Corporate",
        "signingSecretLength": "Signing secret must be at least 30 characters long.",
        "bookingCreated": "Booking Created",
        "partner": "Partner",
        "fleetManager": "Fleet Manager"
    },
    "messages": {
        "credits": {
            "406": "Action failed",
            "407": "Action failed",
            "416": "Coupon has been used",
            "417": "Your account has been inactive. Please contact our adminitrator",
            "418": "There were errors when making payment",
            "419": "Promo code is invalid!",
            "420": "This promo code was applied for another ride. Please use another one.",
            "421": "Card number is not valid.",
            "422": "Expired date is not valid.",
            "429": "Zip code is not valid.",
            "430": "AVS Postal Code does not match",
            "431": "AVS Postal Code not verified",
            "432": "CVV is not valid.",
            "433": "CVV does not match",
            "434": "CVV is not verified",
            "435": "CVV issuer does not participate",
            "436": "Your card was declined",
            "437": "Credit card verification failed. Please check your information and try again.",
            "439": "CVV not processed",
            "441": "No CVV data from issuer",
            "443": "No data from issuer/banknet switch",
            "445": "AVS System unable to process",
            "446": "Issuing bank does not support AVS",
            "447": "AVS not supported for your business",
            "448": "This card has been blocked due to repeated unsuccessful payment attempts. Please change your payment method or try again later",
            "452": "Your card is declined. Please enter another credit card that was registered at the same area as your phone number",
            "453": "This payment.gateway is not supported",
            "454": "AVS Address does not match",
            "455": "AVS Both postal code and address do not match",
            "458": "Authentication failed",
            "459": "Bank cards do not supprt",
            "461": "This card is not suppoted",
            "462": "Card holder name invald",
            "463": "Verification code is ivalid",
            "464": "The Bank does not supprt generating and sending the verification code",
            "470": "Please enter the traveler's email address before creating a booking with credit card",
            "474": "Please enter the traveler's name before creating a booking with credit card",
            "475": "Phone number is required",
            "524": "Insufficient Funds. The account did not have sufficient funds to cover the transaction amount.",
            "911": "We are unable to authenticate your payment method. Please choose a different payment method and try again.",
            "2000": "Do Not Honor",
            "2001": "Insufficient Funds. The account did not have sufficient funds to cover the transaction amount.",
            "2002": "Limit Exceeded",
            "2003": "Cardholder&#39;s Activity Limit Exceeded",
            "2004": "Expired Card",
            "2005": "Invalid Credit Card Number",
            "2006": "Invalid Expiration Date",
            "2007": "No Account",
            "2008": "Card Account Length Error",
            "2009": "No Such Issuer",
            "2010": "Card Issuer Declined CVV",
            "2011": "Voice Authorization Required. The cardholder&#39;s bank is requesting that the merchant calls to obtain a special authorization code in order to complete this transaction",
            "2012": "Processor Declined - Possible Lost Card",
            "2013": "Processor Declined - Possible Stolen Card",
            "2014": "Processor Declined - Fraud Suspected",
            "2015": "Transaction Not Allowed",
            "2016": "Duplicate Transaction",
            "2017": "Cardholder Stopped Billing",
            "2018": "Cardholder Stopped All Billing",
            "2019": "Invalid Transaction",
            "2020": "Violation",
            "2021": "Security Violation",
            "2022": "Declined - Updated Cardholder Available",
            "2023": "Processor Does Not Support This Feature",
            "2024": "Card Type Not Enabled",
            "2025": "Set Up Error - Merchant",
            "2026": "Invalid Merchant ID",
            "2027": "Set Up Error - Amount",
            "2028": "Set Up Error - Hierarchy",
            "2029": "Set Up Error - Card",
            "2030": "Set Up Error - Terminal",
            "2031": "Encryption Error",
            "2032": "Surcharge Not Permitted",
            "2033": "Inconsistent Data",
            "2034": "No Action Taken",
            "2035": "Partial Approval For Amount In Group III Version",
            "2036": "Authorization could not be found to reverse",
            "2037": "Already Reversed",
            "2038": "Processor Declined. The customer&#39;s bank is unwilling to accept the transaction, need to contact their bank for more details.",
            "2039": "Invalid Authorization Code",
            "2040": "Invalid Store",
            "2041": "Declined - Call For Approval",
            "2042": "Invalid Client ID",
            "2043": "Error - Do Not Retry, Call Issuer",
            "2044": "Declined - Call Issuer",
            "2045": "Invalid Merchant Number",
            "2046": "Declined. The customer&#39;s bank is unwilling to accept the transaction, need to contact their bank for more details.",
            "2047": "Call Issuer. Pick Up Card. This error indicates that the card has been reported as lost or stolen by the cardholder.",
            "2048": "Invalid Amount",
            "2049": "Invalid SKU Number",
            "2050": "Invalid Credit Plan",
            "2051": "Credit Card Number does not match method of payment",
            "2052": "Invalid Level III Purchase",
            "2053": "Card reported as lost or stolen",
            "2054": "Reversal amount does not match authorization amount",
            "2055": "Invalid Transaction Division Number",
            "2056": "Transaction amount exceeds the transaction division limit",
            "2057": "Issuer or Cardholder has put a restriction on the card",
            "2058": "Merchant not MasterCard SecureCode enabled.",
            "2060": "Address Verification and Card Security Code Failed",
            "2061": "Invalid Transaction Data",
            "2062": "Invalid Tax Amount",
            "2064": "Invalid Currency Code",
            "2078": "Invalid Secure Payment Data",
            "2080": "Invalid user credentials",
            "3000": "Processor Network Unavailable - Try Again",
            "4001": "Settlement Declined",
            "4006": "Capture amount exceeded allowable limit",
            "81703": "Credit card type is not accepted by this merchant account.",
            "81706": "CVV is required",
            "81707": "CVV must be 3 or 4 digits.",
            "81709": "Expiration date is required.",
            "81710": "Expiration date is invalid.",
            "81714": "Credit card number is required.",
            "81715": "Credit card number is invalid.",
            "81716": "Credit card number must be 12-19 digits.",
            "81717": "Credit card number is not an accepted test number.",
            "81718": "Credit card number cannot be updated to an unsupported card type when it is associated to subscriptions.",
            "81723": "Cardholder name is too long. Maximum 175 characters.",
            "81809": "Postal code may contain no more than 9 letter or number characters",
            "81813": "Postal code can only contain letters, numbers, spaces, and hyphens",
            "91722": "Payment method token is required.",
            "91723": "Update Existing Token is invalid",
            "91730": "Verification not supported on this merchant account.",
            "91734": "Credit card type is not accepted by this merchant account.",
            "91738": "Payment method specified is not a credit card.",
            "91745": "Invalid params for credit card update.",
            "91826": "Postal code is invalid. Postal code must be a string of 5 or 9 digits, optionally separated by a dash or a space.",
            "-1": "This customer account has been deactivated. Please contact our administrator."
        },
        "reBookingMsg": {
            "303": "The time you have selected must be later than the current time!",
            "304": "This booking was not found",
            "500": "Some booking info is not valid or missing. Please check and try again!"
        },
        "promoMsg": {
            "304": "The promo code is not valid",
            "306": "Invalid promotion code. It seems that the promo is applied to other zones.",
            "413": "Sorry, this promo code is not available at the moment",
            "414": "The promo code is not valid",
            "415": "The promo code is not valid",
            "416": "This promo code has already been used.",
            "419": "Sorry, this promo code is not available at the moment",
            "420": "Sorry, you have reached your limited promo uses",
            "421": "Sorry, this promo code has reached its released usage limit ",
            "422": "This promo code cannot be used under your account ",
            "423": "Sorry, you have reached your limited promo budget ",
            "424": "Invalid promotion code. It seems that the promo is only applied to referred customers",
            "425": "This promo code has reached its usage limit per day.",
            "426": "You have reached your limited promo uses per day.",
            "427": "Cannot apply promotion code. This customer’s profile is incomplete.",
            "428": "This customer has used up monthly promotion code.",
            "430": "You have used up your yearly promotion codes.",
            "431": "The promotion code can't be used for this payment method.",
            "432": "This fare amount isn't eligible for the promotion code.",
            "433": "The promotion code isn't eligible to use at selected time.",
            "434": "Sorry, you have reached your limited promo budget.",
            "phoneInvalid": "Please enter passenger's phone number before applying the promo code"
        },
        "updateBookingMsg": {
            "303": "Failed to update the booking. Please try again!",
            "304": "This booking was not found",
            "pickUpTime": "The time you have selected must be later than the current time!",
            "pickup": "There are no booking services available in this area",
            "destination": "Some booking info is not valid or missing. Please check and try again!",
            "dispatchType": "Some booking info is not valid or missing. Please check and try again!"
        },
        "sessionExpired": {
            "403": "You have been logged out because your account has been logged in from another device or your session has expired"
        },
        "not_found": {
            "404": "The page you requested could not be found"
        },
        "booking": {
            "303": "The time you have selected must be later than the current time!",
            "304": "This booking was not found",
            "305": "Booking canceled or completed by Booking.com; please reject the job to remove it from the queue.",
            "306": "No rate assign to selected car type. To apply a rate for any car type, please go to Settings > Car > Car Type.",
            "307": "Can not update booking. Please try again later.",
            "407": "Type rate ko support extra location",
            "500": "Some booking info is not valid or missing. Please check and try again!",
            "upgroup_success": "Booking has been ungrouped successfully",
            "Please_Select_driver": "Please select driver",
            "assign_driver_vehicle_success": "Driver/Vehicle has been assigned successfully",
            "assign_driver_success": "Driver has been assigned successfully",
            "detach_driver_success": "Driver has been detach successfully",
            "detach_vehicle_success": "Vehicle has been detach successfully",
            "Group_success": "Booking has been grouped successfully",
            "change_supplier_success": "Booking has been changed suppliers successfully",
            "change_supplier_partial": "Partial Success: Batch assignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
            "detach_driver_partial": "Partial Success: Batch assignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
            "assign_driver_partial": "Partial Success: Batch assignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
            "update_dispatch": "Update & Dispatch",
            "remove_booking_false": "Failed to remove booking",
            "remove_booking_success": "Booking has been removed successfully",
            "GROUP_NOT_FOUND": "Group Id is not found",
            "NO_SHARING_BOOKINGS": "You can only group bookings if the customer’s booking allows ride-sharing.",
            "DIFFERENT_CAR_TYPES": "The booking you are trying to group has a different car-type",
            "LIMIT_EXCEEDED_ON_SEATS_OR_LUGGAGE": "Booking cannot be grouped: Seat or luggage limit exceeded.",
            "INVALID_CAR_TYPE": "Cannot assign driver, check driver’s vehicle seat & luggage capacity.",
            "VALIDATION_ERROR": "Validation error",
            "INTERNAL": "Internal server error",
            "fare_gt_zero": "Fare must be greater than 0!",
            "edit_total_fail": "Edit total value fail!",
            "complete_fail": "The booking cannot be completed!",
            "cancle_fail": "Something went wrong when canceling this booking. Please try again!",
            "incident_fail": "Incident fail",
            "phone_invalid": "Please enter a valid phone number!",
            "add_psg_fail": "Cannot add new passenger",
            "REMOVE_RECIPIENT_DELIVERED": "Cannot remove recipient has been delivered",
            "REMOVE_LAST_ACTIVE_RECIPIENT": "Cannot remove the last active recipient",
            "ORDER_HAS_BEEN_FINISHED": "Order has been finished already",
            "end_booking_leave": "Leaving this page will end your booking.",
            "update_booking_fail": "Failed to update the booking",
            "EXTERNAL_ID_EXISTED": "External ID already exist on the system.",
            "area_service_unavailable": "There are no booking services available in this area",
            "pickupSameDestination": "Pick-up address must be different from the destination.",
            "time_invalid": "The time you have selected must be later than the current time!",
            "psg_inactivate": "This customer account has been deactivated. Please contact our administrator",
            "data_incorect": "Something went wrong. Please try again later.",
            "preAuthorize": "Please check your account as an amount of {0} is required to guarantee your booking",
            "booking_limited": "Sorry, you have reached the maximum number of bookings per minute/day. Please try again later.",
            "minimumBookAhead": "Reservation pickup time must be at least {0} hour(s) {1} minute(s) from current time",
            "exDoDiffDo": "Extra destination must be different from destination.",
            "recurringTimeRange": "Date out of range. You can only create bookings {0} months in advance. Please contact admin for any issues.",
            "recipientsLimit": "Maximum number of recipients is {0}.",
            "maximumBookAhead": "Reservation pickup time cannot be later than {0} day(s) from current time",
            "Please_select_or_add_a_card": "Please select or add a card!",
            "Unable_to_process_by_fare_zero": "We are unable to process your request, please change car type or booking type and try again. If you need any assistance, please contact our support team",
            "Can_not_connect_to_server": "Cannot connect to server!",
            "Check_connection_or_contact_system_support": "Please check your connection or contact system support for help!",
            "Create_new_booking_success": "A new booking has been created successfully!",
            "Create_new_recurring_booking_success": "New booking(s) has been created successfully",
            "Update_booking_success": "This booking has been updated successfully!",
            "Booking_drop_of_success": "Booking drop off Sucessful",
            "Cancel_booking_success": "This booking has been cancelled successfully!",
            "Booking_detail_not_found": "Booking detail is not found!",
            "Check_promo_error": "Check promoCode error!",
            "Invalid_format": "Invalid format",
            "Flight_not_found": "Flight not found!",
            "Add_new_credit_error": "Add new credit error!",
            "Socket_server_disconnected": "Socket server disconnected!",
            "Please_reload_page_or_check_your_connection": "Sorry. Your request cannot be processed. Please try again.",
            "request_timeout": "Sorry. Your request timeout. Please try again.",
            "Check_customer_error": "Check customer error!",
            "Please_select_car_type": "Please select car type",
            "DropOff_country": "Drop-off point not available for this car type. Please choose another car type or adjust route.",
            "Different_Country": "Pickup and drop-off locations must be in 2 different countries.",
            "Please_enter_destination_for_ridesharing_booking": "Please enter destination for ride-sharing booking",
            "Extra_detination_must_be_difference_from_destination": "Extra destination must be difference from current destination",
            "Pickup_address_must_be_different_from_destination": "Pick-up address must be different from destination",
            "Best_price": "Best price",
            "You_may_be_charged_extra_fee_if_your_trip_excceds": "You may be charged an extra fee if your trip exceeds",
            "You_may_be_charged_extra_fee_if_your_trip_excceds_with_params": "You may be charged an extra fee if your trip exceeds {0} {1}, {2} {3}",
            "Reject_booking_success": "This booking has been rejected successfully!",
            "Reject_fail": "Something went wrong when rejecting this booking. Please try again!",
            "Please_select_payment_method": "Please select payment method",
            "Driver_signed_out_or_deactivated": "This driver has just signed out or been deactivated",
            "reservation_booking_overlaps": "Sorry, this reservation booking overlaps with another one that Driver has accepted.",
            "driver_is_not_available": "Sorry, this driver is not available at this time.",
            "seats_luggage_limit": "Cannot assign booking to driver. The seat or luggage is over the limit.",
            "full_capacity": "This booking can’t be assigned to the driver because the trip has reached its maximum capacity.",
            "marked_failed_ok": "The order has been marked as failed.",
            "warning_markFail_lastRecipient": "This is the last recipient of this booking. If you proceed with marking this order as failed you will then need to complete this booking.",
            "BOOKINGS_LIMIT_EXCEEDED": "You can only assign a maximum of 5 orders into each group",
            "bookingCanceled": "Booking canceled or completed by Booking.com; please reject the job to remove it from the queue."
        },
        "item_no_found": "No item was found",
        "commonMessages": {
            "greater_than_0": "Please enter a value greater than 0.",
            "Required_field": "This field is required",
            "bankPhone_is_required": "Phone number is invalid",
            "bankPhone": "Phone number",
            "distance_from_equa_or_smaller": "The distance 'from' must be smaller than or equal to the distance 'to'",
            "must_be_number": "Please enter valid number",
            "equal_or_greater_than_0": "Please enter a value equal or greater than 0.",
            "must_be_integer": "Please enter integer number",
            "invalid_email_format": " Please enter valid email.",
            "greater_or_equa": "Please enter a value greater than or equal to {0}.",
            "value_must_between": "Please enter a value between {0} and {1}.",
            "large_10x_markup_price": "Edited fare should not exceed 10x estimated fare.",
            "greater_than": "Please enter a value greater than {0}.",
            "less_than": "Please enter a value less than {0}.",
            "less_or_equal": "Please enter a value less than or equal to {0}.",
            "driver_earnings_warning": "Offline payments: Driver earnings must not surpass the collected fare.",
            "limit_amount": "Please enter a value less than or equal to limit amount",
            "Integer_from_range": "Please enter integer number from {0} - {1}",
            "integer_greater_than_0": "Please enter a integer number greater than 0. (Less than 100 with percent and decimal separator is 1 character)",
            "Error_message": "Error!",
            "callcenter_newbooking_message": "New booking request with phone <span class='bold'>{0}</span>",
            "callcenter_existed_booking_message": "One active booking <span class='bold'>#{0}</span> exists with phone <span class='bold'>{1}</span>",
            "callcenter_close_newbooking_form_warning": "Please close/complete your current book before recieve new customer request",
            "Emergency_SOS_Title": "Emergency SOS",
            "Please_enter_a_future_date": "Please enter a future date",
            "Card_holder_is_required": "Card holder is required",
            "Card_number_is_required": "Card number is required",
            "Expired_date_is_required": "Expired date is required",
            "Credit_code_is_required": "Please enter credit code (last validation)",
            "Expired_date_is_invalid": "Expiration date is invalid. Please enter expiration date in format mm/yy",
            "cvv_is_required": "CVV is required",
            "City_is_required": "City is required",
            "State_is_required": "State is required",
            "Address_is_required": "Address is required",
            "Zipcode_is_required": "Zip code is required",
            "Country_is_required": "Country is required",
            "Over_query_limit": "Google API can't load due to temporary error. Please try again later.",
            "outOfInputQueueOrder": "The order # cannot be greater than the total number of drivers in the queue.",
            "Same_pickup_destination": "Pick-up address must be different from destionation address.",
            "Max_length": "Maximum length is {0} characters.",
            "choose_at_least_one": "Please choose at least one option.",
            "choose_at_least_one_intercity_schedule": "Please add schedule or allow customer to request their preferred time.",
            "startDate_greater_than_endDate": "The start date is greater than the end date"
        },
        "trip": {
            "cancel_trip_success": "This trip has been cancelled successfully!",
            "data_incorect": "No driver has been assigned. Select a driver from the dropdown list. <br/> If no drivers is available, select Auto Dispatch to assign later.",
            "INTERNAL": "Internal server error.",
            "NOT_FOUND": "Trip not found or invalid status.",
            "SAME_DRIVER": "The assignee must be different from the current driver.",
            "OVERLAP": "Sorry, this trip overlaps with another one which is accepted by this driver.",
            "CANNOT_ASSIGN": "This driver is invalid (i.e. driver not found, not online, or not logged in)."
        },
        "car_mgmt": {
            "Search_car_color": "Search car color",
            "Searching": "Searching"
        },
        "driver_note": {
            "Search_driver_note": "Search driver note",
            "Searching": "Searching"
        }
    },
    "errors": {
        "303": "user not found",
        "453": "Gateway unsupported",
        "553": "Sorry! Customer has a booking in-progress, please try again later.",
        "1007": "The period between the start date and the end date must be less than 60 days!!",
        "3001": "Phone number existed",
        "3002": "Invalid date of birth",
        "3003": "Expire date is invalid",
        "3004": "Driver not found",
        "3005": "Missing driver settlement info (bank address, city, state, zipcode)",
        "3006": "Missing bank account info (nameOfAccount, typeOfAccount, routingNumber, accountNumber, ssn)",
        "3007": "You can't edit this driver, who is having a job.",
        "3008": "This driver is currently logging in. Please ask him to log out to edit his information.",
        "3009": "This driver has accepted one or more reservation(s). Please assign the booking(s) to other drivers before updating/ changing the driver's information.",
        "3010": "Connect dispatching failed.",
        "3011": "This driver must be setup for operation before being activated.",
        "3012": "This driver must be setup for operation before being activated.",
        "3013": "Just only item in the inactive status can be deleted.",
        "3014": "Required fields are missing. Please check.",
        "3015": "Invalid birth year. Please check.",
        "3016": "Invalid zip code. Please check.",
        "3017": "Invalid state/province. Please check.",
        "3018": "Invalid name of account. Please check.",
        "3019": "Invalid routing number. Please check.",
        "3020": "Birthday is required. Please check.",
        "3021": "Invalid ssn number. Please check.",
        "3022": "Driver's bank account can't receive funds.",
        "3023": "Logged in drivers can't be operated. Please try again later.",
        "3026": "Driver no longer exists.",
        "3027": "The driver's bank account is not set up. Please go to Drivers module to set up his bank account.",
        "3028": "Failed to pay the selected driver(s). Please try again.",
        "3029": "Failed to clear earning data of the driver. Please try again.",
        "3032": "This username already exists on our system. Please enter another one.",
        "3033": "Invalid BSB. The number should be 6 digits in the format xxxxxx.",
        "3039": "Group ID is required.",
        "3040": "This phone number is already in use by a  %{brandName} driver (i.e. phone number can't be duplicated). Please enter another one",
        "3041": "Username existed.",
        "3044": "National ID is duplicated.",
        "3045": "Driver already has a vehicle, Please refresh page",
        "3046": "The company of the vehicle and the company of the driver are different.",
        "4001": "Your car registration number has been existed on system. Please input another one.",
        "4002": "Invalid expired date",
        "4003": "Vehicle must be deactivated before deletion",
        "4004": "Vehicle not found",
        "4005": "This vehicle is already inactive ",
        "4006": "This vehicle is in use and cannot deleted or deactivated",
        "4007": "Car registration number is required",
        "4009": "The vehicle is being assigned to a booking.",
        "4010": "The vehicle is being assigned to a driver.",
        "5001": "Company existed",
        "5004": "Company not found",
        "6001": "Vehicle model existed",
        "6004": "Vehicle model not found",
        "6005": "Vehicle model is in use",
        "7001": "Your car make name has been existed on system. Please input another one.",
        "7004": "Vehicle make not found",
        "8001": "Vehicle type existed",
        "8004": "Vehicle type not found",
        "9001": "Flat route existed",
        "9004": "Flat route not found",
        "10001": "Zone existed",
        "10004": "Zone not found",
        "11001": "Zone existed",
        "11002": "This area overlaps with another one. Please draw it again.",
        "11003": "Zone must be a polygon object",
        "11004": "Zone not found",
        "11005": "Zone is in use",
        "12001": "Inbox existed",
        "12002": "Please config SMS Twilio",
        "12004": "Inbox not found",
        "12005": "There is no recipient match your filters. Please check the list and try again.",
        "12006": "No customer exists in the file.",
        "13001": "This promotion code has been existing on the system. Please input another one",
        "13002": "Invalid Date",
        "13003": "Private",
        "13004": "Promotion code not found",
        "13005": "Only New Customer",
        "13006": "Over Quantity Limit",
        "13007": "Over Budget Limit",
        "13008": "Over Total Use Limit",
        "13011": "This promotion campaign has been deactivated or deleted",
        "13012": "Date range is invalid",
        "13013": "Invalid promotion code. It seems that the promo is applied to other zones",
        "13014": "The zone you selected has been deactivated.",
        "14004": "Booking not found",
        "15002": "Your fleet has been deactivated. Please contact our administrator",
        "15004": "Fleet not found",
        "16001": "Your flat rate name has been existed on system. Please input another one",
        "16004": "Fare not found",
        "17001": "Name is existed",
        "17004": "Package rate not found",
        "18001": "Name is existed",
        "18002": "Fare in use",
        "18004": "Fare not found",
        "19001": "Your package name has been existed on system. Please input another one.",
        "19002": "Fare in use",
        "19004": "Fare not found",
        "20001": "This username already exists on our system. Please enter another one.",
        "20002": "Your account has been inactive. Please contact our administrator",
        "20003": "The username or password you entered is incorrect. Please try again",
        "20004": "The username is not registered in system.",
        "20005": "This link was expired",
        "20006": "Current password does not match. Please try again.",
        "20007": "User # has been existed",
        "20008": "This email already exists on our system. Please enter another one.",
        "21001": "Your permission name has been existed on system. Please input another one.",
        "21002": "Cannot deactivate this permission because it is currently assigned to user(s).",
        "21003": "Role active",
        "21004": "Role not found",
        "22001": "Customer is existed",
        "22004": "Customer not found",
        "22005": "Invalid File Format.",
        "24001": "Phone number invalid",
        "25001": "This name has been existing on the system. Please input another one.",
        "25002": "Invalid time range",
        "25004": "Shift not found",
        "26001": "This name has been existing on the system. Please input another one.",
        "26002": "This shift is currently used in operation, please unassigned it before deleting",
        "26004": "Shift template not found",
        "27001": "Campaign is existed",
        "27002": "Campaign is activated",
        "27004": "Campaign not found",
        "28001": "City is existed",
        "28002": "City is being activated",
        "28003": "City is in use",
        "28004": "City not found",
        "29002": "Phone number is existed",
        "30001": "Queuing area not found",
        "30002": "Queuing area in using",
        "30003": "Your queuing area name has been existed on system. Please input another one",
        "30004": "Queuing area must not overlap with each others",
        "30005": "Queuing area coordinates are invalid",
        "30006": "Pick-up areas are invalid",
        "31001": "Your company Id has been existed on system. Please input another one.",
        "31002": "This username already exists on our system. Please enter another one.",
        "31003": "Corporate not found.",
        "31004": "Corporate User not found.",
        "31005": "Only corporates who are inactive can be deleted.",
        "31006": "This phone has been existing on the Corp Users. Please input another one.",
        "31007": "Corporate traveler not found.",
        "31008": "Card not found.",
        "31009": "This phone has been existing on the system. Please input another one.",
        "31010": "This corporate has been deactivated. Please contact administrator for more information",
        "31011": "This traveller cannot be activated because it belongs to an inactive corporate account",
        "31013": "A business with this name already exists",
        "32001": "Your service name has been existed on system. Please input another one.",
        "32004": "Service not found",
        "33002": "The times ranges must not overlap with each others",
        "34001": "Code is existed",
        "34013": "Date range is invalid",
        "36001": "Name is existed",
        "39001": "The document is required",
        "71001": "Dynamic surcharge existed",
        "71002": "This area overlaps with another one. Please draw it again.",
        "71003": "Dynamic surcharge must be a polygon object",
        "71004": "Dynamic surcharge not found",
        "72001": "Dynamic fare existed",
        "72002": "This area overlaps with another one. Please draw it again.",
        "72003": "Dynamic fare must be a polygon object",
        "72004": "Dynamic fare not found",
        "74001": "Zone existed",
        "74002": "This area overlaps with another one. Please draw it again.",
        "74003": "Zone must be a polygon object",
        "74004": "Zone fare not found",
        "74005": "Zone fare not found",
        "80001": "App banner existed.",
        "80002": "App banner not found.",
        "80003": "Just only item in the inactive status can be deleted.",
        "81001": "File upload is empty.",
        "81002": "File upload is limit size.",
        "82002": "Something went wrong while setting up flightaware. Please try again later.",
        "90001": "Intercity zone existed",
        "90002": "This area overlaps with another one. Please draw it again.",
        "90003": "Intercity zone must be a polygon object",
        "90004": "Intercity zone not found",
        "90005": "Intercity zone is in use",
        "91001": "Intercity rate is existed",
        "91002": "Intercity rate is in use",
        "91003": "Intercity rate not found",
        "92001": "Route name is existed",
        "92002": "Route not found",
        "92003": "This route already exists at other route, please check and try again",
        "92004": "There is an active trip on this route.",
        "93001": "Driver note has been existed on system. Please enter another one.",
        "93002": "Driver note are inactive can be deleted.",
        "93003": "Driver note is in use.",
        "93004": "Driver note not found.",
        "94001": "Your car color name has been existed on system. Please input another one.",
        "94002": "Car color not found",
        "400000": "Phone number is not valid!",
        "400002": "A validation error has occurred.",
        "400003": "Permission denied",
        "400005": "File content is invalid",
        "500000": "Internal system error.",
        "500001": "Please config SMS Twilio",
        "500002": "Request timeout. Please try again later",
        "600001": "Location not found",
        "600002": "This Longitude & Latitude coordinates have existed on the system. Please add another one",
        "610001": "Category existed",
        "610002": "Category not found",
        "610003": "Category existed in party location",
        "700001": "Type is in use",
        "700002": "Partner not found",
        "700003": "Your partner type name has been existed on system. Please input another one.",
        "800000": "This store has been assigned to another merchant account. Please select another one.",
        "800001": "Merchant's bank account can't receive funds.",
        "800002": "Merchant's bank account can't receive funds.",
        "undefined": "Something went wrong with our server, please try it again.",
        "completeBooking": {
            "113": "We are unable to authenticate your payment method. Please choose a different payment method and try again.",
            "450": "The balance is insufficient to pay. Please select another method",
            "451": "The balance is insufficient to pay. Please select another method",
            "521": "The wallet does not have sufficient funds."
        },
        "upload_file_fail": "Invalid file, please check and try again.",
        "upload_photo_fail": "Invalid file, please check and try again.",
        "missing_some_field": "Invalid field, please check and try again."
    },
    "statusDisplay": {
        "supplierAccepted": "Supplier accepted",
        "delivery_supplierAccepted": "Supplier accepted",
        "completedWithoutService": "Completed without driver",
        "confirmed": "Confirmed reservation",
        "pending": "Pending",
        "queue": "Dispatching",
        "offered": "Dispatching",
        "booked": "Driver on the way",
        "engaged": "P.O.B",
        "droppedOff": "Dropped off",
        "pre": "Order accepted",
        "recurrent": "Recurrent",
        "vacant": "",
        "vacantTime": "",
        "arrived": "Arrived and waiting",
        "completed": "Completed",
        "canceled": "Canceled",
        "noShow": "No show",
        "action": "Waiting for process",
        "incident": "Incident",
        "pickupTimeExceeded": "Time out",
        "canceledByCC": "Canceled by CC",
        "canceledByPassenger": "Canceled by passenger",
        "canceledByDriver": "Canceled by driver",
        "canceledByPartner": "Canceled by partner",
        "canceledBymDispatcher": "Canceled by partner",
        "canceledByCorporateAdmin": "Canceled by Corporate Admin",
        "canceledByAPI": "Canceled by API",
        "canceledByWebBooking": "Canceled by web booking",
        "canceledByTimeout": "Canceled by timeout",
        "accepted": "Accepted",
        "rejected": "Rejected",
        "canceledByCorpAd": "Canceled by Corporate Admin",
        "started": "Started",
        "intransit": "In transit",
        "empty": "Empty",
        "notLive": "Partial - not live",
        "live": "Partial - live",
        "full": "Full",
        "delivering": "Delivering",
        "delivery_booked": "Driver on the way to sender",
        "delivery_arrived": "Arrived & waiting for sender",
        "shopForYou_booked": "Driver on the way to collect cash",
        "shopForYou_arrived": "Arrived & waiting to collect cash",
        "collecting": "Driver on the way to collect goods",
        "confirmed_delivery": "Confirmed",
        "food_booked": "Driver on the way to merchant",
        "food_collecting": "Arrived & waiting to collect item",
        "mart_collecting": "Arrived & waiting to collect item",
        "otwMerchant": "Driver on the way to merchant",
        "arrivedAndWaitingToCollectItem": "Arrived & waiting to collect item",
        "canceledByAirlineAdmin": "Canceled by Airline Admin",
        "partialCompleted": "Partial Completed",
        "failed": "Failed",
        "allocated": "Allocated"
    },
    "tripTypes": {
        "scheduled": "Scheduled trips",
        "requested": "Request trips",
        "preferred": "Preferred"
    },
    "login": {
        "Username": "User name",
        "Password": "Password",
        "Remember_me": "Remember me",
        "Forgot_your_password": "Forgot your password",
        "Login": "Login"
    },
    "invoice": {
        "createBtn": "Create invoice",
        "searchPlInvoice": "Search client, contact, invoice number",
        "searchPl": "Search bookId, customer name",
        "createInvoice": "Create Invoice",
        "invoicePeriod": "Invoice Period",
        "invoicePeriodNote": "You can select month to month date ranges.",
        "viewEdit": "View/Edit",
        "billingContact": "Billing Contact",
        "memo": "Memo",
        "memoMessage": "Leave message to customer",
        "dueDate": "Due Date",
        "updateInvoice": "Update Invoice",
        "chargeCustomer": "Charge Customer",
        "invoiceNumber": "Invoice Number",
        "noItem": "No. Items",
        "issuedDate": "Issued Date",
        "refId": "Ref #",
        "pickupTime": "Pick Up Time",
        "pickup": "Pick Up Location",
        "destination": "Drop Off Location",
        "customerName": "Customer Name",
        "totalAmount": "Amount Due",
        "amountDue": "Amount Due",
        "sendInvoice": "Send Invoice",
        "copyInvoice": "Copy Invoice",
        "downloadPDF": "Download as PDF",
        "paymentLink": "Payment link",
        "deleteInvoice": "Delete Invoice",
        "onlySave": "Save Invoice",
        "saveSend": "Save & Send Invoice",
        "sendEmail": "Send Email",
        "addRecipients": "Add Recipients",
        "editInvoice": "Edit Invoice",
        "check": "Check",
        "wire": "Wire",
        "digitalWallet": "Digital Wallet",
        "cash": "Cash",
        "completedTime": "Completed Time",
        "Status": "Status",
        "bookingStatus": "Booking Status",
        "chargeBtn": "Charge {0}",
        "pastDue": "Past due",
        "payCompleted": "This invoice has been paid successfully!",
        "Delete": "Delete invoice?",
        "Delete_content": "This action will permanently remove this invoice. If necessary, you can create a new one for these order(s).",
        "viewInvoice": "View Invoice",
        "pending": "Pending",
        "paid": "Paid",
        "CopyLink": "Copy Link"
    },
    "cue": {
        "jobVisibility": "Job Visibility",
        "jobPubic": "Public",
        "jobPrivate": "Private",
        "jobSetPubic": "Set Public",
        "jobSetPrivate": "Set Private",
        "supplierAccepted": "Supplier accepted",
        "creationTime": "Creation time",
        "Pax": "Pax #",
        "Luggage": "Luggage",
        "allocated": "Allocated",
        "completedWithoutService": "Completed without driver",
        "shuttle": "Shuttle",
        "farmIn": "Farm In",
        "chargeCode": "Charge Code",
        "clientCaseMatter": "Trip Description",
        "operatorNote": "Internal Notes",
        "farmOut": "Farm Out",
        "roaming": "Roaming",
        "FlightUpdate": "Flight Update",
        "networkType": "Network type",
        "InHouse": "In-house",
        "carHailing": "Car Hailing",
        "uploadBookings": "Upload Bookings",
        "moreActions": "More Actions",
        "rideSharing": "Ride-sharing",
        "Rearrange": "Rearrange",
        "GroupId": "Group ID #",
        "Active": "Active",
        "Finished": "Finished",
        "Home_affiliate": "Home / Affiliate",
        "Booking_type": "Booking type",
        "From": "From",
        "balanceWallet": "{0}: {1} remaining",
        "partialWallet": "Partial payment with Wallet",
        "To": "To",
        "Status": "Status",
        "Migration_Status": "Migration Status",
        "Migrated": "migrated",
        "Select_Status": "Select Status",
        "Operator": "Operator",
        "Car_types": "Car types",
        "Service_class": "Service class",
        "Booking_from": "Booking from",
        "All": "All",
        "Pending": "Pending",
        "Dispatching": "Dispatching",
        "Waiting_for_process": "Waiting for process",
        "Confirmed_reservation": "Confirmed reservation",
        "Driver_on_the_way": "Driver on the way",
        "Arrived_and_waiting": "Arrived and waiting",
        "Passenger_on_board": "Passenger on board",
        "Dropped_off": "Dropped off",
        "combobox_select": "Select...",
        "Search_here": "Search here",
        "Bookingid": "Booking #",
        "Pickup_Drop_Off": "Pickup/ Drop Off",
        "Pickup_Drop_Off_Crew": "Pickup/Drop Off time",
        "Pickup_Drop_Off_Local_Time": "Operator Time",
        "Pickup_Location_Destination": "Pickup Location/ Destination",
        "Passenger": "Passenger",
        "Intercity_Route": "Intercity route",
        "Airport_Pickup": "Airport Pickup",
        "Airport_Pickup_Crew": "Airport",
        "Flight_Status": "Status",
        "Payment": "Payment",
        "Vehicle": "Vehicle",
        "Car_type": "Car Type",
        "Driver": "Driver",
        "Estimated_Fare": "Estimated Fare",
        "Notes": "Notes",
        "Note": "Note",
        "Type": "Type",
        "Home": "Home",
        "Affiliate": "Affiliate",
        "now": "Now (Transport)",
        "reservation": "Reservation (Transport)",
        "recurring": "Recurring (Transport)",
        "No_show": "No show",
        "Canceled_by_CC": "Canceled by CC",
        "Canceled_by_passenger": "Canceled by passenger",
        "Canceled_by_partner": "Canceled by partner",
        "Canceled_by_mDispatcher": "Canceled by partner",
        "Canceled_by_Corporate_Admin": "Canceled by Corporate Admin",
        "Canceled_by_API": "Canceled by API",
        "canceled_By_Web_Booking": "Canceled by web booking",
        "canceled_By_Time_Out": "Canceled by timeout",
        "Canceled": "Canceled",
        "Completed": "Completed",
        "Incident": "Incident",
        "Payment_Method": "Payment Method",
        "No_Phone": "No Phone",
        "VIP": "VIP",
        "Non_VIP": "Non-VIP",
        "Passenger_type": "Passenger type",
        "Error_load_booking": "Cannot load the booking, Please try again or refresh page!",
        "Edit_total_success": "Total successfully edited!",
        "Edit_total_fail": "The total fare must be equal or greater than {0}",
        "Time_out": "Time out",
        "Ride_Service": "Ride-service",
        "Regulation": "Regulation",
        "Ride_Sharing": "Ride-sharing",
        "Unmatching": "Unmatching",
        "Matching": "Matching",
        "all": "All",
        "matching": "Matching",
        "unmatching": "Unmatching",
        "regulation": "Regulation",
        "Support_service": "Support service",
        "SOS": "SOS",
        "Non_SOS": "Non-SOS",
        "Corporate": "Corporate",
        "Individual": "Individual",
        "On_curb": "On curb",
        "Meet_Greet": "Meet & Greet",
        "Close": "Close",
        "auto_refrest_cue": "Auto-refresh ({0} seconds)",
        "Ride_type": "Ride type",
        "Edit_total_note": "Note: subtotal, tech fee, booking fee, tax and tip will be updated when changing the total amount",
        "Booking": "Booking",
        "Edit_fare": "Edit fare",
        "Adjust_price": "Adjust price",
        "Total": "Total",
        "Reason": "Reason",
        "Please_enter_reason": "Please enter reason.",
        "Save": "Save",
        "Cancel": "Cancel",
        "dmc": "Booking board",
        "corp": "Booking board (CTM)",
        "Zone": "Pickup zone",
        "mDispatcher": "Partner",
        "streetSharing": "Street-sharing",
        "Driver_No_Job_found": "Waiting for assignments...",
        "expandFilter": "Show filter",
        "collapseFilter": "Hide filter",
        "clearSelectedItems": "Clear Selected Items",
        "Accepted": "Accepted",
        "Yes": "Yes",
        "No": "No",
        "Order_accepted": "Order accepted",
        "Confirmed": "Confirmed",
        "spotTime": "Spot Time",
        "ETA": "ETA",
        "spareTime": "Spare Time",
        "expectedTime": "Expected pickup time",
        "Driver_on_the_way_to_sender": "Driver on the way to sender",
        "Driver_on_the_way_to_recipient": "Driver on the way to recipient",
        "Driver_on_the_way_to_collect_cash": "Driver on the way to collect cash",
        "Driver_on_the_way_to_collect_goods": "Driver on the way to collect goods",
        "Arrived_waiting_for_sender": "Arrived & waiting for sender",
        "Arrived_waiting_for_recipient": "Arrived & waiting for recipient",
        "Arrived_waiting_to_collect_cash": "Arrived & waiting to collect cash",
        "Arrived_waiting_to_deliver_goods": "Arrived & waiting to deliver goods",
        "Delivered": "Delivering",
        "delivery": "Delivery",
        "Service": "Service",
        "intercity": "Intercity",
        "Transport": "Transport",
        "About_Sender": "About Sender",
        "About_Merchant": "About Merchant",
        "About_Recipient": "About Recipient",
        "photos": "Photos",
        "listOfItems": "List of item",
        "cashOnPickUp": "COP",
        "Package_information": "Package information",
        "otwMerchant": "Driver on the way to merchant",
        "arrivedAndWaitingToCollectItem": "Arrived & waiting to collect item",
        "Save_Filter": "Save Filter",
        "add_favorite_filter": "Add filter",
        "favorite_filter_name": "Name",
        "list_favorite_filter": "Manage filters",
        "favorite_filter_actions": "Action",
        "clear_all_filters": "Clear all filters",
        "Delete_Filter": "Delete Filter",
        "CONFIRM_DELETE_FILTER_TEMPLATE": "Are you sure you want to delete this filter?",
        "batchDelivery": "Batch Delivery",
        "editFare": {
            "creditTransactionFee": "Credit card fees",
            "preferredAdjustment": "Preferred adjustment",
            "editFareFee": "Edit fare & fees",
            "markupPrice": "Markup price",
            "quotedPrice": "Quoted price",
            "basicFare": "Basic fare",
            "preferredEarning": "Preferred earning",
            "difference": "Difference",
            "rushHourFee": "Surcharge",
            "serviceFee": "Add'l service",
            "otherFees": "Other fees",
            "subTotal": "Sub total",
            "airportFee": "Airport fee",
            "meetDriverFee": "Meet driver",
            "deliveryFee": "Delivery fee",
            "tollFee": "Toll fee",
            "parkingFee": "Parking fee",
            "gasFee": "Gas fee",
            "techFee": "Tech fee",
            "tip": "Tip",
            "bookingFee": "Booking fee",
            "tax": "Tax",
            "promoAmount": "Promo amt",
            "etaFare": "Total",
            "fleetService": "Fleet service",
            "Note_serviceFee": "(to edit additional service, visit outer list)",
            "startingFee": "Starting",
            "moveFeeInJourney": "Moving",
            "waitFeeInJourney": "Waiting (in-journey)"
        },
        "partialCompleted": "Partial Completed",
        "failed": "Failed",
        "Recurring": "Recurring #",
        "DragAndDrop": "Drag and drop to reorder columns.",
        "bookingCom": "Booking.com",
        "syncingCompleted": "Syncing completed",
        "groupManifest": "Group Manifest",
        "assignSuppliers": "Assign Suppliers",
        "viewActions": "View Actions",
        "holidaytaxis": "HolidayTaxis",
        "karhoo": "Karhoo",
        "pickupTime": "Pick-up time",
        "AssignSupplier": "Assign Supplier",
        "sharedTransfer": "Shared Transfer (%{id})",
        "batchDeliveryGroup": "Batch Delivery (%{id})",
        "foundersFund": "Founders Fund",
        "zeroCommission": "Zero Commission",
        "unassignDriver": "Unassign Driver",
        "notUnassignDriver": "Cannot unassign driver, Please try again",
        "notAssignDriver": "Cannot assign driver, Please try again",
        "selectDriver": "Select Driver",
        "unassignVehicle": "Unassign Vehicle",
        "unassignAll": "Unassign All",
        "notUnassignAll": "Cannot unassign all, Please try again",
        "unassignAllSuccess": "The vehicle and driver have been successfully detached.",
        "unassign_all_partial": "Partial Success: Batch unassignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
        "notUnassignVehicle": "Cannot unassign vehicle, Please try again",
        "notAssignVehicle": "Cannot assign vehicle, Please try again",
        "selectVehicle": "Select Vehicle",
        "confirmUnassign": "Are you sure you want to unassign?"
    },
    "trip": {
        "Driver_vehicle": "Driver's vehicle",
        "tripId": "Trip #",
        "tripTypes": "Trip types",
        "Start_Time": "Start time",
        "Seats_Status": "Seats status",
        "No_Of_Booking": "No. of bookings",
        "Vehicle": "Driver's vehicle",
        "Estimated_Fare": "Fare",
        "capacityStatus": "Seat status",
        "Trip_Types": "Trip types",
        "scheduled": "Scheduled trips",
        "requested": "Request trips",
        "preferred": "Preferred",
        "All": "All",
        "Confirmed": "Confirmed",
        "Started": "Started",
        "In_Transit": "In Transit",
        "Empty": "Empty",
        "Partial_not_live": "Partial - not live",
        "Partial_live": "Partial - live",
        "Full": "Full",
        "Error_load_trip": "Cannot load the trip, Please try again or refresh page!"
    },
    "tripDetails": {
        "Trip_ID": "Intercity trip",
        "DRIVER_JOB": "Driver info",
        "BOOKINGS": "Bookings",
        "updateTrip": "Update Trip",
        "cancelTrip": "Cancel Trip",
        "note_cancel": "Please assign all bookings to another driver before cancel this trip.",
        "TRIP_INFORMATION": "Trip Info",
        "route": "Route",
        "startTime": "Start time",
        "distance": "Distance",
        "duration": "Duration",
        "fare": "Fare",
        "cartype": "Intercity cartype",
        "seatStatus": "Seat status",
        "started": "Started",
        "intransit": "In transit",
        "empty": "Empty",
        "notLive": "Partial - not live",
        "live": "Partial - live",
        "full": "Full",
        "noOfBooking": "No. of booking",
        "luggage": "Luggage",
        "nameDriver": "Name",
        "vehicleType": "Vehicle",
        "assignDriver": "Assign driver",
        "pickUpAddress": "Pick up",
        "destinationAddress": "Drop off",
        "customerName": "Name",
        "customerPhone": "Phone",
        "customerNumber": "Seats",
        "customerLuggageNumber": "Luggage",
        "additionalService": "Additional services",
        "paymentBooking": "Payment",
        "note": "Note",
        "CONFIRM_UPDATE": "confirm update",
        "CONFIRM_UPDATE_MESSAGE": "Are you sure you want to update this trip?",
        "Yes": "Yes",
        "No": "No",
        "Update_info": "Yes, Update info"
    },
    "newbooking": {
        "Search_Vehicle": "Search for vehicle",
        "rideSharing_multi_point": "Only 1 pick-up and 1 drop off point are allowed for ride sharing booking.",
        "Pick_up_time": "Pick up time",
        "Drop_off_time": "Drop off time",
        "All_Suppliers": "All suppliers",
        "Recipient": "Recipient",
        "All": "All",
        "New_Booking": "New Booking",
        "GoogleMapRoute": "Google Map",
        "BOOKING_INFORMATION": "Booking Info",
        "TIP_PROMO": "Tip - Promo",
        "Pick_up": "Pick-up",
        "instructions": "Instructions",
        "instructions_placeholder": "List your instructions here...",
        "Enter_a_location": "Enter a location",
        "Enter_a_location_delivery": "Enter a location",
        "Enter_3rd_party_location": "Enter 3rd party location",
        "Enter_a_sender": "Enter a Sender",
        "Enter_a_recipient": "Enter a Recipient",
        "Vehicle_Selection": "Vehicle Selection",
        "Destination": "Destination",
        "Pickup_time": "Pick up time",
        "Note": "Note",
        "note_for_driver": "Note for driver",
        "affiliate_note": "Note for affiliate",
        "affiliate_note_placeholder": "Enter affiliate notes for operators",
        "operator_note": "Enter internal notes for operators",
        "TRAVELER_INFORMATION": "Traveler Info",
        "Traveler_type": "Traveler type",
        "Individual": "Individual",
        "Corporation": "Corporation",
        "Phone_number": "Phone number",
        "First_name": "First name",
        "Last_name": "Last name",
        "Email_address": "Email address",
        "Corp_id": "Corp id",
        "Manager_name": "Manager's name",
        "Manager_email": "Manager's email",
        "Cost_centre": "Cost centre",
        "Department": "Department",
        "Corp_division": "Corp division",
        "Tip": "Tip",
        "Promo_code": "Promo code",
        "Apply": "Apply",
        "Promo": "Promo:",
        "DISPATCH": "Dispatch",
        "Auto_dispatch": "Auto dispatch",
        "Assign_driver": "Assign",
        "FarmOut": "Farm-out",
        "Driver": "Driver",
        "Driver_Supplier": "Supplier/Driver",
        "PAYMENT_METHOD": "Payment Method",
        "OPERATOR_NOTE": "Internal Notes",
        "TRIP_ESTIMATE": "Trip Estimate",
        "Choose_a_stored_card": "Choose a stored card",
        "Choose_a_payment_method": "Choose a payment method",
        "Use_new_card": "Use new card",
        "Card_holder": "Card holder",
        "Card_number": "Card number",
        "Expired_date": "Expire date(mm/yy)",
        "ccv": "CVV",
        "Distance": "Distance",
        "Duration": "Duration",
        "Fare": "Fare",
        "Route": "Route",
        "Create": "Create",
        "Cancel": "Cancel",
        "Meet_driver": "Meet driver",
        "Airline": "Airline",
        "Flight": "Flight #",
        "FLY_INFO": "Flight Info",
        "Ride_Sharing": "Ride-Sharing",
        "Round_trip": "Round trip",
        "Car_type": "Car type",
        "Address": "Address",
        "City": "City",
        "State": "State",
        "Zip_code": "Zip code",
        "Remove": "Remove",
        "Client_case_matter": "Client case matter / Trip description",
        "Charge_code": "Charge code / Trip code",
        "CANCEL_BOOKING": "Close Booking Confirm",
        "CANCEL_BOOKING_CONFIRM": "Do you want to leave this form? You will lose all the booking data.",
        "Search_here": "Search here",
        "No_support_location": "Unfortunately, the service is not available in this area",
        "Closest_driver_message": "The closest driver is approximately {0} away",
        "No_cars_available": "No car available within dispatch radius.",
        "processed_by_affiliate": "Your requests will be processed by our affiliate partners",
        "FlightExample": "Flight #, e.g AA1097",
        "Yes": "Yes",
        "No": "No",
        "dispatch_3rd": "Dispatch via 3rd party",
        "Company": "Company",
        "Card_required": "Please select or add a card!",
        "Tip_value": "N/A",
        "Calendar": "Calendar",
        "Extra_Destination": "Additional destination",
        "Driver_credit_limited_balance_warning": "This driver's balance is less than or equal limited amount",
        "Meet_Greet_option": "Meet & Greet",
        "On_Curb_option": "On curb",
        "Extra_Services": "Additional Services",
        "Addl_Services": "Additional Services",
        "Save": "Save",
        "Regular": "Regular",
        "Please_fill_in_all_the_required_fields": "Please fill in all the required fields.",
        "Please_select_weekdays": "Please select the repeat on days you want this booking to occur",
        "Flat_rate": "Flat rate",
        "All_company": "All company",
        "Best_price": "Best price",
        "PromoTitle": "Promo",
        "Show_more": "Show more",
        "Warning_pending_payment": "This customer has incomplete payment from past bookings: %{fare}",
        "Warning_outstanding_balance": "Outstanding balance will be applied at the end of the trip.",
        "Not_available": "Not available",
        "Warning_assigned_rate": "Please set up and assign rate for selected car type to continue.",
        "PaxLuggageNumber": "Passenger/luggage number",
        "paxNumber": "Passenger",
        "luggageNumber": "Luggage",
        "Date": "Date",
        "Single": "Single",
        "Recurring": "Recurring",
        "Date_range": "Date Range",
        "WeekDays": "Repeat on days",
        "Create_Route": "Create Route",
        "Pickup_Point": "Pick-up",
        "Drag_To_Render": "Drag and drop to reorder",
        "DropOff_Point": "Drop off points",
        "Sender": "Sender",
        "Recipients": "Recipients",
        "Sender_Infomation": "Sender Information",
        "Recipient_Infomation": "Recipient Information",
        "Recipient_Name": "Recipient name *",
        "Recipient_Phone": "Phone Number *",
        "Room_floor_block": "Room, floor, and block (optional)",
        "Details": "Details",
        "Package_Content": "Package Content *",
        "Order_ID": "Order # (eg. shopify order #)",
        "Instructions_for_driver": "Instructions for driver",
        "Cost_of_Goods": "Cash on Delivery (COD)",
        "Enter_amount": "Enter amount",
        "Additional_Information": "Additional Information",
        "Insurance": "Insurance",
        "Special_Care": "Special Care",
        "Delivery_Fee": "Delivery Fee",
        "Item_fees": "Item fees",
        "Tech_Fee": "Tech Fee",
        "Tax": "Tax",
        "driverNotes": "Driver Notes",
        "rejectModal": "Reject booking",
        "rejectModalBody": "Are you sure you want to reject this booking?",
        "rejectButton": "Reject booking",
        "acceptModal": "Accept booking",
        "acceptModalBody": "Are you sure you want to Accept this booking?",
        "ConfirmedTo": "Confirmed to ({0})",
        "proceedAction": "Are you sure you want to proceed with this action?",
        "acceptButton": "Accept booking",
        "denyModal": "Deny booking",
        "denyModalBody": "Are you sure you want to deny this booking?",
        "denyButton": "Deny booking",
        "Accept_booking_success": "This booking has been accepted successfully!",
        "Deny_booking_success": "This booking has been denied successfully!",
        "adjustFare": "Adjust fare",
        "totalFare": "Total Fare",
        "Profit": "Profit",
        "DriverEarning": "Driver Earning",
        "Payout": "Payout",
        "totalPayout": "Total payout",
        "DriverPayOut": "Driver Payout",
        "confirmReject": "Confirm Rejection",
        "confirmCancelMSG": "Are you sure you want to reject this booking? You may incur a penalty from your provider.",
        "reasonCancel3rd": "Reason for rejection",
        "welcome_psg": "Welcome passenger",
        "AmountDue": "Amount Due",
        "Fleet_Company": "Fleet",
        "Search_driver_hydra": "We will try to find you a driver soon.",
        "ExternalID": "External ID",
        "viewRate": "View Rate",
        "PointToPoint": "Point to point",
        "Offer_driver": "Offer Driver",
        "Assign_driver_vehicle": "Assign Driver / Vehicle",
        "notify_via_email": "Notify Customer via Email"
    },
    "bookingDetailLogs": {
        "recipient": "Recipient",
        "updatedInfo": "Updated info",
        "updatedField": "Updated field",
        "valueBefore": "Value before",
        "valueAfter": "Value after",
        "location": "Location",
        "etaFare": "Fare",
        "dispatchType": "Dispatch",
        "drvInfo": "Driver",
        "vehicleTypeRequest": "Vehicle Request",
        "clientCaseMatter": "Trip description",
        "chargeCode": "Trip code",
        "driverEarningType": "Driver Earning Type",
        "editedDriverEarning": "Driver earning",
        "reasonEditFare": "Reason",
        "bookingReference": "External ID",
        "tip": "Tip",
        "type": "Service type",
        "promo": "Promo",
        "dispatch3rd": "Dispatch 3rd",
        "rideSharing": "Ride-Sharing",
        "notes": "Driver notes",
        "operatorNote": "Internal notes",
        "welcomeMessage": "Welcome Message",
        "flightNumber": "Flight Number",
        "fullName": "Full Name",
        "email": "Email",
        "cardMask": "Card mask",
        "paymentType": "Payment method",
        "recipients": "Recipients",
        "sender": "Sender",
        "pickupName": "Pickup Name",
        "pickupPhone": "Pickup Phone",
        "pickupEmail": "Pickup Email",
        "supplierEarningType": "Payout type",
        "editedSupplierEarning": "Payout value",
        "route": "Route name",
        "markupDifference": "Markup Difference",
        "selectedDates": "Selected Dates",
        "services": "Fleet services",
        "destinationAddress": "Destination location",
        "pickupAddress": "Pick-up location",
        "tripType": "Trip Type",
        "pickUpTime": "Pickup time",
        "paxNumber": "Passenger",
        "luggageNumber": "Luggage",
        "vehicle": "Vehicle",
        "minutesToArrive": "Expected driver arrival",
        "affiliateNote": "Affiliate Note",
        "packageRateName": "Package name",
        "supplierCompanies": "Company",
        "puPoints": "Pick-up location",
        "doPoints": "Destination location",
        "promoValue": "Promo value",
        "packageInfo": "Package info",
        "orderId": "Order Id",
        "cashOnDelivery": "Cash on delivery",
        "externalId": "External Id",
        "recurring": "Recurring",
        "batchDelivery": "Batch Delivery",
        "payout": "Payout",
        "locationDetails": "Location Details",
        "name": "Name",
        "phone": "Phone",
        "address": "Address",
        "basicFare": "Basic fare",
        "airportFee": "Airport fee",
        "bookingFee": "Booking fee",
        "creditTransactionFee": "Credit card fees",
        "fleetService": "Fleet service",
        "fleetServices": "Fleet services",
        "gasFee": "Gas fee",
        "meetDriverFee": "Meet driver fee",
        "moveFeeInJourney": "Moving fee",
        "otherFees": "Other fee",
        "parkingFee": "Parking fee",
        "promoAmount": "Promo amt",
        "rushHourFee": "Rush hour fee",
        "serviceFee": "Service fee",
        "startingFee": "Starting fee",
        "subTotal": "Sub total",
        "tax": "Tax",
        "techFee": "Tech fee",
        "tollFee": "Toll fee",
        "waitFeeInJourney": "Waiting (in-journey) fee"
    },
    "bookingdetail": {
        "settings": "Settings",
        "updateSuccessJobVisibilitySingle": "Update visibility successfully",
        "updateSuccessJobVisibilityMulti": "%{number} bookings has been updated successfully",
        "failApi": "Something went wrong!",
        "settingsTooltip": "Driver already assigned or trip is currently in progress.",
        "shareJobPublicly": "Share Job Publicly",
        "groupOder": "Group order",
        "ExistingGroup": "Existing Group",
        "NewGroup": "New Group",
        "Search_trip": "Search trip #",
        "All": "All",
        "creditTransactionFee": "Credit card fees",
        "partial_payment": "Partial Payment",
        "Write_off_debt": "Write off debt",
        "Booking_detail": "Booking",
        "Order_detail": "Order",
        "Completed_Booking": "Completed Booking",
        "Late_Cancel": "Late cancel",
        "Cancel_Driver": "Cancel by driver",
        "No_Show": "No show",
        "Without_driver": "Complete without driver",
        "Complete_driver": "Complete with driver",
        "BOOKING_INFORMATION": "Booking information",
        "TIP_PROMO": "Tip - Promo",
        "Rearrange_Column": "Rearrange Column",
        "Pick_up": "Pick-up",
        "Enter_a_location": "Enter a location",
        "Destination": "Destination",
        "Pickup_time": "Pick up time",
        "Notes": "Notes",
        "TRAVELER_INFORMATION": "Traveler information",
        "Traveler_type": "Traveler type",
        "Individual": "Individual",
        "Corporation": "Corporation",
        "Phone_number": "Phone number",
        "First_name": "First name",
        "Last_name": "Last name",
        "Email_address": "Email address",
        "Corp_id": "Corp id",
        "Manager_name": "Manager's name",
        "Manager_email": "Manager's email",
        "Cost_centre": "Cost centre",
        "Department": "Department",
        "Corp_division": "Corp division",
        "Tip": "Tip",
        "Promo_code": "Promo code",
        "Apply": "Apply",
        "Promo": "Promo",
        "DISPATCH": "Dispatch",
        "Auto_dispatch": "Auto dispatch",
        "Assign_driver": "Assign",
        "Driver": "Driver",
        "PAYMENT_METHOD": "Payment method",
        "Payment_methods": "Payment methods",
        "pending_payment": "Pending Payment",
        "TRIP_ESTIMATE": "Trip estimate",
        "Choose_a_stored_card": "Choose a stored card",
        "Choose_a_payment_method": "Choose a payment method",
        "OPERATOR_NOTE": "Internal Notes",
        "Use_new_card": "Use new card",
        "Card_holder": "Card holder",
        "Card_number": "Card number",
        "Expired_date": "Expire date(mm/yy)",
        "ccv": "CVV",
        "Distance": "Distance",
        "Duration": "Duration",
        "Fare": "Fare",
        "Route": "Route",
        "Meet_driver": "Meet driver",
        "Airline": "Airline",
        "Flight": "Flight #",
        "FLY_INFO": "Flight info",
        "Ride_Sharing": "Ride-Sharing",
        "Round_trip": "Round trip",
        "Car_type": "Car type",
        "Address": "Address",
        "City": "City",
        "State": "State",
        "Zip_code": "Zip code",
        "Remove": "Remove",
        "New_Booking": "New Booking",
        "Clone_Booking": "Clone Booking",
        "New_Order": "New Order",
        "ViewTrackLink": "View track link",
        "driverLink": "Driver link",
        "ClickCopy": "Click to copy link",
        "ViewLog": "View dispatch logs",
        "Message": "Message",
        "Update_Booking": "Update Booking",
        "Cancel_Booking": "Cancel Booking",
        "Complete_with_payment": "Complete With Payment",
        "Incident": "Incident",
        "CONFIRM_CANCEL": "confirm cancel",
        "CONFIRM_CANCEL_MESSAGE": "Are you sure you want to cancel this booking?",
        "CONFIRM_UPDATE": "confirm update",
        "CONFIRM_UPDATE_MESSAGE": "Are you sure you want to update this booking?",
        "CONFIRM_RESET_EDIT_FARE_MESSAGE": "Your edited fare has been reset, please review before continue.",
        "Close": "Close",
        "Update_info": "Yes, Update info",
        "Update_info_dispatch": "Yes, Update info & dispatch",
        "Update_info_tooltip": "If you are going to reassign this booking to a new driver, please choose \"Yes, Update info & dispatch\" button.",
        "BOOKING": "Booking",
        "DROPPED_OFF": "Dropped off",
        "Payment_Type": "Payment Type",
        "Total": "Total",
        "Next": "Next",
        "Pay_here": "Pay here",
        "Complete_booking": "Complete booking",
        "Complete_booking_tooltip": "Please click \"Update booking\" to save your changes before continuing.",
        "Cancel": "Cancel",
        "Back": "Back",
        "Tax": "Tax",
        "Booking_fees": "Booking fees",
        "Tech_fees": "Tech fees",
        "Subtotal": "Subtotal",
        "Other_fees": "Other fees",
        "Waiting_fee": "Waiting fee",
        "Toll_fees": "Toll fees",
        "Heavy_traffic": "Heavy traffic",
        "Rush_hour": "Surcharge",
        "Basic_fare": "Basic fare",
        "Ok": "OK",
        "Input_reason": "Input reason",
        "Client_case_matter": "Client case matter / Trip description",
        "Charge_code": "Charge code / Trip code",
        "Enter_3rd_party_location": "Enter 3rd party location",
        "Company": "Company",
        "Check_promo_error": "Check promoCode error!",
        "Add_new_credit_error": "Add new credit error!",
        "Can_not_get_fare_setting": "Cannot get fare setting!",
        "Can_not_get_affilate_fare_setting": "Cannot get Affiliate fare settings!",
        "Can_not_check_commisson": "cannot check commisson!",
        "No_flight_info": "No flight info!",
        "Socket_server_disconnected": "Socket server disconnected!",
        "Please_reload_page_or_check_your_connection": "Sorry. Your request cannot be processed. Please try again.",
        "View_Logs": "View logs",
        "Bookingid": "Booking #",
        "Calendar": "Calendar",
        "Extra_Destination": "Additional destination",
        "Meet_Greet_option": "Meet & Greet",
        "On_Curb_option": "On Curb",
        "Credit_Card": "Credit card",
        "Charge": "Charge",
        "No_charge": "No charge",
        "QR_Code_method_not_allow_error_message": "Please select another payment type as QRCode is not available to pay on dashboard!",
        "change_payment_method_note": "If you want to change payment method, please go back to booking form.",
        "Airport_Fee": "Airport Fee",
        "Addi_service": "Add'l Services",
        "Additional_Services": "Additional Services",
        "Minimum": "Minimum",
        "Search_here": "Search here",
        "No_Driver_Yet": "No driver assigned yet",
        "Personal_Card": "Personal card",
        "Reject_booking": "Reject Booking",
        "Confirm_reject": "Confirm reject",
        "Confirm_reject_message": "Are you sure you want to reject this booking?",
        "Confirm_reject_booking_with_charge": "You will be charged {0}% of quoted fare based on our cancellation policy. Are you sure you want to reject this booking?",
        "Yes": "Yes",
        "No": "No",
        "shortTrip": "Short Trip",
        "Add_new_card": "Add new card",
        "Require_customer_info": "Please enter customer information to continue.",
        "confirm_force_overlap": "Confirmation",
        "confirm_force_overlap_message": "This booking overlaps with driver's accepted bookings. Are you sure you want to assign it to this driver?",
        "select": "Select",
        "PICKUP_TIME_RANGE": "Estimated pickup at {0}",
        "food": "Food",
        "mart": "Mart",
        "Order_summary": "Order Summary",
        "addOnPrice": "Adjusted price",
        "applicableFee": "Total (applicable)",
        "itemValue": "Item value",
        "promotion_note_paymentMethod": "This order is being applied promotion code with limited payment method: %{method}.",
        "promotion_note_minimum_fare": "This order is being applied promotion code with limited minimum fare: %{fare}.",
        "promotion_note_schedules": "This order is being applied promotion code with limited pickup time.",
        "edited_fare_note": "This is fare adjustment logs for reference only. Please visit reports to check final fare and fee.",
        "view_good_receipt": "View goods receipt",
        "confirmRemoveGroupTransport": "Are you sure you want to remove current booking from this manifest?",
        "confirmRemoveGroupDelivery": "Are you sure you want to remove current booking from this group?",
        "confirmUngroupTransport": "Are you sure you want to ungroup all bookings from this manifest?",
        "confirmUngroupDelivery": "Are you sure you want to ungroup all bookings from this group?",
        "view_delivery_receipt": "View delivery photo",
        "select_time": "Select time",
        "InputReason": "Input reason",
        "viewOrder": "View order",
        "markFailed": "Mark as failed",
        "corporateName": "Corporate Name",
        "sendMessage": "Send Message",
        "AmountDueBefore": "Amount Due Before",
        "AmountDueAfter": "Amount Due After",
        "chargeCustomer": "Charge Customer",
        "pending": "Not Paid",
        "refundPayment": "Refund Payment",
        "refundableAmount": "Refundable amount",
        "refundReason": "Add refund reason",
        "refundBtn": "Refund {0}",
        "partial": "Partial",
        "paid": "Paid",
        "ViewPaymentLogs": "View payment logs",
        "pmActivity": "Payments Activity",
        "paidStatus": "Paid status",
        "paymentLog": "Payment Log",
        "extraWaitFee": "Waiting fee Initial",
        "extraWaitTime": "Wait time (Initial)",
        "copied": "Copied",
        "warnningCancelCorporate": "Please note, you may incur a charge of amount in accordance with our cancellation policy."
    },
    "map": {
        "Enter_a_location": "Enter a location",
        "All_company": "All company",
        "All_cars": "All cars",
        "Show_all_cars": "Show all cars",
        "Hide_all_cars": "Hide all cars",
        "Vacant": "Available",
        "Dispatching": "Dispatching",
        "Driver_on_the_way": "Driver on the way",
        "Arrived_and_waiting": "Arrived and waiting",
        "P_O_B": "P.O.B",
        "Dropped_off": "Dropped off",
        "Waiting_for_process": "Waiting for process",
        "Unavailable": "Unavailable",
        "Total_Drivers": "Total Drivers",
        "Estimate": "Estimate",
        "Destination": "Destination",
        "Pickup": "Pickup",
        "Start_point": "Start point",
        "Passenger": "Passenger",
        "Driver_name": "Driver name",
        "Search_car_here": "search car/driver name",
        "Error_load_booking": "Cannot load the booking, Please try again or refresh page!",
        "Show_all": "Show all",
        "driversLiveMap": "Drivers Map",
        "heatMap": "Demand Map",
        "Trip": "Trip",
        "traffic": "Traffic",
        "bicyclingLayers": "Bicycling",
        "layer": "Layer",
        "trafficBicycling": "Traffic,Bicycling",
        "All_airline": "All airline"
    },
    "customer": {
        "Customers": "Customers",
        "Points": "Points",
        "Migration_Status": "Migration Status",
        "ADD_CUSTOMER": "Add customer",
        "EDIT_CUSTOMER": "Edit customer",
        "Add": "Add",
        "Activate": "Activate",
        "Deactivate": "Deactivate",
        "Delete": "Delete",
        "Import": "Import",
        "Download_template": "Download template",
        "View_Message": "Message",
        "Send_Message": "Send Message",
        "Export_to_excel": "Export to excel",
        "Filter": "Filter",
        "Apply": "Apply",
        "Clear": "Clear",
        "Name": "Name",
        "Phone_Number": "Phone Number",
        "Email": "Email",
        "Vip": "Vip",
        "companies": "Company",
        "File": "File",
        "Number_successful_imports": "Number of successful imports:",
        "Number_skipped": "Number of skipped:",
        "Number_duplicate_phone_numbers": "Number of duplicate phone numbers:",
        "Number_wrong_phone_numbers": "Number of wrong phone numbers:",
        "Corporate_Name": "Corporation",
        "Registered_From": "Registered From",
        "Registration_Date": "Registration Date",
        "Registration_date_from": "Registration date from",
        "Registration_date_to": "Registration date to",
        "IOS_Last_Login_Version": "IOS Last-Login Version",
        "Android_Last_Login_Version": "Android Last-Login Version",
        "Last_Active_Date": "Last Active Date",
        "Verification": "Verification",
        "Support_ID": "Support ID",
        "Days_Since_Last_Active": "Days Since Last Active",
        "Completed_Bookings": "Completed Bookings",
        "Outstanding_amount": "Outstanding amount",
        "Status": "Status",
        "Actions": "Actions",
        "Action": "Action",
        "Inactive": "Inactive",
        "Active": "Active",
        "Edit": "Edit",
        "View": "View",
        "ALERT_SELECT_ACTIVATE": "Please select customer to activate.",
        "ALERT_SELECT_DEACTIVATE": "Please select customer to deactivate.",
        "ALERT_SELECT_DELETE": "Please select customer to delete.",
        "CONFIRM_DELETE": "Are you sure you want to delete this account? Customer can signup a new profile with empty data after account removal.",
        "CONFIRM_DELETE_MULTI": "Are you sure you want to delete these customers? Only customers who are inactive and not a registered user via app can be deleted.",
        "CONFIRM_DEACTIVATE_MULTI": "Do you want to deactivate these customers?",
        "CONFIRM_ACTIVATE_MULTI": "Do you want to activate these customers?",
        "CONFIRM_EXPORT_TO_EXCEL": "Are you sure you want to export all data as a .xlsx file?",
        "Add_card_top_up_success": "Prepaid card has been topped up successfully",
        "Add_card_success": "The card has been added successfully!",
        "Add_customer_success": "New customer has been created successfully",
        "Update_customer_success": "The customer has been updated successfully",
        "Deleted_customer_success": "The customer has been deleted successfully",
        "Create_customer_fail": "Failed to create customer",
        "Update_customer_fail": "Failed to update customer",
        "ERROR_EXISTED_PHONE": "This phone has been existing on the system. Please input another one",
        "ERROR_INPUT_FIRSTNAME": "Please input first name.",
        "ERROR_INPUT_PHONE": "Please input phone number.",
        "ERROR_INPUT_VALIDPHONE": "Please enter a valid phone number.",
        "ERROR_INPUT_VALIDEMAIL": "Please enter a valid email address.",
        "CONFIRM_DELETE_CARD": "Are you sure you want to delete this cards?",
        "First_name": "First name",
        "Last_name": "Last name",
        "Phone_number": "Phone number",
        "Email_address": "Email address",
        "Notes": "Notes",
        "Account": " Account",
        "Card_management": "Card management",
        "Home": "Home",
        "Affiliate": "Affiliate",
        "SHOW_PERSONAL_CARD": "Show personal card",
        "ADD_PERSONAL_CARD": "Add personal card",
        "SHOW_mDISPATCHER_CARD": "Show partner card",
        "ADD_mDISPATCHER_CARD": "Add partner card",
        "Personal_card": "Personal card",
        "mDispatcher_card": "Partner card",
        "Card_holder": "Card holder ",
        "Card_number": "Card number",
        "Credit_type": "Credit type",
        "Credit_code": "Credit code",
        "Sms_verification": "SMS verification",
        "Sms_verification_placeholder": "Enter verification code",
        "Expired_date": "Expired date ",
        "CVV": "CVV",
        "Address": "Address",
        "Country": "Country",
        "City": "City",
        "State": "State",
        "Zip_code": "Zip code",
        "postal_code": "Postal code",
        "Required_fields": "Required fields",
        "Cancel": "Cancel",
        "Save": "Save",
        "ERROR_INPUT_CARD_HOLDER": "Please input card holder",
        "ERROR_INPUT_CARD_NUMBER": "Please input card number",
        "ERROR_INPUT_EXPIRED_DATE": "Please input expired date",
        "ERROR_VAILD_EXPIRED_DATE": "Expired date is not valid",
        "ERROR_INPUT_CVV": "Please input CVV",
        "ERROR_INPUT_ADDRESS": "Please input address",
        "ERROR_INPUT_CITY": "Please input city",
        "ERROR_INPUT_ZIPCODE": "Please input zip code",
        "ERROR_INPUT_COUNTRY": "Please select country",
        "Info_edit_error": "Error!",
        "Gender": "Gender",
        "Select_Gender": "Select",
        "Select_Idtype": "Select id type",
        "Male": "Male",
        "Female": "Female",
        "Other": "Other",
        "Date_of_birth": "Date of birth",
        "Street_address": "Street address",
        "Select_Zone": "Select Zone",
        "ERROR_GET_GATEWAYS": "ERROR !!! Get Gateways",
        "Require_email": "Please enter the email before adding new card",
        "Require_lastName": "Please enter the last name before adding new card",
        "Require_phone_number": "Please enter the traveler's phone number before make a booking with credit card",
        "IMPORT_CUSTOMER_SUCCESS": "import customers successfully",
        "No_card": "No card found",
        "No_Support": "New card adding is currently not supported in this area",
        "Card_Management": "Card Management",
        "Emergency_contacts": "Emergency contacts",
        "DETAIL_CUSTOMER": "Customer Details",
        "Select_Country": "Select country",
        "Customer_ID": "ID# / National IC#",
        "Credit_balance": "Credit balance",
        "required_email_payment": "The email of customer are required. Please update profile to continue.",
        "Yes": "Yes",
        "No": "No",
        "WriteOffDebt": "Write off debt",
        "selectPayment": "Please select a payment method to close all bookings which have outstanding payment",
        "writeOffDebtSuccess": "Write off debt successfully",
        "writeOffDebtNotes": "Note: This write-off action should only be confirmed after all the outstanding amount is paid in full.",
        "totalCustomer": "Total customers",
        "Personal_Card": "Personal card",
        "INFO_ADD_BALANCE_SUCCESS": "Passenger balance has been updated successfully",
        "INFO_UPDATE_POINT_SUCCESS": "Passenger point has been updated successfully",
        "currency": "Currency",
        "balance": "Balance",
        "changeBalance": "Change balance",
        "change": "Change",
        "WALLET_DEPOSIT": "Wallet",
        "AccessDenied": "You don't have permission to access this module!",
        "referral_code": "Referral code"
    },
    "importBookings": {
        "no": "No.",
        "status": "Status",
        "pickupDate": "Pickup Date",
        "pickup": "Pickup Address",
        "dropoff": "Dropoff Address",
        "passenger": "Passenger",
        "phone": "Phone number",
        "seats": "Seats",
        "luggages": "Luggages",
        "rideSharing": "Ride-share",
        "flightNumber": "Flight No.",
        "notes": "Notes",
        "externalId": "External ID",
        "operatorNote": "Internal notes",
        "clientCaseMatter": "Trip description",
        "chargeCode": "Trip code",
        "paymentType": "Payment",
        "vehicleTypeRequest": "Car type",
        "importBookings": "Import bookings",
        "corporation": "Corporation",
        "clickOrDropCsvFile": "Click or drop.csv file",
        "importingFileForReview": "Importing file for review... ",
        "import": "Import",
        "submit": "Submit",
        "downloadTemplate": "Download template",
        "learnMore": "Learn more",
        "total": "Total",
        "valid": "Valid",
        "invalid": "Invalid",
        "failed": "Failed",
        "processing": "Processing",
        "success": "Success",
        "confirm": "Confirm",
        "confirmMessage": "orders will be created. Are you sure you want to proceed ?",
        "create": "Create",
        "cancel": "Cancel",
        "close": "Close",
        "leavingConfirmMessage": "Your work is not saved! Are you sure you want to leave?",
        "resultMessage": "${success} booking(s) has been created successfully, ${failed} failed.",
        "limitExceededBookings": "Maximum import file size: 100 bookings",
        "delivery": {
            "no": "#",
            "pickupDate": "Pickup date",
            "senderName": "Sender name",
            "senderPhone": "Sender phone",
            "senderMail": "Sender email",
            "senderAddress": "Sender address",
            "recipients": "Recipients",
            "senderDistrictOptional": "District",
            "senderLocationDetails": "Location details",
            "recipientName": "Recipient name",
            "recipientPhone": "Recipient phone",
            "recipientAddress": "Recipient address",
            "recipientDistrictOptional": "District",
            "recipientLocationDetailsOptional": "Location Details",
            "packageContent": "Package content",
            "orderNumber": "Order #",
            "instructions": "Instructions for Driver",
            "cod": "COD Item Value",
            "rideShare": "Ride-share",
            "paymentType": "Payment type",
            "carType": "Car type",
            "recipientLocationDetails": "Location details",
            "operatorNote": "Internal notes",
            "rideSharing": "Batch delivery"
        }
    },
    "merchant": {
        "INFO_ADD_BALANCE_SUCCESS": "Merchant balance has been updated successfully",
        "ADD_MERCHANT_USER": "Add merchant account",
        "EDIT_MERCHANT_USER": "Edit merchant account",
        "MERCHANT_USER_DETAILS": "Merchant account details",
        "CASH_WALLET": "Cash wallet",
        "CREDIT_WALLET": "Credit wallet",
        "ADD_MERCHANT": "Add merchant",
        "ACCOUNT_INFO": "Account Info",
        "BILLING_ADDRESS": "Bank Information",
        "user": {
            "fullName": "Merchant user",
            "Phone_Number": "Phone number",
            "Email": "Email",
            "storeName": "Store",
            "status": "Status",
            "actions": "Action",
            "First_name": "First name",
            "Last_name": "Last name",
            "Select_store": "Select store",
            "accountHolder": "Account holder's name",
            "First_M_Last": "First M. Last",
            "accountNumber": "Account number",
            "ibanNumber": "IBAN number",
            "bankName": "Name of bank",
            "IDtype": "ID Type",
            "IDnumber": "ID# / National IC#",
            "routingNumber": "Routing/Transit/Branch number",
            "checkNumber": "Check number",
            "ssn": "SSN/NI",
            "sortCode": "Sort code",
            "transitNumber": "Transit number",
            "institutionNumber": "Institution Number",
            "birthDay": "Birthday",
            "address": "Address",
            "city": "City",
            "state": "State",
            "postalCode": "Zipcode",
            "beneficiaryIDIC": "Beneficiary ID# / IC#",
            "relationshipOtherName": "Relationship with other?",
            "verificationDocumentFront": "Verification document (front)",
            "verificationDocumentBack": "Verification document (back)",
            "Verification_document_tooltip": "Acceptable forms for identification: \n  - Passport \n  - Driver license \n  - Resident permit ID \n  - Citizen Card \n  - ID card",
            "additionalDocumentFront": "Additional document (front)",
            "additionalDocumentBack": "Additional document (back)",
            "Additional_document_tooltip": "Acceptable forms of address verification: \n  - Driver license \n  - Utility bill \n  - Government issued letter \n  - Statement from a financial institution \n  - Electoral Register entry",
            "please_select": "Please select",
            "commission": "Commission",
            "ERROR_INPUT_VALIDPHONE": "Please enter a valid phone number.",
            "ERROR_EXISTED_PHONE": "This phone has been existing on the system. Please input another one",
            "Activate": "Activate",
            "Deactivate": "Deactivate",
            "ALERT_SELECT_ACTIVATE": "Please select user to activate.",
            "ALERT_SELECT_DEACTIVATE": "Please select user to deactivate.",
            "CONFIRM_ACTIVATE_MULTI": "Do you want to activate these users?",
            "CONFIRM_DEACTIVATE_MULTI": "Do you want to deactivate these users?",
            "RESET_PASSWORD": "Do you want to reset password?",
            "CONFIRM_RESET_PASSWORD": "Do you want to reset password?",
            "REMOVE_BANK_INFO": "Confirm remove bank info",
            "CONFIRM_REMOVE_BANK_INFO": "Do you want to remove bank info?",
            "note_account_password_default": "Note: For new Merchant account, the default password is \"12345678\"",
            "Cash_Balance": "Cash balance",
            "Credit_Balance": "Credit balance"
        },
        "Update_merchant_user_success": "The merchant user has been updated successfully",
        "Update_merchant_user_fail": "Failed to update merchant user",
        "Create_merchant_user_success": "New merchant user has been created successfully",
        "Create_merchant_user_fail": "Failed to create merchant user",
        "Update_merchant_user_status_success": "The merchant user has been updated successfully",
        "Update_merchant_user_status_fail": "Failed to update merchant user",
        "Reset_password_merchant_user_success": "Reset password successfully",
        "Reset_password_merchant_user_fail": "Reset password fail",
        "Deleted_merchant_bank_info_success": "Remove bank info successfully",
        "Deleted_merchant_bank_info_fail": "Remove bank info fail"
    },
    "table_header": {
        "Items_per_page": "Items per page",
        "Page": "Page",
        "Search_here": "Search here",
        "Shown_Active": "%{start} - %{end} of %{total} shown ",
        "Active": "%{active} Active ",
        "OF": "of"
    },
    "driver": {
        "Driver": "Driver",
        "Add": "Add",
        "addDriver": "Add driver",
        "Detail": "Detail",
        "detailDriver": "Driver Details",
        "addNewVehicle": "Add Vehicle",
        "serviceType": "Service Type",
        "transport": "Transport",
        "delivery": "Delivery",
        "stripeConnect": "Stripe Connect",
        "stripeConnectDes": "Add a stripe connect account for easier payouts",
        "bankAccount": "Banking Info",
        "bankAccountDes": "Drivers bank info to handle payouts",
        "supplierComission": "Supplier Commission",
        "supplierComissionDes": "Set commissions the supplier earns for every complete order.",
        "fleetCommission": "Fleet/Supplier commission",
        "fleetCommissionDes": "Set commissions the fleet or supplier earns for every complete order. If the driver is a supplier’s driver, the commission will be deducted from the supplier’s earning.",
        "operationZones": "Operation Zones",
        "operationZonesDes": "Zones that suppliers can receive jobs from:",
        "notes": "Notes",
        "notesDes": "Internal notes about this driver:",
        "searchNotes": "Add notes for driver",
        "marketplace": "Marketplace",
        "marketplaceDes": "Empower drivers to accept jobs they want to do.",
        "otherSettings": "Other Settings",
        "topDriver": "TOP Driver",
        "topDriverDes": "Prioritize top performers with the \"Top Driver\" tag, ensuring they get more rides.",
        "walletTransferBetweenDriver": "Wallet Transfers Between Drivers",
        "walletTransferBetweenDriverDes": "Enable drivers to transfer funds directly to each other’s wallets, facilitating easy distribution of credits by team leaders or ambassadors.",
        "rideSharing": "Ride-sharing",
        "rideSharingDes": "Enable drivers to perform ride-sharing jobs. Contact your account manager for this advance feature.",
        "individual_vehicle": "Individual vehicle",
        "company_vehicle": "Company vehicle",
        "ContactInfo": "Contact Info",
        "name": "Name",
        "expiryDate": "Expiry Date",
        "action": "Action",
        "editDocument": "Edit Document",
        "documentToUpload": "Document to upload",
        "addFiles": "Click or drag file to this area to upload.",
        "fileSize": "Maximum file size 10 MB",
        "fileSizeDes": "The file extension must be .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip",
        "removeOldFile": "Please remove the old file before uploading a new one.",
        "maxLengthFiles": "Please upload max length 1 file",
        "maxSizeFiles": "Please upload file size less than 10MBs",
        "typeFiles": "Please upload file type .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip",
        "maxAvatarSizeFiles": "Please upload avatar size less than 8MBs",
        "IFSCCode": "IFSC Code",
        "Drivers": "Drivers",
        "default": "Default",
        "customize": "Customized",
        "Activate": "Activate",
        "Stripe_connect": "STRIPE CONNECT",
        "status_stripe": "Status: {0} ",
        "stripeConnectStatus": "Stripe connect",
        "stripeStatus": "Stripe account status",
        "Deactivate": "Deactivate",
        "Delete": "Delete",
        "acceptInDay": "Maximum reservations driver can accept in a day",
        "acceptATime": "Total number of reservations a driver can accept at a time",
        "Commission": "Set fleet commission",
        "Title_Commission": "Set fleet commission for all drivers",
        "Confirm_Set_Commission": "Are you sure you want to set this commission value for all drivers?",
        "Commission_Successfully": "Driver commission has been updated successfully",
        "Apply": "Apply",
        "Type": "Type",
        "Currency": "Currency",
        "View_Message": "Message",
        "Send_Message": "Send Message",
        "Driver_Name": "Driver Name",
        "Driver_ID": "ID# / National IC#",
        "Phone_Number": "Phone number",
        "Driver_Type": "Driver Type",
        "TOP": "TOP",
        "Company": "Company",
        "City": "City",
        "Driver_License_Number": "Driver License Number",
        "License_Plate": "Car Registration Number",
        "Car_Type": "Car type",
        "Zone": "Zone",
        "Registered_From": "Registered From",
        "Registration_Date": "Registration Date",
        "IOS_Last_Login_Version": "IOS Last-Login Version",
        "Android_Last_Login_Version": "Android Last-Login Version",
        "Last_Active_Date": "Last Active Date",
        "Days_Since_Last_Active": "Days Since Last Active",
        "Completed_bookings": "Completed bookings",
        "Status": "Status",
        "Actions": "Actions",
        "Action": "Action",
        "Inactive": "Inactive",
        "Active": "Active",
        "Edit": "Edit",
        "View": "View",
        "ALERT_SELECT_ACTIVATE": "Please select driver to activate.",
        "ALERT_SELECT_DEACTIVATE": "Please select driver to deactivate.",
        "ALERT_SELECT_DELETE": "Please select driver to delete.",
        "CONFIRM_DELETE": "Do you want to delete this driver?",
        "CONFIRM_DEACTIVATE_MULTI": "Are you sure you want to deactivate the selected driver(s)? Their connectedhardware meter(s) will be also disabled. Only driver(s) who are having no current trip or reservation can be deactivated.",
        "CONFIRM_ACTIVATE_MULTI": "Do you want to activate these drivers? Only driver(s) who are completely setup for operation can be activated.",
        "CONFIRM_DELETE_MULTI": "Do you want to delete these drivers? Just only driver in the inactive status can be deleted.",
        "CONFIRM_REMOVE_BANKING_INFOR": "Are you sure you want to remove banking information?",
        "REMOVE_BANKING_INFOR": "Remove banking information",
        "ADD_DRIVER": "ADD DRIVER",
        "Avatar": "Avatar",
        "Individual_Driver": "Transport - Rented Vehicle",
        "Owner_Operator": "Transport - Owner Driver",
        "deliveryCompany": "Delivery - Rented Vehicle",
        "deliveryIndividual": "Delivery - Owner Driver",
        "CONTACT_INFO": "CONTACT INFO",
        "Home_address": "Home address",
        "Username": "Username",
        "First_name": "First name",
        "Last_name": "Last name",
        "Phone_number": "Phone number",
        "Email_address": "Email address",
        "Select_city": "Select city",
        "State": "State",
        "Zip_code": "Zip code",
        "Date_of_birth": "Date of birth",
        "placeholderDate": "dd/mm/yyyy",
        "City_driver_want_to_drive": "City driver want to drive",
        "Driver_license_number": "Driver license number",
        "Driver_license_state": "Driver license state/Province",
        "Driver_license_expiry": "Driver road tax expiry",
        "FOR_INSPECTOR": "FOR INSPECTOR",
        "Document": "Document",
        "Document_Id": "Document ID",
        "Link": "Link",
        "Notes": "Notes",
        "UPLOAD_DOCUMENT": "UPLOAD DOCUMENT",
        "Click_here_to_download": "Click to download",
        "Click_here_to_view": "Click to view",
        "EXTENSION_REQUIREMENT": "The file extension must be .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip and less than 5Mb.",
        "Invalid_file": "File content is invalid",
        "File_size_larger_than_5Mb": "File size must be larger than 5Mb",
        "BANK_ACCOUNT": "BANK ACCOUNT",
        "Name_of_bank": "Name of bank",
        "Name_of_account": "Account holder's name",
        "First_M_Last": "First M. Last",
        "RTBnumber": "Routing/Transit/Branch number",
        "bankName": "Bank name",
        "Select_a_bank": "Select a bank",
        "Account_number": "Account number",
        "IBAN_number": "IBAN number",
        "Check_number": "Check number",
        "Swift_code_number": "Swift code number",
        "SSNNI": "SSN/ NI",
        "sortCode": "Sort code",
        "Verification_document": "Verification document",
        "Additional_document": "Additional document",
        "Verification_document_back": "Verification document (back)",
        "Additional_document_back": "Additional document (back)",
        "others": "Others",
        "signedW9Form": "Signed W-9 Form",
        "driverLicense": "Driver license",
        "Verification_document_tooltip": "Please upload the scanned copy of one of the below documents: \n  - Passport \n  - Driver license \n  - Identity card",
        "Additional_document_tootip": "Please upload the scanned copy of one of the below documents: \n  - Electricity or gas bill \n  - Tax statement or certificate for non taxation \n  - Certificate for housing tax \n  - Housing insurance certificate or invoice \n  - Receipt of rent (from a social agency or a real estate agen \n  - or title deed \n  - Telephone bill \n  - Water bill \n  - CAF statement mentioning housing-related aid",
        "isBankAccountOwner": "Is this your account?",
        "yes": "Yes",
        "no": "No",
        "beneficiary_name": "Beneficiary Name",
        "beneficiary_idic": "Beneficiary ID/IC",
        "relationship": "Relationship",
        "select_relationship": "Select relationship status",
        "spouse": "Spouse",
        "daughterOrSon": "Daughter/Son",
        "brotherOrSister": "Brother/Sister",
        "relative": "Relative",
        "friends": "Friends",
        "other": "Other",
        "relationshipOtherName": "Relationship with other?",
        "OPERATION": "OPERATION",
        "CAR_INSURANCE": "CAR INSURANCE",
        "Policy_number": "Policy number",
        "Effective_date": "Effective date",
        "Expired_date": "Expired date",
        "Select_company": "Select company",
        "All_company": "All company",
        "All_Car_Type": "All car type",
        "Car": "Car",
        "Existing_car": "Existing car",
        "New_car": "New car",
        "License_plate": "Car Registration Number",
        "Select_license_plate": "Select car registration number",
        "Select_Car_Type": "Select Car Type",
        "vehicleOwner": "Name of vehicle’s owner",
        "Make": "Make",
        "Select_Car_Make": "Select Car Make",
        "Model": "Model",
        "Select_Car_Model": "Select Car Model",
        "Year": "Year",
        "Select_Year": "Select Year",
        "Color": "Color",
        "Passengers": "Passengers",
        "Luggage": "Luggage",
        "License_expiry": "Road tax expiry",
        "Shift": "Shift",
        "Select_Shift_Template": "Select Shift Template",
        "Fleet_commission": "Fleet commission (report)",
        "Company_commission": "Company commission (report)",
        "rideHailing": "Street Hailing",
        "intercity": "Intercity",
        "food": "Food",
        "mart": "Mart",
        "streetSharing": "Street Sharing",
        "Shuttle": "Shuttle",
        "parcel": "Parcel",
        "driverInsurance": "Driver Insurance",
        "Percentage": "Percentage",
        "Amount": "Amount",
        "transaction": "Transaction",
        "Terminal_ID": "Terminal ID",
        "Auth_key": "Auth key",
        "CREDIT_DEPOSIT": "Credit wallet",
        "Credit_Balance": "Credit Balance",
        "Balance": "Balance",
        "Change_balance": "Change balance",
        "Select_balance": "Select balance",
        "Adjust_amount": "Adjust amount",
        "Adjust_value": "Adjust value",
        "New_balance": "New balance",
        "New_point": "New point",
        "Enter_reason": "Enter reason",
        "ERROR_INPUT_BALANCE": "Please select balance.",
        "ERROR_INPUT_AMOUNT": "Please input this value.",
        "ERROR_INPUT_REASON": "Please enter reason.",
        "ERROR_INPUT_VALID_AMOUNT": "Please enter a value greater than or equal to 0.",
        "INFO_ADD_BALANCE_SUCCESS": "Driver balance has been updated successfully",
        "INFO_ADD_CASH_BALANCE_SUCCESS": "Driver cash balance has been updated successfully",
        "Force_meter": "Force meter",
        "Force_meter_tooltip": "Driver app must be connected to the meter before operating. Applied for Pulsar & Centrodyne's meters",
        "ERROR_EXISTED_PHONE": "This phone has been existing on the system. Please input another one",
        "TOP_driver": "TOP driver",
        "Ride_sharing": "Ride-sharing",
        "Note_password": "Note: For new driver account, the default password is \"12345678\"",
        "Required_fields": "Required fields",
        "Cancel": "Cancel",
        "Save": "Save",
        "SaveAndInProgress": "Save & In progress",
        "SaveAndDeactivate": "Save & Deactivate",
        "ERROR_REQUIRE_": "Please input ",
        "ERROR_INPUT_AVATAR": "Please upload avatar.",
        "ERROR_INPUT_DRIVER_TYPE": "Please select driver type.",
        "ERROR_INPUT_DOCUMENT": "Please upload",
        "ERROR_INPUT_USERNAME": "Please input username.",
        "ERROR_INPUT_VALID_USERNAME": "Username may only contain lower letters & numbers and must be between 3 and 20 characters long",
        "ERROR_INPUT_PHONE": "Please specify a valid phone number.",
        "ERROR_INPUT_VALID_EMAIL": " Please enter valid email.",
        "ERROR_INPUT_EMAIL": "Please input email.",
        "ERROR_INPUT_FIRSTNAME": "Please input first name.",
        "ERROR_INPUT_LASTNAME": "Please input last name.",
        "ERROR_INPUT_ADDRESS": "Please input home address.",
        "ERROR_INPUT_CITY": "Please input city.",
        "ERROR_INPUT_STATE": "Please input state.",
        "ERROR_INPUT_ZIPCODE": "Please input zipcode.",
        "ERROR_INPUT_BIRTHDAY": "Please input birthday.",
        "ERROR_INPUT_NAME_OF_BANK": "Please input name of bank.",
        "ERROR_INPUT_NAME_OF_ACCOUNT": "Please input account holder's name.",
        "ERROR_INPUT_ROUTING_NUMBER": "Please input routing number.",
        "ERROR_INPUT_BANK_NAME": "Please select a bank.",
        "ERROR_INPUT_ACCOUNT_NUMBER": "Please input an account number",
        "ERROR_INPUT_CHECK_NUMBER": "Please input check number.",
        "ERROR_INPUT_SSN": "Please input SSN.",
        "ERROR_INPUT_DocumentId": "Please input Document ID.",
        "ERROR_INPUT_SORTCODE": "Please input sort code.",
        "ERROR_INPUT_COMPANY": "Please select company to activate.",
        "ERROR_INPUT_LICENSE_PLATE": "Please choose car registration number.",
        "ERROR_INPUT_ZONE": "Please select zone to activate.",
        "ERROR_INPUT_SHIFT": "Please select shift to activate.",
        "ERROR_ACCOUNT_NUMBER_ONLY_NUMBERS": "This must contain only numbers",
        "ERROR_SSN_ONLY_NUMBERS": "This must contain only numbers",
        "ERROR_SSN_LENGTH": "SSN must be at least 4 characters",
        "ERROR_COMMISSION_NUMBERS": "Please enter a valid number",
        "INFO_DEACTIVAED_SUCCESS": "The driver has been deactivated successfully.",
        "INFO_ACTIVAED_SUCCESS": "The driver has been activated successfully.",
        "INFO_DEACTIVAED_SUCCESS_BUT_HARDWARE": "The driver has been deactivated but the integrated hardware meter is cannot be turned off for some unexpected reasons.",
        "INFO_MULTIDEACTIVAED_SUCCESS": "The {0} selected drivers have been successfully deactivated.",
        "INFO_MULTIDEACTIVAED_HARDWARE_SUCCESS": "The {0} selected drivers have been successfully deactivated. {1} connected hardware meter(s) were disabled.",
        "INFO_MULTIDEACTIVAED_SUCCESS_BUT_HARDWARE": "The {0} selected drivers have been successfully deactivated. {1} connected hardware meter(s) were disabled. {2} were not due to some unexpected reasons.",
        "INFO_MULTIACTIVAED_SUCCESS": "The {0} selected drivers have been successfully activated.",
        "DRIVER_UPDATE_SUCCESS": "Driver has been updated successfully.",
        "DRIVER_CREATE_SUCCESS": "Driver info has been successfully added",
        "Expiry_Date": "Expiry Date",
        "Case_number": "Case number",
        "Export_to_csv": "Export to CSV",
        "CONFIRM_EXPORT_TO_CSV": "Are you sure you want to export all data under .csv format?",
        "Ride_sharing_tooltip": "Ride-sharing is an advanced feature, please contact our support Team to get more info",
        "In_Review": "In Review",
        "Reset_password_success": "Reset password successfully",
        "Topup_note": "(It is allowed to top up your driver's balance after activating the driver)",
        "cash_balance": "Cash balance",
        "cash_wallet": "Cash wallet",
        "total_balance": "Total balance",
        "total_cash_balance": "Total cash balance",
        "total_credit_balance": "Total credit balance",
        "banking_status": "Banking status",
        "bankingStatus": {
            "verified": "Verified",
            "not_verified": "Not verified"
        },
        "INVALID_FILE_SIZE": "The file size must be less than 8Mb.",
        "totalDrivers": "Total Driver",
        "profileStatus": "Profile status",
        "profile_completed": "Profile completed",
        "profile_incomplete": "Profile incomplete",
        "profile_inProgress": "Profile in progress",
        "in_review_completed": "In review (profile completed)",
        "in_review_incomplete": "In review (profile incomplete)",
        "in_review_inProgress": "In review (profile in progress)",
        "CASH_WALLET_DEPOSIT": "Cash wallet",
        "payoutSelect": "Payout (an inbox and email will be sent to the driver after payout)",
        "Confirm_update_cash_balance": "Change Cash Balance",
        "Confirm_update_cash_balance_msg": "Are you sure you want to change driver balance?",
        "cashBalance": "Cash balance",
        "cashBalance_unavailable": "0.00",
        "cashBalance_active": "Active",
        "cashBalance_negative": "Negative",
        "creditBalance": "Credit balance",
        "ERROR_INPUT_DOCUMETID": "Please input Document Id.",
        "ERROR_INPUT_EXPIRYDATE": "Please input Expiry Date.",
        "driverType": "Driver Type",
        "tooltip_in_review_completed": "Send inbox to 'In review' drivers who complete their profile.",
        "tooltip_in_review_incomplete": "Send inbox to 'In review' drivers who haven't completed their profile.",
        "tooltip_in_review_inProgress": "Send inbox to 'In review' drivers who complete their profile but need extra verification.",
        "tooltip_active": "Send inbox to 'Active' drivers.",
        "tooltip_inactive": "Send inbox to drivers who have account reactivated.",
        "active": "Activate",
        "inactive": "Deactivate",
        "inReviewInProgress": "In Review",
        "Market_place": "Marketplace",
        "Market_place_tooltip": "Empower drivers to choose on-demand or reservations bookings to accept",
        "selectDriverType": "Select driver type",
        "PlsSelectDriverType": "Please select driver type",
        "emergencyContacts": "Emergency Contacts",
        "switchVehicle": "Switch to any vehicle",
        "switchVehicleNote": "Allow driver to switch to any vehicle of their assigned supplier company.",
        "vehicleModel": "Model",
        "licensePlateNumber": "Vehicle Plate",
        "addVehicle": "Add Vehicle",
        "newVehicle": "New Vehicle",
        "linkVehicle": "Link Vehicle",
        "modal": "Modal",
        "selectVehicle": "Select Vehicle",
        "selectVehicleNote": "Enable driver to select or change vehicle for a booking.",
        "Warning_change_company": "Warning: Reassigning this driver to another company will unlink all associated vehicles."
    },
    "corporate": {
        "User_Name": "User Name",
        "Edit_Balance": "Edit Balance",
        "Corporates": "Corporates",
        "Supplier": "Supplier",
        "CarTypes": "Car Types",
        "Add": "Add",
        "Balance": "Balance",
        "Activate": "Activate",
        "Deactivate": "Deactivate",
        "Delete": "Delete",
        "Company_Name": "Company Name",
        "Admin_Name": "Admin Name",
        "Username": "Username",
        "Phone_Number": "Phone Number",
        "importBooking": "Able to import bookings",
        "Email": "Email",
        "Registered_From": "Registered From",
        "Registration_Date": "Registration Date",
        "Status": "Status",
        "Actions": "Actions",
        "Action": "Action",
        "Inactive": "Inactive",
        "Active": "Active",
        "In_Review": "In Review",
        "Edit": "Edit",
        "View": "View",
        "ADD_CORPORATE_CARD": "Add corporate card",
        "selectCountry": "Select country",
        "CONFIRM_DELETE_CARD": "Are you sure you want to delete this cards?",
        "ALERT_SELECT_ACTIVATE": "Please select corporate to activate.",
        "ALERT_SELECT_DEACTIVATE": "Please select corporate to deactivate.",
        "ALERT_SELECT_DELETE": "Please select corporate to delete.",
        "CONFIRM_DELETE": "Do you want to delete this corporate?",
        "CONFIRM_DELETE_USER": "Do you want to delete this corp user?",
        "CONFIRM_DEACTIVATE_MULTI": "Do you want to deactivate these corporates?",
        "CONFIRM_ACTIVATE_MULTI": "Do you want to activate these corporates?",
        "CONFIRM_DELETE_MULTI": "Do you want to delete these corporates?\n ** Only corporates who are inactive can be deleted.",
        "ERROR_INPUT_PHONE": "Please specify a valid phone number.",
        "ERROR_INPUT_VALIDPHONE": "Please enter a valid phone number.",
        "ERROR_EXISTED_PHONE": "This phone has been existing on the system. Please input another one",
        "Account": "Account",
        "Corp_traveler": "Corp Users",
        "Payment_methods": "Payment methods",
        "Corp_operators": "Corp operators",
        "Pricing": "Pricing",
        "COMPANY_INFORMATION": "Company information",
        "Company_name": "Company name",
        "Company_ID": "Company ID (for Corp Users signup)",
        "Company_address": "Company address",
        "Invoice_email": "Invoice email",
        "Confirmation_email": "Confirmation email",
        "colorCodeBooking": "Color code",
        "ADMIN_ACCOUNT": "Admin account",
        "First_name": "First name",
        "Last_name": "Last name",
        "Commission": "Commission",
        "commissionBooking": "Apply commission for bookings from app/pwa",
        "Required_fields": "Required fields",
        "Save": "Save",
        "Cancel": "Cancel",
        "Reset_password": "Reset password",
        "Employee_Name": "Name",
        "Created_Date": "\tCreated Date",
        "Add_corporate_card": "Add corporate card",
        "Corp_Users": "Corp Users",
        "Pricing_Default": "Default (same pricing plan as normal pax)",
        "Pricing_Discount": "Discount by percentage (applied for Corp Users only)",
        "Markup_By_Percent": "Mark-up by percentage (applied for Corp Users only)",
        "Pricing_Add_different_rates": "Add different rates",
        "ERROR_INPUT_COMPANYNAME": "Please input company name.",
        "ERROR_INPUT_USERNAME": "Please input username.",
        "ERROR_INPUT_FIRSTNAME": "Please input first name.",
        "ERROR_INPUT_EMAIL": "Please input email.",
        "ERROR_INPUT_VALID_EMAIL": "Please enter a valid email address.",
        "ERROR_INPUT_VALID_NUMBER": "Please enter valid number",
        "ERROR_INPUT_VALID_RANGE_AMOUNT": "Please enter a value between {0} and {1}.",
        "INFO_ADD_CORPORATE_SUCCESS": "Account {0} has been created successfully. Please check the email {1} for login credentials details.",
        "INFO_UPDATE_CORPORATE_SUCCESS": "The information has been updated successfully.",
        "INFO_UPDATE_PRICING_SUCCESS": "Pricing updated successfully",
        "INFO_RESET_PASSWORD_SUCCESS": "Account {0} has been reset successfully. Please check the email {1} for login credentials details.",
        "COMFIRM_RESET_PASSWORD": "Do you want to reset password?",
        "Traveler_signature": "Traveler signature",
        "Parcel_Delivery": "Enable parcel delivery",
        "Tracking_log": "Tracking log",
        "Rating": "Rating",
        "Email_address": "Email address",
        "Assistant_email": "Assistant email",
        "Corp_PO": "Corp PO",
        "Cost_centre": "Cost centre",
        "Corp_division": "Corp division",
        "Corp_id": "Corp id",
        "Department": "Department",
        "Manager_email": "Manager's email",
        "Manager_name": "Manager's name",
        "Corporate_card": "Corporate card",
        "Direct_invoicing": "Direct invoicing",
        "Corporate_Prepaid": "Corporate Prepaid",
        "Cash": "Cash",
        "Car_Type_ID": "Car Type ID",
        "App_Display_Name": "App Display Name",
        "Normal_Fare": "Regular",
        "Flat_Fare": "Flat Rate",
        "Hourly_Daily_Rate": "Hourly/ Daily",
        "Delivery_Rate": "Delivery",
        "Assign_rate": "Assign rate",
        "Zone": "Zone",
        "Rate": "Rate",
        "Zone_Rate": "Zone - Rate",
        "Please_select_rate": "Please select rate",
        "Paid_by": "Paid by",
        "Enter_new_credit_card": " Enter new credit card ",
        "TOP_UP": "TOP UP",
        "Top_up": "Top up",
        "Topup_amount": "Topup amount",
        "Currency": "Currency",
        "ERROR_INPUT_AMOUNT": "Please input valid amount.",
        "VAT_number": "VAT number",
        "Gender": "Gender",
        "Select_Gender": "Select",
        "Male": "Male",
        "Female": "Female",
        "Date_of_birth": "Date of birth",
        "Street_address": "Street address",
        "City": "City",
        "State": "State",
        "Zip_code": "Zip code",
        "Country": "Country",
        "adding_new_card": "You are adding a new card to use service in %{zoneName} area",
        "No_Support": "New card adding is currently not supported in this area",
        "Job_title": "Job title",
        "required_phone_number_payment": "The phone number of corporate are required. Please update profile to continue.",
        "Yes": "Yes",
        "No": "No",
        "AddCardBeforeTopup": "No credit card was found. Please add a new card to continue.",
        "flightNumber": "Flight number",
        "Company_Type": "Company type",
        "Standard": "Standard corporate",
        "Airline": "Airline",
        "Branding": "Branding",
        "bgImage": "Background image (1600x900)",
        "logoImage": "Logo image",
        "aliasTitle": "Alias (URL) parameter",
        "importChauffeurBooking": "May kakayahang mag-import ng mga booking sa transportasyon",
        "isCorporateVip": "Corporate VIP",
        "isCorporateVipToolTip": "Enable this setting to automatically assign VIP status to all corporate travelers upon creation, you may turn it off for each individual user.",
        "qrTitle": "QR Web booking link",
        "qrNote": "Your personalized partner booking link is here! Display it prominently in bars, hotel receptions, guest rooms, and on your website—anywhere your customers might need a ride or delivery. Consider designing standees with your unique QR code, allowing customers a quick and easy scan to book their journey!"
    },
    "airline": {
        "Airlines": "Arilines",
        "Add": "Add",
        "Activate": "Activate",
        "Deactivate": "Deactivate",
        "Delete": "Delete",
        "Company_Name": "Company Name",
        "Admin_Name": "Admin Name",
        "Username": "Username",
        "Phone_Number": "Phone Number",
        "Email": "Email",
        "Registered_From": "Registered From",
        "Registration_Date": "Registration Date",
        "Status": "Status",
        "Actions": "Actions",
        "Action": "Action",
        "Inactive": "Inactive",
        "Active": "Active",
        "In_Review": "In Review",
        "Edit": "Edit",
        "View": "View",
        "ADD_CORPORATE_CARD": "Add Airline card",
        "selectCountry": "Select country",
        "CONFIRM_DELETE_CARD": "Are you sure you want to delete this cards?",
        "ALERT_SELECT_ACTIVATE": "Please select Airline to activate.",
        "ALERT_SELECT_DEACTIVATE": "Please select Airline to deactivate.",
        "ALERT_SELECT_DELETE": "Please select Airline to delete.",
        "CONFIRM_DELETE": "Do you want to delete this Airline?",
        "CONFIRM_DELETE_USER": "Do you want to delete this Airline user?",
        "CONFIRM_DEACTIVATE_MULTI": "Are you sure want to deactivate these company?",
        "CONFIRM_ACTIVATE_MULTI": "Are you sure you want to active this company?",
        "CONFIRM_DELETE_MULTI": "Are you sure you want to delete this company?\n ** Only company who are inactive can be deleted.",
        "ERROR_INPUT_PHONE": "Please specify a valid phone number.",
        "ERROR_INPUT_VALIDPHONE": "Please enter a valid phone number.",
        "ERROR_EXISTED_PHONE": "This phone has been existing on the system. Please input another one",
        "Airline": "Airline",
        "Crew": "Crew",
        "Payment_methods": "Payment methods",
        "Airline_operators": "Airline operators",
        "Pricing": "Pricing",
        "COMPANY_INFORMATION": "Company information",
        "Company_name": "Company name",
        "Company_ID": "Company ID",
        "Company_address": "Company address",
        "Invoice_email": "Invoice email",
        "Confirmation_email": "Confirmation email",
        "colorCodeBooking": "Color code",
        "ADMIN_ACCOUNT": "Admin account",
        "First_name": "First name",
        "Last_name": "Last name",
        "Commission": "Commission",
        "Required_fields": "Required fields",
        "Required_fields_crews": "Note: For new Crew account, the default password is \"12345678\"",
        "Save": "Save",
        "Cancel": "Cancel",
        "Reset_password": "Reset password",
        "Crew_Name": "Crew",
        "Created_Date": "\tCreated Date",
        "Add_corporate_card": "Add airline card",
        "Corp_Users": "Operators",
        "Pricing_Default": "Default",
        "Pricing_Discount": "Discount by percentage",
        "Markup_By_Percent": "Mark-up by percentage",
        "Pricing_Add_different_rates": "Add different rates",
        "ERROR_INPUT_COMPANYNAME": "Please input company name.",
        "ERROR_INPUT_USERNAME": "Please input username.",
        "ERROR_INPUT_FIRSTNAME": "Please input first name.",
        "ERROR_INPUT_EMAIL": "Please input email.",
        "ERROR_INPUT_VALID_EMAIL": "Please enter a valid email address.",
        "ERROR_INPUT_VALID_NUMBER": "Please enter valid number",
        "ERROR_INPUT_VALID_RANGE_AMOUNT": "Please enter a value between {0} and {1}.",
        "INFO_ADD_CORPORATE_SUCCESS": "Account {0} has been created successfully.",
        "INFO_UPDATE_CORPORATE_SUCCESS": "The information has been updated successfully.",
        "INFO_UPDATE_PRICING_SUCCESS": "Pricing updated successfully",
        "INFO_RESET_PASSWORD_SUCCESS": "Account {0} has been reset successfully.",
        "COMFIRM_RESET_PASSWORD": "Do you want to reset password?",
        "Traveler_signature": "Traveler signature",
        "Tracking_log": "Tracking log",
        "Rating": "Rating",
        "Email_address": "Email address",
        "Assistant_email": "Assistant email",
        "Corp_PO": "Corp PO",
        "Cost_centre": "Cost centre",
        "Corp_division": "Corp division",
        "Corp_id": "Corp id",
        "Department": "Department",
        "Manager_email": "Manager's email",
        "Manager_name": "Manager's name",
        "Corporate_card": "Airline card",
        "Direct_invoicing": "Direct invoicing",
        "Corporate_Prepaid": "Airline Prepaid",
        "Cash": "Cash",
        "Car_Type_ID": "Car Type ID",
        "App_Display_Name": "App Display Name",
        "Normal_Fare": "Regular",
        "Flat_Fare": "Flat Rate",
        "Hourly_Daily_Rate": "Hourly/ Daily",
        "Delivery_Rate": "Delivery",
        "Assign_rate": "Assign rate",
        "Zone": "Zone",
        "Rate": "Rate",
        "Zone_Rate": "Zone - Rate",
        "Please_select_rate": "Please select rate",
        "Paid_by": "Paid by",
        "Enter_new_credit_card": " Enter new credit card ",
        "TOP_UP": "TOP UP",
        "Top_up": "Top up",
        "Topup_amount": "Topup amount",
        "Currency": "Currency",
        "ERROR_INPUT_AMOUNT": "Please input valid amount.",
        "VAT_number": "VAT number",
        "Gender": "Gender",
        "Select_Gender": "Select",
        "Male": "Male",
        "Female": "Female",
        "Date_of_birth": "Date of birth",
        "Street_address": "Street address",
        "City": "City",
        "State": "State",
        "Zip_code": "Zip code",
        "Country": "Country",
        "adding_new_card": "You are adding a new card to use service in %{zoneName} area",
        "No_Support": "New card adding is currently not supported in this area",
        "Job_title": "Job title",
        "required_phone_number_payment": "The phone number of corporate are required. Please update profile to continue.",
        "Yes": "Yes",
        "No": "No",
        "AddCardBeforeTopup": "No credit card was found. Please add a new card to continue."
    },
    "message": {
        "Add": "Add",
        "Delete": "Delete",
        "Items_per_page": "Items per page",
        "Page": "Page",
        "Search_here": "Search here",
        "Shown_Active": "{0} - {1} of {2} shown",
        "Date": "Date",
        "Subject": "Subject",
        "To": "To",
        "Type": "Type",
        "Result": "Result",
        "Actions": "Actions",
        "New_message": "New message",
        "MESSAGE_DETAILS": "Message details",
        "All": "All",
        "Company": "Company",
        "Active": "Active",
        "In_Active": "Inactive",
        "In_Review": "In Review",
        "Send_to": "Send to",
        "All_drivers": "All drivers",
        "All_customers": "All customers",
        "All_merchants": "All merchants",
        "Customized_driver": "Customized driver",
        "Customized_customer": "Customized customer",
        "Customized_merchant": "Customized merchant",
        "Receiver_list": "Receiver list",
        "Content": "Content",
        "Required_fields": "Required fields",
        "Search_driver_name_driver_phone_number": "Search driver name, driver phone number",
        "Search_category_name": "Search category name",
        "Search_customer_name_customer_phone_number": "Search customer name, customer phone number",
        "Search_merchant_name_merchant_phone_number": "Search merchant name, merchant phone number",
        "Search_car_type": "Search car type",
        "Searching": "Searching...",
        "No_results": "No results",
        "Send": "Send",
        "Cancel": "Cancel",
        "SMS_failed_Inbox_succeeded": "SMS failed, Inbox succeeded",
        "Failed": "Failed",
        "Succeeded": "Succeeded",
        "CONFIRM_DELETE": "Do you want to delete this message?",
        "CONFIRM_DELETE_MULTI": "Do you want to delete these messages?",
        "ALERT_SELECT_DELETE": "Please select message to delete.",
        "ERROR_INPUT_RECEIVERLIST": "Please choose at least one driver to send message",
        "ERROR_INPUT_CUSTOMER_RECEIVERLIST": "Please choose at least one customer to send message",
        "ERROR_INPUT_SUBJECT": "Please input subject",
        "ERROR_INPUT_CONTENT": "Please input content",
        "ERROR_INPUT_LEAST_ONE_SUBJECT_CONTENT": "Please set up at least one language to continue",
        "ERROR_INPUT_SUBJECT_MULTI_LANGUAGE": "Please input subject for {0} language",
        "ERROR_INPUT_CONTENT_MULTI_LANGUAGE": "Please input content for {0} language",
        "ERROR_INPUT_URL_MULTI_LANGUAGE": "Please input URL for {0} language",
        "ERROR_INPUT_TITLE_MULTI_LANGUAGE": "Please input title for {0} language",
        "ERROR_INPUT_BODY_MULTI_LANGUAGE": "Please input body for {0} language",
        "import_fail_success_driver": "%{success} driver(s) has been imported successfully, %{fail} failed.",
        "import_fail_success_passenger": "%{success} customer(s) has been imported successfully, %{fail} failed.",
        "import_fail_success_merchant": "%{success} merchant(s) has been imported successfully, %{fail} failed.",
        "CONFIRM_SEND_SUCCESS": "Your message has been sent. This may take a few minutes. Please wait while the system is processing your request.",
        "Load_message_and_sms_fail": "The errors occurred while loading message and SMS details",
        "Update_message_and_sms_fail": "The errors occurred while updating message and SMS details",
        "Update_message_and_sms_success": "Message and SMS details has been updated successfully",
        "Driver_List": "Driver List",
        "Car_Type_List": "Car type list",
        "Search_vehicle_type_name": "Search Vehicle Type Name",
        "ALERT_PROCESSING_DELETE": "Your request is being processed. This could take several seconds",
        "Customer": "Customer",
        "Driver": "Driver",
        "StatusOfCustomer": "Status of customer",
        "StatusOfDriver": "Status of driver",
        "StatusOfMerchant": "Status of merchant",
        "SendTo": "Send to",
        "DownloadReceiverList": "Download receiver list",
        "warningInputChangeOver": "There is a relative change of more than 20% between the 2 recent inputs. Please consider!",
        "NoteDownloadReveverList": "After 1 month, downloading receivers list will be disabled.",
        "Load_voip_fail": "The errors occurred while loading VOIP details.",
        "Update_voip_success": "VOIP details has been updated successfully.",
        "Update_voip_fail": "The errors occurred while updating VOIP details.",
        "expiryDate": "Send to new user until",
        "expiryDate_placeholder": "Expiry Date (mm/dd/yy)",
        "NA": "N/A",
        "PleaseSelectStatusOfDriver": "Please select status of driver",
        "versionApp": "App Version",
        "platform": "Platform",
        "contentType": "Content type",
        "url": "URL",
        "title": "Title",
        "contentId": "ContentId",
        "body": "Body",
        "image_notification": "Image",
        "platformItem": {
            "all": "All",
            "iOS": "iOS",
            "android": "Android"
        },
        "comparisonVersionApp": {
            "is": "Is",
            "is_not": "Is not",
            "is_one_of": "Is one of",
            "is_not_one_of": "Is not one of",
            "greater_or_equal": "Greater or equal",
            "less_or_equal": "Less or equal"
        },
        "contentTypeItem": {
            "html": "HTML",
            "link": "Link"
        },
        "Please_input": "This field is required"
    },
    "fleetInfo": {
        "Fleet_name": "Fleet name",
        "Phone_number": "Phone number",
        "Email_address": "Email address",
        "Country": "Country",
        "Time_zone": "Time zone",
        "Address": "Address",
        "Website": "Website",
        "Currency": "Currency",
        "Distance_unit": "Distance unit",
        "Load_fleet_info_fail": "The errors occurred while loading fleet info"
    },
    "generalSetting": {
        "alertNotifications": {
            "title": "Alert notifications",
            "emails": "Emails",
            "emailsPlaceholder": "Enter email",
            "slack": "Slack Channel (Instructions)",
            "slackPlaceholder": "Slack POST URL",
            "discord": "Discord Channel (Instructions)",
            "discordPlaceholder": "Enter Discord Channel",
            "telegram": "Telegram Channel (Instructions)",
            "telegramPlaceholder": "Enter Telegram Channel"
        },
        "showFare": {
            "title": "Show fare to driver",
            "totalFare": "Total fare",
            "estimateGross": "Estimate gross earning"
        },
        "carHailing": "Street hailing",
        "regular": "Regular",
        "cancelTimeout": "Cancel the timeout bookings automatically",
        "allowStripePaymentLink": "Allow generate Stripe payment link",
        "flat": "Flat",
        "techFee": "Tech fee per transaction",
        "customizeLogo": "Customize dashboard logo",
        "logo": "Logo",
        "customerApp": "Customer App",
        "hourly": "Hourly",
        "shuttle": "Shuttle",
        "parcel": "Parcel",
        "freeCancelTime": "Free cancellation times per day",
        "AM": "AM",
        "PM": "PM",
        "RUSH_HOUR": "Surcharge",
        "Name": "Name",
        "Date": "Date",
        "Mon": "Mon",
        "Tue": "Tue",
        "Wed": "Wed",
        "Thu": "Thu",
        "Fri": "Fri",
        "Sat": "Sat",
        "Sun": "Sun",
        "Time_range": "Time range",
        "From": "From",
        "To": "To",
        "Rush_hour_time_calculation": "Surcharge time calculation",
        "Rush_hour_surcharge": "Surcharge",
        "Required_fields": "Required fields",
        "Save": "Save",
        "Cancel": "Cancel",
        "MDISPATCHER_CANCELLATION": "Pax / Partner / Web booking cancellation",
        "Book_now": "Book now",
        "MDISPATCHER_CANCELLATION_TOOLTIP": "Pax / Partner / Web booking cancellation",
        "Book_now_toolTip": "Cancelation fee is applicable if on-demand bookings get canceled within",
        "Book_later_toolTip": "Cancelation fee is applicable if a reservation gets canceled within before pick-up time",
        "Drv_Book_now_toolTip": "Cancellation fee is applicable if on-demand bookings get canceled within set time, from accept time",
        "Drv_Book_later_toolTip": "Cancellation fee is applicable if in advance bookings get canceled within set time, before expected pick-up time",
        "Free_cancel": "Free cancel duration",
        "Hide_cancel_btn": "Hide cancel button after set times",
        "Driver_cancellation": "Driver cancellation",
        "Time_day": "Times per day",
        "Duration": "Duration",
        "Times": "Times",
        "Book_later": "Book later",
        "Intercity": "Intercity",
        "Intercity_tooltip": "Cancellation fee is applicable if an intercity booking gets canceled within the set time, before pickup time.",
        "MDISPATCHER_NO_SHOW": "No show",
        "AIRPORT_FEE": "Airport fee",
        "From_airport": "From airport",
        "To_airport": "To airport",
        "MEET_DRIVER": "Meet driver",
        "On_curb": "On curb",
        "Meet_Greet": "Meet & Greet",
        "Add": "Add",
        "Edit": "Edit",
        "DELETE_SURCHARGE": "Delete surcharge",
        "DELETE_SERVICE": "Delete service",
        "CHANGE_APPLY_SERVICE_FEES": "Change apply service fees",
        "DELETE_CONFIRM": "Are you sure you want to delete this item?",
        "DELETE_SURCHARGE_CONFIRM": "Are you sure you want to delete the selected surcharge?",
        "DELETE_ADDITIONAL_SERVICES_CONFIRM": "Are you sure you want to delete the selected service?",
        "CHANGE_APPLY_SERVICE_FEES_CONFIRM_CUSTOM": "Are you sure you want to apply different fees for seperated zones?",
        "CHANGE_APPLY_SERVICE_FEES_CONFIRM_ALL": "Are you sure you want to apply the same fees for all zones?",
        "EDIT_SERVICE_FEES": "Edit service fees",
        "EDIT_SERVICE_FEES_CONFIRM": "Are you sure you want to leave without saving settings for %{currentZone}?",
        "HEAVY_TRAFFIC": "Heavy traffic",
        "Heavy_traffic_surcharge": "Heavy traffic surcharge",
        "TOLL_FEE": "Toll fee",
        "parkingFee": "Parking fee",
        "gasFee": "Gas fee",
        "OTHER_FEES": "Other fees",
        "Can_edit": "Able to edit",
        "Can_add_note": "Able to add note",
        "Other_fees_amount": "Other fees amount",
        "TAX": "TAX",
        "Tax_surcharge": "Tax surcharge",
        "TIP": "Tip",
        "Default_tip": "Default tip",
        "RESERVATION_REMINDER": "Reservation reminder",
        "Driver_app_SMS_alert_before": "Driver app - SMS & alert before",
        "Pax_app_alert_before": "Pax app - alert before",
        "Confirm_driver_info_via_email_before": "Send email / sms booking confirmation to customer before",
        "Confirm_driver_info_via_email_before_hint": "The system will send out a booking confirmation to the customer based on your set time, before the pickup time.",
        "Show_request_tab_on_driver_app": "Show 'Request' tab on driver app",
        "Show_preferred_tab_on_driver_app": "Show 'Preferred' tab on driver app",
        "Sound_notification": "Sound notification",
        "Advance_information": "Advance information corporate users",
        "Load_general_fail": "The errors occurred while loading general setting",
        "Update_general_success": "General setting has been updated successfully",
        "Update_general_fail": "The errors occurred while updating general setting",
        "Time_over_lap": "The times ranges must not overlap with each others",
        "Allow_passenger_to_register_as_corp_traveler": "Allow passenger to register as corp users",
        "Not_Allow_Make_Booking_Without_Assign_Fare": "Do not show car type on PWA and App if no fare assign (v4.6.60 and above)",
        "Not_Allow_show_car_type_on_cc": "Do not show car type on CC if no fare assign",
        "Disable_Direct_Invoicing_Receipt_Email": "Disable direct invoicing receipt email",
        "Additional_services": "Additional Services",
        "Edit_additional_services": "Edit Additional Services",
        "Service_Fee_By_Currencies": "Service fee",
        "Service_type": "Service Type",
        "Optional": "Optional",
        "Compulsory": "Compulsory",
        "Vehicle_type": "Vehicle Type",
        "Adding_additional_service_success": "New service has been created successfully",
        "Update_additional_service_success": "The service has been updated successfully",
        "Delete_additional_service_success": "The service has been deleted successfully",
        "Adding_additional_service_fail": "Failed to add Service ",
        "Update_additional_service_fail": "Failed to update service",
        "Other_settings": "Other settings",
        "Operator_assign_error": "There were errors when assigning operator",
        "Create_surcharge_success": "New surcharge has been created successfully",
        "Update_surcharge_success": "The surcharge has been updated successfully",
        "Delete_surcharge_success": "The surcharge has been deleted successfully",
        "Create_surcharge_fail": "Failed to reate surcharge ",
        "Update_surcharge_fail": "Failed to update surcharge ",
        "Delete_surcharge_fail": "Failed to delete surcharge",
        "Get_surcharge_detail_fail": "Failed to get surcharge detail ",
        "Repeat": "Repeat",
        "DayOfWeek": "Day of week",
        "SingleDay": "Single day",
        "Yearly": "Yearly",
        "Backup_phone_number": "Backup number",
        "Backup_phone_number_hint": "Direct to this number when driver and passenger are out of operation zones",
        "Apply_countdown_clock": "Apply countdown clock",
        "No_show_duration_tooltip": "Set up time to display No Show button after driver tap on Arrived button on Driver App",
        "Limit_radius": "Limit radius",
        "Limit_radius_tooltip": "Driver can report a booking as no-show within this radius",
        "Limit_radius_arrive_pickup": "Limit radius driver can arrive & pickup for transport booking",
        "Booking_validation": "Booking validations",
        "Invalid_period_of_time": "Invalid period of time",
        "hide_Provider_Filter_From_Corporate_Board": "Hide provider filter from corporate board",
        "customer_email_is_optional": "Customer's email is optional on passenger app",
        "disable_hourly_rate": "Disable hourly rate when both hourly and flat rate are available",
        "customer_gender_is_compulsory": "Customer's gender is compulsory on passenger app",
        "SHOW_MAP_STATUS": "Show map status",
        "Pay_to": "Pay to",
        "EMERGENCY_SOS": "Emergency S.O.S",
        "customer_address_is_compulsory": "Customer's address is compulsory on passenger app",
        "customer_id_is_compulsory": "Customer's ID# / National IC# is compulsory on passenger app",
        "allowPassengerToUploadDocument": "Allow passenger to upload ID# / National IC# document",
        "compulsoryNationalIcDocumentInPaxApp": "Customer's ID# / National IC# document is compulsory on Pax app",
        "doNotAllowAddDriverWhenNationalIdDuplicated": "Do not allow operator to add driver when national ID is duplicated",
        "forceCustomerToUseLiveLocation": "Force customer to use Live Location",
        "disableMarketPlace": "Disable Market Place for new drivers",
        "dontShowSecondStageImmediately": "Hide stage 2 signup",
        "compulsoryCarInsurance": "Driver's car insurance is compulsory",
        "marketPlace": "Market Place",
        "Enable": "Enable",
        "Disable": "Disable",
        "showTotalFareWithoutPromotionOnDriverApp": "Show total fare without promotion on Driver app",
        "showTotalFareWithoutPromotionOnDriverApp_hint": "- Transport: apply this setting before driver makes payment. <br/> - Delivery & Intercity: apply this setting before driver start job.",
        "hideFareOnPaxAppForAllCorporateBookings": "Hide fare on Pax app for all corporate bookings",
        "hidePaxNameAvatarOnDispatchScreen": "Hide customer's name and avatar on dispatching screen",
        "showFullCustomerNameOnDriverApp": "Show full customer name on Driver app",
        "hideDriverLogoutBtn": "Hide Driver logout button on Driver app",
        "showMeetAndGreetButtonForEveryBooking": "Show Meet & Greet button for every booking",
        "settings": "Settings",
        "Default_value": "Default value",
        "limitTopupAmount": {
            "valueByCurrencies": "Warning amount",
            "minimumByCurrencies": "Minimum top up amount",
            "maximumByCurrencies": "Maximum top up amount",
            "title": "Minimum and Maximum Top up amount"
        },
        "driverApp": "Driver App",
        "passengerApp": "Passenger App",
        "Default_Topup_Values": "Default Top up Values",
        "service_fees": "Service Fees",
        "commission_fleet": "Default Fleet Commission",
        "apply_all": "The same for all operation zones",
        "apply_custom_zone": "Differentiated between all operation zones",
        "apply": "Apply",
        "tollFeeDriverCanInput": "Limit amount driver can input",
        "unlimited": "Unlimited",
        "limited": "Limited",
        "sameFee": "Same fee",
        "customizedAirportfee": "Customized airport fee",
        "noteAirportFeeModal": "Note: Airport fee is only applied for airports that are on the list.",
        "intercity_schedule_trip_time_range": "To ensure the chronological order of booking time, please make sure the time-range of preferred/request trips is larger than the schedule trips",
        "airportFee": {
            "tooltipApply": "Default airport fee will be applied for each trip from / to airport by default. If airport fee is customized then new fee will be applied for that place.",
            "titleSearchAiportZone": "Please search your airport and add your fee",
            "airport": "Airport",
            "currency": "Currency",
            "fromAirportFee": "From airport fee",
            "toAirportFee": "To airport fee",
            "action": "Actions"
        },
        "Yes": "Yes",
        "No": "No",
        "tipPaymentMethod": "Payment method available for customer to tip his/her driver after the order",
        "tipDefaultValues": "Default values for tip after ride",
        "creadit": "Credit card (Personal card / Corporate card)",
        "wallet": "Wallet",
        "tnGeWallet": "TnG eWallet",
        "vippseWallet": "Vipps",
        "DurationRegularBooking": "Duration (for regular bookings)",
        "googlePay": "Google Pay",
        "applePay": "Apple Pay",
        "DurationRegularBookingToolTip": "\"No show\" button will be displayed in after the driver taps on \"Arrived\" button on Driver app.",
        "noShowCountDown": {
            "customizedNoShow": "Customization for particular locations (e.g airport...)",
            "customizedNoShowTitle": "Apply no-show count down duration for particular locations (e.g airport...)"
        },
        "maxWaitTime": "Max extra wait time",
        "maxWaitTimeToolTip": "This is the maximum extra waiting time that will be charged to the customer. You have the flexibility to customize the per-minute fee in your rate settings.",
        "maxWaitTimePlaceholder": "Maximum charge wait time",
        "HEAT_MAP": "Heat Map",
        "heatMap": {
            "defaultMode": "Default mode",
            "radius": "Radius",
            "intensity": "Intensity",
            "opacity": "Opacity",
            "requestedBookings": "Requested bookings",
            "activePassengers": "Active passengers",
            "completedBookings": "Completed bookings",
            "timeoutBookings": "Time out bookings",
            "tooltipRadius": "The radius of influence for each data point, in pixels.",
            "tooltipIntensity": "The maximum intensity of the heatmap.",
            "tooltipOpacity": "The opacity of the heatmap, expressed as a number between 0 and 1."
        },
        "schedule_trip": "Schedule trip",
        "driver_starts_trip_before_set_time": "Driver starts trip before set time",
        "request_trip": "Request trip",
        "preferred_trip": "Preferred trip",
        "time_range": "Time range for request and preferred trip",
        "working_time": "Working time",
        "hour": "hour",
        "hours": "hours",
        "minutes": "minutes",
        "indicate_pickup_time_in_advance_to_customer": "Indicate pickup time in advance to customer.",
        "customers_can_select_start_time_and_request_intercity_trip": "Customers can select start time and request intercity trip. Drivers also can create their preferred trip with their own schedule. Example: a time range of 1 hour will allow customers and drivers to create an intercity trip starts at 8:00 am - 9:00 am, 9:00 am - 10:00 am, or 10:00 am - 11:00 am.",
        "this_setting_limits_customers_to_create_request_trip_within_working_hours_only": "This setting limits customers to create request trip within working hours only.",
        "face_mask_verify": "Face mask verification",
        "faceMask_allZones": "All zones",
        "faceMask_customized": "Customized zone",
        "turn_on_verify": "Turn on verification",
        "force_to_wear_face_mask": "Force to wear face mask",
        "time_range_to_verify": "Do not verify again within",
        "customizedFaceMaskZone": "Customized face mask verification",
        "zoneOperation": "Operation Zone",
        "FaceMaskCustomized": "Customized",
        "time": "time",
        "day": "day",
        "days": "days",
        "allow_driver_to_skip_verification_after": "Allow driver to skip verification after",
        "driver_need_to_verify": "When driver need to verify",
        "open_app": "Open app",
        "start_reservation": "Start reservation",
        "choose_at_least_one": "You must select at least one action to continue.",
        "face_mask_customized_fail": "You must select at least one action to continue ({0}).",
        "vehicleAgeLimit": "Vehicle age limit",
        "vehicleAgeLimit_years": "years",
        "founderFund_firstBooking": "Founder's Fund: first booking only",
        "distance_diversity": "Distance diversity",
        "Add_Surcharge_Distance": "Add",
        "from": "From",
        "to": "To",
        "surchargeValue": "Surcharge",
        "actions": "Action",
        "please_enter_distance_range_valid": "Distance %{name} is invalid!",
        "please_enter_distance_to": "Please enter distance \"To\"",
        "please_enter_surchargeValue": "Please enter distance \"Surcharge\" for distance range %{name}",
        "please_enter_surchargeValue_is_number": "Please enter valid number \"Surcharge\" for distance range %{name}",
        "Update_service_fee_success": "Service fees has been updated successfully",
        "Update_service_fee_fail": "The errors occurred while updating service fees",
        "Update_fleet_commission_success": "Default fleet commision has been updated successfully",
        "Update_fleet_commission_fail": "The errors occurred while updating default fleet commission",
        "RESERVATION_POLICY": "Reservation policy",
        "default_expected_pickup_time": "Default expected pickup time",
        "default_expected_pickup_time_hint": "The default value you expect a driver to arrive from the pickup time.",
        "same_original_pickup_time": "Same as original pickup time",
        "quarter_before": "15 min before",
        "half_before": "30 min before",
        "quarter_after": "15 min after",
        "half_after": "30 min after",
        "custom": "Custom",
        "min_before": "min before",
        "min_after": "min after",
        "TransactionFee": "Transaction fee",
        "creditCardPercent": "Credit card/ prepaid",
        "directBilling": "Direct billing",
        "externalCard": "External Card"
    },
    "social": {
        "pax_app": "Passenger app",
        "driver_app": "Driver app",
        "default": "Default",
        "customized": "Customized",
        "html": "HTML",
        "about": "About",
        "email": "Email support",
        "enablePhone": "Phone number",
        "phone": "",
        "policy": "Link Privacy Policy",
        "termOfUse": "Link Terms of Use",
        "site": "Website",
        "logoDriverIOS": "Logo - IOS",
        "logoDriverAndroid": "Logo - Android",
        "htmlUrl": "HTML file",
        "Update_fail": "The errors occurred while updating social",
        "Update_success": "The social has been updated successfully"
    },
    "user": {
        "Show_per_page": "Items per page",
        "Page": "Page",
        "Search_here": "Search here",
        "show_pages": "{0} - {1} of {2} shown",
        "Username": "Username",
        "Name": "Name",
        "Phone_number": "Phone Number",
        "Email": "Email",
        "Permission": "Permission",
        "Status": "Status",
        "Actions": "Actions",
        "Active": "Active",
        "Inactive": "Inactive",
        "Edit": "Edit",
        "Deactivate": "Deactivate",
        "Delete": "Delete",
        "Add": "Add",
        "ADD_USER": "Add user",
        "First_name": "First name",
        "Last_name": "Last name",
        "AgendId": "User #",
        "Email_address": "Email address",
        "Address": "Address",
        "Required_fields": "Required fields",
        "Save": "Save",
        "Cancel": "Cancel",
        "EDIT_USER": "Edit user",
        "Yes": "Yes",
        "DELETE_USER": "Delete user",
        "DETAIL_USER": "Detail user",
        "DELETE_CONFIRM": "Are you sure you want to delete the selected user?",
        "Update_user_fail": "The errors occurred while updating user",
        "Update_user_success": "The user has been updated successfully",
        "Create_user_fail": "The errors occurred while creating user",
        "Create_user_success": "Account {0} has been created successfully. Please check the email {1} for login credentials details.",
        "Load_user_fail": "The errors occurred while loading user",
        "Update_user_status_fail": "The errors occurred while updating user status",
        "Delete_user_fail": "The errors occurred while deleting user",
        "Delete_user_success": "The user has been deleted successfully",
        "CONFIRM_RESET_PASSWORD": "Do you want to reset password?",
        "RESET_PASSWORD": "Do you want to reset password?",
        "Reset_password": "Reset password",
        "Select_permission": "Select Permission",
        "Reset_password_success": "Reset password successfully"
    },
    "report": {
        "query": {
            "errorMessage": {
                "bookFrom": "Please select booking from",
                "paymentMethod": "Please select payment method",
                "bookingType": "Please select booking type",
                "ratingType": "Please select rating type",
                "stars": "Please select rating type",
                "type": "Type",
                "transactionType": "Please select transaction type",
                "serviceType": "Please select service type",
                "rideService": "Please select ride service",
                "invalidEmail": "Please enter a valid email address."
            },
            "search": {
                "driverReferalPax": "Search: Driver Name, Phone Number",
                "passengerRefersPassengers": "Search: Customer Name, Phone Number",
                "driverReferalPaxDetail": "Search: Passenger Name",
                "bookingDetails": "Search: Booking ID, Operator, Customer phone, Customer name, Driver name, ID# / National IC#, Car Registration Number, Queuing area, Note, Trip, Support ID",
                "financial": "Search: Driver name, Phone",
                "completedTime": "Completed Time",
                "collectedTime": "Collected Time",
                "financialDriver": "Search: Booking ID, Driver name, ID# / National IC#",
                "carActivity": "Search: Car Registration Number",
                "affiliation": "Search: Booking ID",
                "prepaidTopUp": "Search: Company name, Top up ID, Transaction ID",
                "operatorLogs": "Search: Operator name, User name, Description",
                "corporate": "Search: Booking ID, Driver name, Corp Users, Client case matter, Charge code",
                "bookingLogs": "Search: Booking ID, Customer name, Support ID, Driver name, ID# / National IC#",
                "rating": "Search: Booking ID, Customer phone, Customer name, Car registration number",
                "mDispatcher": "Search: Booking ID, Partner name",
                "totalAdjustment": "Search: Booking ID",
                "incidentCancellation": "Search: Booking ID, Customer phone, Support Id, Customer name",
                "acceptanceCancellationRate": "Search: Driver name, Phone number",
                "revenueDriver": "Search: Driver name",
                "dailyDriver": "Search: Driver phone, Driver name, ID# / National IC#",
                "dailyCar": "Search: Car Registration Number, Permission",
                "dailyDriverCar": "Search: Driver phone, Driver name, ID# / National IC#, Car Registration Number",
                "driverLoginStatus": "Search: Driver phone, Driver name, ID# / National IC#, Car Registration Number",
                "bookingSummary": "Search: Operator name, User name, Booking ID",
                "creditWallet": "Search: Company name, Driver name, Booking ID, Operator",
                "cashWallet": "Search: Company name, Driver name, Booking ID, Phone number",
                "settlement": "Search: Driver name, ID# / National IC#",
                "driverRating": "Search: Booking ID, Customer phone, Customer name, Car registration number, Support ID",
                "driverWithdrawal": "Driver name, Phone, Company, Transaction ID, Reference #",
                "penaltyCompensation": "Search: Booking ID, Driver Name, Passenger Name",
                "incompletePayment": "Search: Booking ID, Support ID, Customer Name",
                "passengerWallet": "Search: Booking ID, Customer Name",
                "driverDeposit": "Search: Company name, Driver name, Booking ID, Operator",
                "refund": "Search: Booking ID, Trip Id, Support ID, Customer name",
                "driverRefersCustomerBookings": "Search: Referral code, Support ID",
                "driverHistory": "Search: Payout ID",
                "merchantHistory": "Search: Payout ID",
                "firstWillWin": "Search: customer name, customer phone, customer ID",
                "firstWillWinDriver": "Search: driver name, driver phone, username",
                "merchant": "Search: store",
                "merchantCashTransactionHistory": "Search: Booking ID, Transaction ID, Merchant name",
                "merchantCreditTransactionHistory": "Search: Booking ID, Transaction ID, Merchant name",
                "deleteAccountRequest": "Search: Phone",
                "affiliationPayoutHistory": "Search: Payout ID",
                "thirdPartyBooking": "Search: Booking Id, External Id"
            },
            "common": {
                "all": "All"
            },
            "today": "Today",
            "sendDownloadLink": "Recipient Email Address",
            "generaltingYourExcel": "Please note, generating your excel file may take some time. We'll email it to you as soon as it's ready. ",
            "messageSuccess": "File in progress. You'll get an email when ready. Track progress or download directly from Reports/Export History",
            "thisMonth": "This month",
            "lastMonth": "Last month",
            "past30Days": "Past 30 days",
            "thisYear": "This year",
            "custom": "Custom",
            "customize": "Customize",
            "thisWeek": "This week",
            "past7Days": "Last 7 Days",
            "weekly": "Weekly",
            "monthly": "Monthly",
            "allTime": "All time",
            "tomorrow": "Tomorrow",
            "nextWeek": "Next Week",
            "lastWeek": "Last Week",
            "nextMonth": "Next Month",
            "yesterday": "Yesterday",
            "fromDate": "From date",
            "toDate": "To date",
            "driver": "Driver: %{name}",
            "customer": "Customer: %{name}",
            "referralCode": "Referral Code: %{name}",
            "referer": "Referrer: %{name}",
            "referee": "Referee: %{name}",
            "usedPromoCode": "Promotion: %{name}",
            "voucherCode": "Code: %{name}",
            "city": "City",
            "company": "Company",
            "company_report": "Company: %{name}",
            "campaign_report": "Campaign: %{name}",
            "operator": "Operator",
            "farmType": "Farm type",
            "carLicensePlate": "Car registration number: %{name}",
            "settlementType": "Type",
            "bookingService": "Network type",
            "payoutOption": "Payout option",
            "currency": "Currency",
            "bookingFrom": "Booking from",
            "thirdPartyAction": "Action",
            "paymentMethod": "Payment method",
            "paymentStatus": "Payment status",
            "bookingType": "Booking type",
            "adjustPrice": "Adjust price",
            "intercityRoute": "Intercity route",
            "ratingType": "Rating type",
            "stars": "Rating",
            "driverRating": "Driver rating",
            "vehicleRating": "Vehicle rating",
            "mDispatcherType": "Partner type",
            "mDispatcher": "Partner name",
            "campaign": "Campaign",
            "promocode": "Promo code",
            "canceledBy": "Canceled by",
            "timezone": "Timezone",
            "month": "Month",
            "year": "Year",
            "view": "View",
            "action": "Action",
            "status": "Status",
            "module": "Module",
            "driverType": "Type",
            "vehicleType": "Car type",
            "serviceType": "Service type",
            "expireDate": "Expiry date",
            "rideSharing": "Ride sharing",
            "rideService": "Ride service",
            "transactionType": "Transaction Type",
            "completeFromCC": "Complete from CC",
            "withDrawalTransactionType": "Transaction Type",
            "bookingStatus": "Booking status",
            "withdrawalStatus": "Status",
            "originalStatus": "Original payment status",
            "currentStatus": "Current payment status",
            "tip": "Tip",
            "type": "Type",
            "userType": "User type",
            "eventType": "Event type",
            "eventState": "Event state",
            "eventName": "Event name",
            "merchantType": "Merchant type",
            "merchantService": "Merchant service",
            "storeName": "Store",
            "merchant": "Merchant",
            "event": "Event name: %{name}",
            "store": "Store name: %{name}",
            "selectedMerchant": "Merchant: %{name}",
            "associatedApps": "Associated apps",
            "settlement": "Settlement",
            "rideServiceItem": {
                "all": "All",
                "regulation": "Regulation",
                "rideSharing": "Ride-sharing"
            },
            "actionItem": {
                "all": "All",
                "login": "Log in",
                "logout": "Log out",
                "bookIn": "Available",
                "bookOff": "Not available",
                "deny": "Reject",
                "ignore": "Ignore",
                "accept": "Accept",
                "startTrip": "On my way",
                "updateBooking": "Update",
                "arrive": "Arrive",
                "pickup": "Pick up",
                "drop": "Drop off",
                "complete": "Complete",
                "noShow": "No show",
                "rqJob": "Dispatching",
                "cancel": "Cancel"
            },
            "loginStatusItem": {
                "all": "All",
                "online": "Logging in",
                "offline": "Unlogged in"
            },
            "payoutType": "Payout type",
            "affiliationPayoutTypeItem": {
                "auto": "Auto payout",
                "manual": "Manual payout"
            },
            "affiliationSettlementItem": {
                "payable": "Payable",
                "receivable": "Receivable"
            },
            "affiliationPaymentMethod": {
                "online": "Online",
                "directInvoicing": "Direct invoicing"
            },
            "payoutPaymentStatusItem": {
                "paid": "Paid",
                "pending": "Pending"
            },
            "farmTypeItem": {
                "all": "All",
                "farmIn": "Farm in",
                "farmOut": "Farm Out",
                "roaming": "Roaming",
                "affiliationOwner": "Affiliation owner",
                "farmInAndAffiliationOwner": "Farm in and affiliation owner",
                "farmOutAndAffiliationOwner": "Roaming and affiliation owner"
            },
            "settlementDriverTypeItem": {
                "all": "All driver",
                "individual": "Company Car"
            },
            "shortTrip": {
                "all": "All",
                "shortTrip": "Short trip",
                "nonShortTrip": "Non-short trip"
            },
            "paymentMethodItem": {
                "B2BTerminal": "B2BTerminal",
                "webBooking": "Web booking",
                "pendingPayment": "Pending payment",
                "all": "All",
                "cash": "Cash",
                "cashBySender": "Cash by sender",
                "cashByRecipient": "Cash by recipient",
                "personalCard": "Personal card",
                "corporateCard": "Corporate card",
                "qrCodePayment": "QR code payment",
                "directBilling": "Direct invoicing",
                "externalCard": "External card",
                "mDispatcherCard": "Partner card",
                "corporatePrepaid": "Corporate prepaid",
                "canceled": "Canceled",
                "noShow": "No Show",
                "incident": "Incident",
                "fleetCard": "External card",
                "applePay": "Apple pay",
                "creditCardExternal": "Credit card",
                "paxWallet": "Wallet",
                "tngeWallet": "TnG eWallet",
                "vippseWallet": "Vipps",
                "zainCash": "ZainCash",
                "xendit": "Xendit",
                "FIB": "FIB",
                "SantimPay": "SantimPay",
                "gCash": "GCash",
                "completedWithoutService": "Completed without driver",
                "BankOfGeorgia": "Bank Of Georgia",
                "partialCompleted": "Partial Completed",
                "failed": "Failed",
                "googlePay": "Google Pay",
                "paymentLink": "Payment Link",
                "easyPaisa": "Easy Paisa",
                "jazzCash": "Jazz Cash"
            },
            "paymentStatusItem": {
                "all": "All",
                "fullPayment": "Full payment",
                "partialPayment": "Partial payment",
                "pendingPayment": "Pending payment",
                "partialRefund": "Partial refund",
                "fullRefund": "Full refund"
            },
            "companySettlementTypeItem": {
                "all": "All",
                "fleetOweCompany": "Fleet owe company",
                "companyOweFleet": "Company owe fleet"
            },
            "bookingStatusItem": {
                "all": "All",
                "completed": "Completed",
                "incident": "Incident",
                "paxNoShow": "Pax no show",
                "driverNoShow": "Driver no show",
                "rejected": "Rejected",
                "canceledByTimeOut": "Canceled by timeout",
                "canceledByTimeOutRejected": "Canceled by timeout (rejected)",
                "canceledByBookingBoard": "Canceled by booking board",
                "canceledByCustomer": "Canceled by customer",
                "canceledBySupplier": "Canceled by supplier"
            },
            "bookingFromItem": {
                "all": "All",
                "paxApp": "Pax app",
                "mDispatcher": "Partner",
                "kiosk": "Kiosk",
                "cc": "Command Center",
                "webBooking": "Web booking",
                "carHailing": "Car-hailing",
                "streetSharing": "Street-sharing",
                "dashboard": "Booking board",
                "corp": "Booking board (CTM)",
                "dmc": "Booking board (DMC)",
                "Boost_web_link": "Boost web link"
            },
            "thirdPartyActionItem": {
                "all": "All",
                "searched": "Searched",
                "allocated": "Allocated",
                "updated": "Updated",
                "cancelled": "Cancelled"
            },
            "bookingTypeItem": {
                "batchDelivery": "Batch Delivery",
                "all": "All",
                "now": "Now (Transport)",
                "reservation": "Reservation (Transport)",
                "intercity": "Intercity",
                "delivery": "Delivery",
                "shuttle": "Shuttle"
            },
            "adjustPrices": {
                "all": "All",
                "normalPrice": "Normal price",
                "addonPrice": "Addon price"
            },
            "bookingServiceItem": {
                "all": "All",
                "roaming": "Roaming",
                "homeFleet": "Farm out",
                "localBooking": "In-house",
                "farmIn": "Farm in",
                "farmInAndAffiliationOwner": "Farm in & affiliation owner",
                "provideService": "Farm in"
            },
            "ratingTypeItem": {
                "all": "All",
                "like": "Like",
                "dislike": "Dislike"
            },
            "starsItem": {
                "all": "All",
                "star1": "1 Star",
                "star2": "2 Stars",
                "star3": "3 Stars",
                "star4": "4 Stars",
                "star5": "5 Stars"
            },
            "incidentCanceledTypes": {
                "all": "All",
                "incident": "Incident",
                "canceled": "Canceled"
            },
            "canceledByItem": {
                "all": "All",
                "CorpAD": "Corporate Admin",
                "passenger": "Passenger",
                "driver": "Driver",
                "merchant": "Merchant",
                "CC": "CC",
                "mDispatcher": "Partner",
                "Partner": "Partner",
                "webBooking": "Web booking",
                "timeout": "Timeout"
            },
            "bookingSummaryActionItem": {
                "all": "All",
                "create": "Create",
                "update": "Update",
                "cancel": "Cancel",
                "complete": "Complete",
                "incident": "Incident"
            },
            "transactionTypeItem": {
                "bankAccount": "Transferred to bank account",
                "sentToCustomer": "Send to customer",
                "receivedFromDriver": "Received from driver",
                "sentToDriver": "Sent to driver",
                "creditWallet": "Transferred to credit wallet",
                "netEarning": "Net Earning",
                "referralEarning": "Referral Earning",
                "all": "All",
                "topUp": "Top up",
                "editBalance": "Edit balance",
                "bookingDeduction": "Booking deduction",
                "bookingPromo": "Booking promotion",
                "topUpGCash": "Top up by GCash",
                "cashWallet": "Cash wallet",
                "topUpMOLPay": "Top up by Razer Pay",
                "topUpZainCash": "Top up by ZainCash",
                "topUpXendit": "Top up by Xendit",
                "topUpFIB": "Top up by FIB",
                "topUpSantimPay": "Top up by SantimPay",
                "topUpTelebirr": "Top up by Telebirr",
                "topUpKsher": "Top up by Ksher",
                "topUpWallet": "Top up by {0}",
                "TnG": "Top up by TnG",
                "credit": "Top up by credit card",
                "ride": "Ride",
                "tipAfterCompleted": "Tip after ride",
                "debtWriteOff": "Debt write-off",
                "refund": "Refund",
                "excessOffsetAmount": "Excess Cash Credit",
                "excessDeductionAmount": "Excess Cash Debit",
                "cashExcess": "Excess Cash Debit",
                "gateway": "Top up from gateway",
                "driverTopUpForPax": "Excess Cash Debit",
                "updatedBalance": "Updated balance",
                "partialEarning": "Earning",
                "payout": "Payout",
                "payoutManual": "Payout manual",
                "payoutDriver": "Payout",
                "itemValue": "Item values",
                "redeemVoucher": "Redeem code #",
                "topUpReferrer": "Referral reward",
                "driverInsurance": "Driver Insurance",
                "cancellationPenalty": "Cancellation Penalty",
                "WingBank": "Transfers to Wingbank account",
                "customerDebt": "Customer Debt",
                "googlePay": "Top up by Google Pay",
                "applePay": "Top up by Apple Pay",
                "merchantCommission": "Merchant commission",
                "eWallet": "Transferred to external e-wallet",
                "reversed": "Withdrawal reversed"
            },
            "moduleItem": {
                "all": "All",
                "bookings": "Bookings",
                "customers": "Customers",
                "dispatch": "Dispatch",
                "general": "General",
                "promotion": "Promotion",
                "quest": "Quest",
                "rate": "Rate",
                "car": "Car",
                "city": "City",
                "company": "Company",
                "drivers": "Drivers",
                "driverSettlement": "Driver settlement",
                "login": "Login",
                "logout": "Logout",
                "mDispatcher": "Partner",
                "mDispatcherType": "Partner type",
                "operation": "Operation",
                "partners": "Partners",
                "permission": "Permission",
                "queuingArea": "Queuing area",
                "report": "Report",
                "shift": "Shift",
                "user": "User",
                "voiceSms": "Voice & SMS",
                "intercityZone": "Zone (intercity)",
                "Payout": "Payout",
                "code#": "Code #",
                "ApiManagement": "API management"
            },
            "serviceTypeItem": {
                "all": "All",
                "oneWay": "One way",
                "fromAirport": "From airport",
                "toAirport": "To airport",
                "hourly": "Hourly/daily",
                "roundTrip": "Round trip",
                "fromAirportHourlyDaily": "From airport - hourly/daily",
                "fromAirportRoundTrip": "From airport - round trip",
                "toAirportHourlyDaily": "To airport - hourly/daily",
                "toAirportRoundTrip": "To airport - round trip"
            },
            "merchantTransactionTypeItem": {
                "all": "All",
                "editBalance": "Edit balance",
                "payout": "Payout",
                "earning": "Earning",
                "itemValue": "Item value",
                "orderDeduction": "Order deduction"
            },
            "withdrawalTransactionType": {
                "WingBank": "Transferred to Wingbank account",
                "bankAccount": "Transferred to bank account",
                "cashWallet": "Transferred to cash wallet",
                "creditWallet": "Transferred to credit wallet"
            },
            "payoutDriver": {
                "driversHaveBankAccount": "Drivers have bank account",
                "driversDontHaveBankAccount": "Drivers don't have bank account",
                "payoutEndTime": "Payout end time",
                "driverHaveInvalidBankAccount": "Drivers have invalid bank account"
            },
            "payoutMerchant": {
                "HaveBankAccount": "Merchants have bank account",
                "DontHaveBankAccount": "Merchants don't have bank account",
                "HaveInvalidBankAccount": "Merchants have invalid bank account",
                "payoutEndTime": "Payout end time"
            }
        },
        "error": {
            "23005": "For system performance you may only view reports in %{interval} %{unitLabel}, please adjust your date-range"
        },
        "result": {
            "idPermitType": "ID type",
            "noResultFound": "No result found",
            "page": "Page",
            "itemsPerPage": "Items per page",
            "showPages": "Shown %{from} - %{to} of %{total}",
            "exportToExcel": "Export to excel",
            "exportToCSV": "Export to csv",
            "exportToPaymentFile": "Export to payment file",
            "pay": "Pay",
            "paySelected": "Pay selected",
            "payAll": "Pay all",
            "invalidBankAccount": "Invalid bank account",
            "validBankAccount": "Valid bank account",
            "bookingDetails": {
                "successSendMessage": "Your message has been sent",
                "packageName": "Package Name",
                "transactionId": "Transaction ID",
                "proofOfFailure": "Proof Of Failure",
                "recipientsNumber": "No. of recipient",
                "recipientsInfo": "Recipient info",
                "creditTransactionFee": "Credit card fees",
                "carTypeRequest": "Requested cartype",
                "bookingId": "Booking #",
                "bookingStatus": "Booking status",
                "tripId": "Trip #",
                "bookFrom": "Booking from",
                "bookingService": "Network type",
                "bookingType": "Booking type",
                "serviceType": "Service type",
                "operator": "Operator",
                "customerPhone": "Passenger phone number",
                "customerName": "Passenger name",
                "customerIdNumber": "Passenger ID# / IC#",
                "corpDivision": "Division",
                "corpDept": "Dept",
                "chargeCode": "Charge code",
                "tripDescription": "Trip description",
                "rideSharing": "Ride-sharing",
                "queueName": "Queuing area",
                "bookingTime": "Creation time",
                "pickupTime": "Pickup time",
                "expectedPickupTime": "Expected Pickup Time",
                "dispatchTime": "Dispatch time",
                "onBoardTime": "On board time",
                "droppedOffTime": "Drop off time",
                "onBoardDuration": "On board duration",
                "dispatch": "Dispatch #",
                "completedTime": "Completed Time",
                "driverName": "Driver name",
                "driverIdPermit": "ID# / National IC#",
                "driverLicenseNumber": "Driver license number",
                "companyName": "Company",
                "carType": "Car Type",
                "licensePlate": "Car Registration Number",
                "intercityRoute": "Intercity route",
                "pickupLocation": "Pickup location",
                "geoPickup": "Pickup location lat/long",
                "extraDestination": "Extra destinations",
                "originDestination": "Original destination",
                "originEstDistance": "Original est distance",
                "destination": "Actual destination",
                "geoDestination": "Actual destination lat/long",
                "pickupFrom": "Pickup location from",
                "distanceTour": "P.O.B distance",
                "internalNotes": "Internal notes",
                "notes": "Notes",
                "basicFareCalculator": "Basic fare calculator",
                "basicFare": "Basic fare",
                "editFare": "Edit basic fare",
                "oldBasicFare": "Old basic fare",
                "airportFee": "Airport fee",
                "meetDriver": "Meet driver",
                "rushHour": "Surcharge",
                "surchargeParameter": "Dynamic surcharge",
                "dynamicFare": "Dynamic fare",
                "heavyTraffic": "Heavy traffic",
                "tollFee": "Toll fee",
                "parkingFee": "Parking fee",
                "gasFee": "Gas fee",
                "otherFees": "Other fees",
                "subtotal": "Subtotal",
                "subTotalFF": "Subtotal FF",
                "techFee": "Tech fee",
                "serviceFee": "Additional services",
                "fleetServiceFee": "Fleet services",
                "bookingFee": "Booking fee",
                "salesTax": "Sales tax",
                "tipAmt": "Tip amt",
                "promoCode": "Promo code",
                "promoAmt": "Promo amt",
                "requestedTotalAmount": "Requested total amount",
                "total": "Total amt",
                "totalNotRounded": "ROUND (Total amt)",
                "actualReceived": "Actual received",
                "differenceAmt": "Difference (received)",
                "customerDebt": "Customer Debt",
                "markupDifference": "Markup difference",
                "receivedWhenDropOff": "Received when drop off",
                "refundAmount": "Refund amt",
                "requestedPaymentMethod": "Requested payment method",
                "paidBy": "Paid by",
                "splitPayment": "Split payment",
                "paidByWallet": "Paid by wallet",
                "paidByOtherMethod": "Paid by other method",
                "cashReceived": "Cash received",
                "transferredChangeByWallet": "Transfer change by wallet",
                "returnedChangeByCash": "Return change by cash",
                "instructions": "Instructions",
                "additionalServiceName": "Name",
                "additionalServiceValue": "Value",
                "sendReceiptHeader": "",
                "sendReceipt": "Send receipt",
                "send": "Send",
                "cancel": "Cancel",
                "sentReceiptSuccess": "The receipt has been sent successfully",
                "sentReceiptFailed": "The receipt cannot be sent right now. Please try again.",
                "coRider": "Co-rider",
                "coriderName": "Name",
                "stopAddress": "Additional destination",
                "stopGeo": "Lat/long",
                "addonPrice": "Customer adjust price",
                "oldSubTotal": "Subtotal before adjust",
                "view_good_receipt": "Goods receipt",
                "view_delivery_receipt": "Delivery photo",
                "view": "View",
                "tipAfterCompletedDetails": "Transferred: {0}{1}; Transaction fee: {0}{2}",
                "fleetMarkup": "Profit",
                "editedDriverEarning": "Edited driver earning",
                "driverEarning": "Driver earning",
                "pickUpZoneName": "Pickup zone",
                "dynamicFactor": "Dynamic fare (factor)"
            },
            "financial": {
                "driverName": "Driver name",
                "driverPhone": "Phone number",
                "driverUserName": "Username",
                "companyName": "Company",
                "bookingList": "Booking list",
                "totalFare": "Total fare w/o promo",
                "netEarning": "Net earning",
                "showBookingList": "Show",
                "referralEarning": "Referral earning",
                "totalEarningNet": "Total earning (net)",
                "totalEarningNetTooltip": "Money you earned after returning all fees to fleet. Total earning (net) = Net earning + Tip after ride",
                "totalEarningNetTooltipRefferal": "Money you earned after returning all fees to fleet. Total earning (net) = Referal earning + Net earning + Tip after ride",
                "totalEarningGross": "Total earning (gross)",
                "totalEarning": "Total earning",
                "totalEarningGrossTooltip": "Money you earned from booking fare and tips. Total earning (gross) = Gross earning + Tip after ride",
                "totalEarningGrossTooltipRefferal": "Money you earned from booking fare and tip. Total earning (gross) = Referal earning + Gross earning + Tip after ride",
                "note": "Note",
                "shortTrip": "Short trip"
            },
            "financialDriver": {
                "supplierAmount": "Supplier commission",
                "payoutType": "Payout type",
                "date": "Date",
                "driverTipAfterCompleted": "Tip after ride",
                "bookingId": "Booking ID",
                "paidBy": "Paid by",
                "bookFrom": "Booking from",
                "bookingType": "Booking type",
                "companyName": "Company",
                "driverName": "Driver",
                "driverIdPermit": "ID# / National IC#",
                "totalFare": "Total fare without promo",
                "subtotal": "Subtotal",
                "airportFee": "Airport fee",
                "meetDriver": "Meet driver",
                "tollFee": "Toll fee",
                "parkingFee": "Parking fee",
                "gasFee": "Gas fee",
                "rushHour": "Rush hour",
                "transactionFee": "Transaction fee",
                "techFee": "Tech fee",
                "salesTax": "Sales tax",
                "tipAmt": "Tip amt",
                "bookingFee": "Booking fee",
                "commission": "Commission",
                "deduction": "Deduction",
                "promoAmt": "Promo amt",
                "ridePayment": "Ride payment",
                "driverTax": "Driver tax",
                "grossEarning": "Gross earning",
                "driverDeduction": "Driver deduction",
                "netEarning": "Net Earning",
                "payToDriver": "Pay to driver",
                "payToFleet": "Pay to fleet",
                "totalNotRounded": "ROUND (Total amt)",
                "fleetServiceFee": "Fleet service fees",
                "editedDriverEarning": "Edited driver earning",
                "stripeFee": "Stripe Fee"
            },
            "financialCompany": {
                "companyName": "Company ",
                "homeFleetNetEarning": "Home fleet Net Earning",
                "affiliateNetEarning": "Affiliate Net Earning",
                "totalNetEarning": "Total Driver Net Earning",
                "status": "Status",
                "companyAmount": "Company profit"
            },
            "companySettlement": {
                "companyName": "Company",
                "pendingSettlement": "Pending settlement",
                "viewDetails": "View details",
                "settlementType": "Type"
            },
            "companySettlementDetails": {
                "bookId": "Booking ID",
                "dateTime": "Date time",
                "originalPaymentStatus": "Original payment status",
                "subTotal": "Subtotal",
                "fleetAmount": "Fleet amount",
                "driverAmount": "Driver amount",
                "companyAmount": "Company amount",
                "companyOweFleet": "Company owe fleet",
                "fleetOweCompany": "Fleet owe company",
                "notes": "Notes"
            },
            "financialProfit": {
                "profit": "Profit",
                "bookingId": "Booking #",
                "subTotal": "Subtotal",
                "subTotalFF": "Subtotal FF",
                "fleetCommission": "Fleet commission",
                "techFee": "Tech fee",
                "promotion": "Promotion",
                "markupDifference": "Markup difference",
                "Profit": "Profit",
                "currency": "Currency",
                "fleetServiceFee": "Fleet service (fleet take)",
                "fleetServiceFeeTooltip": "Amount fleet takes commission from service fees"
            },
            "promotion": {
                "date": "Date",
                "promoCode": "Promo code",
                "users": "Users",
                "uses": "Uses",
                "checked": "Checked",
                "applied": "Applied",
                "totalPromoAmount": "Total promo amount",
                "totalReceipt": "Total receipt",
                "numberOfUsers": "Number of users",
                "numberOfPromoCodeAppliance": "Number of promo code appliance",
                "numberOfSuccessfulBookings": "Number of successful bookings",
                "numberOfCompletedBookings": "Number of completed bookings",
                "numberOfCompletedUsers": "Number of completed users",
                "numberOfUsersApplyPromotion": "Number of users apply promotion",
                "numberOfUsersApplyPromotionAndCompleteBooking": "Number of users apply promotion & complete booking",
                "numberOfPromoCodeApplianceTooltip": "Number of promo code appliance by all users",
                "numberOfSuccessfulBookingsTooltip": "Number of bookings created successfully with promotion code",
                "numberOfCompletedBookingsTooltip": "Number of completed bookings which applied promotion code"
            },
            "export": {
                "createdDate": "Date",
                "sessionName": "Session Name",
                "download": "Download",
                "delete": "Delete",
                "percent": "Percent",
                "status": "Status",
                "name": "Module name"
            },
            "redeemed": {
                "date": "Date",
                "code": "Code",
                "customers": "Customer",
                "phone": "Phone number",
                "value": "Value",
                "currency": "Currency",
                "campaignName": "Campaign"
            },
            "carActivity": {
                "plateNumber": "Car Registration Number",
                "vehicleType": "Car type",
                "companyName": "Company",
                "day1st": "1st",
                "day2nd": "2nd",
                "day3rd": "3rd",
                "day4th": "4th",
                "day5th": "5th",
                "day6th": "6th",
                "day7th": "7th",
                "day8th": "8th",
                "day9th": "9th",
                "day10th": "10th",
                "day11th": "11th",
                "day12th": "12th",
                "day13th": "13th",
                "day14th": "14th",
                "day15th": "15th",
                "day16th": "16th",
                "day17th": "17th",
                "day18th": "18th",
                "day19th": "19th",
                "day20th": "20th",
                "day21st": "21st",
                "day22nd": "22nd",
                "day23rd": "23rd",
                "day24th": "24th",
                "day25th": "25th",
                "day26th": "26th",
                "day27th": "27th",
                "day28th": "28th",
                "day29th": "29th",
                "day30th": "30th",
                "day31st": "31st",
                "operationDays": "Operation days",
                "activeCars": "Total active car:"
            },
            "carActivityLogsTitle": "Logs",
            "carActivityLogs": {
                "licensePlate": "Car Registration Number",
                "companyName": "Company",
                "bookingId": "Booking ID",
                "driverName": "Driver name",
                "driverIDNumber": "ID# / National IC#",
                "date": "Accepted time",
                "bookFrom": "Booking from"
            },
            "affiliation": {
                "date": "Pickup time",
                "bookingId": "Booking ID",
                "bookingType": "Booking type",
                "homeFleet": "Home fleet",
                "providerFleet": "Provider fleet",
                "driver": "Driver",
                "passenger": "Passenger",
                "paidBy": "Paid by",
                "airportFee": "Airport fee",
                "meetDriver": "Meet driver",
                "rushHour": "Surcharge",
                "tollFee": "Toll fee",
                "parkingFee": "Parking fee",
                "gasFee": "Gas fee",
                "heavyTraffic": "Heavy traffic",
                "otherFees": "Other fees",
                "provider": "Supplier",
                "basicFareProvider": "Basic fare provider",
                "subTotalProvider": "Sub total provider",
                "techFeeProvider": "Tech fee provider",
                "bookingFeeProvider": "Booking fee provider",
                "taxValueProvider": "Tax value provider",
                "tipProvider": "Tip provider",
                "totalFareProvider": "Total fare provider",
                "basicFare": "Basic fare",
                "subtotal": "Subtotal",
                "techFee": "Tech fee",
                "bookingFee": "Booking fee",
                "tax": "Tax",
                "tip": "Tip",
                "totalFare": "Total fare",
                "payoutInLocalCurrency": "Ride earning in local currency",
                "payoutInUSD": "Ride earning in USD",
                "fleetCommInUSD": "Fleet comm in USD",
                "bookingFeeInUSD": "Booking fee in USD",
                "totalPayoutInUSD": "Total payout in USD",
                "subTotal": "Subtotal",
                "bookingStatus": "Booking status",
                "vehicle": "Vehicle",
                "customerPhone": "Customer phone number",
                "driverPhone": "Driver phone number",
                "total": "Total",
                "totalInUSD": "Total fare (USD)",
                "supplierPayout": "Supplier payout",
                "supplierPayoutInUSD": "Supplier payout (USD)",
                "buyerPayout": "Buyer commission",
                "buyerPayoutInUSD": "Commission (USD)",
                "buyerCommission": "Commission",
                "buyerCommissionInUSD": "Commission (USD)",
                "receivableInUSD": "Receivable (USD)",
                "payableInUSD": "Payable (USD)",
                "fleetMarkupInUSD": "Profit (USD)",
                "paymentMethod": "Payment method",
                "paymentStatus": "Payment status"
            },
            "prepaidTopUp": {
                "date": "Date & time",
                "topUpId": "Top up ID",
                "transactionId": "Transaction ID",
                "name": "Name",
                "company": "Company",
                "paidBy": "Paid by",
                "amount": "Amount",
                "newAmount": "New Balance",
                "transactionType": "Transaction type"
            },
            "operatorLogs": {
                "date": "Date",
                "operator": "Operator",
                "username": "Username",
                "module": "Module",
                "description": "Description"
            },
            "corporate": {
                "date": "Date",
                "bookingId": "Booking ID",
                "operatorName": "Corp operator",
                "corporateName": "Company",
                "corpTraveler": "Corp Users",
                "travelerSignature": "Traveler signature",
                "corpId": "Corp id",
                "corpDivision": "Corp division",
                "costCentre": "Cost centre",
                "corpPO": "Corp PO",
                "managerName": "Manager's name",
                "managerEmail": "Manager's email",
                "title": "Title",
                "department": "Department",
                "clientCaseMatter": "Client case matter / Trip description",
                "chargeCode": "Charge code / Trip code",
                "driverName": "Driver",
                "pickupLocation": "Pickup location",
                "destination": "Destination",
                "total": "Total amt",
                "subtotal": "Subtotal",
                "subTotalFF": "Subtotal FF",
                "commission": "Commission",
                "commissionBooking": "Apply commission for bookings from app/pwa",
                "paidBy": "Paid by",
                "statusDisplay": "Status",
                "flightNumber": "Flight number"
            },
            "bookingLogs": {
                "bookingId": "Booking ID",
                "bookingService": "Network type",
                "bookingType": "Booking type",
                "driverName": "Driver",
                "driverIdPermit": "ID# / National IC#",
                "customerName": "Passenger",
                "pickupTime": "Pickup time",
                "onBoardTime": "On board time",
                "droppedOffTime": "Drop off time",
                "pickupLocation": "Pickup location",
                "destination": "Destination",
                "dispatchHistory": "Dispatch history",
                "otwDistance": "O.T.W distance",
                "distanceTour": "P.O.B distance",
                "duration": "P.O.B duration",
                "paidBy": "Paid by",
                "bookFrom": "Booking From",
                "map": "Map",
                "showDispatchHistory": "Show",
                "showMap": "Show",
                "paymentActivities": "Payment activities"
            },
            "rating": {
                "date": "Date",
                "bookingId": "Booking ID",
                "driverName": "Driver",
                "driverIdPermit": "ID# / National IC#",
                "customerName": "Customer name",
                "customerPhone": "Customer phone",
                "like": "Like",
                "dislike": "Dislike",
                "notes": "Comment",
                "stars": "Rating",
                "vehicleRating": "Vehicle Rating",
                "driverRating": "Driver Rating",
                "plateNumber": "Car Registration Number"
            },
            "mDispatcher": {
                "date": "Date",
                "bookingId": "Booking ID",
                "mDispatcherName": "Partner name",
                "mDispatcherType": "Partner type",
                "subtotal": "Subtotal",
                "commission": "Commission",
                "paidBy": "Paid by"
            },
            "totalAdjustment": {
                "date": "Date",
                "bookingId": "Booking ID",
                "oldAmount": "Total",
                "type": "Type",
                "markupDifference": "Markup difference",
                "newAmount": "Edited total/Markup",
                "username": "Operator",
                "reason": "Reason"
            },
            "incidentCancellation": {
                "date": "Date",
                "bookingId": "Booking ID",
                "customerName": "Customer name",
                "customerPhone": "Customer phone",
                "bookFrom": "Booking from",
                "canceledBy": "Canceled by",
                "type": "Type",
                "reason": "Reason",
                "reasonCode": {
                    "0": "I found another travel option",
                    "1": "Driver too far away",
                    "2": "I entered wrong location",
                    "3": "I waited for too long",
                    "4": "Driver asked to cancel",
                    "5": "I changed my plans",
                    "6": "I wanted to change location",
                    "101": "Most or all items are unavailable",
                    "102": "We're too busy right now",
                    "103": "We're closed right now",
                    "104": "We're closing soon"
                },
                "canceller": "Canceller"
            },
            "acceptanceCancellationRate": {
                "companyId": "Company Id",
                "driverId": "Driver Id",
                "driverName": "Driver name",
                "phoneNumber": "Phone number",
                "company": "Company",
                "acceptanceRate": "Acceptance rate",
                "cancellationRate": "Cancellation rate",
                "rating": "Rating"
            },
            "revenueFleet": {
                "date": "Date",
                "fareRevenue": "Fare revenue",
                "trip": "Trip",
                "avgFareTrip": "Avg fare/trip",
                "drivers": "# Drivers",
                "avgTripPerDriver": "Avg trip/driver",
                "dispatcher": "# Dispatcher"
            },
            "revenueDriver": {
                "date": "Date",
                "driverName": "Driver",
                "fareRevenue": "Fare revenue",
                "trip": "# Trip",
                "avgFareTrip": "Avg fare/trip"
            },
            "revenueMonthly": {
                "month": "Month",
                "trip": "Finished trips",
                "subtotal": "Subtotal",
                "tipAmt": "Tip",
                "techFee": "Tech fee",
                "salesTax": "Tax",
                "partnerCommission": "Partner commission/ ODD",
                "totalFare": "Total fare without promo",
                "netEarning": "Net Earning",
                "fleetServiceFee": "Fleet service fees"
            },
            "dailyDriver": {
                "driverName": "Driver",
                "driverIDPermit": "ID# / National IC#",
                "driverPhone": "Phone",
                "companyName": "Company",
                "workingHoursOnline": "Online (hrs)",
                "workingHoursAvailable": "Available (hrs)",
                "driverWorkingHours": "Driver working hours",
                "distance": "Driver traveled distance",
                "CC_activity": "CC activity",
                "fare_summary": "CC activity",
                "workingHoursNotAvailable": "Not available (hrs)",
                "workingHoursPob": "P.O.B (hrs)",
                "workingHoursPobToolTip": "Passenger on board duration",
                "workingHoursAccepted": "Accepted",
                "workingHoursReject": "Reject",
                "workingHoursIgnore": "Ignore",
                "workingHoursCancel": "Cancel",
                "completedTripsTotal": "Total",
                "completedTripsCc": "From CC",
                "completedTripsCarHailing": "From car-hailing",
                "completedTripsStreetSharing": "From Street Sharing",
                "completedTripsPaxApp": "From pax app",
                "completedTripsWebBooking": "From web booking",
                "completedTripsMDispatcher": "From partner",
                "completedTripsKiosk": "From Kiosk",
                "completedTripsApi": "From API",
                "ccActivityDispatchedTrips": "Dispatched trips",
                "ccActivityAccepted": "Accepted",
                "ccActivityReject": "Reject",
                "ccActivityIgnore": "Ignore",
                "ccActivityCancelByPassenger": "Canceled by passenger ",
                "ccActivityCancelByDriver": "Canceled by driver",
                "ccActivityCancelByCc": "Canceled by CC",
                "ccActivityCancelByAPI": "Canceled by API",
                "ccActivityNoShow": "No show",
                "fareSummaryTotalCash": "Cash",
                "fareSummaryTotalCreditCard": "Credit card",
                "fareSummaryTotalExternalCard": "External card",
                "fareSummaryTotalPaxWallet": "Wallet",
                "fareSummaryTotalDirectBilling": "Driect invoicing",
                "fareSummaryTotalQrCode": "QR code",
                "fareSummaryTotalPrePaid": "Corporate Prepaid",
                "fareSummaryTotalApplePay": "Apple pay",
                "fareSummaryTotalTngeWallet": "TNG eWallet",
                "fareSummaryTotalVippseWallet": "Vipps",
                "fareSummaryTotal": "Total",
                "ratingNotes": "No. notes",
                "ratingDislike": "No. dislikes",
                "ratingLike": "No. likes",
                "fareDetailsBasicFare": "Basic fare",
                "fareDetailsAirportFee": "Airport fee",
                "fareDetailsRushHour": "Surcharge",
                "fareDetailsHeavyTraffic": "Heavy traffic",
                "fareDetailsOtherFees": "Other fees",
                "fareDetailsSubTotal": "Subtotal",
                "fareDetailsSubTotalFF": "Subtotal FF",
                "fareDetailsTechFee": "Tech fee",
                "fareDetailsBookingFee": "Booking fee",
                "fareDetailsSalesTax": "Sales tax",
                "fareDetailsTip": "Tip amt",
                "fareDetailsPromoAmount": "Promo amt",
                "fareDetailsTotalAmt": "Total amt",
                "availableDistance": "Available",
                "otwDistance": "O.T.W",
                "otwDistanceToolTip": "On the way distance",
                "pobDistance": "P.O.B",
                "pobDistanceToolTip": "Passenger on board distance",
                "ratingStars1": "No. 1 star",
                "ratingStars2": "No. 2 stars",
                "ratingStars3": "No. 3 stars",
                "ratingStars4": "No. 4 stars",
                "ratingStars5": "No. 5 stars",
                "ratingAvg": "Average",
                "ssnNumber": "SSN #",
                "driverEmail": "Email address",
                "driverAddress": "Home address",
                "fareDetailsAddonPrice": "Customer adjust price",
                "fareDetailsOldSubTotal": "Subtotal before adjust"
            },
            "dailyCar": {
                "carInfo": "Car info",
                "fareDetails": "Fare details",
                "completedTrips": "Completed trips",
                "carInfoCompanyName": "Company",
                "carInfoPermission": "Permission",
                "carInfoVehiclePlateNumber": "Car Registration Number",
                "carInfoVhcId": "Car #",
                "carInfoCarType": "Car type",
                "completedTripsTotal": "Total",
                "completedTripsCc": "From CC",
                "completedTripsCarHailing": "From car-hailing",
                "completedTripsStreetSharing": "From Street Sharing",
                "completedTripsPaxApp": "From pax app",
                "completedTripsWebBooking": "From web booking",
                "completedTripsMDispatcher": "From partner",
                "completedTripsKiosk": "From Kiosk",
                "completedTripsAPI": "From API",
                "fareDetailsBasicFare": "Basic fare",
                "fareDetailsAirportFee": "Airport fee",
                "fareDetailsMeetDriver": "Meet driver",
                "fareDetailsRushHour": "Surcharge",
                "fareDetailsHeavyTraffic": "Heavy traffic",
                "fareDetailsTollFee": "Toll fee",
                "fareDetailsParkingFee": "Parking fee",
                "fareDetailsGasFee": "Gas fee",
                "fareDetailsOtherFees": "Other fees",
                "fareDetailsSubTotal": "Sub total",
                "fareDetailsSubTotalFF": "Subtotal FF",
                "fareDetailsTechFee": "Tech fee",
                "fareDetailsBookingFee": "Booking fee",
                "fareDetailsSalesTax": "Sales tax",
                "fareDetailsTip": "Tip",
                "fareDetailsPromoAmount": "Promo amount",
                "fareDetailsTotalAmt": "Total amt",
                "fareDetailsAddonPrice": "Customer adjust price",
                "fareDetailsOldSubTotal": "Subtotal before adjust"
            },
            "dailyDriverCar": {
                "driverInfo": "Driver info",
                "driverName": "Driver name",
                "driverIDPermit": "ID# / National IC#",
                "driverPhone": "Phone number",
                "companyName": "Company",
                "actionInfo": "Actions",
                "date": "Date",
                "action": "Action",
                "bookingId": "Booking ID",
                "carInfo": "Car info",
                "permission": "Permission",
                "licensePlate": "Car Registration Number",
                "carId": "Car #",
                "carType": "Car type"
            },
            "driverLoginStatus": {
                "driver": "Driver",
                "driverIdPermit": "ID# / National IC#",
                "phone": "Phone number\t",
                "username": "Username",
                "company": "Company",
                "carType": "Car type\t",
                "licensePlate": "Car Registration Number",
                "status": "Status"
            },
            "bookingSummary": {
                "date": "Date",
                "operator": "Operator",
                "username": "Username",
                "action": "Action",
                "bookingId": "Booking ID"
            },
            "cashWallet": {
                "bankAccount": "Transferred to bank account",
                "creditWallet": "Transferred to credit wallet",
                "tipAfterCompleted": "Tip After Completed",
                "netEarning": "Net Earning",
                "referralEarning": "Referral Earning",
                "receiverAccount": "Receiver Account",
                "dateAndTime": "Date & time",
                "driverName": "Driver name",
                "phoneNumber": "Phone number",
                "userName": "Username",
                "company": "Company",
                "type": "Type",
                "bookingId": "Booking #",
                "operator": "Operator",
                "reason": "Reason",
                "amount": "Amount",
                "newAmount": "New balance",
                "currency": "Currency",
                "topUpGCashReason": "Top up by Gcash",
                "topUpReason": "Top up from credit card *{0}",
                "cashWalletReason": "Top up from Cash Wallet",
                "topUpMOLPayReason": "Razer Pay [ {0} ]",
                "chargedAmount": "Charge amount",
                "differentPercent": "Discount / Extra charge (%)",
                "operatorName": "Operator",
                "sendPayout": "Action",
                "sendPayoutTitle": "Resend payout email",
                "payoutReason": "{0} (Payout {1} {2})"
            },
            "creditWallet": {
                "receiverType": "Receiver Type",
                "receiverName": "Receiver Name",
                "receiverPhone": "Receiver phone number",
                "senderPhone": "Sender phone number",
                "senderName": "Sender name",
                "sentToCustomer": "Send to customer",
                "sentToDriver": "Send to driver",
                "dateAndTime": "Date & time",
                "driverName": "Driver name",
                "phoneNumber": "Phone number",
                "userName": "Username",
                "company": "Company",
                "type": "Type",
                "bookingId": "Booking #",
                "operator": "Operator",
                "reason": "Reason",
                "amount": "Amount",
                "newAmount": "New balance",
                "currency": "Currency",
                "transactionId": "Transaction ID",
                "topUpGCashReason": "Top up by Gcash",
                "topUpReason": "Top up from credit card *{0}",
                "cashWalletReason": "Top up from Cash Wallet",
                "topUpMOLPayReason": "Razer Pay [ {0} ]",
                "topUpWalletReason": "{0} [ {1} ]",
                "chargedAmount": "Charge amount",
                "differentPercent": "Discount / Extra charge (%)",
                "cashExcessReason": "Customer must pay {0}; Cash received {1}",
                "referralEarningReason": "Customer {0} {1} completed first booking {2}",
                "referralEarningReason_2": "Customer {0} {1} completed booking {2}",
                "insurance": "Driver insurance"
            },
            "passengerWallet": {
                "dateTime": "Date & time",
                "customerName": "Customer name",
                "phoneNumber": "Phone number",
                "type": "Type",
                "bookingId": "Booking #",
                "changedAmount": "Changed amount",
                "amount": "Changed amount",
                "newBalance": "New balance",
                "currency": "Currency",
                "transactionId": "Transaction ID",
                "Note": "Note",
                "credit": "Topup by credit card *{0}",
                "TnG": "Topup by TnG #{0}",
                "MOLPay": "Top up from [RazerPay - #{0}]",
                "gatewayName": "Top up from [{0} - #{1}]",
                "cashExcess": "Customer must pay {0}, Cash given {1}",
                "editBalance": "Edit Balance",
                "ride": "Ride",
                "topUp": "Top up",
                "topUpReferee": "Referral reward ({0})",
                "topUpReferrer": "New referee (#{0}): {1} ({2})"
            },
            "recipientsInfo": {
                "order": "No.",
                "orderId": "Order #",
                "name": "Recipient name",
                "phone": "Recipient phone #",
                "address": "Recipient address",
                "deliveryFee": "Delivery fee",
                "ItemValue": "Item Value (COD)",
                "status": "Status",
                "Performer": "Performer",
                "Reason": "Reason",
                "proofOfFailure": "Proof of failure",
                "referenceNumber": "Reference Number"
            },
            "dispatchHistory": {
                "performer": "Performer",
                "name": "Name",
                "action": "Action",
                "date": "Date",
                "location": "Driver's location",
                "estimate": "Vehicle-to-pickup location estimate",
                "passenger": "Customer",
                "detachVehicle": "Detach Vehicle",
                "assignVehicle": "Assign Vehicle"
            },
            "payToDriver": {
                "driverName": "Driver",
                "totalUnsettledTransactions": "Total unsettled transactions",
                "totalUnsettledAmount": "Total unsettled amount",
                "actions": "Actions",
                "pay": "Pay",
                "remove": "Remove",
                "clearSuccess": "The earning of selected driver was successfully cleared.",
                "clearFailed": "Failed to clear earning data of the driver. Please try again.",
                "selectDriver": {
                    "title": "",
                    "body": "Please select driver to pay.",
                    "buttonTitle": "OK"
                },
                "confirmClear": {
                    "title": "",
                    "body": "Are you sure you want to clear earning data of this driver? This action cannot be undone.",
                    "buttonTitle": "Yes",
                    "closeButtonText": "No"
                },
                "confirmPay": {
                    "body": "Are you sure you want to pay to selected driver(s)?",
                    "buttonTitle": "Yes",
                    "closeButtonText": "No"
                },
                "paymentSuccessfully": "Payments settled successfully to driver(s)"
            },
            "customerQuest": {
                "name": "Name",
                "phoneNumber": "Phone number",
                "supportId": "Support ID",
                "ranking": "Ranking",
                "points": "Points",
                "numberOfReservation": "Number of reservation",
                "driver": "Driver",
                "customer": "Customer",
                "firstWillWin": "First will win",
                "firstWillWinLuckyDraw": "First will win (lucky draw)",
                "largestWillWin": "Largest will win (leaderboard)",
                "qualified": "Qualified",
                "notQualified": "Not qualified",
                "userName": "User name",
                "completedDate": "Completed date",
                "isQualified": "Status",
                "ticket": "Ticket",
                "numOfComplete": "Num of completed",
                "bookingDistance": "P.O.B distance",
                "numberOfIntercity": "Number of intercity",
                "numberOfDelivery": "Number of delivery",
                "pleaseSelectEventName": "Please select event name.",
                "numberOfQualifiedSetting": "Number of qualified (setting): ",
                "numberOfActualQualified": "Number of actual qualified: ",
                "driverRating": "Driver Rating",
                "acceptanceRating": "Acceptance Rating",
                "cancellationRating": "Cancellation Rating",
                "period": "Period: "
            },
            "payoutToDriver": {
                "IFSCCode": "IFSC Code",
                "payoutID": "Payout ID",
                "driverName": "Driver",
                "driverPhone": "Phone number",
                "companyName": "Company",
                "period": "Period",
                "driverId": "ID# / National IC#",
                "cashWalletBalance": "Cash wallet balance",
                "creditBalance": "Credit balance",
                "holdAmount": "Hold amount",
                "minPayout": "Min payout",
                "enterMinimumPayoutAmount": "Enter minimum payout amount",
                "payoutAmount": "Payout amount",
                "payoutDate": "Payout date",
                "actions": "Actions",
                "pay": "Pay",
                "remove": "Remove",
                "payoutEndTime": "Payout end time",
                "clearSuccess": "The earning of selected driver was successfully cleared.",
                "clearFailed": "Failed to clear earning data of the driver. Please try again.",
                "amountKeepInDriverCashWallet": "Amount keep in driver's Cash Wallet",
                "selectDriver": {
                    "title": "",
                    "body": "Please select driver to pay.",
                    "buttonTitle": "OK"
                },
                "confirmClear": {
                    "title": "",
                    "body": "Are you sure you want to clear earning data of this driver? This action cannot be undone.",
                    "buttonTitle": "Yes",
                    "closeButtonText": "No"
                },
                "confirmPay": {
                    "body": "Are you sure you want to pay to selected driver(s)? <br> - Number of drivers: <b>%{drivers}</b> <br> - Min payout out for each driver: %{currency} <b>%{min}</b>  <br> - Hold amount: %{currency}<b> %{hold}</b> <br> - Total payout: %{currency} <b>%{total}</b>   ",
                    "buttonTitle": "Yes",
                    "closeButtonText": "No"
                },
                "paymentSuccessfully": "Payments settled successfully to driver(s)",
                "paymentCompleted": "Payout completed: <br> - Pay successfully: %{success} <br> - Failed: %{failed} <br> - Total payout successfully: %{currency} %{total}",
                "noteSelectDrivers": "Note: select drivers who have bank account to pay.",
                "sendPayout": "Send payout successful notification to email address",
                "accountNumber": "Account number",
                "iBanNumber": "IBAN number",
                "accountHoldName": "Account holder's name",
                "bankName": "Bank name",
                "selelctedDriverHasBeenPaid": "Selected driver(s) has been paid",
                "selelctedDriverHasBeenFailed": "Selected driver(s) has been failed",
                "selelctedDriverHasBeenUpdateAsInvalid": "Selected driver(s) has been updated as 'Invalid bank account'",
                "selelctedDriverHasBeenUpdateAsValid": "Selected driver(s) has been updated as 'Valid bank account'",
                "isBankAccountOwner": "Beneficiary same as driver",
                "isBankAccountOwner_true": "Yes",
                "isBankAccountOwner_false": "No",
                "beneficiaryIDIC": "Beneficiary ID# / IC#",
                "bankRelationship": "Relationship",
                "bankRelationship_1": "Spouse",
                "bankRelationship_2": "Daughter/Son",
                "bankRelationship_3": "Brother/Sister",
                "bankRelationship_4": "Relative",
                "bankRelationship_5": "Friends",
                "bankRelationship_6": "Other: {0}"
            },
            "payoutToMerchant": {
                "payoutId": "Payout ID",
                "merchantName": "Merchant Name",
                "phone": "Phone number",
                "accountNumber": "Account number",
                "iBanNumber": "IBAN number",
                "bankAccountHolder": "Account holder's name",
                "bankName": "Bank name",
                "pendingAmount": "Pending amount",
                "payoutAmount": "Payout amount",
                "payoutDate": "Payout date",
                "holdAmount": "Hold amount",
                "minPayout": "Min payout",
                "selelctedMerchantHasBeenPaid": "Selected merchant(s) has been paid",
                "selelctedMerchantHasBeenFailed": "Selected merchant(s) has been failed",
                "pay": "Pay",
                "selectMerchant": {
                    "title": "Warning",
                    "body": "Please select merchant to pay.",
                    "buttonTitle": "OK"
                },
                "confirmPay": {
                    "body": "Are you sure you want to pay to selected merchant(s)? <br> - Number of merchants: <b>%{merchants}</b> <br> - Min payout out for each driver: %{currency} <b>%{min}</b>  <br> - Hold amount: %{currency}<b> %{hold}</b> <br> - Total payout: %{currency} <b>%{total}</b>",
                    "buttonTitle": "Yes",
                    "closeButtonText": "No"
                },
                "sendPayout": "Send payout successful notification to email address",
                "HasBeenUpdateAsInvalid": "Selected merchant(s) has been updated as 'Invalid bank account'",
                "HasBeenUpdateAsValid": "Selected merchant(s) has been updated as 'Valid bank account'"
            },
            "settlementHistory": {
                "date": "Date",
                "driverName": "Driver name",
                "dateRange": "Date range",
                "totalTransaction": "Total settled transactions",
                "paidAmount": "Total settled amount"
            },
            "settlement": {
                "driverName": "Driver",
                "driverIdPermit": "ID# / National IC#",
                "totalBookings": "Total booking",
                "total": "Total amt",
                "approved": "Approved",
                "acct": "ACCT",
                "gross": "GROSS",
                "percent": 0.03,
                "net": "NET"
            },
            "documentExpiry": {
                "driverName": "Driver name",
                "driverPhone": "Driver phone",
                "company": "Company",
                "documentName": "Document name",
                "expiredDate": "Expired date",
                "status": "Status",
                "statusStr": {
                    "NA": "N/A",
                    "expired": "Expired",
                    "active": "Active",
                    "expireSoon": "Expire soon"
                }
            },
            "referralDetail": {
                "bookingId": "Book #",
                "completedDate": "Completed Time",
                "paidBy": "Paid by",
                "bookFrom": "Booking from",
                "driverName": "Driver name",
                "driverIdPermit": "ID# / National IC#",
                "companyName": "Company",
                "Passenger": "Passenger",
                "Customer": "Customer",
                "subtotal": "Subtotal",
                "subTotalFF": "Subtotal FF",
                "subtotalWithPromo": "Subtotal with promotion",
                "referralEarning": "Referral Earning",
                "Amount": "Amount",
                "Note": "Note",
                "Currency": "Currency",
                "PercentOfSubTotal": "{0}% of subtotal",
                "max": "max {0}",
                "referrerInfo": "Driver refers customer",
                "phoneReferral": "Driver phone #",
                "driverNumber": "ID# / National IC#",
                "referralCode": "Referral code"
            },
            "referralHistory": {
                "driverName": "Driver name",
                "phoneNumber": "Phone number",
                "companyName": "Company",
                "referralCode": "Referral code",
                "commission": "Total commission",
                "expectedReferralEarning": "Expect referral earning",
                "activePaxTooltip": "Passengers who have redeemed the referral code from the driver with at least one booking successfully made.",
                "referredPaxTooltip": "Passengers who have redeemed the referral code from the driver but yet to make any booking.",
                "activePax": "Total active customers",
                "referredPax": "Total referred customers",
                "detailFields": {
                    "passengerId": "Passenger ID",
                    "passengerName": "Customer name",
                    "firstBookingDate": "1st completed booking date",
                    "firstBookingDateGMT": "1st completed booking date (GMT)",
                    "passengerPhone": "Customer phone number",
                    "appliedDate": "Referred date",
                    "referredByDriver": "Referred by driver",
                    "driverPhone": "Driver phone number",
                    "referralCode": "Referral code"
                },
                "showDetail": "Show",
                "detail": "Detail"
            },
            "paxReferralHistory": {
                "customerName": "Customer name",
                "phoneNumber": "Phone number",
                "referralCode": "Referral code",
                "commission": "Total commission",
                "activePaxTooltip": "Passengers who have redeemed the referral code with at least one booking successfully made.",
                "referredPaxTooltip": "Passengers who have redeemed the referral code but yet to make any booking.",
                "activePax": "Total active customers",
                "referredPax": "Total referred customers",
                "detailFields": {
                    "passengerId": "Passenger ID",
                    "passengerName": "Passenger name",
                    "firstBookingDate": "1st completed booking date",
                    "firstBookingDateGMT": "1st completed booking date (GMT)"
                },
                "showDetail": "Show",
                "detail": "Detail"
            },
            "driverRefersDriver": {
                "companyName": "Company",
                "phoneNumber": "Phone number",
                "username": "Username",
                "referralCode": "Referral code",
                "referrer": "Referrer",
                "numberOfReferees": "Number of referees",
                "activeReferees": "Active referees",
                "referee": "Referee",
                "refereePhone": "Referee phone number",
                "refereeUsername": "Referee username",
                "referredDate": "Referred date",
                "activatedDate": "Activated date",
                "referrerByDriver": "Referrer",
                "referrerPhone": "Referrer phone number",
                "referralStatus": "Referral status",
                "lifeTimeBookings": "Life time bookings",
                "inReview": "In review",
                "activated": "Activated"
            },
            "driverWithdrawal": {
                "transferToEWallet": "Transferred to external e-wallet *{0}",
                "channelCode": "Channel code",
                "channelName": "Channel name",
                "reversed": "Reversed",
                "currentBalanceTooltip": "The current balance of driver's cash wallet before withdrawal request",
                "paymentTime": "Payment time",
                "requestTime": "Request time",
                "referenceId": "Reference #",
                "transactionId": "Transaction ID",
                "description": "Description",
                "transferAmount": "Transfer amount",
                "currency": "Currency",
                "approved": "Approved",
                "rejected": "Rejected",
                "failed": "Failed",
                "reason": "Reason"
            },
            "penaltyCompensation": {
                "penaltyAmount": "Penalty",
                "totalCollect": "Need to pay in local currency",
                "totalCollectInUSD": "Need to pay in USD",
                "compensationAmount": "Compensate",
                "compensationAmountInUSD": "Compensate (USD)",
                "penaltyReason": "Penalty Reason",
                "estimatedPrice": "Estimated price",
                "estimatedPriceInUSD": "Estimated price (USD)",
                "penaltyAmountInUSD": "Penalty (USD)",
                "amount": "Amount",
                "amountInUSD": "Amount (USD)"
            },
            "imcompletePayment": {
                "bookingId": "Booking ID",
                "bookFrom": "Booking from",
                "bookingTime": "Date/Time Booking",
                "completedTime": "Date/Time Booking Completed",
                "customerName": "Customer name",
                "requestedPaymentMethod": "Requested payment method",
                "originalPaymentStatus": "Original payment status",
                "currentPaymentStatus": "Current payment status",
                "updatedDateTime": "Date/Time Balance collected",
                "totalAmt": "Total amt",
                "originalOutstandingAmount": "Original outstanding amount",
                "currentOutstandingAmount": "Current outstanding amount",
                "outstandingAmountPaidBy": "Outstanding amount collect by",
                "writeOffDebtMethod": "Outstanding amount collect by",
                "reason": "Reason",
                "customerPhone": "Phone number",
                "amountCollected": "Amount collected",
                "partialPaymentAmount": "Partial payment when drop off"
            },
            "driverDeposit": {
                "dateAndTime": "Date & time",
                "userName": "Username",
                "type": "Type",
                "newAmount": "New balance",
                "topUpGCashReason": "Top up by Gcash",
                "topUpReason": "Top up from credit card *{0}",
                "cashWalletReason": "Top up from Cash Wallet",
                "topUpMOLPayReason": "Razer Pay [ {0} ]"
            },
            "refund": {
                "fullRefund": "Full refund",
                "partialRefund": "Partial refund",
                "tripId": "Trip ID",
                "route": "Route",
                "refundAmount": "Refund amount",
                "refundTime": "Refund time",
                "paymentStatus": "Payment status"
            },
            "merchant": {
                "store": "Store",
                "name": "Name",
                "phoneNumber": "Phone number",
                "bookingId": "Booking #",
                "totalOrders": "Total orders",
                "completedOrders": "Completed orders",
                "canceledOrders": "Canceled orders",
                "incidentOrders": "Incident",
                "revenue": "Revenue",
                "commission": "Commission",
                "earning": "Earning",
                "food": "Food",
                "mart": "Mart",
                "preferredMerchant": "Preferred merchant",
                "regularMerchant": "Regular merchant",
                "companyName": "Company"
            },
            "merchantCashTransactionHistory": {
                "companyName": "Company",
                "dateTime": "Date & time",
                "merchantUserName": "Merchant user",
                "merchantUserPhone": "Phone number",
                "merchantUserEmail": "Email",
                "merchantName": "Merchant",
                "transactionType": "Type",
                "bookId": "Booking #",
                "transactionId": "Transaction ID",
                "operatorName": "Operator",
                "reason": "Reason",
                "amount": "Amount",
                "newBalance": "New balance",
                "currencyISO": "Currency"
            },
            "merchantCreditTransactionHistory": {
                "dateTime": "Date & time",
                "merchantUserName": "Merchant user",
                "merchantUserPhone": "Phone number",
                "merchantUserEmail": "Email",
                "merchantName": "Merchant",
                "transactionType": "Type",
                "bookId": "Booking #",
                "transactionId": "Transaction ID",
                "operatorName": "Operator",
                "reason": "Reason",
                "amount": "Amount",
                "newBalance": "New balance",
                "currencyISO": "Currency"
            },
            "deleteAccountRequest": {
                "date": "Date",
                "phone": "Phone",
                "username": "Username",
                "supportId": "Support ID",
                "requestFrom": "Request from",
                "associatedApps": "Associated apps",
                "reason": "Reason"
            },
            "affiliationPayoutHistory": {
                "payoutId": "Payment ID",
                "dateTime": "Date & time",
                "payoutType": "Type",
                "accountHolderName": "Account holder's name",
                "bankName": "Bank name",
                "accountNumber": "Account number",
                "totalPayout": "Total payment (USD)",
                "viewDetails": "View details",
                "notes": "Note",
                "bookId": "Booking #",
                "transactionType": "Transaction type",
                "transactionId": "Transaction Id (auto transfer)",
                "settlement": "Settlement",
                "paymentMethod": "Type",
                "networkType": "Network type"
            },
            "thirdPartyBooking": {
                "date": "Date",
                "bookingId": "Booking ID",
                "externalId": "External ID",
                "psgPhone": "Passenger phone number",
                "psgName": "Passenger name",
                "pickupLocation": "Pickup location",
                "pickupLocationLatLong": "Pickup location lat/long",
                "dropoffLocation": "Dropoff location",
                "dropoffLocationLatLong": "Dropoff location lat/long",
                "pickupTime": "Pickup time",
                "numOfPassenger": "Num of passenger",
                "action": "Action"
            }
        }
    },
    "zoneSetting": {
        "Add": "Add",
        "Import": "Import",
        "Name": "Name",
        "Status": "Status",
        "Active": "Active",
        "Inactive": "Inactive",
        "Deactivate": "Deactivate",
        "Activate": "Activate",
        "Delete": "Delete",
        "Cancel": "Cancel",
        "Input_zone_name": "Input zone name",
        "Save_zone": "Save zone",
        "Clear_draw": "Clear draw",
        "Edit": "Edit",
        "Delete_zone_success": "Zone hase been deleted successfully",
        "Delete_zone_fail": "The errors occurred while deleting zone",
        "Update_zone_fail": "The errors occurred while updating zone",
        "Update_zone_success": "Zone hase been updated successfully",
        "Create_zone_fail": "The errors occurred while creating zone",
        "Create_zone_success": "New zone hase been created successfully",
        "Draw_zone": "Please draw zone on map",
        "Import_zones_successful": "Import zones successful",
        "Import_zones_fail": "Failed to import zones ",
        "DELETE_ZONE": "Delete Zone",
        "ZONE_CONFIRM": "Are you sure you want to delete the selected zone ?",
        "Name_zone_required": "Name and Zone are required!",
        "Select_file": "Select file",
        "No": "No",
        "Yes": "Yes",
        "Import_zones_intercity_successful": "[{0}] zone(s) has been imported successfully, [{1}] failed.",
        "Import_zones_intercity_successful_failed": "[{0}] zone(s) has been imported successfully, [{1}] failed.",
        "Flat_zone": "Flat zone",
        "Json_file": "Json file",
        "Import_zones": "Import zones",
        "timezone": "Time Zone"
    },
    "companySetting": {
        "DELETE_COMPANY": "Delete company",
        "DELETE_CONFIRM": "Do you want to delete company?",
        "DETAIL_COMPANY": "Detail company",
        "commissionValue": "Company commission",
        "connectStatus": "Stripe Connect",
        "connectStripe": "Connect",
        "stripeConnectId": "Stripe account ID",
        "loginStripe": "Dashboard",
        "disconnectStripe": "Disconnect",
        "Yes": "Yes",
        "No": "No",
        "Cancel": "Cancel",
        "EDIT_COMPANY": "EDIT COMPANY SUPPLIER",
        "ADD_COMPANY": "ADD COMPANY SUPPLIER",
        "Name": "Name",
        "Required_fields": "Required fields",
        "Save": "Save",
        "Actions": "Actions",
        "Edit": "Edit",
        "Add": "Add",
        "Show_per_page": "Items per page",
        "Page": "Page",
        "show_pages": "{0} - {1} of {2} shown",
        "Create_company_success": "New company has been created successfully",
        "Create_company_fail": "The errors occurred while creating company",
        "Update_company_success": "Company has been updated successfully",
        "Update_company_fail": "The errors occurred while updating company",
        "Load_company_fail": "The errors occurred while loading company",
        "Delete_company_success": "Company has been deleted successfully",
        "Delete_company_fail": "The errors occurred while deleting company",
        "hideFare": "Always hide fare",
        "Broadcast_orders": "Broadcast all orders",
        "paymentMethodsApply": "Support payment method",
        "emailsNotification": "Emails Notification",
        "CopySignUpLink": "Copy sign up link",
        "operationZone": "Operation Zone",
        "delayBroadcast": "Show order after",
        "releaseTime": "Release Time",
        "advanceBookingVisibility": "Advance booking visibility",
        "advanceBookingVisibilityTooltip": "Set the number of days before the pickup date that a supplier can see the booking. This ensures that bookings are released to drivers in a timely manner, based on each supplier."
    },
    "dispatchSetting": {
        "GENERAL": "General",
        "Offer_interval": "Offer interval (driver)",
        "Offer_interval_merchant": "Offer interval (merchant)",
        "Offer_interval_merchant_toolTip": "Maximum time for a merchant to accept a order",
        "Offer_interval_toolTip": "Maximum time for a driver to accept a booking",
        "soundMarketplace": "Sound",
        "sound": "Sound",
        "Auto_re_dispatch_interval_toolTip": "Break time between 2 rounds of dispatching",
        "Maximum_auto_dispatch_duration_toolTip": "Maximum time of dispatching a booking to drivers",
        "Seconds": "Seconds",
        "Auto_re_dispatch_interval": "Auto re-dispatch interval",
        "Maximum_auto_dispatch_duration": "Maximum auto-dispatch duration",
        "Time_out": "Time out",
        "mins": "mins",
        "minTimeBeforeReservation": "Do not allow Driver to start reservation before pickup time",
        "IN_ADVANCE": "IN ADVANCE",
        "Assign_reservation_to_driver": "Assign reservation to driver",
        "Dispatcher_confirms_job_for_driver": "Dispatcher confirms job for driver",
        "Dispatcher_confirms_job_for_offline_driver": "Dispatcher confirms reservation for offline driver",
        "Broadcast_orders": "Broadcast orders to all companies",
        "Broadcast_orders_tooltip_1": "Enable this setting if you just want to broadcast all trips to all company suppliers. \n Keep in mind you need to enable broadcast mode on in each company’s profile.",
        "Minimum_book_ahead": "Minimum book ahead",
        "Maximum_book_ahead": "Maximum book ahead - single order",
        "Maximum_period_recurring_oders_fleet": "Maximum period for recurring orders (fleet operator)",
        "Maximum_period_recurring_oders_corp": "Maximum period for recurring orders (corporate)",
        "Day": "Day",
        "Week": "Week",
        "Month": "Month",
        "hrs": "hrs",
        "Driver_cancellation_duration": "Driver cancellation duration",
        "Mins": "mins",
        "Maximum_Day": "Maximum reservations driver can accept in a day.",
        "Maximum_Accept_Reservation": "Total number of reservations a driver can accept at a time.",
        "Local_notification_before": "Local notification before",
        "Dispatch_mode": "Dispatch mode",
        "Manual": "Manual",
        "Immediate_auto_dispatch": "Immediate auto-dispatch",
        "Delayed_auto_dispatch": "Delayed auto-dispatch",
        "Auto_dispatch_before_pickup_time": "Auto dispatch before pickup time",
        "Required_fields": "Required fields",
        "Shuttle": "SHUTTLE",
        "Shuttle_Dispatch_mode": "Dispatch mode",
        "Dispatch_setting_Shuttle_Dispatch_mode_tooltip": "- Manual mode: Reservation will be saved and stayed on Cue of your Command Center under “Pending” status once reservation is created. After clicking on “Update booking” at the booking detail, reservation will be dispatched to your assigned drivers or to all drivers in case of no driver assigning. It will be integrated into driver device calendar and reminder will be available. <br>- Delayed mode:Reservation will be saved and stayed on Cue of your Command Center under “Pending” status once reservation is created. Reservation will be automatically dispatched before “time value at “Process in advance”” from pick up time to the assigned driver or the nearest available one in case of no driver assigning. <br>- Immediate auto-dispatch mode: Reservation will be automatically dispatched to all drivers once reservation is created. It will be integrated into driver device calendar and reminder will be available.",
        "Save": "Save",
        "Process_in_advance": "Process in advance",
        "Save_dispatch_success": "Dispatch has been updated successfully",
        "Save_dispatch_fail": "There were errors when updating dispatch",
        "Dispatch_setting_general_tooltip": "Apply to booking on demand, booking in advance with delayed auto-dispatch",
        "Dispatch_setting_Assign_reservation_to_driver_tooltip": "Reservation is assigned and accepted for driver automatically when this option is enable",
        "Dispatch_setting_Driver_cancellation_duration_tooltip": " Duration is from the time when the pickup time to driver cancels the booking",
        "Dispatch_setting_Dispatch_mode_tooltip": "- Manual mode: Reservation will be saved and stayed on Cue of your Command Center under “Pending” status once reservation is created. After clicking on “Update booking” at the booking detail, reservation will be dispatched to your assigned drivers or to all drivers in case of no driver assigning. It will be integrated into driver device calendar and reminder will be available. <br>- Delayed mode:Reservation will be saved and stayed on Cue of your Command Center under “Pending” status once reservation is created. Reservation will be automatically dispatched before “time value at “Process in advance”” from pick up time to the assigned driver or the nearest available one in case of no driver assigning. <br>- Immediate auto-dispatch mode: Reservation will be automatically dispatched to all drivers once reservation is created. It will be integrated into driver device calendar and reminder will be available.",
        "Notification_nearby_drivers_before_pickup_time": "Notify nearby drivers before pickup time",
        "Dispatch_setting_Maximum_Day_tooltip": "This is to limit drivers from having too many reservations on the same day. Applicable for Marketplace and Intercity.",
        "Maximum_Accept_Reservation_tooltip": "The number of accepted reservations by driver cannot exceed this number. This limits the driver from accepting too many at a time. Applicable for Marketplace and Intercity.",
        "Notification_nearby_drivers_before_pickup_time_tooltip": "If a customer's reservation has not been accepted by any driver before the set time, before pickup time, the system will notify drivers nearby. This will hopefully prompt them to check marketplace to accept the booking.",
        "marketPlace": "MARKET PLACE",
        "howFarDriverSeeBookingMarketplace": "How far out the driver can see bookings in the marketplace",
        "shortNoticeOrder": "Short notice order",
        "shortNoticeOrderTooltip": "Set the time frame for bookings to be considered urgent and visible to all tiers immediately."
    },
    "voiceSMSSetting": {
        "ACCOUNT_SETTINGS": "Account settings",
        "Account_SID": "Account SID",
        "Auth_Token": "Auth Token",
        "Application_ID": "Application ID",
        "SMS_number_1": "SMS number 1",
        "SMS_number_2": "SMS number 2",
        "SMS_SETTINGS": "SMS SETTINGS",
        "MASKED_PHONE_SETTINGS": "MASKED PHONE SETTINGS",
        "SMS_MARKETING_SETTINGS": "SMS MARKETING SETTINGS",
        "Phone_number": "Phone number",
        "Save": "Save",
        "voiceSMS": "TWILIO",
        "DRIVER_APP": "Driver App",
        "PASSENGER_APP": "Passenger App",
        "allowDriverToCallCC": "Allow Driver to call Helpdesk via:",
        "allowDriverToCallHelpdesk": "Allow Driver to call Helpdesk via:",
        "allowDriverToCallPassenger": "Allow Driver to call Passenger via:",
        "allowPassengerToCallCC": "Allow Passenger to call Helpdesk via:",
        "allowPassengerToCallHelpdesk": "Allow Passenger to call Helpdesk via:",
        "allowPassengerToCallDriver": "Allow Passenger to call Driver via:",
        "voip": "VOIP",
        "mobile": "Mobile",
        "bookingSMS": "Message template",
        "bookingSMSTemplate": {
            "name": "Name",
            "content": "Content",
            "action": "Action",
            "DELETE": "Delete Message template",
            "DELETE_CONFIRM": "Are you sure you want to delete the selected Message template?",
            "EDIT": "Edit Message template",
            "ADD": "Add Message template",
            "DETAIL": "Detail Message template",
            "Update_success": "The message template has been updated successfully",
            "Create_success": "The message template has been created successfully",
            "Delete_success": "The message template has been deleted successfully",
            "Delete_fail": "The errors occurred while deleting Message template",
            "Load_fail": "The errors occurred while loading Message template",
            "Update_fail": "The errors occurred while updating Message template",
            "Create_fail": "The errors occurred while creating Message template"
        },
        "bookingSMSModal": {
            "title": "Add SMS template",
            "save": "Save",
            "cancel": "Cancel",
            "titleMessage": "Send Message",
            "template": "Template",
            "phoneNumber": "Phone number",
            "content": "Content",
            "messageType": "Message type",
            "inbox": "Inbox",
            "sms": "SMS",
            "sendTo": "Sent to",
            "passenger": "Passenger",
            "driver": "Driver"
        }
    },
    "regularSetting": {
        "extraWaitTime": "Extra wait time",
        "extraWaitTimeTooltip": "Fee to compensate the driver when he has to wait additional time for the customer till they are onboard.",
        "extraWaitTimeTooltip2": "To set the maximum wait time, go to general settings.",
        "fareWaitTimeLater": "Fare per minute (Reservation)",
        "fareWaitTimeNow": "Fare per minute (Now)",
        "serviceType": "Service Type",
        "transport": "Transport",
        "delivery": "Delivery",
        "Fer_Stop": "Fee fer stop",
        "First_Stop": "Fee for first stop",
        "Each_Stop": "Fee for each extra stop",
        "Name": "Name",
        "Pay_to_driver_Cash": "Pay to Supplier/Driver (Cash booking)",
        "Last_Update": "Last Update",
        "Status": "Status",
        "Required_fields": "Required fields",
        "Yes": "Yes",
        "No": "No",
        "Cancel": "Cancel",
        "Save": "Save",
        "Actions": "Actions",
        "Edit": "Edit",
        "Add": "Add",
        "Show_per_page": "Items per page",
        "Page": "Page",
        "show_pages": "{0} - {1} of {2} shown",
        "DELETE_CONFIRM": "Do you want to delete?",
        "ADD_REGULAR_RATE": "Add regular rate",
        "EDIT_REGULAR_RATE": "Edit regular rate",
        "DETAIL_REGULAR_RATE": "Detail regular rate",
        "DELETE_REGULAR_RATE": "Delete regular rate",
        "Delete_confirm_regular_rate": "Do you want to delete this item?",
        "STARTING": "Starting",
        "Starting_Now": "Starting (Now)",
        "Starting_Reservation": "Starting (Reservation)",
        "FIRST_DISTANCE_FEE": "First distance fee",
        "First_distance_fee": "First distance",
        "From": "From",
        "To": "To",
        "Fee_for_first_distance": "Fee for first distance",
        "SECOND_DISTANCE_FEE": "Second distance fee",
        "Second_distance_fee": "Second-distance",
        "AFTER_SECOND_DISTANCE": "After second distance",
        "Fee_after_second_distance": "Fee after second distance",
        "PER_MINUTE": "Per minute",
        "Fee_per_minute": "Fare per minute",
        "mi": "mi",
        "hour": "hour",
        "MINIMUM": "Minimum",
        "Minimum_Now": "Minimum (Now)",
        "Minimum_Reservation": "Minimum (Reservation)",
        "PAX_MDISPATCHER_CANCELLATION": "Pax/ partner cancellation",
        "On_demand": "Pax/partner cancellation - On demand",
        "Penalty": "Penalty amount",
        "In_advance": "Pax/partner cancellation - In advance",
        "PAX_MDISPATCHER_NO_SHOW": "Pax/ partner no show",
        "Driver_cancel_demand": "Driver cancel policy - on demand",
        "Driver_cancel_advance": "Driver cancel policy - in advance",
        "Assign_car_type": "Assign car type",
        "Select_car_type": "Select car type",
        "Active": "Active",
        "Inactive": "Inactive",
        "Deactivate": "Deactivate",
        "DELETE_FLATE_RATE": "Delete flate rate",
        "Delete_confirm_flat_rate": "Do you want to delete this item?",
        "EDIT_FLAT_RATE": "Edit flat rate",
        "ADD_FLAT_RATE": "Add flat rate",
        "DELETE_FLAT_RATE": "Delete flat rate",
        "DELETE_HOURLY_RATE": "Delete hourly/ daily rate",
        "Delete_confirm_hour_rate": "Do you want to delete this item?",
        "EDIT_HOURLY_RATE": "Edit hourly/ daily rate",
        "ADD_HOURLY_RATE": "Add hourly/ daily rate",
        "DELETE_FLAT_ROUTE": "Delete flat route",
        "DELETE_ROUTE_CONFIRM": "Are you sure to delete route?",
        "EDIT_ROUTE": "Edit route",
        "ADD_ROUTE": "Add route",
        "Route_name": "Route name",
        "Route_type": "Route Type",
        "Zone_to_zone": "Zone to zone",
        "Zipcode_to_zipcode": "Zipcode to zipcode",
        "First_location": "First location",
        "Second_location": "Second location",
        "First_location_zip_note": "*Notes: You can enter many zip codes at the same time by using ',' symbol. For consecutive codes, please use '->' symbol instead of inputting one by one ( ex: 90001 -> 90099, 90101, 90102, 90103, 90174, 90185)",
        "Second_location_zip_note": "*Notes: You can enter many zip codes at the same time by using ',' symbol. For consecutive codes, please use '->' symbol instead of inputting one by one ( ex: 90001 -> 90099, 90101, 90102, 90103, 90174, 90185)",
        "SINGLE_TRIP_SETUP": "Single trip setup",
        "Route_1": "Route 1 (First location -> Second location)",
        "Route_2": "Route 2 (Second location -> First location)",
        "Limitation": "Limitation",
        "Unlimited": "Unlimited",
        "Limited": "Limited",
        "Time_coverage": "Time coverage",
        "Extra_time": "Extra time",
        "ROUND_TRIP_SETUP": "Round trip setup",
        "Round_trip": "Round trip",
        "ROUTES": "ROUTES",
        "Route_1_Fare": "Route 1 Fare",
        "Route_2_Fare": "Route 2 Fare",
        "Round_Trip_Fare": "Round Trip Fare",
        "Select_a_zone": "Select a zone",
        "minute": "minute",
        "VIEW_REGULAR_RATE": "View detail regular rate",
        "VIEW_FLAT_RATE": "View flat rate",
        "VIEW_HOURLY_RATE": "View hourly rate",
        "HOURLY_PACKAGE_RATE": "Hourly package rate",
        "Package_name": "Package name",
        "Package_duration": "Package duration",
        "PackageId": "Package ID",
        "Base_rate": "Base rate",
        "Distance_coverage": "Distance coverage",
        "Extra_duration": "Extra duration",
        "Extra_distance": "Extra distance",
        "DELETE_HOURLY_PACKAGE": "Delete hourly package",
        "DELETE_PACKAGE_CONFIRM": "Do you want to delete package rate?",
        "EDIT_PACKAGE": "Edit package",
        "VIEW_PACKAGE": "View package detail",
        "ADD_PACKAGE": "Add package",
        "Fee_per_extra_duration": "Fee per extra duration",
        "Fee_per_extra_distance": "Fee per extra distance",
        "hours": "hour(s)",
        "days": "day(s)",
        "Update_flat_rate_success": "Flat rate has been updated successfully",
        "Update_flat_rate_fail": "The errors occurred while updating flat rate",
        "Created_flat_rate_success": "Flat rate has been created successfully",
        "Created_flat_rate_fail": "The errors occurred while creating flat rate",
        "Update_flat_rate_route_success": "Flat rate route has been updated successfully",
        "Update_flat_rate_route_fail": "The errors occurred while updating flat rate route",
        "Created_flat_rate_route_success": "Flat rate route has been created successfully",
        "Created_flat_rate_route_fail": "The errors occurred while creating flat rate route",
        "Loading_flat_rate_fail": "The errors occurred while loading flat rate detail",
        "Delete_flat_rate_route_fail": "The errors occurred while deleting flat rate route",
        "Delete_flat_rate_success": "Flat rate has been deleted successfully",
        "Delete_flat_rate_fail": "The errors occurred while deleting flat rate",
        "Update_intercity_rate_success": "Intercity rate has been updated successfully",
        "Update_intercity_rate_fail": "The errors occurred while updating intercity rate",
        "Created_intercity_rate_success": "Intercity rate has been created successfully",
        "Created_intercity_rate_fail": "The errors occurred while creating intercity rate",
        "Update_intercity_rate_route_success": "Intercity rate route has been updated successfully",
        "Update_intercity_rate_route_fail": "The errors occurred while updating intercity rate route",
        "Created_intercity_rate_route_success": "Intercity rate route has been created successfully",
        "Created_intercity_rate_route_fail": "The errors occurred while creating intercity rate route",
        "Loading_intercity_rate_fail": "The errors occurred while loading intercity rate detail",
        "Delete_intercity_rate_route_fail": "The errors occurred while deleting intercity rate route",
        "Delete_intercity_rate_success": "Intercity rate has been deleted successfully",
        "Delete_intercity_rate_fail": "The errors occurred while deleting intercity rate",
        "Update_regular_rate_success": "Regular rate has been updated successfully",
        "Update_regular_rate_fail": "The errors occurred while updating regular rate",
        "Create_regular_rate_success": "Regular rate has been created successfully",
        "Create_regular_rate_fail": "The errors occurred while creating regular rate",
        "Update_hourly_rate_success": "Hourly rate has been updated successfully",
        "Update_hourly_rate_fail": "The errors occurred while updating hourly rate",
        "Create_hourly_rate_success": "Hourly rate has been created successfully",
        "Create_hourly_rate_fail": "The errors occurred while creating hourly rate",
        "Update_hourly_rate_package_success": "Hourly rate package has been updated successfully",
        "Update_hourly_rate_package_fail": "The errors occurred while updating hourly rate package",
        "Create_hourly_rate_package_success": "Hourly rate package has been created successfully",
        "Create_hourly_rate_package_fail": "The errors occurred while creating hourly rate package",
        "Load_hourly_rate_fail": "The errors occurred while loading hourly rate details",
        "Update_hourly_status": "The errors occurred while updating hourly rate status",
        "Delete_hourly_fail": "The errors occurred while deleting hourly rate",
        "Delete_hourly_success": "Hourly rate has been deleted successfully",
        "Loading_regular_rate_fail": "The errors occurred while loading regular rate",
        "Update_regular_status_fail": "The errors occurred while updating regular rate status",
        "Delete_regular_rate_success": "Regular rate has been deleted successfully",
        "Delete_regular_rate_fail": "The errors occurred while deleting regular rate",
        "Please_go_to_Car_Type_settings": "To apply this rate to any car type, please go to Settings > Car > Car Type.",
        "Car_type_filter": "Car Type Filter",
        "Delete_package_error": "There was an error when deleting the package!",
        "Pax_mDispatcher_cancellation_tooltip": "Cancellation policy applied for passengers",
        "Penalty_tooltip": "The amount customer will be charged. In addition to the penalty you can add tax or tech fee.",
        "Pax_mDispatcher_no_show_tooltip": "No show policy applied for passengers. This fee will be charged if drivers tap \"No show\" when they do not see passengers at the pickup location.",
        "detail_route": "Detail route",
        "detail_package": "Detail Package",
        "THIRD_DISTANCE_FEE": "Third distance fee",
        "AFTER_DISTANCE_FEE": "After third distance fee",
        "Loading_delivery_rate_fail": "The errors occurred while loading delivery rate detail",
        "Update_delivery_rate_fail": "The errors occurred while updating delivery rate",
        "Update_delivery_rate_success": "Delivery rate has been updated successfully",
        "Created_delivery_rate_success": "Delivery rate has been created successfully",
        "Created_delivery_rate_fail": "The errors occurred while creating delivery rate",
        "Starting_fee": "Starting fee",
        "First_distance": "First distance",
        "Second_distance": "Second distance",
        "Third_distance": "Third distance",
        "Fee_for_second_distance": "Fee for second distance",
        "Fee_for_third_distance": "Fee for third distance",
        "Fee_after_third_distance": "Fee after third distance",
        "Assign_rate_to_car_type": "Assign rate to cartype",
        "car_type": "Cartype",
        "PRICE_ADJUSTABLE": "Price adjustable",
        "minimum_Percent_Adjust": "Minimum % from estimated fare",
        "maximum_Percent_Adjust": "Maximum % from estimated fare",
        "Amount_Step": "Price adjustable",
        "Tax": "Tax",
        "TechFee": "Tech fee",
        "Pay_to_driver": "Pay to Supplier/Driver",
        "Pay_to_driver_tooltip": "Options to compensate drivers:\n - Fixed amount: A fixed amount the driver will receive.\n - Commission: Driver’s set commission applied to the penalty amount.",
        "Fix_amount": "Fixed amount",
        "Commission": "Commission",
        "fareFlat": "Fare flat",
        "flatRoute": "Flat route",
        "Mode": "Mode",
        "speedThresholdKM": "Speed threshold (km/h)",
        "speedThresholdMI": "Speed threshold (mi/h)",
        "timeAndMileage": "Time and mileage",
        "timeOrMileage": "Time or mileage",
        "perDistanceKM": "Per kilometer",
        "perDistanceMI": "Per mile",
        "perTime": "Per minute"
    },
    "flatRateSettings": {
        "flatZoneNote": "The zones you create here are only used to setup your flat rates."
    },
    "deliverySetting": {
        "Name": "Name",
        "Last_Update": "Last Update",
        "Status": "Status",
        "Actions": "Actions"
    },
    "sharingSetting": {
        "EDIT_RATE": "Edit street sharing rate",
        "ADD_RATE": "Add street sharing rate",
        "VIEW_RATE": "View street sharing rate",
        "Name": "Name",
        "startingFee": "Starting fare",
        "minimumFee": "Minimum fare",
        "Update_streetSharing_rate_fail": "The errors occurred while updating street sharing rate",
        "Update_streetSharing_rate_success": "Street sharing rate has been updated successfully",
        "Created_streetSharing_rate_success": "Street sharing rate has been created successfully",
        "Created_streetSharing_rate_fail": "The errors occurred while creating street sharing rate",
        "Loading_streetSharing_rate_fail": "The errors occurred while loading street sharing rate detail",
        "Delete_streetSharing_rate_success": "Street sharing rate has been deleted successfully",
        "Delete_streetSharing_rate_fail": "The errors occurred while deleting street sharing rate",
        "Delete_streetSharing_rate": "Delete street sharing rate",
        "Assign_Car": "Assign car type"
    },
    "carTypeSetting": {
        "newService": "New Service",
        "editService": "Edit Service",
        "serviceInfo": "Service Info",
        "name": "Name",
        "namePH": "Car-type name",
        "enterNumber": "Enter a number",
        "appDisplayName": "App display name",
        "shortDescription": "Short description",
        "maxPassengers": "Max passengers",
        "maxLuggage": "Max luggages",
        "vehiclePhoto": "Vehicle Photos",
        "iconShowList": "Icon to show in list",
        "imageShowWeb": "Image to show on web",
        "carPinOnMap": "Car PIN on map",
        "recommendedSize": "Recommended size:",
        "serviceSetting": "Service Settings",
        "serviceType": "Service type",
        "reservation": "Reservation",
        "onDemand": "On-demand",
        "destinationSetting": "Destination setting",
        "requireDestination": "Require destination",
        "extraDestination": "Extra destination",
        "calculatorFareMode": "Calculate fare mode",
        "hideDestinationOnDemand": "On-demand",
        "meteredFare": "Metered fare",
        "quotedFare": "Quoted fare",
        "availableZones": "Available Zones",
        "dispatchAssociation": "Dispatch Association",
        "Save": "Save",
        "Edit": "Edit",
        "Cancel": "Cancel",
        "validateName": "Please input name",
        "validateAppDisplayName": "Please input app display name",
        "validateSelectImage": "Please select an image",
        "updateSuccess": "Update successfully",
        "createSuccess": "Create successfully",
        "failApi": "Something went wrong!",
        "validateRequire": "Please specify passenger count and double-check luggage."
    },
    "carSetting": {
        "Details": "Details",
        "vehicleInfo": "Vehicle Info",
        "Documents": "Documents",
        "vehicle": "Vehicle",
        "addVehicle": "Add Vehicle",
        "detailVehicle": "Vehicle Details",
        "additionalVehicleTitle": "Additional Vehicle Information",
        "Phone_Number_Tooltip": "Enter a vehicle-specific phone number for customer use. If not provided, calls will default to the driver’s personal number. Ideal for suppliers who equip vehicles with dedicated contact phones.",
        "discardChange": "Discard changes",
        "model_name_is_required": "Model name is required",
        "DELETE_CAR": "Delete car",
        "DELETE_CONFIRM": "Do you want to delete selected car?",
        "Yes": "Yes",
        "Cancel": "Cancel",
        "EDIT_CAR": "Edit car",
        "CREATE_CAR": "Create car",
        "Plate_Number": "Car Registration Number",
        "Car_Type": "Car type",
        "Select_car_type": "Select car type",
        "Make": "Make",
        "Select_car_make": "Select car make",
        "Model": "Model",
        "Select_a_car_model": "Select a car model",
        "Year": "Production Year",
        "Select_year": "Select year",
        "Hardware_Meter": "Hardware Meter",
        "VIN": "Vehicle identification number (VIN)",
        "Company": "Company",
        "Select_company": "Select company",
        "Max_Passengers": "Max Passengers",
        "Max_Luggage": "Max Luggage",
        "Color": "Color",
        "License_expiry": "Road tax expiry",
        "Required_fields": "Required fields",
        "Save": "Save",
        "Add": "Add",
        "Car_Model": "Car Model",
        "Created_Date": "Created Date",
        "Status": "Status",
        "Actions": "Actions",
        "Show_per_page": "Items per page",
        "Page": "Page",
        "show_pages": "{0} - {1} of {2} shown",
        "Active": "Active",
        "activeVehicle": "Active vehicle",
        "Inactive": "Inactive",
        "Edit": "Edit",
        "Deactivate": "Deactivate",
        "Activate": "Activate",
        "Delete": "Delete",
        "Name": "Name",
        "MODEL_LIST": "Model list",
        "Model_Name": "Model name",
        "Add_Model": "Add model",
        "DELETE_CAR_MODEL": "Delete car model",
        "DELETE_CONFIRM_MODEL": "Do you want to delete car model?",
        "DELETE_CAR_MAKE": "Delete car make",
        "DELETE_CONFIRM_CAR_MAKE": "Do you want to delete this car make?",
        "EDIT_CAR_MAKE": "Edit car make",
        "CREATE_CAR_MAKE": "Add car make",
        "Assign_Rate": "Assign Rate",
        "ASSIGN_RATE": "Assign rate",
        "Car_type_ID": "Car type ID",
        "App_Display_Name": "App Display Name",
        "Affiliate_car_type": "Affiliate Car Type",
        "Regular_rate": "Regular rate",
        "Zone": "Zone",
        "Rate": "Rate",
        "Zone_Rate": "Zone - Rate",
        "Flat_Fare": "Flat Rate",
        "Flat_Delivery": "Delivery flat",
        "Select_flat_rate": "Select flat rate",
        "Hourly_daily_rate": "Hourly/ Daily",
        "Dispatch_To": "Dispatch To",
        "Normal_Fare": "Regular",
        "Upload_an_image": "Upload an image",
        "DETAIL_CAR": "Car detail",
        "DELETE_MULTI_CAR": "Delete selected cars",
        "DELETE_MULTI_CAR_CONFIRM": "Do you want to delete these vehicles ,Just only vehicles in the inactive status can be delete?",
        "DETAIL_CAR_MAKE": "Car Make",
        "Car_Make": "Car Make",
        "Create_car_success": "New car has been created successfully",
        "Update_car_success": "This car has been updated successfully.",
        "Update_car_fail": "The errors occurred while updating car",
        "Create_car_fail": "The errors occurred while creating car",
        "Load_car_fail": "The errors occurred while loading car",
        "Delete_car_fail": "The errors occurred while deleting car",
        "Delete_car_success": "Car has been deleted successfully",
        "Change_cars_status_success": "All car statuses have been changed successfully",
        "Change_cars_status_fail": "The errors occurred while changing car statuses",
        "Delete_cars_success": "All selected cars have been deleted successfully",
        "Delete_cars_fail": "The errors occurred while deleting cars",
        "Update_car_type_success": "Car type has been updated successfully",
        "Update_car_type_fail": "The errors occurred while updating car type",
        "Load_car_type_details_fail": "Could not load car type details",
        "Update_car_make_fail": "The errors occurred while updating car make",
        "Update_car_make_success": "Car make has been updated successfully",
        "Create_car_make_fail": "The errors occurred while creating car make",
        "Create_car_make_success": "New car make has been created successfully",
        "Update_car_model_fail": "The errors occurred while updating car model",
        "Update_car_model_success": "Car model has been updated successfully",
        "Create_car_model_fail": "The errors occurred while creating car model",
        "Create_car_model_success": "New car model has been created successfully",
        "Loading_car_model_fail": "The errors occurred while loading car model",
        "Delete_car_make_success": "Car make has been deleted successfully",
        "Delete_cat_make_fail": "The errors occurred while deleting car make",
        "Delete_regular_rate_success": "Delete Regular rate successfull",
        "Delete_regular_rate_fail": "Delete Regular rate error",
        "Update_status_error": "Update status error",
        "Case_number": "Case number",
        "Phone_Number": "Phone Number",
        "ERROR_INPUT_VALIDPHONE": "Please enter a valid phone number.",
        "Car_type_filter": "Car type filter",
        "No": "No",
        "Intercity_Rate": "Intercity Rate",
        "Select_intercity_rate": "Select intercity rate",
        "Delivery_Rate": "Delivery Rate",
        "Select_delivery_rate": "Select delivery rate",
        "vehicleOwner": "Name of vehicle’s owner",
        "EDIT_CAR_COLOR": "Edit car color",
        "DETAIL_CAR_COLOR": "Car color detail",
        "CREATE_CAR_COLOR": "Add car color",
        "Car_Color": "Car color",
        "Update_car_color_fail": "The errors occurred while updating car color.",
        "Update_car_color_success": "Car color has been updated successfully.",
        "Create_car_color_fail": "The errors occurred while creating car color.",
        "Create_car_color_success": "New car color has been created successfully",
        "Delete_car_color_success": "Car color has been deleted successfully.",
        "Delete_car_color_fail": "The errors occurred while deleting car color.",
        "DELETE_CAR_COLOR": "Delete car color",
        "DELETE_CONFIRM_CAR_COLOR": "Do you want to delete this car color?",
        "sharing_Rate": "Street Sharing Rate",
        "bookingAffiliate": "Affiliate Booking.com car type",
        "streetSharing": "Street Sharing"
    },
    "operationSetting": {
        "Driver": "Driver",
        "Search_driver_name": "Search driver name",
        "Company": "Company",
        "License_plate": "Car Registration Number",
        "Select_license_plate": "Select car registration number",
        "Shift": "Shift",
        "Zone": "Zone",
        "Required_fields": "Required fields",
        "Save": "Save",
        "No": "No.",
        "Car_Type": "Car type",
        "Plate_Number": "Car Registration Number",
        "Unassign": "Unassign",
        "Please_choose_driver": "Please choose driver.",
        "Please_choose_company": "Please choose company.",
        "Please_license_plate": "Please choose car registration number.",
        "Please_choose_shift": "Please choose shift.",
        "Please_choose_zone": "Please choose zone.",
        "CONFIRM_UNASSIGN": "Do you want to unassigned this driver?",
        "Assign_success": "Operation has been assigned successfully",
        "Unassign_success": "Driver has been unassigned successfully."
    },
    "paymentMethod": {
        "cash": "Cash",
        "easyPaisa": "Easy Paisa",
        "jazzCash": "Jazz Cash",
        "cashBySender": "Cash by sender",
        "cashByRecipient": "Cash by recipient",
        "personalCard": "Personal card",
        "corporateCard": "Corporate card",
        "qrCodePayment": "QR code payment",
        "directBilling": "Direct Invoicing",
        "externalCard": "External card",
        "mDispatcherCard": "Partner card",
        "corporatePrepaid": "Corporate prepaid",
        "fleetCard": "External card",
        "applePay": "Apple pay",
        "creditExternalCard": "Credit card",
        "googlePay": "Google Pay",
        "wallet": "Wallet",
        "BankOfGeorgia": "Bank Of Georgia",
        "tnGeWalletlet": "TnG eWallet",
        "paymentMethod_1": "Cash",
        "paymentMethod_2": "Personal card",
        "paymentMethod_3": "Partner card",
        "paymentMethod_4": "Corporate card",
        "paymentMethod_5": "Direct Invoicing",
        "paymentMethod_6": "External card",
        "paymentMethod_7": "Corporate prepaid",
        "paymentMethod_8": "QR code payment",
        "paymentMethod_9": "Apple pay",
        "paymentMethod_11": "Airpay",
        "paymentMethod_12": "Credit card",
        "paymentMethod_13": "Wallet",
        "paymentMethod_14": "TnG eWallet",
        "paymentMethod_16": "Cash by sender",
        "paymentMethod_17": "Cash by recipient",
        "paymentMethod_19": "Boost eWallet",
        "paymentMethod_20": "Vipps",
        "paymentMethod_21": "ZainCash",
        "paymentMethod_23": "Google Pay",
        "paymentMethod_25": "Easy Paisa",
        "paymentMethod_26": "Jazz Cash",
        "vippseWallet": "Vipps",
        "paymentMethod_credit": "Personal card",
        "paymentMethod_razerPay": "RazerPay",
        "paymentMethod_gCash": "GCash",
        "paymentMethod_zainCash": "ZainCash",
        "paymentMethod_xenDit": "Xendit",
        "paymentMethod_FIB": "FIB",
        "paymentMethod_SantimPay": "SantimPay",
        "paymentMethod_ksher": "Ksher",
        "paymentMethod_paydunya": "PayDunya",
        "paymentMethod_fawry": "Fawry",
        "paymentMethod_BankOfGeorgia": "Bank Of Georgia",
        "paymentLink": "Payment link ",
        "paymentMethod_xendit": "Xendit",
        "paymentMethod_easyPaisa": "Easy Paisa",
        "paymentMethod_jazzCash": "Jazz Cash",
        "paymentMethod_flooz": "Flooz",
        "paymentMethod_tmoney": "Tmoney",
        "paymentMethod_corporateCard": "Corporate card"
    },
    "changePassword": {
        "Change_password": "Change password",
        "Current_password": "Current password",
        "New_password": "New password",
        "Confirm_password": "Confirm password",
        "ERROR_INPUT_PASSWORD": "Please input password",
        "ERROR_INPUT_VALID_PASSWORD": "Password must contain at least 8 characters, one numeric digit and a special character",
        "ERROR_INPUT_MATCH_PASSWORD": "Password does not match. Please try again",
        "Save": "Save",
        "Cancel": "Cancel"
    },
    "userProfile": {
        "My_account": "My account",
        "Username": "Username",
        "First_name": "Firstname",
        "Last_name": "Last name",
        "Phone_number": "Phone number",
        "Email_address": "Email address",
        "Address": "Address",
        "Save": "Save",
        "Edit_profile": "Edit profile",
        "Change_password": "Change password",
        "ERROR_INPUT_USERNAME": "Please input username.",
        "ERROR_INPUT_FIRSTNAME": "Please input first name.",
        "ERROR_INPUT_VALID_EMAIL": " Please enter valid email.",
        "Required_fields": "Required fields"
    },
    "General": {
        "searchQueuingDriver": "Search: Driver Name, Phone Number",
        "search": "Search",
        "User": "User",
        "My_Account": "My Account",
        "Sign_out": "Sign out",
        "My_site": "My site",
        "Command_center": "Command Center",
        "Notifications": "Notifications",
        "Show_all": "Show All",
        "SOS_Notifications": "SOS Notifications",
        "You_dont_have_any_message": "You do not have any message",
        "Mon": "Mon",
        "Tue": "Tue",
        "Wed": "Wed",
        "Thu": "Thu",
        "Fri": "Fri",
        "Sat": "Sat",
        "Sun": "Sun",
        "Now": "Now",
        "NowComplete": "Now (Acttual Pickup Time)",
        "hrs": "hrs",
        "patmentMethod_1": "Cash",
        "patmentMethod_2": "Personal card",
        "patmentMethod_3": "Partner card",
        "patmentMethod_4": "Corporate Card",
        "patmentMethod_5": "Direct invoicing",
        "patmentMethod_6": "External card",
        "patmentMethod_7": "Corporate Prepaid",
        "patmentMethod_8": "QR Code",
        "patmentMethod_9": "Apple Pay",
        "patmentMethod_10": "Apple Pay",
        "patmentMethod_12": "Credit Card",
        "patmentMethod_13": "Wallet",
        "patmentMethod_14": "TnG eWallet",
        "patmentMethod_16": "Cash by sender",
        "patmentMethod_17": "Cash by recipient",
        "patmentMethod_19": "Boost",
        "patmentMethod_20": "Vipps",
        "patmentMethod_23": "Google Play",
        "patmentMethod_24": "Payment Link",
        "patmentMethod_25": "Easy Paisa",
        "patmentMethod_26": "Jazz Cash",
        "patmentMethod_ZainCash": "ZainCash",
        "patmentMethod_Xendit": "Xendit",
        "patmentMethod_FIB": "FIB",
        "patmentMethod_SantimPay": "SantimPay",
        "patmentMethod_GCash": "GCash",
        "patmentMethod_cash": "Cash",
        "patmentMethod_credit": "Personal card",
        "patmentMethod_mDispatcher": "Partner card",
        "patmentMethod_corpCredit": "Corporate Card",
        "patmentMethod_directBilling": "Direct invoicing",
        "patmentMethod_fleetCard": "External card",
        "patmentMethod_prepaid": "Corporate Prepaid",
        "patmentMethod_QRCode": "QR Code",
        "patmentMethod_applePay": "Apple Pay",
        "patmentMethod_easyPaisa": "Easy Paisa",
        "patmentMethod_jazzCash": "Jazz Cash",
        "patmentMethod_prePaid": "Corporate Prepaid",
        "patmentMethod_GooglePay": "Google Pay",
        "patmentMethod_creditCardExternal": "Credit Card",
        "patmentMethod_PaxWallet": "Wallet",
        "patmentMethod_TnGeWallet": "TnG eWallet",
        "patmentMethod_boosteWallet": "Boost",
        "patmentMethod_Vipps": "Vipps",
        "patmentMethod_exZainCash": "ZainCash",
        "patmentMethod_exGCash": "GCash",
        "patmentMethod_exPayTM": "PayTM",
        "patmentMethod_exFlooz": "Flooz",
        "patmentMethod_exTmoney": "Tmoney",
        "patmentMethod_exTelebirr": "GCash",
        "paymentMethod_exzainCash": "ZainCash",
        "paymentMethod_exXendit": "Xendit",
        "paymentMethod_exFIB": "FIB",
        "paymentMethod_exSantimPay": "SantimPay",
        "patmentMethod_exKsher": "Ksher",
        "paymentMethod_paydunya": "PayDunya",
        "paymentMethod_exfawry": "Fawry",
        "patmentMethod_exBankOfGeorgia": "Bank Of Georgia",
        "patmentMethod_exPayDunya": "PayDunya",
        "patmentMethod_21": "ZainCash",
        "patmentMethod_paymentLink": "Payment Link",
        "patmentMethod_card": "Personal card",
        "patmentMethod_corporateCard": "Corporate card",
        "patmentMethod_paxWallet": "Wallet",
        "patmentMethod_externalWallet": "External Wallet",
        "patmentMethod_googlePay": "Google Pay",
        "patmentAirlineMethod_5": "Direct invoicing",
        "patmentAirlineMethod_4": "Airline Card",
        "patmentAirlineMethod_7": "Airline Prepaid",
        "Name": "Name",
        "Delete": "Delete",
        "App_SOS_dialog_form_message_1": "{0} Emergency SOS!",
        "App_SOS_dialog_form_message_2": "I need help at",
        "App_SOS_dialog_form_message_3": "Booking: {0}, Driver: {1}, Passenger: {2}, Car Registration Number: {3} ({4})",
        "mins": "mins",
        "Credit_Card": "Credit card",
        "ALL": "All",
        "NotFound": "The page you requested could not be found",
        "ServerError": "Something went wrong",
        "AccessDenied": "You don't have permission to access this module!",
        "Retry": "Retry",
        "Can_not_get_data": "Can't get data"
    },
    "shiftSettings": {
        "Add": "Add",
        "Show_per_page": "Items per page",
        "Page": "Page",
        "Name": "Name",
        "Time_Range": "Time Range",
        "Actions": "Actions",
        "show_pages": "{0} - {1} of {2} shown",
        "Edit": "Edit",
        "Delete": "Delete",
        "EDIT_SHIFT_SETTING": "Edit shift setting",
        "ADD_SHIFT_SETTING": "Add shift setting",
        "DETAIL_SHIFT_SETTING": "Detail shift setting",
        "DELETE_SHIFT_SETTING": "Delete shift setting",
        "Delete_confirm_shift_setting": "Do you want to delete this item?",
        "Required_fields": "Required fields",
        "Save": "Save",
        "Cancel": "Cancel",
        "From": "From",
        "To": "To",
        "Edit_shift_template": "Edit Shift Template",
        "Add_shift_template": "Add Shift Template",
        "Detail_shift_template": "Detail Shift Template",
        "Delete_shift_template": "Delete Shift Template",
        "Delete_confirm_shift_template": "Do you want to delete this item?",
        "Working_time": "Working Time",
        "Create_shift_settings_success": "New shift setting has been created successfully",
        "Create_shift_settings_fail": "The errors occurred while creating shift setting",
        "Update_shift_settings_success": "Shift setting has been updated successfully",
        "Update_shift_settings_fail": "The errors occurred while updating shift setting",
        "Delete_shift_settings_success": "Shift setting has been deleted successfully",
        "Delete_shift_settings_fail": "The errors occurred while deleting shift setting",
        "Create_shift_template_success": "New shift template has been created successfully",
        "Create_shift_template_fail": "The errors occurred while creating shift template",
        "Update_shift_template_success": "Shift template has been updated successfully",
        "Update_shift_template_fail": "The errors occurred while updating shift template",
        "Delete_shift_template_success": "Shift template has been deleted successfully",
        "Delete_shift_template_fail": "The errors occurred while deleting shift template",
        "Yes": "Yes",
        "No": "No"
    },
    "promotionSettings": {
        "Detail_campaign": "Detail Campaign",
        "Edit_campaign": "Edit Campaign",
        "Add_campaign": "Add Campaign",
        "Delete_campaign": "Delete Campaign",
        "Delete_confirm_campaign": "Do you want to delete selected campaign?",
        "Add": "Add",
        "Corporate_list": "Corporate list",
        "permittedCorporate": "Permitted corporate list",
        "CorporateCustomers": "Corporate Customers",
        "auto_add_corporate": "Auto add to Corporate User's promotion list",
        "Show_per_page": "Items per page",
        "Page": "Page",
        "Name": "Name",
        "Actions": "Actions",
        "Private": "Private",
        "show_pages": "{0} - {1} of {2} shown",
        "Edit": "Edit",
        "Delete": "Delete",
        "Send_to_inbox": "Send to inbox",
        "Save": "Save",
        "Duplicate": "Duplicate",
        "Cancel": "Cancel",
        "Required_fields": "Required fields",
        "Promotion_code": "Promotion Code",
        "Promotion_type": "Promotion Type",
        "Campaign": "Campaign",
        "Type": "Type",
        "Value": "Value",
        "keepMinFee": "Total fare after promo must greater than minimum fare",
        "Zone": "Zones",
        "paymentMethodsApply": "Payment methods",
        "schedules": "Schedules",
        "minimumEstFareApply": "Minimum estimate total fare to apply code",
        "quantityLimitPerYear": "Maximum uses/user/year",
        "termAndCondition": "Term & condition",
        "Valid_from": "Valid From",
        "Valid_to": "Valid To",
        "Total_uses_user": "Total Uses/User",
        "Released_quantity": "Released Quantity",
        "Number_of_sending_inbox": "Number Of Sending Inbox",
        "Status": "Status",
        "Activate": "Activate",
        "Deactivate": "Deactivate",
        "Add_promotion_code": "Add Promotion Code",
        "Edit_promotion_code": "Edit Promotion Code",
        "Detail_promotion_code": "Detail Promotion Code",
        "Delete_promotion_code": "Delete Promotion Code",
        "Delete_confirm_promotion_code": "Do you want to delete selected promotion code?",
        "New_customer": "New customers",
        "Budget_per_user": "Budget/user (for whole promotion time period)",
        "Budget_per_user_day": "Budget/user/day",
        "Budget_per_user_month": "Budget/user/month",
        "Budget_per_user_year": "Budget/user/year",
        "Unlimited": "Unlimited",
        "Limited": "Limited",
        "Total_uses_per_user": "Maximum uses/user (for whole promotion time period)",
        "Send_an_in_box_automatically_to_new_customers": "Send an inbox automatically to new customers",
        "Notes": "Notes",
        "Public": "Public",
        "Create_campaign_success": "New campaign has been created successfully",
        "Create_campaign_fail": "The errors occurred while creating campaign",
        "Update_campaign_success": "Campaign has been updated successfully",
        "Update_campaign_fail": "The errors occurred while updating campaign",
        "Delete_campaign_success": "Campaign has been deleted successfully",
        "Delete_campaign_fail": "The errors occurred while deleting campaign",
        "Loading_campaign_fail": "The errors occurred while loading campaign",
        "Update_campaign_status_success": "Campaign status has been updated successfully",
        "Update_campaign_status_fail": "The errors occurred while updating campaign status",
        "Loading_promotion_code_fail": "The errors occurred while loading promotion code",
        "Select_campaign": "Select Campaign",
        "Create_promotion_code_success": "New promotion code has been created successfully",
        "Create_promotion_code_fail": "The errors occurred while creating promotion code",
        "Update_promotion_code_status_success": "Promotion code status has been updated successfully",
        "Update_promotion_code_status_fail": "The errors occurred while updating promotion code status",
        "Delete_promotion_code_confirmation": "Do you want to delete these promotion codes?",
        "Just_delete_inactive": "** Just only promotion code in the inactive status can be deleted.",
        "Send_inbox_confirmation": "Are you sure you want to send this promo code to passenger inbox?",
        "Send_inbox_success": "Promotion code has been sent successfully.",
        "Send_inbox_fail": "Promotion code has been sent failed. Please try again!",
        "Confirmation": "Confirmation",
        "Delete_promotion_codes_success": "Promotion code has been deleted successfully",
        "Delete_promotion_codes_fail": "The errors occurred while deleting promotion code",
        "Delete_multiple_promotion_codes_success": "Promotion codes has been deleted successfully",
        "Delete_multiple_promotion_codes_fail": "The errors occurred while deleting promotion codes",
        "Delete_promotion_code_success": "Promotion code has been deleted successfully",
        "Delete_promotion_code_fail": "The errors occurred while deleting promotion code",
        "Update_multiple_promotion_code_status_success": "Promotion code statuses has been updated successfully",
        "Update_multiple_promotion_code_status_fail": "The errors occurred while deleting promotion code statuses",
        "Search_here": "Search here",
        "Please_input_campaign_name": "Please input campaign name",
        "Please_input_promotion_code": "Please input promotion code",
        "Please_input_promotion_code_at_least_4_characters": "Please enter at least 4 characters.",
        "Please_select_campaign": "Please select campaign",
        "Please_input_value": "Please input value",
        "Please_select_date": "Please select date",
        "Please_add_schedules": "Please add schedule",
        "Please_add_corporate": "Please add corporate",
        "Please_choose_at_least_one_customer": "Please choose at least one customer.",
        "This_campaign_name_has_been_existing_on_the_system_Please_input_another_one": "This campaign name has been existing on the system. Please input another one.",
        "This_promotion_code_has_been_existing_on_the_system_Please_input_another_one": "This promotion code has been existing on the system. Please input another one.",
        "Invalid_file": "File content is invalid",
        "File_size_larger_than_5Mb": "File size is larger than 5Mb",
        "Import_promotions_success": "File has been imported successfully",
        "Import_Promotions": "Import File",
        "Number_successful_imports": "Imported items",
        "Number_skipped": "Skip items",
        "File": "File",
        "Phone_Numbers": "phone number(s)",
        "Import_Promotion_code_success": "Promotion code <b>{0}</b> has been imported successfully",
        "Import_Promotion_code_fail": "Promotion code <b>{0}</b> has been failed to import. Please try again",
        "Customer_type": "Customer type",
        "All": "All",
        "Referred_customers": "Referred customers",
        "Common_customers": "Common customers",
        "Operation_zone": "Operation zones",
        "totalUsesLimitPerDay": "Maximum uses/user/day",
        "quantityLimitPerDay": "Maximum quantity/day",
        "quantityLimitPerMonth": "Maximum quantity/user/month",
        "applyVerifiedCustomers": "Apply for verified Malaysian customers only",
        "applyWhenBudgetLimit": "User can apply code till budget limit is finished",
        "applyVerifiedCustomersTooltip": "This promotion is applicable for Malaysian who has verified national ID and uploaded their ID card to the system only.",
        "valueLimitPerUse": "Maximum value/use (Apply for app v48 and above)",
        "Promotion_Services": "Service",
        "transport": "Transport",
        "shuttle": "Shuttle",
        "parcel": "Parcel",
        "food": "Food",
        "mart": "Mart",
        "intercity": "Intercity",
        "addSchedule": "Add Schedule",
        "editSchedule": "Edit Schedule",
        "date": "Date",
        "Time_range": "Time range",
        "startTime": "Start time",
        "endTime": "End time",
        "applyForOtherDays": "Apply for other days",
        "scheduleTimeOverlap": "Schedule time must not overlap with each others",
        "schedule_startDate_greater_than_endDate": "The start date is greater than the end date",
        "scheduleTimeDuplicate": "Schedule time must not duplicate with each others",
        "promoIcon": "Promo icon",
        "bannerImage": "Banner image",
        "Upload_an_image": "Upload an image",
        "Click_here_to_view": "Click here to view."
    },
    "quest_event": {
        "name": "Name",
        "userType": "Type of User",
        "carType": "Car type",
        "operationZones": "Operation zone",
        "type": "Event Type",
        "isLuckyDraw": "Lucky Draw Round",
        "start_date": "Start Date",
        "end_date": "End Date",
        "numOfQualify": "Number of Qualified",
        "event_progress": "Event Progress",
        "isActive": "Status",
        "Actions": "Actions",
        "passenger": "Customer",
        "driver": "Driver",
        "first_will_win": "First will win",
        "largest_will_win": "Largest will win (leaderboard)",
        "not_start": "Not start",
        "active": "Active",
        "closed": "Closed",
        "event_details": "Quest Details",
        "add_event": "Add Quest",
        "limited": "Limited",
        "unlimited": "Unlimited",
        "eventImage": "Event Image",
        "sharedImage": "Shared Image",
        "qualifiedImage": "Qualified Image",
        "expiredImage": "Expired Image",
        "luckyDrawImage": "Lucky Draw Image",
        "Upload_an_image": "Upload an image",
        "Required_Image": "Image Extensions (.jpg, .png, .gif)",
        "ok": "OK",
        "eventId": "Event ID",
        "passengerMetric": {
            "booking_distance": "Booking distance",
            "booking_time": "Booking time",
            "booking_amount_spent": "Booking amount spent",
            "num_of_ondemand": "Number of on-demand",
            "num_of_reservation": "Number of reservation",
            "num_of_intercity": "Number of intercity",
            "num_of_delivery": "Number of delivery",
            "num_of_referral": "Number of referral",
            "num_of_sharing": "Number of sharing",
            "topup_wallet_amount": "Topup wallet amount",
            "num_of_complete_wallet": "Number of complete booking (wallet)",
            "num_of_complete_booking_by_referee": "Number of complete booking (by referee)",
            "number_of_complete_booking": "Number of complete booking",
            "number_of_food_mart": "Number of food / mart"
        },
        "driverMetric": {
            "booking_distance": "Job distance",
            "booking_time": "Job time",
            "num_of_referral": "Number of referral",
            "num_of_complete": "Number of complete booking (unique customer)",
            "num_of_referral_driver": "Number of referral (driver)",
            "num_of_accept": "Number of accept booking",
            "num_of_receive": "Number of receive booking",
            "num_of_cancel": "Number of cancel booking by driver",
            "sum_of_rating": "Total number of rating star",
            "total_rating": "Total number of rating times",
            "earnings_amount": "Earnings Amount",
            "num_of_complete_wallet": "Number of complete booking (wallet)",
            "num_of_complete_booking_by_referee": "Number of complete booking (by referee)",
            "available_time": "Online time"
        },
        "Click_here_to_view": "Click here to view.",
        "view": "Click here to view.",
        "metricType": "Type",
        "task": "Task",
        "value": "Value",
        "quantity": "Quantity",
        "quests_list": "Quests",
        "rankImages": "Rank image (point)",
        "qualifiedPrizeOrder": "Qualified Prizes",
        "luckyDrawPrizeOrder": "Lucky Draw Prizes",
        "reward": "Item",
        "from": "From",
        "to": "To",
        "image": "Image",
        "Add_Prize": "Add",
        "bannerText": "Banner text",
        "buttonText": "Button text",
        "bannerLink": "Banner link",
        "shortLink": "Short link",
        "buttonLinkDesktop": "Button link (Desktop)",
        "buttonLinkAndroid": "Button link (Android)",
        "buttonLinkiOS": "Button link (iOS)",
        "termsURL": "Terms & Conditions Link",
        "metadataTitle": "Metadata title",
        "metadataDescription": "Metadata description",
        "isDarkMode": "Dark Mode",
        "isActive_details": "Active",
        "Required_fields": "Required fields",
        "change": "Change",
        "Please_input_name": "Please input name",
        "Please_select_date": "Please select date",
        "endDate_invalid": "End Date invalid",
        "startDate_greater_than_endDate": "The start date is greater than the end date",
        "Please_input_termsURL": "Please input Terms & Conditions Link",
        "Please_input_metadataTitle": "Please input metadata title",
        "Please_input_metadataDescription": "Please input metadata description",
        "Please_input_bannerText": "Please input banner text",
        "Please_input_buttonText": "Please input button text",
        "Please_input_bannerLink": "Please input banner link",
        "Please_input_shortLink": "Please input short link",
        "Please_input_buttonLinkDesktop": "Please input button link (Desktop)",
        "Please_input_buttonLinkAndroid": "Please input button link (Android)",
        "Please_input_buttonLinkiOS": "Please input button link (iOS) ",
        "the_range_1_to_5": "Please input value in the range 1 to 5 for %{name}!",
        "the_range_1_to_100": "Please input value in the range 1 to 100 for %{name}!",
        "task_is_empty": "Please input task for %{name}!",
        "task_limit_50_characters": "Task maximum length is 50 characters for %{name}!",
        "please_input_points": "Please input points for %{name}!",
        "please_input_value": "Please input value for %{name}!",
        "value_is_invalid": "%{name}, value must be a number",
        "please_upload_image": "Please upload image for item %{name}!",
        "rank_invalid": "Rank %{name} is invalid!",
        "rank_invalid_to": "Rank \"To\" must be a number",
        "deepLink": "Deeplink",
        "point_per_task": "Point per task",
        "points": "Points",
        "points_per_some_think": "Points per %{unit}",
        "criterias": "Criteria (optional)",
        "criterias_list": "Criteria",
        "criteriaType": "Type",
        "criteriaValue": "Value",
        "acceptance_rating": "Acceptance Rating (greater than or equal)",
        "cancellation_rating": "Cancellation Rating (less than or equal)",
        "driver_rating": "Driver Rating (greater than or equal)",
        "please_upload_image_rank": "Please upload image for rank %{name}!",
        "Update_event_success": "Quest has been updated successfully",
        "Update_event_fail": "The errors occurred while updating quest",
        "Create_event_success": "New quest has been created successfully",
        "Create_event_fail": "The errors occurred while creating quest",
        "suspend_list": "Suspend List"
    },
    "voucherSettings": {
        "Search_here": "Search here",
        "Voucher_code": "Code #",
        "Campaign": "Campaign",
        "Type": "Type",
        "Value": "Value",
        "Valid_from": "Valid From",
        "Valid_to": "Valid To",
        "isActive": "Status",
        "Activate": "Activate",
        "Deactivate": "Deactivate",
        "Actions": "Actions",
        "Active": "Active",
        "Inactive": "Inactive",
        "redeemed": "Redeemed",
        "expired": "Expired",
        "Add_Voucher_code": "Add code",
        "Add": "Add",
        "Show_per_page": "Items per page",
        "Page": "Page",
        "Name": "Name",
        "show_pages": "{0} - {1} of {2} shown",
        "Edit": "Edit",
        "Delete": "Delete",
        "Save": "Save",
        "Duplicate": "Duplicate",
        "Cancel": "Cancel",
        "Required_fields": "Required fields",
        "Detail_voucher_code": "Detail Voucher Code",
        "Delete_voucher_code": "Delete Voucher Code",
        "Confirmation": "Confirmation",
        "Delete_confirm_voucher_code": "Do you want to delete selected voucher code?",
        "Delete_voucher_code_confirmation": "Do you want to delete these voucher codes?",
        "Just_delete_inactive": "** Just only voucher code in the inactive status can be deleted.",
        "Loading_voucher_code_fail": "The errors occurred while loading voucher code",
        "Select_campaign": "Select Campaign",
        "Create_voucher_code_success": "New voucher code has been created successfully",
        "Create_voucher_code_fail": "The errors occurred while creating voucher code",
        "Update_voucher_code_status_success": "Voucher code status has been updated successfully",
        "Update_voucher_code_status_fail": "The errors occurred while updating voucher code status",
        "Delete_voucher_codes_success": "Voucher code has been deleted successfully",
        "Delete_voucher_codes_fail": "The errors occurred while deleting voucher code",
        "Delete_multiple_voucher_codes_success": "Voucher codes has been deleted successfully",
        "Delete_multiple_voucher_codes_fail": "The errors occurred while deleting voucher codes",
        "Delete_voucher_code_success": "Voucher code has been deleted successfully",
        "Delete_voucher_code_fail": "The errors occurred while deleting voucher code",
        "Update_multiple_voucher_code_status_success": "Voucher code statuses has been updated successfully",
        "Update_multiple_voucher_code_status_fail": "The errors occurred while deleting voucher code statuses",
        "Please_input_voucher_code": "Please input voucher code",
        "Please_input_voucher_code_at_least_4_characters": "Please enter at least 4 characters.",
        "Please_select_campaign": "Please select campaign",
        "Please_input_value": "Please input value",
        "Please_select_date": "Please select date",
        "Invalid_file": "File content is invalid",
        "File_size_larger_than_5Mb": "File size is larger than 5Mb",
        "Import_vouchers_success": "File has been imported successfully",
        "Import_vouchers": "Import File",
        "Number_successful_imports": "Imported items",
        "Number_skipped": "Skip items",
        "File": "File",
        "Phone_Numbers": "phone number(s)",
        "Import_voucher_code_success": "Voucher code <b>{0}</b> has been imported successfully",
        "Import_voucher_code_fail": "Voucher code <b>{0}</b> has been failed to import. Please try again",
        "All": "All"
    },
    "pointConfig": {
        "point_config": "Point config",
        "Convert_spent_amount_to_points": "Convert spent amount to points",
        "Convert_topup_amount_to_points": "Convert topup amount to points",
        "Redeem_points_to_voucher": "Redeem points to voucher",
        "others": "Other",
        "Online_Payment": "Online payment",
        "transport": "Transport",
        "parcel": "Parcel",
        "food": "Food",
        "mart": "Mart",
        "delivery": "Delivery",
        "intercity": "Intercity",
        "applyCash": "Apply for cash",
        "point": "Point",
        "vourcherName": "Name",
        "pointsToRedeem": "Points to redeem",
        "vourcherValue": "Voucher value",
        "validTime": "Valid time",
        "month": "Month(s)",
        "day": "Day(s)",
        "termAndCondition": "Terms & conditions",
        "Actions": "Actions",
        "add_voucher": "Add",
        "instructionURL": "Instruction link",
        "displayName": "Show name on super app",
        "isActive": "Active",
        "EDIT_VOUCHER": "Update Voucher",
        "DETAIL_VOUCHER": "Voucher Details",
        "CREATE_VOUCHER": "Add Voucher",
        "update_successfully": "Point config setting has been updated successfully",
        "Create_voucher_successfully": "New voucher has been created successfully",
        "Create_voucher_fail": "The errors occurred while creating voucher",
        "Update_voucher_successfully": "Voucher has been updated successfully",
        "Update_voucher_fail": "The errors occurred while updating voucher",
        "Delete_voucher_successfully": "Voucher has been deleted successfully",
        "Delete_voucher_fail": "The errors occurred while deleting voucher",
        "Confirmation": "Confirmation",
        "Delete_voucher_confirmation": "Do you want to delete this voucher?",
        "Please_enter_value_least_one": "Conversion rate is required! Set up spent amount or top-up amount to continue.",
        "Please_enter_value_is_number_spentAmount": "Please enter valid amount at \"Convert spent amount\" service \"{0}\".",
        "Please_enter_value_is_number_topUp": "Please enter valid amount at \"Convert topup amount\" method \"{0}\"."
    },
    "resetPassword": {
        "New_Password": "New Password",
        "New_Comfirm_Password": "New Confirm Password",
        "Save": "Save",
        "INFO_SUCCESS": "A reset password link has been sent to your email",
        "TOKEN_EXPIRED": "Your reset password token has been expired",
        "Back_To_Login_Page": "Back To Login Page"
    },
    "permission": {
        "editBooking": "Edit farm-out bookings",
        "companyOperator": "Company Operator",
        "Third_Party_Integration_Booking": "3rd party Integration (Booking.com)",
        "Third_Party_Integration_HolidayTaxis": "3rd party Integration (HolidayTaxis)",
        "supplierPermission": "Supplier permission",
        "Hint_edit_booking": "Allow companies/suppliers to edit bookings farmed out by the fleet owner.",
        "View": "View",
        "Actions": "Actions",
        "EditFare": "Edit Fare",
        "EditDriverEarning": "Edit Driver Earning",
        "EditTotal": "Edit Total",
        "ImportBookings": "Import Transport Bookings",
        "ImportDeliveryBookings": "Import Delivery Bookings",
        "ShowFare": "Show Fare",
        "delete_permission": "Delete Permission",
        "DELETE_CONFIRM": "Are you sure you want to delete the selected permission!",
        "Yes": "Yes",
        "No": "No",
        "Cancel": "Cancel",
        "DRIVERS": "DRIVERS",
        "Driver_list": "Driver list",
        "Enter_to_search": "Enter to search",
        "Permitted_driver_list": "Permitted driver list",
        "Save": "Save",
        "CAR": "CAR",
        "Car_list": "Car list",
        "Permitted_car_list": "Permitted car list",
        "Edit_permission": "Edit permission",
        "Add_permission": "Add permission",
        "Detail_permission": "Detail permission",
        "Name": "Name",
        "Driver_Car": "Driver/Car",
        "All": "All",
        "Company": "Company",
        "By_specific": "By specific",
        "Drivers": "Drivers",
        "Cars": "Cars",
        "Show_per_page": "Items per page",
        "Page": "Page",
        "show_pages": "{0} - {1} of {2} shown",
        "Delete": "Delete",
        "Edit": "Edit",
        "Status": "Status",
        "Action": "Action",
        "Inactive": "Inactive",
        "Active": "Active",
        "Update_status_error": "Update status error",
        "All_tooltip": "All driver(s) will be displayed on Map, Cue, Drivers, Operation and Reports. All car(s) will be displayed on Car mgmt",
        "Company_tooltip": "Permitted driver(s) in this company will be displayed on Map, Cue, Drivers, Operation and Reports. Permitted car(s) in this company will be displayed on Car mgmt",
        "By_specific_tooltip": "Permitted driver(s) in this list will be displayed on Drivers, Operation. Permitted car(s) in this list will be displayed on Car mgmt",
        "Full_permissions": "Full permissions",
        "ChangeBalance": "Change Credit Balance",
        "ChangeCashBalance": "Change Cash Balance",
        "TotalBalance": "Total Balance",
        "WriteOffDebt": "Write Off Debt",
        "topupPaxWallet": "Change Balance",
        "SeFleetCommission": "Set fleet commission (all drivers)",
        "EditFleetCommission": "Edit fleet commission",
        "EditBalance": "Edit Balance",
        "ViewHeatMap": "View Heat Map",
        "Delete_permission_success": "The permission has been deleted successfully",
        "Delete_permission_fail": "The errors occurred while deleting permission",
        "Create_permission_fail": "The errors occurred while creating new permission",
        "Create_permission_success": "New permission has been created successfully",
        "Update_permission_fail": "The errors occurred while updating permission",
        "Update_permission_success": "The permission has been updated successfully",
        "No_action_selected": "Please tick at least one action in the action list",
        "Required_fields": "Required fields",
        "sendSMS": "Send Message (SMS)",
        "sendInbox": "Send Message (Inbox)",
        "PayToDriver": "View 'Pay to Driver'",
        "PayoutHistory": "View 'Payout History'",
        "Pay": "Pay driver",
        "ActivateDriver": "Activate Driver",
        "Export": "Export",
        "Export_csv": "Export .csv",
        "Street-sharing_rate": "Street sharing rate"
    },
    "thirdPartySettings": {
        "category": "Category",
        "Not_over_limit_subs": "You can not create more than 10 sub-locations",
        "Show_per_page": "Items per page",
        "Page": "Page",
        "show_pages": "{0} - {1} of {2} shown",
        "Delete": "Delete",
        "Edit": "Edit",
        "Status": "Status",
        "Actions": "Actions",
        "Action": "Action",
        "Inactive": "Inactive",
        "Active": "Active",
        "website": "Website",
        "Check_Google_Address": "Check Google address from latitude & longitude",
        "Delete_Category_success": "Category has been deleted successfully",
        "Delete_category_fail": "The errors occurred while deleting category",
        "availableTime": "Available time",
        "Please_choose_at_least_one_category": "Please choose at least one category",
        "Please_input_category_name": "Please input category name",
        "Create_category_success": "Category has been created successfully",
        "Create_category_fail": "The errors occurred while creating category",
        "Update_third_party_success": "Location has been updated successfully",
        "Update_category_fail": "The errors occurred while updating category",
        "All_Category": "All category",
        "Delete_Category": "Edit category",
        "Edit_Category": "Edit category",
        "Add_Category": "Add category",
        "Add_Third_Party_location": "Add 3rd Party Location",
        "Add": "Add",
        "Edit_Third_Party_location": "Edit 3rd Party Location",
        "Location_Not_Found": "Location not found",
        "Create_third_party_success": "Location has been created successfully",
        "Create_third_party_fail": "The errors occurred while creating location",
        "Update_third_party_fail": "The errors occurred while updating location",
        "Alias": "Alias",
        "Address": "Address",
        "Latitude": "Latitude",
        "Longitude": "Longitude",
        "Please_input_address": "Please input address",
        "Please_input_name": "Please input location name",
        "Please_input_latitude": "Please input latitude",
        "Please_input_longitude": "Please input longitude",
        "Save": "Save",
        "Cancel": "Cancel",
        "Required_fields": "Required fields",
        "Last_update": "Last Update",
        "Username": "Username",
        "locationType": "Location Type",
        "single": "Single location",
        "multiple": "Multiple locations",
        "AddMore": "Add more",
        "locationList": "List of locations",
        "instructionLink": "Instruction Link",
        "location": "Location",
        "checkOnMap": "Check on map",
        "Remove": "Remove",
        "placeType": "Type",
        "all": "All",
        "PU": "Pickup",
        "DO": "Drop off",
        "namePlaceholder": "e.g. terminal 1",
        "instructionLinkPlaceholder": "Html page with full instruction",
        "instructionLinkTooltip": "Please enter a link for instruction page. Passenger can follow the instruction on this link to find pickup location.",
        "longitudePlaceholder": 108.1837969,
        "latitudePlaceholder": 16.0393381,
        "Error_message_lat_in_range": "The latitude must be between -90.0 and 90.0",
        "Error_message_lng_in_range": "The longitude must be between -180.0 and 180.0.",
        "Delete_third_party_success": "Location has been deleted successfully",
        "Delete_third_party_fail": "The errors occurred while deleting location",
        "Delete_location": "Delete location",
        "Note_guide": "Note: when searching on the map, your location will be quickly marked with a pin."
    },
    "city": {
        "DELETE_CITY": "Delete city",
        "DELETE_CONFIRM": "Are you sure you want to delete the selected city?",
        "Yes": "Yes",
        "No": "No",
        "Cancel": "Cancel",
        "EDIT_CITY": "Edit city",
        "ADD_CITY": "Add City",
        "Name": "Name",
        "Required_fields": "Required fields",
        "Save": "Save",
        "Add": "Add",
        "Show_per_page": "Items per page",
        "Page": "Page",
        "show_pages": "{0} - {1} of {2} shown",
        "Delete": "Delete",
        "Edit": "Edit",
        "DETAIL_CITY": "Detail city",
        "Update_city_success": "The city has been updated successfully",
        "Create_city_success": "New city has been created successfully",
        "Delete_city_success": "The city has been deleted successfully",
        "cityName": "City name",
        "Service": "Service",
        "serviceTransportation": "Transportation",
        "serviceAssistant": "Assistant",
        "serviceDelivery": "Delivery",
        "isShowDriverSignUp": "Show in provider signup",
        "note_isShowDriverSignUp": "(Apply for new signup module from 4.6.46 and above)"
    },
    "jobTab": {
        "DELETE_JOBTAB": "Delete Jobs Tab",
        "DELETE_CONFIRM": "Are you sure you want to delete the selected Jobs Tab?",
        "Yes": "Yes",
        "No": "No",
        "Cancel": "Cancel",
        "EDIT_JOBTAB": "Edit Job Tab",
        "ADD_JOBTAB": "Add Jobs Tab",
        "Name": "Name",
        "Required_fields": "Required fields",
        "Save": "Save",
        "Add": "Add",
        "Show_per_page": "Items per page",
        "Page": "Page",
        "show_pages": "{0} - {1} of {2} shown",
        "Delete": "Delete",
        "Edit": "Edit",
        "DETAIL_JOBTAB": "Detail Jobs Tab",
        "Update_JOBTAB_success": "The Jobs Tab has been updated successfully",
        "Create_JOBTAB_success": "New Jobs Tab has been created successfully",
        "Delete_JOBTAB_success": "The Jobs Tab has been deleted successfully",
        "JOBTABName": "Jobs Tab name",
        "jobsLink": "Link"
    },
    "driverNote": {
        "name": "Driver note",
        "DELETE": "Delete Driver Note",
        "DELETE_CONFIRM": "Are you sure you want to delete the selected Driver Note?",
        "EDIT": "Edit Driver Note",
        "ADD": "Add Driver Note",
        "DETAIL": "Detail Driver Note",
        "Update_success": "The Driver Note has been updated successfully",
        "Create_success": "New Driver Note has been created successfully",
        "Delete_success": "The Driver Note has been deleted successfully",
        "Delete_fail": "The errors occurred while deleting driver note",
        "Load_fail": "The errors occurred while loading driver note",
        "Update_fail": "The errors occurred while updating driver note",
        "Create_fail": "The errors occurred while creating driver note"
    },
    "flightIntegration": {
        "Username": "Username",
        "provider": "Flight Provider",
        "Key": "API Key",
        "apiId": "API Id",
        "Save": "Save",
        "Update_flight_integration_success": "The flight integration has been updated successfully",
        "Update_flight_integration_fail": "The errors occurred while updating flight integration"
    },
    "smsIntegration": {
        "provider": "SMS Provider",
        "apiToken": "API Token",
        "subAccountId": "Sub Account ID",
        "senderIdSource": "Sender ID (source):",
        "apiKey": "API Key",
        "username": "Username",
        "shortCode": "Short Code",
        "key": "Key",
        "webService": "Web service",
        "bearerToken": "Bearer token",
        "keyId": "Key ID",
        "email": "Email",
        "appKey": "App key",
        "sender": "Sender",
        "password": "Password",
        "maskedPhone": "Masked phone number",
        "senderId": "Sender ID",
        "webservice": "Web service",
        "brandname": "Brand name",
        "appSecret": "App secret",
        "consumerKey": "Consumer key",
        "requestUrl": "Request Url",
        "authId": "Auth ID",
        "authToken": "Auth Token",
        "phloId": "PHLO ID",
        "dlcNumber": "10DLC Number",
        "app_key": "App key",
        "api_key": "API key",
        "accountSID": "Account SID",
        "applicationId": "Application ID",
        "phoneNumber": "SMS number 1",
        "phoneNumber2": "SMS number 2",
        "SenderID_1": "SenderID 1",
        "SenderID_2": "SenderID 2",
        "smsMarketingNumber": "SMS makerting phone number",
        "updateSMSSuccess": "The sms integration has been updated successfully",
        "Deleted_success": "The SMS has been deleted successfully",
        "receiverCountries": "Receiver Countries",
        "error": {
            "ERROR_CREATE": "The errors occurred while creating sms",
            "COUNTRY_EXISTED": "Country already exist on system",
            "ERROR_DELETE": "Failed to delete SMS",
            "ERROR_UPDATE": "Failed to update SMS"
        },
        "identifierId": "Identifier ID"
    },
    "mDispatcher": {
        "mDispatchers": "Partners",
        "Active": "Active",
        "Inactive": "Inactive",
        "Deactivate": "Deactivate",
        "Activate": "Activate",
        "Delete": "Delete",
        "Save": "Save",
        "Edit": "Edit",
        "ADD_MDISPATCHER": "Add partner",
        "EDIT_MDISPATCHER": "Edit partner",
        "Phone_number": "Phone Number",
        "first_name": "First name",
        "last_name": "Last name",
        "email": "Email",
        "address": "Address",
        "mDispatcher_type": "Partner type",
        "mDispatcher_type_toolTip": "To add more partner types, please go to Settings > Partner Type",
        "queuing_area_toolTip": "To add more queuing areas, please go to Queuing Area menu.",
        "mDispatcher": "Partner",
        "card_management": "Card Management",
        "Select_mDispatcher_type": "Select Partner Type",
        "Amount": "Amount",
        "Percent": "Percent",
        "Yes": "Yes",
        "Required_fields": "Required fields",
        "Cancel": "Cancel",
        "commission_type": "Commission Type",
        "commission_value": "Commission Value",
        "Name": "Name",
        "Phone_Number": "Phone Number",
        "queuing_area": "Queuing Area",
        "mdispatcher_type": "Partner Type",
        "registered_from": "Registered From",
        "registration_date": "Registration Date",
        "status": "Status",
        "actions": "Actions",
        "Select_queue_area": "Select queue area",
        "for_new_mDipatcher_account_password_default_is_password": "For new partner account, the default password is \"12345678\"",
        "Create_mDispatcher_success": "New partner has been created successfully",
        "Create_mDispatcher_fail": "The errors occurred while creating partner",
        "Load_mDispatcher_fail": "The errors occurred while loading partner",
        "Update_mDispatcher_success": "Partner has been updated successfully",
        "Update_mDispatcher_fail": "The errors occurred while updating partner",
        "Update_mDispatcher_status_success": "Partner status has been updated successfully",
        "Update_mDispatcher_status_fail": "The errors occurred while updating partner status",
        "Update_mDispatcher_statuses_success": "Partner statuses has been updated successfully",
        "Update_mDispatcher_statuses_fail": "The errors occurred while updating partner statuses",
        "Delete_mDispatcher_success": "Partner has been deleted successfully",
        "Delete_mDispatcher_fail": "The errors occurred while deleting partner",
        "Delete_mDispatchers_success": "Partners has been deleted successfully",
        "Delete_mDispatchers_fail": "The errors occurred while deleting partners",
        "Do_you_want_to_delete_these_mDipatchers": "Do you want to delete these partners?",
        "Only_mDistcher_in_the_inactive_status_can_be_deleted": "***Just only mDistcher in the inactive status can be deleted",
        "No_card": "No card found",
        "Reset_Password": "Reset Password",
        "Reset_password_success": "Partner password has been reset successfully",
        "Reset_password_fail": "The errors occurred while reseting partners password",
        "Do_you_want_to_reset_password": "Do you want to reset password ?",
        "ERROR_EXISTED_PHONE": "This phone has been existing on the system. Please input another one",
        "ERROR_INPUT_VALIDPHONE": "Please enter a valid phone number.",
        "Partner_detail": "Partner details",
        "ALERT_SELECT_ACTIVATE": "Please select partners to activate.",
        "ALERT_SELECT_DEACTIVATE": "Please select partners to deactivate.",
        "CONFIRM_DEACTIVATE_MULTI": "Do you want to deactivate these partners?",
        "CONFIRM_ACTIVATE_MULTI": "Do you want to activate these partners?",
        "ERROR_COMMISSION_NUMBERS": "Please enter a valid number",
        "No_Support": "New card adding is currently not supported in this area",
        "Add": "Add",
        "Default_zone": "Default zone",
        "required_email_payment": "The email of partner are required. Please update profile to continue.",
        "titleGuestQR": "Partner Guest-QR Link",
        "questQRContent": "Your personalized partner booking link is here! Display it prominently in bars, hotel receptions, guest rooms, and on your website—anywhere your customers might need a ride or delivery. Consider designing standees with your unique QR code, allowing customers a quick and easy scan to book their journey!",
        "View_Guest_QR": "View Guest QR"
    },
    "mDispatcherType": {
        "Name": "Name",
        "Required_fields": "Required fields",
        "Save": "Save",
        "Cancel": "Cancel",
        "No": "No",
        "Add": "Add",
        "Show_per_page": "Items per page",
        "Page": "Page",
        "show_pages": "{0} - {1} of {2} shown",
        "Delete": "Delete",
        "Edit": "Edit",
        "DELETE_MDISPATCHER_TYPE": "Delete Partner Type",
        "DELETE_CONFIRM": "Are you sure to delete partner type?",
        "Yes": "Yes",
        "EDIT_MDISPATCHER_TYPE": "Edit Partner Type",
        "ADD_MDISPATCHER_TYPE": "Add Partner Type",
        "DETAIL_MDISPATCHER_TYPE": "Detail Partner Type",
        "Actions": "Actions",
        "Delete_mDispatcherType_success": "Partner Type has been deleted successfully",
        "Delete_mDispatcherType_fail": "The errors occurred while deleting partner type",
        "Load_mDispatcherType_fail": "The errors occurred while loading partners type",
        "Create_mDispatcherType_success": "New Partner Type has been created successfully",
        "Create_mDispatcherType_fail": "The errors occurred while creating partners type",
        "Update_mDispatcherType_success": "Partner Type has been updated successfully",
        "Update_mDispatcherType_fail": "The errors occurred while updating partners type"
    },
    "queuingArea": {
        "queuing_area_info": "Queue area info",
        "queuingDrivers": "Active drivers",
        "Name": "Name",
        "Required_fields": "Required fields",
        "Save": "Save",
        "Cancel": "Cancel",
        "Yes": "Yes",
        "No": "No",
        "Add": "Add",
        "Show_per_page": "Items per page",
        "Enter_to_search": "Enter to search",
        "Page": "Page",
        "show_pages": "{0} - {1} of {2} shown",
        "Delete": "Delete",
        "Edit": "Edit",
        "Active": "Active",
        "Inactive": "Inactive",
        "Deactivate": "Deactivate",
        "Activate": "Activate",
        "drivers": {
            "order": "Order",
            "Driver_name": "Driver name",
            "Phone_Number": "Phone number",
            "driverLicenseNumber": "Car Registration Number",
            "Car_Type": "Car type",
            "checkInTime": "Check in time",
            "duration": "Duration",
            "timestampScore": "Timestamp Score",
            "changeOrderDriver": "Change order",
            "detailOrderDriver": "Driver order",
            "Yes": "Yes",
            "No": "No",
            "insert": "Insert",
            "refresh": "Refresh",
            "insertDriver": "Insert a driver to the queue",
            "orderDriver": "Order #",
            "reason": "Reason",
            "removeDriver": "Remove Driver from Queue",
            "removeDriverConfirm": "Do you want to remove this driver from the queue?",
            "driverNotStay": "This driver doesn't stay within queue area. Please try again later.",
            "overOrder": "The order # cannot be greater than the total number of drivers in the queue.",
            "noDriverFound": "No driver found",
            "driverUpdateErrRs": "The driver is not in the queue area. Please try again later.",
            "orderUpdateErrRs": "The order # cannot be greater than the total number of drivers in the queue.",
            "removeDriverSuccess": "The driver has been successfully removed from the queue area.",
            "removeDriverFail": "An error occurred while removing driver from the queue area.",
            "operationInsertFailRs": "The driver does not exist or is unavailable.",
            "versionInsertFailRs": "To apply this feature, the driver needs to upgrade his driver app to the latest version.",
            "queueInsertFailRs": "The queue does not exist or the driver is not in the queue area. Please try again later.",
            "vehicleInsertFailRs": "The queue does not support the vehicle type of driver.",
            "orderInsertFailRs": "The order # cannot be greater than the total number of drivers in the queue.",
            "positionInsertFailRs": "An error occurred while inserting driver into the queue area.",
            "insertDriverSuccess": "The driver has been successfully inserted to the queue area.",
            "changeOrderSuccess": "The driver's position has been successfully changed.",
            "insertDriverFail": "An error occurred while inserting driver from the queue area.",
            "changeOrderFail": "An error occurred while changing driver's position from the queue area.",
            "selectToInsert": "Search: Driver name, Phone number",
            "updateBy": "Updated by operator",
            "changeOrder": "Change order",
            "remove": "Remove"
        },
        "keepDriverPlaceCancelNoShow": "Keep driver's place when booking is canceled / no-show",
        "keepDriverPlaceUnavailable": "Keep driver's place when driver is unavailable",
        "range": "Queue range",
        "queueRangeTooltip": "This is how drivers will see their queuing position number on driver app. For example:\n  - Setting queue range at 1: drivers will see their position in queue as a single number: 1, 2, 3, 4, etc.\n  - Setting queue range at 5: drivers will see their position in queue as a range: 1-5, 6-10, 11-15, 16-20, etc.\n  - Setting queue range at 10: drivers will see their position in queue as a range: 1-10, 11-20, 21-30, 31-40, etc.",
        "queueCanceledTooltip": "- When passenger cancel or no-show, the driver will remain on the top position if the driver returns to the queue within the setting time.\n  - The driver's position will be set high priority if the driver returns to the queue within the setting time after completing a short-trip booking.",
        "DELETE_QUEUING_AREA": "Delete Queuing Area",
        "DELETE_CONFIRM": "Do you want to delete Queuing Area?",
        "EDIT_QUEUING_AREA": "Edit Queuing Area",
        "ADD_QUEUING_AREA": "Add Queuing Area",
        "DETAIL_QUEUING_AREA": "Detail queuing area",
        "Queuing_area": "Queuing area",
        "Address": "Address",
        "Enter_a_location": "Enter a location",
        "Entered_Address_is_incorrect": "The system cannot determine your location. Please use the suggested address.",
        "Not_supported_zone": "This queue area is not in the supported zone",
        "Active_mDispatcher_list": "Active partner list",
        "Queue_list": "Queue list",
        "Allow_radius": "Allow radius",
        "Min_drivers_required": "# Min Drivers Required",
        "Open_queuing_area": "Open queuing area",
        "Auto_dispatch": "Auto dispatch to drivers in queue (no need to use Partner app)",
        "Active_car_list": "Active car list",
        "mDispatcher": "Partner",
        "Google_Address": "Google Address",
        "Status": "Status",
        "Update_status_error": "Update status error",
        "driver_checkin_area": "Driver check-in area",
        "EDIT_POLYGONS": "Edit polygons",
        "pax_pickup_area": "Passenger pick-up area",
        "pickup_area": "Pick-up area",
        "your_chosen_polygon": "Your chosen polygon",
        "other_queues": "Other queues",
        "operation_zones": "Operation zones",
        "delete_pickup_area_confirm": "Are you sure you want to delete this pick-up area?",
        "delete_pickup_area": "Delete pick-up area",
        "please_choose_driver_check_in_area": "Please choose driver check-in area.",
        "please_choose_pick_up_area": "Please choose pick-up area.",
        "Load_queuing_area_fail": "The errors occurred while loading queuing area",
        "Create_queuingArea_success": "Queuing area has been created successfully",
        "Update_queuingArea_success": "Queuing area has been updated successfully",
        "Delete_queuingArea_success": "Queuing area has been deleted successfully",
        "Delete_queuingArea_fail": "The errors occurred while deleting queuing area",
        "Create_queuingArea_fail": "The errors occurred while creating queuing area",
        "Load_queuingArea_fail": "The errors occurred while loading queuing area",
        "Update_queuingArea_fail": "The errors occurred while updating queuing area"
    },
    "appBanner": {
        "Save": "Save",
        "Pax_app": "Pax app",
        "Apply_to": "Apply to",
        "Please_select_view": "Please select view",
        "Select_Type": "Please select type of banner",
        "Home_view": "Home view",
        "Other_view": "Other view",
        "Banner_1": "Banner #1",
        "Upload_an_image": "No image selected",
        "Action": "Action",
        "NA": "N/A",
        "Link_to_website": "Link to website",
        "Make_a_call": "Make a call",
        "Send_an_email": "Send an email",
        "Banner_2": "Banner #2",
        "Banner_3": "Banner #3",
        "Driver_app": "Driver app",
        "pax": "Passenger app",
        "driver": "Driver app",
        "Name": "Name",
        "viewType": "Type",
        "status": "Status",
        "image": "Image",
        "html": "HTML",
        "Delete_Banner": "Delete banner",
        "Delete_confirm_banner": "Are you sure you want to delete the selected banner?",
        "Delete_banner_success": "Banner has been deleted successfully",
        "Delete_banner_fail": "The errors occurred while deleting banner",
        "edit_banner": "Edit Banner",
        "detail_banner": "Detail Banner",
        "create_banner": "Add Banner",
        "note_1": "*  The file extension must be .jpg, .jpeg, .png, .gif and less than %{limit}MB.",
        "note_2": "*  Images should have a 16:9 aspect ratio.",
        "note_3": "* Other views including Inbox, Refer friend, Receipt, My booking, Promo code, Add Credit card/Payment methods, Profile, Waiting for process/Confirmed reservation, Completed view.",
        "Update_app_banner_success": "App banner has been updated successfully",
        "Update_app_banner_fail": "Failed to update the app banner!",
        "Create_app_banner_success": "App banner has been created successfully",
        "Create_app_banner_fail": "Failed to create the app banner!",
        "Note": "Please use the file in .png format (transparent background, sized 400:200px) for better app performance",
        "Limit_size_upload": "The file size must be less than 8Mb.",
        "display_duration": "Display duration",
        "playback_settings": "Playback settings",
        "display_banner_again_after": "Display banners again after",
        "stop_display_banner_after": "Stop display banner after",
        "enable_playback": "Enable playback",
        "Update_playback_success": "Playback settings has been updated successfully",
        "Update_playback_fail": "Failed to update the playback settings!",
        "language": "Language",
        "single": "Single Language",
        "multi": "Multi Languages",
        "es-ES": "Spanish",
        "en-US": "English",
        "fr-FR": "French",
        "id-ID": "Bahasa indonesia",
        "vi-VN": "Vietnamese",
        "ur-PK": "Urdu",
        "ms-MY": "Malaysia",
        "ar-AR": "Arabic",
        "nb-NO": "Norwegian",
        "en-GB": "English (United Kingdom)",
        "am-ET": "Amharic",
        "ro-RO": "Romanian",
        "version": "App Version",
        "comparisonVersionApp": {
            "is": "Is",
            "is_not": "Is not",
            "is_one_of": "Is one of",
            "is_not_one_of": "Is not one of",
            "greater_or_equal": "Greater or equal",
            "less_or_equal": "Less or equal"
        },
        "platform": "Platform",
        "platformItem": {
            "all": "All",
            "iOS": "iOS",
            "android": "Android"
        },
        "userStatus": "User status",
        "Active": "Active",
        "Inactive": "Inactive",
        "in_review_completed": "In review (profile completed)",
        "in_review_incomplete": "In review (profile incomplete)",
        "in_review_inProgress": "In review (profile in progress)",
        "zones": "Zones"
    },
    "reservation": {
        "name": "Reservation",
        "company": "Company",
        "carType": "Car type",
        "licensePlate": "Car Registration Number: %{name}",
        "driver": "Driver: %{name}",
        "showAllDrivers": "Show All Drivers",
        "available": "Available",
        "reserved": "Reserved",
        "search_holder": "Company, Driver Name, Car Type, Car Registration Number",
        "all": "All",
        "today": "Today",
        "thisWeek": "This Week",
        "day": "Daily",
        "week": "Weekly",
        "bookingID": "Booking ID",
        "tripId": "Trip ID",
        "pickupTime": "Pickup time",
        "pickupLocation": "Pickup location",
        "monday": "Monday",
        "tuesday": "Tuesday",
        "wednesday": "Wednesday",
        "thursday": "Thursday",
        "friday": "Friday",
        "saturday": "Saturday",
        "sunday": "Sunday",
        "overlapped": "Overlapped",
        "noData": "No data was found.",
        "bookingDetails": "Reservation Details",
        "reservedHint": "Reservation bookings are confirmed",
        "availableHint": "Don't have any confirmed reservation bookings",
        "overlappedHint": "Two or more confirmed reservation bookings overlap"
    },
    "dashboard": {
        "API": "API",
        "cc": "CC",
        "dmc": "Booking board",
        "streetSharing": "Street-Sharing",
        "kiosk": "Kiosk",
        "mDispatcher": "Partner",
        "paxApp": "Pax App",
        "webBooking": "Web Booking",
        "carHailing": "Car-Hailing",
        "acceptance": "Acceptance",
        "booking_com": "Booking.com",
        "completed": "Completed",
        "noShow": "No Show",
        "canceled": "Canceled",
        "incident": "Incident",
        "rate1": "1 Star",
        "rate2": "2 Stars",
        "rate3": "3 Stars",
        "rate4": "4 Stars",
        "rate5": "5 Stars",
        "Export_to_Excel": "Export to Excel",
        "Profit": "Profit",
        "day_change": "Daily change",
        "week_change": "Weekly change",
        "month_change": "Monthly change",
        "Booking_requests": "Booking requests",
        "Complete_bookings": "Completed bookings",
        "New_customers": "New customers",
        "Booking_dashboard": "Booking board",
        "others": "Others",
        "Revenue_booking": "Profit & Finished Booking",
        "Profit_finish_booking": "Profit & Finished Booking",
        "Booking_request": "Booking request",
        "Finished_booking": "Finished booking",
        "failed": "Failed",
        "partialCompleted": "Partial Completed",
        "Finished_booking_char_header": "Finished booking ({0})",
        "Bookings": "Bookings ({0})",
        "Ratings": "Ratings ({0})",
        "Daily": "Daily",
        "Weekly": "Weekly",
        "Monthly": "Monthly",
        "Total_customer": "Total customer {0}",
        "ProfitToolTips": "The money fleet makes after excluding all expenses",
        "RevenueToolTips": "The money fleet actually receives during selected period, including promotion, deductions for drivers, tax, etc.",
        "ProfitChangeToolTips": "The percentage of change in the fleet's profits compared to previous period",
        "BookingRequestsToolTips": "Total number of on-demand bookings and reservations which are created during selected period",
        "PercentageOfAcceptanceToolTips": "The percentage of accepted bookings during selected period",
        "BookingChangeHint": "The percentage of change in created bookings compared to previous period",
        "CompletedBookingHint": "Total number of on-demand bookings and reservations which are completed during selected period",
        "PercentageOfSatisfactionHint": "The percentage of satisfaction during selected period",
        "CompleteBookingDailyChangeHint": "The percentage of change in completed bookings compared to previous period",
        "NewCustomersHint": "Total number of new customers registered during selected period",
        "CustomerChangeHint": "The number and percentage of change in customers compared to previous period",
        "TotalCustomer": "Total number of customers in system",
        "ProfitAndFinishedBookingHint": "Profit & finished bookings of fleet during selected period",
        "BookingRequestHint": "Total number of on-demand bookings and reservations which are created during selected period",
        "FinishedBookingChartsHint": "Total number of on-demand bookings and reservations which are closed during selected period, including cancel, no show, incident, and completed",
        "Customer_count": "{0} customer count",
        "Revenue": "Revenue"
    },
    "referral": {
        "first_package": "1st package (i.e the first completed booking of Pax)",
        "driver_refers_to_pax": "Driver refers customer",
        "driver_will_get": "Driver will get",
        "money": "Money",
        "amount": "Amount",
        "percent": "Percent",
        "settlement": "Commission (% subtotal amount of each completed booking)",
        "pax_will_get": "Pax will get",
        "promotion_code": "Promotion Code",
        "select_a_promotion": "Select a promotion code",
        "maximum": "Maximum",
        "second_package": "2nd package",
        "from": "From",
        "to": "To",
        "After_2nd_package": "After 2nd package",
        "from_end_value_of_above_package": "From value of the last above package + 1",
        "Expiration_date": "Expiration date",
        "Never": "Never",
        "Customized_date": "Customized date",
        "limited": "Limited",
        "unlimited": "Unlimited",
        "Valid_from": "Valid From",
        "Valid_to": "Valid to",
        "Invitation_limit": "Referee limit/driver",
        "Status": "Status",
        "Active": "Active",
        "Inactive": "Inactive",
        "note": "* Please note that once the campaign is active, any update on reward values will only affect new customers.",
        "Required_fields": "Required fields",
        "refferal_update_successfully": "Referral setting has been updated successfully",
        "distributeIncentiveToDriver": "Distribute incentive to driver",
        "Immediate": "Immediate",
        "Periodic": "Periodic (Manually)",
        "Period": "Period",
        "startDate": "Start date",
        "nextDistributionDate": "Next distribution date",
        "oneMonth": "1 month",
        "threeMonth": "3 months",
        "sixMonth": "6 months",
        "passenger_refers_to_pax": "Customer refers customer",
        "referer_will_get": "Referer will get",
        "referee_will_get": "Referee will get",
        "promo": "Promotion",
        "Pax_invitation_limit": "Invitation limit/user",
        "promoPaidBy": "Promotion paid by",
        "fleet": "Fleet",
        "driver": "Driver",
        "promoCode": "Promotion code",
        "isSendInbox": "Send inbox",
        "isDefaultCode": "Auto apply on app",
        "autoApplyOnWeb": "Auto apply on web",
        "isDefaultCode_hint": "Add promotion code to customer's list and auto apply to booking when customer creates order",
        "Actions": "Actions",
        "add_promoCode": "Add",
        "Send_an_in_box_automatically_to_new_customers": "Send inbox to customer",
        "set_default_promo_code": "Auto apply on app",
        "EDIT_PROMO_CODE": "Edit promotion code",
        "DETAIL_PROMO_CODE": "Promotion code details",
        "CREATE_PROMO_CODE": "Create promotion code",
        "DELETE_PROMO_CODE": "Delete promotion code",
        "DELETE_CONFIRM_PROMO_CODE": "Do you want to delete this promotion code?",
        "ERROR_INPUT_SOURCE": "Please choose at least one."
    },
    "upload_document": {
        "NO_FILE_CHOSEN": "No file chosen",
        "Limit_size_upload": "File size exceeds the limit allowed {0}Mb."
    },
    "payment_info": {
        "member_since": "Member since",
        "package_name": "Package name",
        "last_payment": "Last payment",
        "credit_card": "Credit card",
        "last_digits": "Last 4 digits",
        "default": "Default",
        "add_new_card": "Add new card",
        "history": "History",
        "transaction_date": "Transaction date",
        "transaction_id": "Transaction ID",
        "billing_cycle": "Billing cycle",
        "amount": "Amount",
        "payment_history": "Billing history",
        "card_information": "Card information",
        "card_holder": "Card holder",
        "card_number": "Card number",
        "expiration_date": "Expiration date",
        "cvv": "CVV"
    },
    "search_by": {
        "group_id": "Group ID",
        "booking_id": "Booking #",
        "recurring_id": "Recurring #",
        "ExternalID": "External #",
        "tripId": "Trip #",
        "driver_name": "Driver name",
        "driver_phone_number": "Driver phone number",
        "license_plate": "Car Registration Number",
        "vehicle": "Vehicle",
        "operator": "Operator",
        "passenger_email": "Passenger email",
        "passenger_name": "Passenger name",
        "passenger_phone_number": "Passenger phone number",
        "destination": "Destination",
        "pick_up": "Pick-up",
        "flight_number": "Flight #",
        "book_from": "Booking from",
        "note": "Note",
        "email": "Email",
        "phone": "Phone"
    },
    "withdrawal": {
        "transferToEWallet": "Transferred to external e-wallet *{0}",
        "channelCode": "Channel code",
        "channelName": "Channel name",
        "reversed": "Reversed",
        "requestTime": "Request time",
        "referenceId": "Reference #",
        "driverName": "Driver name",
        "phoneNumber": "Phone number",
        "company": "Company",
        "description": "Description",
        "requestAmount": "Request amount",
        "transferAmount": "Transfer amount",
        "currentBalance": "Current balance",
        "currency": "Currency",
        "action": "Action",
        "approve": "Approve",
        "reject": "Reject",
        "transferToBankAccount": "Transferred to bank account *{0}",
        "transferToWingBank": "Transferred to Wing bank ID *{0}",
        "transferToCreditWallet": "Transferred to credit wallet",
        "reason": "Reason",
        "search": "Driver name, phone, company, transaction ID, reference #",
        "message": {
            "approve": "Are you sure you want to pay this driver an amount of {0}",
            "approveMulti": "Are you sure you want to pay selected driver(s)?",
            "approveSuccess": "Selected request has been paid",
            "approveFail": "Selected request was not successful. Please try again.",
            "approveMultiSuccess": "{0} out of {1} selected request(s) has been paid.",
            "approveMultiFail": "{0} out of {1} selected request(s) was not successful. Please try again.",
            "buttonConfirmTitle": "Yes",
            "closeButtonText": "No",
            "pleaseInputReason": "Please input reason",
            "rejectSuccess": "Driver's request has been rejected",
            "rejectFail": "Fail to reject the selected request"
        },
        "getDataFailure": "Can't get data",
        "rejectRequest": "Reject request"
    },
    "dynamicSurcharge": {
        "operationZone": "Operation zone",
        "activeSurcharge": "Active surcharge",
        "surcharge": "Surcharge",
        "surchargeName": "Surcharge name",
        "parameter": "Parameter (e.g 1.x)",
        "pickupPoint": "Pickup zone",
        "dropOffPoint": "Drop-off zone",
        "delete_surcharge_success": "Surcharge has been deleted successfully",
        "delete_surcharge_fail": "The errors occurred while deleting surcharge",
        "update_surcharge_fail": "The errors occurred while updating surcharge",
        "update_surcharge_success": "Surcharge has been updated successfully",
        "create_surcharge_fail": "The errors occurred while creating surcharge",
        "create_surcharge_success": "Surcharge has been created successfully",
        "active_surcharge_fail": "The errors occurred while activating surcharge",
        "active_surcharge_success": "Surcharge has been activated successfully",
        "deactive_surcharge_fail": "The errors occurred while deactivating surcharge",
        "deactive_surcharge_success": "Surcharge has been deactivated successfully",
        "delete_surcharge": "Delete surcharge",
        "delete_confirm_message": "Are you sure you want to delete the selected surcharge?",
        "note": "*Note: this is how much your basic fare will be multiplied by. Example: a fare of $20 would be $30 when parameter is at 1.5x"
    },
    "dynamicFare": {
        "operationZone": "Operation zone",
        "activeFare": "Active fare",
        "fare": "Fare",
        "fareName": "Fare name",
        "factor": "Multiply by factor",
        "fix_amount": "Fixed amount",
        "parameter": "Parameter (e.g 1.x)",
        "delete_fare_success": "Fare has been deleted successfully",
        "delete_fare_fail": "The errors occurred while deleting fare",
        "update_fare_fail": "The errors occurred while updating fare",
        "update_fare_success": "Fare has been updated successfully",
        "create_fare_fail": "The errors occurred while creating fare",
        "create_fare_success": "Fare has been created successfully",
        "active_fare_fail": "The errors occurred while activating fare",
        "active_fare_success": "Fare has been activated successfully",
        "deactive_fare_fail": "The errors occurred while deactivating fare",
        "deactive_fare_success": "Fare has been deactivated successfully",
        "delete_fare": "Delete fare",
        "delete_confirm_message": "Are you sure you want to delete the selected fare?",
        "note": "*Note: this is how much your basic fare will be multiplied by. Example: a fare of $20 would be $30 when parameter is at 1.5x",
        "note_amount": "*Note: this is how much your basic fare will be added. Example: a fare of $20 would be $25 when set amount is $5"
    },
    "notifications": {
        "search": "Search booking ID, external ID",
        "searchProviderName": "Search provider name",
        "statusItem": {
            "all": "All notifications",
            "canceledByDriver": "Transport - Canceled by Driver",
            "canceledByPartner": "Affiliate - Canceled by partner",
            "reservationReminder": "Reservation reminder",
            "localPending": "Local - Pending",
            "reservationIsNotStarted": "Reservation isn't started",
            "partnerRequestsCars": "Partner request cars",
            "timeout": "Timeout",
            "noShow": "Affiliate - No show",
            "affiliatePending": "Affiliate - Pending",
            "affiliatePaymentFailed": "Affiliate Payment Failed",
            "affiliateTimeout": "Affiliate Timeout",
            "flightApi": "Flight API",
            "DebtWriteOff": "Debt write-off",
            "driverCancelTrip": "Intercity - Canceled by Driver",
            "minimumSeatRequired": "Minimum seats required",
            "newFarmInJobFrom3rd": "Booking.com - New booking",
            "canceledJobBy3rd": "Booking.com - Canceled",
            "updatedJobBy3rd": "Booking.com - Updated",
            "newFarmInJobFromHolidayTaxis": "HolidayTaxis - New booking",
            "canceledJobByHolidayTaxis": "HolidayTaxis - Canceled",
            "updatedJobByHolidayTaxis": "HolidayTaxis - Updated",
            "newFarmInJobFromKarhoo": "Karhoo - New booking",
            "canceledJobByKarhoo": "Karhoo - Canceled",
            "updatedJobByKarhoo": "Karhoo - Updated"
        },
        "sos": {
            "sosSearch": "Search booking ID, pax name, driver name",
            "statusItem": {
                "all": "All",
                "driver": "Drivers",
                "passenger": "Passengers",
                "crew": "Crew"
            }
        }
    },
    "smartData": {
        "No": "No.",
        "driverName": "Name",
        "phone": "Phone",
        "rating": "Rating",
        "completed": "Completed",
        "noShow": "No-show",
        "incident": "Incident",
        "canceled": "Canceled by driver",
        "accepted": "Accepted",
        "reject": "Reject",
        "ignore": "Ignore",
        "earning": "Earning",
        "query": {
            "top20": "Top 20",
            "top50": "Top 50",
            "top100": "Top 100",
            "bottom20": "Bottom 20",
            "bottom50": "Bottom 50",
            "bottom100": "Bottom 100"
        },
        "status": {
            "canceled": "Canceled by driver",
            "noShow": "No-show",
            "incident": "Incident",
            "completed": "Completed",
            "earning": "Earning",
            "reject": "Reject",
            "ignore": "Ignore",
            "accepted": "Accepted"
        }
    },
    "Reject": "No Show",
    "Do not allow Driver to start reservat": "Affiliation",
    "intercityRate": {
        "Delete_intercity_rate": "Delete intercity rate",
        "edit_intercity_rate": "Edit intercity rate",
        "view_intercity_rate": "View intercity rate",
        "add_intercity_rate": "Add intercity rate",
        "Delete_confirm_intercity_rate": "Do you want to delete this item?",
        "Update_intercity_rate_success": "Intercity rate has been updated successfully",
        "Update_intercity_rate_fail": "The errors occurred while updating intercity rate",
        "Created_intercity_rate_success": "Intercity rate has been created successfully",
        "Created_intercity_rate_fail": "The errors occurred while creating intercity rate",
        "Update_intercity_rate_route_success": "Intercity rate route has been updated successfully",
        "Update_intercity_rate_route_fail": "The errors occurred while updating intercity route",
        "Created_intercity_rate_route_success": "Intercity rate route has been created successfully",
        "Created_intercity_rate_route_fail": "The errors occurred while creating intercity route",
        "Loading_intercity_rate_fail": "The errors occurred while loading intercity rate detail",
        "Delete_intercity_rate_route_fail": "The errors occurred while deleting intercity rate route",
        "Delete_intercity_rate_success": "Intercity rate has been deleted successfully",
        "Delete_intercity_rate_fail": "The errors occurred while deleting intercity rate"
    },
    "intercityRoute": {
        "alias": "Alias",
        "routeNameHint": "To change route name, please go to Settings > Zone > Intercity and change name of city zones accordingly.",
        "pricePerSeat": "Price per seat (tax excluded)",
        "distance": "Distance",
        "duration": "Duration",
        "cancellationPenalty": "Penalty amount per seat",
        "noShow": "Pax/partner no show",
        "penalty": "Penalty amount per seat",
        "no_show_tooltip": "The amount customer will be charged per seat. In addition to the penalty customer will pay tax or tech fee if applied",
        "firstLocation": "First location",
        "secondLocation": "Second location",
        "routeOneSetup": "Setup route 1",
        "routetwoSetup": "Setup route 2",
        "schedules": "Schedules",
        "date": "Date",
        "time": "Time",
        "editSchedule": "Edit schedule",
        "addSchedule": "Add schedule",
        "requestTimeEnable": "Allow customer to set request time",
        "extraFee": "Extra fee",
        "hideRouteEnable": "Hide this route",
        "timeRange": "Time range",
        "workingTime": "Working time",
        "applyForOtherDays": "Apply for other days",
        "limitSeatLuggage": "Limit seat / luggage",
        "warningMinimumSeats": "Minimum seats required",
        "warningMinimumSeatsHint": "Minimum number of seats required to make a trip happen",
        "allowStartLessMinRequiredSeats": "Driver to start trip regardless of the minimum seat requirement",
        "allowStartLessMinRequiredSeatsHint": "Allow driver to start the trip even if the requested number of seats is less than the minimum seat required",
        "freeLuggagePerSeat": "Free luggage per seat",
        "freeLuggagePerSeatHint": "Number of free luggage included per seat",
        "allowToAddExtraLuggage": "Add additional luggage",
        "allowToAddExtraLuggageHint": "Allow Passenger to add additional luggages",
        "feePerExtraLuggage": "Fee per extra luggage",
        "Delete_intercity_route": "Delete intercity route",
        "Delete_confirm_intercity_route": "Do you want to delete this route?",
        "Update_intercity_route_fail": "The errors occurred while updating intercity route",
        "Update_intercity_route_success": "Intercity route has been updated successfully",
        "created_intercity_route_success": "Intercity route has been created successfully",
        "created_intercity_route_fail": "The errors occurred while creating intercity route",
        "Delete_intercity_route_success": "Intercity route has been deleted successfully",
        "Delete_intercity_route_fail": "The errors occurred while deleting intercity route",
        "penalty_less_than_or_equal_to_price": "Please enter a value less than or equal to price per seat value.",
        "scheduleTimeOverlap": "Schedule time must not overlap with each others",
        "allows_adding_extra": "Allows adding extra charge when customer request time which isn't scheduled by fleet.",
        "this_is_to_limit_users": "This is to limit users (i.e. customer, driver, operator) from creating new booking or trip on this route. Existing bookings or trips are remained as reserved"
    },
    "dayOfWeek": {
        "0": "Sunday",
        "1": "Monday",
        "2": "Tuesday",
        "3": "Wednesday",
        "4": "Thursday",
        "5": "Friday",
        "6": "Saturday"
    },
    "intercityBooking": {
        "route": "Route",
        "departureDate": "Departure date",
        "departureTime": "Departure time",
        "Departure": "Departure",
        "Arrival": "Arrival",
        "RefundFare": "Refund fare",
        "intercityZoneNote": "The zones you create here are only used to setup your intercity rates.",
        "zoneNameHint": "Please keep your zone name short and clear so that your customers can search and select easily.",
        "zoneUploadNote_2": "*  It is recommended that image resolution should be 1095x360 and file size less than 5MB.",
        "Complete_booking": "Complete Booking",
        "ConfirmCancel": "Confirm cancel"
    },
    "deliveryRate": {
        "Delete_delivery_rate": "Delete delivery rate",
        "edit_delivery_rate": "Edit delivery rate",
        "view_delivery_rate": "View delivery rate",
        "add_delivery_rate": "Add delivery rate",
        "Delete_confirm_delivery_rate": "Do you want to delete this item?",
        "Update_delivery_rate_success": "Delivery rate has been updated successfully",
        "Update_delivery_rate_fail": "The errors occurred while updating delivery rate",
        "Created_delivery_rate_success": "Delivery rate has been created successfully",
        "Created_delivery_rate_fail": "The errors occurred while creating delivery rate",
        "Update_delivery_rate_route_success": "Delivery rate route has been updated successfully",
        "Update_delivery_rate_route_fail": "The errors occurred while updating delivery route",
        "Created_delivery_rate_route_success": "Delivery rate route has been created successfully",
        "Created_delivery_rate_route_fail": "The errors occurred while creating delivery route",
        "Loading_delivery_rate_fail": "The errors occurred while loading delivery rate detail",
        "Delete_delivery_rate_route_fail": "The errors occurred while deleting delivery rate route",
        "Delete_delivery_rate_success": "Delivery rate has been deleted successfully",
        "Delete_delivery_rate_fail": "The errors occurred while deleting delivery rate",
        "distance_km": "Fee per km",
        "distance_mile": "Fee per mil",
        "flat": "Flat Fee",
        "startingFee": "Starting fee",
        "feeFirstDistance": "Fee first distance",
        "feeSecondDistance": "Fee second distance",
        "feeThirdDistance": "Fee third distance",
        "feeAfterThirdDistance": "Fee after third distance",
        "feePerMinute": "Fare per minute",
        "feeMinimum": "Fee minimum",
        "feeFirstStop": "Fee for first stop",
        "feeExtraStop": "Fee for each extra stop"
    },
    "pwaSettings": {
        "pwa": "PWA",
        "iconName": "Icon & name",
        "logoBackgroundColor": "Logo, background & color",
        "longName": "Long name",
        "shortName": "Short name",
        "icon192": "Icon on Home Screen (192x192)",
        "icon512": "Icon on Splash Screen",
        "logo": "Logo (300x300)",
        "logoMenu": "Logo menu (300x300)",
        "backgroundImage": "Background image - Transport (1024x500)",
        "backgroundImageDelivery": "Background image - Delivery (1024x500)",
        "mainColor": "Main color",
        "secondaryColor": "Secondary color",
        "showMainColor": "Show main color instead of background image",
        "success": "PWA setting has been updated successfully",
        "failed": "Failed to update PWA setting. Please try again!",
        "errorUploadFileSize": "The required dimensions are {0}x{0} px.",
        "hideWBInstallAppBtn": "Hide install app button",
        "enableBookNow": "Now",
        "enableGuestCheckout": "Guest checkout",
        "enableBookLater": "Later",
        "enableHideRecipient": "Hide recipient info (Package content, Delivery instructions)",
        "pwaLink": "PWA for Passenger",
        "partnerLink": "PWA for Partner",
        "pwaDriverLink": "PWA for Driver",
        "providerSignUp": "Driver sign-up link",
        "AdsTracking": "Ads Tracking",
        "googleId": "Google Tag ID",
        "placeholdergoogleId": "Enter Google Tag ID",
        "metaId": "Meta Pixel ID",
        "placeholdermetaId": "Enter Meta Pixel ID"
    },
    "emailConfig": {
        "emailServer": "Email server",
        "default": "Default",
        "customized": "Customized",
        "fromName": "From name",
        "email": "From email",
        "replyTo": "Reply to",
        "userAuth": "Username",
        "password": "Password",
        "smtpServer": "SMTP server",
        "port": "SMTP port",
        "isAuthenticated": "Authenticated",
        "option": "Enable Option",
        "none": "None",
        "tls": "TLS",
        "ssl": "SSL",
        "save": "Save",
        "cancel": "Cancel",
        "success": "Update email server success",
        "fail": "Update email server fail",
        "loadFail": "Load email server detail fail"
    },
    "driverFields": {
        "Load_fail": "",
        "Edit": "Edit",
        "View": "View",
        "Cancel": "Cancel",
        "show": "Show",
        "hide": "Hide",
        "EDIT_DRIVER_FIELDS": "Edit Driver Fields",
        "DETAIL_DRIVER_FIELDS": "Detail Driver Fields",
        "ADD_DRIVER_FIELDS": "Add Driver Fields",
        "fieldKey": "Fields key",
        "fieldName": "Fields name",
        "availableCC": "Available in Command Center",
        "availableDrvSignUp": "Available in Driver sign up",
        "availableCompanyCar": "Company Car - Transport drivers",
        "deliveryCompany": "Company Car - Delivery drivers",
        "availableIndividualCar": "Individual Car - Transport drivers",
        "deliveryIndividual": "Individual Car - Delivery drivers",
        "required": "Required",
        "Update_fail": "The errors occurred while updating driver fields",
        "Update_success": "The Driver Fields has been updated successfully",
        "FieldKey": "Fields Key",
        "FieldName": "Fields Name (American English)",
        "CommandCenter": "Available In Command Center",
        "SignUp": "Available In Driver Sign Up",
        "CompanyTransport": "Company Car - Transport",
        "CompanyDelivery": "Company Car - Delivery",
        "IndividualTransport": "Individual Car - Transport",
        "IndividualDelivery": "Individual Car - Delivery",
        "Require": "Required",
        "Actions": "Actions",
        "fieldType": "Fields Type",
        "default": "Default",
        "customized": "Customized"
    },
    "driverDocument": {
        "Load_fail": "",
        "Edit": "Edit",
        "View": "View",
        "Cancel": "Cancel",
        "show": "Show",
        "hide": "Hide",
        "EDIT_DRIVER_DOCUMENT": "Edit Driver Document",
        "DETAIL_DRIVER_DOCUMENT": "Detail Driver Document",
        "ADD_DRIVER_DOCUMENT": "Add Driver Document",
        "documentKey": "Document key",
        "documentName": "Document name",
        "documentNameDetail": {
            "zh-HK": "Traditional Chinese",
            "it-IT": "Standard Italian",
            "es-ES": "Spanish",
            "en-US": "American English",
            "fr-FR": "Standard French",
            "zh-CN": "Simplified Chinese",
            "id-ID": "Bahasa Indonesia",
            "vi-VN": "Vietnamese",
            "ur-PK": "Urdu (Islamic Republic of Pakistan)",
            "ms-MY": "Malay (Malaysia)",
            "ar-AR": "Arabic",
            "en-GB": "English (United Kingdom)",
            "nb-NO": "Norwegian",
            "am-ET": "Amharic",
            "ro-RO": "Romanian",
            "th-TH": "Thai",
            "ta-IN": "Tamil",
            "hi-IN": "Hindi",
            "te-IN": "Telugu",
            "ka-GE": "Georgian",
            "ru-RU": "Russian",
            "kn-IN": "Kannada"
        },
        "isIndividual": "Company car - Transport drivers",
        "deliveryCompany": "Company car - Delivery drivers",
        "isOwner": "Individual driver - Transport drivers",
        "deliveryIndividual": "Individual driver - Delivery drivers",
        "availableCC": "Available in Command Center",
        "availableDrvSignUp": "Available in Driver sign up",
        "isRequired": "Required",
        "isExpiredDate": "Expiry date - Command Center",
        "expiredDateForDrvSignUp": "Expiry date - Driver signup",
        "requireExpiredDate": "Require Expiry Date",
        "requireExpiredDateForDrvSignUp": "Require Expiry Date",
        "requireDocumentId": "Require Document ID",
        "documentId": "Document ID - Command Center",
        "documentIdForDrvSignUp": "Document ID - Driver signup",
        "Update_fail": "The errors occurred while updating driver document",
        "Update_success": "The Driver Document has been updated successfully",
        "DocumentKey": "Document Key",
        "DocumentName": "Document Name (American English)",
        "CommandCenter": "Available In Command Center",
        "SignUp": "Available In Driver Sign Up",
        "CompanyTransport": "Company Car - Transport",
        "CompanyDelivery": "Company Car - Delivery",
        "IndividualTransport": "Individual Car - Transport",
        "IndividualDelivery": "Individual Car - Delivery",
        "Require": "Required",
        "Actions": "Actions"
    },
    "holidayTaxis": {
        "title": "HolidayTaxis API Credentials",
        "note": "Communicate with HolidayTaxis's technical team to provide you with api key to connect to their system.",
        "apiKey": "API key",
        "placeHolderApiKey": "Enter api key"
    },
    "trailNotes": {
        "maxLengthFiles": "Maximum 3 files per comment",
        "maxSizeFiles": "Maximum 10MB per file",
        "typeFiles": "File type supported: pdf, doc, docx, csv, xls, xlsx, jpeg, jpg, png, webp, txt, note",
        "maxComments": "Maximum 20 comments per booking",
        "bookingActivity": "Booking Activity",
        "viewAll": "View all",
        "operator": "Operator",
        "driver": "Driver",
        "passenger": "Customer",
        "pHInput": "Add a new comment. You can click save to save the note. You can also drag an drop multiple files here.",
        "addFiles": "Add files",
        "save": "Save",
        "inputNotes": "Please input text",
        "successDeleteTrailNotes": "Delete trail notes successfully",
        "successCreateTrailNotes": "Create trail notes successfully",
        "deleteTrailNote": "Delete a trail note",
        "msgDeleteTrailNote": "Are you sure you want to delete this?"
    },
    "supplier": {
        "permissionRequired": "Operator need to select a permission",
        "offerDetail": "Offer Detail",
        "orderDetail": "Order Detail",
        "bookingNumber": "Booking Number",
        "date": "Date",
        "pickupLocation": "Pick up Location",
        "dropoffLocation": "Drop off Location",
        "passenger": "Passenger",
        "luggage": "Luggage",
        "pickupService": "Pickup Service",
        "pickupSignage": "Pickup Signage",
        "flightNumber": "Flight Number",
        "departure": "Departure",
        "arrival": "Arrival",
        "terminal": "Terminal",
        "gate": "Gate",
        "meetGreet": "Meet & Greet",
        "specialRequests": "Special Requests",
        "additionalServices": "Additional Services",
        "price": "Price",
        "prices": "Prices",
        "guest": "Guest",
        "serviceClass": "Service Class",
        "distance": "Distance",
        "duration": "Duration",
        "acceptOffer": "Accept Offer",
        "updateBooking": "Update Booking",
        "package": "Package",
        "route": "Route",
        "assignedChauffer": "Assigned Chauffeur",
        "assignedVehicle": "Assigned Vehicle",
        "logs": "Logs",
        "link": "Links",
        "desLink": "Click to copy link",
        "trackLink": "Track link",
        "driverLink": "Driver link",
        "close": "Close",
        "links": "Links",
        "linksDes": "Click to copy link",
        "status": "Status",
        "rating": "Rating",
        "permissions": "Permissions",
        "permissionsNote": "Assign different levels of authorization to your team. You can choose whether your operators have visibility of open offers or only assignments already accepted.",
        "owner": "Owner",
        "ownerNote": "Full Access",
        "manageUsers": "Manage Users",
        "manageUsersNote": "Add/remove new operators",
        "manageDrivers": "Manage Drivers",
        "manageDriversNote": "Add/remove drivers",
        "manageVehicles": "Manage Vehicles",
        "manageVehiclesNote": "Add/remove vehicles",
        "mapView": "Live Map",
        "mapViewNote": "Track drivers and active trips live on the map.",
        "calendarView": "Calendar View",
        "calendarViewNote": "View calendar of all assigned trips.",
        "bookingView": "Booking View",
        "bookingViewNote": "View and manage current bookings, assign drivers, and allocate vehicles.",
        "offersView": "Offers View",
        "offersViewNote": "Accept new job offers.",
        "pricing": "Pricing",
        "pricingNote": "View trip prices",
        "userDetail": "User Detail",
        "addOperator": "Add Operator",
        "updateOperator": "Update Operator",
        "supplier": "Supplier",
        "zone": "Zone",
        "emails": "Emails",
        "email": "Email",
        "phoneNumber": "Phone Number",
        "Deactivate": "Deactivate",
        "Activate": "Activate",
        "ALERT_SELECT_DEACTIVATE": "Please select supplier to deactivate",
        "ALERT_SELECT_ACTIVATE": "Please select supplier to activate",
        "CONFIRM_DEACTIVATE_MULTI": "Do you want to deactivate these suppliers?",
        "CONFIRM_ACTIVATE_MULTI": "Do you want to activate these suppliers?",
        "Update_supplier_user_status_success": "Supplier status has been updated successfully",
        "Update_supplier_user_status_fail": "The errors occurred while updating supplier status",
        "agent": "Agent",
        "Supplier": "Supplier",
        "addSupplier": "Add Supplier",
        "detailSupplier": "Supplier Detail",
        "addUser": "Add User",
        "Add": "Add",
        "ContactInfo": "Contact Info",
        "policy": "Policy",
        "users": "Users",
        "setting": "Setting",
        "zones": "Zones",
        "actions": "Actions",
        "companyName": "Company Name",
        "corporationType": "Corporation Type",
        "country": "Country",
        "ADMIN_INFORMATION": "Admin Information",
        "firstName": "First Name",
        "lastName": "Last Name",
        "commissionValue": "Fleet commission",
        "commissionValueDes": "Set commissions the fleet earns for every complete order.",
        "paymentMethodsApplyDes": "Job specific payment methods that suppliers can process:",
        "bookingSettings": "Booking Settings",
        "bookingSettingsDes": "Configure job visibility, release times, and delays for efficient supplier operations.",
        "advanceBookingVisibilityTooltip": "Specify how many days in advance suppliers can see the orders.",
        "delayBroadcastToolTip": "Set a delay time from the release time if set or the start of the day (00:00). This delay controls when the jobs become visible to the suppliers after the initial release time.",
        "releaseTimeToolTip": "Set the time of day when jobs are released to suppliers, starting from the first day they can see bookings. Ensure this time allows suppliers enough preparation time.",
        "otherSettings": "Other Settings",
        "hideFare": "Driver - Always hide fare",
        "hideFareDes": "Hide the fare for all drivers of this supplier.",
        "supplierJobRequired": "Supplier Job Acceptance Required",
        "supplierJobRequiredDes": "Suppliers must acknowledge and accept jobs before they can assign a driver/vehicle or allow the job to be viewed by drivers in the marketplace.",
        "supplierApproval": "Suppliers Driver/Vehicle Approval",
        "supplierApprovalDes": "Allow supplier operators to activate drivers and vehicles within their fleet.",
        "emailsApply": "Email Notifications",
        "emailsApplyDes": "Emails to send new jobs, booking updates.",
        "address": "Address",
        "zipCode": "Zip/Postal Code",
        "city": "City",
        "state": "State/Province",
        "tax": "Tax Identification Number",
        "businessRegistration": "Business Registration Number",
        "vatNumber": "VAT ID/Number",
        "mobile": "Mobile",
        "editOperator": "Edit Operator",
        "searchEmail": "Search Email",
        "acceptOfferSuccess": "Accept offer successfully",
        "acceptOfferFail": "Booking not found or another supplier has accepted the booking",
        "driverPayout": "Driver payout",
        "payout": "Payout",
        "totalPayout": "Total payout",
        "totalPayoutDes": "The total payout includes the base payout amount plus any additional fees you have chosen to allocate to your providers.",
        "supplierPayout": "Supplier payout",
        "driverTip": "Driver tip",
        "cancel": "Cancel",
        "save": "Save",
        "activeSupplier": "Active supplier",
        "userName": "Username",
        "bookingCompletedView": "Completed Bookings",
        "bookingCompletedViewNote": "View the history of all completed bookings.",
        "driverEarning": "Driver earning"
    },
    "noResultFound": "No results found"
};
