import { getTravelerModeByCarType } from '../components/bookingDetail/bookFunction/serviceRequest';
import {
  getDistanceMatrixAction,
  getPlaceDetail,
  getDirections,
  getGeoCoding,
  getDirectionFromJupiter,
} from '../actions/mapProviderAction';
import { QQWebservice, QQUltis } from '../components/qqMap';
import { PROVIDER_MAP_TYPE } from '../constants/commondata';
import { clearParams, getLanguageAutoCompleteSearch } from './commonFunctions'
import auth from '../reducers/auth';
import { data } from 'jquery';

/* global google */
const convert = require('convert-units');

export const geoPlaceDetailMapProvider = ({
  auth,
  placeid,
  sessionToken,
  callback,
  language,
  isTencent = false,
  bookId
}) => {
  if(!placeid) return
  const OK = isTencent ? 'OK' : google.maps.GeocoderStatus.OK;
  const params = {
    placeid,
    fleetId: auth?.selectedFleet?.fleetId,
    sessiontoken: sessionToken,
    userId: auth?.user?._id,
    channel: getChannelMap(),
    language: getLanguageAutoCompleteSearch(language?.locale),
    forceProvider: isTencent ? PROVIDER_MAP_TYPE.tencent : '',
    bookId
  }
  getPlaceDetail(clearParams(params))
  .then((response) => {
    const { result = {}, status } = response?.res?? {};

    if (status !== OK) {
      callback({ status }, null, null);
      return;
    }
    let cityObject = result.address_components.find((item) => item.types.includes('locality'));

    const countryObject = result.address_components.find((item) => item.types.includes('country'));

    if (!cityObject) {
      cityObject = result.address_components.find((item) => item.types.includes('administrative_area_level_1'));
    }
    const address_components = result.address_components;
    const latLng = {
      lat: result.geometry.location.lat,
      lng: result.geometry.location.lng,
      zipCode: getZipCode(result),
      city: getCountryCityFromPlaces(result),
      countryCode: countryObject ? countryObject.short_name : '',
      cityName: cityObject ? cityObject.long_name : '',
      formatted_address: result?.formatted_address,
      addressDetails: {
        cityName: getAdressDetails(
          address_components,
          'administrative_area_level_1'
        ).long_name
          ? getAdressDetails(address_components, 'administrative_area_level_1')
            .long_name
          : '',
        country: getAdressDetails(address_components, 'country').long_name
          ? getAdressDetails(address_components, 'country').long_name
          : '',
        floor: getAdressDetails(address_components, 'floor').long_name
          ? getAdressDetails(address_components, 'floor').long_name
          : '',
        pointOfInterest: getAdressDetails(
          address_components,
          'point_of_interest'
        ).long_name
          ? getAdressDetails(address_components, 'point_of_interest').long_name
          : '',
        political: getAdressDetails(address_components, 'political').long_name
          ? getAdressDetails(address_components, 'political').long_name
          : '',
        postalCode: getAdressDetails(address_components, 'postal_code')
          .long_name
          ? getAdressDetails(address_components, 'postal_code').long_name
          : '',
        room: getAdressDetails(address_components, 'room').long_name
          ? getAdressDetails(address_components, 'room').long_name
          : '',
        route: getAdressDetails(address_components, 'route').long_name
          ? getAdressDetails(address_components, 'route').long_name
          : '',
        streetNumber: getAdressDetails(address_components, 'street_number')
          .long_name
          ? getAdressDetails(address_components, 'street_number').long_name
          : '',
        countryCode: getAdressDetails(address_components, 'country').short_name
          ? getAdressDetails(address_components, 'country').short_name
          : '',
      },
    };
    callback(null, latLng, result);
  });
};

function getCityName(place) {
  let cityObject = place.address_components.find((item) => item.types.includes('locality'));
  if (!cityObject) {
    cityObject = place.address_components.find((item) => item.types.includes('administrative_area_level_1'));
  }
  return cityObject ? cityObject.long_name : '';
}
export function getZipCode(place) {
  let zipcode = '';
  for (let i = 0; i < place.address_components.length; i++) {
    const item = place.address_components[i];
    if (['postal_code', 'postal_code_prefix'].includes(item?.types?.[0])) {
      zipcode = item.long_name;
      break;
    }
    if (item.types[0] == 'locality') {
      zipcode = item.short_name;
    }
  }
  return zipcode;
}
export function getCountryCityFromPlaces(place) {
  const address = place.address_components;
  let city_country;
  let zipcode = '';
  let locality = '';
  let adminarealv1 = '';
  let country = '';
  for (let i = 0; i < address.length; i++) {
    if (address[i].types[0] === 'postal_code') {
      zipcode = address[i].short_name;
    }
    if (address[i].types[0] === 'locality') {
      locality = address[i].long_name;
    }
    if (address[i].types[0] === 'country') {
      country = address[i].long_name;
    }
    if (address[i].types[0] === 'administrative_area_level_1') {
      adminarealv1 = address[i].long_name;
    }
  }
  city_country = zipcode + '_' + locality + '_' + adminarealv1 + '_' + country;
  return city_country;
}
export function getChannelMap(fleetId) {
  return `cc`;
}

export const getDirectionMapProviderFromJupiter = async ({
  points,
  travelMode,
  callback,
  isTencent = false,
  bookId,
  auth,
  vehicleType,
  jobType,
  deliveryData
}) => {
  if (points.length >= 2) {
    const params = getParamDirectionByBookType({
      points,
      travelMode,
      callback,
      isTencent,
      bookId,
      auth,
      vehicleType,
      jobType,
      deliveryData
    })
    const result = await getDirectionFromJupiter(params)
    callback(result)
  }
}

export function getDirectionMapProvider({
  points,
  travelMode,
  unit,
  shortestPathEstimation,
  fleetId,
  callback,
  isTencent = false,
  bookId,
  auth
}) {
  if (!fleetId) return;
  if (points.length >= 2) {
    let waypoints = 'optimize:false';
    points
      .filter((point, index) => point && index > 0 && index < points.length - 1)
      .map((pos) => {
        if (pos.lat && pos.lng) {
          waypoints = `${waypoints}|${pos.lat},${pos.lng}`;
        }
      });
    const params = {
      origin: `${points[0].lat},${points[0].lng}`,
      destination: `${points[points.length - 1].lat},${
        points[points.length - 1].lng
      }`,
      alternatives: shortestPathEstimation,
      waypoints,
      mode: travelMode,
      fleetId,
      unitSystem: unit,
      channel: getChannelMap(fleetId),
      forceProvider: isTencent ? PROVIDER_MAP_TYPE.tencent : '',
      userId: auth?.user?._id
    }
    if(bookId) params.bookId = bookId
    getDirections(clearParams(params)).then((res) => {
      const response = res.res && res.res;
      const status = response.status;
      if (shortestPathEstimation) {
        let distance = 0;
        let duration = 0;
        let distanceTxt = '';
        let durationTxt = '';
        let shortestPath = null;
        if (response) {
          const routes = response.routes;
          if (routes != null && routes.length > 0) {
            let minDistance = 0;
            let minDuration = 0;
            for (let i = 0; i < routes.length; i++) {
              const legs = routes[i].legs;
              for (let j = 0; j < legs.length; j++) {
                minDistance += legs[j].distance.value;
                minDuration += legs[j].duration.value;
              }
              if (distance == 0 || distance > minDistance) {
                distance = minDistance;
                duration = minDuration;
                distanceTxt = legs[0].distance.text;
                durationTxt = legs[0].duration.text;
                shortestPath = routes[i];
              } else if (distance == minDistance && duration > minDuration) {
                duration = minDuration;
              }
              minDistance = 0;
              minDuration = 0;
            }
          }
          response.routes = [shortestPath];
        }
      }
      if (status === 'ZERO_RESULTS' && travelMode !== 'DRIVING') {
        getDirectionMapProvider({
          points,
          travelMode: 'DRIVING',
          unit,
          shortestPathEstimation,
          fleetId,
          callback,
          isTencent
        });
      } else {
        response.request = {
          travelMode,
          unitSystem: unit,
        };
        callback([response], status);
      }
    });
  }
}

export function getDirection(
  points,
  travelMode,
  unit,
  shortestPathEstimation,
  callback
) {
  const directionsService = new google.maps.DirectionsService();
  const route = null;
  if (points.length >= 2) {
    const waypoints = points
      .filter((point, index) => point && index > 0 && index < points.length - 1)
      .map((pos) => ({
        location: new google.maps.LatLng(pos.lat, pos.lng),
        stopover: true,
      }));
    directionsService.route(
      {
        origin: new google.maps.LatLng(points[0].lat, points[0].lng),
        waypoints,
        optimizeWaypoints: false,
        destination: new google.maps.LatLng(
          points[points.length - 1].lat,
          points[points.length - 1].lng
        ),
        travelMode: travelMode || 'DRIVING',
        unitSystem: unit,
        provideRouteAlternatives: shortestPathEstimation,
      },
      (response, status) => {
        if (shortestPathEstimation) {
          let distance = 0;
          let duration = 0;
          let distanceTxt = '';
          let durationTxt = '';
          let shortestPath = null;
          if (response) {
            const routes = response.routes;
            if (routes != null && routes.length > 0) {
              let minDistance = 0;
              let minDuration = 0;
              for (let i = 0; i < routes.length; i++) {
                const legs = routes[i].legs;
                for (let j = 0; j < legs.length; j++) {
                  minDistance += legs[j].distance.value;
                  minDuration += legs[j].duration.value;
                }
                if (distance == 0 || distance > minDistance) {
                  distance = minDistance;
                  duration = minDuration;
                  distanceTxt = legs[0].distance.text;
                  durationTxt = legs[0].duration.text;
                  shortestPath = routes[i];
                } else if (distance == minDistance && duration > minDuration) {
                  duration = minDuration;
                }
                minDistance = 0;
                minDuration = 0;
              }
            }
            response.routes = [shortestPath];
            // delete response.geocoded_waypoints
            // delete response.request
          }
        }
        if (status === 'ZERO_RESULTS' && travelMode !== 'DRIVING') {
          getDirection(
            points,
            'DRIVING',
            unit,
            shortestPathEstimation,
            callback
          );
        } else {
          callback([response], status);
        }
      }
    );
  }
}
export const getPositionInfomation = async ({latLng, callback, auth, language, bookId, id, isTencent}) => {
  if (typeof latLng.toJSON === 'function') {
    latLng = latLng.toJSON();
  }
  const params = {
    channel: getChannelMap(),
    latlng: `${latLng.lat},${latLng.lng}`,
    language,
    fleetId: auth?.selectedFleet?.fleetId,
    userId: auth?.user?._id,
    bookId,
    forceProvider: isTencent ? PROVIDER_MAP_TYPE.tencent : ''
  }
  const result = await getGeoCoding(clearParams(params));
  if(result.res?.results?.length > 0) {
    const addressInfo = result?.res?.results?.[0] || {}
    const countryObject = addressInfo.address_components.find((item) => item.types.includes('country'));
    const zipCode = getZipCode(addressInfo);
    const city = getCountryCityFromPlaces(addressInfo);
    const cityName = getCityName(addressInfo);
    const address_components = addressInfo.address_components;
    callback(
      addressInfo,
      {
        ...latLng,
        zipCode,
        city,
        cityName,
        country: countryObject ? countryObject.long_name : '',
        countryCode: countryObject ? countryObject.short_name : '',
        addressDetails: {
          cityName: getAdressDetails(
            address_components,
            'administrative_area_level_1'
          ).long_name
            ? getAdressDetails(
              address_components,
              'administrative_area_level_1'
            ).long_name
            : '',
          country: getAdressDetails(address_components, 'country').long_name
            ? getAdressDetails(address_components, 'country').long_name
            : '',
          floor: getAdressDetails(address_components, 'floor').long_name
            ? getAdressDetails(address_components, 'floor').long_name
            : '',
          pointOfInterest: getAdressDetails(
            address_components,
            'point_of_interest'
          ).long_name
            ? getAdressDetails(address_components, 'point_of_interest')
              .long_name
            : '',
          political: getAdressDetails(address_components, 'political')
            .long_name
            ? getAdressDetails(address_components, 'political').long_name
            : '',
          postalCode: getAdressDetails(address_components, 'postal_code')
            .long_name
            ? getAdressDetails(address_components, 'postal_code').long_name
            : '',
          room: getAdressDetails(address_components, 'room').long_name
            ? getAdressDetails(address_components, 'room').long_name
            : '',
          route: getAdressDetails(address_components, 'route').long_name
            ? getAdressDetails(address_components, 'route').long_name
            : '',
          streetNumber: getAdressDetails(address_components, 'street_number')
            .long_name
            ? getAdressDetails(address_components, 'street_number').long_name
            : '',
          countryCode: getAdressDetails(address_components, 'country')
            .short_name
            ? getAdressDetails(address_components, 'country').short_name
            : '',
        },
      },
      id
    );
  }
}

export function getMinWayFromResults(results, vehicleType) {
  let distance = 0;
  let duration = 0;
  let distanceTxt = '';
  let durationTxt = '';
  let shortestPath = null;
  const extraDisDurs = [];
  results.map((result) => {
    if (result) {
      const routes = result.routes;
      if (routes != null && routes.length > 0) {
        let minDistance = 0;
        let minDuration = 0;
        for (let i = 0; i < routes.length; i++) {
          if (!routes[i]) continue;
          const legs = routes[i].legs;
          for (let j = 0; j < legs.length; j++) {
            minDistance += legs[j].distance.value;
            minDuration += legs[j].duration.value;
            if (j < legs.length - 1) {
              extraDisDurs.push({
                distance: legs[j].distance,
                duration: legs[j].duration,
              });
            }
          }
          if (distance == 0 || distance > minDistance) {
            distance = minDistance;
            duration = minDuration;
            distanceTxt = legs[0].distance.text;
            durationTxt = legs[0].duration.text;
            shortestPath = routes[i];
          } else if (distance == minDistance && duration > minDuration) {
            duration = minDuration;
          }
          minDistance = 0;
          minDuration = 0;
        }
      }
      result.routes = [shortestPath];
    }
  });

  const ggFactor = vehicleType && vehicleType.googleETAFactor
    ? vehicleType.googleETAFactor
    : 1;
  let unit = 'km';

  if (results[0] && results[0].request && results[0].request.unitSystem == 1) {
    unit = 'mi';
  }

  const disDur = {
    distance: {
      value: distance,
      text: Math.round(convert(distance).from('m').to(unit) * 10) / 10 + unit,
    },
    duration: {
      value: Math.floor(duration * ggFactor),
      text: toHumanFormat(secondsToTime(Math.floor(duration * ggFactor))),
    },
  };

  if (extraDisDurs.length) {
    disDur.extraDisDurs = extraDisDurs.map((extraDisDur) => ({
      distance: {
        value: extraDisDur.distance.value,
        text:
          Math.round(
            convert(extraDisDur.distance.value).from('m').to(unit) * 10
          )
            / 10
          + unit,
      },
      duration: {
        value: Math.floor(extraDisDur.duration.value * ggFactor),
        text: toHumanFormat(
          secondsToTime(Math.floor(extraDisDur.duration.value * ggFactor))
        ),
      },
    }));
  }

  return disDur;
}

export function getDistanceMatrix({
  origins,
  destinations,
  vehicleType,
  auth,
  callback,
  isTencent
}) {
  // var service = new google.maps.DistanceMatrixService();
  const travelMode = getTravelerModeByCarType(vehicleType);
  let destinationParam = '';
  destinations.map((destination) => {
    if (destinationParam === '') {
      destinationParam = `${destination.lat()},${destination.lng()}`;
    } else {
      destinationParam = `${destinationParam}|${destination.lat()},${destination.lng()}`;
    }
  });
  getDistanceMatrixAction({
    origins: `${origins[0]},${origins[1]}`,
    destinations: destinationParam,
    mode: travelMode,
    fleetId: auth.selectedFleet.fleetId,
    channel: getChannelMap(),
    userId: auth?.user?._id,
    forceProvider: isTencent ? PROVIDER_MAP_TYPE.tencent : ''
  }).then((res) => {
    const { rows = [], status } = res && res.res;
    if (status === 'OK') {
      let minText = '';
      let minValue = 0;
      let closest = 0;
      for (let j = 0; j < rows.length; j++) {
        const shortestRound = rows[j].elements;
        for (let i = 0; i < shortestRound.length; i++) {
          const round = shortestRound[i];
          if (round.status === google.maps.DirectionsStatus.ZERO_RESULTS) break;
          closest = 1;
          if (minValue == 0 || minValue > round.duration.value) {
            const duration = round.duration.value;
            minValue = Math.floor(
              duration * (vehicleType.googleETAFactor || 1)
            ); 
            minText = toHumanFormat(
              secondsToTime(
                Math.floor(duration * (vehicleType.googleETAFactor || 1)) + 60
              )
            );
          }
        }
      }
      if (closest == 1) {
        callback({
          minText,
          closest,
        });
      } else if (travelMode === 'BICYCLING') {
        getDistanceMatrix({
          origins,
          destinations,
          vehicleType,
          auth,
          callback,
          isTencent
        });
      } else {
        callback(null);
      }
    }
  });
}

const findDriverNearestByGroup = async (nearestList) => {
    if(!nearestList || !(nearestList.length > 0)) return []
    const params = _.pick(nearestList[0], ['origins', 'destinations', 'mode', 'fleetId', 'channel', 'userId', 'forceProvider'])
    const res = await getDistanceMatrixAction(clearParams(params));
    const { rows = [], status } = res?.res;
    const dataRespone = nearestList.map(nearest => {
      if(status === 'OK') {
        const vhc = nearest.vehicleDetail;
        let minText = '',
          minValue = 0,
          closest = 0;
        for (let j = 0; j < rows.length; j++) {
          const shortestRound = rows[j].elements;
          for (let i = 0; i < shortestRound.length; i++) {
            const round = shortestRound[i];
            if (round.status !== 'OK') break;
            closest = 1;
            if (minValue == 0 || minValue > round?.duration?.value) {
              const duration = round.duration.value;
              minValue = Math.floor(
                duration * (vhc.googleETAFactor || 1)
              );
              minText = toHumanFormat(
                secondsToTime(
                  Math.floor(duration * (vhc.googleETAFactor || 1)) + 60
                )
              );
            }
          }
        }
        if (closest == 1) {
          return {
            vehicleId: vhc._id,
            vehicleType: vhc.vehicleType,
            minText,
            closest,
          }
        } else {
          return null
        }
      }
    })
    return dataRespone || []
}

export const findDriverNearest = (nearestVehicle) => {
  if(!nearestVehicle || !(nearestVehicle?.length > 0)) return []
  // group vehicle based mode and destibation
  const groupedArray = Object.values(_.groupBy(nearestVehicle, item => `${item.mode}-${item.destinations}`));
  let promiseArr = []
  for (let index = 0; index < groupedArray.length; index++) {
    promiseArr.push(findDriverNearestByGroup(groupedArray[index]))
  }
  if(promiseArr.length === 0) return []
  return Promise.all(promiseArr)
    .then(results => {
      if(results?.length > 0) {
        return _.flatten(results)
      }
      return []
    })
    .catch((error) => {
      return []
    })
}

export const convertDirectionParam = (locations) => {
  let locationsParam = '';
    locations?.map((destination) => {
    if (locationsParam === '') {
      locationsParam = `${destination.lat},${destination.lng}`;
    } else {
      locationsParam = `${locationsParam}|${destination.lat},${destination.lng}`;
    }
  });
  return locationsParam
}

export function toHumanFormat(time) {
  /** *
     * obj = {
        "h": hours,
        "m": minutes,
        "s": seconds
    } */
  let mins = time.m + ' min';
  let hours = time.h + ' hour';
  if (time.m > 1) mins = time.m + ' mins';
  if (time.h > 1) hours = time.h + ' hours';
  if (time.h > 0) return hours + ' ' + mins;
  return mins;
}
export function secondsToTime(secs) {
  secs = Math.round(secs);
  const hours = Math.floor(secs / (60 * 60));

  const divisor_for_minutes = secs % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);

  const obj = {
    h: hours,
    m: minutes,
    s: seconds,
  };
  return obj;
}

export function getDirectionsWithoutETAMinDistance({
  origin,
  waypoints,
  destination,
  optimizeWaypoints,
  travelMode,
  callback,
  auth
}) {
  let waypointsRq = 'optimize:false';
  waypoints.map((waypoint) => {
    waypointsRq = `${waypointsRq}|${waypoint.lat},${waypoint.lng}`;
  });
  getDirections({
    origin: `${origin[0]},${origin[1]}`,
    destination: `${destination[0]},${destination[1]}`,
    alternatives: optimizeWaypoints,
    waypoints: waypointsRq,
    mode: travelMode,
    fleetId: auth?.selectedFleet?.fleetId,
    userId: auth?.user?._id,
    channel: getChannelMap(),
  }).then((res) => {
    const response = res.res && res.res;
    const status = response.status;
    if (status == google.maps.DirectionsStatus.OK) return callback(response);
    return callback(null);
  });
}

const getAdressDetails = (items, type) => {
  let result = {};
  items.forEach((item) => {
    const check = item.types.indexOf(type);
    if (check !== -1) {
      result = item;
    }
  });
  return result;
};

const getParamDirectionByBookType = ({
  auth,
  bookId,
  points,
  travelMode,
  isRoundTrip,
  vehicleType,
  jobType,
  deliveryData,
  isTencent
}) => {
  const baseResult = {
    fleetId: auth.selectedFleet.fleetId,
    bookId,
    forceProvider: isTencent ? PROVIDER_MAP_TYPE.tencent : '',
    travelMode: travelMode?.toLowerCase(),
    isRoundTrip,
    jobType,
    vehicleType,
  };

  if (jobType === 'delivery') {
    const recipients = points.slice(1)
      .map((p, id) => ({
        geo: [p.lng, p.lat],
        order: id + 1
      }));

    return {
      ...baseResult,
      ...deliveryData,
      pickup: {
        geo: [points[0].lng, points[0].lat]
      },
      recipients,
    };
  } else {
    const waypoints = points.slice(1, -1)
      .filter(pos => pos.lat && pos.lng)
      .map(pos => ({ lng: pos.lng, lat: pos.lat }));
  
    return {
      ...baseResult,
      pickup: [points[0].lng, points[0].lat],
      destination: [points[points.length - 1].lng, points[points.length - 1].lat],
      waypoints,
      jobType: 'transport'
    };
  }
};
