export const it = {
    "name": "Italian",
    "flagCode": "it",
    "locale": "it-IT",
    "project": {
        "name": "QUp New CC "
    },
    "Sidebar": {
        "Setting": "Impostazioni",
        "Map": "Mappa",
        "Cue": "Elenco Manuale",
        "ImportBookings": "Import Transport Bookings",
        "ImportDeliveryBookings": "Import Delivery Bookings",
        "Trip": "Trip",
        "New_booking": "Nuova Prenotazione",
        "City": "City",
        "Chauffeur": "Transport",
        "Delivery": "Delivery",
        "Shuttle": "Shuttle",
        "Intercity": "Intercity",
        "Customer": "Clienti",
        "Driver": "Autisti",
        "REPORTS": "Reports",
        "Corporate": "Aziendale",
        "mDispatcher": "mDispatcher",
        "Reservation": "Calendario",
        "withdrawalRequest": "Richieste di prelievo",
        "Driver_Settlement": "Pagamenti autista",
        "Dashboard": "Analytics",
        "SmartData": "Smart data",
        "Smart_Data": "Smart data",
        "Campaign_Builder": "Campaign builder",
        "Company_operator": "Company operator",
        "Queuing_area": "Queuing area",
        "Withdrawal_Requests": "Richieste di prelievo",
        "pickUp": "Pick up",
        "dropOff": "Drop off",
        "SignUpNotifications": "Sign Up Notifications",
        "Payout": "Payout",
        "Payout_label": "Payout",
        "payout": {
            "Pay_To_Driver": "Pay to Drivers",
            "Payout_History_For_Driver": "Payout Driver History",
            "Pay_To_Merchant": "Pay to Merchants",
            "Payout_History_For_Merchant": "Payout Merchant History"
        },
        "number_of_records_to_export": "Number of records to export",
        "export": "Export",
        "Airline": "Airline",
        "driverPayout": {
            "Pay_to_driver": "Pay to Drivers",
            "Payout_history": "Payout History"
        },
        "Reports": {
            "Driver_Referral_history": "Driver refers customer",
            "Driver_refers_Pax": "Drivers",
            "Driver_refers_Pax_Customers": "Customers",
            "Driver_refers_Pax_Bookings": "Bookings",
            "Driver_refers_customer_Drivers": "Driver refers customer (Drivers)",
            "Driver_refers_customer_Customers": "Driver refers customer (Customers)",
            "Driver_refers_customer_Bookings": "Driver refers customer (Bookings)",
            "Passenger_refers_Passengers": "Customer refers customer",
            "Driver_refers_drivers_Referrer": "Driver refers drivers (Referrer)",
            "Deletion_request": "Deletion request",
            "Driver_refers_drivers_Referee": "Driver refers drivers (Referee)",
            "Driver_refers_Driver": "Driver refers driver",
            "Driver_refers_driver_Referrer": "Referrer",
            "Driver_refers_driver_Referee": "Referee",
            "Referral_history": "Referral history",
            "Booking_details": "Dettagli prenotazione",
            "Financial": "Finanziario",
            "Financial_driver": "Autisti",
            "Financial_fleet": "Flotta",
            "Financial_company": "Azienda",
            "Financial_Driver": "Finanziario (Autisti)",
            "Financial_Fleet": "Finanziario (Flotta)",
            "Financial_Company": "Finanziario (Azienda)",
            "Financial_fleet_profit": "Profitto della flotta",
            "Financial_Company_Tooltip": "Rapporto sui guadagni netti dei conducenti, raggruppati per società",
            "Financial_Driver_ToolTip": "Rapporto sul guadagno netto di ciascun autista",
            "Financial_Fleet_Profit_ToolTip": "Utile della flotta",
            "Driver_activity": "Attività autista",
            "Revenue_Summary": "Revenue summary",
            "Revenue_fleet": "Flotta",
            "Revenue_driver": "Autisti",
            "Revenue_monthly": "Mensile",
            "Revenue_Summary_Fleet": "Sommario entrate (Flotta)",
            "Revenue_Summary_Driver": "Sommario entrate (Autisti)",
            "Revenue_Summary_Monthly": "Sommario entrate (Mensile)",
            "Total_adjustment": "Totale Adeguamento",
            "Promotion": "Promozione",
            "Incident_Cancellation": "Incidenti & Cancellazioni",
            "Booking_logs": "Booking logs",
            "Rating": "votazioni",
            "Thumbs_rating": "votazioni",
            "Stars_rating": "Voti dell'autista",
            "Operator_logs": "Registri operatore",
            "Daily": "Giornaliero",
            "Daily_Driver": "Autista giornaliero",
            "Daily_Car": "Vettura giornaliera",
            "Daily_Driver_Car": "Vettura con Autista Giornaliero ",
            "mDispatcher": "mDispatcher",
            "Corporate": "Aziendale",
            "Driver_login_status": "Status registrazione dell'autista",
            "Car_activity": "Attività della vettura",
            "Partner": "Partner",
            "Prepaid_top_up": "Corporate prepaid",
            "Affiliation": "Affiliazione",
            "Affiliation_penalty_compensation": "Penalty/Compensation",
            "Affiliation_payout_history": "Settlement history",
            "Affiliation_bookings": "Bookings",
            "Booking_summary": "Booking summary",
            "Cash_Wallet": "Cash wallet",
            "Credit_Wallet": "Portafoglio di credito",
            "Driver_wallet": "Portafoglio conducente",
            "Driver_Deposit": "Deposito vettura",
            "Driver_cash_wallet": "Cash Wallet",
            "Driver_Deposit_old": "Deposito vettura",
            "Document_expiry": "Il documento scade",
            "Settlement": "Accordo",
            "Unapproved_Driver": "autista non approvato",
            "Approved_Driver": "Autista approvato",
            "Pay_to_driver": "Paga all'autista",
            "Settlement_history": "Storico pagamenti",
            "Withdrawal_History": "Ritiro del conducente",
            "creditWalletTooltip": "Il conducente deposita sul portafoglio di credito",
            "Incomplete_payment": "pagamento incompleto",
            "Driver_withdrawal": "Ritiro del conducente",
            "Incident_&_Cancellation": "Incident & Cancellation",
            "Passenger_wallet": "Incident & Cancellation",
            "Accept_Cancel_rate": "Acceptance/Cancellation rate",
            "Acceptance/Cancellation_rate": "Acceptance/Cancellation rate",
            "driverDepositTooltip": "Il conducente deposita sul portafoglio di credito",
            "Customer_quest": "Quest",
            "First_will_win": "First will win",
            "Largest_will_win": "Largest will win",
            "redeemed": "Code #",
            "Code_#": "Code #",
            "Quests": "Quest",
            "Merchant_Report": "Merchant",
            "Merchant_Financial_Report": "Financial",
            "Merchant_Cash_Report": "Cash wallet",
            "Merchant_Credit_Report": "Credit wallet",
            "Deletion_Request": "Deletion request",
            "Driver_Rating": "Customer rates driver",
            "Passenger_Rating": "Driver rates customer",
            "Company_settlement": "Company settlement",
            "Export": "Export History",
            "Third_Party_Booking": "3rd party Booking",
            "BookingDotCom": "Booking.com",
            "Third_Party_Booking_Bookingcom": "Third Party Booking (Booking.com)",
            "Third_Party_Booking_HolidayTaxis": "Third Party Booking (HolidayTaxis)",
            "Third_Party_Booking_Karhoo": "Third Party Booking (Karhoo)",
            "Third_Party_Booking_Hoppa": "Third Party Booking (Hoppa)"
        },
        "Settings": {
            "Fleet_info": "Informazioni flotta",
            "Stripe_connect": "Stripe Connect",
            "stripe_title_connect": "Activate your Stripe Connect account to receive your payout automatically.",
            "Payment_info": "Billing info",
            "Permission": "Permesso",
            "Driver_refer_driver": "Driver refer driver",
            "Show_referal_history": "Show/hide referral history on driver app",
            "companyTooltip": "Supplier company",
            "User": "Utilizzatore",
            "General": "Generale",
            "Regular": "Regolare",
            "Sharing": "Street Sharing",
            "Flat": "Fissa",
            "Hourly": "Hourly - Daily",
            "Company": "Azienda",
            "Dispatch": "Assegnazione",
            "Voice_SMS": "Voice & SMS",
            "Rate": "Rate",
            "Car": "Car",
            "Car_type": "Tipo di macchina",
            "Car_mgmt": "Macchina mgmt",
            "Car_Make": "Costruttore e modello",
            "Stripe_Connect": "Stripe connect",
            "Voice_&_SMS": "Voice & SMS",
            "Street-sharing_rate": "Street sharing rate",
            "Partner_type": "Tipo mDispatcher",
            "Shift_template": "Modello turno",
            "Driver_App": "Driver App",
            "Shift_settings": "Impostazioni turno",
            "Operation": "Operazione",
            "Driver_Note": "Driver note",
            "Promotion_code": "Codice Promozionale",
            "Point_config": "Point config",
            "Code_#": "Code #",
            "Voucher_code": "Code #",
            "Campaign": "Campagna",
            "Queuing_area": "Seleziona citta’",
            "Referral_code": "Il tuo codice di riferimento",
            "City": "Città",
            "category": "Category",
            "3rd_party_location": "Luogo di terzi",
            "Zone": "Zona",
            "App_banner": "App banner",
            "App_banner_pegasus": "App banner",
            "Flight_integration": "Integrazione volo",
            "SMS_integration": "SMS Integration",
            "Invoice_Setting": "Invoice",
            "Driver_refers_customer": "Driver refers customer",
            "Pax_refers_Pax": "Customer refers customer",
            "Customer_refers_customer": "Customer refers customer",
            "Dynamic_Surcharge": "Sovrapprezzo dinamico",
            "Dynamic_Fare": "Dynamic Fare",
            "FlatZone": "Flat rate",
            "IntercityZone": "Intercity",
            "Intercity_Zone": "Intercity Zone",
            "Intercity_Rate": "Intercity Rate",
            "Car_Color": "Car color",
            "third_party_integration": "3rd party integration",
            "bookingCom": "Booking.com",
            "HolidayTaxis": "HolidayTaxis",
            "Karhoo": "Karhoo",
            "Corporate": "Corporate",
            "KarhooIntegration": "Karhoo Integration",
            "holidaytaxis": "HolidayTaxis",
            "karhoo": "Karhoo",
            "hoppa": "Hoppa",
            "Hoppa": "Hoppa",
            "HoppaIntegration": "Hoppa Integration",
            "Third_Party_Integration_Hoppa": "3rd party Integration (Hoppa.com)",
            "titleHoppa": "Hoppa API Credentials",
            "noteHoppa": "Communicate with Hoppa’s technical team to provide you with client, and secret key to connect to their system.",
            "titleHoppaWebhook": "Webhook Configuration",
            "noteHoppaWebhook": "Provide the parameters below for Hoppa to configure. These credentials are used to communicate between both platforms. For any issues please contact your tech provider.",
            "Third_Party_Integration_Booking": "3rd party Integration (Booking.com)",
            "Third_Party_Integration_HolidayTaxis": "3rd party Integration (HolidayTaxis)",
            "Third_Party_Integration_Karhoo": "3rd party Integration (Karhoo.com)",
            "Third_Party_Integration_Mozio": "3rd party Integration (Mozio.com)",
            "invoiceBranding": "Invoice Branding",
            "titleInvoice": "Customize your company header and footer information.",
            "titleheader": "Header Title",
            "descriptionHeader": "Header Description",
            "placehodelHeader": "For example add company details like email, phone number, address, tax number, etc.",
            "titleFooter": "Footer Title",
            "placehodelFooter": "For example, add bank wire information, or any policy etc",
            "descriptionFooter": "Footer Description ",
            "titleBookingCom": "Booking.com API Credentials",
            "noteBookingCom": "Communicate with booking.com’s technical team to provide you with client, and secret key to connect to their system.",
            "clientKey": "Client Key",
            "placeHolderClientKey": "Enter client key",
            "secretKey": "Secret Key",
            "placeHolderSecretKey": "Enter client secret",
            "titleBookingComWebhook": "Webhook Configuration",
            "noteBookingComWebhook": "Provide the parameters below for booking.com to configure. These credentials are used to communicate between both platforms. For any issues please contact your tech provider.",
            "webhook": "Fleet Webhook URL",
            "clientId": "Fleet Client Key",
            "clientSecret": "Fleet Secret Key",
            "driver_app": "Driver App",
            "Jobs_tab": "Jobs Tab",
            "Email_config": "Email config",
            "Driver_fields": "Driver fields",
            "Driver_document": "Driver document",
            "Selected": "Selected",
            "API_Management": "API management"
        }
    },
    "APIKeySetting": {
        "APIKey": "API Keys",
        "APIKey_title": "Do not share your API key with others, or expose it in the browser or other client-side code. In order to protect the security of your account, Good Journey may also automatically disable any API key that we've found has leaked publicly.",
        "newKeyBtn": "Create new key",
        "updateApplication": "Update Application",
        "RegisterApp": "Register a new application",
        "RegisterBtn": "Register Application",
        "appDescription": "App Description",
        "appName": "App Name",
        "clientKey": "Client Key",
        "secretKey": "Secret Key",
        "hostedEndPoint": "Hosted endpoints",
        "maxEndpoints": "You can have a max of 5 endpoints",
        "addEndpoint": "Add Endpoint",
        "updateEndpoint": "Update Endpoint",
        "ManageWebhooks": "Manage Webhooks",
        "CreateWebhook": "Create webhooks",
        "subscriptionID": "Subscription ID",
        "webhookURL": "Webhook URL",
        "signingSecret": "Signing secret",
        "eventsToSend": "Events to send",
        "listeningFor": "Listening For",
        "assignedDriver": "Assigned Driver",
        "bookingStatus": "Booking Status",
        "driverLocation": "Driver Location",
        "titleSigningSecret": "This secret is provided by your partner. If for any reason the secret has been compromised, you can update the secret, or delete the web hook.",
        "notUsingHTTPS": "Webhook URL is not using HTTPS.",
        "invalidURL": "Webhook URL is invalid.",
        "clientType": "Client Type",
        "individual": "Individual",
        "corporate": "Corporate",
        "signingSecretLength": "Signing secret must be at least 30 characters long.",
        "bookingCreated": "Booking Created",
        "partner": "Partner",
        "fleetManager": "Fleet Manager"
    },
    "messages": {
        "credits": {
            "406": "Azione fallita",
            "407": "Azione fallita",
            "416": "Coupon has been used",
            "417": "Il tuo account non é attivo. Contatta la nostra amministrazione",
            "418": "There were errors when making payment",
            "419": "Promo code is invalid!",
            "420": "This promo code was applied for another ride. Please use another one.",
            "421": "Numero carta invalido",
            "422": "Data di scadenza invalida.",
            "429": "Cap invalido.",
            "430": "Il codice postale AVS non combacia",
            "431": "Codice postale AVS non verificato",
            "432": "CVV invalido.",
            "433": "CVV non combacia",
            "434": "CVV non verificato",
            "435": "L’emittente CVV non partecipa",
            "436": "La tua carta é stata rifiutata",
            "437": "Insufficient funds. Please check your balance!",
            "439": "CVV non trattato",
            "441": "Nessun dato CVV dall’emittente",
            "443": "Nessun dato dall’emittente/banknet switch",
            "445": "Sistema AVS incapace di processare",
            "446": "La banca emittente non supporta AVS",
            "447": "Errore, AVS non supportato per la tua attività",
            "448": "Questa carta é stata bloccata in seguito a numerosi tentativi di pagamento infruttuosi. Per favore cambia mezzo di pagamento o riprova piu’ tardi",
            "452": "La tua carta é stata rifiutata. Per favore inserisci un’altra carta di credito registrata nella stessa zona del tuo numero di telefono [numero#]",
            "453": "This payment.gateway is not supported",
            "454": "L’indirizzo AVS non combacia",
            "455": "Codice postale e indirizzo AVS non combaciano",
            "458": "Autentica fallita",
            "459": "Bank cards do not supprt",
            "461": "This card is not suppoted",
            "462": "Card holder name invald",
            "463": "Verification code is ivalid",
            "464": "The Bank does not supprt generating and sending the verification code",
            "470": "Please enter the traveler's email address before creating a booking with credit card",
            "474": "Please enter the traveler's name before creating a booking with credit card",
            "475": "Phone number is required",
            "524": "Insufficient Funds. The account did not have sufficient funds to cover the transaction amount.",
            "911": "We are unable to authenticate your payment method. Please choose a different payment method and try again.",
            "2000": "Non procedere al pagamento",
            "2001": "Insufficient Funds. The account did not have sufficient funds to cover the transaction amount.",
            "2002": "Limit Exceeded",
            "2003": "Cardholder&#39;s Activity Limit Exceeded",
            "2004": "Carta scaduta",
            "2005": "Invalid Credit Card Number",
            "2006": "Invalid Expiration Date",
            "2007": "No Account",
            "2008": "Card Account Length Error",
            "2009": "No Such Issuer",
            "2010": "Card Issuer Declined CVV",
            "2011": "Voice Authorization Required. The cardholder&#39;s bank is requesting that the merchant calls to obtain a special authorization code in order to complete this transaction",
            "2012": "Processor Declined - Possible Lost Card",
            "2013": "Processor Declined - Possible Stolen Card",
            "2014": "Processor Declined - Fraud Suspected",
            "2015": "Transaction Not Allowed",
            "2016": "Duplicate Transaction",
            "2017": "Cardholder Stopped Billing",
            "2018": "Cardholder Stopped All Billing",
            "2019": "Invalid Transaction",
            "2020": "Violation",
            "2021": "Security Violation",
            "2022": "Declined - Updated Cardholder Available",
            "2023": "Processor Does Not Support This Feature",
            "2024": "Card Type Not Enabled",
            "2025": "Set Up Error - Merchant",
            "2026": "Invalid Merchant ID",
            "2027": "Set Up Error - Amount",
            "2028": "Set Up Error - Hierarchy",
            "2029": "Set Up Error - Card",
            "2030": "Set Up Error - Terminal",
            "2031": "Encryption Error",
            "2032": "Surcharge Not Permitted",
            "2033": "Inconsistent Data",
            "2034": "No Action Taken",
            "2035": "Partial Approval For Amount In Group III Version",
            "2036": "Authorization could not be found to reverse",
            "2037": "Already Reversed",
            "2038": "Processor Declined. The customer&#39;s bank is unwilling to accept the transaction, need to contact their bank for more details.",
            "2039": "Invalid Authorization Code",
            "2040": "Invalid Store",
            "2041": "Declined - Call For Approval",
            "2042": "Invalid Client ID",
            "2043": "Error - Do Not Retry, Call Issuer",
            "2044": "Declined - Call Issuer",
            "2045": "Invalid Merchant Number",
            "2046": "Declined. The customer&#39;s bank is unwilling to accept the transaction, need to contact their bank for more details.",
            "2047": "Call Issuer. Pick Up Card. This error indicates that the card has been reported as lost or stolen by the cardholder.",
            "2048": "Invalid Amount",
            "2049": "Invalid SKU Number",
            "2050": "Invalid Credit Plan",
            "2051": "Credit Card Number does not match method of payment",
            "2052": "Invalid Level III Purchase",
            "2053": "Card reported as lost or stolen",
            "2054": "Reversal amount does not match authorization amount",
            "2055": "Invalid Transaction Division Number",
            "2056": "Transaction amount exceeds the transaction division limit",
            "2057": "Issuer or Cardholder has put a restriction on the card",
            "2058": "Merchant not MasterCard SecureCode enabled.",
            "2060": "Address Verification and Card Security Code Failed",
            "2061": "Invalid Transaction Data",
            "2062": "Invalid Tax Amount",
            "2064": "Invalid Currency Code",
            "2078": "Invalid Secure Payment Data",
            "2080": "Invalid user credentials",
            "3000": "Processor Network Unavailable - Try Again",
            "4001": "Settlement Declined",
            "4006": "Capture amount exceeded allowable limit",
            "81703": "La carta di credito non viene accettata da questo conto commerciale",
            "81706": "CVV richiesto",
            "81707": "CVV deve essere di 3 o 4 cifre",
            "81709": "Data di scadenza richiesta.",
            "81710": "Data di scadenza invalida.",
            "81714": "E’ richiesto il numero della carta di credito.",
            "81715": "Numero della carta di credito invalido.",
            "81716": "Il numero della carta di credito deve essere di 12-19 cifre",
            "81717": "Il numero di carta di credito non é accettato come numero di test",
            "81718": "Il numero di carta di credito non puo’ essere aggiornato su carta non supportata quando é associato ad abbonamenti.",
            "81723": "Nome del titolare della carta troppo lungo. Al massimo 175 lettere",
            "81809": "Il codice postale non puo’ contenere piu’ di 9 lettere o numeri",
            "81813": "Il codice postale puo’ contenere solo lettere, numeri, spazi, tratti",
            "91722": "Mezzo di pagamento token richiesto.",
            "91723": "Aggiornamento Token invalido.",
            "91730": "Verifica non supportata da questo conto commerciale",
            "91734": "Tipo di carta di credito non accettato da questo conto commerciale",
            "91738": "Il mezzo di pagamento specificato non é con carta di credito",
            "91745": "Parametri invalidi per aggiornare carta di credito.",
            "91826": "Codice postale invalido. Il codice postale é una serie di 5 o 9 cifre eventualmente separate da spazi",
            "-1": "Questo conto cliente é stato disattivato. Per favore contatta la nostra amministrazione"
        },
        "reBookingMsg": {
            "303": "L’orario selezionato deve essere successivo all’orario presente!",
            "304": "Prenotazione non trovata",
            "500": "Alcune informazioni della prenotazione sono scorrette. Per favore verifica e riprova"
        },
        "promoMsg": {
            "304": "TIl codice promozionale non é valido",
            "306": "Invalid promotion code. It seems that the promo is applied to other zones.",
            "413": "Spiacenti, questo codice promozionale non é disponibile al momento",
            "414": "Il codice promozionale non é valido",
            "415": "Il codice promozionale non é valido",
            "416": "Questo codice promozionale é già stato usato..",
            "419": "Spiacenti, questo codice promozionale non é disponibile al momento",
            "420": "Spiacenti, hai raggiunto il limite di utilizzo della promozione",
            "421": "Spiacente, questo codice promozionale ha raggiunto il limite di validità ",
            "422": "Questo codice promozionale non puo’ essere utilizzato dal tuo conto",
            "423": "Spiacenti, hai raggiunto il limite di fondi della promozione ",
            "424": "Invalid promotion code. It seems that the promo is only applied to referred customers",
            "425": "This promo code has reached its usage limit per day.",
            "426": "You have reached your limited promo uses per day.",
            "427": "Cannot apply promotion code. This customer’s profile is incomplete.",
            "428": "This customer has used up monthly promotion code.",
            "430": "You have used up your yearly promotion codes.",
            "431": "The promotion code can't be used for this payment method.",
            "432": "This fare amount isn't eligible for the promotion code.",
            "433": "The promotion code isn't eligible to use at selected time.",
            "434": "Sorry, you have reached your limited promo budget.",
            "phoneInvalid": "Per favore inserisci il numero di telefono del passeggero prima di applicare il codice promozionale"
        },
        "updateBookingMsg": {
            "303": "Aggiornamento della prenotazione fallito. Per favore riprova",
            "304": "Prenotazione non trovata",
            "pickUpTime": "L’orario selezionato deve essere successivo all’orario presente!",
            "pickup": "Non ci sono servizi di prenotazione disponibili in quest’area",
            "destination": "Alcune informazioni della prenotazione sono scorrette. Per favore verifica e riprova!",
            "dispatchType": "Alcune informazioni della prenotazione sono scorrette. Per favore verifica e riprova!"
        },
        "sessionExpired": {
            "403": "You have been logged out because your account has been logged in from another device or your session has expired"
        },
        "not_found": {
            "404": "The page you requested could not be found"
        },
        "booking": {
            "303": "L’orario selezionato deve essere successivo all’orario presente!!",
            "304": "Prenotazione non trovata",
            "305": "Booking canceled or completed by Booking.com; please reject the job to remove it from the queue.",
            "306": "No rate assign to selected car type. To apply a rate for any car type, please go to Settings > Car > Car Type.",
            "307": "Can not update booking. Please try again later.",
            "407": "Type rate ko support extra location",
            "500": "Alcune informazioni della prenotazione sono scorrette. Per favore verifica e riprova",
            "upgroup_success": "Booking has been ungrouped successfully",
            "Please_Select_driver": "Please select driver",
            "assign_driver_vehicle_success": "Driver/Vehicle has been assigned successfully",
            "assign_driver_success": "Driver has been assigned successfully",
            "detach_driver_success": "Driver has been detach successfully",
            "detach_vehicle_success": "Vehicle has been detach successfully",
            "Group_success": "Booking has been grouped successfully",
            "change_supplier_success": "Booking has been changed suppliers successfully",
            "change_supplier_partial": "Partial Success: Batch assignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
            "detach_driver_partial": "Partial Success: Batch assignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
            "assign_driver_partial": "Partial Success: Batch assignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
            "update_dispatch": "Update & Dispatch",
            "remove_booking_false": "Failed to remove booking",
            "remove_booking_success": "Booking has been removed successfully",
            "GROUP_NOT_FOUND": "Group Id is not found",
            "NO_SHARING_BOOKINGS": "You can only group bookings if the customer’s booking allows ride-sharing.",
            "DIFFERENT_CAR_TYPES": "The booking you are trying to group has a different car-type",
            "LIMIT_EXCEEDED_ON_SEATS_OR_LUGGAGE": "Booking cannot be grouped: Seat or luggage limit exceeded.",
            "INVALID_CAR_TYPE": "Cannot assign driver, check driver’s vehicle seat & luggage capacity.",
            "VALIDATION_ERROR": "Validation error",
            "INTERNAL": "Internal server error",
            "fare_gt_zero": "La tariffa deve essere maggiore di 0!",
            "edit_total_fail": "Modifica del valore totale fallita!",
            "complete_fail": "The booking cannot be completed!",
            "cancle_fail": "Something went wrong when canceling this booking. Please try again!",
            "incident_fail": "Incidente fallito",
            "phone_invalid": "Il numero di telefono non é valido!!",
            "add_psg_fail": "Impossibile aggiungere passeggero",
            "REMOVE_RECIPIENT_DELIVERED": "Cannot remove recipient has been delivered",
            "REMOVE_LAST_ACTIVE_RECIPIENT": "Cannot remove the last active recipient",
            "ORDER_HAS_BEEN_FINISHED": "Order has been finished already",
            "end_booking_leave": "Lasciare questa pagina terminerà la tua prenotazione",
            "update_booking_fail": "Aggiornamento della prenotazione fallito. Per favore riprova",
            "EXTERNAL_ID_EXISTED": "External ID already exist on the system.",
            "area_service_unavailable": "Non ci sono servizi di prenotazione disponibili in quest’area",
            "pickupSameDestination": "Indirizzo pick-up deve essere diverso dalla destinazione.",
            "time_invalid": "L’orario selezionato deve essere successivo all’orario presente!",
            "psg_inactivate": "Questo conto cliente é stato disattivato. Per favore contatta la nostra amministrazione",
            "data_incorect": "Something went wrong. Please try again later.",
            "preAuthorize": "Per favore verifica il tuo conto perché l'importo di {0} é richiesto al fine di garantire la tua prenotazione",
            "booking_limited": "Spiacenti, hai raggiunto il numero massimo di prenotazioni per minuto/giorno. Per favore riprova piu’ tardi",
            "minimumBookAhead": "Reservation pickup time must be at least {0} hour(s) {1} minute(s) from current time",
            "exDoDiffDo": "Extra destination must be different from destination.",
            "recurringTimeRange": "Date out of range. You can only create bookings {0} months in advance. Please contact admin for any issues.",
            "recipientsLimit": "Maximum number of recipients is {0}.",
            "maximumBookAhead": "L'orario della prenotazione non puo' essere piu' tardi di {0} giorno(i) dall'orario attuale",
            "Please_select_or_add_a_card": "Per favore seleziona o aggiungi una carta!!",
            "Unable_to_process_by_fare_zero": "Impossibile trattare la tua richiesta, per favore cambia tipo di vettura o tipo di prenotazione e riprova. Se hai bisogno di aiuto contatta la nostra assistenza tecnica.",
            "Can_not_connect_to_server": "Impossibile connettersi al server!",
            "Check_connection_or_contact_system_support": "Per favore verfica la tua connessione o contatta il supporto per avere aiuto!",
            "Create_new_booking_success": "A new booking has been created successfully!",
            "Create_new_recurring_booking_success": "New booking(s) has been created successfully",
            "Update_booking_success": "Questa prenotazione é stata aggiornata con successo!",
            "Booking_drop_of_success": "Scarico della prenotazione effettuato con successo",
            "Cancel_booking_success": "Questa prenotazione é stata cancellata con successo!",
            "Booking_detail_not_found": "Dettaglio prenotazione non trovato!",
            "Check_promo_error": "Verifica errore codice promozionale!",
            "Invalid_format": "Formato invalido",
            "Flight_not_found": "Volo non trovato!!",
            "Add_new_credit_error": "Aggiungi nuovo errore credito!",
            "Socket_server_disconnected": "Socket server disconnected!",
            "Please_reload_page_or_check_your_connection": "Sorry. Your request cannot be processed. Please try again.",
            "request_timeout": "Sorry. Your request timeout. Please try again.",
            "Check_customer_error": "Verifica errore cliente!",
            "Please_select_car_type": "Per favore seleziona tipo di macchina.",
            "DropOff_country": "Drop-off point not available for this car type. Please choose another car type or adjust route.",
            "Different_Country": "Pickup and drop-off locations must be in 2 different countries.",
            "Please_enter_destination_for_ridesharing_booking": "Per favore inserisci la destinazione per una prenotazione in car pooling",
            "Extra_detination_must_be_difference_from_destination": "La destinazione aggiuntiva deve essere differente dalla destinazione attuale",
            "Pickup_address_must_be_different_from_destination": "L'indirizzo di partenza deve essere diverso da quello di destinazione",
            "Best_price": "miglior prezzo",
            "You_may_be_charged_extra_fee_if_your_trip_excceds": "Potresti pagare un extra se il tuo viaggio eccede",
            "You_may_be_charged_extra_fee_if_your_trip_excceds_with_params": "Potresti pagare un extra se il tuo viaggio eccede {0} {1}, {2} {3}",
            "Reject_booking_success": "This booking has been rejected successfully!",
            "Reject_fail": "Something went wrong when rejecting this booking. Please try again!",
            "Please_select_payment_method": "Please select payment method",
            "Driver_signed_out_or_deactivated": "This driver has just signed out or been deactivated",
            "reservation_booking_overlaps": "Sorry, this reservation booking overlaps with another one that Driver has accepted.",
            "driver_is_not_available": "Sorry, this driver isn’t available at the requested time.",
            "seats_luggage_limit": "This booking can’t be assigned to the driver because the seats/luggage limit has been exceeded.",
            "full_capacity": "This booking can’t be assigned to the driver because the trip has reached its maximum capacity.",
            "marked_failed_ok": "The order has been marked as failed.",
            "warning_markFail_lastRecipient": "This is the last recipient of this booking. If you proceed with marking this order as failed you will then need to complete this booking.",
            "BOOKINGS_LIMIT_EXCEEDED": "You can only assign a maximum of 5 orders into each group",
            "bookingCanceled": "Booking canceled or completed by Booking.com; please reject the job to remove it from the queue."
        },
        "item_no_found": "Nessun oggetto trovato.",
        "commonMessages": {
            "greater_than_0": "Per favore inserisci un valore maggiore di 0.",
            "Required_field": "Questo campo é obbligatorio",
            "bankPhone_is_required": "Phone number is invalid",
            "bankPhone": "Phone number",
            "distance_from_equa_or_smaller": "La distanza “da” deve essere inferiore o uguale alla distanza “a”",
            "must_be_number": "Per favore inserisci email valida",
            "equal_or_greater_than_0": "Per favore inserisci un valore uguale o maggiore di 0.",
            "must_be_integer": "Per favore inserisci un numero intero",
            "invalid_email_format": " Per favore inserisci email valida.",
            "greater_or_equa": "Per favore inserisci un valore maggiore o uguale a {0}.",
            "value_must_between": "Per favore inserisci un numero fra {0} e {1}.",
            "large_10x_markup_price": "Edited fare should not exceed 10x estimated fare.",
            "greater_than": "Please enter a value greater than {0}.",
            "less_than": "Please enter a value less than {0}.",
            "less_or_equal": "Please enter a value less than or equal to {0}.",
            "driver_earnings_warning": "Offline payments: Driver earnings must not surpass the collected fare.",
            "limit_amount": "Please enter a value less than or equal to limit amount",
            "Integer_from_range": "Per favore inserisci un numero intero fra {0} - {1}",
            "integer_greater_than_0": "Please enter a integer number greater than 0. (Less than 100 with percent and decimal separator is 1 character)",
            "Error_message": "Error!",
            "callcenter_newbooking_message": "New booking request with phone <span class='bold'>{0}</span>",
            "callcenter_existed_booking_message": "One active booking <span class='bold'>#{0}</span> exists with phone <span class='bold'>{1}</span>",
            "callcenter_close_newbooking_form_warning": "Per favore chiudi/completa la prenotazione attuale prima di ricevere una nuova richiesta dal cliente",
            "Emergency_SOS_Title": "Emergency SOS",
            "Please_enter_a_future_date": "Please enter a future date",
            "Card_holder_is_required": "Card holder is required",
            "Card_number_is_required": "E’ richiesto il numero della carta di credito.",
            "Expired_date_is_required": "Expired date is required",
            "Credit_code_is_required": "Please enter credit code (last validation)",
            "Expired_date_is_invalid": "Data di scadenza invalida. Please enter expiration date in format mm/yy",
            "cvv_is_required": "CVV richiesto",
            "City_is_required": "City is required",
            "State_is_required": "State is required",
            "Address_is_required": "Address is required",
            "Zipcode_is_required": "Zip code is required",
            "Country_is_required": "Country is required",
            "Over_query_limit": "Google API can't load due to temporary error. Please try again later.",
            "outOfInputQueueOrder": "The order # cannot be greater than the total number of drivers in the queue.",
            "Same_pickup_destination": "Pick-up address must be different from destination address.",
            "Max_length": "Maximum length is {0} characters.",
            "choose_at_least_one": "Please choose at least one option.",
            "choose_at_least_one_intercity_schedule": "Please add schedule or allow customer to request their preferred time.",
            "startDate_greater_than_endDate": "The start date is greater than the end date"
        },
        "trip": {
            "cancel_trip_success": "This trip has been cancelled successfully!",
            "data_incorect": "No driver has been assigned. Select a driver from the dropdown list. <br/> If no drivers is available, select Auto Dispatch to assign later.",
            "INTERNAL": "Internal server error.",
            "NOT_FOUND": "Trip not found or invalid status.",
            "SAME_DRIVER": "The assignee must be different from the current driver.",
            "OVERLAP": "Sorry, this trip overlaps with another one which is accepted by this driver.",
            "CANNOT_ASSIGN": "This driver is invalid (i.e. driver not found, not online, or not logged in)."
        },
        "car_mgmt": {
            "Search_car_color": "Search car color",
            "Searching": "Searching"
        },
        "driver_note": {
            "Search_driver_note": "Search driver note",
            "Searching": "Searching"
        }
    },
    "errors": {
        "303": "Utilizzatore non trovato",
        "453": "Gateway unsupported",
        "553": "Sorry! Customer has a booking in-progress, please try again later.",
        "1007": "The period between the start date and the end date must be less than 60 days!!",
        "3001": "Numero di telefono esistente",
        "3002": "Data di nascita invalida",
        "3003": "Data di scadenza della patente dell'autista invalida",
        "3004": "Autista non trovato",
        "3005": "Mancano le informazioni amministrative dell'autista (indirizzo banca, città, cap)",
        "3006": "Mancano le informazioni bancarie dell'autista (nome e tipo di conto, numero conto, numero routing, codice fiscale)",
        "3007": "Non puoi modificare questo autista mentre sta lavorando.",
        "3008": "Questo autista é attualmente connesso al sistema. Per favore chiedigli di disconnettersi per poter modificare le sue informazioni.",
        "3009": "L’autista ha accettato una o priu’ prenotazioni.Per favore assegna le prenotazioni ad altro autista(i) prima di disattivarlo.",
        "3010": "Connessione assegnazione fallita.",
        "3011": "Questo autista deve essere predisposto per essere operativo prima di essere attivato.",
        "3012": "Questo autista deve essere predisposto per essere operativo prima di essere attivato",
        "3013": "** Solo un oggetto in status inattivo puo’ essere eliminato.",
        "3014": "Alcuni campi obbligatori sono mancanti. Per favore verifica",
        "3015": "Anno di nascita invalido. Per favore verifica.",
        "3016": "Cap invalido.Per favore verifica.",
        "3017": "Provincia invalida.Per favore verifica.",
        "3018": "Nome del conto invalido.Per favore verifica.",
        "3019": "Numero routing invalido.Per favore verifica.",
        "3020": "Il giorno di nascita é obbligatorio. Per favore verifica.",
        "3021": "Il codice fiscale é invalido. Per favore verifica.",
        "3022": "Driver's bank account can't receive funds.",
        "3023": "Gli autisti connessi al sistema non possono essere gestiti. Per favore riprova dopo",
        "3026": "L’autista non esiste piu’",
        "3027": "Il conto bancario dell’autista non é stato predisposto. Per favore vai nel modulo autista per impostare il conto bancario dell’autista",
        "3028": "Impossibile pagare l'autista(i) selezionati. Per favore riprova.",
        "3029": "Failed to clear earning data of the driver. Please try again.",
        "3032": "Questo username esiste già nel nostro sistema. Per favore inseriscine un altro.",
        "3033": "Invalid BSB. The number should be 6 digits in the format xxxxxx.",
        "3039": "Group ID is required.",
        "3040": "This phone number is already in use by a  %{brandName} driver (i.e. phone number can't be duplicated). Please enter another one",
        "3041": "Username existed.",
        "3044": "National ID is duplicated.",
        "3045": "Driver already has a vehicle, Please refresh page",
        "3046": "The company of the vehicle and the company of the driver are different.",
        "4001": "Il tuo numero di targa esiste già nel sistema. Per favore inseriscine un altro",
        "4002": "Data di scadenza invalida",
        "4003": "Il veicolo deve essere disattivato prima di essere eliminato",
        "4004": "Veicolo non trovato",
        "4005": "Questo veicolo é già inattivo ",
        "4006": "Questo veicolo é in uso e non puo' essere eliminato o disattivato",
        "4007": "Il numero di targa é obbligatorio",
        "4009": "The vehicle is being assigned to a booking.",
        "4010": "The vehicle is being assigned to a driver.",
        "5001": "Azienda già esistente",
        "5004": "Azienda non trovata",
        "6001": "Modello del veicolo già esistente",
        "6004": "Modello del veicolo non trovato",
        "6005": "Modello del veicolo in uso",
        "7001": "Il tuo costruttore esiste già nel sistema .Per favore inseriscine un altro",
        "7004": "Marca del veicolo non trovata",
        "8001": "Tipo di veicolo già esistente",
        "8004": "Tipo di veicolo non trovato",
        "9001": "Itinerario a tariffa fissa già esistente",
        "9004": "Itinerario a tariffa fissa non trovato",
        "10001": "Zona già esistente",
        "10004": "Zona non trovata",
        "11001": "Zona già esistente",
        "11002": "Conflitto di Zona",
        "11003": "La zona deve essere poligonale",
        "11004": "Zona non trovata",
        "11005": "Zona in uso",
        "12001": "Casella già esistente",
        "12002": "Per favore configura SMS Twilio.",
        "12004": "Casella non trovata",
        "12005": "There is no recipient match your filters. Please check the list and try again.",
        "12006": "No customer exists in the file.",
        "13001": "Questo codice di promozione esiste già nel sistema. Per favore inseriscine un altro.",
        "13002": "Data invalida",
        "13003": "Privato",
        "13004": "Codice promozionale non trovato",
        "13005": "Solo Nuovo Cliente",
        "13006": "Superiore al limite di quantità",
        "13007": "Superiore al limite di spesa",
        "13008": "Superiore al limite di uso",
        "13011": "Questa campagna promozionale é stata disattivata o eliminata",
        "13012": "Date range is invalid",
        "13013": "Invalid promotion code. It seems that the promo is applied to other zones",
        "13014": "The zone you selected has been deactivated.",
        "14004": "Prenotazione non trovata",
        "15002": "La tua flotta é stata disattivata. Per favore contatta la nostra amministrazione",
        "15004": "Flotta non trovata",
        "16001": "Il nome della tariffa é già esistente",
        "16004": "Tariffa non trovata",
        "17001": "Nome é già esistente",
        "17004": "Tariffa pacchetto non trovata",
        "18001": "Nome é già esistente",
        "18002": "Tariffa in uso",
        "18004": "Tariffa non trovata",
        "19001": "Shift name has existed",
        "19002": "Tariffa in uso",
        "19004": "Tariffa non trovata",
        "20001": "Questo username esiste già nel nostro sistema. Per favore inseriscine un altro.",
        "20002": "Il tuo conto non é attivo. Per favore chiama la nostra Amministrazione",
        "20003": "Username o password non sono corretti. Per favore riprova",
        "20004": "Username non registrato nel sistema.",
        "20005": "Questo link é scaduto",
        "20006": "La password attuale non combacia. Per favore riprova.",
        "20007": "Utilizzatore # é già esistente",
        "20008": "This email already exists on our system. Please enter another one.",
        "21001": "Il nome del tuo permesso esiste già nel sistema. Per favore inseriscine un altro..",
        "21002": "Impossibile disattivare questo permesso perché é stato attribuito ad uno o piu’ utilizzatori",
        "21003": "Role active",
        "21004": "Ruolo non trovato",
        "22001": "Il cliente é già esistente",
        "22004": "Cliente non trovato",
        "22005": "Formato del file invalido..",
        "24001": "Numero di telefono invalido",
        "25001": "This name has been existing on the system. Please input another one.",
        "25002": "Fascia oraria invalida",
        "25004": "Turno non trovato",
        "26001": "This name has been existing on the system. Please input another one.",
        "26002": "Questo turno é al momento in funzione, per favore togli l’assegnazione prima di cancellarlo.",
        "26004": "Modello del turno non trovato",
        "27001": "La campagna esiste già",
        "27002": "La campagna é attiva",
        "27004": "Campagna non trovata",
        "28001": "La città esiste già",
        "28002": "La città é stata attivata",
        "28003": "La città é in uso",
        "28004": "Città non trovata",
        "29002": "Il numero di telefono esite già",
        "30001": "Area di fila non trovata",
        "30002": "Area di fila in uso",
        "30003": "Your queuing area name has been existed on system. Please input another one",
        "30004": "Queuing area must not overlap with each others",
        "30005": "Queuing area coordinates are invalid",
        "30006": "Pick-up areas are invalid",
        "31001": "Your company name has been existed on system. Please input another one.",
        "31002": "Questo username esiste già nel nostro sistema. Per favore inseriscine un altro",
        "31003": "Azienda non trovata.",
        "31004": "Utilizzatore aziendale non trovato.",
        "31005": "Solo aziende inattive possono essere eliminate..",
        "31006": "Questo telefono esiste già nel viaggiatore aziendale. Per favore inseriscine un altro.",
        "31007": "Corporate traveler not found.",
        "31008": "Nessuna carta trovata.",
        "31009": "Questo telefono esiste già nel sistema. Per favore inseriscine un altro.",
        "31010": "Questa azienda é stata disattivata. Per favore contatta l'amministrazione per piu' informazioni",
        "31011": "Questo viaggiatore non puo' essere attivato perché appartiene ad un conto aziendale inattivo",
        "31013": "A business with this name already exists",
        "32001": "Il tuo username esiste già nel sistema. Per favore inseriscine un altro",
        "32004": "Service not found",
        "33002": "The times ranges must not overlap with each others",
        "34001": "Code is existed",
        "34013": "Date range is invalid",
        "36001": "Name is existed",
        "39001": "The document is required",
        "71001": "Dynamic surcharge existed",
        "71002": "This area overlaps with another one. Please draw it again.",
        "71003": "Dynamic surcharge must be a polygon object",
        "71004": "Dynamic surcharge not found",
        "72001": "Dynamic fare existed",
        "72002": "This area overlaps with another one. Please draw it again.",
        "72003": "Dynamic fare must be a polygon object",
        "72004": "Dynamic fare not found",
        "74001": "Zone existed",
        "74002": "This area overlaps with another one. Please draw it again.",
        "74003": "Zone must be a polygon object",
        "74004": "Zone fare not found",
        "74005": "Zone fare not found",
        "80001": "App banner existed.",
        "80002": "App banner not found.",
        "80003": "Just only item in the inactive status can be deleted.",
        "81001": "File upload is empty.",
        "81002": "File upload is limit size.",
        "82002": "Qualcosa è andato storto durante l'installazione del flightaware. Per favore riprova più tardi.",
        "90001": "Intercity zone existed",
        "90002": "This area overlaps with another one. Please draw it again.",
        "90003": "Intercity zone must be a polygon object",
        "90004": "Intercity zone not found",
        "90005": "Intercity zone is in use",
        "91001": "Intercity rate is existed",
        "91002": "Intercity rate is in use",
        "91003": "Intercity rate not found",
        "92001": "Route name is existed",
        "92002": "Route not found",
        "92003": "This route already exists at other route, please check and try again",
        "92004": "There is an active trip on this route.",
        "93001": "Driver note has been existed on system. Please enter another one.",
        "93002": "Driver note are inactive can be deleted.",
        "93003": "Driver note is in use.",
        "93004": "Driver note not found.",
        "94001": "Your car color name has been existed on system. Please input another one.",
        "94002": "Car color not found",
        "400000": "Il numero di telefono non é valido!",
        "400002": "A validation error has occurred.",
        "400003": "Permesso negato",
        "400005": "Contenuto del file invalido",
        "500000": "Errore interno al sistema.",
        "500001": "Per favore configura SMS Twilio.",
        "500002": "Request timeout. Please try again later",
        "600001": "Località non trovata",
        "600002": "Queste coordinate di longitudine & Latitudine esistono già nel sistema. Per favore aggiungine altre",
        "610001": "Category existed",
        "610002": "Category not found",
        "610003": "Category existed in party location",
        "700001": "Il tipo é in uso",
        "700002": "mDispatcher non trovato",
        "700003": "Your mDispatcher type name has been existed on system. Please input another one.",
        "800000": "This store has been assigned to another merchant account. Please select another one.",
        "800001": "Merchant's bank account can't receive funds.",
        "800002": "Merchant's bank account can't receive funds.",
        "undefined": "Qualcosa é andato storto con il server, per favore riprova.",
        "completeBooking": {
            "113": "We are unable to authenticate your payment method. Please choose a different payment method and try again.",
            "450": "The balance is insufficient to pay. Please select another method",
            "451": "The balance is insufficient to pay. Please select another method",
            "521": "The wallet does not have sufficient funds."
        },
        "upload_file_fail": "Invalid file, please check and try again.",
        "upload_photo_fail": "Invalid file, please check and try again.",
        "missing_some_field": "Invalid field, please check and try again."
    },
    "statusDisplay": {
        "supplierAccepted": "Supplier accepted",
        "delivery_supplierAccepted": "Supplier accepted",
        "completedWithoutService": "Completed without driver",
        "confirmed": "Prenotazione confermata",
        "pending": "In attesa",
        "queue": "Assegnazione",
        "offered": "Assegnazione",
        "booked": "Autista in arrivo",
        "engaged": "P.O.B",
        "droppedOff": "Scaricato",
        "pre": "In attesa",
        "recurrent": "Ricorrente",
        "vacant": "",
        "vacantTime": "",
        "arrived": "Arrivato e in attesa",
        "completed": "completato",
        "canceled": "cancellato",
        "noShow": "Assente",
        "action": "In attesa di trattazione",
        "incident": "Incidente",
        "pickupTimeExceeded": "Tempo scaduto",
        "canceledByCC": "Cancellato da CC",
        "canceledByPassenger": "Cancellata dal passeggero",
        "canceledByDriver": "Annullato dal pilota",
        "canceledByPartner": "Cancellata dal partner",
        "canceledBymDispatcher": "Cancellato da mDispatcher",
        "canceledByCorporateAdmin": "Cancellato dall’Amministratore dell’Azienda",
        "canceledByAPI": "Cancellato da API",
        "canceledByWebBooking": "Cancellato dalle prenotazioni online",
        "canceledByTimeout": "Cancellato per timeout",
        "accepted": "Accepted",
        "rejected": "Respinto",
        "canceledByCorpAd": "Cancellato dall’Amministratore dell’Azienda",
        "started": "Started",
        "intransit": "In transit",
        "empty": "Empty",
        "notLive": "Partial - not live",
        "live": "Partial - live",
        "full": "Full",
        "delivering": "Delivering",
        "delivery_booked": "Driver on the way to sender",
        "delivery_arrived": "Arrived & waiting for sender",
        "shopForYou_booked": "Driver on the way to collect cash",
        "shopForYou_arrived": "Arrived & waiting to collect cash",
        "collecting": "Driver on the way to merchant",
        "confirmed_delivery": "Confirmed",
        "food_booked": "Driver on the way to merchant",
        "food_collecting": "Arrived & waiting to collect item",
        "mart_collecting": "Arrived & waiting to collect item",
        "otwMerchant": "Driver on the way to merchant",
        "arrivedAndWaitingToCollectItem": "Arrived & waiting to collect item",
        "canceledByAirlineAdmin": "Canceled by Airline Admin",
        "partialCompleted": "Partial Completed",
        "failed": "Failed",
        "allocated": "Allocated"
    },
    "tripTypes": {
        "scheduled": "Scheduled trips",
        "requested": "Request trips",
        "preferred": "Preferred"
    },
    "login": {
        "Username": "Username",
        "Password": "Password",
        "Remember_me": "Ricordami",
        "Forgot_your_password": "Password dimenticata",
        "Login": "Registrati"
    },
    "invoice": {
        "createBtn": "Create invoice",
        "searchPlInvoice": "Search client, contact, invoice number",
        "searchPl": "Search bookId, customer name",
        "createInvoice": "Create Invoice",
        "invoicePeriod": "Invoice Period",
        "invoicePeriodNote": "You can select month to month date ranges.",
        "viewEdit": "View/Edit",
        "billingContact": "Billing Contact",
        "memo": "Memo",
        "memoMessage": "Leave message to customer",
        "dueDate": "Due Date",
        "updateInvoice": "Update Invoice",
        "chargeCustomer": "Charge Customer",
        "invoiceNumber": "Invoice Number",
        "noItem": "No. Items",
        "issuedDate": "Issued Date",
        "refId": "Ref #",
        "pickupTime": "Pick Up Time",
        "pickup": "Pick Up Location",
        "destination": "Drop Off Location",
        "customerName": "Customer Name",
        "totalAmount": "Amount Due",
        "amountDue": "Amount Due",
        "sendInvoice": "Send Invoice",
        "copyInvoice": "Copy Invoice",
        "downloadPDF": "Download as PDF",
        "paymentLink": "Payment link",
        "deleteInvoice": "Delete Invoice",
        "onlySave": "Save Invoice",
        "saveSend": "Save & Send Invoice",
        "sendEmail": "Send Email",
        "addRecipients": "Add Recipients",
        "editInvoice": "Edit Invoice",
        "check": "Check",
        "wire": "Wire",
        "digitalWallet": "Digital Wallet",
        "cash": "Cash",
        "completedTime": "Completed Time",
        "Status": "Status",
        "bookingStatus": "Booking Status",
        "chargeBtn": "Charge {0}",
        "pastDue": "Past due",
        "payCompleted": "This invoice has been paid successfully!",
        "Delete": "Delete invoice?",
        "Delete_content": "This action will permanently remove this invoice. If necessary, you can create a new one for these order(s).",
        "viewInvoice": "View Invoice",
        "pending": "Pending",
        "paid": "Paid",
        "CopyLink": "Copy Link"
    },
    "cue": {
        "jobVisibility": "Job Visibility",
        "jobPubic": "Public",
        "jobPrivate": "Private",
        "jobSetPubic": "Set Public",
        "jobSetPrivate": "Set Private",
        "supplierAccepted": "Supplier accepted",
        "creationTime": "Creation time",
        "Pax": "Pax #",
        "Luggage": "Luggage",
        "allocated": "Allocated",
        "completedWithoutService": "Completed without driver",
        "shuttle": "Shuttle",
        "farmIn": "Farm In",
        "chargeCode": "Charge Code",
        "clientCaseMatter": "Trip Description",
        "operatorNote": "Internal Notes",
        "farmOut": "Farm Out",
        "roaming": "Roaming",
        "FlightUpdate": "Flight Update",
        "networkType": "Network type",
        "InHouse": "In-house",
        "carHailing": "Car Hailing",
        "uploadBookings": "Upload Bookings",
        "moreActions": "More Actions",
        "rideSharing": "Condividi-viaggio",
        "Rearrange": "Rearrange",
        "GroupId": "Group ID #",
        "Active": "Attiva",
        "Finished": "Terminato",
        "Home_affiliate": "Home / Affiliate",
        "Booking_type": "Tipo prenotazione",
        "From": "Da",
        "balanceWallet": "{0}: {1} remaining",
        "partialWallet": "Partial payment with Wallet",
        "To": "A",
        "Status": "Stato",
        "Migration_Status": "Migration Status",
        "Migrated": "migrated",
        "Select_Status": "Select Status",
        "Operator": "Operatore",
        "Car_types": "Car types",
        "Service_class": "Service class",
        "Booking_from": "Prenotato da",
        "All": "Tutto",
        "Pending": "In attesa",
        "Dispatching": "Assegnazione",
        "Waiting_for_process": "In attesa di trattazione",
        "Confirmed_reservation": "Prenotazione confermata",
        "Driver_on_the_way": "Autista in arrivo",
        "Arrived_and_waiting": "Arrivato e in attesa",
        "Passenger_on_board": "Passeggero a bordo",
        "Dropped_off": "Scaricato",
        "combobox_select": "Seleziona ...",
        "Search_here": "Cerca qui",
        "Bookingid": "Prenotazione #",
        "Pickup_Drop_Off": "Carico/ Scarico",
        "Pickup_Drop_Off_Crew": "Pickup/Drop Off time",
        "Pickup_Drop_Off_Local_Time": "Operator Time",
        "Pickup_Location_Destination": "Indirizzo di destinazione/ destinazione",
        "Passenger": "Passeggero",
        "Intercity_Route": "Intercity route",
        "Airport_Pickup": "Appuntamento all’aeroporto",
        "Airport_Pickup_Crew": "Airport",
        "Flight_Status": "Status",
        "Payment": "Pagamento",
        "Vehicle": "Veicolo",
        "Car_type": "Tipo di macchina",
        "Driver": "Autista",
        "Estimated_Fare": "Tariffa stimata",
        "Notes": "Note",
        "Note": "Note",
        "Type": "genere",
        "Home": "Casa",
        "Affiliate": "Affiliato",
        "now": "Adesso",
        "reservation": "Prenotazione",
        "recurring": "Recurring (Transport)",
        "No_show": "Assente",
        "Canceled_by_CC": "Cancellata da CC",
        "Canceled_by_passenger": "Cancellata dal passeggero",
        "Canceled_by_partner": "Cancellata dal partner",
        "Canceled_by_mDispatcher": "Cancellato da mDispatcher",
        "Canceled_by_Corporate_Admin": "Cancellato dall’Amministratore dell’Azienda",
        "Canceled_by_API": "Cancellato da API",
        "canceled_By_Web_Booking": "Cancellato dalle prenotazioni online",
        "canceled_By_Time_Out": "Cancellato per timeout",
        "Canceled": "Cancellata",
        "Completed": "Completato",
        "Incident": "Incidente",
        "Payment_Method": "Mezzo di Pagamento",
        "No_Phone": "Nessun telefono",
        "VIP": "VIP",
        "Non_VIP": "Non-VIP",
        "Passenger_type": "Tipo passeggero",
        "Error_load_booking": "Impossibile caricare la prenotazione, Per favore riprova o ricarica la pagina!",
        "Edit_total_success": "Totale modificato con successo",
        "Edit_total_fail": "The total fare must be equal or greater than {0}",
        "Time_out": "Tempo scaduto",
        "Ride_Service": "servizio corsa",
        "Regulation": "Regolazione",
        "Ride_Sharing": "Condividi-viaggio",
        "Unmatching": "Non abbinamento",
        "Matching": "Abbinamento",
        "all": "Tutti",
        "matching": "Abbinamento",
        "unmatching": "Non abbinamento",
        "regulation": "Regolazione",
        "Support_service": "Servizio Supporto",
        "SOS": "SOS",
        "Non_SOS": "Non-SOS",
        "Corporate": "Corporate",
        "Individual": "Singolo autista",
        "On_curb": "Trattenuto",
        "Meet_Greet": "Incontra & Saluta",
        "Close": "Vicino",
        "auto_refrest_cue": "Auto-refresh ({0} seconds)",
        "Ride_type": "Tipo di corsa",
        "Edit_total_note": "Note: subtotale, spese tecniche, spese di prenotazione, tasse e mance saranno aggiornate con il cambiamento dell'importo totale",
        "Booking": "Prenotazione",
        "Edit_fare": "Edit fare",
        "Adjust_price": "Adjust price",
        "Total": "Totale",
        "Reason": "Ragione",
        "Please_enter_reason": "Per favore inserisci motivo.",
        "Save": "Salva",
        "Cancel": "Cancella",
        "dmc": "Booking board (DMC)",
        "corp": "Booking board (CTM)",
        "Zone": "Zona",
        "mDispatcher": "Partner",
        "streetSharing": "Street-sharing",
        "Driver_No_Job_found": "Waiting for assignments...",
        "expandFilter": "Filtro",
        "collapseFilter": "Hide filter",
        "clearSelectedItems": "elementi selezionati Cancella",
        "Accepted": "Accepted",
        "Yes": "Si",
        "No": "No",
        "Order_accepted": "Order accepted",
        "Confirmed": "Confirmed",
        "spotTime": "Spot Time",
        "ETA": "ETA",
        "spareTime": "Spare Time",
        "expectedTime": "Expected pickup time",
        "Driver_on_the_way_to_sender": "Driver on the way to sender",
        "Driver_on_the_way_to_recipient": "Driver on the way to recipient",
        "Driver_on_the_way_to_collect_cash": "Driver on the way to collect cash",
        "Driver_on_the_way_to_collect_goods": "Driver on the way to collect goods",
        "Arrived_waiting_for_sender": "Arrived & waiting for sender",
        "Arrived_waiting_for_recipient": "Arrived & waiting for recipient",
        "Arrived_waiting_to_collect_cash": "Arrived & waiting to collect cash",
        "Arrived_waiting_to_deliver_goods": "Arrived & waiting to deliver goods",
        "Delivered": "Delivering",
        "delivery": "Delivery",
        "Service": "Service",
        "intercity": "Intercity",
        "Transport": "Transport",
        "About_Sender": "About Sender",
        "About_Merchant": "About Merchant",
        "About_Recipient": "About Recipient",
        "photos": "Photos",
        "listOfItems": "List of item",
        "cashOnPickUp": "COP",
        "Package_information": "Package information",
        "otwMerchant": "Driver on the way to merchant",
        "arrivedAndWaitingToCollectItem": "Arrived & waiting to collect item",
        "Save_Filter": "Save Filter",
        "add_favorite_filter": "Add filter",
        "favorite_filter_name": "Name",
        "list_favorite_filter": "Manage filters",
        "favorite_filter_actions": "Action",
        "clear_all_filters": "Clear all filters",
        "Delete_Filter": "Delete Filter",
        "CONFIRM_DELETE_FILTER_TEMPLATE": "Are you sure you want to delete this filter?",
        "batchDelivery": "Batch Delivery",
        "editFare": {
            "creditTransactionFee": "Credit card fees",
            "preferredAdjustment": "Preferred adjustment",
            "editFareFee": "Edit fare & fees",
            "markupPrice": "Markup price",
            "quotedPrice": "Quoted price",
            "basicFare": "Basic fare",
            "preferredEarning": "Preferred earning",
            "difference": "Difference",
            "rushHourFee": "Surcharge",
            "serviceFee": "Add'l service",
            "otherFees": "Other fees",
            "subTotal": "Sub total",
            "airportFee": "Airport fee",
            "meetDriverFee": "Meet driver",
            "deliveryFee": "Delivery fee",
            "tollFee": "Toll fee",
            "parkingFee": "Parking fee",
            "gasFee": "Gas fee",
            "techFee": "Tech fee",
            "tip": "Tip",
            "bookingFee": "Booking fee",
            "tax": "Tax",
            "promoAmount": "Promo amt",
            "etaFare": "Total",
            "fleetService": "Fleet service",
            "Note_serviceFee": "(to edit additional service, visit outer list)",
            "startingFee": "Starting",
            "moveFeeInJourney": "Moving",
            "waitFeeInJourney": "Waiting (in-journey)"
        },
        "partialCompleted": "Partial Completed",
        "failed": "Failed",
        "Recurring": "Recurring #",
        "DragAndDrop": "Drag and drop to reorder columns.",
        "bookingCom": "Booking.com",
        "syncingCompleted": "Syncing completed",
        "groupManifest": "Group Manifest",
        "assignSuppliers": "Assign Suppliers",
        "viewActions": "View Actions",
        "holidaytaxis": "HolidayTaxis",
        "karhoo": "Karhoo",
        "pickupTime": "Pick-up time",
        "AssignSupplier": "Assign Supplier",
        "sharedTransfer": "Shared Transfer (%{id})",
        "batchDeliveryGroup": "Batch Delivery (%{id})",
        "foundersFund": "Founders Fund",
        "zeroCommission": "Zero Commission",
        "unassignDriver": "Unassign Driver",
        "notUnassignDriver": "Cannot unassign driver, Please try again",
        "notAssignDriver": "Cannot assign driver, Please try again",
        "selectDriver": "Select Driver",
        "unassignVehicle": "Unassign Vehicle",
        "unassignAll": "Unassign All",
        "notUnassignAll": "Cannot unassign all, Please try again",
        "unassignAllSuccess": "The vehicle and driver have been successfully detached.",
        "unassign_all_partial": "Partial Success: Batch unassignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
        "notUnassignVehicle": "Cannot unassign vehicle, Please try again",
        "notAssignVehicle": "Cannot assign vehicle, Please try again",
        "selectVehicle": "Select Vehicle",
        "confirmUnassign": "Are you sure you want to unassign?"
    },
    "trip": {
        "Driver_vehicle": "Driver's vehicle",
        "tripId": "Trip #",
        "tripTypes": "Trip types",
        "Start_Time": "Start time",
        "Seats_Status": "Seats status",
        "No_Of_Booking": "No. of bookings",
        "Vehicle": "Driver's vehicle",
        "Estimated_Fare": "Fare",
        "capacityStatus": "Seat status",
        "Trip_Types": "Trip types",
        "scheduled": "Scheduled trips",
        "requested": "Request trips",
        "preferred": "Preferred",
        "All": "All",
        "Confirmed": "Confirmed",
        "Started": "Started",
        "In_Transit": "In Transit",
        "Empty": "Empty",
        "Partial_not_live": "Partial - not live",
        "Partial_live": "Partial - live",
        "Full": "Full",
        "Error_load_trip": "Cannot load the trip, Please try again or refresh page!"
    },
    "tripDetails": {
        "Trip_ID": "Intercity trip",
        "DRIVER_JOB": "Driver info",
        "BOOKINGS": "Bookings",
        "updateTrip": "Update Trip",
        "cancelTrip": "Cancel Trip",
        "note_cancel": "Please assign all bookings to another driver before cancel this trip.",
        "TRIP_INFORMATION": "Trip Info",
        "route": "Route",
        "startTime": "Start Time",
        "distance": "Distance",
        "duration": "Duration",
        "fare": "Fare",
        "cartype": "Intercity cartype",
        "seatStatus": "Seat status",
        "started": "Started",
        "intransit": "In transit",
        "empty": "Empty",
        "notLive": "Partial - not live",
        "live": "Partial - live",
        "full": "Full",
        "noOfBooking": "No. of booking",
        "luggage": "Luggage",
        "nameDriver": "Name",
        "vehicleType": "Vehicle",
        "assignDriver": "Assign driver",
        "pickUpAddress": "Pick up",
        "destinationAddress": "Drop off",
        "customerName": "Name",
        "customerPhone": "Phone",
        "customerNumber": "Seats",
        "customerLuggageNumber": "Luggage",
        "additionalService": "Additional services",
        "paymentBooking": "Payment",
        "note": "Note",
        "CONFIRM_UPDATE": "confirm update",
        "CONFIRM_UPDATE_MESSAGE": "Are you sure you want to update this trip?",
        "Yes": "Yes",
        "No": "No",
        "Update_info": "Yes, Update info"
    },
    "newbooking": {
        "Search_Vehicle": "Search for vehicle",
        "rideSharing_multi_point": "Only 1 pick-up and 1 drop off point are allowed for ride sharing booking.",
        "Pick_up_time": "Pick up time",
        "Drop_off_time": "Drop off time",
        "All_Suppliers": "All suppliers",
        "Recipient": "Recipient",
        "All": "All",
        "New_Booking": "Nuova Prenotazione",
        "GoogleMapRoute": "Google Map",
        "BOOKING_INFORMATION": "INFORMAZIONI PRENOTAZIONE",
        "TIP_PROMO": "Mancia - Promozione",
        "Pick_up": "Indirizzo di partenza",
        "instructions": "Instructions",
        "instructions_placeholder": "List your instructions here...",
        "Enter_a_location": "Inserisci località",
        "Enter_a_location_delivery": "Inserisci località",
        "Enter_3rd_party_location": "Inserisci localizzazione terzo",
        "Enter_a_sender": "Enter a Sender",
        "Enter_a_recipient": "Enter a Recipient",
        "Vehicle_Selection": "Vehicle Selection",
        "Destination": "Luogo appuntamento/destinazione",
        "Pickup_time": "Data e ora di partenza",
        "Note": "Note",
        "note_for_driver": "Note for driver",
        "affiliate_note": "Note for affiliate",
        "affiliate_note_placeholder": "Enter affiliate notes for operators",
        "operator_note": "Enter internal notes for operators",
        "TRAVELER_INFORMATION": "INFORMAZIONI VIAGGIATORE",
        "Traveler_type": "Tipo viaggiatore",
        "Individual": "Singolo autista",
        "Corporation": "Azienda",
        "Phone_number": "Numero di telefono",
        "First_name": "Nome",
        "Last_name": "Cognome",
        "Email_address": "Indirizzo email",
        "Corp_id": "Note",
        "Manager_name": " P.IVA/Cod.Fisc",
        "Manager_email": "Codice Univoco ",
        "Cost_centre": "@PEC",
        "Department": "Ragione sociale",
        "Corp_division": "Indirizzo",
        "Tip": "Mancia",
        "Promo_code": "Codice promozionale",
        "Apply": "Applica",
        "Promo": "Promozione:",
        "DISPATCH": "Assegnazione",
        "Auto_dispatch": "Assegnazione automatica",
        "Assign_driver": "Assegna Autista",
        "FarmOut": "Farm-out",
        "Driver": "Autista",
        "Driver_Supplier": "Supplier/Driver",
        "PAYMENT_METHOD": "MEZZO DI PAGAMENTO",
        "OPERATOR_NOTE": "Internal Notes",
        "TRIP_ESTIMATE": "STIMA DEL VIAGGIO",
        "Choose_a_stored_card": "Scegli una carta in memoria",
        "Choose_a_payment_method": "Scegli un metodo di pagamento",
        "Use_new_card": "Usa nuova carta",
        "Card_holder": "Nome titolare carta",
        "Card_number": "Numero carta",
        "Expired_date": "Data di sczdenza (mm/yy)",
        "ccv": "CVV",
        "Distance": "Distanza",
        "Duration": "Durata",
        "Fare": "Tariffa",
        "Route": "Strade",
        "Create": "Salva",
        "Cancel": "Cancella",
        "Meet_driver": "Incontra l’autista",
        "Airline": "Compagnia aerea",
        "Flight": "Volo #",
        "FLY_INFO": "INFORMAZIONI SUL VOLO",
        "Ride_Sharing": "Condividi-viaggio",
        "Round_trip": "Andata e ritorno",
        "Car_type": "Tipo di macchina",
        "Address": "Indirizzo",
        "City": "Città",
        "State": "Stato",
        "Zip_code": "Cap",
        "Remove": "Rimuovi",
        "Client_case_matter": "descrizione del viaggio",
        "Charge_code": "codice corsa",
        "CANCEL_BOOKING": "CLOSE BOOKING CONFIRM",
        "CANCEL_BOOKING_CONFIRM": "Vuoi lasciare questa pagina? Perdere tutti i dettagli della prenotazione.",
        "Search_here": "Cerca qui",
        "No_support_location": "Sfortunatamente il servizio non é disponibile in quest'area",
        "Closest_driver_message": "L'autista più vicino é approssimativamente a {0}",
        "No_cars_available": "Nessun mezzo disponibile in questo momento.",
        "processed_by_affiliate": "La tua richiesta verrà processata dai nostri partners affiliati",
        "FlightExample": "Volo #, e.g AA1097",
        "Yes": "Si",
        "No": "No",
        "dispatch_3rd": "Dispatch via 3rd party",
        "Company": "Azienda",
        "Card_required": "Per favore seleziona o aggiungi una carta!!",
        "Tip_value": "N/A",
        "Calendar": "Calendario",
        "Extra_Destination": "Destinazione aggiuntiva",
        "Driver_credit_limited_balance_warning": "Il saldo dell'autista é inferiore o uguale all'importo limitato",
        "Meet_Greet_option": "Incontra & Saluta",
        "On_Curb_option": "Trattenuto",
        "Extra_Services": "Servizi aggiuntivi",
        "Addl_Services": "Add'l Services",
        "Save": "Salva",
        "Regular": "Regolare",
        "Please_fill_in_all_the_required_fields": "Please fill in all the required fields.",
        "Please_select_weekdays": "Please select the repeat on days you want this booking to occur",
        "Flat_rate": "Flat rate",
        "All_company": "Tutta l'azienda",
        "Best_price": "miglior prezzo",
        "PromoTitle": "Promozione",
        "Show_more": "Mostra di più",
        "Warning_pending_payment": "This customer has incomplete payment from past bookings: %{fare}",
        "Warning_outstanding_balance": "Outstanding balance will be applied at the end of the trip.",
        "Not_available": "Not available",
        "Warning_assigned_rate": "Si prega di impostare e assegnare la tariffa per il tipo di auto selezionata per continuare.",
        "PaxLuggageNumber": "Passenger/luggage number",
        "paxNumber": "Passenger",
        "luggageNumber": "Luggage",
        "Date": "Date",
        "Single": "Single",
        "Recurring": "Recurring",
        "Date_range": "Date Range",
        "WeekDays": "Repeat on days",
        "Create_Route": "Create Route",
        "Pickup_Point": "Pick-up",
        "Drag_To_Render": "Drag and drop to reorder",
        "DropOff_Point": "Drop off points",
        "Sender": "Sender",
        "Recipients": "Recipients",
        "Sender_Infomation": "Sender Information",
        "Recipient_Infomation": "Recipient Information",
        "Recipient_Name": "Recipient name *",
        "Recipient_Phone": "Phone Number *",
        "Room_floor_block": "Room, floor, and block (optional)",
        "Details": "Details",
        "Package_Content": "Package Content *",
        "Order_ID": "Order # (eg. shopify order #)",
        "Instructions_for_driver": "Instructions for driver",
        "Cost_of_Goods": "Cash on Delivery (COD)",
        "Enter_amount": "Enter amount",
        "Additional_Information": "Additional Information",
        "Insurance": "Insurance",
        "Special_Care": "Special Care",
        "Delivery_Fee": "Delivery Fee",
        "Item_fees": "Item fees",
        "Tech_Fee": "Tech Fee",
        "Tax": "Tax",
        "driverNotes": "Driver Notes",
        "rejectModal": "Reject booking",
        "rejectModalBody": "Are you sure you want to reject this booking?",
        "rejectButton": "Reject booking",
        "acceptModal": "Accept booking",
        "acceptModalBody": "Are you sure you want to Accept this booking?",
        "ConfirmedTo": "Confirmed to ({0})",
        "proceedAction": "Are you sure you want to proceed with this action?",
        "acceptButton": "Accept booking",
        "denyModal": "Deny booking",
        "denyModalBody": "Are you sure you want to deny this booking?",
        "denyButton": "Deny booking",
        "Accept_booking_success": "This booking has been accepted successfully!",
        "Deny_booking_success": "This booking has been denied successfully!",
        "adjustFare": "Adjust fare",
        "totalFare": "Total Fare",
        "Profit": "Profit",
        "DriverEarning": "Driver Earning",
        "Payout": "Payout",
        "totalPayout": "Total payout",
        "DriverPayOut": "Driver Payout",
        "confirmReject": "Confirm Rejection",
        "confirmCancelMSG": "Are you sure you want to reject this booking? You may incur a penalty from your provider.",
        "reasonCancel3rd": "Reason for rejection",
        "welcome_psg": "Welcome passenger",
        "AmountDue": "Amount Due",
        "Fleet_Company": "Fleet",
        "Search_driver_hydra": "We will try to find you a driver soon.",
        "ExternalID": "External ID",
        "viewRate": "View Rate",
        "PointToPoint": "Point to point",
        "Offer_driver": "Offer Driver",
        "Assign_driver_vehicle": "Assign Driver / Vehicle",
        "notify_via_email": "Notify Customer via Email"
    },
    "bookingDetailLogs": {
        "recipient": "Recipient",
        "updatedInfo": "Updated info",
        "updatedField": "Updated field",
        "valueBefore": "Value before",
        "valueAfter": "Value after",
        "location": "Location",
        "etaFare": "Fare",
        "dispatchType": "Dispatch",
        "drvInfo": "Driver",
        "vehicleTypeRequest": "Vehicle Request",
        "clientCaseMatter": "Trip description",
        "chargeCode": "Trip code",
        "driverEarningType": "Driver Earning Type",
        "editedDriverEarning": "Driver earning",
        "reasonEditFare": "Reason",
        "bookingReference": "External ID",
        "tip": "Tip",
        "type": "Service type",
        "promo": "Promo",
        "dispatch3rd": "Dispatch 3rd",
        "rideSharing": "Ride-Sharing",
        "notes": "Driver notes",
        "operatorNote": "Internal notes",
        "welcomeMessage": "Welcome Message",
        "flightNumber": "Flight Number",
        "fullName": "Full Name",
        "email": "Email",
        "cardMask": "Card mask",
        "paymentType": "Payment method",
        "recipients": "Recipients",
        "sender": "Sender",
        "pickupName": "Pickup Name",
        "pickupPhone": "Pickup Phone",
        "pickupEmail": "Pickup Email",
        "supplierEarningType": "Payout type",
        "editedSupplierEarning": "Payout value",
        "route": "Route name",
        "markupDifference": "Markup Difference",
        "selectedDates": "Selected Dates",
        "services": "Fleet services",
        "destinationAddress": "Destination location",
        "pickupAddress": "Pick-up location",
        "tripType": "Trip Type",
        "pickUpTime": "Pickup time",
        "paxNumber": "Passenger",
        "luggageNumber": "Luggage",
        "vehicle": "Vehicle",
        "minutesToArrive": "Expected driver arrival",
        "affiliateNote": "Affiliate Note",
        "packageRateName": "Package name",
        "supplierCompanies": "Company",
        "puPoints": "Pick-up location",
        "doPoints": "Destination location",
        "promoValue": "Promo value",
        "packageInfo": "Package info",
        "orderId": "Order Id",
        "cashOnDelivery": "Cash on delivery",
        "externalId": "External Id",
        "recurring": "Recurring",
        "batchDelivery": "Batch Delivery",
        "payout": "Payout",
        "locationDetails": "Location Details",
        "name": "Name",
        "phone": "Phone",
        "address": "Address",
        "basicFare": "Basic fare",
        "airportFee": "Airport fee",
        "bookingFee": "Booking fee",
        "creditTransactionFee": "Credit card fees",
        "fleetService": "Fleet service",
        "fleetServices": "Fleet services",
        "gasFee": "Gas fee",
        "meetDriverFee": "Meet driver fee",
        "moveFeeInJourney": "Moving fee",
        "otherFees": "Other fee",
        "parkingFee": "Parking fee",
        "promoAmount": "Promo amt",
        "rushHourFee": "Rush hour fee",
        "serviceFee": "Service fee",
        "startingFee": "Starting fee",
        "subTotal": "Sub total",
        "tax": "Tax",
        "techFee": "Tech fee",
        "tollFee": "Toll fee",
        "waitFeeInJourney": "Waiting (in-journey) fee"
    },
    "bookingdetail": {
        "settings": "Settings",
        "updateSuccessJobVisibilitySingle": "Update visibility successfully",
        "updateSuccessJobVisibilityMulti": "%{number} bookings has been updated successfully",
        "failApi": "Something went wrong!",
        "settingsTooltip": "Driver already assigned or trip is currently in progress.",
        "shareJobPublicly": "Share Job Publicly",
        "groupOder": "Group order",
        "ExistingGroup": "Existing Group",
        "NewGroup": "New Group",
        "Search_trip": "Search trip #",
        "All": "All",
        "creditTransactionFee": "Credit card fees",
        "partial_payment": "Partial Payment",
        "Write_off_debt": "Write off debt",
        "Booking_detail": "Dettagli prenotazione",
        "Order_detail": "Order",
        "Completed_Booking": "Completed Booking",
        "Late_Cancel": "Late cancel",
        "Cancel_Driver": "Cancel by driver",
        "No_Show": "No show",
        "Without_driver": "Complete without driver",
        "Complete_driver": "Complete with driver",
        "BOOKING_INFORMATION": "INFORMAZIONI PRENOTAZIONE",
        "TIP_PROMO": "Mancia - Promozione",
        "Rearrange_Column": "Rearrange Column",
        "Pick_up": "Appuntamento*",
        "Enter_a_location": "Inserisci località",
        "Destination": "Destinazione",
        "Pickup_time": "Data e ora di partenza",
        "Notes": "Note",
        "TRAVELER_INFORMATION": "INFORMAZIONI VIAGGIATORE",
        "Traveler_type": "Tipo viaggiatore",
        "Individual": "Singolo autista",
        "Corporation": "Azienda",
        "Phone_number": "Numero di Telefono",
        "First_name": "Nome",
        "Last_name": "Cognome",
        "Email_address": "Indirizzo email",
        "Corp_id": "Note",
        "Manager_name": " P.IVA/Cod.Fisc",
        "Manager_email": "Codice Univoco ",
        "Cost_centre": "@PEC",
        "Department": "Ragione sociale",
        "Corp_division": "divisione Società",
        "Tip": "Mancia",
        "Promo_code": "Codice promozionale",
        "Apply": "Applica",
        "Promo": "Promozione",
        "DISPATCH": "Assegnazione",
        "Auto_dispatch": "Assegnazione automatica",
        "Assign_driver": "Assegna autista",
        "Driver": "Autista",
        "PAYMENT_METHOD": "SELEZIONA UN TIPO DI PAGAMENTO",
        "Payment_methods": "Mezzo di Pagamento",
        "pending_payment": "Pending Payment",
        "TRIP_ESTIMATE": "STIMA DEL VIAGGIO",
        "Choose_a_stored_card": "Scegli una carta in memoria",
        "Choose_a_payment_method": "Scegli un metodo di pagamento",
        "OPERATOR_NOTE": "Internal Notes",
        "Use_new_card": "Usa nuova carta",
        "Card_holder": "Titolare carta",
        "Card_number": "Numero carta",
        "Expired_date": "Data di sczdenza (mm/yy)",
        "ccv": "CVV",
        "Distance": "Distanza",
        "Duration": "Durata",
        "Fare": "Tariffa",
        "Route": "Strada",
        "Meet_driver": "Incontra l’autista",
        "Airline": "Compagnia aerea",
        "Flight": "Volo",
        "FLY_INFO": "Informazioni sul volo",
        "Ride_Sharing": "Condividi-viaggio",
        "Round_trip": "Andata e ritorno",
        "Car_type": "Tipo di macchina",
        "Address": "Indirizzo",
        "City": "Città",
        "State": "Stato",
        "Zip_code": "Cap",
        "Remove": "Rimuovi",
        "New_Booking": "Nuova Prenotazione",
        "Clone_Booking": "Clone Booking",
        "New_Order": "New Order",
        "ViewTrackLink": "View track link",
        "driverLink": "Driver link",
        "ClickCopy": "Click to copy link",
        "ViewLog": "View dispatch logs",
        "Message": "Message",
        "Update_Booking": "Aggiorna Prenotazione",
        "Cancel_Booking": "Cancella Prenotazione",
        "Complete_with_payment": "Completa con pagamento",
        "Incident": "Incidente",
        "CONFIRM_CANCEL": "CONFERMI LA CANCELLAZIONE",
        "CONFIRM_CANCEL_MESSAGE": "Sei sicuro di voler cancellare la prenotazione?",
        "CONFIRM_UPDATE": "CONFERMA AGGIORNAMENTO",
        "CONFIRM_UPDATE_MESSAGE": "Sei sicuro di volere aggiornare questa prenotazione?",
        "CONFIRM_RESET_EDIT_FARE_MESSAGE": "Your edited fare has been reset, please review before continue.",
        "Close": "Vicino",
        "Update_info": "Informazioni aggiornamento",
        "Update_info_dispatch": "Informazioni aggiornamento & assegnazione",
        "Update_info_tooltip": "Se vuoi riassegnare questa prenotazione a un nuovo autista, seleziona \"Sì, aggiorna informazioni e spedizione\"",
        "BOOKING": "presente",
        "DROPPED_OFF": "scaricato",
        "Payment_Type": "Tipo pagamento",
        "Total": "Totale",
        "Next": "Prossimo",
        "Pay_here": "Paga qui",
        "Complete_booking": "Complete booking",
        "Complete_booking_tooltip": "Please click \"Update booking\" to save your changes before continuing.",
        "Cancel": "Cancella",
        "Back": "Indietro",
        "Tax": "Tassa",
        "Booking_fees": "Spese di prenotazione",
        "Tech_fees": "Spese tecniche",
        "Subtotal": "Subtotale",
        "Other_fees": "Altre spese",
        "Waiting_fee": "Waiting fee",
        "Toll_fees": "Toll fees",
        "Heavy_traffic": "Traffico intenso",
        "Rush_hour": "Sovraccarico",
        "Basic_fare": "Tariffa base",
        "Ok": "Ok",
        "Input_reason": "Inserisci ragione",
        "Client_case_matter": "Client case matter / Trip description",
        "Charge_code": "Charge code / Trip code",
        "Enter_3rd_party_location": "Inserisci localizzazione terzo",
        "Company": "Azienda",
        "Check_promo_error": "Verifica errore codice promozionale!",
        "Add_new_credit_error": "Aggiungi nuovo errore credito!",
        "Can_not_get_fare_setting": "Impossibile ottenere impostazioni tariffa!",
        "Can_not_get_affilate_fare_setting": "Impossibile ottenere impostazioni tariffa dell'Affiliato!",
        "Can_not_check_commisson": "impossibile verificare commissione!",
        "No_flight_info": "Nessuna informazione sul volo!",
        "Socket_server_disconnected": "Socket server disconnected!",
        "Please_reload_page_or_check_your_connection": "Sorry. Your request cannot be processed. Please try again.",
        "View_Logs": "View logs",
        "Bookingid": "Prenotazione #",
        "Calendar": "Calendario",
        "Extra_Destination": "Destinazione aggiuntiva",
        "Meet_Greet_option": "Incontra & Saluta",
        "On_Curb_option": "Trattenuto",
        "Credit_Card": "Carta di credito",
        "Charge": "Addebita",
        "No_charge": "Trattenuto = nessun addebito",
        "QR_Code_method_not_allow_error_message": "Please select another payment type as QRCode is not available to pay on dashboard!",
        "change_payment_method_note": "Se vuoi cambiare metodo di pagamento ritorna al formulario di prenotazione.",
        "Airport_Fee": "Tariffa aeroporto",
        "Addi_service": "Servizi Supplementari",
        "Additional_Services": "Servizi Supplementari",
        "Minimum": "Minimo",
        "Search_here": "Cerca qui",
        "No_Driver_Yet": "No driver assigned yet",
        "Personal_Card": "Carta personale",
        "Reject_booking": "Reject Booking",
        "Confirm_reject": "Confirm reject",
        "Confirm_reject_message": "Are you sure you want to reject this booking?",
        "Confirm_reject_booking_with_charge": "You will be charged {0}% of quoted fare based on our cancellation policy. Are you sure you want to reject this booking?",
        "Yes": "Si",
        "No": "No",
        "shortTrip": "breve viaggio",
        "Add_new_card": "Aggiungi carta",
        "Require_customer_info": "Inserisci le informazioni del cliente per continuare.",
        "confirm_force_overlap": "Confirmation",
        "confirm_force_overlap_message": "This booking overlaps with driver's accepted bookings. Are you sure you want to assign it to this driver?",
        "select": "Select",
        "PICKUP_TIME_RANGE": "Estimated pickup at {0}",
        "food": "Food",
        "mart": "Mart",
        "Order_summary": "Order Summary",
        "addOnPrice": "Adjusted price",
        "applicableFee": "Total (applicable)",
        "itemValue": "Item value",
        "promotion_note_paymentMethod": "This order is being applied promotion code with limited payment method: %{method}.",
        "promotion_note_minimum_fare": "This order is being applied promotion code with limited minimum fare: %{fare}.",
        "promotion_note_schedules": "This order is being applied promotion code with limited pickup time.",
        "edited_fare_note": "This is fare adjustment logs for reference only. Please visit reports to check final fare and fee.",
        "view_good_receipt": "View goods receipt",
        "confirmRemoveGroupTransport": "Are you sure you want to remove current booking from this manifest?",
        "confirmRemoveGroupDelivery": "Are you sure you want to remove current booking from this group?",
        "confirmUngroupTransport": "Are you sure you want to ungroup all bookings from this manifest?",
        "confirmUngroupDelivery": "Are you sure you want to ungroup all bookings from this group?",
        "view_delivery_receipt": "View delivery photo",
        "select_time": "Select time",
        "InputReason": "Input reason",
        "viewOrder": "View order",
        "markFailed": "Mark as failed",
        "corporateName": "Corporate Name",
        "sendMessage": "Send Message",
        "AmountDueBefore": "Amount Due Before",
        "AmountDueAfter": "Amount Due After",
        "chargeCustomer": "Charge Customer",
        "pending": "Not Paid",
        "refundPayment": "Refund Payment",
        "refundableAmount": "Refundable amount",
        "refundReason": "Add refund reason",
        "refundBtn": "Refund {0}",
        "partial": "Partial",
        "paid": "Paid",
        "ViewPaymentLogs": "View payment logs",
        "pmActivity": "Payments Activity",
        "paidStatus": "Paid status",
        "paymentLog": "Payment Log",
        "extraWaitFee": "Waiting fee Initial",
        "extraWaitTime": "Wait time (Initial)",
        "copied": "Copied",
        "warnningCancelCorporate": "Please note, you may incur a charge of amount in accordance with our cancellation policy."
    },
    "map": {
        "Enter_a_location": "Inserisci una località",
        "All_company": "Tutta l'azienda",
        "All_cars": "Tutte le macchine",
        "Show_all_cars": "Show all cars",
        "Hide_all_cars": "Hide all cars",
        "Vacant": "Libero",
        "Dispatching": "Assegnazione",
        "Driver_on_the_way": "Autista in arrivo",
        "Arrived_and_waiting": "Arrivato e in attesa",
        "P_O_B": "P.A.B.",
        "Dropped_off": "Scaricato",
        "Waiting_for_process": "In attesa di trattazione",
        "Unavailable": "Indisponibile",
        "Total_Drivers": "Total Drivers",
        "Estimate": "Stima",
        "Destination": "Destinazione",
        "Pickup": "Indirizzo di partenza",
        "Start_point": "Punto di partenza",
        "Passenger": "Passeggeri",
        "Driver_name": "Nome autista",
        "Search_car_here": "Cerva vettura qui",
        "Error_load_booking": "Impossibile caricare la prenotazione, Per favore riprova o ricarica la pagina!",
        "Show_all": "Mostra tutto",
        "driversLiveMap": "Drivers Map",
        "heatMap": "Demand Map",
        "Trip": "Trip",
        "traffic": "Traffic",
        "bicyclingLayers": "Bicycling",
        "layer": "Layer",
        "trafficBicycling": "Traffic,Bicycling",
        "All_airline": "All airline"
    },
    "customer": {
        "Customers": "Clienti",
        "Points": "Points",
        "Migration_Status": "Migration Status",
        "ADD_CUSTOMER": "Aggiungi cliente",
        "EDIT_CUSTOMER": "Modifica Cliente",
        "Add": "Aggiungi",
        "Activate": "Attivare.",
        "Deactivate": "Disattivato",
        "Delete": "Elimina",
        "Import": "Importa",
        "Download_template": "Download template",
        "View_Message": "Messaggio",
        "Send_Message": "Messaggio",
        "Export_to_excel": "Esporta su excel",
        "Filter": "Filtro",
        "Apply": "Applica",
        "Clear": "Pulisci",
        "Name": "Nome",
        "Phone_Number": "Numero di telefono",
        "Email": "Email",
        "Vip": "Vip",
        "companies": "Company",
        "File": "Documento",
        "Number_successful_imports": "Number of successful imports: ",
        "Number_skipped": "Numero di saltati:",
        "Number_duplicate_phone_numbers": "Number of duplicate phone numbers: ",
        "Number_wrong_phone_numbers": "Number of wrong phone numbers: ",
        "Corporate_Name": "Azienda",
        "Registered_From": "Registrato da",
        "Registration_Date": "Data di registrazione",
        "Registration_date_from": "Data di registrazione da ",
        "Registration_date_to": "Data di registrazione a",
        "IOS_Last_Login_Version": "IOS Versione ultima connessione",
        "Android_Last_Login_Version": "Android Versione ultima connessione",
        "Last_Active_Date": "Ultima data attiva",
        "Verification": "Verification",
        "Support_ID": "Support ID",
        "Days_Since_Last_Active": "Giorni dall'ultima data attiva",
        "Completed_Bookings": "Prenotazioni completate",
        "Outstanding_amount": "Outstanding amount",
        "Status": "Stato",
        "Actions": "Azioni",
        "Action": "Azioni",
        "Inactive": "Inattivo",
        "Active": "Attivo",
        "Edit": "Modifica",
        "View": "Mostra",
        "ALERT_SELECT_ACTIVATE": "Per favore seleziona cliente da attivare.",
        "ALERT_SELECT_DEACTIVATE": "Per favore seleziona cliente da disattivare.",
        "ALERT_SELECT_DELETE": "Per favore seleziona cliente da eliminare.",
        "CONFIRM_DELETE": "Sei sicuro di voler eliminare questo account? Il cliente può registrare un nuovo profilo con dati vuoti dopo la rimozione dell'account.",
        "CONFIRM_DELETE_MULTI": "Vuoi eliminare questi clienti? ** Solo i clienti che sono inattivi e non sono utilizzatori registrati della app possono essere eliminati.",
        "CONFIRM_DEACTIVATE_MULTI": "Vuoi disattivare questi clienti?",
        "CONFIRM_ACTIVATE_MULTI": "Vuoi attivare questi clienti?",
        "CONFIRM_EXPORT_TO_EXCEL": "Are you sure you want to export all data as a .xlsx file?",
        "Add_card_top_up_success": "Prepaid card has been topped up successfully",
        "Add_card_success": "Carta personale aggiunta con successo!",
        "Add_customer_success": "Il Cliente é stato aggiunto con successo",
        "Update_customer_success": "Il Cliente é stato aggiornato con successo",
        "Deleted_customer_success": "The customer has been deleted successfully",
        "Create_customer_fail": "Impossibile creare cliente",
        "Update_customer_fail": "Impossibile aggiornare cliente",
        "ERROR_EXISTED_PHONE": "Questo telefono esiste già nel sistema. Per favore inseriscine un altro.",
        "ERROR_INPUT_FIRSTNAME": "Per favore inserisci nome.",
        "ERROR_INPUT_PHONE": "Per favore inserisci numero di telefono.",
        "ERROR_INPUT_VALIDPHONE": "Per favore inserisci un numero di telefono valido.",
        "ERROR_INPUT_VALIDEMAIL": "Per favore inserisci una email valida.",
        "CONFIRM_DELETE_CARD": "Sei sicuro di voler cancellare queste carte?",
        "First_name": "Nomee",
        "Last_name": "Cognome",
        "Phone_number": "Numero di telefono",
        "Email_address": "Indirizzo email",
        "Notes": "Appunti",
        "Account": " Conto",
        "Card_management": "Gestione carta",
        "Home": "Casa",
        "Affiliate": "Affiliato",
        "SHOW_PERSONAL_CARD": "mostra carta personale",
        "ADD_PERSONAL_CARD": "Aggiungi carta personale",
        "SHOW_mDISPATCHER_CARD": "MOSTRA CARTA mDISPATCHER",
        "ADD_mDISPATCHER_CARD": "Aggiungi carta mdispatcher",
        "Personal_card": "Carta personale",
        "mDispatcher_card": "Carta mDispatcher",
        "Card_holder": "Titolare carta ",
        "Card_number": "Numero carta",
        "Credit_type": "Credit type",
        "Credit_code": "Credit code",
        "Sms_verification": "SMS verification",
        "Sms_verification_placeholder": "Enter verification code",
        "Expired_date": "Data scadenza ",
        "CVV": "CVV",
        "Address": "Indirizzo",
        "Country": "Paese",
        "City": "Città",
        "State": "Stato",
        "Zip_code": "Cap",
        "postal_code": "Postal code",
        "Required_fields": "Campi obbligatori",
        "Cancel": "Cancella",
        "Save": "Salva",
        "ERROR_INPUT_CARD_HOLDER": "Per favore inserisci titolare carta",
        "ERROR_INPUT_CARD_NUMBER": "Per favore inserisci numero carta",
        "ERROR_INPUT_EXPIRED_DATE": "Per favore inserisci data di scadenza",
        "ERROR_VAILD_EXPIRED_DATE": "Data di scadenza invalida.",
        "ERROR_INPUT_CVV": "Per favore inserisci CVV",
        "ERROR_INPUT_ADDRESS": "Per favore inserisci l'indirizzo",
        "ERROR_INPUT_CITY": "Per favore inserisci nome città",
        "ERROR_INPUT_ZIPCODE": "Per favore inserisci cap",
        "ERROR_INPUT_COUNTRY": "Please select country",
        "Info_edit_error": "Errore!",
        "Gender": "Genere",
        "Select_Gender": "Seleziona",
        "Select_Idtype": "Select",
        "Male": "Maschio",
        "Female": "Femmina",
        "Other": "Altro",
        "Date_of_birth": "Data di Nascita",
        "Street_address": "Indirizzo",
        "Select_Zone": "Seleziona Zona",
        "ERROR_GET_GATEWAYS": "ERROR !!! Get Gateways",
        "Require_email": "Per favore inserisci email prima di aggiungere una nuova carta",
        "Require_lastName": "Per favore inserisci cognome prima di aggiungere la nuova carta",
        "Require_phone_number": "Per favore inserisci il numero di telefono del viaggiatore prima di fare una prenotazione con carta di credito",
        "IMPORT_CUSTOMER_SUCCESS": "Clienti importati con successo",
        "No_card": "Nessuna carta trovata.",
        "No_Support": "L'aggiunta di una nuova carta non é supportata in quest'area",
        "Card_Management": "Gestione carta",
        "Emergency_contacts": "Emergency contacts",
        "DETAIL_CUSTOMER": "Dettagli Cliente",
        "Select_Country": "Seleziona città",
        "Customer_ID": "Carta di identità # Carta di identità nazionale #",
        "Credit_balance": "Credit balance",
        "required_email_payment": "The email of customer are required. Please update profile to continue.",
        "Yes": "Yes",
        "No": "No",
        "WriteOffDebt": "Write off debt",
        "selectPayment": "Please select a payment method to close all bookings which have outstanding payment",
        "writeOffDebtSuccess": "Write off debt successfully",
        "writeOffDebtNotes": "Note: This write-off action should only be confirmed after all the outstanding amount is paid in full.",
        "totalCustomer": "Total customers",
        "Personal_Card": "Personal card",
        "INFO_ADD_BALANCE_SUCCESS": "Passenger balance has been updated successfully",
        "INFO_UPDATE_POINT_SUCCESS": "Passenger point has been updated successfully",
        "currency": "Currency",
        "balance": "Balance",
        "changeBalance": "Change balance",
        "change": "Change",
        "WALLET_DEPOSIT": "Wallet",
        "AccessDenied": "You don't have permission to access this module!",
        "referral_code": "Referral code"
    },
    "importBookings": {
        "no": "No.",
        "status": "Status",
        "pickupDate": "Pickup Date",
        "pickup": "Pickup Address",
        "dropoff": "Dropoff Address",
        "passenger": "Passenger",
        "phone": "Phone number",
        "seats": "Seats",
        "luggages": "Luggages",
        "rideSharing": "Ride-share",
        "flightNumber": "Flight No.",
        "notes": "Notes",
        "externalId": "External ID",
        "operatorNote": "Internal notes",
        "clientCaseMatter": "Trip description",
        "chargeCode": "Trip code",
        "paymentType": "Payment",
        "vehicleTypeRequest": "Car type",
        "importBookings": "Import bookings",
        "corporation": "Corporation",
        "clickOrDropCsvFile": "Click or drop.csv file",
        "importingFileForReview": "Importing file for review... ",
        "import": "Import",
        "submit": "Submit",
        "downloadTemplate": "Download template",
        "learnMore": "Learn more",
        "total": "Total",
        "valid": "Valid",
        "invalid": "Invalid",
        "failed": "Failed",
        "processing": "Processing",
        "success": "Success",
        "confirm": "Confirm",
        "confirmMessage": "orders will be created. Are you sure you want to proceed ?",
        "create": "Create",
        "cancel": "Cancel",
        "close": "Close",
        "leavingConfirmMessage": "Your work is not saved! Are you sure you want to leave?",
        "resultMessage": "${success} booking(s) has been created successfully, ${failed} failed.",
        "limitExceededBookings": "Maximum import file size: 100 bookings",
        "delivery": {
            "no": "#",
            "pickupDate": "Pickup date",
            "senderName": "Sender name",
            "senderPhone": "Sender phone",
            "senderMail": "Sender email",
            "senderAddress": "Sender address",
            "recipients": "Recipients",
            "senderDistrictOptional": "District",
            "senderLocationDetails": "Location details",
            "recipientName": "Recipient name",
            "recipientPhone": "Recipient phone",
            "recipientAddress": "Recipient address",
            "recipientDistrictOptional": "District",
            "recipientLocationDetailsOptional": "Location Details",
            "packageContent": "Package content",
            "orderNumber": "Order #",
            "instructions": "Instructions for Driver",
            "cod": "COD Item Value",
            "rideShare": "Ride-share",
            "paymentType": "Payment type",
            "carType": "Car type",
            "recipientLocationDetails": "Location details",
            "operatorNote": "Internal notes",
            "rideSharing": "Batch delivery"
        }
    },
    "merchant": {
        "INFO_ADD_BALANCE_SUCCESS": "Merchant balance has been updated successfully",
        "ADD_MERCHANT_USER": "Add merchant account",
        "EDIT_MERCHANT_USER": "Edit merchant account",
        "MERCHANT_USER_DETAILS": "Merchant account details",
        "CASH_WALLET": "Cash wallet",
        "CREDIT_WALLET": "Credit wallet",
        "ADD_MERCHANT": "Add merchant",
        "ACCOUNT_INFO": "Account Info",
        "BILLING_ADDRESS": "Bank Information",
        "user": {
            "fullName": "Merchant user",
            "Phone_Number": "Phone number",
            "Email": "Email",
            "storeName": "Merchant",
            "status": "Status",
            "actions": "Action",
            "First_name": "First name",
            "Last_name": "Last name",
            "Select_store": "Select merchant",
            "accountHolder": "Account holder's name",
            "First_M_Last": "First M. Last",
            "accountNumber": "Account number",
            "ibanNumber": "IBAN number",
            "bankName": "Name of bank",
            "IDtype": "ID Type",
            "IDnumber": "ID# / National IC#",
            "routingNumber": "Routing/Transit/Branch number",
            "checkNumber": "Check number",
            "ssn": "SSN/NI",
            "sortCode": "Sort code",
            "transitNumber": "Transit number",
            "institutionNumber": "Institution Number",
            "birthDay": "Birthday",
            "address": "Address",
            "city": "City",
            "state": "State",
            "postalCode": "Zipcode",
            "beneficiaryIDIC": "Beneficiary ID# / IC#",
            "relationshipOtherName": "Relationship with other?",
            "verificationDocumentFront": "Verification document (front)",
            "verificationDocumentBack": "Verification document (back)",
            "Verification_document_tooltip": "Acceptable forms for identification: \n  - Passport \n  - Driver license \n  - Resident permit ID \n  - Citizen Card \n  - ID card",
            "additionalDocumentFront": "Additional document (front)",
            "additionalDocumentBack": "Additional document (back)",
            "Additional_document_tooltip": "Acceptable forms of address verification: \n  - Driver license \n  - Utility bill \n  - Government issued letter \n  - Statement from a financial institution \n  - Electoral Register entry",
            "please_select": "Please select",
            "commission": "Commission",
            "ERROR_INPUT_VALIDPHONE": "Please enter a valid phone number.",
            "ERROR_EXISTED_PHONE": "This phone has been existing on the system. Please input another one",
            "Activate": "Activate",
            "Deactivate": "Deactivate",
            "ALERT_SELECT_ACTIVATE": "Please select user to activate.",
            "ALERT_SELECT_DEACTIVATE": "Please select user to deactivate.",
            "CONFIRM_ACTIVATE_MULTI": "Do you want to activate these users?",
            "CONFIRM_DEACTIVATE_MULTI": "Do you want to deactivate these users?",
            "RESET_PASSWORD": "Do you want to reset password?",
            "CONFIRM_RESET_PASSWORD": "Do you want to reset password?",
            "REMOVE_BANK_INFO": "Confirm remove bank info",
            "CONFIRM_REMOVE_BANK_INFO": "Do you want to remove bank info?",
            "note_account_password_default": "Note: For new Merchant account, the default password is \"12345678\"",
            "Cash_Balance": "Cash balance",
            "Credit_Balance": "Credit balance"
        },
        "Update_merchant_user_success": "The merchant user has been updated successfully",
        "Update_merchant_user_fail": "Failed to update merchant user",
        "Create_merchant_user_success": "New merchant user has been created successfully",
        "Create_merchant_user_fail": "Failed to create merchant user",
        "Update_merchant_user_status_success": "The merchant user has been updated successfully",
        "Update_merchant_user_status_fail": "Failed to update merchant user",
        "Reset_password_merchant_user_success": "Reset password successfully",
        "Reset_password_merchant_user_fail": "Reset password fail",
        "Deleted_merchant_bank_info_success": "Remove bank info successfully",
        "Deleted_merchant_bank_info_fail": "Remove bank info fail"
    },
    "table_header": {
        "Items_per_page": "Oggetti per pagina",
        "Page": "Pagina",
        "Search_here": "Cerca qui",
        "Shown_Active": "%{start} - %{end} of %{total} mostrati ",
        "Active": "%{active} Attivo ",
        "OF": "of"
    },
    "driver": {
        "Driver": "Driver",
        "Add": "Aggiungi",
        "addDriver": "Add driver",
        "Detail": "Detail",
        "detailDriver": "Driver Details",
        "addNewVehicle": "Add Vehicle",
        "serviceType": "Service Type",
        "transport": "Transport",
        "delivery": "Delivery",
        "stripeConnect": "Stripe Connect",
        "stripeConnectDes": "Add a stripe connect account for easier payouts",
        "bankAccount": "Banking Info",
        "bankAccountDes": "Drivers bank info to handle payouts",
        "supplierComission": "Supplier Commission",
        "supplierComissionDes": "Set commissions the supplier earns for every complete order.",
        "fleetCommission": "Fleet/Supplier commission",
        "fleetCommissionDes": "Set commissions the fleet or supplier earns for every complete order. If the driver is a supplier’s driver, the commission will be deducted from the supplier’s earning.",
        "operationZones": "Operation Zones",
        "operationZonesDes": "Zones that suppliers can receive jobs from:",
        "notes": "Notes",
        "notesDes": "Internal notes about this driver:",
        "searchNotes": "Add notes for driver",
        "marketplace": "Marketplace",
        "marketplaceDes": "Empower drivers to accept jobs they want to do.",
        "otherSettings": "Other Settings",
        "topDriver": "TOP Driver",
        "topDriverDes": "Prioritize top performers with the \"Top Driver\" tag, ensuring they get more rides.",
        "walletTransferBetweenDriver": "Wallet Transfers Between Drivers",
        "walletTransferBetweenDriverDes": "Enable drivers to transfer funds directly to each other’s wallets, facilitating easy distribution of credits by team leaders or ambassadors.",
        "rideSharing": "Ride-sharing",
        "rideSharingDes": "Enable drivers to perform ride-sharing jobs. Contact your account manager for this advance feature.",
        "individual_vehicle": "Individual vehicle",
        "company_vehicle": "Company vehicle",
        "ContactInfo": "Contact Info",
        "name": "Name",
        "expiryDate": "Expiry Date",
        "action": "Action",
        "editDocument": "Edit Document",
        "documentToUpload": "Document to upload",
        "addFiles": "Click or drag file to this area to upload.",
        "fileSize": "Maximum file size 10 MB",
        "fileSizeDes": "The file extension must be .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip",
        "removeOldFile": "Please remove the old file before uploading a new one.",
        "maxLengthFiles": "Please upload max length 1 file",
        "maxSizeFiles": "Please upload file size less than 10MBs",
        "typeFiles": "Please upload file type .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip",
        "maxAvatarSizeFiles": "Please upload avatar size less than 8MBs",
        "IFSCCode": "IFSC Code",
        "Drivers": "Autisti",
        "default": "Default",
        "customize": "Customized",
        "Activate": "Attivato",
        "Stripe_connect": "STRIPE CONNECT",
        "status_stripe": "Status: {0} ",
        "stripeConnectStatus": "Stripe connect",
        "stripeStatus": "Stripe account status",
        "Deactivate": "Disattivare",
        "Delete": "Cancella",
        "acceptInDay": "Maximum reservations driver can accept in a day",
        "acceptATime": "Total number of reservations a driver can accept at a time",
        "Commission": "Set fleet commission",
        "Title_Commission": "Set fleet commission for all drivers",
        "Confirm_Set_Commission": "Are you sure you want to set this commission value for all drivers?",
        "Commission_Successfully": "Driver commission has been updated successfully",
        "Apply": "Apply",
        "Type": "genere",
        "Currency": "Currency",
        "View_Message": "Messaggio",
        "Send_Message": "Invia Messaggio",
        "Driver_Name": "Nome autista",
        "Driver_ID": "Carta di identità # Carta di identità nazionale #",
        "Phone_Number": "Numero di telefono",
        "Driver_Type": "Tipo di Autista",
        "TOP": "TOP",
        "Company": "Azienda",
        "City": "Città",
        "Driver_License_Number": "Numero Patente Autista",
        "License_Plate": "Numero di targa",
        "Car_Type": "Tipo di macchina",
        "Zone": "Zona",
        "Registered_From": "Registrato da",
        "Registration_Date": "Data di registrazione",
        "IOS_Last_Login_Version": "IOS Versione ultima connessione",
        "Android_Last_Login_Version": "Android Versione ultima connessione",
        "Last_Active_Date": "Ultima data attiva",
        "Days_Since_Last_Active": "Giorni dall'ultima data attiva",
        "Completed_bookings": "Prenotazioni completate",
        "Status": "Stato",
        "Actions": "Azioni",
        "Action": "Azioni",
        "Inactive": "Inattivo",
        "Active": "Attiva",
        "Edit": "Modifica",
        "View": "Mostra",
        "ALERT_SELECT_ACTIVATE": "Per favore seleziona l’autista da attivare.",
        "ALERT_SELECT_DEACTIVATE": "Per favore seleziona l’autista da disattivare.",
        "ALERT_SELECT_DELETE": "Per favore seleziona l’autista da eliminare.",
        "CONFIRM_DELETE": "Vuoi eliminare questo autista?",
        "CONFIRM_DEACTIVATE_MULTI": "Sei sicuro di voler disattivare l’autista(i) selezionato(i)? Il loro contatore hardware collegato sarà ugualmente disabilitato.",
        "CONFIRM_ACTIVATE_MULTI": "DVuoi attivare questi autisti? Solo l’autista(i) predisposto per essere operativo puo’ essere attivato.",
        "CONFIRM_DELETE_MULTI": "DVuoi eliminare questi autisti? Solo un autista in status inattivo puo’ essere eliminato..",
        "CONFIRM_REMOVE_BANKING_INFOR": "Are you sure you want to remove banking information?",
        "REMOVE_BANKING_INFOR": "Remove banking information",
        "ADD_DRIVER": "Aggiungi autista",
        "Avatar": "Avatar",
        "Individual_Driver": "Singolo autista",
        "Owner_Operator": "Operatore Proprietario",
        "deliveryCompany": "Delivery - Company vehicle",
        "deliveryIndividual": "Delivery - Individual vehicle",
        "CONTACT_INFO": "Informazioni di contatto",
        "Home_address": "Indirizzo Casa",
        "Username": "Username",
        "First_name": "Nome",
        "Last_name": "Cognome",
        "Phone_number": "Numero di Telefono",
        "Email_address": "Indirizzo email",
        "Select_city": "Seleziona citta’",
        "State": "State",
        "Zip_code": "Cap",
        "Date_of_birth": "Data di Nascita",
        "placeholderDate": "mm/dd/yyyy",
        "City_driver_want_to_drive": "City driver want to drive",
        "Driver_license_number": "Numero Patente Autista",
        "Driver_license_state": "Stato Patente Autista/Province",
        "Driver_license_expiry": "La Patente dell’Autista Scade",
        "FOR_INSPECTOR": "Per ispettore",
        "Document": "Documento",
        "Document_Id": "Document ID",
        "Link": "Link",
        "Notes": "Note",
        "UPLOAD_DOCUMENT": "Carica documento",
        "Click_here_to_download": "Clicca qui per scaricare.",
        "Click_here_to_view": "Click to view",
        "EXTENSION_REQUIREMENT": "L’estensione del documento deve essere jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, e di taglia inferiore a 5Mb.",
        "Invalid_file": "Documento invalido",
        "File_size_larger_than_5Mb": "La taglia del file deve essere maggiore di 5Mb",
        "BANK_ACCOUNT": "Conto bancario",
        "Name_of_bank": "Nome della banca",
        "Name_of_account": "Nome del conto",
        "First_M_Last": "Primo M. Ultimo",
        "RTBnumber": "Routing/Transit/Branch number",
        "bankName": "Bank name",
        "Select_a_bank": "Select a bank",
        "Account_number": "Numero del conto",
        "IBAN_number": "Numero IBAN",
        "Check_number": "Check number",
        "Swift_code_number": "Numero codice swift",
        "SSNNI": "SSN/ NI",
        "sortCode": "Sort code",
        "Verification_document": "Verification document (front)",
        "Additional_document": "Additional document (front)",
        "Verification_document_back": "Verification document (back)",
        "Additional_document_back": "Additional document (back)",
        "others": "Altro",
        "signedW9Form": "KB",
        "driverLicense": "Patente",
        "Verification_document_tooltip": "Acceptable forms for identification: \n  - Passport \n  - Driver license \n  - Resident permit ID \n  - Citizen Card \n  - ID card",
        "Additional_document_tootip": "Acceptable forms of address verification: \n  - Driver license \n  - Utility bill \n  - Government issued letter \n  - Statement from a financial institution \n  - Electoral Register entry",
        "isBankAccountOwner": "Is this your account?",
        "yes": "Yes",
        "no": "No",
        "beneficiary_name": "Beneficiary Name",
        "beneficiary_idic": "Beneficiary ID/IC",
        "relationship": "Relationship",
        "select_relationship": "Select relationship status",
        "spouse": "Spouse",
        "daughterOrSon": "Daughter/Son",
        "brotherOrSister": "Brother/Sister",
        "relative": "Relative",
        "friends": "Friends",
        "other": "Other",
        "relationshipOtherName": "Relationship with other?",
        "OPERATION": "OPERAZIONE",
        "CAR_INSURANCE": "Assicurazione macchina",
        "Policy_number": "Numero polizza",
        "Effective_date": "Data effettiva",
        "Expired_date": "Data di scadenza",
        "Select_company": "Seleziona azienda",
        "All_company": "All company",
        "All_Car_Type": "All Car Type",
        "Car": "Car",
        "Existing_car": "Macchina esistente",
        "New_car": "Nuova macchina",
        "License_plate": "Numero di targa",
        "Select_license_plate": "Seleziona numero di targa",
        "Select_Car_Type": "Seleziona Tipo di Macchina",
        "vehicleOwner": "Name of vehicle’s owner",
        "Make": "Crea",
        "Select_Car_Make": "Seleziona Un costruttore auto",
        "Model": "Modello",
        "Select_Car_Model": "Seleziona modello macchina",
        "Year": "Anno",
        "Select_Year": "Seleziona anno",
        "Color": "Colore",
        "Passengers": "Passeggeri",
        "Luggage": "Bagaglio",
        "License_expiry": "Patente scade",
        "Shift": "Turno",
        "Select_Shift_Template": "Seleziona Modello Turno",
        "Fleet_commission": "Commissione Flotta (report)",
        "Company_commission": "Company commission (report)",
        "rideHailing": "Street Hailing",
        "intercity": "Intercity",
        "food": "Food",
        "mart": "Mart",
        "streetSharing": "Street Sharing",
        "Shuttle": "Shuttle",
        "parcel": "Parcel",
        "driverInsurance": "Driver Insurance",
        "Percentage": "Percentuale",
        "Amount": "Montante",
        "transaction": "/transazione",
        "Terminal_ID": "Terminal ID",
        "Auth_key": "Auth key",
        "CREDIT_DEPOSIT": "Portafoglio di credito",
        "Credit_Balance": "Saldo ",
        "Balance": "Equilibrio",
        "Change_balance": "Cambia saldo",
        "Select_balance": "Select balance",
        "Adjust_amount": "Adjust amount",
        "Adjust_value": "Adjust value",
        "New_balance": "Nuovo saldo",
        "New_point": "New point",
        "Enter_reason": "Inserisci motivo",
        "ERROR_INPUT_BALANCE": "Per favore seleziona il saldo.",
        "ERROR_INPUT_AMOUNT": "Per favore inserisci valore.",
        "ERROR_INPUT_REASON": "Per favore inserisci motivo.",
        "ERROR_INPUT_VALID_AMOUNT": "Per favore inserisci un valore maggiore di 0.",
        "INFO_ADD_BALANCE_SUCCESS": "Il saldo dell'autista é stato aggiornato con successo",
        "INFO_ADD_CASH_BALANCE_SUCCESS": "Driver cash balance has been updated successfully",
        "Force_meter": "Force meter",
        "Force_meter_tooltip": "Driver app must be connected to the meter before operating. Applied for Pulsar & Centrodyne's meters",
        "ERROR_EXISTED_PHONE": "Questo telefono esiste già nel sistema. Per favore inseriscine un altro.",
        "TOP_driver": "TOP driver",
        "Ride_sharing": "Condividi-viaggio",
        "Note_password": "Nota: Per il conto del nuovo autista la password provvisoria é \"12345678\"",
        "Required_fields": "Campi obbligatori",
        "Cancel": "Cancella",
        "Save": "Salva",
        "SaveAndInProgress": "Save & In progress",
        "SaveAndDeactivate": "Save & Deactivate",
        "ERROR_REQUIRE_": "Per favore inserisci ",
        "ERROR_INPUT_AVATAR": "Please upload avatar.",
        "ERROR_INPUT_DRIVER_TYPE": "Please select driver type.",
        "ERROR_INPUT_DOCUMENT": "Please upload ",
        "ERROR_INPUT_USERNAME": "Per favore inserisci username.",
        "ERROR_INPUT_VALID_USERNAME": "Lo Username deve contenere solo lettere minuscole & numeri e deve essere lungo dai 3 ai 20 caratteri.",
        "ERROR_INPUT_PHONE": "Per favore specifica un numero di telefono valido.",
        "ERROR_INPUT_VALID_EMAIL": " Per favore inserisci email valida.",
        "ERROR_INPUT_EMAIL": " Please input email.",
        "ERROR_INPUT_FIRSTNAME": "Per favore inserisci nome..",
        "ERROR_INPUT_LASTNAME": "Per favore inserisci cognome .",
        "ERROR_INPUT_ADDRESS": "Per favore inserisci indirizzo di casa..",
        "ERROR_INPUT_CITY": "Per favore inserisci nome città.",
        "ERROR_INPUT_STATE": "Per favore inserisci provincia..",
        "ERROR_INPUT_ZIPCODE": "Per favore inserisci a codice postale",
        "ERROR_INPUT_BIRTHDAY": "Per favore inserisci data di nascita .",
        "ERROR_INPUT_NAME_OF_BANK": "Per favore iserisci il nome della banca..",
        "ERROR_INPUT_NAME_OF_ACCOUNT": "Per favore inserisci nome del conto.",
        "ERROR_INPUT_ROUTING_NUMBER": "Per favore inserisci nuero routing.",
        "ERROR_INPUT_BANK_NAME": "Please select a bank.",
        "ERROR_INPUT_ACCOUNT_NUMBER": "Per favore inserisci numero di conto",
        "ERROR_INPUT_CHECK_NUMBER": "Please input check number.",
        "ERROR_INPUT_SSN": "Please input SSN.",
        "ERROR_INPUT_DocumentId": "Please input Document ID.",
        "ERROR_INPUT_SORTCODE": "Please input sort code.",
        "ERROR_INPUT_COMPANY": "Per favore seleziona azienda da attivare .",
        "ERROR_INPUT_LICENSE_PLATE": "Per favore scegli targa.",
        "ERROR_INPUT_ZONE": "Per favore seleziona zona da attivare.",
        "ERROR_INPUT_SHIFT": "Per favore seleziona turno da attivare.",
        "ERROR_ACCOUNT_NUMBER_ONLY_NUMBERS": "Questo deve contenere solo numeri",
        "ERROR_SSN_ONLY_NUMBERS": "Questo deve contenere solo numeri",
        "ERROR_SSN_LENGTH": "Il codice fiscale deve avere almeno 4 caratteri",
        "ERROR_COMMISSION_NUMBERS": "Per favore inserisci un numero valido.",
        "INFO_DEACTIVAED_SUCCESS": "L'autista é stato disattivato con successo.",
        "INFO_ACTIVAED_SUCCESS": "L'autista é stato attivato con successo.",
        "INFO_DEACTIVAED_SUCCESS_BUT_HARDWARE": "L’autista é stato disattivato ma il suo contatore hardware collegato non puo’ essere spento per ragioni inaspettate",
        "INFO_MULTIDEACTIVAED_SUCCESS": "Gli autisti {0} selezionati sono stati disattivati con successo.",
        "INFO_MULTIDEACTIVAED_HARDWARE_SUCCESS": "TGli autisti {0} selezionati sono stati disattivati con successo.  {1} Il contatore(i) connesso é stato disabilitato.",
        "INFO_MULTIDEACTIVAED_SUCCESS_BUT_HARDWARE": "Gli [3] autisti selezionati sono stati disattivati con successo. [2] contatore(i) hardware collegato disabilitato. [1] non dovute per ragioni inaspettate",
        "INFO_MULTIACTIVAED_SUCCESS": "Gli autisti {0} selezionati sono stati attivati con successo.",
        "DRIVER_UPDATE_SUCCESS": "Autista aggiornato con successo",
        "DRIVER_CREATE_SUCCESS": "Le informazioni dell'autista sono state aggiunte con successo",
        "Expiry_Date": "Data di scadenza",
        "Case_number": "Numero caso",
        "Export_to_csv": "Export to CSV",
        "CONFIRM_EXPORT_TO_CSV": "Sei sicuro di voler esportare tutti i dati in formato .csv?",
        "Ride_sharing_tooltip": "Car pooling é una funzionalità avanzata, per favore contatta il nostro supporto per avere maggiori informazioni.",
        "In_Review": "In revisione",
        "Reset_password_success": "Reset password successful",
        "Topup_note": "(It is allowed to top up your driver's balance after activating the driver)",
        "cash_balance": "Saldo in contanti",
        "cash_wallet": "Portafoglio in contanti",
        "total_balance": "Total balance",
        "total_cash_balance": "saldo di cassa totale",
        "total_credit_balance": "saldo a credito totale",
        "banking_status": "Banking status",
        "bankingStatus": {
            "verified": "Verified",
            "not_verified": "Not verified"
        },
        "INVALID_FILE_SIZE": "The file size must be less than 8Mb.",
        "totalDrivers": "Total Driver",
        "profileStatus": "Profile status",
        "profile_completed": "Profile completed",
        "profile_incomplete": "Profile incomplete",
        "profile_inProgress": "Profile in progress",
        "in_review_completed": "In review (profile completed)",
        "in_review_incomplete": "In review (profile incomplete)",
        "in_review_inProgress": "In review (profile in progress)",
        "CASH_WALLET_DEPOSIT": "Cash wallet",
        "payoutSelect": "Payout (an inbox and email will be sent to the driver after payout)",
        "Confirm_update_cash_balance": "Change Cash Balance",
        "Confirm_update_cash_balance_msg": "Are you sure you want to change driver balance?",
        "cashBalance": "Cash balance",
        "cashBalance_unavailable": "0.00",
        "cashBalance_active": "Active",
        "cashBalance_negative": "Negative",
        "creditBalance": "Credit balance",
        "ERROR_INPUT_DOCUMETID": "Please input Document Id.",
        "ERROR_INPUT_EXPIRYDATE": "Please input Expiry Date.",
        "driverType": "Driver Type",
        "tooltip_in_review_completed": "Send inbox to 'In review' drivers who complete their profile.",
        "tooltip_in_review_incomplete": "Send inbox to 'In review' drivers who haven't completed their profile.",
        "tooltip_in_review_inProgress": "Send inbox to 'In review' drivers who complete their profile but need extra verification.",
        "tooltip_active": "Send inbox to 'Active' drivers.",
        "tooltip_inactive": "Send inbox to drivers who have account reactivated.",
        "active": "Activate",
        "inactive": "Deactivate",
        "inReviewInProgress": "In Review",
        "Market_place": "Marketplace",
        "Market_place_tooltip": "Empower drivers to choose on-demand or reservations bookings to accept",
        "selectDriverType": "Select driver type",
        "PlsSelectDriverType": "Please select driver type",
        "emergencyContacts": "Emergency Contacts",
        "switchVehicle": "Switch to any vehicle",
        "switchVehicleNote": "Allow driver to switch to any vehicle of their assigned supplier company.",
        "vehicleModel": "Model",
        "licensePlateNumber": "Vehicle Plate",
        "addVehicle": "Add Vehicle",
        "newVehicle": "New Vehicle",
        "linkVehicle": "Link Vehicle",
        "modal": "Modal",
        "selectVehicle": "Select Vehicle",
        "selectVehicleNote": "Enable driver to select or change vehicle for a booking.",
        "Warning_change_company": "Warning: Reassigning this driver to another company will unlink all associated vehicles."
    },
    "corporate": {
        "User_Name": "User Name",
        "Edit_Balance": "Edit Balance",
        "Corporates": "Aziende",
        "Supplier": "Supplier",
        "CarTypes": "Car Types",
        "Add": "Aggiungi",
        "Balance": "Balance",
        "Activate": "Attivato",
        "Deactivate": "Disattivato",
        "Delete": "Cancella",
        "Company_Name": "Nome dell’azienda",
        "Admin_Name": "Nome Admin",
        "Username": "Username",
        "Phone_Number": "Numero di telefono",
        "importBooking": "Able to import bookings",
        "Email": "Email",
        "Registered_From": "Registrato da",
        "Registration_Date": "Data di registrazione",
        "Status": "Stato",
        "Actions": "Azioni",
        "Action": "Azioni",
        "Inactive": "Inattivo",
        "Active": "Attivo",
        "In_Review": "In revisione",
        "Edit": "Modifica",
        "View": "Mostra",
        "ADD_CORPORATE_CARD": "AGGIUNGI CARTA AZIENDALE",
        "selectCountry": "Seleziona Paese",
        "CONFIRM_DELETE_CARD": "Sei sicuro di voler cancellare queste carte?",
        "ALERT_SELECT_ACTIVATE": "Per favore seleziona azienda da attivare.",
        "ALERT_SELECT_DEACTIVATE": "Per favore seleziona azienda da disattivare.",
        "ALERT_SELECT_DELETE": "Per favore seleziona azienda da eliminare.",
        "CONFIRM_DELETE": "Vuoi eliminare questa azienda?",
        "CONFIRM_DELETE_USER": "Vuoi cancellare questo oggetto?",
        "CONFIRM_DEACTIVATE_MULTI": "Vuoi disattivare questi oggetti?",
        "CONFIRM_ACTIVATE_MULTI": "Vuoi attivare questi oggetti?",
        "CONFIRM_DELETE_MULTI": "DVuoi eliminare queste aziende?\n ** Solo le aziende inattive possono essere eliminate.",
        "ERROR_INPUT_PHONE": "Per favore specifica un numero di telefono valido.",
        "ERROR_INPUT_VALIDPHONE": "Per favore inserisci un numero di telefono valido.",
        "ERROR_EXISTED_PHONE": "Questo telefono esiste già nel sistema. Per favore inseriscine un altro.",
        "Account": "Conto",
        "Corp_traveler": "Viaggiatore aziendale",
        "Payment_methods": "Mezzi di pagamento",
        "Corp_operators": "Operatori",
        "Pricing": "Pricing",
        "COMPANY_INFORMATION": "INFORMAZIONI AZIENDA",
        "Company_name": "Nome dell’azienda",
        "Company_ID": "Codice Univoco",
        "Company_address": "Indirizzo azienda",
        "Invoice_email": "email fattura",
        "Confirmation_email": "Conferma email",
        "colorCodeBooking": "Color code",
        "ADMIN_ACCOUNT": "CONTO ADMIN",
        "First_name": "Nome",
        "Last_name": "Cognome",
        "Commission": "Commissione",
        "commissionBooking": "Apply commission for bookings from app/pwa",
        "Required_fields": "Campi obbligatori",
        "Save": "Registra",
        "Cancel": "Cancella",
        "Reset_password": "Resetta password",
        "Employee_Name": "Nome impiegato",
        "Created_Date": "Data creata",
        "Add_corporate_card": "Aggiungi carta aziendale",
        "Corp_Users": "Corp Users",
        "Pricing_Default": "Default (same pricing plan as normal pax)",
        "Pricing_Discount": "Discount by percentage (applied for Corp Users only)",
        "Markup_By_Percent": "Markup in percentuale (applicato solo per viaggiatore viaggiatore)",
        "Pricing_Add_different_rates": "Add different rates",
        "ERROR_INPUT_COMPANYNAME": "Per favore inserisci nome dell’azienda",
        "ERROR_INPUT_USERNAME": "Per favore inserisci username.",
        "ERROR_INPUT_FIRSTNAME": "Per favore inserisci nome.",
        "ERROR_INPUT_EMAIL": "Per favore inserisci email",
        "ERROR_INPUT_VALID_EMAIL": "Per favore inserisci una email valida.",
        "ERROR_INPUT_VALID_NUMBER": "Per favore inserisci un numero valido.",
        "ERROR_INPUT_VALID_RANGE_AMOUNT": "Per favore inserisci un numero fra {0} e {1}.",
        "INFO_ADD_CORPORATE_SUCCESS": "Utente {0} registrato con successo. Verifica l'indirizzo email {1} per le credenziali di accesso.",
        "INFO_UPDATE_CORPORATE_SUCCESS": "The information has been updated successfully.",
        "INFO_UPDATE_PRICING_SUCCESS": "I prezzi sono stati aggiornati con successo.",
        "INFO_RESET_PASSWORD_SUCCESS": "Account {0} has been reset successfully. Please check the email {1} for login credentials details.",
        "COMFIRM_RESET_PASSWORD": "Vuoi resettare la password?",
        "Traveler_signature": "Traveler signature",
        "Parcel_Delivery": "Enable parcel delivery",
        "Tracking_log": "Tracking log",
        "Rating": "Valutazione",
        "Email_address": "Indirizzo email",
        "Assistant_email": "Assistente email",
        "Corp_PO": "Altro",
        "Cost_centre": "Centro costi",
        "Corp_division": "Corp division",
        "Corp_id": "Corp id",
        "Department": "Department",
        "Manager_email": "Codice Univoco ",
        "Manager_name": " P.IVA/Cod.Fisc",
        "Corporate_card": "Carta Aziendale",
        "Direct_invoicing": "Fatturazione diretta",
        "Corporate_Prepaid": "Prepagato dall’azienda",
        "Cash": "Contanti",
        "Car_Type_ID": "Car Type ID",
        "App_Display_Name": "App Display Name",
        "Normal_Fare": "Regolare",
        "Flat_Fare": "Tasso fisso",
        "Hourly_Daily_Rate": "Hourly/ Daily",
        "Delivery_Rate": "Delivery",
        "Assign_rate": "Assegna tariffa",
        "Zone": "Zona",
        "Rate": "Valuta",
        "Zone_Rate": "Zone - Rate",
        "Please_select_rate": "Per favore seleziona una data",
        "Paid_by": "Pagato da",
        "Enter_new_credit_card": " Inserisci nuova carta di credito ",
        "TOP_UP": "RICARICA",
        "Top_up": "Ricarica",
        "Topup_amount": "Ricarica importo",
        "Currency": "Moneta",
        "ERROR_INPUT_AMOUNT": "Per favore inserisci un importo valido.",
        "VAT_number": "partita IVA",
        "Gender": "Genere",
        "Select_Gender": "Seleziona",
        "Male": "Maschio",
        "Female": "Femmina",
        "Date_of_birth": "Data di Nascita",
        "Street_address": "Indirizzo",
        "City": "Città",
        "State": "Stato",
        "Zip_code": "Cap",
        "Country": "Paese",
        "adding_new_card": "Stai aggiungendo una nuova carta per usare il servizio in %{Nomezona} area",
        "No_Support": "L'aggiunta di una nuova vettura non é supportata in quest'area",
        "Job_title": "Posizione",
        "required_phone_number_payment": "The phone number of corporate are required. Please update profile to continue.",
        "Yes": "Si",
        "No": "No",
        "AddCardBeforeTopup": "No credit card was found. Please add a new card to continue.",
        "flightNumber": "Flight number",
        "Company_Type": "Company type",
        "Standard": "Standard corporate",
        "Airline": "Airline",
        "Branding": "Branding",
        "bgImage": "Background image (1600x900)",
        "logoImage": "Logo image",
        "aliasTitle": "Alias (URL) parameter",
        "importChauffeurBooking": "May kakayahang mag-import ng mga booking sa transportasyon",
        "isCorporateVip": "Corporate VIP",
        "isCorporateVipToolTip": "Enable this setting to automatically assign VIP status to all corporate travelers upon creation, you may turn it off for each individual user.",
        "qrTitle": "QR Web booking link",
        "qrNote": "Your personalized partner booking link is here! Display it prominently in bars, hotel receptions, guest rooms, and on your website—anywhere your customers might need a ride or delivery. Consider designing standees with your unique QR code, allowing customers a quick and easy scan to book their journey!"
    },
    "airline": {
        "Airlines": "Arilines",
        "Add": "Add",
        "Activate": "Activate",
        "Deactivate": "Deactivate",
        "Delete": "Delete",
        "Company_Name": "Company Name",
        "Admin_Name": "Admin Name",
        "Username": "Username",
        "Phone_Number": "Phone Number",
        "Email": "Email",
        "Registered_From": "Registered From",
        "Registration_Date": "Registration Date",
        "Status": "Status",
        "Actions": "Actions",
        "Action": "Action",
        "Inactive": "Inactive",
        "Active": "Active",
        "In_Review": "In Review",
        "Edit": "Edit",
        "View": "View",
        "ADD_CORPORATE_CARD": "Add Airline card",
        "selectCountry": "Select country",
        "CONFIRM_DELETE_CARD": "Are you sure you want to delete this cards?",
        "ALERT_SELECT_ACTIVATE": "Please select Airline to activate.",
        "ALERT_SELECT_DEACTIVATE": "Please select Airline to deactivate.",
        "ALERT_SELECT_DELETE": "Please select Airline to delete.",
        "CONFIRM_DELETE": "Do you want to delete this Airline?",
        "CONFIRM_DELETE_USER": "Do you want to delete this Airline user?",
        "CONFIRM_DEACTIVATE_MULTI": "Are you sure want to deactivate these company?",
        "CONFIRM_ACTIVATE_MULTI": "Are you sure you want to active this company?",
        "CONFIRM_DELETE_MULTI": "Are you sure you want to delete this company?\n ** Only company who are inactive can be deleted.",
        "ERROR_INPUT_PHONE": "Please specify a valid phone number.",
        "ERROR_INPUT_VALIDPHONE": "Please enter a valid phone number.",
        "ERROR_EXISTED_PHONE": "This phone has been existing on the system. Please input another one",
        "Airline": "Airline",
        "Crew": "Crew",
        "Payment_methods": "Payment methods",
        "Airline_operators": "Airline operators",
        "Pricing": "Pricing",
        "COMPANY_INFORMATION": "Company information",
        "Company_name": "Company name",
        "Company_ID": "Company ID",
        "Company_address": "Company address",
        "Invoice_email": "Invoice email",
        "Confirmation_email": "Confirmation email",
        "colorCodeBooking": "Color code",
        "ADMIN_ACCOUNT": "Admin account",
        "First_name": "First name",
        "Last_name": "Last name",
        "Commission": "Commission",
        "Required_fields": "Required fields",
        "Required_fields_crews": "Note: For new Crew account, the default password is \"12345678\"",
        "Save": "Save",
        "Cancel": "Cancel",
        "Reset_password": "Reset password",
        "Crew_Name": "Crew",
        "Created_Date": "\tCreated Date",
        "Add_corporate_card": "Add airline card",
        "Corp_Users": "Operators",
        "Pricing_Default": "Default",
        "Pricing_Discount": "Discount by percentage",
        "Markup_By_Percent": "Mark-up by percentage",
        "Pricing_Add_different_rates": "Add different rates",
        "ERROR_INPUT_COMPANYNAME": "Please input company name.",
        "ERROR_INPUT_USERNAME": "Please input username.",
        "ERROR_INPUT_FIRSTNAME": "Please input first name.",
        "ERROR_INPUT_EMAIL": "Please input email.",
        "ERROR_INPUT_VALID_EMAIL": "Please enter a valid email address.",
        "ERROR_INPUT_VALID_NUMBER": "Please enter valid number",
        "ERROR_INPUT_VALID_RANGE_AMOUNT": "Please enter a value between {0} and {1}.",
        "INFO_ADD_CORPORATE_SUCCESS": "Account {0} has been created successfully.",
        "INFO_UPDATE_CORPORATE_SUCCESS": "The information has been updated successfully.",
        "INFO_UPDATE_PRICING_SUCCESS": "Pricing updated successfully",
        "INFO_RESET_PASSWORD_SUCCESS": "Account {0} has been reset successfully.",
        "COMFIRM_RESET_PASSWORD": "Do you want to reset password?",
        "Traveler_signature": "Traveler signature",
        "Tracking_log": "Tracking log",
        "Rating": "Rating",
        "Email_address": "Email address",
        "Assistant_email": "Assistant email",
        "Corp_PO": "Corp PO",
        "Cost_centre": "Cost centre",
        "Corp_division": "Corp division",
        "Corp_id": "Corp id",
        "Department": "Department",
        "Manager_email": "Manager's email",
        "Manager_name": "Manager's name",
        "Corporate_card": "Airline card",
        "Direct_invoicing": "Direct invoicing",
        "Corporate_Prepaid": "Airline Prepaid",
        "Cash": "Cash",
        "Car_Type_ID": "Car Type ID",
        "App_Display_Name": "App Display Name",
        "Normal_Fare": "Regular",
        "Flat_Fare": "Flat Rate",
        "Hourly_Daily_Rate": "Hourly/ Daily",
        "Delivery_Rate": "Delivery",
        "Assign_rate": "Assign rate",
        "Zone": "Zone",
        "Rate": "Rate",
        "Zone_Rate": "Zone - Rate",
        "Please_select_rate": "Please select rate",
        "Paid_by": "Paid by",
        "Enter_new_credit_card": " Enter new credit card ",
        "TOP_UP": "TOP UP",
        "Top_up": "Top up",
        "Topup_amount": "Topup amount",
        "Currency": "Currency",
        "ERROR_INPUT_AMOUNT": "Please input valid amount.",
        "VAT_number": "VAT number",
        "Gender": "Gender",
        "Select_Gender": "Select",
        "Male": "Male",
        "Female": "Female",
        "Date_of_birth": "Date of birth",
        "Street_address": "Street address",
        "City": "City",
        "State": "State",
        "Zip_code": "Zip code",
        "Country": "Country",
        "adding_new_card": "You are adding a new card to use service in %{zoneName} area",
        "No_Support": "New card adding is currently not supported in this area",
        "Job_title": "Job title",
        "required_phone_number_payment": "The phone number of corporate are required. Please update profile to continue.",
        "Yes": "Yes",
        "No": "No",
        "AddCardBeforeTopup": "No credit card was found. Please add a new card to continue."
    },
    "message": {
        "Add": "Aggiungi",
        "Delete": "Cancella",
        "Items_per_page": "Oggetti per pagina",
        "Page": "Pagina",
        "Search_here": "Cerca qui",
        "Shown_Active": "{0} - {1} of {2} mostrati",
        "Date": "Data",
        "Subject": "Soggetto",
        "To": "A",
        "Type": "genere",
        "Result": "Risultato",
        "Actions": "Azioni",
        "New_message": "Nuovo Messaggio",
        "MESSAGE_DETAILS": "DETTAGLI MESSAGGIO",
        "All": "Tutto",
        "Company": "Azienda",
        "Active": "Attivo",
        "In_Active": "Inattivo",
        "In_Review": "In revisione",
        "Send_to": "Send to",
        "All_drivers": "Tutti gli autisti",
        "All_customers": "All customers",
        "All_merchants": "All merchants",
        "Customized_driver": "Customized driver",
        "Customized_customer": "Customized customer",
        "Customized_merchant": "Customized merchant",
        "Receiver_list": "Lista Ricevente",
        "Content": "Contenuto",
        "Required_fields": "Campi obbligatori",
        "Search_driver_name_driver_phone_number": "Cerca nome autista, numero di telefono autista",
        "Search_category_name": "Search category name",
        "Search_customer_name_customer_phone_number": "Cerca nome cliente, numero di telefono  cliente",
        "Search_merchant_name_merchant_phone_number": "Search merchant name, merchant phone number",
        "Search_car_type": "Cerca tipo vettura",
        "Searching": "In ricerca...",
        "No_results": "risultati",
        "Send": "Invia",
        "Cancel": "Cancella",
        "SMS_failed_Inbox_succeeded": "SMS fallito; In arrivo riuscito",
        "Failed": "Fallito",
        "Succeeded": "Riuscito",
        "CONFIRM_DELETE": "Vuoi cancellare questo messaggio?",
        "CONFIRM_DELETE_MULTI": "Vuoi cancellare questi messaggi?",
        "ALERT_SELECT_DELETE": "Per favore seleziona messaggio da cancellare",
        "ERROR_INPUT_RECEIVERLIST": "Per favore scegli almeno un autista per l’invio del messaggio",
        "ERROR_INPUT_CUSTOMER_RECEIVERLIST": "Per favore scegli almeno un clienti per l’invio del messaggio",
        "ERROR_INPUT_SUBJECT": "Per favore inserisci soggetto",
        "ERROR_INPUT_CONTENT": "Per favore inserisci contenuto",
        "ERROR_INPUT_LEAST_ONE_SUBJECT_CONTENT": "Please set up at least one language to continue",
        "ERROR_INPUT_SUBJECT_MULTI_LANGUAGE": "Please input subject for {0} language",
        "ERROR_INPUT_CONTENT_MULTI_LANGUAGE": "Please input content for {0} language",
        "ERROR_INPUT_URL_MULTI_LANGUAGE": "Please input URL for {0} language",
        "ERROR_INPUT_TITLE_MULTI_LANGUAGE": "Please input title for {0} language",
        "ERROR_INPUT_BODY_MULTI_LANGUAGE": "Please input body for {0} language",
        "import_fail_success_driver": "%{success} driver(s) has been imported successfully, %{fail} failed.",
        "import_fail_success_passenger": "%{success} customer(s) has been imported successfully, %{fail} failed.",
        "import_fail_success_merchant": "%{success} merchant(s) has been imported successfully, %{fail} failed.",
        "CONFIRM_SEND_SUCCESS": "Il tuo messaggio é stato inviato. Questo prenderà qualche minuto. Per favore attendi mentre il sistema processa la tua richiesta.",
        "Load_message_and_sms_fail": "Sono intervenuti degli errori nella fase di caricamento messaggio e dettagli SMS",
        "Update_message_and_sms_fail": "Sono intervenuti degli errori nella fase di aggiornamento messaggio e dettagli SMS",
        "Update_message_and_sms_success": "Messaggio e dettagli SMS sono stati aggiornati con successo",
        "Driver_List": "Lista autista",
        "Car_Type_List": "Lista tipo di vettura",
        "Search_vehicle_type_name": "Cerca Nome Tipo Veicolo",
        "ALERT_PROCESSING_DELETE": "La tua richiesta viene processata. Questo puo' prendere qualche secondo",
        "Customer": "Clienti",
        "Driver": "Autista",
        "StatusOfCustomer": "Status of customer",
        "StatusOfDriver": "Status of driver",
        "StatusOfMerchant": "Status of merchant",
        "SendTo": "Send to",
        "DownloadReceiverList": "Download receiver list",
        "warningInputChangeOver": "C'è una variazione relativa di oltre il 20% tra i 2 input recenti. Si prega di prendere in considerazione!",
        "NoteDownloadReveverList": "Dopo 1 mese, il download dell'elenco dei destinatari verrà disabilitato.",
        "Load_voip_fail": "The errors occurred while loading VOIP details.",
        "Update_voip_success": "VOIP details has been updated successfully.",
        "Update_voip_fail": "The errors occurred while updating VOIP details.",
        "expiryDate": "Send to new user until",
        "expiryDate_placeholder": "Expiry Date (mm/dd/yy)",
        "NA": "N/A",
        "PleaseSelectStatusOfDriver": "Please select status of driver",
        "versionApp": "App Version",
        "platform": "Platform",
        "contentType": "Content type",
        "url": "URL",
        "title": "Title",
        "contentId": "ContentId",
        "body": "Body",
        "image_notification": "Image",
        "platformItem": {
            "all": "All",
            "iOS": "iOS",
            "android": "Android"
        },
        "comparisonVersionApp": {
            "is": "Is",
            "is_not": "Is not",
            "is_one_of": "Is one of",
            "is_not_one_of": "Is not one of",
            "greater_or_equal": "Greater or equal",
            "less_or_equal": "Less or equal"
        },
        "contentTypeItem": {
            "html": "HTML",
            "link": "Link"
        },
        "Please_input": "This field is required"
    },
    "fleetInfo": {
        "Fleet_name": "Nome flotta",
        "Phone_number": "Numero di telefono",
        "Email_address": "Indirizzo email",
        "Country": "Paese",
        "Time_zone": "Fuso orario",
        "Address": "Indirizzo",
        "Website": "Sito web",
        "Currency": "Moneta",
        "Distance_unit": "Unità distanza",
        "Load_fleet_info_fail": "Sono intervenuti degli errori nella fase di caricamento delle informazioni della flotta"
    },
    "generalSetting": {
        "alertNotifications": {
            "title": "Alert notifications",
            "emails": "Emails",
            "emailsPlaceholder": "Enter email",
            "slack": "Slack Channel (Instructions)",
            "slackPlaceholder": "Slack POST URL",
            "discord": "Discord Channel (Instructions)",
            "discordPlaceholder": "Enter Discord Channel",
            "telegram": "Telegram Channel (Instructions)",
            "telegramPlaceholder": "Enter Telegram Channel"
        },
        "showFare": {
            "title": "Show fare to driver",
            "totalFare": "Total fare",
            "estimateGross": "Estimate gross earning"
        },
        "carHailing": "Street hailing",
        "regular": "Regular",
        "cancelTimeout": "Cancel the timeout bookings automatically",
        "allowStripePaymentLink": "Allow generate Stripe payment link",
        "flat": "Flat",
        "techFee": "Tech fee per transaction",
        "customizeLogo": "Customize dashboard logo",
        "logo": "Logo",
        "customerApp": "Customer App",
        "hourly": "Hourly",
        "shuttle": "Shuttle",
        "parcel": "Parcel",
        "freeCancelTime": "Free cancellation times per day",
        "AM": "AM",
        "PM": "PM",
        "RUSH_HOUR": "Sovrapprezzo",
        "Name": "Nome",
        "Date": "Data",
        "Mon": "Lu",
        "Tue": "Ma",
        "Wed": "Me",
        "Thu": "Gio",
        "Fri": "Ve",
        "Sat": "Sa",
        "Sun": "Dom",
        "Time_range": "Variazioni nel tempo",
        "From": "Da",
        "To": "A",
        "Rush_hour_time_calculation": "Surcharge time calculation",
        "Rush_hour_surcharge": "Sovrapprezzo",
        "Required_fields": "Campi obbligatori",
        "Save": "Salva",
        "Cancel": "Cancella",
        "MDISPATCHER_CANCELLATION": "CANCELLAZIONE PASSEGGERO/ MDISPATCHER/ WEB BOOKING ",
        "Book_now": "Book now",
        "MDISPATCHER_CANCELLATION_TOOLTIP": "Pax / Partner / Web booking cancellation",
        "Book_now_toolTip": "La tassa di cancellazione è applicabile se le prenotazioni su richiesta vengono cancellate entro",
        "Book_later_toolTip": "La penale di cancellazione è applicabile se una prenotazione viene cancellata entro prima dell'orario di ritiro",
        "Drv_Book_now_toolTip": "Cancellation fee is applicable if on-demand bookings get canceled within set time, from accept time",
        "Drv_Book_later_toolTip": "Cancellation fee is applicable if in advance bookings get canceled within set time, before expected pick-up time",
        "Free_cancel": "Free cancel duration",
        "Hide_cancel_btn": "Hide cancel button after set times",
        "Driver_cancellation": "Driver cancellation",
        "Time_day": "Times per day",
        "Duration": "Durata",
        "Times": "Orari",
        "Book_later": "Book later",
        "Intercity": "Intercity",
        "Intercity_tooltip": "Cancellation fee is applicable if an intercity booking gets canceled within the set time, before pickup time.",
        "MDISPATCHER_NO_SHOW": "ASSENTE",
        "AIRPORT_FEE": "TARIFFA AEROPORTO",
        "From_airport": "Dall’aereoporto",
        "To_airport": "All’aereoporto",
        "MEET_DRIVER": "INCONTRA L’AUTISTA",
        "On_curb": "Trattenuto",
        "Meet_Greet": "Incontra & Saluta",
        "Add": "Aggiungi",
        "Edit": "Modifica",
        "DELETE_SURCHARGE": "Delete surcharge",
        "DELETE_SERVICE": "Elimina servizio",
        "CHANGE_APPLY_SERVICE_FEES": "Change apply service fees",
        "DELETE_CONFIRM": "Sei sicuro di voler eliminare questi oggetti?",
        "DELETE_SURCHARGE_CONFIRM": "Are you sure you want to delete the selected surcharge?",
        "DELETE_ADDITIONAL_SERVICES_CONFIRM": "Are you sure you want to delete the selected service?",
        "CHANGE_APPLY_SERVICE_FEES_CONFIRM_CUSTOM": "Sei certo di applicare tariffe diverse per le zone separati?",
        "CHANGE_APPLY_SERVICE_FEES_CONFIRM_ALL": "Sei sicuro di voler applicare le stesse commissioni per tutte le zone?",
        "EDIT_SERVICE_FEES": "Edit service fees",
        "EDIT_SERVICE_FEES_CONFIRM": "Sei sicuro di volere lasciare senza salvare le impostazioni per %{currentZone}?",
        "HEAVY_TRAFFIC": "TRAFFICO INTENSO",
        "Heavy_traffic_surcharge": "Sovrapprezzo traffico intenso",
        "TOLL_FEE": "Toll fee",
        "parkingFee": "Parking fee",
        "gasFee": "Gas fee",
        "OTHER_FEES": "ALTRE SPESE",
        "Can_edit": "capace di modifica",
        "Can_add_note": "capace di aggiungere note ",
        "Other_fees_amount": "Importo altre spese",
        "TAX": "TASSE",
        "Tax_surcharge": "Sovraccarico Tassa",
        "TIP": "MANCIA",
        "Default_tip": "Mancia prestabilita",
        "RESERVATION_REMINDER": "Promemoria prenotazione",
        "Driver_app_SMS_alert_before": "Driver app - avverti prima",
        "Pax_app_alert_before": "Pax - app avverti prima",
        "Confirm_driver_info_via_email_before": "Send email / sms booking confirmation to customer before",
        "Confirm_driver_info_via_email_before_hint": "The system will send out a booking confirmation to the customer based on your set time, before the pickup time.",
        "Show_request_tab_on_driver_app": "Show 'Request' tab on driver app",
        "Show_preferred_tab_on_driver_app": "Show 'Preferred' tab on driver app",
        "Sound_notification": "Sound notification",
        "Advance_information": "Advance information corporate users",
        "Load_general_fail": "The errors occurred while loading general setting",
        "Update_general_success": "Le impostazioni generali sono state aggiornate con successo",
        "Update_general_fail": "Sono intervenuti degli errori nella fase di caricamento delle impostazioni generali",
        "Time_over_lap": "Le fasce orarie non devono sovrapporsi",
        "Allow_passenger_to_register_as_corp_traveler": "Consenti al passeggero di registrarsi come viaggiatore aziendale",
        "Not_Allow_Make_Booking_Without_Assign_Fare": "Non consentire al passeggero di effettuare una prenotazione se la tariffa base é = 0.00",
        "Not_Allow_show_car_type_on_cc": "Do not show car type on CC if no fare assign",
        "Disable_Direct_Invoicing_Receipt_Email": "Disabilita ricevuta fatturazione diretta",
        "Additional_services": "Modifica servizi aggiuntivi",
        "Edit_additional_services": "Modifica servizi aggiuntivi",
        "Service_Fee_By_Currencies": "Spese servizio",
        "Service_type": "Tipo di servizio",
        "Optional": "Opzionale",
        "Compulsory": "Compulsory",
        "Vehicle_type": "Tipo veicolo/identità",
        "Adding_additional_service_success": "Add service successfully",
        "Update_additional_service_success": "Servizio aggiornato con successo",
        "Delete_additional_service_success": "The service has been deleted successfully",
        "Adding_additional_service_fail": "Impossibile aggiungere servizio ",
        "Update_additional_service_fail": "Impossibile aggiornare servizio",
        "Other_settings": "Other settings",
        "Operator_assign_error": "Sono intervenuti degli errori nella fase di assegnazione dell'operatore",
        "Create_surcharge_success": "Sovrapprezzo creato con successo",
        "Update_surcharge_success": "Sovrapprezzo aggiornato con successo",
        "Delete_surcharge_success": "Sovrapprezzo eliminato con successo",
        "Create_surcharge_fail": "Impossibile creare sovrapprezzo ",
        "Update_surcharge_fail": "Impossibile aggiornare sovrapprezzo ",
        "Delete_surcharge_fail": "Impossibile eliminare sovrapprezzo",
        "Get_surcharge_detail_fail": "Impossibile ottenere dettagli sovrapprezzo ",
        "Repeat": "Ripeti",
        "DayOfWeek": "Giorno della settimana",
        "SingleDay": "Giorno singolo",
        "Yearly": "Annuale",
        "Backup_phone_number": "Backup numero di telefono",
        "Backup_phone_number_hint": "Dirigi su questo numero quando autista e passeggero sono fuori dalle zone operazionali",
        "Apply_countdown_clock": "Applica conto alla rovescia",
        "No_show_duration_tooltip": "Imposta tempo di display del bottone Non presentato dopo che l'autista abbia selezionato il bottone Arrivato sull'app autista",
        "Limit_radius": "Raggio limite",
        "Limit_radius_tooltip": "Il conducente può segnalare una prenotazione come mancata presentazione entro questo raggio",
        "Limit_radius_arrive_pickup": "Limit radius driver can arrive & pickup for transport booking",
        "Booking_validation": "Booking validations",
        "Invalid_period_of_time": "Periodo di tempo invalido",
        "hide_Provider_Filter_From_Corporate_Board": "Hide provider filter from corporate board",
        "customer_email_is_optional": "L'email del passeggero é opzionale sulla app passeggero",
        "disable_hourly_rate": "Disable hourly rate when both hourly and flat rate are available",
        "customer_gender_is_compulsory": "Customer's gender is compulsory on passenger app",
        "SHOW_MAP_STATUS": "Show map status",
        "Pay_to": "Pay to",
        "EMERGENCY_SOS": "Emergency S.O.S",
        "customer_address_is_compulsory": "Customer's address is compulsory on passenger app",
        "customer_id_is_compulsory": "ID # / Nazionale IC # documento del cliente è obbligatoria su Pax app",
        "allowPassengerToUploadDocument": "Lasciare passeggero per caricare ID # / Nazionale IC # documento",
        "compulsoryNationalIcDocumentInPaxApp": "ID # / Nazionale IC # documento del cliente è obbligatoria su Pax app",
        "doNotAllowAddDriverWhenNationalIdDuplicated": "Do not allow operator to add driver when national ID is duplicated",
        "forceCustomerToUseLiveLocation": "Force customer to use Live Location",
        "disableMarketPlace": "Disable Market Place for new drivers",
        "dontShowSecondStageImmediately": "Hide stage 2 signup",
        "compulsoryCarInsurance": "Driver's car insurance is compulsory",
        "marketPlace": "Market Place",
        "Enable": "Enable",
        "Disable": "Disable",
        "showTotalFareWithoutPromotionOnDriverApp": "Show total fare without promotion on Driver app",
        "showTotalFareWithoutPromotionOnDriverApp_hint": "- Transport: apply this setting before driver makes payment. <br/> - Delivery & Intercity: apply this setting before driver start job.",
        "hideFareOnPaxAppForAllCorporateBookings": "Nascondi la tariffa sull'app Pax per tutte le prenotazioni aziendali",
        "hidePaxNameAvatarOnDispatchScreen": "Hide customer's name and avatar on dispatching screen",
        "showFullCustomerNameOnDriverApp": "Show full customer name on Driver app",
        "hideDriverLogoutBtn": "Hide Driver logout button on Driver app",
        "showMeetAndGreetButtonForEveryBooking": "Show Meet & Greet button for every booking",
        "settings": "impostazioni",
        "Default_value": "Default value",
        "limitTopupAmount": {
            "valueByCurrencies": "Warning amount",
            "minimumByCurrencies": "Minimum top up amount",
            "maximumByCurrencies": "Maximum top up amount",
            "title": "Minimum and Maximum Top up amount"
        },
        "driverApp": "Driver App",
        "passengerApp": "Passenger App",
        "Default_Topup_Values": "Valori di ricarica predefiniti",
        "service_fees": "Il costo del servizio",
        "commission_fleet": "Default Fleet Commission",
        "apply_all": "Lo stesso per tutte le zone operative",
        "apply_custom_zone": "Separati in ciascuna zona operativa",
        "apply": "Apply",
        "tollFeeDriverCanInput": "quantità di immissione conducente può",
        "unlimited": "Unlimited",
        "limited": "Limited",
        "sameFee": "stessa tassa",
        "customizedAirportfee": "tassa aeroportuale su misura",
        "noteAirportFeeModal": "Nota: qualsiasi aeroporto non nella lista non verrà applicata l'aeroporto a pagamento.",
        "intercity_schedule_trip_time_range": "To ensure the chronological order of booking time, please make sure the time-range of preferred/request trips is larger than the schedule trips",
        "airportFee": {
            "tooltipApply": "tassa aeroportuale di default sarà applicata per ogni viaggio da / per l'aeroporto di default. Se tassa aeroporto è personalizzato quindi sarà applicata nuova tassa per quel posto.",
            "titleSearchAiportZone": "Si prega di cercare l'aeroporto e aggiungere il canone",
            "airport": "Airport",
            "currency": "Currency",
            "fromAirportFee": "From airport fee",
            "toAirportFee": "To airport fee",
            "action": "Actions"
        },
        "Yes": "Si",
        "No": "No",
        "tipPaymentMethod": "Payment methods available for customer to tip his/her driver after the ride",
        "tipDefaultValues": "Suggerimento predefinito dopo la corsa",
        "creadit": "Credit card (Personal card / Corporate card)",
        "wallet": "Wallet",
        "tnGeWallet": "TnG eWallet",
        "vippseWallet": "Vipps",
        "DurationRegularBooking": "Duration (for regular bookings)",
        "googlePay": "Google Pay",
        "applePay": "Apple Pay",
        "DurationRegularBookingToolTip": "\"No show\" button will be displayed in after the driver taps on \"Arrived\" button on Driver app.",
        "noShowCountDown": {
            "customizedNoShow": "Personalizzazione per località particolari (ad es. Aeroporto ...)",
            "customizedNoShowTitle": "Applica la durata del conto alla rovescia per mancata presentazione per determinate località (ad es. Aeroporto ...)"
        },
        "maxWaitTime": "Max extra wait time",
        "maxWaitTimeToolTip": "This is the maximum extra waiting time that will be charged to the customer. You have the flexibility to customize the per-minute fee in your rate settings.",
        "maxWaitTimePlaceholder": "Maximum charge wait time",
        "HEAT_MAP": "Mappa della domanda",
        "heatMap": {
            "defaultMode": "Default mode",
            "radius": "Raggio",
            "intensity": "Intensità",
            "opacity": "Opacità",
            "requestedBookings": "Requested bookings",
            "activePassengers": "Active passengers",
            "completedBookings": "Completed bookings",
            "timeoutBookings": "Time out bookings",
            "tooltipRadius": "The radius of influence for each data point, in pixels.",
            "tooltipIntensity": "The maximum intensity of the heatmap.",
            "tooltipOpacity": "The opacity of the heatmap, expressed as a number between 0 and 1."
        },
        "schedule_trip": "Schedule trip",
        "driver_starts_trip_before_set_time": "Driver starts trip before set time",
        "request_trip": "Request trip",
        "preferred_trip": "Preferred trip",
        "time_range": "Time range for request and preferred trip",
        "working_time": "Working time",
        "hour": "hour",
        "hours": "hours",
        "minutes": "minutes",
        "indicate_pickup_time_in_advance_to_customer": "Indicate pickup time in advance to customer.",
        "customers_can_select_start_time_and_request_intercity_trip": "Customers can select start time and request intercity trip. Drivers also can create their preferred trip with their own schedule. Example: a time range of 1 hour will allow customers and drivers to create an intercity trip starts at 8:00 am - 9:00 am, 9:00 am - 10:00 am, or 10:00 am - 11:00 am.",
        "this_setting_limits_customers_to_create_request_trip_within_working_hours_only": "This setting limits customers to create request trip within working hours only.",
        "face_mask_verify": "Face mask verification",
        "faceMask_allZones": "All zones",
        "faceMask_customized": "Customized zone",
        "turn_on_verify": "Turn on verification",
        "force_to_wear_face_mask": "Force to wear face mask",
        "time_range_to_verify": "Do not verify again within",
        "customizedFaceMaskZone": "Customized face mask verification",
        "zoneOperation": "Operation Zone",
        "FaceMaskCustomized": "Customized",
        "time": "time",
        "day": "day",
        "days": "days",
        "allow_driver_to_skip_verification_after": "Allow driver to skip verification after",
        "driver_need_to_verify": "When driver need to verify",
        "open_app": "Open app",
        "start_reservation": "Start reservation",
        "choose_at_least_one": "You must select at least one action to continue.",
        "face_mask_customized_fail": "You must select at least one action to continue ({0}).",
        "vehicleAgeLimit": "Vehicle age limit",
        "vehicleAgeLimit_years": "years",
        "founderFund_firstBooking": "Founder's Fund: first booking only",
        "distance_diversity": "Distance diversity",
        "Add_Surcharge_Distance": "Add",
        "from": "From",
        "to": "To",
        "surchargeValue": "Surcharge",
        "actions": "Action",
        "please_enter_distance_range_valid": "Distance %{name} is invalid!",
        "please_enter_distance_to": "Please enter distance \"To\"",
        "please_enter_surchargeValue": "Please enter distance \"Surcharge\" for distance range %{name}",
        "please_enter_surchargeValue_is_number": "Please enter valid number \"Surcharge\" for distance range %{name}",
        "Update_service_fee_success": "Service fees has been updated successfully",
        "Update_service_fee_fail": "The errors occurred while updating service fees",
        "Update_fleet_commission_success": "Default fleet commision has been updated successfully",
        "Update_fleet_commission_fail": "The errors occurred while updating default fleet commission",
        "RESERVATION_POLICY": "Reservation policy",
        "default_expected_pickup_time": "Default expected pickup time",
        "default_expected_pickup_time_hint": "The default value you expect a driver to arrive from the pickup time.",
        "same_original_pickup_time": "Same as original pickup time",
        "quarter_before": "15 min before",
        "half_before": "30 min before",
        "quarter_after": "15 min after",
        "half_after": "30 min after",
        "custom": "Custom",
        "min_before": "min before",
        "min_after": "min after",
        "TransactionFee": "Transaction fee",
        "creditCardPercent": "Credit card/ prepaid",
        "directBilling": "Direct billing",
        "externalCard": "External Card"
    },
    "social": {
        "pax_app": "Passenger app",
        "driver_app": "Driver app",
        "default": "Default",
        "customized": "Customized",
        "html": "HTML",
        "about": "About",
        "email": "Email support",
        "enablePhone": "Phone number",
        "phone": "",
        "policy": "Link Privacy Policy",
        "termOfUse": "Link Terms of Use",
        "site": "Website",
        "logoDriverIOS": "Logo - IOS",
        "logoDriverAndroid": "Logo - Android",
        "htmlUrl": "HTML file",
        "Update_fail": "The errors occurred while updating social",
        "Update_success": "The social has been updated successfully"
    },
    "user": {
        "Show_per_page": "Oggetti per pagina",
        "Page": "Pagina",
        "Search_here": "Cerca qui",
        "show_pages": "Mostra {0} - {1} di {2}",
        "Username": "Username",
        "Name": "Nome",
        "Phone_number": "Numero di telefono",
        "Email": "Email",
        "Permission": "Permesso",
        "Status": "Stato",
        "Actions": "Azioni",
        "Active": "Attiva",
        "Inactive": "Inattivo",
        "Edit": "Modifica",
        "Deactivate": "Disattivato",
        "Delete": "Cancella",
        "Add": "Aggiungi",
        "ADD_USER": "Aggiungi Utilizzatore",
        "First_name": "Nome",
        "Last_name": "Cognome",
        "AgendId": "Utilizzatore #",
        "Email_address": "Indirizzo email",
        "Address": "Indirizzo",
        "Required_fields": "Campi obbligatori",
        "Save": "Salva",
        "Cancel": "Cancella",
        "EDIT_USER": "Modifica Utilizzatore",
        "Yes": "Si",
        "DELETE_USER": "ELIMINA UTILIZZATORE",
        "DETAIL_USER": "Dettagli utilizzatore",
        "DELETE_CONFIRM": "Sei sicuro di eliminare l'utilizzatore?",
        "Update_user_fail": "Sono intervenuti degli errori nella fase di aggiornamento dell'utilizzatore",
        "Update_user_success": "L'utilizzatore é stato aggiornato con successo",
        "Create_user_fail": "Sono intervenuti degli errori nella fase di creazione dell'utilizzatore",
        "Create_user_success": "Utente {0} registrato con successo. Verifica l'indirizzo email {1} per le credenziali di accesso.",
        "Load_user_fail": "Sono intervenuti degli errori nella fase di caricamento dell'utilizzatore",
        "Update_user_status_fail": "Sono intervenuti degli errori nella fase di aggiornamento status dell'utilizzatore",
        "Delete_user_fail": "Sono intervenuti degli errori nella fase di eliminazione dell'utilizzatore",
        "Delete_user_success": "L'utilizzatore é stato eliminato con successo",
        "CONFIRM_RESET_PASSWORD": "Vuoi resettare la password?",
        "RESET_PASSWORD": "Vuoi resettare la password?",
        "Reset_password": "Resetta password",
        "Select_permission": "Seleziona Permesso",
        "Reset_password_success": "Reset password successful"
    },
    "report": {
        "query": {
            "errorMessage": {
                "bookFrom": "Per favore seleziona prenotazione da",
                "paymentMethod": "Per favore seleziona il mezzo di pagamento",
                "bookingType": "Please select booking type",
                "ratingType": "Per favore seleziona tipo di valutazione",
                "stars": "Per favore seleziona tipo di valutazione",
                "type": "genere",
                "transactionType": "Per favore seleziona tipo di transazione",
                "serviceType": "Per favore seleziona tipo di servizio.",
                "rideService": "Per favore seleziona servizio corsa",
                "invalidEmail": "Per favore inserisci un indirizzo email valido."
            },
            "search": {
                "driverReferalPax": "Search: Driver Name, Phone Number",
                "passengerRefersPassengers": "Search: Customer Name, Phone Number",
                "driverReferalPaxDetail": "Search: Passenger Name",
                "bookingDetails": "Search: Booking ID, Operator, Customer phone, Customer name, Driver name, ID# / National IC#, License plate, Queuing area, Note, Support ID",
                "financial": "Search: Driver name, Phone",
                "completedTime": "Completed Time",
                "collectedTime": "Collected Time",
                "financialDriver": "Search: Booking ID, Driver name, ID# / National IC#",
                "carActivity": "Cerca targa",
                "affiliation": "Search: Booking ID",
                "prepaidTopUp": "Search: Company name, Top up ID, ID transazione",
                "operatorLogs": "Search: Operator name, User name, Description",
                "corporate": "Search: Booking ID, Driver name, Corp Users, Client case matter, Charge code",
                "bookingLogs": "Search: Booking ID, Customer name, Driver name, ID# / National IC#",
                "rating": "Search: Booking ID, Customer phone, Customer name",
                "mDispatcher": "Search: Booking ID, mDispatcher name",
                "totalAdjustment": "Search: Booking ID",
                "incidentCancellation": "Search: Booking ID, Customer phone, Customer name",
                "acceptanceCancellationRate": "Search: Driver name, Phone number",
                "revenueDriver": "Search: Driver name",
                "dailyDriver": "Search:  Driver phone, Driver name, ID# / National IC#",
                "dailyCar": "Search: License plate, Permission",
                "dailyDriverCar": "Search:  Driver phone, Driver name, ID# / National IC#, License plate",
                "driverLoginStatus": "Search:  Driver phone, Driver name, ID# / National IC#, License plate",
                "bookingSummary": "Search: Operator name, User name, Booking ID",
                "creditWallet": "Search: Company name, Driver name, Booking ID, Operator",
                "cashWallet": "Search: Company name, Driver name, Booking ID, Phone number",
                "settlement": "Search: Driver name, ID# / National IC#",
                "driverRating": "Search: Booking ID, Customer phone, Customer name",
                "driverWithdrawal": "Driver name, Phone, Company, ID transazione, Reference #",
                "penaltyCompensation": "Search: Booking ID, Driver Name, Passenger Name",
                "incompletePayment": "Search: Booking ID, Support ID, Customer Name",
                "passengerWallet": "Search: Booking ID, Customer Name",
                "driverDeposit": "Search: Company name, Driver name, Booking ID, Operator",
                "refund": "Search: Booking ID, Trip Id, Customer name",
                "driverRefersCustomerBookings": "Search: Referral code",
                "driverHistory": "Search: Payout ID",
                "merchantHistory": "Search: Payout ID",
                "firstWillWin": "Search: customer name, customer phone, customer ID",
                "firstWillWinDriver": "Search: driver name, driver phone, username",
                "merchant": "Search: store",
                "merchantCashTransactionHistory": "Search: Booking ID, ID transazione, Merchant name",
                "merchantCreditTransactionHistory": "Search: Booking ID, ID transazione, Merchant name",
                "deleteAccountRequest": "Search: Phone",
                "affiliationPayoutHistory": "Search: Payout ID",
                "thirdPartyBooking": "Search: Booking Id, External Id"
            },
            "common": {
                "all": "Tutto"
            },
            "today": "Oggi",
            "sendDownloadLink": "Recipient Email Address",
            "generaltingYourExcel": "Please note, generating your excel file may take some time. We'll email it to you as soon as it's ready. ",
            "messageSuccess": "File in progress. You'll get an email when ready. Track progress or download directly from Reports/Export History",
            "thisMonth": "Questo Mese",
            "lastMonth": "Il mese scorso",
            "past30Days": "Gli ultimi 30 giorni",
            "thisYear": "Quest’anno",
            "custom": "Personalizzato",
            "customize": "personalizzare",
            "thisWeek": "This week",
            "past7Days": "Ultimi 7 giorni",
            "weekly": "Weekly",
            "monthly": "Monthly",
            "allTime": "All time",
            "tomorrow": "Tomorrow",
            "nextWeek": "Next Week",
            "lastWeek": "Last Week",
            "nextMonth": "Next Month",
            "yesterday": "Yesterday",
            "fromDate": "Dalla data",
            "toDate": "Alla data",
            "driver": "Autista: %{name}",
            "customer": "Customer: %{name}",
            "referralCode": "Referral Code: %{name}",
            "referer": "Referer: %{name}",
            "referee": "Referee: %{name}",
            "usedPromoCode": "Promotion: %{name}",
            "voucherCode": "Code: %{name}",
            "city": "City",
            "company": "Azienda",
            "company_report": "Azienda: %{name}",
            "campaign_report": "Campaign: %{name}",
            "operator": "Operatore",
            "farmType": "Farm type",
            "carLicensePlate": "Targa vettura: %{name}",
            "settlementType": "Type",
            "bookingService": "Network type",
            "payoutOption": "Payout option",
            "currency": "Moneta",
            "bookingFrom": "Prenotato da",
            "thirdPartyAction": "Action",
            "paymentMethod": "Mezzo di Pagamento",
            "paymentStatus": "Stato del pagamento",
            "bookingType": "Tipo prenotazione",
            "adjustPrice": "Adjust price",
            "intercityRoute": "Intercity route",
            "ratingType": "Tipo di valutazione",
            "stars": "Valutazione",
            "driverRating": "Voti dell'autista",
            "vehicleRating": "Vehicle rating",
            "mDispatcherType": "Tipo mDispatcher",
            "mDispatcher": "mDispatcher name",
            "campaign": "Campagna",
            "promocode": "Codice promozionale",
            "canceledBy": "Cancellato da",
            "timezone": "Fuso orario",
            "month": "Mese",
            "year": "Anno",
            "view": "Mostra",
            "action": "Azioni",
            "status": "Stato",
            "module": "Modulo",
            "driverType": "genere",
            "vehicleType": "Tipo di macchina",
            "serviceType": "Tipo di servizio",
            "expireDate": "Data di scadenza",
            "rideSharing": "car pooling",
            "rideService": "servizio corsa",
            "transactionType": "Transaction Type",
            "completeFromCC": "Complete from CC",
            "withDrawalTransactionType": "Transaction Type",
            "bookingStatus": "Stato della prenotazione",
            "withdrawalStatus": "Stato",
            "originalStatus": "Stato pagamento originale",
            "currentStatus": "Stato corrente di pagamento",
            "tip": "Tip",
            "type": "genere",
            "userType": "User type",
            "eventType": "Event type",
            "eventState": "Event state",
            "eventName": "Event name",
            "merchantType": "Merchant type",
            "merchantService": "Merchant service",
            "storeName": "Store",
            "merchant": "Merchant",
            "event": "Event name: %{name}",
            "store": "Store name: %{name}",
            "selectedMerchant": "Merchant: %{name}",
            "associatedApps": "Associated apps",
            "settlement": "Settlement",
            "rideServiceItem": {
                "all": "Tutto",
                "regulation": "Regulation",
                "rideSharing": "Car pooling"
            },
            "actionItem": {
                "all": "Tutto",
                "login": "Log in",
                "logout": "esci",
                "bookIn": "Indisponibile",
                "bookOff": "Non Disponibile",
                "deny": "Respingi",
                "ignore": "Ignora",
                "accept": "Accettato",
                "startTrip": "On my way",
                "updateBooking": "Aggiornamento",
                "arrive": "Arrivare",
                "pickup": "Carico",
                "drop": "Scarica",
                "complete": "Completato",
                "noShow": "Assente",
                "rqJob": "Assegnazione",
                "cancel": "Cancellato"
            },
            "loginStatusItem": {
                "all": "Tutto",
                "online": "connesso",
                "offline": "sconnesso"
            },
            "payoutType": "Payout type",
            "affiliationPayoutTypeItem": {
                "auto": "Auto payout",
                "manual": "Manual payout"
            },
            "affiliationSettlementItem": {
                "payable": "Payable",
                "receivable": "Receivable"
            },
            "affiliationPaymentMethod": {
                "online": "Online",
                "directInvoicing": "Direct invoicing"
            },
            "payoutPaymentStatusItem": {
                "paid": "Paid",
                "pending": "Pending"
            },
            "farmTypeItem": {
                "all": "Tutto",
                "farmIn": "Farm in",
                "farmOut": "Farm out",
                "roaming": "Roaming",
                "affiliationOwner": "gruppo & proprietario affiliazione",
                "farmInAndAffiliationOwner": "Farm in and affiliation owner",
                "farmOutAndAffiliationOwner": "Farm out and affiliation owner"
            },
            "settlementDriverTypeItem": {
                "all": "Tutti gli autisti",
                "individual": "Singolo autista"
            },
            "shortTrip": {
                "all": "All",
                "shortTrip": "Short trip",
                "nonShortTrip": "Viaggio non breve"
            },
            "paymentMethodItem": {
                "B2BTerminal": "B2BTerminal",
                "webBooking": "Web booking",
                "pendingPayment": "Pending payment",
                "all": "Tutto",
                "cash": "Contanti",
                "cashBySender": "Cash by sender",
                "cashByRecipient": "Cash by recipient",
                "personalCard": "Carta personale",
                "corporateCard": "Carta Aziendale",
                "qrCodePayment": "QR code payment",
                "directBilling": "Fatturazione diretta",
                "externalCard": "Carta esterna",
                "mDispatcherCard": "Carta mDispatcher",
                "corporatePrepaid": "Carta Prepagata",
                "canceled": "Cancellato",
                "noShow": "Assente",
                "incident": "Incidente",
                "fleetCard": "Carta esterna",
                "applePay": "Apple pay",
                "creditCardExternal": "Credit card",
                "paxWallet": "Wallet",
                "tngeWallet": "TnG eWallet",
                "vippseWallet": "Vipps",
                "zainCash": "ZainCash",
                "xendit": "Xendit",
                "FIB": "FIB",
                "SantimPay": "SantimPay",
                "gCash": "GCash",
                "completedWithoutService": "Completed without driver",
                "BankOfGeorgia": "Bank Of Georgia",
                "partialCompleted": "Partial Completed",
                "failed": "Failed",
                "googlePay": "Google Pay",
                "paymentLink": "Payment Link",
                "easyPaisa": "Easy Paisa",
                "jazzCash": "Jazz Cash"
            },
            "paymentStatusItem": {
                "all": "Tutto",
                "fullPayment": "Pagamento completo",
                "partialPayment": "Pagamento parziale",
                "pendingPayment": "In attesa di Pagamento",
                "partialRefund": "Partial refund",
                "fullRefund": "Full refund"
            },
            "companySettlementTypeItem": {
                "all": "All",
                "fleetOweCompany": "Fleet owe company",
                "companyOweFleet": "Company owe fleet"
            },
            "bookingStatusItem": {
                "all": "All",
                "completed": "Completed",
                "incident": "Incident",
                "paxNoShow": "PAX no show",
                "driverNoShow": "Driver no show",
                "rejected": "Respinto",
                "canceledByTimeOut": "Canceled by timeout",
                "canceledByTimeOutRejected": "Canceled by timeout (rejected)",
                "canceledByBookingBoard": "Canceled by booking board",
                "canceledByCustomer": "Canceled by customer",
                "canceledBySupplier": "Canceled by supplier"
            },
            "bookingFromItem": {
                "all": "Tutto",
                "paxApp": "Pax app",
                "mDispatcher": "MDispatcher",
                "kiosk": "Kiosk",
                "cc": "Centrale di gestione",
                "webBooking": "Web booking",
                "carHailing": "Chiamata al volo",
                "streetSharing": "Street-sharing",
                "dashboard": "Booking board",
                "corp": "Booking board (CTM)",
                "dmc": "Booking board (DMC)",
                "Boost_web_link": "Boost web link"
            },
            "thirdPartyActionItem": {
                "all": "All",
                "searched": "Searched",
                "allocated": "Allocated",
                "updated": "Updated",
                "cancelled": "Cancelled"
            },
            "bookingTypeItem": {
                "batchDelivery": "Batch Delivery",
                "all": "Tutto",
                "now": "Adesso",
                "reservation": "Prenotazione",
                "intercity": "Intercity",
                "delivery": "Delivery",
                "shuttle": "Shuttle"
            },
            "adjustPrices": {
                "all": "All",
                "normalPrice": "Normal price",
                "addonPrice": "Addon price"
            },
            "bookingServiceItem": {
                "all": "Tutto",
                "roaming": "Roaming",
                "homeFleet": "Farm out",
                "localBooking": "Prenotazione locale",
                "farmIn": "Farm in",
                "farmInAndAffiliationOwner": "gruppo & proprietario affiliazione",
                "provideService": "Farm in"
            },
            "ratingTypeItem": {
                "all": "Tutto",
                "like": "Mi Piace",
                "dislike": "Non Mi Piace"
            },
            "starsItem": {
                "all": "Tutto",
                "star1": "1 Stelle",
                "star2": "2 Stelle",
                "star3": "3 Stelle",
                "star4": "4 Stelle",
                "star5": "5 Stelle"
            },
            "incidentCanceledTypes": {
                "all": "All",
                "incident": "Incident",
                "canceled": "Canceled"
            },
            "canceledByItem": {
                "all": "Tutto",
                "CorpAD": "Corporate Admin",
                "passenger": "Passeggero",
                "driver": "Autisti",
                "merchant": "Merchant",
                "CC": "CC",
                "mDispatcher": "MDispatcher",
                "Partner": "Partners",
                "webBooking": "Web booking",
                "timeout": "Timeout"
            },
            "bookingSummaryActionItem": {
                "all": "Tutto",
                "create": "Crea",
                "update": "Update",
                "cancel": "Cancella",
                "complete": "completato",
                "incident": "Incident"
            },
            "transactionTypeItem": {
                "bankAccount": "Transferred to bank account",
                "sentToCustomer": "Send to customer",
                "receivedFromDriver": "Received from driver",
                "sentToDriver": "Sent to driver",
                "creditWallet": "Transferred to credit wallet",
                "netEarning": "Net Earning",
                "referralEarning": "Referral Earning",
                "all": "Tutto",
                "topUp": "Ricarica",
                "editBalance": "Modifica saldo",
                "bookingDeduction": "Booking deduction",
                "bookingPromo": "Booking promotion",
                "topUpGCash": "Ricarica di Gcash",
                "cashWallet": "Cash wallet",
                "topUpMOLPay": "Top up by Razer Pay",
                "topUpZainCash": "Top up by ZainCash",
                "topUpXendit": "Top up by Xendit",
                "topUpFIB": "Top up by FIB",
                "topUpSantimPay": "Top up by SantimPay",
                "topUpTelebirr": "Top up by Telebirr",
                "topUpKsher": "Top up by Ksher",
                "topUpWallet": "Top up by {0}",
                "TnG": "Top up by TnG",
                "credit": "Top up by credit card",
                "ride": "Ride",
                "tipAfterCompleted": "Tip after ride",
                "debtWriteOff": "Debt write-off",
                "refund": "Refund",
                "excessOffsetAmount": "Excess Cash Credit",
                "excessDeductionAmount": "Excess Cash Debit",
                "cashExcess": "Excess Cash Debit",
                "gateway": "Top up from gateway",
                "driverTopUpForPax": "Excess Cash Debit",
                "updatedBalance": "Updated balance",
                "partialEarning": "Earning",
                "payout": "Payout manual",
                "payoutManual": "Payout manual",
                "payoutDriver": "Payout",
                "itemValue": "Item values",
                "redeemVoucher": "Redeem code #",
                "topUpReferrer": "Referral reward",
                "driverInsurance": "Driver Insurance",
                "cancellationPenalty": "Cancellation Penalty",
                "WingBank": "Transfers to Wingbank account",
                "customerDebt": "Customer Debt",
                "googlePay": "Top up by Google Pay",
                "applePay": "Top up by Apple Pay",
                "merchantCommission": "Merchant commission",
                "eWallet": "Transferred to external e-wallet",
                "reversed": "Withdrawal reversed"
            },
            "moduleItem": {
                "all": "Tutto",
                "bookings": "Prenotazioni",
                "customers": "Clienti",
                "dispatch": "Assegnazione",
                "general": "Generale",
                "promotion": "Promozione",
                "quest": "Quest",
                "rate": "Valuta",
                "car": "Macchina",
                "city": "Città",
                "company": "Azienda",
                "drivers": "Autisti",
                "driverSettlement": "Pagamenti autista",
                "login": "Registrati",
                "logout": "Esci",
                "mDispatcher": "mDispatcher",
                "mDispatcherType": "Tipo mDispatcher",
                "operation": "Operazione",
                "partners": "Partners",
                "permission": "Permesso",
                "queuingArea": "Area Fila",
                "report": "Reports",
                "shift": "Turno",
                "user": "Utilizzatori",
                "voiceSms": "Voce & SMS",
                "intercityZone": "Zone (intercity)",
                "Payout": "Payout",
                "code#": "Code #",
                "ApiManagement": "API management"
            },
            "serviceTypeItem": {
                "all": "Tutto",
                "oneWay": "Senso unico",
                "fromAirport": "Dall’aereoporto",
                "toAirport": "All’aereoporto",
                "hourly": "oraria/giornaliera",
                "roundTrip": "Andata e ritorno",
                "fromAirportHourlyDaily": "Dall’aereoporto - hourly/daily",
                "fromAirportRoundTrip": "Dall’aereoporto - round trip",
                "toAirportHourlyDaily": "All’aereoporto - hourly/daily",
                "toAirportRoundTrip": "All’aereoporto - round trip"
            },
            "merchantTransactionTypeItem": {
                "all": "All",
                "editBalance": "Edit balance",
                "payout": "Payout",
                "earning": "Earning",
                "itemValue": "Item value",
                "orderDeduction": "Order deduction"
            },
            "withdrawalTransactionType": {
                "WingBank": "Transferred to Wingbank account",
                "bankAccount": "Trasferito sul conto bancario",
                "cashWallet": "Transferred to cash wallet",
                "creditWallet": "Trasferito sul portafoglio di credito"
            },
            "payoutDriver": {
                "driversHaveBankAccount": "Drivers have bank account",
                "driversDontHaveBankAccount": "Drivers don't have bank account",
                "payoutEndTime": "Payout end time",
                "driverHaveInvalidBankAccount": "Drivers have invalid bank account"
            },
            "payoutMerchant": {
                "HaveBankAccount": "Merchants have bank account",
                "DontHaveBankAccount": "Merchants don't have bank account",
                "HaveInvalidBankAccount": "Merchants have invalid bank account",
                "payoutEndTime": "Payout end time"
            }
        },
        "error": {
            "23005": "For system performance you may only view reports in %{interval} %{unitLabel}, please adjust your date-range"
        },
        "result": {
            "idPermitType": "ID tipo",
            "noResultFound": "Nessun risultato",
            "page": "Page",
            "itemsPerPage": "oggetti per pagina",
            "showPages": "Shown %{from} - %{to} of %{total}",
            "exportToExcel": "Esporta su excel",
            "exportToCSV": "Export to csv",
            "exportToPaymentFile": "Export to payment file",
            "pay": "Paga",
            "paySelected": "Pay selected",
            "payAll": "Pay all",
            "invalidBankAccount": "Invalid bank account",
            "validBankAccount": "Valid bank account",
            "bookingDetails": {
                "successSendMessage": "Your message has been sent",
                "packageName": "Package Name",
                "transactionId": "ID transazione",
                "proofOfFailure": "Proof Of Failure",
                "recipientsNumber": "No. of recipient",
                "recipientsInfo": "Recipient info",
                "creditTransactionFee": "Credit card fees",
                "carTypeRequest": "Requested cartype",
                "bookingId": "Prenotazione #",
                "bookingStatus": "Booking status",
                "tripId": "Trip #",
                "bookFrom": "Prenotato da",
                "bookingService": "Network type",
                "bookingType": "Tipo prenotazione",
                "serviceType": "Tipo di servizio",
                "operator": "Operatore",
                "customerPhone": "Numero di telefono del passeggero",
                "customerName": "Nome passeggero",
                "customerIdNumber": "Passenger ID# / IC#",
                "corpDivision": "Division",
                "corpDept": "Dept",
                "chargeCode": "Charge code",
                "tripDescription": "Trip description",
                "rideSharing": "car pooling",
                "queueName": "Area Fila",
                "bookingTime": "Orario Prenotazione",
                "pickupTime": "Data e ora di partenza",
                "expectedPickupTime": "Expected Pickup Time",
                "dispatchTime": "Tempo assegnazione",
                "onBoardTime": "orario a bordo",
                "droppedOffTime": "Orario scarico passeggero",
                "onBoardDuration": "On board duration",
                "dispatch": "Assegnazione #",
                "completedTime": "Tempo Completato",
                "driverName": "Nome autista",
                "driverIdPermit": "ICarta di identità # Carta di identità nazionale #",
                "driverLicenseNumber": "Numero Patente Autista",
                "companyName": "Azienda",
                "carType": "Tipo Macchina",
                "licensePlate": "Numero di targa",
                "intercityRoute": "Intercity route",
                "pickupLocation": "Luogo dell’appuntamento",
                "geoPickup": "Luogo dell’appuntamento lat/long",
                "extraDestination": "Extra destinations",
                "originDestination": "Original destination",
                "originEstDistance": "Original est distance",
                "destination": "Destinazione",
                "geoDestination": "Destinazione lat/long",
                "pickupFrom": "Pickup location from",
                "distanceTour": "distanza P.O.B (passeggero a bordo)",
                "internalNotes": "Internal notes",
                "notes": "Note",
                "basicFareCalculator": "Basic fare calculator",
                "basicFare": "Tariffa base",
                "editFare": "Edit basic fare",
                "oldBasicFare": "Old basic fare",
                "airportFee": "tariffa aeroporto",
                "meetDriver": "Incontra l’autista",
                "rushHour": "Sovrapprezzo",
                "surchargeParameter": "Sovrapprezzo dinamico",
                "dynamicFare": "Dynamic fare",
                "heavyTraffic": "Traffico intenso",
                "tollFee": "Toll fee",
                "parkingFee": "Parking fee",
                "gasFee": "Gas fee",
                "otherFees": "Altre spese",
                "subtotal": "Subtotale",
                "subTotalFF": "Subtotal FF",
                "techFee": "Spese tecniche",
                "serviceFee": "Add'l services",
                "fleetServiceFee": "Fleet services",
                "bookingFee": "Spese di prenotazione",
                "salesTax": "Tasse vendita",
                "tipAmt": "Ammontare mancia",
                "promoCode": "Codice promozionale",
                "promoAmt": "Promo amt",
                "requestedTotalAmount": "Requested total amount",
                "total": "Ammontare totale",
                "totalNotRounded": "Round (totale)",
                "actualReceived": "Actual received",
                "differenceAmt": "Difference (received)",
                "customerDebt": "Customer Debt",
                "markupDifference": "Markup difference",
                "receivedWhenDropOff": "Received when drop off",
                "refundAmount": "Refund amt",
                "requestedPaymentMethod": "Metodo di pagamento richiesto",
                "paidBy": "Pagato da",
                "splitPayment": "Split payment",
                "paidByWallet": "Paid by wallet",
                "paidByOtherMethod": "Paid by other method",
                "cashReceived": "Cash received",
                "transferredChangeByWallet": "Transfer change by wallet",
                "returnedChangeByCash": "Return change by cash",
                "instructions": "Instructions",
                "additionalServiceName": "Nome",
                "additionalServiceValue": "valore",
                "sendReceiptHeader": "",
                "sendReceipt": "Send receipt",
                "send": "Invia",
                "cancel": "Cancella",
                "sentReceiptSuccess": "The receipt has been sent successfully",
                "sentReceiptFailed": "The receipt cannot be sent right now. Please try again.",
                "coRider": "copilota",
                "coriderName": "Nome",
                "stopAddress": "Destinazione aggiuntiva",
                "stopGeo": "Lat/long",
                "addonPrice": "Customer adjust price",
                "oldSubTotal": "Subtotal before adjust",
                "view_good_receipt": "Goods receipt",
                "view_delivery_receipt": "Delivery photo",
                "view": "View",
                "tipAfterCompletedDetails": "Transferred: {0}{1}; Transaction fee: {0}{2}",
                "fleetMarkup": "Profit",
                "editedDriverEarning": "Edited driver earning",
                "driverEarning": "Driver earning",
                "pickUpZoneName": "Pickup zone",
                "dynamicFactor": "Dynamic fare (factor)"
            },
            "financial": {
                "driverName": "Nome Autista",
                "driverPhone": "Numero di telefono",
                "driverUserName": "Username",
                "companyName": "Azienda",
                "bookingList": "Booking list",
                "totalFare": "Totale Tariffa w/o promozione",
                "netEarning": "Guadagno Netto",
                "showBookingList": "Mostra",
                "referralEarning": "Referral earning",
                "totalEarningNet": "Total earning (net)",
                "totalEarningNetTooltip": "Money you earned after returning all fees to fleet. Total earning (net) = Net earning + Tip after ride",
                "totalEarningNetTooltipRefferal": "Money you earned after returning all fees to fleet. Total earning (net) = Referal earning + Net earning + Tip after ride",
                "totalEarningGross": "Total earning (gross)",
                "totalEarning": "Total earning",
                "totalEarningGrossTooltip": "Money you earned from booking fare and tips. Total earning (gross) = Gross earning + Tip after ride",
                "totalEarningGrossTooltipRefferal": "Money you earned from booking fare and tip. Total earning (gross) = Referal earning + Gross earning + Tip after ride",
                "note": "Note",
                "shortTrip": "breve viaggio"
            },
            "financialDriver": {
                "supplierAmount": "Supplier commission",
                "payoutType": "Payout type",
                "date": "Data",
                "driverTipAfterCompleted": "Tip after ride",
                "bookingId": "Booking ID",
                "paidBy": "Pagato da",
                "bookFrom": "Prenotato da",
                "bookingType": "Tipo prenotazione",
                "companyName": "Azienda",
                "driverName": "Autista",
                "driverIdPermit": "Carta di identità # Carta di identità nazionale #",
                "totalFare": "Total fare without promo",
                "subtotal": "Subtotale",
                "airportFee": "tariffa aeroporto",
                "meetDriver": "Incontra l’autista",
                "tollFee": "Toll fee",
                "parkingFee": "Parking fee",
                "gasFee": "Gas fee",
                "rushHour": "Rush hour",
                "transactionFee": "Transaction fee",
                "techFee": "Spese tecniche",
                "salesTax": "Tasse vendita",
                "tipAmt": "Ammontare mancia",
                "bookingFee": "Spese di prenotazione",
                "commission": "Commissione",
                "deduction": "Deduzione",
                "promoAmt": "Promo amt",
                "ridePayment": "Ride payment",
                "driverTax": "Driver tax",
                "grossEarning": "Gross earning",
                "driverDeduction": "Driver deduction",
                "netEarning": "Guadagno Netto",
                "payToDriver": "Pay to driver",
                "payToFleet": "Pay to fleet",
                "totalNotRounded": "Round (totale)",
                "fleetServiceFee": "Fleet service fees",
                "editedDriverEarning": "Edited driver earning",
                "stripeFee": "Stripe Fee"
            },
            "financialCompany": {
                "companyName": "Azienda ",
                "homeFleetNetEarning": "Home fleet Net Earning",
                "affiliateNetEarning": "Affiliate Net Earning",
                "totalNetEarning": "Total Driver Net Earning",
                "status": "Stato",
                "companyAmount": "Company profit"
            },
            "companySettlement": {
                "companyName": "Company",
                "pendingSettlement": "Pending settlement",
                "viewDetails": "View details",
                "settlementType": "Type"
            },
            "companySettlementDetails": {
                "bookId": "Booking ID",
                "dateTime": "Date time",
                "originalPaymentStatus": "Original payment status",
                "subTotal": "Subtotal",
                "fleetAmount": "Fleet amount",
                "driverAmount": "Driver amount",
                "companyAmount": "Company amount",
                "companyOweFleet": "Company owe fleet",
                "fleetOweCompany": "Fleet owe company",
                "notes": "Notes"
            },
            "financialProfit": {
                "profit": "Profitto",
                "bookingId": "Booking #",
                "subTotal": "Subtotal",
                "subTotalFF": "Subtotal FF",
                "fleetCommission": "Fleet commission",
                "techFee": "Tech fee",
                "promotion": "Promotion",
                "markupDifference": "Markup difference",
                "Profit": "Profit",
                "currency": "Currency",
                "fleetServiceFee": "Fleet service (fleet take)",
                "fleetServiceFeeTooltip": "Amount fleet takes commission from service fees"
            },
            "promotion": {
                "date": "Data",
                "promoCode": "Codice Promozione",
                "users": "# Utilizzatori",
                "uses": "# Utilizzi",
                "checked": "Checked",
                "applied": "Applied",
                "totalPromoAmount": "Totale ammontare promozione",
                "totalReceipt": "Ricevuta Totale",
                "numberOfUsers": "Number of users",
                "numberOfPromoCodeAppliance": "Number of promo code appliance",
                "numberOfSuccessfulBookings": "Number of successful bookings",
                "numberOfCompletedBookings": "Number of completed bookings",
                "numberOfCompletedUsers": "Number of completed users",
                "numberOfUsersApplyPromotion": "Number of users apply promotion",
                "numberOfUsersApplyPromotionAndCompleteBooking": "Number of users apply promotion & complete booking",
                "numberOfPromoCodeApplianceTooltip": "Number of promo code appliance by all users",
                "numberOfSuccessfulBookingsTooltip": "Number of bookings created successfully with promotion code",
                "numberOfCompletedBookingsTooltip": "Number of completed bookings which applied promotion code"
            },
            "export": {
                "createdDate": "Date",
                "sessionName": "Session Name",
                "download": "Download",
                "delete": "Delete",
                "percent": "Percent",
                "status": "Status",
                "name": "Module name"
            },
            "redeemed": {
                "date": "Date",
                "code": "Code",
                "customers": "Customer",
                "phone": "Phone number",
                "value": "Value",
                "currency": "Currency",
                "campaignName": "Campaign"
            },
            "carActivity": {
                "plateNumber": "Targa vettura",
                "vehicleType": "Tipo di macchina",
                "companyName": "Azienda",
                "day1st": "1st",
                "day2nd": "2nd",
                "day3rd": "3rd",
                "day4th": "4th",
                "day5th": "5th",
                "day6th": "6th",
                "day7th": "7th",
                "day8th": "8th",
                "day9th": "9th",
                "day10th": "10th",
                "day11th": "11th",
                "day12th": "12th",
                "day13th": "13th",
                "day14th": "14th",
                "day15th": "15th",
                "day16th": "16th",
                "day17th": "17th",
                "day18th": "18th",
                "day19th": "19th",
                "day20th": "20th",
                "day21st": "21st",
                "day22nd": "22nd",
                "day23rd": "23rd",
                "day24th": "24th",
                "day25th": "25th",
                "day26th": "26th",
                "day27th": "27th",
                "day28th": "28th",
                "day29th": "29th",
                "day30th": "30th",
                "day31st": "31st",
                "operationDays": "Giorni operazionali",
                "activeCars": "Totale vetture attive"
            },
            "carActivityLogsTitle": "Registrazioni",
            "carActivityLogs": {
                "licensePlate": "Targa della vettura",
                "companyName": "Azienda",
                "bookingId": "Booking ID",
                "driverName": "Nome Autista",
                "driverIDNumber": "Carta di identità # Carta di identità nazionale #",
                "date": "Accepted time",
                "bookFrom": "Prenotato da"
            },
            "affiliation": {
                "date": "Data e ora di partenza",
                "bookingId": "Booking ID",
                "bookingType": "Tipo prenotazione",
                "homeFleet": "Home fleet",
                "providerFleet": "Provider fleet",
                "driver": "Autisti",
                "passenger": "Passeggero",
                "paidBy": "Pagato da",
                "airportFee": "Tariffa aeroporto",
                "meetDriver": "Incontra l’autista",
                "rushHour": "Ora di Punta",
                "tollFee": "Toll fee",
                "parkingFee": "Parking fee",
                "gasFee": "Gas fee",
                "heavyTraffic": "Traffico intenso",
                "otherFees": "Altre spese",
                "provider": "Fornitore",
                "basicFareProvider": "tariffa base fornitore",
                "subTotalProvider": "Sub totale fornitore",
                "techFeeProvider": "Totale spese tecniche fornitore",
                "bookingFeeProvider": "Spese di prenotazione fornitore",
                "taxValueProvider": "Valore fiscale fornitore",
                "tipProvider": "Mancia fornitore",
                "totalFareProvider": "Totale spese fornitore",
                "basicFare": "Tariffa base",
                "subtotal": "Subtotale",
                "techFee": "Spese tecniche",
                "bookingFee": "Spese di prenotazione",
                "tax": "Tasse",
                "tip": "Mancia",
                "totalFare": "Totale tariffa",
                "payoutInLocalCurrency": "Ride earning in local currency",
                "payoutInUSD": "Ride earning in USD",
                "fleetCommInUSD": "Commissioni flotta in euro",
                "bookingFeeInUSD": "Spese di prenotazione in euro",
                "totalPayoutInUSD": "Totale rimborso in euro",
                "subTotal": "Subtotal",
                "bookingStatus": "Stato della prenotazione",
                "vehicle": "Vehicle",
                "customerPhone": "Customer phone number",
                "driverPhone": "Driver phone number",
                "total": "Total",
                "totalInUSD": "Total fare (USD)",
                "supplierPayout": "Supplier payout",
                "supplierPayoutInUSD": "Supplier payout (USD)",
                "buyerPayout": "Buyer commission",
                "buyerPayoutInUSD": "Commission (USD)",
                "buyerCommission": "Commission",
                "buyerCommissionInUSD": "Commission (USD)",
                "receivableInUSD": "Receivable (USD)",
                "payableInUSD": "Payable (USD)",
                "fleetMarkupInUSD": "Profit (USD)",
                "paymentMethod": "Payment method",
                "paymentStatus": "Payment status"
            },
            "prepaidTopUp": {
                "date": "Date & time",
                "topUpId": "Top up ID",
                "transactionId": "ID transazione",
                "name": "Nome",
                "company": "Azienda",
                "paidBy": "Pagato da",
                "amount": "Ammontare",
                "newAmount": "New Balance",
                "transactionType": "Transaction type"
            },
            "operatorLogs": {
                "date": "Data",
                "operator": "Operatore",
                "username": "Username",
                "module": "Modulo",
                "description": "Descrizione"
            },
            "corporate": {
                "date": "Data",
                "bookingId": "Booking ID",
                "operatorName": "Corp operator",
                "corporateName": "Azienda",
                "corpTraveler": "Viaggiatore aziendale",
                "travelerSignature": "Traveler signature",
                "corpId": "Note",
                "corpDivision": "Indirizzo",
                "costCentre": "Cost centre",
                "corpPO": "Altro",
                "managerName": "Manager's name",
                "managerEmail": "Manager's email",
                "title": "Title",
                "department": "Department",
                "clientCaseMatter": "Client case matter / Trip description",
                "chargeCode": "Charge code / Trip code",
                "driverName": "Autista",
                "pickupLocation": "Luogo dell’appuntamento",
                "destination": "Destinazione",
                "total": "Ammontare totale",
                "subtotal": "Subtotale",
                "subTotalFF": "Subtotal FF",
                "commission": "Commissione",
                "commissionBooking": "Apply commission for bookings from app/pwa",
                "paidBy": "Pagato da",
                "statusDisplay": "Booking status",
                "flightNumber": "Flight number"
            },
            "bookingLogs": {
                "bookingId": "Booking ID",
                "bookingService": "Network type",
                "bookingType": "Tipo prenotazione",
                "driverName": "Autisti",
                "driverIdPermit": "Carta di identità # Carta di identità nazionale #",
                "customerName": "Passeggero",
                "pickupTime": "Data e ora di partenza",
                "onBoardTime": "Orario passeggero a bordo",
                "droppedOffTime": "Orario scarico passeggero",
                "pickupLocation": "Luogo dell’appuntamento",
                "destination": "Destinazione",
                "dispatchHistory": "Storico assegnazioni",
                "otwDistance": "O.T.W distance",
                "distanceTour": "P.O.B distance",
                "duration": "Durata",
                "paidBy": "Pagato da",
                "bookFrom": "Prenotato da",
                "map": "Mappa",
                "showDispatchHistory": "Mostra",
                "showMap": "Mostra",
                "paymentActivities": "Payment activities"
            },
            "rating": {
                "date": "Data",
                "bookingId": "Booking ID",
                "driverName": "Autisti",
                "driverIdPermit": "Carta di identità # Carta di identità nazionale #",
                "customerName": "Nome cliente",
                "customerPhone": "Customer phone",
                "like": "Mi Piace",
                "dislike": "Non Mi Piace",
                "notes": "Commento",
                "stars": "Valutazione",
                "vehicleRating": "Vehicle Rating",
                "driverRating": "Voti dell'autista",
                "plateNumber": "Plate Number"
            },
            "mDispatcher": {
                "date": "Data",
                "bookingId": "Booking ID",
                "mDispatcherName": "mDispatcher name",
                "mDispatcherType": "Tipo mDispatcher",
                "subtotal": "Subtotale",
                "commission": "Commissione",
                "paidBy": "Pagato da"
            },
            "totalAdjustment": {
                "date": "Data",
                "bookingId": "Booking ID",
                "oldAmount": "Ammontare Precedente",
                "type": "Type",
                "markupDifference": "Markup difference",
                "newAmount": "Nuovo Ammontare",
                "username": "Operator",
                "reason": "Reason"
            },
            "incidentCancellation": {
                "date": "Data",
                "bookingId": "Booking ID",
                "customerName": "Nome cliente",
                "customerPhone": "Customer phone",
                "bookFrom": "Prenotato da",
                "canceledBy": "Cancellato da",
                "type": "genere",
                "reason": "Reason",
                "reasonCode": {
                    "0": "Ho trovato un'altra opzione di viaggio",
                    "1": "Autista troppo lontano",
                    "2": "Ho inserito la posizione sbagliata",
                    "3": "Ho aspettato troppo a lungo",
                    "4": "Il conducente ha chiesto di annullare",
                    "5": "I changed my plans",
                    "6": "I wanted to change location",
                    "101": "Most or all items are unavailable",
                    "102": "We're too busy right now",
                    "103": "We're closed right now",
                    "104": "We're closing soon"
                },
                "canceller": "Azzeratore"
            },
            "acceptanceCancellationRate": {
                "companyId": "Company Id",
                "driverId": "Driver Id",
                "driverName": "Driver name",
                "phoneNumber": "Phone number",
                "company": "Company",
                "acceptanceRate": "Tasso di accettazione",
                "cancellationRate": "Tasso di cancellazione",
                "rating": "Rating"
            },
            "revenueFleet": {
                "date": "Data",
                "fareRevenue": "Entrate tariffa",
                "trip": "Viaggio",
                "avgFareTrip": "Avg fare/trip",
                "drivers": "# Autisti",
                "avgTripPerDriver": "Strada viaggio/autista",
                "dispatcher": "# Assegnazione"
            },
            "revenueDriver": {
                "date": "Data",
                "driverName": "Autisti",
                "fareRevenue": "Entrate tariffa",
                "trip": "# Viaggio",
                "avgFareTrip": "Avg fare/trip"
            },
            "revenueMonthly": {
                "month": "Month",
                "trip": "Finished trips",
                "subtotal": "Subtotal",
                "tipAmt": "Tip",
                "techFee": "Tech fee",
                "salesTax": "Tax",
                "partnerCommission": "Partner commission/ ODD",
                "totalFare": "Total fare without promo",
                "netEarning": "Net Earning",
                "fleetServiceFee": "Fleet service fees"
            },
            "dailyDriver": {
                "driverName": "Autisti",
                "driverIDPermit": "Carta di identità # Carta di identità nazionale #",
                "driverPhone": "Telefono",
                "companyName": "Azienda",
                "workingHoursOnline": "Online (ore)",
                "workingHoursAvailable": "Disponibile (ore)",
                "driverWorkingHours": "Driver working hours",
                "distance": "Driver traveled distance",
                "CC_activity": "CC activity",
                "fare_summary": "CC activity",
                "workingHoursNotAvailable": "Non Disponibile (ore)",
                "workingHoursPob": "P.O.B (ore)",
                "workingHoursPobToolTip": "Passenger on board duration",
                "workingHoursAccepted": "Accettato",
                "workingHoursReject": "Respingi",
                "workingHoursIgnore": "Ignora",
                "workingHoursCancel": "Cancella",
                "completedTripsTotal": "Totale",
                "completedTripsCc": "Da CC",
                "completedTripsCarHailing": "Da chiamate al volo",
                "completedTripsStreetSharing": "From Street Sharing",
                "completedTripsPaxApp": "Da app passeggero",
                "completedTripsWebBooking": "Da web booking",
                "completedTripsMDispatcher": "Da  mDispatcher",
                "completedTripsKiosk": "Da Kiosk",
                "completedTripsApi": "Da API",
                "ccActivityDispatchedTrips": "Viaggi assegnati",
                "ccActivityAccepted": "# Accettato",
                "ccActivityReject": "Respingi",
                "ccActivityIgnore": "Ignora",
                "ccActivityCancelByPassenger": "Cancellata dal passeggero ",
                "ccActivityCancelByDriver": "Cancellato dall'autista",
                "ccActivityCancelByCc": "Cancellata da CC",
                "ccActivityCancelByAPI": "Cancellato da API",
                "ccActivityNoShow": "Assente",
                "fareSummaryTotalCash": "Contanti",
                "fareSummaryTotalCreditCard": "Carta di credito",
                "fareSummaryTotalExternalCard": "Carta esterna",
                "fareSummaryTotalPaxWallet": "Wallet",
                "fareSummaryTotalDirectBilling": "Driect invoicing",
                "fareSummaryTotalQrCode": "QR code",
                "fareSummaryTotalPrePaid": "Corporate Prepaid",
                "fareSummaryTotalApplePay": "Apple pay",
                "fareSummaryTotalTngeWallet": "TNG eWallet",
                "fareSummaryTotalVippseWallet": "Vipps",
                "fareSummaryTotal": "Totale",
                "ratingNotes": "No. note",
                "ratingDislike": "No. dislikes",
                "ratingLike": "No. likes",
                "fareDetailsBasicFare": "Tariffa base",
                "fareDetailsAirportFee": "Tariffa aeroporto",
                "fareDetailsRushHour": "Sovrapprezzo",
                "fareDetailsHeavyTraffic": "Traffico intenso",
                "fareDetailsOtherFees": "Altre spese",
                "fareDetailsSubTotal": "Subtotale",
                "fareDetailsSubTotalFF": "Subtotal FF",
                "fareDetailsTechFee": "Spese tecniche",
                "fareDetailsBookingFee": "Spese di prenotazione",
                "fareDetailsSalesTax": "Tasse vendita",
                "fareDetailsTip": "Ammontare mancia",
                "fareDetailsPromoAmount": "Promo amt",
                "fareDetailsTotalAmt": "Ammontare totale",
                "availableDistance": "Available",
                "otwDistance": "O.T.W",
                "otwDistanceToolTip": "On the way distance",
                "pobDistance": "P.O.B",
                "pobDistanceToolTip": "Passenger on board distance",
                "ratingStars1": "No. 1 star",
                "ratingStars2": "No. 2 stars",
                "ratingStars3": "No. 3 stars",
                "ratingStars4": "No. 4 stars",
                "ratingStars5": "No. 5 stars",
                "ratingAvg": "Average",
                "ssnNumber": "SSN #",
                "driverEmail": "Indirizzo email",
                "driverAddress": "Indirizzo Casa",
                "fareDetailsAddonPrice": "Customer adjust price",
                "fareDetailsOldSubTotal": "Subtotal before adjust"
            },
            "dailyCar": {
                "carInfo": "Informazioni macchina",
                "fareDetails": "Dettagli tariffa",
                "completedTrips": "Viaggi completati",
                "carInfoCompanyName": "Azienda",
                "carInfoPermission": "Permesso",
                "carInfoVehiclePlateNumber": "Numero di targa",
                "carInfoVhcId": "Macchina #",
                "carInfoCarType": "Tipo di macchina",
                "completedTripsTotal": "Totale",
                "completedTripsCc": "Da CC",
                "completedTripsCarHailing": "Da chiamate al volo",
                "completedTripsStreetSharing": "From Street Sharing",
                "completedTripsPaxApp": "Da app passeggero",
                "completedTripsWebBooking": "Da web booking",
                "completedTripsMDispatcher": "Da  mDispatcher",
                "completedTripsKiosk": "Da Kiosk",
                "completedTripsAPI": "Da API",
                "fareDetailsBasicFare": "Tariffa base",
                "fareDetailsAirportFee": "Tariffa aeroporto",
                "fareDetailsMeetDriver": "Incontra l’autista",
                "fareDetailsRushHour": "Sovrapprezzo",
                "fareDetailsHeavyTraffic": "Traffico intenso",
                "fareDetailsTollFee": "Toll fee",
                "fareDetailsParkingFee": "Parking fee",
                "fareDetailsGasFee": "Gas fee",
                "fareDetailsOtherFees": "Altre spese",
                "fareDetailsSubTotal": "Sub totale",
                "fareDetailsSubTotalFF": "Subtotal FF",
                "fareDetailsTechFee": "Spese tecniche",
                "fareDetailsBookingFee": "Tariffe prenotazione",
                "fareDetailsSalesTax": "Tasse vendita",
                "fareDetailsTip": "mancia",
                "fareDetailsPromoAmount": "importo promozione",
                "fareDetailsTotalAmt": "Ammontare totale",
                "fareDetailsAddonPrice": "Customer adjust price",
                "fareDetailsOldSubTotal": "Subtotal before adjust"
            },
            "dailyDriverCar": {
                "driverInfo": "Informazioni Autista",
                "driverName": "Nome autista",
                "driverIDPermit": "Carta di identità # Carta di identità nazionale #",
                "driverPhone": "Numero di telefono",
                "companyName": "Azienda",
                "actionInfo": "Azioni",
                "date": "Data",
                "action": "Azione",
                "bookingId": "Booking ID",
                "carInfo": "Informazioni macchina",
                "permission": "Permesso",
                "licensePlate": "Numero di targa",
                "carId": "Macchina #",
                "carType": "Tipo di macchina"
            },
            "driverLoginStatus": {
                "driver": "Autisti",
                "driverIdPermit": "Carta di identità # Carta di identità nazionale #",
                "phone": "Numero di telefono",
                "username": "Username",
                "company": "Company",
                "carType": "Car type",
                "licensePlate": "License plate",
                "status": "Stato"
            },
            "bookingSummary": {
                "date": "Data",
                "operator": "Operator",
                "username": "Username",
                "action": "Azioni",
                "bookingId": "Booking ID"
            },
            "cashWallet": {
                "bankAccount": "Transferred to bank account",
                "creditWallet": "Transferred to credit wallet",
                "tipAfterCompleted": "Tip After Completed",
                "netEarning": "Net Earning",
                "referralEarning": "Referral Earning",
                "receiverAccount": "Receiver Account",
                "dateAndTime": "Date & time",
                "driverName": "Nome autista",
                "phoneNumber": "Numero di telefono",
                "userName": "Username",
                "company": "Azienda",
                "type": "genere",
                "bookingId": "Prenotazione #",
                "operator": "Operatore",
                "reason": "Ragione",
                "amount": "Importo modificato",
                "newAmount": "Nuovo saldo",
                "currency": "Moneta",
                "topUpGCashReason": "Ricarica di Gcash",
                "topUpReason": "Ricarica dalla carta di credito *{0}",
                "cashWalletReason": "Ricarica da Cash Wallet",
                "topUpMOLPayReason": "Razer Pay [ {0} ]",
                "chargedAmount": "Charge amount",
                "differentPercent": "Discount / Extra charge (%)",
                "operatorName": "Operator",
                "sendPayout": "Action",
                "sendPayoutTitle": "Resend payout email",
                "payoutReason": "{0} (Payout {1} {2})"
            },
            "creditWallet": {
                "receiverType": "Receiver Type",
                "receiverName": "Receiver Name",
                "receiverPhone": "Receiver phone number",
                "senderPhone": "Sender phone number",
                "senderName": "Sender name",
                "sentToCustomer": "Send to customer",
                "sentToDriver": "Send to driver",
                "dateAndTime": "Date & time",
                "driverName": "Nome autista",
                "phoneNumber": "Numero di telefono",
                "userName": "Username",
                "company": "Azienda",
                "type": "genere",
                "bookingId": "Prenotazione #",
                "operator": "Operatore",
                "reason": "Ragione",
                "amount": "Importo modificato",
                "newAmount": "Nuovo saldo",
                "currency": "Moneta",
                "transactionId": "ID transazione",
                "topUpGCashReason": "Ricarica di Gcash",
                "topUpReason": "Ricarica dalla carta di credito *{0}",
                "cashWalletReason": "Ricarica da Cash Wallet",
                "topUpMOLPayReason": "Razer Pay [ {0} ]",
                "topUpWalletReason": "{0} [ {1} ]",
                "chargedAmount": "Charge amount",
                "differentPercent": "Discount / Extra charge (%)",
                "cashExcessReason": "Customer must pay {0}; Cash received {1}",
                "referralEarningReason": "Customer {0} {1} completed first booking {2}",
                "referralEarningReason_2": "Customer {0} {1} completed booking {2}",
                "insurance": "Driver insurance"
            },
            "passengerWallet": {
                "dateTime": "Date & time",
                "customerName": "Customer name",
                "phoneNumber": "Phone number",
                "type": "genere",
                "bookingId": "Booking #",
                "changedAmount": "Changed amount",
                "amount": "Changed amount",
                "newBalance": "New balance",
                "currency": "Currency",
                "transactionId": "ID transazione",
                "Note": "Note",
                "credit": "Topup by credit card *{0}",
                "TnG": "Topup by TnG #{0}",
                "MOLPay": "Top up from [RazerPay - #{0}]",
                "gatewayName": "Top up from [{0} - #{1}]",
                "cashExcess": "Customer must pay {0}, Cash given {1}",
                "editBalance": "Edit Balance",
                "ride": "Ride",
                "topUp": "Top up",
                "topUpReferee": "Referral reward ({0})",
                "topUpReferrer": "New referee (#{0}): {1} ({2})"
            },
            "recipientsInfo": {
                "order": "No.",
                "orderId": "Order #",
                "name": "Recipient name",
                "phone": "Recipient phone #",
                "address": "Recipient address",
                "deliveryFee": "Delivery fee",
                "ItemValue": "Item Value (COD)",
                "status": "Status",
                "Performer": "Performer",
                "Reason": "Reason",
                "proofOfFailure": "Proof of failure",
                "referenceNumber": "Reference Number"
            },
            "dispatchHistory": {
                "performer": "Esecutore",
                "name": "Nome",
                "action": "Azioni",
                "date": "Data",
                "location": "Driver's location",
                "estimate": "Vstima veicolo da luogo di carico",
                "passenger": "Customer",
                "detachVehicle": "Detach Vehicle",
                "assignVehicle": "Assign Vehicle"
            },
            "payToDriver": {
                "driverName": "Autisti",
                "totalUnsettledTransactions": "Totale transazioni non pagate",
                "totalUnsettledAmount": "Totale importo non pagato",
                "actions": "Azioni",
                "pay": "Paga",
                "remove": "Remove",
                "clearSuccess": "The earning of selected driver was successfully cleared.",
                "clearFailed": "Failed to clear earning data of the driver. Please try again.",
                "selectDriver": {
                    "title": "",
                    "body": "Per favore seleziona l'autista da pagare..",
                    "buttonTitle": "OK"
                },
                "confirmClear": {
                    "title": "",
                    "body": "Are you sure you want to clear earning data of this driver? This action cannot be undone.",
                    "buttonTitle": "Yes",
                    "closeButtonText": "No"
                },
                "confirmPay": {
                    "body": "Are you sure you want to pay to selected driver(s)?",
                    "buttonTitle": "Yes",
                    "closeButtonText": "No"
                },
                "paymentSuccessfully": "Pagamento effettuato con successo"
            },
            "customerQuest": {
                "name": "Name",
                "phoneNumber": "Phone number",
                "supportId": "Support ID",
                "ranking": "Ranking",
                "points": "Points",
                "numberOfReservation": "Number of reservation",
                "driver": "Driver",
                "customer": "Customer",
                "firstWillWin": "First will win",
                "firstWillWinLuckyDraw": "First will win (lucky draw)",
                "largestWillWin": "Largest will win (leaderboard)",
                "qualified": "Qualified",
                "notQualified": "Not qualified",
                "userName": "User name",
                "completedDate": "Completed date",
                "isQualified": "Status",
                "ticket": "Ticket",
                "numOfComplete": "Num of completed",
                "bookingDistance": "P.O.B distance",
                "numberOfIntercity": "Number of intercity",
                "numberOfDelivery": "Number of delivery",
                "pleaseSelectEventName": "Please select event name.",
                "numberOfQualifiedSetting": "Number of qualified (setting): ",
                "numberOfActualQualified": "Number of actual qualified: ",
                "driverRating": "Driver Rating",
                "acceptanceRating": "Acceptance Rating",
                "cancellationRating": "Cancellation Rating",
                "period": "Period: "
            },
            "payoutToDriver": {
                "IFSCCode": "IFSC Code",
                "payoutID": "Payout ID",
                "driverName": "Driver",
                "driverPhone": "Phone number",
                "companyName": "Company",
                "period": "Period",
                "driverId": "ID# / National IC#",
                "cashWalletBalance": "Cash wallet balance",
                "creditBalance": "Credit balance",
                "holdAmount": "Hold amount",
                "minPayout": "Min payout",
                "enterMinimumPayoutAmount": "Enter minimum payout amount",
                "payoutAmount": "Payout amount",
                "payoutDate": "Payout date",
                "actions": "Actions",
                "pay": "Pay",
                "remove": "Remove",
                "payoutEndTime": "Payout end time",
                "clearSuccess": "The earning of selected driver was successfully cleared.",
                "clearFailed": "Failed to clear earning data of the driver. Please try again.",
                "amountKeepInDriverCashWallet": "Amount keep in driver's Cash Wallet",
                "selectDriver": {
                    "title": "",
                    "body": "Please select driver to pay.",
                    "buttonTitle": "OK"
                },
                "confirmClear": {
                    "title": "",
                    "body": "Are you sure you want to clear earning data of this driver? This action cannot be undone.",
                    "buttonTitle": "Yes",
                    "closeButtonText": "No"
                },
                "confirmPay": {
                    "body": "Are you sure you want to pay to selected driver(s)? <br> - Number of drivers: <b>%{drivers}</b> <br> - Min payout out for each driver: %{currency} <b>%{min}</b>  <br> - Hold amount: %{currency}<b> %{hold}</b> <br> - Total payout: %{currency} <b>%{total}</b>   ",
                    "buttonTitle": "Yes",
                    "closeButtonText": "No"
                },
                "paymentSuccessfully": "Payments settled successfully to driver(s)",
                "paymentCompleted": "Payout completed: <br> - Pay successfully: %{success} <br> - Failed: %{failed} <br> - Total payout successfully: %{currency} %{total}",
                "noteSelectDrivers": "Note: select drivers who have bank account to pay.",
                "sendPayout": "Send payout successful notification to email address",
                "accountNumber": "Account number",
                "iBanNumber": "IBAN number",
                "accountHoldName": "Account holder's name",
                "bankName": "Bank name",
                "selelctedDriverHasBeenPaid": "Selected driver(s) has been paid",
                "selelctedDriverHasBeenFailed": "Selected driver(s) has been failed",
                "selelctedDriverHasBeenUpdateAsInvalid": "Selected driver(s) has been updated as 'Invalid bank account'",
                "selelctedDriverHasBeenUpdateAsValid": "Selected driver(s) has been updated as 'Valid bank account'",
                "isBankAccountOwner": "Beneficiary same as driver",
                "isBankAccountOwner_true": "Yes",
                "isBankAccountOwner_false": "No",
                "beneficiaryIDIC": "Beneficiary ID# / IC#",
                "bankRelationship": "Relationship",
                "bankRelationship_1": "Spouse",
                "bankRelationship_2": "Daughter/Son",
                "bankRelationship_3": "Brother/Sister",
                "bankRelationship_4": "Relative",
                "bankRelationship_5": "Friends",
                "bankRelationship_6": "Other: {0}"
            },
            "payoutToMerchant": {
                "payoutId": "Payout ID",
                "merchantName": "Merchant Name",
                "phone": "Phone number",
                "accountNumber": "Account number",
                "iBanNumber": "IBAN number",
                "bankAccountHolder": "Account holder's name",
                "bankName": "Bank name",
                "pendingAmount": "Pending amount",
                "payoutAmount": "Payout amount",
                "payoutDate": "Payout date",
                "holdAmount": "Hold amount",
                "minPayout": "Min payout",
                "selelctedMerchantHasBeenPaid": "Selected merchant(s) has been paid",
                "selelctedMerchantHasBeenFailed": "Selected merchant(s) has been failed",
                "pay": "Pay",
                "selectMerchant": {
                    "title": "Warning",
                    "body": "Please select merchant to pay.",
                    "buttonTitle": "OK"
                },
                "confirmPay": {
                    "body": "Are you sure you want to pay to selected merchant(s)? <br> - Number of merchants: <b>%{merchants}</b> <br> - Min payout out for each driver: %{currency} <b>%{min}</b>  <br> - Hold amount: %{currency}<b> %{hold}</b> <br> - Total payout: %{currency} <b>%{total}</b>",
                    "buttonTitle": "Yes",
                    "closeButtonText": "No"
                },
                "sendPayout": "Send payout successful notification to email address",
                "HasBeenUpdateAsInvalid": "Selected merchant(s) has been updated as 'Invalid bank account'",
                "HasBeenUpdateAsValid": "Selected merchant(s) has been updated as 'Valid bank account'"
            },
            "settlementHistory": {
                "date": "Data",
                "driverName": "Nome Autista",
                "dateRange": "Intervallo di date",
                "totalTransaction": "Totale transazioni pagate",
                "paidAmount": "Totale importo pagato"
            },
            "settlement": {
                "driverName": "Autisti",
                "driverIdPermit": "Carta di identità # Carta di identità nazionale #",
                "totalBookings": "Total booking",
                "total": "Ammontare totale",
                "approved": "Approvato",
                "acct": "CONTO",
                "gross": "LORDO",
                "percent": 0.03,
                "net": "NETTO"
            },
            "documentExpiry": {
                "driverName": "Nome autista",
                "driverPhone": "Telefono dell'autista",
                "company": "Azienda",
                "documentName": "Nome del documento",
                "expiredDate": "Data scaduta",
                "status": "Stato",
                "statusStr": {
                    "NA": "N/A",
                    "expired": "Scaduto",
                    "active": "Attiva",
                    "expireSoon": "Expire soon"
                }
            },
            "referralDetail": {
                "bookingId": "Book #",
                "completedDate": "Completed Time",
                "paidBy": "Paid by",
                "bookFrom": "Booking from",
                "driverName": "Driver name",
                "driverIdPermit": "ID# / National IC#",
                "companyName": "Company",
                "Passenger": "Passenger",
                "Customer": "Customer",
                "subtotal": "Subtotal",
                "subTotalFF": "Subtotal FF",
                "subtotalWithPromo": "Subtotal with promotion",
                "referralEarning": "Referral Earning",
                "Amount": "Amount",
                "Note": "Note",
                "Currency": "Currency",
                "PercentOfSubTotal": "{0}% of subtotal",
                "max": "max {0}",
                "referrerInfo": "Driver refers customer",
                "phoneReferral": "Driver phone #",
                "driverNumber": "ID# / National IC#",
                "referralCode": "Referral code"
            },
            "referralHistory": {
                "driverName": "Driver name",
                "phoneNumber": "Phone number",
                "companyName": "Company",
                "referralCode": "Referral code",
                "commission": "comission",
                "expectedReferralEarning": "Expect referral earning",
                "activePaxTooltip": "Passeggeri che hanno riscattato il codice di riferimento dall'autista con almeno una prenotazione effettuata con successo.",
                "referredPaxTooltip": "Passeggeri che hanno riscattato il codice di riferimento dall'autista ma non hanno ancora effettuato alcuna prenotazione.",
                "activePax": "Totale passeggeri attivi",
                "referredPax": "Totale passeggeri riferiti",
                "detailFields": {
                    "passengerId": "Passenger ID",
                    "passengerName": "Nome del passeggero",
                    "firstBookingDate": "1a data di prenotazione completata",
                    "firstBookingDateGMT": "1st completed booking date (GMT)",
                    "passengerPhone": "Customer phone number",
                    "appliedDate": "Referred date",
                    "referredByDriver": "Referred by driver",
                    "driverPhone": "Driver phone number",
                    "referralCode": "Referral code"
                },
                "showDetail": "Show",
                "detail": "Detail"
            },
            "paxReferralHistory": {
                "customerName": "Customer name",
                "phoneNumber": "Phone number",
                "referralCode": "Referral code",
                "commission": "Total commission",
                "activePaxTooltip": "Passengers who have redeemed the referral code with at least one booking successfully made.",
                "referredPaxTooltip": "Passengers who have redeemed the referral code but yet to make any booking.",
                "activePax": "Total active customers",
                "referredPax": "Total referred customers",
                "detailFields": {
                    "passengerId": "Passenger ID",
                    "passengerName": "Passenger name",
                    "firstBookingDate": "1st completed booking date",
                    "firstBookingDateGMT": "1st completed booking date (GMT)"
                },
                "showDetail": "Show",
                "detail": "Detail"
            },
            "driverRefersDriver": {
                "companyName": "Company",
                "phoneNumber": "Phone number",
                "username": "Username",
                "referralCode": "Referral code",
                "referrer": "Referrer",
                "numberOfReferees": "Number of referees",
                "activeReferees": "Active referees",
                "referee": "Referee",
                "refereePhone": "Referee phone number",
                "refereeUsername": "Referee username",
                "referredDate": "Referred date",
                "activatedDate": "Activated date",
                "referrerByDriver": "Referrer",
                "referrerPhone": "Referrer phone number",
                "referralStatus": "Referral status",
                "lifeTimeBookings": "Life time bookings",
                "inReview": "In review",
                "activated": "Activated"
            },
            "driverWithdrawal": {
                "transferToEWallet": "Transferred to external e-wallet *{0}",
                "channelCode": "Channel code",
                "channelName": "Channel name",
                "reversed": "Reversed",
                "currentBalanceTooltip": "Il saldo corrente del portafoglio in contanti del conducente prima della richiesta di prelievo",
                "paymentTime": "Tempo di pagamento",
                "requestTime": "Richiedi il tempo",
                "referenceId": "Numero di riferimento",
                "transactionId": "ID transazione",
                "description": "Descrizione",
                "transferAmount": "Importo del trasferimento",
                "currency": "Currency",
                "approved": "Approvato",
                "rejected": "Respinto",
                "failed": "Failed",
                "reason": "Reason"
            },
            "penaltyCompensation": {
                "penaltyAmount": "Penale",
                "totalCollect": "Necessità di pagare in valuta locale",
                "totalCollectInUSD": "Devi pagare in euro",
                "compensationAmount": "Compensazione",
                "compensationAmountInUSD": "Compensazione (USD)",
                "penaltyReason": "Penalty Reason",
                "estimatedPrice": "Estimated price",
                "estimatedPriceInUSD": "Estimated price (USD)",
                "penaltyAmountInUSD": "Penale (USD)",
                "amount": "Amount",
                "amountInUSD": "Amount (USD)"
            },
            "imcompletePayment": {
                "bookingId": "Booking ID",
                "bookFrom": "Booking from",
                "bookingTime": "Date/Time Booking",
                "completedTime": "Date/Time Booking Completed",
                "customerName": "Customer name",
                "requestedPaymentMethod": "Requested payment method",
                "originalPaymentStatus": "Stato pagamento originale",
                "currentPaymentStatus": "Stato corrente di pagamento",
                "updatedDateTime": "Aggiornamento Data / ora",
                "totalAmt": "Total amt",
                "originalOutstandingAmount": "Consistenze originale",
                "currentOutstandingAmount": "Corrente importo in sospeso",
                "outstandingAmountPaidBy": "Consistenze A carico",
                "writeOffDebtMethod": "Outstanding amount collect by",
                "reason": "Reason",
                "customerPhone": "Phone number",
                "amountCollected": "Amount collected",
                "partialPaymentAmount": "Partial payment when drop off"
            },
            "driverDeposit": {
                "dateAndTime": "Date & time",
                "userName": "Username",
                "type": "genere",
                "newAmount": "Nuovo saldo",
                "topUpGCashReason": "Ricarica di Gcash",
                "topUpReason": "Ricarica dalla carta di credito *{0}",
                "cashWalletReason": "Ricarica da Cash Wallet",
                "topUpMOLPayReason": "Razer Pay [ {0} ]"
            },
            "refund": {
                "fullRefund": "Full refund",
                "partialRefund": "Partial refund",
                "tripId": "Trip ID",
                "route": "Route",
                "refundAmount": "Refund amount",
                "refundTime": "Refund time",
                "paymentStatus": "Payment status"
            },
            "merchant": {
                "store": "Store",
                "name": "Name",
                "phoneNumber": "Phone number",
                "bookingId": "Booking #",
                "totalOrders": "Total orders",
                "completedOrders": "Completed orders",
                "canceledOrders": "Canceled orders",
                "incidentOrders": "Incident",
                "revenue": "Revenue",
                "commission": "Commission",
                "earning": "Earning",
                "food": "Food",
                "mart": "Mart",
                "preferredMerchant": "Preferred merchant",
                "regularMerchant": "Regular merchant",
                "companyName": "Company"
            },
            "merchantCashTransactionHistory": {
                "companyName": "Company",
                "dateTime": "Date & time",
                "merchantUserName": "Merchant user",
                "merchantUserPhone": "Phone number",
                "merchantUserEmail": "Email",
                "merchantName": "Merchant",
                "transactionType": "Type",
                "bookId": "Booking #",
                "transactionId": "ID transazione",
                "operatorName": "Operator",
                "reason": "Reason",
                "amount": "Amount",
                "newBalance": "New balance",
                "currencyISO": "Currency"
            },
            "merchantCreditTransactionHistory": {
                "dateTime": "Date & time",
                "merchantUserName": "Merchant user",
                "merchantUserPhone": "Phone number",
                "merchantUserEmail": "Email",
                "merchantName": "Merchant",
                "transactionType": "Type",
                "bookId": "Booking #",
                "transactionId": "ID transazione",
                "operatorName": "Operator",
                "reason": "Reason",
                "amount": "Amount",
                "newBalance": "New balance",
                "currencyISO": "Currency"
            },
            "deleteAccountRequest": {
                "date": "Date",
                "phone": "Phone",
                "username": "Username",
                "supportId": "Support ID",
                "requestFrom": "Request from",
                "associatedApps": "Associated apps",
                "reason": "Reason"
            },
            "affiliationPayoutHistory": {
                "payoutId": "Payment ID",
                "dateTime": "Date & time",
                "payoutType": "Type",
                "accountHolderName": "Account holder's name",
                "bankName": "Bank name",
                "accountNumber": "Account number",
                "totalPayout": "Total payment (USD)",
                "viewDetails": "View details",
                "notes": "Note",
                "bookId": "Booking #",
                "transactionType": "Transaction type",
                "transactionId": "Transaction Id (auto transfer)",
                "settlement": "Settlement",
                "paymentMethod": "Type",
                "networkType": "Network type"
            },
            "thirdPartyBooking": {
                "date": "Date",
                "bookingId": "Booking ID",
                "externalId": "External ID",
                "psgPhone": "Passenger phone number",
                "psgName": "Passenger name",
                "pickupLocation": "Pickup location",
                "pickupLocationLatLong": "Pickup location lat/long",
                "dropoffLocation": "Dropoff location",
                "dropoffLocationLatLong": "Dropoff location lat/long",
                "pickupTime": "Pickup time",
                "numOfPassenger": "Num of passenger",
                "action": "Action"
            }
        }
    },
    "zoneSetting": {
        "Add": "Aggiungi",
        "Import": "Importa",
        "Name": "Nome",
        "Status": "Stato",
        "Active": "Attivo",
        "Inactive": "Inattivo",
        "Deactivate": "Disattivato",
        "Activate": "Attivato",
        "Delete": "Elimina",
        "Cancel": "Cancella",
        "Input_zone_name": "Input zone name",
        "Save_zone": "Save zone",
        "Clear_draw": "Clear draw",
        "Edit": "Modifica",
        "Delete_zone_success": "La zona é stata eliminata con successo",
        "Delete_zone_fail": "Sono intervenuti degli errori nella fase di eliminazione della zona",
        "Update_zone_fail": "Sono intervenuti degli errori nella fase di aggiornamento della zona",
        "Update_zone_success": "La nuova zona é stata aggiornata con successo",
        "Create_zone_fail": "Sono intervenuti degli errori nella fase di creazione della zona",
        "Create_zone_success": "La nuova zona é stata creata con successo",
        "Draw_zone": "Per favore disegna la zona sulla mappa",
        "Import_zones_successful": "Importazione zone avvenuta con successo",
        "Import_zones_fail": "Impossibile importare zone ",
        "DELETE_ZONE": "Elimina Zona",
        "ZONE_CONFIRM": "Vuoi eliminare la zona?",
        "Name_zone_required": "Nome e Zona sono obbligatori!",
        "Select_file": "Select file",
        "No": "No",
        "Yes": "Si",
        "Import_zones_intercity_successful": "[{0}] zone(s) has been imported successfully, [{1}] failed.",
        "Import_zones_intercity_successful_failed": "[{0}] zone(s) has been imported successfully, [{1}] failed.",
        "Flat_zone": "Flat zone",
        "Json_file": "Json file",
        "Import_zones": "Import zones",
        "timezone": "Time Zone"
    },
    "companySetting": {
        "DELETE_COMPANY": "ELIMINA AZIENDA",
        "DELETE_CONFIRM": "Vuoi eliminare l'azienda!",
        "DETAIL_COMPANY": "DETTAGLIO AZIENDA",
        "commissionValue": "Company commission",
        "connectStatus": "Stripe Connect",
        "connectStripe": "Connect",
        "stripeConnectId": "Stripe account ID",
        "loginStripe": "Dashboard",
        "disconnectStripe": "Disconnect",
        "Yes": "Si",
        "No": "no",
        "Cancel": "Cancella",
        "EDIT_COMPANY": "Modifica azienda",
        "ADD_COMPANY": "Aggiungi azienda",
        "Name": "Nome",
        "Required_fields": "Campi obbligatori",
        "Save": "Salva",
        "Actions": "Azioni",
        "Edit": "Modifica",
        "Add": "Aggiungi",
        "Show_per_page": "oggetti per pagina",
        "Page": "Pagina",
        "show_pages": "{0} - {1} of {2} mostrati",
        "Create_company_success": "La nuova aziendda é stata creata con successo",
        "Create_company_fail": "Sono intervenuti degli errori nella fase di creazione dell'azienda",
        "Update_company_success": "L'azienda é stata aggiornata con successo",
        "Update_company_fail": "Sono intervenuti degli errori nella fase di aggiornamento dell'azienda",
        "Load_company_fail": "Sono intervenuti degli errori nella fase di caricamento dell'azienda",
        "Delete_company_success": "L'azienda é stata eliminata con successo",
        "Delete_company_fail": "Sono intervenuti degli errori nella fase di eliminazione dell'azienda",
        "hideFare": "Always hide fare",
        "Broadcast_orders": "Broadcast all orders",
        "paymentMethodsApply": "Support payment method",
        "emailsNotification": "Emails Notification",
        "CopySignUpLink": "Copy sign up link",
        "operationZone": "Operation Zone",
        "delayBroadcast": "Show order after",
        "releaseTime": "Release Time",
        "advanceBookingVisibility": "Advance booking visibility",
        "advanceBookingVisibilityTooltip": "Set the number of days before the pickup date that a supplier can see the booking. This ensures that bookings are released to drivers in a timely manner, based on each supplier."
    },
    "dispatchSetting": {
        "GENERAL": "GENERALE",
        "Offer_interval": "Intervallo offerta",
        "Offer_interval_merchant": "Offer interval (merchant)",
        "Offer_interval_merchant_toolTip": "Maximum time for a merchant to accept a order",
        "Offer_interval_toolTip": "Tempo massimo affinché un conducente accetti una prenotazione",
        "soundMarketplace": "Sound",
        "sound": "Sound",
        "Auto_re_dispatch_interval_toolTip": "Tempo di pausa tra 2 round di spedizione",
        "Maximum_auto_dispatch_duration_toolTip": "Tempo massimo di invio di una prenotazione ai conducenti",
        "Seconds": "secondi",
        "Auto_re_dispatch_interval": "Intervallo di riassegnazione automatica",
        "Maximum_auto_dispatch_duration": "Massima durata assegnazione-automatica",
        "Time_out": "Tempo scaduto",
        "mins": "minuti",
        "minTimeBeforeReservation": "Non consentire al conducente di avviare la prenotazione prima di",
        "IN_ADVANCE": "IN ANTICIPO",
        "Assign_reservation_to_driver": "Assign reservation to driver",
        "Dispatcher_confirms_job_for_driver": "Dispatcher confirms reservation for online driver",
        "Dispatcher_confirms_job_for_offline_driver": "Il dispatcher conferma la prenotazione per il driver offline",
        "Broadcast_orders": "Broadcast orders to all companies",
        "Broadcast_orders_tooltip_1": "Enable this setting if you just want to broadcast all trips to all company suppliers. \n Keep in mind you need to enable broadcast mode on in each company’s profile.",
        "Minimum_book_ahead": "Prenotazione minima davanti",
        "Maximum_book_ahead": "Prenotazione massima davanti",
        "Maximum_period_recurring_oders_fleet": "Maximum period for recurring orders (fleet operator)",
        "Maximum_period_recurring_oders_corp": "Maximum period for recurring orders (corporate)",
        "Day": "Day",
        "Week": "Settimana",
        "Month": "Mese",
        "hrs": "ore",
        "Driver_cancellation_duration": "Cancellazione durata autista",
        "Mins": "mins",
        "Maximum_Day": "Il conducente per le prenotazioni massime può accettare in un giorno.",
        "Maximum_Accept_Reservation": "Il conducente per le prenotazioni massime può accettare alla volta.",
        "Local_notification_before": "Local notification before",
        "Dispatch_mode": "Modo di assegnazione",
        "Manual": "Manuale",
        "Immediate_auto_dispatch": "assegnazione-automatica immediata",
        "Delayed_auto_dispatch": "assegnazione-automatica ritardata",
        "Auto_dispatch_before_pickup_time": "Auto dispatch before pickup time",
        "Required_fields": "Campi obbligatori",
        "Shuttle": "SHUTTLE",
        "Shuttle_Dispatch_mode": "Dispatch mode",
        "Dispatch_setting_Shuttle_Dispatch_mode_tooltip": "- Manual mode: Reservation will be saved and stayed on Cue of your Command Center under “Pending” status once reservation is created. After clicking on “Update booking” at the booking detail, reservation will be dispatched to your assigned drivers or to all drivers in case of no driver assigning. It will be integrated into driver device calendar and reminder will be available. <br>- Delayed mode:Reservation will be saved and stayed on Cue of your Command Center under “Pending” status once reservation is created. Reservation will be automatically dispatched before “time value at “Process in advance”” from pick up time to the assigned driver or the nearest available one in case of no driver assigning. <br>- Immediate auto-dispatch mode: Reservation will be automatically dispatched to all drivers once reservation is created. It will be integrated into driver device calendar and reminder will be available.",
        "Save": "Salva",
        "Process_in_advance": "Trattazione in anticipo",
        "Save_dispatch_success": "L'assegnazione é stata aggiornata con successo",
        "Save_dispatch_fail": "Sono intervenuti degli errori nella fase di aggiornamento dell'assegnazione",
        "Dispatch_setting_general_tooltip": "Applica a prenotazioni su richiesta, prenotazioni in anticipo con assegnazione automatica ritardata",
        "Dispatch_setting_Assign_reservation_to_driver_tooltip": "Reservation is assigned and accepted for driver automatically when this option is enable",
        "Dispatch_setting_Driver_cancellation_duration_tooltip": "Durata é dall’ora dell’appuntamento alla cancellazione da parte dell’autista",
        "Dispatch_setting_Dispatch_mode_tooltip": "-- Modo manuale : una volta creata la prenotazione sarà nell’elenco della Centrale di Gestione Command con lo status “In corso”. Cliccando su “aggiorna prenotazione” nei dettagli della prenotazione, la prenotazione sarà assegnata agli autisti designati o a tutti gli autisti nel caso non siano stati designati degli autisti. Sarà integrata nel calendario dell’autista e un promemoria sarà disponibile. <br>- Modo Ritardato : La prenotazione sarà registrata e rimarrà nell’Elenco della Centrale di Gestione con lo status “In corso”. La prenotazione sarà assegnata automaticamente prima “valore tempo a “Processo in anticipo”” dall’ora dell’appuntamento all’autista assegnato o il primo disponibile in caso in cui non sia stata assegnata a nessun autista. <br>-Modo assegnazione automatica immediata: La prenotazione sarà automaticamente assegnata a tutti gli autisti una volta creata. Sarà integrata nel calendario dell’autista e un promemoria sarà disponibile.",
        "Notification_nearby_drivers_before_pickup_time": "Notifica ai conducenti vicini prima dell'orario di ritiro",
        "Dispatch_setting_Maximum_Day_tooltip": "This is to limit drivers from having too many reservations on the same day. Applicable for Marketplace and Intercity.",
        "Maximum_Accept_Reservation_tooltip": "The number of accepted reservations by driver cannot exceed this number. This limits the driver from accepting too many at a time. Applicable for Marketplace and Intercity.",
        "Notification_nearby_drivers_before_pickup_time_tooltip": "If a customer's reservation has not been accepted by any driver before the set time, before pickup time, the system will notify drivers nearby. This will hopefully prompt them to check marketplace to accept the booking.",
        "marketPlace": "MARKET PLACE",
        "howFarDriverSeeBookingMarketplace": "How far out the driver can see bookings in the marketplace",
        "shortNoticeOrder": "Short notice order",
        "shortNoticeOrderTooltip": "Set the time frame for bookings to be considered urgent and visible to all tiers immediately."
    },
    "voiceSMSSetting": {
        "ACCOUNT_SETTINGS": "Impostazioni conto",
        "Account_SID": "Conto SID",
        "Auth_Token": "Token Autorizzazione",
        "Application_ID": "Identità Applicazione",
        "SMS_number_1": "Numero SMS",
        "SMS_number_2": "Numero SMS 2",
        "SMS_SETTINGS": "Impostazioni SMS",
        "MASKED_PHONE_SETTINGS": "impostazioni telefono privato",
        "SMS_MARKETING_SETTINGS": "Impostazioni marketing SMS",
        "Phone_number": "Numero di telefono",
        "Save": "Salva",
        "voiceSMS": "TWILIO",
        "DRIVER_APP": "Driver App",
        "PASSENGER_APP": "Passenger App",
        "allowDriverToCallCC": "Allow Driver to call Helpdesk via:",
        "allowDriverToCallHelpdesk": "Allow Driver to call Helpdesk via:",
        "allowDriverToCallPassenger": "Allow Driver to call Passenger via:",
        "allowPassengerToCallCC": "Allow Passenger to call Helpdesk via:",
        "allowPassengerToCallHelpdesk": "Allow Passenger to call Helpdesk via:",
        "allowPassengerToCallDriver": "Allow Passenger to call Driver via:",
        "voip": "VOIP",
        "mobile": "Mobile",
        "bookingSMS": "Message template",
        "bookingSMSTemplate": {
            "name": "Name",
            "content": "Content",
            "action": "Action",
            "DELETE": "Delete Message template",
            "DELETE_CONFIRM": "Are you sure you want to delete the selected Message template?",
            "EDIT": "Edit Message template",
            "ADD": "Add Message template",
            "DETAIL": "Detail Message template",
            "Update_success": "The message template has been updated successfully",
            "Create_success": "The message template has been created successfully",
            "Delete_success": "The message template has been deleted successfully",
            "Delete_fail": "The errors occurred while deleting Message template",
            "Load_fail": "The errors occurred while loading Message template",
            "Update_fail": "The errors occurred while updating Message template",
            "Create_fail": "The errors occurred while creating Message template"
        },
        "bookingSMSModal": {
            "title": "Add SMS template",
            "save": "Save",
            "cancel": "Cancel",
            "titleMessage": "Send Message",
            "template": "Template",
            "phoneNumber": "Phone number",
            "content": "Content",
            "messageType": "Message type",
            "inbox": "Inbox",
            "sms": "SMS",
            "sendTo": "Sent to",
            "passenger": "Passenger",
            "driver": "Driver"
        }
    },
    "regularSetting": {
        "extraWaitTime": "Extra wait time",
        "extraWaitTimeTooltip": "Fee to compensate the driver when he has to wait additional time for the customer till they are onboard.",
        "extraWaitTimeTooltip2": "To set the maximum wait time, go to general settings.",
        "fareWaitTimeLater": "Fare per minute (Reservation)",
        "fareWaitTimeNow": "Fare per minute (Now)",
        "serviceType": "Service Type",
        "transport": "Transport",
        "delivery": "Delivery",
        "Fer_Stop": "Fee fer stop",
        "First_Stop": "Fee for first stop",
        "Each_Stop": "Fee for each extra stop",
        "Name": "Nome ",
        "Pay_to_driver_Cash": "Pay to Supplier/Driver (Cash booking)",
        "Last_Update": "Ultimo aggiornamento",
        "Status": "Stato",
        "Required_fields": "Campi obbligatori",
        "Yes": "Si",
        "No": "No",
        "Cancel": "Cancella",
        "Save": "Salva",
        "Actions": "Azioni",
        "Edit": "Modifica",
        "Add": "Aggiungi",
        "Show_per_page": "oggetti per pagina",
        "Page": "Pagina",
        "show_pages": "{0} - {1} of {2} mostrati",
        "DELETE_CONFIRM": "Vuoi eliminare?",
        "ADD_REGULAR_RATE": "Aggiungi tariffa normale",
        "EDIT_REGULAR_RATE": "Modifica tariffa normale",
        "DETAIL_REGULAR_RATE": "dettagli tariffa normale",
        "DELETE_REGULAR_RATE": "ELIMINA TARIFFA BASE",
        "Delete_confirm_regular_rate": "Vuoi cancellare questo oggetto?",
        "STARTING": "In partenza",
        "Starting_Now": "In partenza (Adesso)",
        "Starting_Reservation": "In partenza (Prenotazione)",
        "FIRST_DISTANCE_FEE": "tariffa primo tragitto",
        "First_distance_fee": "Primo tratto",
        "From": "Da",
        "To": "A",
        "Fee_for_first_distance": "Tariffa per prima distanza",
        "SECOND_DISTANCE_FEE": "Tariffa per secondo tragitto",
        "Second_distance_fee": "tariffa secondo tragitto",
        "AFTER_SECOND_DISTANCE": "Dopo secondo tragitto",
        "Fee_after_second_distance": "Tariffa successiva secondo tragitto",
        "PER_MINUTE": "Per minuto",
        "Fee_per_minute": "Tariffa per minuto",
        "mi": "mi",
        "hour": "Oraria",
        "MINIMUM": "MINIMO",
        "Minimum_Now": "Minimo (Adesso)",
        "Minimum_Reservation": "Minimo (Prenotazione)",
        "PAX_MDISPATCHER_CANCELLATION": "CANCELLAZIONE PASSEGGERO/ MDISPATCHER",
        "On_demand": "Su domanda",
        "Penalty": "Penalità",
        "In_advance": "In anticipo",
        "PAX_MDISPATCHER_NO_SHOW": "PASSEGGERO/ MDISPATCHER NON SI SONO PRESENTATI",
        "Driver_cancel_demand": "Driver cancel policy - on demand",
        "Driver_cancel_advance": "Driver cancel policy - in advance",
        "Assign_car_type": "Assegna tipo macchina",
        "Select_car_type": "Seleziona tipo macchina",
        "Active": "Attivo",
        "Inactive": "Inattivo",
        "Deactivate": "Disattivato",
        "DELETE_FLATE_RATE": "ELIMINA TARIFFA FISSA",
        "Delete_confirm_flat_rate": "Vuoi cancellare questo oggetto?",
        "EDIT_FLAT_RATE": "Modifica tariffa fissa",
        "ADD_FLAT_RATE": "Aggiungi tariffa fissa",
        "DELETE_FLAT_RATE": "ELIMINA TARIFFA FISSA",
        "DELETE_HOURLY_RATE": "ELIMINA TARIFFA ORARIA/ GIORNALIERA",
        "Delete_confirm_hour_rate": "DVuoi cancellare questo oggetto?",
        "EDIT_HOURLY_RATE": "EDIT HOURLY/ DAILY RATE",
        "ADD_HOURLY_RATE": "ADD HOURLY/ DAILY RATE",
        "DELETE_FLAT_ROUTE": "Elimina itinerario fisso",
        "DELETE_ROUTE_CONFIRM": "Sei sicuro di eliminare l'itinerario?",
        "EDIT_ROUTE": "EDIT ROUTE",
        "ADD_ROUTE": "Aggiungi strada",
        "Route_name": "Nome strada",
        "Route_type": "Route Type",
        "Zone_to_zone": "Zone to zone",
        "Zipcode_to_zipcode": "Zipcode to zipcode",
        "First_location": "Prima località",
        "Second_location": "Seconda località",
        "First_location_zip_note": "*Notes: You can enter many zip codes at the same time by using ',' symbol. For consecutive codes, please use '->' symbol instead of inputting one by one ( ex: 90001 -> 90099, 90101, 90102, 90103, 90174, 90185)",
        "Second_location_zip_note": "*Notes: You can enter many zip codes at the same time by using ',' symbol. For consecutive codes, please use '->' symbol instead of inputting one by one ( ex: 90001 -> 90099, 90101, 90102, 90103, 90174, 90185)",
        "SINGLE_TRIP_SETUP": "SINGLE TRIP SETUP",
        "Route_1": "Itinerario 1 (Prima località -> Seconda località)",
        "Route_2": "Itinerario 2 (Prima località -> Seconda località)",
        "Limitation": "Limitation",
        "Unlimited": "Illimitato",
        "Limited": "Limitato",
        "Time_coverage": "Time coverage",
        "Extra_time": "Extra time",
        "ROUND_TRIP_SETUP": "ROUND TRIP SETUP",
        "Round_trip": "Andata e ritorno",
        "ROUTES": "Strade",
        "Route_1_Fare": "Route 1 Fare",
        "Route_2_Fare": "Route 2 Fare",
        "Round_Trip_Fare": "Round Trip Fare",
        "Select_a_zone": "Seleziona zona",
        "minute": "minuto",
        "VIEW_REGULAR_RATE": "VEDI DETTAGLI TARIFFA BASE",
        "VIEW_FLAT_RATE": "VEDI TARIFFA FISSA",
        "VIEW_HOURLY_RATE": "VEDI TARIFFA ORARIA",
        "HOURLY_PACKAGE_RATE": "TARIFFA PACCHETTO ORARIO",
        "Package_name": "Package name",
        "Package_duration": "Package duration",
        "PackageId": "Package ID",
        "Base_rate": "Base rate",
        "Distance_coverage": "Distance coverage",
        "Extra_duration": "Extra duration",
        "Extra_distance": "Extra distance",
        "DELETE_HOURLY_PACKAGE": "ELIMINA PACCHETTO ORARIO",
        "DELETE_PACKAGE_CONFIRM": "Vuoi eliminare la tariffa pacchetto?",
        "EDIT_PACKAGE": "EDIT PACKAGE",
        "VIEW_PACKAGE": "VEDI DETTAGLI PACCHETTO",
        "ADD_PACKAGE": "ADD PACKAGE",
        "Fee_per_extra_duration": "Fee per extra duration",
        "Fee_per_extra_distance": "Fee per extra distance",
        "hours": "ora(e)",
        "days": "giorno(i)",
        "Update_flat_rate_success": "La tariffa fissa é stata aggiornata con successo",
        "Update_flat_rate_fail": "Sono intervenuti degli errori nella fase di aggiornamento della tariffa fissa",
        "Created_flat_rate_success": "La tariffa fissa é stata creata con successo",
        "Created_flat_rate_fail": "Sono intervenuti degli errori nella fase di creazione della tariffa fissa",
        "Update_flat_rate_route_success": "L'itinerario a tariffa fissa é stato aggiornato con successo",
        "Update_flat_rate_route_fail": "Sono intervenuti degli errori nella fase di aggiornamento dell'itinerario a tariffa fissa",
        "Created_flat_rate_route_success": "L'itinerario a tariffa fissa é stato creato con successo",
        "Created_flat_rate_route_fail": "Sono intervenuti degli errori nella fase di creazione dell'itinerario a tariffa fissa",
        "Loading_flat_rate_fail": "Sono intervenuti degli errori nella fase di caricamento dettagli della tariffa fissa",
        "Delete_flat_rate_route_fail": "Sono intervenuti degli errori nella fase di eliminazione dell'itinerario a tariffa fissa",
        "Delete_flat_rate_success": "La tariffa fissa é stata eliminata con successo",
        "Delete_flat_rate_fail": "Sono intervenuti degli errori nella fase di eliminazione dell'itinerario a tariffa fissa",
        "Update_intercity_rate_success": "Intercity rate has been updated successfully",
        "Update_intercity_rate_fail": "The errors occurred while updating intercity rate",
        "Created_intercity_rate_success": "Intercity rate has been created successfully",
        "Created_intercity_rate_fail": "The errors occurred while creating intercity rate",
        "Update_intercity_rate_route_success": "Intercity rate route has been updated successfully",
        "Update_intercity_rate_route_fail": "The errors occurred while updating intercity rate route",
        "Created_intercity_rate_route_success": "Intercity rate route has been created successfully",
        "Created_intercity_rate_route_fail": "The errors occurred while creating intercity rate route",
        "Loading_intercity_rate_fail": "The errors occurred while loading intercity rate detail",
        "Delete_intercity_rate_route_fail": "The errors occurred while deleting intercity rate route",
        "Delete_intercity_rate_success": "Intercity rate has been deleted successfully",
        "Delete_intercity_rate_fail": "The errors occurred while deleting intercity rate",
        "Update_regular_rate_success": "La tariffa base é stata aggiornata con successo",
        "Update_regular_rate_fail": "Sono intervenuti degli errori nella fase di caricamento della tariffa base",
        "Create_regular_rate_success": "La tariffa base é stata creata con successo",
        "Create_regular_rate_fail": "Sono intervenuti degli errori nella fase di creazione della tariffa base",
        "Update_hourly_rate_success": "Hourly rate has been updated successfully",
        "Update_hourly_rate_fail": "Sono intervenuti degli errori nella fase di aggiornamento della tariffa oraria",
        "Create_hourly_rate_success": "La tariffa oraria é stata creata con successo",
        "Create_hourly_rate_fail": "Sono intervenuti degli errori nella fase di creazione della tariffa oraria",
        "Update_hourly_rate_package_success": "Il pacchetto tariffa oraria é stato aggiornato con successo ",
        "Update_hourly_rate_package_fail": "Sono intervenuti degli errori nella fase di aggiornamento del pacchetto tariffa oraria",
        "Create_hourly_rate_package_success": "Il pacchetto tariffa oraria é stato creato con successo ",
        "Create_hourly_rate_package_fail": "Sono intervenuti degli errori nella fase di creazione del pacchetto tariffa oraria",
        "Load_hourly_rate_fail": "Sono intervenuti degli errori nella fase di caricamento dettaglidella tariffa oraria",
        "Update_hourly_status": "Sono intervenuti degli errori nella fase di aggiornamento dello status della tariffa oraria",
        "Delete_hourly_fail": "Sono intervenuti degli errori nella fase di eliminazione della tariffa oraria",
        "Delete_hourly_success": "La tariffa oraria é stata eliminata con successo",
        "Loading_regular_rate_fail": "Sono intervenuti degli errori nella fase di caricamento della tariffa base",
        "Update_regular_status_fail": "Sono intervenuti degli errori nella fase di aggiornamento status della tariffa base",
        "Delete_regular_rate_success": "La tariffa base é stata eliminata con successo",
        "Delete_regular_rate_fail": "Sono intervenuti degli errori nella fase di eliminazione della tariffa base",
        "Please_go_to_Car_Type_settings": "Per favore vai nelle impostazioni del tipo veicolo per assegnare la tariffa ai tipi di veicolo",
        "Car_type_filter": "Filtro tipo macchina",
        "Delete_package_error": "Si é verificato un errore nella fase di eliminazione del pacchetto!",
        "Pax_mDispatcher_cancellation_tooltip": "Regole sulla cancellazione applicate ai passeggeri",
        "Penalty_tooltip": "Montante a carico",
        "Pax_mDispatcher_no_show_tooltip": "Regole in caso di assenza del passeggero. Questa tariffa verrà addebitata se l’autista digiterà 'Assente' quando non trova il passeggero sul luogo dell’appuntamento.",
        "detail_route": "Detail route",
        "detail_package": "Detail Package",
        "THIRD_DISTANCE_FEE": "Third distance fee",
        "AFTER_DISTANCE_FEE": "After third distance fee",
        "Loading_delivery_rate_fail": "The errors occurred while loading delivery rate detail",
        "Update_delivery_rate_fail": "The errors occurred while updating delivery rate",
        "Update_delivery_rate_success": "Delivery rate has been updated successfully",
        "Created_delivery_rate_success": "Delivery rate has been created successfully",
        "Created_delivery_rate_fail": "The errors occurred while creating delivery rate",
        "Starting_fee": "Starting fee",
        "First_distance": "First distance",
        "Second_distance": "Second distance",
        "Third_distance": "Third distance",
        "Fee_for_second_distance": "Fee for second distance",
        "Fee_for_third_distance": "Fee for third distance",
        "Fee_after_third_distance": "Fee after third distance",
        "Assign_rate_to_car_type": "Assign rate to cartype",
        "car_type": "Assign car type",
        "PRICE_ADJUSTABLE": "Price adjustable",
        "minimum_Percent_Adjust": "Minimum % from estimated fare",
        "maximum_Percent_Adjust": "Maximum % from estimated fare",
        "Amount_Step": "Price adjustable",
        "Tax": "Tax",
        "TechFee": "Tech fee",
        "Pay_to_driver": "Pay to Supplier/Driver",
        "Pay_to_driver_tooltip": "Options to compensate drivers:\n - Fixed amount: A fixed amount the driver will receive.\n - Commission: Driver’s set commission applied to the penalty amount.",
        "Fix_amount": "Fixed amount",
        "Commission": "Commission",
        "fareFlat": "Fare flat",
        "flatRoute": "Flat route",
        "Mode": "Mode",
        "speedThresholdKM": "Speed threshold (km/h)",
        "speedThresholdMI": "Speed threshold (mi/h)",
        "timeAndMileage": "Time and mileage",
        "timeOrMileage": "Time or mileage",
        "perDistanceKM": "Per kilometer",
        "perDistanceMI": "Per mile",
        "perTime": "Per minute"
    },
    "flatRateSettings": {
        "flatZoneNote": "The zones you create here are only used to setup your flat rates."
    },
    "deliverySetting": {
        "Name": "Name",
        "Last_Update": "Last Update",
        "Status": "Status",
        "Actions": "Actions"
    },
    "sharingSetting": {
        "EDIT_RATE": "Edit street sharing rate",
        "ADD_RATE": "Add street sharing rate",
        "VIEW_RATE": "View street sharing rate",
        "Name": "Name",
        "startingFee": "Starting fare",
        "minimumFee": "Minimum fare",
        "Update_streetSharing_rate_fail": "The errors occurred while updating street sharing rate",
        "Update_streetSharing_rate_success": "Street sharing rate has been updated successfully",
        "Created_streetSharing_rate_success": "Street sharing rate has been created successfully",
        "Created_streetSharing_rate_fail": "The errors occurred while creating street sharing rate",
        "Loading_streetSharing_rate_fail": "The errors occurred while loading street sharing rate detail",
        "Delete_streetSharing_rate_success": "Street sharing rate has been deleted successfully",
        "Delete_streetSharing_rate_fail": "The errors occurred while deleting street sharing rate",
        "Delete_streetSharing_rate": "Delete street sharing rate",
        "Assign_Car": "Assign car type"
    },
    "carTypeSetting": {
        "newService": "New Service",
        "editService": "Edit Service",
        "serviceInfo": "Service Info",
        "name": "Name",
        "namePH": "Car-type name",
        "enterNumber": "Enter a number",
        "appDisplayName": "App display name",
        "shortDescription": "Short description",
        "maxPassengers": "Max passengers",
        "maxLuggage": "Max luggages",
        "vehiclePhoto": "Vehicle Photos",
        "iconShowList": "Icon to show in list",
        "imageShowWeb": "Image to show on web",
        "carPinOnMap": "Car PIN on map",
        "recommendedSize": "Recommended size:",
        "serviceSetting": "Service Settings",
        "serviceType": "Service type",
        "reservation": "Reservation",
        "onDemand": "On-demand",
        "destinationSetting": "Destination setting",
        "requireDestination": "Require destination",
        "extraDestination": "Extra destination",
        "calculatorFareMode": "Calculate fare mode",
        "hideDestinationOnDemand": "On-demand",
        "meteredFare": "Metered fare",
        "quotedFare": "Quoted fare",
        "availableZones": "Available Zones",
        "dispatchAssociation": "Dispatch Association",
        "Save": "Save",
        "Edit": "Edit",
        "Cancel": "Cancel",
        "validateName": "Please input name",
        "validateAppDisplayName": "Please input app display name",
        "validateSelectImage": "Please select an image",
        "updateSuccess": "Update successfully",
        "createSuccess": "Create successfully",
        "failApi": "Something went wrong!",
        "validateRequire": "Please specify passenger count and double-check luggage."
    },
    "carSetting": {
        "Details": "Details",
        "vehicleInfo": "Vehicle Info",
        "Documents": "Documents",
        "vehicle": "Vehicle",
        "addVehicle": "Add Vehicle",
        "detailVehicle": "Vehicle Details",
        "additionalVehicleTitle": "Additional Vehicle Information",
        "Phone_Number_Tooltip": "Enter a vehicle-specific phone number for customer use. If not provided, calls will default to the driver’s personal number. Ideal for suppliers who equip vehicles with dedicated contact phones.",
        "discardChange": "Discard changes",
        "model_name_is_required": "Model name is required",
        "DELETE_CAR": "ELIMINA VEICOLO",
        "DELETE_CONFIRM": "Vuoi eliminare il veicolo selezionato?",
        "Yes": "Si",
        "Cancel": "Cancella",
        "EDIT_CAR": "MODIFICA VEICOLO",
        "CREATE_CAR": "CREA VEICOLO",
        "Plate_Number": "Numero di targa",
        "Car_Type": "Tipo di macchina",
        "Select_car_type": "Seleziona tipo macchina",
        "Make": "Costruttore",
        "Select_car_make": "Seleziona costruttore",
        "Model": "Modello",
        "Select_a_car_model": "Seleziona un modello auto",
        "Year": "Anno",
        "Select_year": "Seleziona anno",
        "Hardware_Meter": "Hardware Meter",
        "VIN": "Seleziona anno",
        "Company": "Azienda",
        "Select_company": "Seleziona azienda",
        "Max_Passengers": "Max Passeggeri",
        "Max_Luggage": "Max Bagagli",
        "Color": "Colore",
        "License_expiry": "Patente scade",
        "Required_fields": "Campi obbligatori",
        "Save": "Salva",
        "Add": "Aggiungi",
        "Car_Model": "Modello Macchina",
        "Created_Date": "Crea data",
        "Status": "Stato",
        "Actions": "Azioni",
        "Show_per_page": "oggetti per pagina",
        "Page": "Pagina",
        "show_pages": "{0} - {1} of {2} mostrati",
        "Active": "Attiva",
        "activeVehicle": "Active vehicle",
        "Inactive": "Inattivo",
        "Edit": "Modifica",
        "Deactivate": "Disattivato",
        "Activate": "Attivato",
        "Delete": "Cancella",
        "Name": "Nome",
        "MODEL_LIST": "LISTA MODELLO",
        "Model_Name": "Nome modello",
        "Add_Model": "Aggiungi modello",
        "DELETE_CAR_MODEL": "ELIMINA MODELLO VEICOLO",
        "DELETE_CONFIRM_MODEL": "Vuoi eliminare il modello del veicolo?",
        "DELETE_CAR_MAKE": "Delete car make",
        "DELETE_CONFIRM_CAR_MAKE": "Do you want to delete this car make?",
        "EDIT_CAR_MAKE": "Modifica costruttore auto",
        "CREATE_CAR_MAKE": "Aggiungi costruttore auto",
        "Assign_Rate": "Assegna tariffa",
        "ASSIGN_RATE": "ASSEGNA TARIFFA",
        "Car_type_ID": "Tipo Macchina ID",
        "App_Display_Name": "App Display Name",
        "Affiliate_car_type": "Affiliate Car Type",
        "Regular_rate": "Tariffa normale",
        "Zone": "Zona",
        "Rate": "Valuta",
        "Zone_Rate": "Tariffa zona",
        "Flat_Fare": "Tasso fisso",
        "Flat_Delivery": "Delivery flat",
        "Select_flat_rate": "Seleziona tariffa fissa.",
        "Hourly_daily_rate": "Hourly/ Daily",
        "Dispatch_To": "Assegna a",
        "Normal_Fare": "Regolare",
        "Upload_an_image": "Carica immagine",
        "DETAIL_CAR": "DETTAGLI VEICOLO",
        "DELETE_MULTI_CAR": "Elimina vetture selezionate",
        "DELETE_MULTI_CAR_CONFIRM": "Vuoi eliminare questi veicoli, esclusivamente i veicoli inattivi possono essere eliminati?",
        "DETAIL_CAR_MAKE": "Costruttore",
        "Car_Make": "Costruttore",
        "Create_car_success": "Nuova vettura creata con successo",
        "Update_car_success": "This car has been updated successfully.",
        "Update_car_fail": "Sono intervenuti degli errori nella fase di aggiornamento del tipo di vettura",
        "Create_car_fail": "Sono intervenuti degli errori nella fase di creazione della marca della vettura",
        "Load_car_fail": "Sono intervenuti degli errori nella fase di caricamento del modello della vettura",
        "Delete_car_fail": "Sono intervenuti degli errori nella fase di eliminazione della marca della vettura",
        "Delete_car_success": "La vettura é stata eliminata con successo",
        "Change_cars_status_success": "Gli status della vettura sono stati modificati con successo",
        "Change_cars_status_fail": "Sono intervenuti degli errori nella fase di cambiamento di status della vettura",
        "Delete_cars_success": "Tutte le vetture selezionate sono state eliminate con successo",
        "Delete_cars_fail": "Sono intervenuti degli errori nella fase di cambiamento eliminazione delle vetture",
        "Update_car_type_success": "Il tipo di vettura é stato aggiornato con successo",
        "Update_car_type_fail": "Sono intervenuti degli errori nella fase di aggiornamento del tipo di vettura",
        "Load_car_type_details_fail": "Ipossibile caricare i dettagli del tipo di vettura",
        "Update_car_make_fail": "Sono intervenuti degli errori nella fase di aggiornamento della marca della vettura",
        "Update_car_make_success": "La marca della vettura é stata aggiornata con successo",
        "Create_car_make_fail": "Sono intervenuti degli errori nella fase di creazione della marca della vettura",
        "Create_car_make_success": "La nuova marca della vettura é stata creata con successo",
        "Update_car_model_fail": "Sono intervenuti degli errori nella fase di aggiornamento del modello della vettura",
        "Update_car_model_success": "Il modello della vettura é stato aggiornato con successo",
        "Create_car_model_fail": "TSono intervenuti degli errori nella fase di creazione del modello della vettura",
        "Create_car_model_success": "Il nuovo modello della vettura é stato creato con successo",
        "Loading_car_model_fail": "Sono intervenuti degli errori nella fase di caricamento del modello della vettura",
        "Delete_car_make_success": "La marca della vettura é stata eliminata con successo",
        "Delete_cat_make_fail": "Sono intervenuti degli errori nella fase di eliminazione della marca della vettura",
        "Delete_regular_rate_success": "Cancellazione Tariffa base effettuata con successo",
        "Delete_regular_rate_fail": "Cancella errore tariffa base",
        "Update_status_error": "aggiorna status errore",
        "Case_number": "Numero caso",
        "Phone_Number": "Numero di telefono",
        "ERROR_INPUT_VALIDPHONE": "Per favore inserisci un numero di telefono valido..",
        "Car_type_filter": "Filtro tipo macchina",
        "No": "No",
        "Intercity_Rate": "Intercity Rate",
        "Select_intercity_rate": "Select intercity rate",
        "Delivery_Rate": "Delivery Rate",
        "Select_delivery_rate": "Select delivery rate",
        "vehicleOwner": "Name of vehicle’s owner",
        "EDIT_CAR_COLOR": "Edit car color",
        "DETAIL_CAR_COLOR": "Car color detail",
        "CREATE_CAR_COLOR": "Add car color",
        "Car_Color": "Car color",
        "Update_car_color_fail": "The errors occurred while updating car color.",
        "Update_car_color_success": "Car color has been updated successfully.",
        "Create_car_color_fail": "The errors occurred while creating car color.",
        "Create_car_color_success": "New car color has been created successfully",
        "Delete_car_color_success": "Car color has been deleted successfully.",
        "Delete_car_color_fail": "The errors occurred while deleting car color.",
        "DELETE_CAR_COLOR": "Delete car color",
        "DELETE_CONFIRM_CAR_COLOR": "Do you want to delete this car color?",
        "sharing_Rate": "Street Sharing Rate",
        "bookingAffiliate": "Affiliate Booking.com car type",
        "streetSharing": "Street Sharing"
    },
    "operationSetting": {
        "Driver": "Autisti",
        "Search_driver_name": "Cerca nome autista",
        "Company": "Azienda",
        "License_plate": "Numero di targa",
        "Select_license_plate": "Seleziona numero di targa",
        "Shift": "Turno",
        "Zone": "Zona",
        "Required_fields": "Campi obbligatori",
        "Save": "Salva",
        "No": "No.",
        "Car_Type": "Tipo Macchina",
        "Plate_Number": "Numero di targa",
        "Unassign": "Togli l’assegnazione",
        "Please_choose_driver": "Per favore scegli un autista.",
        "Please_choose_company": "Per favore scegli un’azienda.",
        "Please_license_plate": "Per favore scegli targa.",
        "Please_choose_shift": "Per favore scegli il turno.",
        "Please_choose_zone": "Per favore scegli la zona.",
        "CONFIRM_UNASSIGN": "Vuoi togliere l’assegnamento all’autista?",
        "Assign_success": "Operazione assegnata con successo",
        "Unassign_success": "L’assegnamento é stato tolto all’autista con successo."
    },
    "paymentMethod": {
        "cash": "Cash",
        "easyPaisa": "Easy Paisa",
        "jazzCash": "Jazz Cash",
        "cashBySender": "Cash by sender",
        "cashByRecipient": "Cash by recipient",
        "personalCard": "Carta personale",
        "corporateCard": "Carta Aziendale",
        "qrCodePayment": "QR code payment",
        "directBilling": "Fatturazione diretta",
        "externalCard": "Carta esterna",
        "mDispatcherCard": "Carta mDispatcher",
        "corporatePrepaid": "Carta Prepagata",
        "fleetCard": "Carta esterna",
        "applePay": "Apple pay",
        "creditExternalCard": "Credit card",
        "googlePay": "Google Pay",
        "wallet": "Wallet",
        "BankOfGeorgia": "Bank Of Georgia",
        "tnGeWalletlet": "TnG eWallet",
        "paymentMethod_1": "Cash",
        "paymentMethod_2": "Carta personale",
        "paymentMethod_3": "Carta mDispatcher",
        "paymentMethod_4": "Carta Aziendale",
        "paymentMethod_5": "Fatturazione diretta",
        "paymentMethod_6": "Carta esterna",
        "paymentMethod_7": "Carta Prepagata",
        "paymentMethod_8": "QR code payment",
        "paymentMethod_9": "Apple pay",
        "paymentMethod_11": "Airpay",
        "paymentMethod_12": "Credit card",
        "paymentMethod_13": "Wallet",
        "paymentMethod_14": "TnG eWallet",
        "paymentMethod_16": "Cash by sender",
        "paymentMethod_17": "Cash by recipient",
        "paymentMethod_19": "Boost eWallet",
        "paymentMethod_20": "Vipps",
        "paymentMethod_21": "ZainCash",
        "paymentMethod_23": "Google Pay",
        "paymentMethod_25": "Easy Paisa",
        "paymentMethod_26": "Jazz Cash",
        "vippseWallet": "Vipps",
        "paymentMethod_credit": "Personal card",
        "paymentMethod_razerPay": "RazerPay",
        "paymentMethod_gCash": "GCash",
        "paymentMethod_zainCash": "ZainCash",
        "paymentMethod_xenDit": "Xendit",
        "paymentMethod_FIB": "FIB",
        "paymentMethod_SantimPay": "SantimPay",
        "paymentMethod_ksher": "Ksher",
        "paymentMethod_paydunya": "PayDunya",
        "paymentMethod_fawry": "Fawry",
        "paymentMethod_BankOfGeorgia": "Bank Of Georgia",
        "paymentLink": "Payment link ",
        "paymentMethod_xendit": "Xendit",
        "paymentMethod_easyPaisa": "Easy Paisa",
        "paymentMethod_jazzCash": "Jazz Cash",
        "paymentMethod_flooz": "Flooz",
        "paymentMethod_tmoney": "Tmoney",
        "paymentMethod_corporateCard": "Corporate card"
    },
    "changePassword": {
        "Change_password": "Cambia password",
        "Current_password": "Password attuale",
        "New_password": "Nuova password",
        "Confirm_password": "Conferma nuova password",
        "ERROR_INPUT_PASSWORD": "Per favore inserisci password",
        "ERROR_INPUT_VALID_PASSWORD": "PasswLa Password deve contenere almeno 8 caratteri, dei quali uno numerico ed uno speciale",
        "ERROR_INPUT_MATCH_PASSWORD": "La password non combacia. Per favore riprova.",
        "Save": "Salva",
        "Cancel": "Cancella"
    },
    "userProfile": {
        "My_account": "My account",
        "Username": "Username",
        "First_name": "Nome",
        "Last_name": "Cognome",
        "Phone_number": "Numero di telefono",
        "Email_address": "Indirizzo email",
        "Address": "Indirizzo email",
        "Save": "Salva",
        "Edit_profile": "Modifica profilo",
        "Change_password": "Cambia password",
        "ERROR_INPUT_USERNAME": "Per favore inserisci username.",
        "ERROR_INPUT_FIRSTNAME": "Per favore inserisci nome.",
        "ERROR_INPUT_VALID_EMAIL": "Per favore inserisci un’email valida.",
        "Required_fields": "Campi obbligatori"
    },
    "General": {
        "searchQueuingDriver": "Search: Driver Name, Phone Number",
        "search": "Cerca",
        "User": "Utilizzatore",
        "My_Account": "My Account",
        "Sign_out": "Disconnettiti",
        "My_site": "Il mio sito",
        "Command_center": "Centrale di gestione",
        "Notifications": "Notifica",
        "Show_all": "Mostra tutto",
        "SOS_Notifications": "Notifiche SOS",
        "You_dont_have_any_message": "Non hai alcun messaggio",
        "Mon": "Lu",
        "Tue": "Ma",
        "Wed": "Me",
        "Thu": "Gio",
        "Fri": "Ve",
        "Sat": "Sa",
        "Sun": "Dom",
        "Now": "Adesso",
        "NowComplete": "Now (Acttual Pickup Time)",
        "hrs": "ore",
        "patmentMethod_1": "Contante",
        "patmentMethod_2": "Carta personale",
        "patmentMethod_3": "mDispatch card",
        "patmentMethod_4": "Carta Aziendale",
        "patmentMethod_5": "Fatturazione diretta",
        "patmentMethod_6": "Carta esterna",
        "patmentMethod_7": "Carta Prepagata",
        "patmentMethod_8": "Codice QR",
        "patmentMethod_9": "Apple Pay",
        "patmentMethod_10": "Apple Pay",
        "patmentMethod_12": "Credit Card",
        "patmentMethod_13": "Wallet",
        "patmentMethod_14": "TnG eWallet",
        "patmentMethod_16": "Cash by sender",
        "patmentMethod_17": "Cash by recipient",
        "patmentMethod_19": "Boost",
        "patmentMethod_20": "Vipps",
        "patmentMethod_23": "Google Play",
        "patmentMethod_24": "Payment Link",
        "patmentMethod_25": "Easy Paisa",
        "patmentMethod_26": "Jazz Cash",
        "patmentMethod_ZainCash": "ZainCash",
        "patmentMethod_Xendit": "Xendit",
        "patmentMethod_FIB": "FIB",
        "patmentMethod_SantimPay": "SantimPay",
        "patmentMethod_GCash": "GCash",
        "patmentMethod_cash": "Cash",
        "patmentMethod_credit": "Personal card",
        "patmentMethod_mDispatcher": "Partner card",
        "patmentMethod_corpCredit": "Corporate Card",
        "patmentMethod_directBilling": "Direct invoicing",
        "patmentMethod_fleetCard": "External card",
        "patmentMethod_prepaid": "Corporate Prepaid",
        "patmentMethod_QRCode": "QR Code",
        "patmentMethod_applePay": "Apple Pay",
        "patmentMethod_easyPaisa": "Easy Paisa",
        "patmentMethod_jazzCash": "Jazz Cash",
        "patmentMethod_prePaid": "Corporate Prepaid",
        "patmentMethod_GooglePay": "Google Pay",
        "patmentMethod_creditCardExternal": "Credit Card",
        "patmentMethod_PaxWallet": "Wallet",
        "patmentMethod_TnGeWallet": "TnG eWallet",
        "patmentMethod_boosteWallet": "Boost",
        "patmentMethod_Vipps": "Vipps",
        "patmentMethod_exZainCash": "ZainCash",
        "patmentMethod_exGCash": "GCash",
        "patmentMethod_exPayTM": "PayTM",
        "patmentMethod_exFlooz": "Flooz",
        "patmentMethod_exTmoney": "Tmoney",
        "patmentMethod_exTelebirr": "GCash",
        "paymentMethod_exzainCash": "ZainCash",
        "paymentMethod_exXendit": "Xendit",
        "paymentMethod_exFIB": "FIB",
        "paymentMethod_exSantimPay": "SantimPay",
        "patmentMethod_exKsher": "Ksher",
        "paymentMethod_paydunya": "PayDunya",
        "paymentMethod_exfawry": "Fawry",
        "patmentMethod_exBankOfGeorgia": "Bank Of Georgia",
        "patmentMethod_exPayDunya": "PayDunya",
        "patmentMethod_21": "ZainCash",
        "patmentMethod_paymentLink": "Payment Link",
        "patmentMethod_card": "Personal card",
        "patmentMethod_corporateCard": "Corporate card",
        "patmentMethod_paxWallet": "Wallet",
        "patmentMethod_externalWallet": "External Wallet",
        "patmentMethod_googlePay": "Google Pay",
        "patmentAirlineMethod_5": "Direct invoicing",
        "patmentAirlineMethod_4": "Airline Card",
        "patmentAirlineMethod_7": "Airline Prepaid",
        "Name": "Nome",
        "Delete": "Cancella",
        "App_SOS_dialog_form_message_1": "{0} Emergenza SOS!",
        "App_SOS_dialog_form_message_2": "I need help at ",
        "App_SOS_dialog_form_message_3": "Booking: {0}, Driver: {1}, Passenger: {2}, License Plate: {3} ({4})",
        "mins": "mins",
        "Credit_Card": "Carte de crédit",
        "ALL": "Tutto",
        "NotFound": "The page you requested could not be found",
        "ServerError": "Something went wrong",
        "AccessDenied": "You don't have permission to access this module!",
        "Retry": "Retry",
        "Can_not_get_data": "Can't get data"
    },
    "shiftSettings": {
        "Add": "Aggiungi",
        "Show_per_page": "Oggetti per pagina",
        "Page": "Pagina",
        "Name": "Nome",
        "Time_Range": "Intervallo tempo",
        "Actions": "Azioni",
        "show_pages": "{0} - {1} of {2} mostrati",
        "Edit": "Modifica",
        "Delete": "Cancella",
        "EDIT_SHIFT_SETTING": "MODIFICA IMPOSTAZIONI TURNO",
        "ADD_SHIFT_SETTING": "AGGIUNGI IMPOSTAZIONI TURNO",
        "DETAIL_SHIFT_SETTING": "DETTAGLI IMPOSTAZIONI TURNO",
        "DELETE_SHIFT_SETTING": "ELIMINA IMPOSTAZIONI DEL TURNO",
        "Delete_confirm_shift_setting": "Vuoi cancellare questo oggetto?",
        "Required_fields": "Campi obbligatori",
        "Save": "Salva",
        "Cancel": "Cancella",
        "From": "Da",
        "To": "A",
        "Edit_shift_template": "Modifica Modello Turno",
        "Add_shift_template": "Aggiungi Modello Turno",
        "Detail_shift_template": "Dettaglio modello turno",
        "Delete_shift_template": "Elimina modello turno",
        "Delete_confirm_shift_template": "Vuoi cancellare questo oggetto?",
        "Working_time": "Orario di Lavoro",
        "Create_shift_settings_success": "Le impostazioni del nuovo turno sono state create con successo",
        "Create_shift_settings_fail": "Sono intervenuti degli errori nella fase di creazione delle impostazioni del turno",
        "Update_shift_settings_success": "Le impostazioni del turno sono state create con successo",
        "Update_shift_settings_fail": "Sono intervenuti degli errori nella fase di aggiornamento del turno",
        "Delete_shift_settings_success": "Le impostazioni del turno sono state eliminate con successo",
        "Delete_shift_settings_fail": "Sono intervenuti degli errori nella fase di eliminazione delle impostazioni del turno",
        "Create_shift_template_success": "Il modello del nuovo turno é stato creato con successo",
        "Create_shift_template_fail": "Sono intervenuti degli errori nella fase di creazione del modello del turno",
        "Update_shift_template_success": "Il modello del turno é stato creato con successo",
        "Update_shift_template_fail": "Sono intervenuti degli errori nella fase di aggiornamento del modello del turno",
        "Delete_shift_template_success": "Il modello del turno é stato eliminato con successo",
        "Delete_shift_template_fail": "Sono intervenuti degli errori nella fase di eliminazione del modello del turno",
        "Yes": "Yes",
        "No": "No"
    },
    "promotionSettings": {
        "Detail_campaign": "Dettaglio Campagna",
        "Edit_campaign": "Modifica Campagna",
        "Add_campaign": "Aggiungi Campagna",
        "Delete_campaign": "Elimina campagna",
        "Delete_confirm_campaign": "Vuoi eliminare la campagna selezionata?",
        "Add": "Aggiungi",
        "Corporate_list": "Corporate list",
        "permittedCorporate": "Permitted corporate list",
        "CorporateCustomers": "Corporate Customers",
        "auto_add_corporate": "Auto add to Corporate User's promotion list",
        "Show_per_page": "oggetti per pagina",
        "Page": "Pagina",
        "Name": "Nome",
        "Actions": "Azioni",
        "Private": "Privato",
        "show_pages": "{0} - {1} of {2} mostrati",
        "Edit": "Modifica",
        "Delete": "Cancella",
        "Send_to_inbox": "Send to inbox",
        "Save": "Salva",
        "Duplicate": "Duplicare",
        "Cancel": "Cancella",
        "Required_fields": "Campi obbligatori",
        "Promotion_code": "Codice Promozionale",
        "Promotion_type": "Tipo di promozione",
        "Campaign": "Campagna",
        "Type": "genere",
        "Value": "Valuta",
        "keepMinFee": "Total fare after promo must greater than minimum fare",
        "Zone": "Zones",
        "paymentMethodsApply": "Payment methods",
        "schedules": "Schedules",
        "minimumEstFareApply": "Minimum estimate total fare to apply code",
        "quantityLimitPerYear": "Maximum uses/user/year",
        "termAndCondition": "Term & condition",
        "Valid_from": "Valido da",
        "Valid_to": "Valido a",
        "Total_uses_user": "Totale Usi/Utilizzatore",
        "Released_quantity": "Quantità emesse",
        "Number_of_sending_inbox": "Numero della casella di invio",
        "Status": "Stato",
        "Activate": "Attivato",
        "Deactivate": "Disattivato",
        "Add_promotion_code": "Aggiungi codice promozionale",
        "Edit_promotion_code": "Modifica codeice promozionale",
        "Detail_promotion_code": "Dettaglio codice promozionale",
        "Delete_promotion_code": "Elimina codice promozionale",
        "Delete_confirm_promotion_code": "Vuoi eliminare il codice promozionale selezionato?",
        "New_customer": "Nuovo Cliente",
        "Budget_per_user": "Budget/user (for whole promotion time period)",
        "Budget_per_user_day": "Budget/user/day",
        "Budget_per_user_month": "Budget/user/month",
        "Budget_per_user_year": "Budget/user/year",
        "Unlimited": "Illimitato",
        "Limited": "Limitato",
        "Total_uses_per_user": "Totale usi/utilizzatore",
        "Send_an_in_box_automatically_to_new_customers": "Invia automaticamente un'email ai nuovi clienti",
        "Notes": "Note",
        "Public": "Pubblico",
        "Create_campaign_success": "La nuova campagna é stata creata con successo ",
        "Create_campaign_fail": "Sono intervenuti degli errori nella fase di creazione della campagna",
        "Update_campaign_success": "La campagna é stata aggiornata con successo",
        "Update_campaign_fail": "Sono intervenuti degli errori nella fase di aggiornamento della campagna",
        "Delete_campaign_success": "La campagna é stata eliminata con successo",
        "Delete_campaign_fail": "Sono intervenuti errori nella fase di eliminazione della campagna",
        "Loading_campaign_fail": "Sono intervenuti degli errori nella fase di caricamento della campagna",
        "Update_campaign_status_success": "Lo status della campagna é stato aggiornato con successo",
        "Update_campaign_status_fail": "Sono intervenuti degli errori nella fase di aggiornamento status della campagna",
        "Loading_promotion_code_fail": "Sono intervenuti errori nella fase di caricamento del codice promozionale",
        "Select_campaign": "Seleziona campagna",
        "Create_promotion_code_success": "Il nuovo codice promozionale é stato creato con successo",
        "Create_promotion_code_fail": "Sono intervenuti degli errori nella fase di creazione del codice promozionale",
        "Update_promotion_code_status_success": "Lo status del codice promozionale é stato aggiornato con successo",
        "Update_promotion_code_status_fail": "Sono intervenuti degli errori nella fase di aggiornamento status del codice promozionale",
        "Delete_promotion_code_confirmation": "Vuoi eliminare questi codici promozionali?",
        "Just_delete_inactive": "** Solo codici di promozione in status inattivo possono essere eliminati.",
        "Send_inbox_confirmation": "Are you sure you want to send this promo code to passenger inbox?",
        "Send_inbox_success": "Promotion code has been sent successfully.",
        "Send_inbox_fail": "Promotion code has been sent failed. Please try again!",
        "Confirmation": "Conferma",
        "Delete_promotion_codes_success": "Il codice promozionale é stato elimianato con successo",
        "Delete_promotion_codes_fail": "Sono intervenuti errori nella fase di eliminazione del codice promozionale",
        "Delete_multiple_promotion_codes_success": "Il codice promozionale é stato elimianato con successo",
        "Delete_multiple_promotion_codes_fail": "Sono intervenuti errori nella fase di eliminazione dei codici promozionali",
        "Delete_promotion_code_success": "Il codice promozionale é stato elimianato con successo",
        "Delete_promotion_code_fail": "Sono intervenuti errori nella fase di eliminazione del codice promozionale",
        "Update_multiple_promotion_code_status_success": "Gli status del codice promozionale é stato aggiornato con successo",
        "Update_multiple_promotion_code_status_fail": "Sono intercorsi errori nella fase di eliminazione status del codice promozionale",
        "Search_here": "Cerca qui",
        "Please_input_campaign_name": "Per favore inserisci nome campagna",
        "Please_input_promotion_code": "Per favore inserisci codice promozionale",
        "Please_input_promotion_code_at_least_4_characters": "Per favore inserisci almeno 4 caratteri.",
        "Please_select_campaign": "Per favore seleziona campagna",
        "Please_input_value": "Per favore inserisci valuta",
        "Please_select_date": "Per favore seleziona data",
        "Please_add_schedules": "Please add schedule",
        "Please_add_corporate": "Please add corporate",
        "Please_choose_at_least_one_customer": "Per favore scegli almeno un cliente per l’invio del messaggio.",
        "This_campaign_name_has_been_existing_on_the_system_Please_input_another_one": "Il nome di questa campagna esiste già nel sistema. Per favore inseriscine un’altra",
        "This_promotion_code_has_been_existing_on_the_system_Please_input_another_one": "Questo codice di promozione esiste già nel sistema. Per favore inseriscine un altro.",
        "Invalid_file": "Documento invalido",
        "File_size_larger_than_5Mb": "La taglia del file é maggiore di 5Mb",
        "Import_promotions_success": "Il file é stato importato con successo",
        "Import_Promotions": "Oggetti importati",
        "Number_successful_imports": "Importa oggetti",
        "Number_skipped": "Salta oggetti",
        "File": "Documento",
        "Phone_Numbers": "phone number(s)",
        "Import_Promotion_code_success": "Promotion code <b>{0}</b> has been imported successfully",
        "Import_Promotion_code_fail": "Promotion code <b>{0}</b> has been failed to import. Please try again",
        "Customer_type": "Customer type",
        "All": "Tutto",
        "Referred_customers": "Referred customers",
        "Common_customers": "Common customers",
        "Operation_zone": "Zone operative",
        "totalUsesLimitPerDay": "Maximum uses/user/day",
        "quantityLimitPerDay": "Maximum quantity/day",
        "quantityLimitPerMonth": "Quantità massima / utente / mese",
        "applyVerifiedCustomers": "Richiedi solo i clienti malesi verificati",
        "applyWhenBudgetLimit": "User can apply code till budget limit is finished",
        "applyVerifiedCustomersTooltip": "Questa promozione è applicabile per i malesi che hanno verificato l'ID nazionale e caricato la propria carta d'identità solo sul sistema",
        "valueLimitPerUse": "Maximum value/use (Apply for app v48 and above)",
        "Promotion_Services": "Service",
        "transport": "Transport",
        "shuttle": "Shuttle",
        "parcel": "Parcel",
        "food": "Food",
        "mart": "Mart",
        "intercity": "Intercity",
        "addSchedule": "Add Schedule",
        "editSchedule": "Edit Schedule",
        "date": "Date",
        "Time_range": "Time range",
        "startTime": "Start time",
        "endTime": "End time",
        "applyForOtherDays": "Apply for other days",
        "scheduleTimeOverlap": "Schedule time must not overlap with each others",
        "schedule_startDate_greater_than_endDate": "The start date is greater than the end date",
        "scheduleTimeDuplicate": "Schedule time must not duplicate with each others",
        "promoIcon": "Promo icon",
        "bannerImage": "Banner image",
        "Upload_an_image": "Upload an image",
        "Click_here_to_view": "Click here to view."
    },
    "quest_event": {
        "name": "Name",
        "userType": "Type of User",
        "carType": "Car type",
        "operationZones": "Operation zone",
        "type": "Event Type",
        "isLuckyDraw": "Lucky Draw Round",
        "start_date": "Start Date",
        "end_date": "End Date",
        "numOfQualify": "Number of Qualified",
        "event_progress": "Event Progress",
        "isActive": "Status",
        "Actions": "Actions",
        "passenger": "Customer",
        "driver": "Driver",
        "first_will_win": "First will win",
        "largest_will_win": "Largest will win (leaderboard)",
        "not_start": "Not start",
        "active": "Active",
        "closed": "Closed",
        "event_details": "Quest Details",
        "add_event": "Add Quest",
        "limited": "Limited",
        "unlimited": "Unlimited",
        "eventImage": "Event Image",
        "sharedImage": "Shared Image",
        "qualifiedImage": "Qualified Image",
        "expiredImage": "Expired Image",
        "luckyDrawImage": "Lucky Draw Image",
        "Upload_an_image": "Upload an image",
        "Required_Image": "Image Extensions (.jpg, .png, .gif)",
        "ok": "OK",
        "eventId": "Event ID",
        "passengerMetric": {
            "booking_distance": "Booking distance",
            "booking_time": "Booking time",
            "booking_amount_spent": "Booking amount spent",
            "num_of_ondemand": "Number of on-demand",
            "num_of_reservation": "Number of reservation",
            "num_of_intercity": "Number of intercity",
            "num_of_delivery": "Number of delivery",
            "num_of_referral": "Number of referral",
            "num_of_sharing": "Number of sharing",
            "topup_wallet_amount": "Topup wallet amount",
            "num_of_complete_wallet": "Number of complete booking (wallet)",
            "num_of_complete_booking_by_referee": "Number of complete booking (by referee)",
            "number_of_complete_booking": "Number of complete booking",
            "number_of_food_mart": "Number of food / mart"
        },
        "driverMetric": {
            "booking_distance": "Job distance",
            "booking_time": "Job time",
            "num_of_referral": "Number of referral",
            "num_of_complete": "Number of complete booking (unique customer)",
            "num_of_referral_driver": "Number of referral (driver)",
            "num_of_accept": "Number of accept booking",
            "num_of_receive": "Number of receive booking",
            "num_of_cancel": "Number of cancel booking by driver",
            "sum_of_rating": "Total number of rating star",
            "total_rating": "Total number of rating times",
            "earnings_amount": "Earnings Amount",
            "num_of_complete_wallet": "Number of complete booking (wallet)",
            "num_of_complete_booking_by_referee": "Number of complete booking (by referee)",
            "available_time": "Online time"
        },
        "Click_here_to_view": "Click here to view.",
        "view": "Click here to view.",
        "metricType": "Type",
        "task": "Task",
        "value": "Value",
        "quantity": "Quantity",
        "quests_list": "Quests",
        "rankImages": "Rank image (point)",
        "qualifiedPrizeOrder": "Qualified Prizes",
        "luckyDrawPrizeOrder": "Lucky Draw Prizes",
        "reward": "Item",
        "from": "From",
        "to": "To",
        "image": "Image",
        "Add_Prize": "Add",
        "bannerText": "Banner text",
        "buttonText": "Button text",
        "bannerLink": "Banner link",
        "shortLink": "Short link",
        "buttonLinkDesktop": "Button link (Desktop)",
        "buttonLinkAndroid": "Button link (Android)",
        "buttonLinkiOS": "Button link (iOS)",
        "termsURL": "Terms & Conditions Link",
        "metadataTitle": "Metadata title",
        "metadataDescription": "Metadata description",
        "isDarkMode": "Dark Mode",
        "isActive_details": "Active",
        "Required_fields": "Required fields",
        "change": "Change",
        "Please_input_name": "Please input name",
        "Please_select_date": "Please select date",
        "endDate_invalid": "End Date invalid",
        "startDate_greater_than_endDate": "The start date is greater than the end date",
        "Please_input_termsURL": "Please input Terms & Conditions Link",
        "Please_input_metadataTitle": "Please input metadata title",
        "Please_input_metadataDescription": "Please input metadata description",
        "Please_input_bannerText": "Please input banner text",
        "Please_input_buttonText": "Please input button text",
        "Please_input_bannerLink": "Please input banner link",
        "Please_input_shortLink": "Please input short link",
        "Please_input_buttonLinkDesktop": "Please input button link (Desktop)",
        "Please_input_buttonLinkAndroid": "Please input button link (Android)",
        "Please_input_buttonLinkiOS": "Please input button link (iOS) ",
        "the_range_1_to_5": "Please input value in the range 1 to 5 for %{name}!",
        "the_range_1_to_100": "Please input value in the range 1 to 100 for %{name}!",
        "task_is_empty": "Please input task for %{name}!",
        "task_limit_50_characters": "Task maximum length is 50 characters for %{name}!",
        "please_input_points": "Please input points for %{name}!",
        "please_input_value": "Please input value for %{name}!",
        "value_is_invalid": "%{name}, value must be a number",
        "please_upload_image": "Please upload image for item %{name}!",
        "rank_invalid": "Rank %{name} is invalid!",
        "rank_invalid_to": "Rank \"To\" must be a number",
        "deepLink": "Deeplink",
        "point_per_task": "Point per task",
        "points": "Points",
        "points_per_some_think": "Points per %{unit}",
        "criterias": "Criteria (optional)",
        "criterias_list": "Criteria",
        "criteriaType": "Type",
        "criteriaValue": "Value",
        "acceptance_rating": "Acceptance Rating (greater than or equal)",
        "cancellation_rating": "Cancellation Rating (less than or equal)",
        "driver_rating": "Driver Rating (greater than or equal)",
        "please_upload_image_rank": "Please upload image for rank %{name}!",
        "Update_event_success": "Quest has been updated successfully",
        "Update_event_fail": "The errors occurred while updating quest",
        "Create_event_success": "New quest has been created successfully",
        "Create_event_fail": "The errors occurred while creating quest",
        "suspend_list": "Suspend List"
    },
    "voucherSettings": {
        "Search_here": "Search here",
        "Voucher_code": "Code #",
        "Campaign": "Campaign",
        "Type": "Type",
        "Value": "Value",
        "Valid_from": "Valid From",
        "Valid_to": "Valid To",
        "isActive": "Status",
        "Activate": "Activate",
        "Deactivate": "Deactivate",
        "Actions": "Actions",
        "Active": "Active",
        "Inactive": "Inactive",
        "redeemed": "Redeemed",
        "expired": "Expired",
        "Add_Voucher_code": "Add code",
        "Add": "Add",
        "Show_per_page": "Items per page",
        "Page": "Page",
        "Name": "Name",
        "show_pages": "{0} - {1} of {2} shown",
        "Edit": "Edit",
        "Delete": "Delete",
        "Save": "Save",
        "Duplicate": "Duplicate",
        "Cancel": "Cancel",
        "Required_fields": "Required fields",
        "Detail_voucher_code": "Detail Voucher Code",
        "Delete_voucher_code": "Delete Voucher Code",
        "Confirmation": "Confirmation",
        "Delete_confirm_voucher_code": "Do you want to delete selected voucher code?",
        "Delete_voucher_code_confirmation": "Do you want to delete these voucher codes?",
        "Just_delete_inactive": "** Just only voucher code in the inactive status can be deleted.",
        "Loading_voucher_code_fail": "The errors occurred while loading voucher code",
        "Select_campaign": "Select Campaign",
        "Create_voucher_code_success": "New voucher code has been created successfully",
        "Create_voucher_code_fail": "The errors occurred while creating voucher code",
        "Update_voucher_code_status_success": "Voucher code status has been updated successfully",
        "Update_voucher_code_status_fail": "The errors occurred while updating voucher code status",
        "Delete_voucher_codes_success": "Voucher code has been deleted successfully",
        "Delete_voucher_codes_fail": "The errors occurred while deleting voucher code",
        "Delete_multiple_voucher_codes_success": "Voucher codes has been deleted successfully",
        "Delete_multiple_voucher_codes_fail": "The errors occurred while deleting voucher codes",
        "Delete_voucher_code_success": "Voucher code has been deleted successfully",
        "Delete_voucher_code_fail": "The errors occurred while deleting voucher code",
        "Update_multiple_voucher_code_status_success": "Voucher code statuses has been updated successfully",
        "Update_multiple_voucher_code_status_fail": "The errors occurred while deleting voucher code statuses",
        "Please_input_voucher_code": "Please input voucher code",
        "Please_input_voucher_code_at_least_4_characters": "Please enter at least 4 characters.",
        "Please_select_campaign": "Please select campaign",
        "Please_input_value": "Please input value",
        "Please_select_date": "Please select date",
        "Invalid_file": "File content is invalid",
        "File_size_larger_than_5Mb": "File size is larger than 5Mb",
        "Import_vouchers_success": "File has been imported successfully",
        "Import_vouchers": "Import File",
        "Number_successful_imports": "Imported items",
        "Number_skipped": "Skip items",
        "File": "File",
        "Phone_Numbers": "phone number(s)",
        "Import_voucher_code_success": "Voucher code <b>{0}</b> has been imported successfully",
        "Import_voucher_code_fail": "Voucher code <b>{0}</b> has been failed to import. Please try again",
        "All": "All"
    },
    "pointConfig": {
        "point_config": "Point config",
        "Convert_spent_amount_to_points": "Convert spent amount to points",
        "Convert_topup_amount_to_points": "Convert topup amount to points",
        "Redeem_points_to_voucher": "Redeem points to voucher",
        "others": "Other",
        "Online_Payment": "Online payment",
        "transport": "Transport",
        "parcel": "Parcel",
        "food": "Food",
        "mart": "Mart",
        "delivery": "Delivery",
        "intercity": "Intercity",
        "applyCash": "Apply for cash",
        "point": "Point",
        "vourcherName": "Name",
        "pointsToRedeem": "Points to redeem",
        "vourcherValue": "Voucher value",
        "validTime": "Valid time",
        "month": "Month(s)",
        "day": "Day(s)",
        "termAndCondition": "Terms & conditions",
        "Actions": "Actions",
        "add_voucher": "Add",
        "instructionURL": "Instruction link",
        "displayName": "Show name on super app",
        "isActive": "Active",
        "EDIT_VOUCHER": "Update Voucher",
        "DETAIL_VOUCHER": "Voucher Details",
        "CREATE_VOUCHER": "Add Voucher",
        "update_successfully": "Point config setting has been updated successfully",
        "Create_voucher_successfully": "New voucher has been created successfully",
        "Create_voucher_fail": "The errors occurred while creating voucher",
        "Update_voucher_successfully": "Voucher has been updated successfully",
        "Update_voucher_fail": "The errors occurred while updating voucher",
        "Delete_voucher_successfully": "Voucher has been deleted successfully",
        "Delete_voucher_fail": "The errors occurred while deleting voucher",
        "Confirmation": "Confirmation",
        "Delete_voucher_confirmation": "Do you want to delete this voucher?",
        "Please_enter_value_least_one": "Conversion rate is required! Set up spent amount or top-up amount to continue.",
        "Please_enter_value_is_number_spentAmount": "Please enter valid amount at \"Convert spent amount\" service \"{0}\".",
        "Please_enter_value_is_number_topUp": "Please enter valid amount at \"Convert topup amount\" method \"{0}\"."
    },
    "resetPassword": {
        "New_Password": "Invia Nuova Password",
        "New_Comfirm_Password": "Nuova conferma password",
        "Save": "Salva",
        "INFO_SUCCESS": "Un link per resettare la password ti é stato inviato per email",
        "TOKEN_EXPIRED": "Il tuo token per il reset della password é scaduto",
        "Back_To_Login_Page": "Torna alla pagina di registrazione"
    },
    "permission": {
        "editBooking": "Edit farm-out bookings",
        "companyOperator": "Company Operator",
        "Third_Party_Integration_Booking": "3rd party Integration (Booking.com)",
        "Third_Party_Integration_HolidayTaxis": "3rd party Integration (HolidayTaxis)",
        "supplierPermission": "Supplier permission",
        "Hint_edit_booking": "Allow companies/suppliers to edit bookings farmed out by the fleet owner.",
        "View": "Mostra",
        "Actions": "Azioni",
        "EditFare": "Edit Fare",
        "EditDriverEarning": "Edit Driver Earning",
        "EditTotal": "Modifica totale",
        "ImportBookings": "Import Transport Bookings",
        "ImportDeliveryBookings": "Import Delivery Bookings",
        "ShowFare": "Show Fare",
        "delete_permission": "Elimina Permesso",
        "DELETE_CONFIRM": "Do you want to delete permission!",
        "Yes": "Si",
        "No": "No",
        "Cancel": "Cancella",
        "DRIVERS": "AUTISTI",
        "Driver_list": "Lista autista",
        "Enter_to_search": "Accedi alla Ricerca",
        "Permitted_driver_list": "Lista autisti autorizzati",
        "Save": "Salva",
        "CAR": "CAR",
        "Car_list": "Lista macchina",
        "Permitted_car_list": "Lista macchine autorizzate",
        "Edit_permission": "Modifica Permesso",
        "Add_permission": "Aggiungi Permesso",
        "Detail_permission": "Dettaglio permesso",
        "Name": "Nome",
        "Driver_Car": "Driver/Car",
        "All": "Tutto",
        "Company": "Azienda",
        "By_specific": "By specific",
        "Drivers": "Autisti",
        "Cars": "Veicoli",
        "Show_per_page": "Pagina",
        "Page": "Page",
        "show_pages": "{0} - {1} of {2} mostrati",
        "Delete": "Cancella",
        "Edit": "Modifica",
        "Status": "Stato",
        "Action": "Azioni",
        "Inactive": "Inattivo",
        "Active": "Attivo",
        "Update_status_error": "aggiorna status errore",
        "All_tooltip": "All driver(s) will be displayed on Map, Cue, Drivers, Operation and Reports. All car(s) will be displayed on Car mgmt",
        "Company_tooltip": "Permitted driver(s) in this company will be displayed on Map, Cue, Drivers, Operation and Reports. Permitted car(s) in this company will be displayed on Car mgmt",
        "By_specific_tooltip": "Permitted driver(s) in this list will be displayed on Drivers, Operation. Permitted car(s) in this list will be displayed on Car mgmt",
        "Full_permissions": "Full permissions",
        "ChangeBalance": "Cambia saldo",
        "ChangeCashBalance": "Change Cash Balance",
        "TotalBalance": "Total Balance",
        "WriteOffDebt": "Write Off Debt",
        "topupPaxWallet": "Change Balance",
        "SeFleetCommission": "Set fleet commission",
        "EditFleetCommission": "Edit fleet commission",
        "EditBalance": "Edit Balance",
        "ViewHeatMap": "Visualizza mappe di calore",
        "Delete_permission_success": "The permission has been deleted successfully",
        "Delete_permission_fail": "The errors occurred while deleting permission",
        "Create_permission_fail": "The errors occurred while creating new permission",
        "Create_permission_success": "New permission has been created successfully",
        "Update_permission_fail": "The errors occurred while updating permission",
        "Update_permission_success": "The permission has been updated successfully",
        "No_action_selected": "Please tick at least one action in the action list",
        "Required_fields": "Campi obbligatori",
        "sendSMS": "Send Message (SMS)",
        "sendInbox": "Send Message (Inbox)",
        "PayToDriver": "View 'Pay to Driver'",
        "PayoutHistory": "View 'Payout History'",
        "Pay": "Pay driver",
        "ActivateDriver": "Activate Driver",
        "Export": "Export",
        "Export_csv": "Export .csv",
        "Street-sharing_rate": "Street sharing rate"
    },
    "thirdPartySettings": {
        "category": "Category",
        "Not_over_limit_subs": "You can not create more than 10 sub-locations",
        "Show_per_page": "Pagina",
        "Page": "Page",
        "show_pages": "{0} - {1} of {2} mostrati",
        "Delete": "Cancella",
        "Edit": "Modifica",
        "Status": "Stato",
        "Actions": "Azioni",
        "Action": "Azioni",
        "Inactive": "Inattivo",
        "Active": "Attivo",
        "website": "Website",
        "Check_Google_Address": "Verifica l'indirizzo google per latitudine & longitudine",
        "Delete_Category_success": "Category has been deleted successfully",
        "Delete_category_fail": "The errors occurred while deleting category",
        "availableTime": "Available time",
        "Please_choose_at_least_one_category": "Please choose at least one category",
        "Please_input_category_name": "Please input category name",
        "Create_category_success": "Category has been created successfully",
        "Create_category_fail": "The errors occurred while creating category",
        "Update_third_party_success": "La località é stata aggiornata con successo",
        "Update_category_fail": "The errors occurred while updating category",
        "All_Category": "All category",
        "Delete_Category": "Edit category",
        "Edit_Category": "Edit category",
        "Add_Category": "Add category",
        "Add_Third_Party_location": "Aggiungi",
        "Add": "Aggiungi",
        "Edit_Third_Party_location": "Inserisci localizzazione del terzo",
        "Location_Not_Found": "Località non trovata",
        "Create_third_party_success": "La località é stata creata con successo",
        "Create_third_party_fail": "The errors occurred while creating location",
        "Update_third_party_fail": "Sono intervenuti degli errori nella fase di aggiornamento della località",
        "Alias": "Alias",
        "Address": "Indirizzo",
        "Latitude": "Latitudine",
        "Longitude": "Longitudine",
        "Please_input_address": "Per favore inserisci l'indirizzo",
        "Please_input_name": "Please input location name",
        "Please_input_latitude": "Per favore inserisci latitudine",
        "Please_input_longitude": "Per favore inserisci longitudine",
        "Save": "Salva",
        "Cancel": "Cancella",
        "Required_fields": "Campi obbligatori",
        "Last_update": "Ultimo aggiornamento",
        "Username": "Username",
        "locationType": "LocationType",
        "single": "Posizione singola",
        "multiple": "Più posizioni",
        "AddMore": "Add more",
        "locationList": "Elenco di posizioni",
        "instructionLink": "Link alle istruzioni",
        "location": "Location",
        "checkOnMap": "Controlla sulla mappa",
        "Remove": "Remove",
        "placeType": "genere",
        "all": "All",
        "PU": "Pickup",
        "DO": "Drop off",
        "namePlaceholder": "ad es. Terminal 1",
        "instructionLinkPlaceholder": "Pagina HTML con istruzioni complete",
        "instructionLinkTooltip": "Inserisci un link per la pagina delle istruzioni. Il passeggero può seguire le istruzioni su questo link per trovare la posizione di ritiro.",
        "longitudePlaceholder": "108.18379690000006",
        "latitudePlaceholder": "16.0393381",
        "Error_message_lat_in_range": "La latitudine deve essere compresa fra -90.0 e 90.0",
        "Error_message_lng_in_range": "La longitudine deve essere compresa fra -180.0 e 180.0.",
        "Delete_third_party_success": "La località é stata eliminata con successo",
        "Delete_third_party_fail": "Sono intervenuti degli errori nella fase di eliminazione della località",
        "Delete_location": "Delete location",
        "Note_guide": "Note: when searching on the map, your location will be quickly marked with a pin."
    },
    "city": {
        "DELETE_CITY": "Elimina città",
        "DELETE_CONFIRM": "Vuoi eliminare la città?",
        "Yes": "Si",
        "No": "No",
        "Cancel": "Cancella",
        "EDIT_CITY": "Modifica città",
        "ADD_CITY": "Aggiungi città",
        "Name": "Nome",
        "Required_fields": "Campi obbligatori",
        "Save": "Salva",
        "Add": "Aggiungi",
        "Show_per_page": "Pagina",
        "Page": "Page",
        "show_pages": "{0} - {1} of {2} mostrati",
        "Delete": "Delete",
        "Edit": "Modifica",
        "DETAIL_CITY": "Dettaglio città",
        "Update_city_success": "The city has been updated successfully",
        "Create_city_success": "New city has been created successfully",
        "Delete_city_success": "The city has been deleted successfully",
        "cityName": "City name",
        "Service": "Service",
        "serviceTransportation": "Transportation",
        "serviceAssistant": "Assistant",
        "serviceDelivery": "Delivery",
        "isShowDriverSignUp": "Show in provider signup",
        "note_isShowDriverSignUp": "(Apply for new signup module from 4.6.46 and above)"
    },
    "jobTab": {
        "DELETE_JOBTAB": "Delete Jobs Tab",
        "DELETE_CONFIRM": "Are you sure you want to delete the selected Jobs Tab?",
        "Yes": "Yes",
        "No": "No",
        "Cancel": "Cancel",
        "EDIT_JOBTAB": "Edit Job Tab",
        "ADD_JOBTAB": "Add Jobs Tab",
        "Name": "Name",
        "Required_fields": "Required fields",
        "Save": "Save",
        "Add": "Add",
        "Show_per_page": "Items per page",
        "Page": "Page",
        "show_pages": "{0} - {1} of {2} shown",
        "Delete": "Delete",
        "Edit": "Edit",
        "DETAIL_JOBTAB": "Detail Jobs Tab",
        "Update_JOBTAB_success": "The Jobs Tab has been updated successfully",
        "Create_JOBTAB_success": "New Jobs Tab has been created successfully",
        "Delete_JOBTAB_success": "The Jobs Tab has been deleted successfully",
        "JOBTABName": "Jobs Tab name",
        "jobsLink": "Link"
    },
    "driverNote": {
        "name": "Driver note",
        "DELETE": "Delete Driver Note",
        "DELETE_CONFIRM": "Are you sure you want to delete the selected Driver Note?",
        "EDIT": "Edit Driver Note",
        "ADD": "Add Driver Note",
        "DETAIL": "Detail Driver Note",
        "Update_success": "The Driver Note has been updated successfully",
        "Create_success": "New Driver Note has been created successfully",
        "Delete_success": "The Driver Note has been deleted successfully",
        "Delete_fail": "The errors occurred while deleting driver note",
        "Load_fail": "The errors occurred while loading driver note",
        "Update_fail": "The errors occurred while updating driver note",
        "Create_fail": "The errors occurred while creating driver note"
    },
    "flightIntegration": {
        "Username": "Username",
        "provider": "Flight Provider",
        "Key": "API Key",
        "apiId": "API Id",
        "Save": "Salva",
        "Update_flight_integration_success": "The flight integration has been updated successfully",
        "Update_flight_integration_fail": "The errors occurred while updating flight integration"
    },
    "smsIntegration": {
        "provider": "SMS Provider",
        "apiToken": "API Token",
        "subAccountId": "Sub Account ID",
        "senderIdSource": "Sender ID (source):",
        "apiKey": "API Key",
        "username": "Username",
        "shortCode": "Short Code",
        "key": "Key",
        "webService": "Web service",
        "bearerToken": "Bearer token",
        "keyId": "Key ID",
        "email": "Email",
        "appKey": "App key",
        "sender": "Sender",
        "password": "Password",
        "maskedPhone": "Masked phone number",
        "senderId": "Sender ID",
        "webservice": "Web service",
        "brandname": "Brand name",
        "appSecret": "App secret",
        "consumerKey": "Consumer key",
        "requestUrl": "Request Url",
        "authId": "Auth ID",
        "authToken": "Auth Token",
        "phloId": "PHLO ID",
        "dlcNumber": "10DLC Number",
        "app_key": "App key",
        "api_key": "API key",
        "accountSID": "Account SID",
        "applicationId": "Application ID",
        "phoneNumber": "SMS number 1",
        "phoneNumber2": "SMS number 2",
        "SenderID_1": "SenderID 1",
        "SenderID_2": "SenderID 2",
        "smsMarketingNumber": "SMS makerting phone number",
        "updateSMSSuccess": "The sms integration has been updated successfully",
        "Deleted_success": "The SMS has been deleted successfully",
        "receiverCountries": "Receiver Countries",
        "error": {
            "ERROR_CREATE": "The errors occurred while creating sms",
            "COUNTRY_EXISTED": "Country already exist on system",
            "ERROR_DELETE": "Failed to delete SMS",
            "ERROR_UPDATE": "Failed to update SMS"
        },
        "identifierId": "Identifier ID"
    },
    "mDispatcher": {
        "mDispatchers": "mDispatchers",
        "Active": "Attivo",
        "Inactive": "Inattivo",
        "Deactivate": "Disattivato",
        "Activate": "Attivato",
        "Delete": "Cancella",
        "Save": "Salva",
        "Edit": "Modifica",
        "ADD_MDISPATCHER": "Aggiungi mDispatcher",
        "EDIT_MDISPATCHER": "Modifica mDispatcher",
        "Phone_number": "Numero di Telefono",
        "first_name": "Nome",
        "last_name": "Cognome",
        "email": "Email",
        "address": "Indirizzo",
        "mDispatcher_type": "Tipo mDispatcher",
        "mDispatcher_type_toolTip": "Per aggiungere altri tipi di partner, vai in Impostazioni> Tipo di partner",
        "queuing_area_toolTip": "Per aggiungere più aree di accodamento, vai al menu Area di accodamento.",
        "mDispatcher": "mDispatcher",
        "card_management": "Card Management",
        "Select_mDispatcher_type": "Seleziona tipo mDispatcher",
        "Amount": "Ammontare",
        "Percent": "Percentuale",
        "Yes": "Si",
        "Required_fields": "Campi obbligatori",
        "Cancel": "Cancella",
        "commission_type": "Tipo di commissione",
        "commission_value": "Valuta commissione",
        "Name": "Nome",
        "Phone_Number": "Numero di Telefono",
        "queuing_area": "Area Fila",
        "mdispatcher_type": "Tipo mDispatcher",
        "registered_from": "Registrato da",
        "registration_date": "Data di registrazione",
        "status": "Stato",
        "actions": "Azioni",
        "Select_queue_area": "Seleziona area fila",
        "for_new_mDipatcher_account_password_default_is_password": "Per il nuovo conto mDispatcher la password provvisoria é \"12345678\"",
        "Create_mDispatcher_success": "Il nuovo mDipatcher é stato creato con successo",
        "Create_mDispatcher_fail": "Sono intervenuti degli errori nella fase di creazione del tipo mDispatcher",
        "Load_mDispatcher_fail": "Sono intervenuti degli errori nella fase di caricamento di mDipatcher",
        "Update_mDispatcher_success": "mDipatcher é stato aggiornato con successo",
        "Update_mDispatcher_fail": "Sono intervenuti degli errori nella fase di aggiornamento di mDipatcher",
        "Update_mDispatcher_status_success": "Lo status di mDipatcher é stato aggiornato con successo",
        "Update_mDispatcher_status_fail": "Sono intervenuti degli errori nella fase di aggiornamento degli status di mDipatcher",
        "Update_mDispatcher_statuses_success": "Lo status di mDipatcher é stato aggiornato con successo",
        "Update_mDispatcher_statuses_fail": "Sono intervenuti degli errori nella fase di aggiornamento degli status di mDipatcher",
        "Delete_mDispatcher_success": "mDipatcher é stato eliminato con successo",
        "Delete_mDispatcher_fail": "Sono intervenuti degli errori nella fase di eliminazione degli mDipatcher",
        "Delete_mDispatchers_success": "mDipatcher é stato eliminato con successo",
        "Delete_mDispatchers_fail": "Sono intervenuti degli errori nella fase di eliminazione degli mDipatcher",
        "Do_you_want_to_delete_these_mDipatchers": "Vuoi eliminare questi mDispatcher?",
        "Only_mDistcher_in_the_inactive_status_can_be_deleted": "** Solo mDispatcher in status inattivo puo’ essere eliminato.",
        "No_card": "Nessuna carta trovata.",
        "Reset_Password": "Resetta password",
        "Reset_password_success": "La password di mDipatcher é stata resettata con successo",
        "Reset_password_fail": "Sono intervenuti degli errori nella fase di reset della password di mDipatcher",
        "Do_you_want_to_reset_password": "Vuoi resettare la password ?",
        "ERROR_EXISTED_PHONE": "Questo telefono esiste già nel sistema. Per favore inseriscine un altro.",
        "ERROR_INPUT_VALIDPHONE": "Per favore inserisci un numero di telefono valido..",
        "Partner_detail": "DETAGLIO TIPO  MDISPATCHER ",
        "ALERT_SELECT_ACTIVATE": "Per favore seleziona mDispatcher per attivare.",
        "ALERT_SELECT_DEACTIVATE": "Per favore seleziona mDispatcher per disattivare",
        "CONFIRM_DEACTIVATE_MULTI": "Vuoi disattivare questi mDispatchers?",
        "CONFIRM_ACTIVATE_MULTI": "Vuoi attivare questi mDispatcher?",
        "ERROR_COMMISSION_NUMBERS": "Per favore inserisci un numero valido.",
        "No_Support": "L'aggiunta di una nuova carta non é supportata in quest'area",
        "Add": "Aggiungi",
        "Default_zone": "Default zone",
        "required_email_payment": "The email of partner are required. Please update profile to continue.",
        "titleGuestQR": "Partner Guest-QR Link",
        "questQRContent": "Your personalized partner booking link is here! Display it prominently in bars, hotel receptions, guest rooms, and on your website—anywhere your customers might need a ride or delivery. Consider designing standees with your unique QR code, allowing customers a quick and easy scan to book their journey!",
        "View_Guest_QR": "View Guest QR"
    },
    "mDispatcherType": {
        "Name": "Nome ",
        "Required_fields": "Campi obbligatori",
        "Save": "Salva",
        "Cancel": "Cancella",
        "No": "No",
        "Add": "Aggiungi",
        "Show_per_page": "Pagina",
        "Page": "Pagina",
        "show_pages": "{0} - {1} of {2} mostrati",
        "Delete": "Delete",
        "Edit": "Modifica",
        "DELETE_MDISPATCHER_TYPE": "Elimina tipo mDispatcher ",
        "DELETE_CONFIRM": "Sei sicuro di voler eliminare tipo mDispatcher?",
        "Yes": "Yes",
        "EDIT_MDISPATCHER_TYPE": "Modifica tipo mDispatcher ",
        "ADD_MDISPATCHER_TYPE": "Aggiungi tipo mDispatcher",
        "DETAIL_MDISPATCHER_TYPE": "DETAGLIO TIPO  MDISPATCHER ",
        "Actions": "Azioni",
        "Delete_mDispatcherType_success": "Il tipo mDispatcher é stato eliminato con successo",
        "Delete_mDispatcherType_fail": "Sono intervenuti degli errori nella fase di eliminazione del tipo mDispatcher ",
        "Load_mDispatcherType_fail": "Sono intervenuti degli errori nella fase di caricamento del tipo mDispatcher",
        "Create_mDispatcherType_success": "Il nuovo tipo mDispatcher é stato creato con successo ",
        "Create_mDispatcherType_fail": "Sono intervenuti degli errori nella fase di creazione del tipo mDispatcher",
        "Update_mDispatcherType_success": "mDispatcher aggiornato con successo",
        "Update_mDispatcherType_fail": "Sono intervenuti errori nella fase di aggiornamento del tipo mDispatcher "
    },
    "queuingArea": {
        "queuing_area_info": "Queue area info",
        "queuingDrivers": "Active drivers",
        "Name": "Nome",
        "Required_fields": "Campi obbligatori",
        "Save": "Salva",
        "Cancel": "Cancella",
        "Yes": "Si",
        "No": "No",
        "Add": "Aggiungi",
        "Show_per_page": "oggetti per pagina",
        "Enter_to_search": "Enter to search",
        "Page": "Pagina",
        "show_pages": "Mostra {0} - {1} di {2}",
        "Delete": "Cancella",
        "Edit": "Modifica",
        "Active": "Attivo",
        "Inactive": "Inattivo",
        "Deactivate": "Disattivato",
        "Activate": "Attivato",
        "drivers": {
            "order": "Order",
            "Driver_name": "Driver name",
            "Phone_Number": "Phone number",
            "driverLicenseNumber": "License plate",
            "Car_Type": "Car type",
            "checkInTime": "Check in time",
            "duration": "Duration",
            "timestampScore": "Punteggio data / ora",
            "changeOrderDriver": "Cambiare ordine",
            "detailOrderDriver": "Driver order",
            "Yes": "Yes",
            "No": "No",
            "insert": "Inserire",
            "refresh": "ricaricare",
            "insertDriver": "Inserisci un driver nella coda",
            "orderDriver": "Ordine #",
            "reason": "Reason",
            "removeDriver": "Remove Driver from Queue",
            "removeDriverConfirm": "Vuoi rimuovere questo driver dalla coda?",
            "driverNotStay": "Il driver non si trova nell'area della coda. Per favore riprova più tardi.",
            "overOrder": "The order # cannot be greater than the total number of drivers in the queue.",
            "noDriverFound": "No driver found",
            "driverUpdateErrRs": "The driver is not in the queue area. Please try again later.",
            "orderUpdateErrRs": "The order # cannot be greater than the total number of drivers in the queue.",
            "removeDriverSuccess": "The driver has been successfully removed from the queue area.",
            "removeDriverFail": "An error occurred while removing driver from the queue area.",
            "operationInsertFailRs": "The driver does not exist or is unavailable.",
            "versionInsertFailRs": "Per applicare questa funzione, il driver deve aggiornare la sua app driver alla versione più recente",
            "queueInsertFailRs": "The queue does not exist or the driver is not in the queue area. Please try again later.",
            "vehicleInsertFailRs": "The queue does not support the vehicle type of driver.",
            "orderInsertFailRs": "The order # cannot be greater than the total number of drivers in the queue.",
            "positionInsertFailRs": "An error occurred while inserting driver into the queue area.",
            "insertDriverSuccess": "The driver has been successfully inserted to the queue area.",
            "changeOrderSuccess": "The driver's position has been successfully changed.",
            "insertDriverFail": "An error occurred while inserting driver from the queue area.",
            "changeOrderFail": "An error occurred while changing driver's position from the queue area.",
            "selectToInsert": "Search: Driver name, Phone number",
            "updateBy": "Updated by operator",
            "changeOrder": "Change order",
            "remove": "Remove"
        },
        "keepDriverPlaceCancelNoShow": "Conservare il posto di guida quando la prenotazione viene cancellata / no-show",
        "keepDriverPlaceUnavailable": "Mantieni il posto di guida quando il conducente non è disponibile o offline",
        "range": "Queue range",
        "queueRangeTooltip": "Questo è il modo in cui i conducenti visualizzeranno il loro numero di posizione in coda sull'app driver. Ad esempio:\nImpostazione dell'intervallo di coda su 1: i conducenti vedranno la loro posizione in coda come un singolo numero: 1, 2, 3, 4, ecc.\nImpostazione dell'intervallo di coda su 5: i conducenti vedranno la loro posizione in coda come un intervallo: 1-5, 6-10, 11-15, 16-20, ecc.\nImpostazione dell'intervallo di coda su 10: i conducenti vedranno la loro posizione in coda come un intervallo: 1-10, 11-20, 21-30, 31-40, ecc. ",
        "queueCanceledTooltip": "1. In caso di annullamento o mancata presentazione del passeggero, il conducente rimarrà nella posizione più alta se il conducente ritorna in coda entro il tempo stabilito.\n2. La posizione del conducente sarà considerata prioritaria se il conducente ritorna in coda entro il tempo stabilito dopo aver completato una prenotazione di breve durata ",
        "DELETE_QUEUING_AREA": "Elimina area di fila",
        "DELETE_CONFIRM": "Vuoi eliminare l'area di fila?",
        "EDIT_QUEUING_AREA": "Modifica area fila",
        "ADD_QUEUING_AREA": "Aggiungi area fila",
        "DETAIL_QUEUING_AREA": "DetDettaglio area di fila",
        "Queuing_area": "Area Fila",
        "Address": "Indirizzo",
        "Enter_a_location": "Inserisci una località",
        "Entered_Address_is_incorrect": "Il sistema non puo’ determinare dove ti trovi . Per favore utilizza l’indirizzo suggerito da Google",
        "Not_supported_zone": "Questa area fila non é in zona supportata",
        "Active_mDispatcher_list": "Active mDispatcher list",
        "Queue_list": "Lista fila",
        "Allow_radius": "Consenti area",
        "Min_drivers_required": "# Min autisti richiesto",
        "Open_queuing_area": "Apri area fila",
        "Auto_dispatch": "Auto dispatch to drivers in queue (no need to use Partner app)",
        "Active_car_list": "Attiva lista macchina",
        "mDispatcher": "mDispatcher",
        "Google_Address": "Indirizzo Google",
        "Status": "Stato",
        "Update_status_error": "aggiorna status errore",
        "driver_checkin_area": "Area check-in del conducente",
        "EDIT_POLYGONS": "Modifica poligoni",
        "pax_pickup_area": "Area di raccolta passeggeri",
        "pickup_area": "Area di raccolta",
        "your_chosen_polygon": "Il poligono scelto",
        "other_queues": "Other queues",
        "operation_zones": "Operation zones",
        "delete_pickup_area_confirm": "Sei sicuro di voler eliminare questa area di prelievo?",
        "delete_pickup_area": "Seleziona l'area di check-in del conducente.",
        "please_choose_driver_check_in_area": "Scegli l'area di prelievo.",
        "please_choose_pick_up_area": "Please choose pick-up area.",
        "Load_queuing_area_fail": "Sono intervenuti degli errori nella fase di caricamento dell'area di fila",
        "Create_queuingArea_success": "Area di fila aggiornata con successo",
        "Update_queuingArea_success": "Area di fila aggiornata con successo",
        "Delete_queuingArea_success": "L'area di accodamento è stata eliminata correttamente",
        "Delete_queuingArea_fail": "Si sono verificati errori durante l'eliminazione dell'area di accodamento",
        "Create_queuingArea_fail": "Si sono verificati errori durante la creazione dell'area di accodamento",
        "Load_queuingArea_fail": "Si sono verificati errori durante il caricamento dell'area di accodamento",
        "Update_queuingArea_fail": "Si sono verificati errori durante l'aggiornamento dell'area di accodamento"
    },
    "appBanner": {
        "Save": "Salva",
        "Pax_app": "Pax app",
        "Apply_to": "Apply to",
        "Please_select_view": "Please select view",
        "Select_Type": "Please select type of banner",
        "Home_view": "Home view",
        "Other_view": "Other view",
        "Banner_1": "Banner #1",
        "Upload_an_image": "Nessuna immagine selezionata",
        "Action": "azione",
        "NA": "N/A",
        "Link_to_website": "Link to website",
        "Make_a_call": "Make a call",
        "Send_an_email": "Send an email",
        "Banner_2": "Banner #2",
        "Banner_3": "Banner #3",
        "Driver_app": "Driver app",
        "pax": "Passgener App",
        "driver": "Driver App",
        "Name": "Name",
        "viewType": "genere",
        "status": "Stato",
        "image": "Image",
        "html": "HTML",
        "Delete_Banner": "Delete banner",
        "Delete_confirm_banner": "Are you sure you want to delete the selected banner?",
        "Delete_banner_success": "Banner has been deleted successfully",
        "Delete_banner_fail": "The errors occurred while deleting banner",
        "edit_banner": "Edit Banner",
        "detail_banner": "Detail Banner",
        "create_banner": "Add Banner",
        "note_1": "* The file extension must be .jpg, .jpeg, .png, .gif and less than 8MB.",
        "note_2": "* Images should have a 16:9 aspect ratio.",
        "note_3": "* Other views including Inbox, Refer friend, Receipt, My booking, Promo code, Add Credit card/Payment methods, Profile, Waiting for process/Confirmed reservation, Completed view.",
        "Update_app_banner_success": "App banner has been updated successfully",
        "Update_app_banner_fail": "Failed to update the app banner!",
        "Create_app_banner_success": "App banner has been created successfully",
        "Create_app_banner_fail": "Failed to create the app banner!",
        "Note": "Please use the file in .png format (transparent background, sized 400:200px) for better app performance",
        "Limit_size_upload": "The file size must be less than 8Mb.",
        "display_duration": "Display duration",
        "playback_settings": "Playback settings",
        "display_banner_again_after": "Display banners again after",
        "stop_display_banner_after": "Stop display banner after",
        "enable_playback": "Enable playback",
        "Update_playback_success": "Playback settings has been updated successfully",
        "Update_playback_fail": "Failed to update the playback settings!",
        "language": "Language",
        "single": "Single Language",
        "multi": "Multi Languages",
        "es-ES": "Spanish",
        "en-US": "English",
        "fr-FR": "French",
        "id-ID": "Bahasa indonesia",
        "vi-VN": "Vietnamese",
        "ur-PK": "Urdu",
        "ms-MY": "Malaysia",
        "ar-AR": "Arabic",
        "nb-NO": "Norwegian",
        "en-GB": "English (United Kingdom)",
        "am-ET": "Amharic",
        "ro-RO": "Romanian",
        "version": "App Version",
        "comparisonVersionApp": {
            "is": "Is",
            "is_not": "Is not",
            "is_one_of": "Is one of",
            "is_not_one_of": "Is not one of",
            "greater_or_equal": "Greater or equal",
            "less_or_equal": "Less or equal"
        },
        "platform": "Platform",
        "platformItem": {
            "all": "All",
            "iOS": "iOS",
            "android": "Android"
        },
        "userStatus": "User status",
        "Active": "Active",
        "Inactive": "Inactive",
        "in_review_completed": "In review (profile completed)",
        "in_review_incomplete": "In review (profile incomplete)",
        "in_review_inProgress": "In review (profile in progress)",
        "zones": "Zones"
    },
    "reservation": {
        "name": "Prenotazione",
        "company": "Azienda",
        "carType": "Tipo di macchina",
        "licensePlate": "Numero di targa: %{name}",
        "driver": "Autista: %{name}",
        "showAllDrivers": "Mostra tutti gli autisti",
        "available": "Indisponibile",
        "reserved": "Riservato",
        "search_holder": "Azienda, Nome autista, Tipo vettura, Targa",
        "all": "Tutti",
        "today": "Oggi",
        "thisWeek": "Questa settimana",
        "day": "Giorno",
        "week": "Settimana",
        "bookingID": "Booking ID",
        "tripId": "Trip ID",
        "pickupTime": "Orario dell’appuntamento",
        "pickupLocation": "Luogo dell’appuntamento",
        "monday": "Lunedi'",
        "tuesday": "Martedi'",
        "wednesday": "Mercoledi'",
        "thursday": "Giovedi'",
        "friday": "Venerdi'",
        "saturday": "Sabato",
        "sunday": "Domenica",
        "overlapped": "Sovrapposto",
        "noData": "No data was found.",
        "bookingDetails": "Dettagli prenotazione",
        "reservedHint": "La prenotazioni sono confermate",
        "availableHint": "Non ci sono prenotazioni confermate",
        "overlappedHint": "Due o piu' prenotazioni confermate sono sovrapposte"
    },
    "dashboard": {
        "API": "API",
        "cc": "CC",
        "dmc": "Booking board",
        "streetSharing": "Street-Sharing",
        "kiosk": "Kiosk",
        "mDispatcher": "mDispatcher",
        "paxApp": "Pax App",
        "webBooking": "Web Booking",
        "carHailing": "Car-Hailing",
        "acceptance": "Acceptance",
        "booking_com": "Booking.com",
        "completed": "Completed",
        "noShow": "No Show",
        "canceled": "Canceled",
        "incident": "Incident",
        "rate1": "1 Star",
        "rate2": "2 Stars",
        "rate3": "3 Stars",
        "rate4": "4 Stars",
        "rate5": "5 Stars",
        "Export_to_Excel": "Export to Excel",
        "Profit": "Profitto",
        "day_change": "Daily change",
        "week_change": "Weekly change",
        "month_change": "Monthly change",
        "Booking_requests": "Booking requests",
        "Complete_bookings": "Completed bookings",
        "New_customers": "New customers",
        "Booking_dashboard": "Booking board",
        "others": "Altro",
        "Revenue_booking": "Profit & Finished Booking",
        "Profit_finish_booking": "Profit & Finished Booking",
        "Booking_request": "Booking request",
        "Finished_booking": "Finished booking",
        "failed": "Failed",
        "partialCompleted": "Partial Completed",
        "Finished_booking_char_header": "Finished booking ({0})",
        "Bookings": "Bookings ({0})",
        "Ratings": "Ratings ({0})",
        "Daily": "Daily",
        "Weekly": "Weekly",
        "Monthly": "Monthly",
        "Total_customer": "Total customer {0}",
        "ProfitToolTips": "The money fleet makes after excluding all expenses",
        "RevenueToolTips": "The money fleet actually receives during selected period, including promotion, deductions for drivers, tax, etc.",
        "ProfitChangeToolTips": "The percentage of change in the fleet's profits compared to previous period",
        "BookingRequestsToolTips": "Total number of on-demand bookings and reservations which are created during selected period",
        "PercentageOfAcceptanceToolTips": "The percentage of accepted bookings during selected period",
        "BookingChangeHint": "The percentage of change in created bookings compared to previous period",
        "CompletedBookingHint": "Total number of on-demand bookings and reservations which are completed during selected period",
        "PercentageOfSatisfactionHint": "The percentage of satisfaction during selected period",
        "CompleteBookingDailyChangeHint": "The percentage of change in completed bookings compared to previous period",
        "NewCustomersHint": "Total number of new customers registered during selected period",
        "CustomerChangeHint": "The number and percentage of change in customers compared to previous period",
        "TotalCustomer": "Total number of customers in system",
        "ProfitAndFinishedBookingHint": "Profit & finished bookings of fleet during selected period",
        "BookingRequestHint": "Total number of on-demand bookings and reservations which are created during selected period",
        "FinishedBookingChartsHint": "Total number of on-demand bookings and reservations which are closed during selected period, including cancel, no show, incident, and completed",
        "Customer_count": "{0} customer count",
        "Revenue": "Revenue"
    },
    "referral": {
        "first_package": "1st package (i.e the first completed booking of Pax)",
        "driver_refers_to_pax": "Driver refers to Passengers",
        "driver_will_get": "Driver will get",
        "money": "Money",
        "amount": "Amount",
        "percent": "Percent",
        "settlement": "Commission (% subtotal amount of each completed booking)",
        "pax_will_get": "Pax will get",
        "promotion_code": "Promotion Code",
        "select_a_promotion": "Select a promotion code",
        "maximum": "Maximum",
        "second_package": "2nd package",
        "from": "From",
        "to": "To",
        "After_2nd_package": "After 2nd package",
        "from_end_value_of_above_package": "From value of the last above package + 1",
        "Expiration_date": "Expiration date",
        "Never": "Never",
        "Customized_date": "Customized date",
        "limited": "Limited",
        "unlimited": "Unlimited",
        "Valid_from": "Valid From",
        "Valid_to": "Valid to",
        "Invitation_limit": "Invitation limit/user",
        "Status": "Stato",
        "Active": "Active",
        "Inactive": "Inactive",
        "note": "* Please note that once the campaign is active, any update on reward values will only affect new customers.",
        "Required_fields": "Required fields",
        "refferal_update_successfully": "Referral setting has been updated successfully",
        "distributeIncentiveToDriver": "Distribute incentive to driver",
        "Immediate": "Immediate",
        "Periodic": "Periodic (Manually)",
        "Period": "Period",
        "startDate": "Start date",
        "nextDistributionDate": "Next distribution date",
        "oneMonth": "1 month",
        "threeMonth": "3 months",
        "sixMonth": "6 months",
        "passenger_refers_to_pax": "Passenger refers to Passengers",
        "referer_will_get": "Referer will get",
        "referee_will_get": "Referee will get",
        "promo": "Promotion",
        "Pax_invitation_limit": "Invitation limit/user",
        "promoPaidBy": "Promotion paid by",
        "fleet": "Fleet",
        "driver": "Driver",
        "promoCode": "Promotion code",
        "isSendInbox": "Send inbox",
        "isDefaultCode": "Auto apply on app",
        "autoApplyOnWeb": "Auto apply on web",
        "isDefaultCode_hint": "Add promotion code to customer's list and auto apply to booking when customer creates order",
        "Actions": "Actions",
        "add_promoCode": "Add",
        "Send_an_in_box_automatically_to_new_customers": "Send inbox to customer",
        "set_default_promo_code": "Auto apply on app",
        "EDIT_PROMO_CODE": "Edit promotion code",
        "DETAIL_PROMO_CODE": "Promotion code details",
        "CREATE_PROMO_CODE": "Create promotion code",
        "DELETE_PROMO_CODE": "Delete promotion code",
        "DELETE_CONFIRM_PROMO_CODE": "Do you want to delete this promotion code?",
        "ERROR_INPUT_SOURCE": "Please choose at least one."
    },
    "upload_document": {
        "NO_FILE_CHOSEN": "No file chosen",
        "Limit_size_upload": "File size exceeds the limit allowed {0}Mb."
    },
    "payment_info": {
        "member_since": "Member since",
        "package_name": "Package name",
        "last_payment": "Last payment",
        "credit_card": "Credit card",
        "last_digits": "Last 4 digits",
        "default": "Default",
        "add_new_card": "Aggiungi carta",
        "history": "History",
        "transaction_date": "Transaction date",
        "transaction_id": "ID transazione",
        "billing_cycle": "Billing cycle",
        "amount": "Amount",
        "payment_history": "Billing history",
        "card_information": "Card information",
        "card_holder": "Card holder",
        "card_number": "Card number",
        "expiration_date": "Expiration date",
        "cvv": "CVV"
    },
    "search_by": {
        "group_id": "Group ID",
        "booking_id": "Prenotazione #",
        "recurring_id": "Recurring #",
        "ExternalID": "External #",
        "tripId": "Trip #",
        "driver_name": "Nome autista",
        "driver_phone_number": "Numero di telefono",
        "license_plate": "Numero di targa",
        "vehicle": "Veicolo",
        "operator": "Operatore",
        "passenger_email": "Email passeggero",
        "passenger_name": "Nome passeggero",
        "passenger_phone_number": "Numero di telefono del passeggero",
        "destination": "Destinazione",
        "pick_up": "Indirizzo di partenza",
        "flight_number": "Volo #",
        "book_from": "Prenotato da",
        "note": "Note",
        "email": "Email",
        "phone": "Telefono"
    },
    "withdrawal": {
        "transferToEWallet": "Transferred to external e-wallet *{0}",
        "channelCode": "Channel code",
        "channelName": "Channel name",
        "reversed": "Reversed",
        "requestTime": "Richiedi il tempo",
        "referenceId": "Numero di riferimento",
        "driverName": "Driver name",
        "phoneNumber": "Phone number",
        "company": "Company",
        "description": "Descrizione",
        "requestAmount": "richiesta importo",
        "transferAmount": "Importo del trasferimento",
        "currentBalance": "Bilancio corrente",
        "currency": "Currency",
        "action": "Action",
        "approve": "Approve",
        "reject": "Reject",
        "transferToBankAccount": "Trasferito sul conto bancario *{0}",
        "transferToWingBank": "Transferred to Wing bank ID *{0}",
        "transferToCreditWallet": "Trasferito sul portafoglio di credito",
        "reason": "Reason",
        "search": "Driver name, phone, company, transaction ID, reference #",
        "message": {
            "approve": "Sei sicuro di voler pagare a questo driver un importo di {0}?",
            "approveMulti": "Sei sicuro di voler pagare i driver selezionati?",
            "approveSuccess": "Selected request has been paid",
            "approveFail": "Selected request was not successful. Please try again",
            "approveMultiSuccess": "{0} su {1} richieste selezionate sono state pagate",
            "approveMultiFail": "{0} su {1} richieste selezionate non hanno avuto esito positivo. Per favore riprova",
            "buttonConfirmTitle": "Yes",
            "closeButtonText": "No",
            "pleaseInputReason": "Please input reason",
            "rejectSuccess": "La richiesta del conducente è stata respinta",
            "rejectFail": "Impossibile rifiutare la richiesta selezionata."
        },
        "getDataFailure": "Can't get data",
        "rejectRequest": "Reject request"
    },
    "dynamicSurcharge": {
        "operationZone": "Operation zone",
        "activeSurcharge": "Active surcharge",
        "surcharge": "Surcharge",
        "surchargeName": "nome supplemento",
        "parameter": "Parametro",
        "pickupPoint": "Pickup zone",
        "dropOffPoint": "Drop-off zone",
        "delete_surcharge_success": "Surcharge has been deleted successfully",
        "delete_surcharge_fail": "The errors occurred while deleting surcharge",
        "update_surcharge_fail": "The errors occurred while updating surcharge",
        "update_surcharge_success": "Surcharge has been updated successfully",
        "create_surcharge_fail": "The errors occurred while creating surcharge",
        "create_surcharge_success": "Surcharge has been created successfully",
        "active_surcharge_fail": "The errors occurred while activating surcharge",
        "active_surcharge_success": "Surcharge has been activated successfully",
        "deactive_surcharge_fail": "The errors occurred while deactivating surcharge",
        "deactive_surcharge_success": "Surcharge has been deactivated successfully",
        "delete_surcharge": "Delete surcharge",
        "delete_confirm_message": "Are you sure you want to delete the selected surcharge?",
        "note": "*Note: this is how much your basic fare will be multiplied by. Example: a fare of $20 would be $30 when parameter is at 1.5x"
    },
    "dynamicFare": {
        "operationZone": "Operation zone",
        "activeFare": "Active fare",
        "fare": "Fare",
        "fareName": "Fare name",
        "factor": "Multiply by factor",
        "fix_amount": "Fixed amount",
        "parameter": "Parametro",
        "delete_fare_success": "Fare has been deleted successfully",
        "delete_fare_fail": "The errors occurred while deleting fare",
        "update_fare_fail": "The errors occurred while updating fare",
        "update_fare_success": "Fare has been updated successfully",
        "create_fare_fail": "The errors occurred while creating fare",
        "create_fare_success": "Fare has been created successfully",
        "active_fare_fail": "The errors occurred while activating fare",
        "active_fare_success": "Fare has been activated successfully",
        "deactive_fare_fail": "The errors occurred while deactivating fare",
        "deactive_fare_success": "Fare has been deactivated successfully",
        "delete_fare": "Delete fare",
        "delete_confirm_message": "Are you sure you want to delete the selected fare?",
        "note": "*Note: this is how much your basic fare will be multiplied by. Example: a fare of $20 would be $30 when parameter is at 1.5x",
        "note_amount": "*Note: this is how much your basic fare will be added. Example: a fare of $20 would be $25 when set amount is $5"
    },
    "notifications": {
        "search": "Ricerca Prenotazione id, external ID",
        "searchProviderName": "Search provider name",
        "statusItem": {
            "all": "All notifications",
            "canceledByDriver": "Annullato dal pilota",
            "canceledByPartner": "Canceled by partner",
            "reservationReminder": "promemoria Prenotazione",
            "localPending": "Locale - In attesa",
            "reservationIsNotStarted": "La prenotazione non è iniziata",
            "partnerRequestsCars": "Partner richiede automobili",
            "timeout": "Timeout",
            "noShow": "Affiliate - No show",
            "affiliatePending": "Affiliato - In attesa",
            "affiliatePaymentFailed": "Affiliazione - Il pagamento non è riuscita",
            "affiliateTimeout": "Affiliato - Timeout",
            "flightApi": "Flight API",
            "DebtWriteOff": "Cancellazione del debito",
            "driverCancelTrip": "Driver cancels trip",
            "minimumSeatRequired": "Minimum seats required",
            "newFarmInJobFrom3rd": "Booking.com - New booking",
            "canceledJobBy3rd": "Booking.com - Canceled",
            "updatedJobBy3rd": "Booking.com - Updated",
            "newFarmInJobFromHolidayTaxis": "HolidayTaxis - New booking",
            "canceledJobByHolidayTaxis": "HolidayTaxis - Canceled",
            "updatedJobByHolidayTaxis": "HolidayTaxis - Updated",
            "newFarmInJobFromKarhoo": "Karhoo - New booking",
            "canceledJobByKarhoo": "Karhoo - Canceled",
            "updatedJobByKarhoo": "Karhoo - Updated"
        },
        "sos": {
            "sosSearch": "Search booking ID, pax name, driver name",
            "statusItem": {
                "all": "All",
                "driver": "Drivers",
                "passenger": "Passengers",
                "crew": "Crew"
            }
        }
    },
    "smartData": {
        "No": "No.",
        "driverName": "Name",
        "phone": "Phone",
        "rating": "Rating",
        "completed": "Completed",
        "noShow": "No-show",
        "incident": "Incident",
        "canceled": "Annullato dal pilota",
        "accepted": "Accepted",
        "reject": "Reject",
        "ignore": "Ignore",
        "earning": "guadagno",
        "query": {
            "top20": "Top 20",
            "top50": "Top 50",
            "top100": "Top 100",
            "bottom20": "Bottom 20",
            "bottom50": "Bottom 50",
            "bottom100": "Bottom 100"
        },
        "status": {
            "canceled": "Annullato dal pilota",
            "noShow": "No-show",
            "incident": "Incident",
            "completed": "Completed",
            "earning": "guadagno",
            "reject": "Reject",
            "ignore": "Ignore",
            "accepted": "Accepted"
        }
    },
    "Reject": "Assente",
    "Do not allow Driver to start reservat": "Affiliazione",
    "intercityRate": {
        "Delete_intercity_rate": "Delete intercity rate",
        "edit_intercity_rate": "Edit intercity rate",
        "view_intercity_rate": "View intercity rate",
        "add_intercity_rate": "Add intercity rate",
        "Delete_confirm_intercity_rate": "Do you want to delete this item?",
        "Update_intercity_rate_success": "Intercity rate has been updated successfully",
        "Update_intercity_rate_fail": "The errors occurred while updating intercity rate",
        "Created_intercity_rate_success": "Intercity rate has been created successfully",
        "Created_intercity_rate_fail": "The errors occurred while creating intercity rate",
        "Update_intercity_rate_route_success": "Intercity rate route has been updated successfully",
        "Update_intercity_rate_route_fail": "The errors occurred while updating intercity route",
        "Created_intercity_rate_route_success": "Intercity rate route has been created successfully",
        "Created_intercity_rate_route_fail": "The errors occurred while creating intercity route",
        "Loading_intercity_rate_fail": "The errors occurred while loading intercity rate detail",
        "Delete_intercity_rate_route_fail": "The errors occurred while deleting intercity rate route",
        "Delete_intercity_rate_success": "Intercity rate has been deleted successfully",
        "Delete_intercity_rate_fail": "The errors occurred while deleting intercity rate"
    },
    "intercityRoute": {
        "alias": "Alias",
        "routeNameHint": "To change route name, please go to Settings > Zone > Intercity and change name of city zones accordingly.",
        "pricePerSeat": "Price per seat (tax excluded)",
        "distance": "Distance",
        "duration": "Duration",
        "cancellationPenalty": "Penalty amount per seat",
        "noShow": "Pax/partner no show",
        "penalty": "Penalty amount per seat",
        "no_show_tooltip": "The amount customer will be charged per seat. In addition to the penalty customer will pay tax or tech fee if applied",
        "firstLocation": "First location",
        "secondLocation": "Second location",
        "routeOneSetup": "Setup route 1",
        "routetwoSetup": "Setup route 2",
        "schedules": "Schedules",
        "date": "Date",
        "time": "Time",
        "editSchedule": "Edit schedule",
        "addSchedule": "Add schedule",
        "requestTimeEnable": "Allow customer to set request time",
        "extraFee": "Extra fee",
        "hideRouteEnable": "Hide this route",
        "timeRange": "Time range",
        "workingTime": "Working time",
        "applyForOtherDays": "Apply for other days",
        "limitSeatLuggage": "Limit seat / luggage",
        "warningMinimumSeats": "Minimum seats required",
        "warningMinimumSeatsHint": "Minimum number of seats required to make a trip happen",
        "allowStartLessMinRequiredSeats": "Allow driver to start trip regardless of the minimum seat requirement",
        "allowStartLessMinRequiredSeatsHint": "Driver can start a trip even if requested seats less than minimum seat required",
        "freeLuggagePerSeat": "Free luggage per seat",
        "freeLuggagePerSeatHint": "Number free of luggage included per seat",
        "allowToAddExtraLuggage": "Allow to add additional luggage",
        "allowToAddExtraLuggageHint": "Passenger can request to add more luggage",
        "feePerExtraLuggage": "Fee per extra luggage",
        "Delete_intercity_route": "Delete intercity route",
        "Delete_confirm_intercity_route": "Do you want to delete this route?",
        "Update_intercity_route_fail": "Intercity route has been updated successfully",
        "Update_intercity_route_success": "Intercity route has been updated successfully",
        "created_intercity_route_success": "Intercity route has been created successfully",
        "created_intercity_route_fail": "The errors occurred while creating intercity route",
        "Delete_intercity_route_success": "Intercity route has been deleted successfully",
        "Delete_intercity_route_fail": "The errors occurred while deleting intercity route",
        "penalty_less_than_or_equal_to_price": "Please enter a value less than or equal to price per seat value.",
        "scheduleTimeOverlap": "Schedule time must not overlap with each others",
        "allows_adding_extra": "Allows adding extra charge when customer request time which isn't scheduled by fleet.",
        "this_is_to_limit_users": "This is to limit users (i.e. customer, driver, operator) from creating new booking or trip on this route. Existing bookings or trips are remained as reserved"
    },
    "dayOfWeek": {
        "0": "Sunday",
        "1": "Monday",
        "2": "Tuesday",
        "3": "Wednesday",
        "4": "Thursday",
        "5": "Friday",
        "6": "Saturday"
    },
    "intercityBooking": {
        "route": "Route",
        "departureDate": "Departure date",
        "departureTime": "Departure time",
        "Departure": "Departure",
        "Arrival": "Arrival",
        "RefundFare": "Refund fare",
        "intercityZoneNote": "These zones are not operational zone. And they are used for setting intercity rate only.",
        "zoneNameHint": "Please keep your zone name short and clear so that your customers can search and select easily.",
        "zoneUploadNote_2": "*  It is recommended that image resolution should be 1095x360 and file size less than 5MB.",
        "Complete_booking": "Complete Booking",
        "ConfirmCancel": "Confirm cancel"
    },
    "deliveryRate": {
        "Delete_delivery_rate": "Delete delivery rate",
        "edit_delivery_rate": "Edit delivery rate",
        "view_delivery_rate": "View delivery rate",
        "add_delivery_rate": "Add delivery rate",
        "Delete_confirm_delivery_rate": "Do you want to delete this item?",
        "Update_delivery_rate_success": "Delivery rate has been updated successfully",
        "Update_delivery_rate_fail": "The errors occurred while updating delivery rate",
        "Created_delivery_rate_success": "Delivery rate has been created successfully",
        "Created_delivery_rate_fail": "The errors occurred while creating delivery rate",
        "Update_delivery_rate_route_success": "Delivery rate route has been updated successfully",
        "Update_delivery_rate_route_fail": "The errors occurred while updating delivery route",
        "Created_delivery_rate_route_success": "Delivery rate route has been created successfully",
        "Created_delivery_rate_route_fail": "The errors occurred while creating delivery route",
        "Loading_delivery_rate_fail": "The errors occurred while loading delivery rate detail",
        "Delete_delivery_rate_route_fail": "The errors occurred while deleting delivery rate route",
        "Delete_delivery_rate_success": "Delivery rate has been deleted successfully",
        "Delete_delivery_rate_fail": "The errors occurred while deleting delivery rate",
        "distance_km": "Fee per km",
        "distance_mile": "Fee per mil",
        "flat": "Flat Fee",
        "startingFee": "Starting fee",
        "feeFirstDistance": "Fee first distance",
        "feeSecondDistance": "Fee second distance",
        "feeThirdDistance": "Fee third distance",
        "feeAfterThirdDistance": "Fee after third distance",
        "feePerMinute": "Fare per minute",
        "feeMinimum": "Fee minimum",
        "feeFirstStop": "Fee for first stop",
        "feeExtraStop": "Fee for each extra stop"
    },
    "pwaSettings": {
        "pwa": "PWA",
        "iconName": "Icon & name",
        "logoBackgroundColor": "Logo, background & color",
        "longName": "Long name",
        "shortName": "Short name",
        "icon192": "Icon on Home Screen (192x192)",
        "icon512": "Icon on Splash Screen",
        "logo": "Logo (300x300)",
        "logoMenu": "Logo menu (300x300)",
        "backgroundImage": "Background image - Transport (1024x500)",
        "backgroundImageDelivery": "Background image - Delivery (1024x500)",
        "mainColor": "Main color",
        "secondaryColor": "Secondary color",
        "showMainColor": "Show main color instead of background image",
        "success": "PWA setting has been updated successfully",
        "failed": "Failed to update PWA setting. Please try again!",
        "errorUploadFileSize": "The required dimensions are {0}x{0} px.",
        "hideWBInstallAppBtn": "Hide install app button",
        "enableBookNow": "Now",
        "enableGuestCheckout": "Guest checkout",
        "enableBookLater": "Later",
        "enableHideRecipient": "Hide recipient info (Package content, Delivery instructions)",
        "pwaLink": "PWA for Passenger",
        "partnerLink": "PWA for Partner",
        "pwaDriverLink": "PWA for Driver",
        "providerSignUp": "Driver sign-up link",
        "AdsTracking": "Ads Tracking",
        "googleId": "Google Tag ID",
        "placeholdergoogleId": "Enter Google Tag ID",
        "metaId": "Meta Pixel ID",
        "placeholdermetaId": "Enter Meta Pixel ID"
    },
    "emailConfig": {
        "emailServer": "Email server",
        "default": "Default",
        "customized": "Customized",
        "fromName": "From name",
        "email": "From email",
        "replyTo": "Reply to",
        "userAuth": "Username",
        "password": "Password",
        "smtpServer": "SMTP server",
        "port": "SMTP port",
        "isAuthenticated": "Authenticated",
        "option": "Enable Option",
        "none": "None",
        "tls": "TLS",
        "ssl": "SSL",
        "save": "Save",
        "cancel": "Cancel",
        "success": "Update email server success",
        "fail": "Update email server fail",
        "loadFail": "Load email server detail fail"
    },
    "driverFields": {
        "Load_fail": "",
        "Edit": "Edit",
        "View": "View",
        "Cancel": "Cancel",
        "show": "Show",
        "hide": "Hide",
        "EDIT_DRIVER_FIELDS": "Edit Driver Fields",
        "DETAIL_DRIVER_FIELDS": "Detail Driver Fields",
        "ADD_DRIVER_FIELDS": "Add Driver Fields",
        "fieldKey": "Fields key",
        "fieldName": "Fields name",
        "availableCC": "Available in Command Center",
        "availableDrvSignUp": "Available in Driver sign up",
        "availableCompanyCar": "Company Car - Transport drivers",
        "deliveryCompany": "Company Car - Delivery drivers",
        "availableIndividualCar": "Individual Car - Transport drivers",
        "deliveryIndividual": "Individual Car - Delivery drivers",
        "required": "Required",
        "Update_fail": "The errors occurred while updating driver fields",
        "Update_success": "The Driver Fields has been updated successfully",
        "FieldKey": "Fields Key",
        "FieldName": "Fields Name (American English)",
        "CommandCenter": "Available In Command Center",
        "SignUp": "Available In Driver Sign Up",
        "CompanyTransport": "Company Car - Transport",
        "CompanyDelivery": "Company Car - Delivery",
        "IndividualTransport": "Individual Car - Transport",
        "IndividualDelivery": "Individual Car - Delivery",
        "Require": "Required",
        "Actions": "Actions",
        "fieldType": "Fields Type",
        "default": "Default",
        "customized": "Customized"
    },
    "driverDocument": {
        "Load_fail": "",
        "Edit": "Edit",
        "View": "View",
        "Cancel": "Cancel",
        "show": "Show",
        "hide": "Hide",
        "EDIT_DRIVER_DOCUMENT": "Edit Driver Document",
        "DETAIL_DRIVER_DOCUMENT": "Detail Driver Document",
        "ADD_DRIVER_DOCUMENT": "Add Driver Document",
        "documentKey": "Document key",
        "documentName": "Document name",
        "documentNameDetail": {
            "zh-HK": "Traditional Chinese",
            "it-IT": "Standard Italian",
            "es-ES": "Spanish",
            "en-US": "American English",
            "fr-FR": "Standard French",
            "zh-CN": "Simplified Chinese",
            "id-ID": "Bahasa Indonesia",
            "vi-VN": "Vietnamese",
            "ur-PK": "Urdu (Islamic Republic of Pakistan)",
            "ms-MY": "Malay (Malaysia)",
            "ar-AR": "Arabic",
            "en-GB": "English (United Kingdom)",
            "nb-NO": "Norwegian",
            "am-ET": "Amharic",
            "ro-RO": "Romanian",
            "th-TH": "Thai",
            "ta-IN": "Tamil",
            "hi-IN": "Hindi",
            "te-IN": "Telugu",
            "ka-GE": "Georgian",
            "ru-RU": "Russian",
            "kn-IN": "Kannada"
        },
        "isIndividual": "Company car - Transport drivers",
        "deliveryCompany": "Company car - Delivery drivers",
        "isOwner": "Individual driver - Transport drivers",
        "deliveryIndividual": "Individual driver - Delivery drivers",
        "availableCC": "Available in Command Center",
        "availableDrvSignUp": "Available in Driver sign up",
        "isRequired": "Required",
        "isExpiredDate": "Expiry date - Command Center",
        "expiredDateForDrvSignUp": "Expiry date - Driver signup",
        "requireExpiredDate": "Require Expiry Date",
        "requireExpiredDateForDrvSignUp": "Require Expiry Date",
        "requireDocumentId": "Require Document ID",
        "documentId": "Document ID - Command Center",
        "documentIdForDrvSignUp": "Document ID - Driver signup",
        "Update_fail": "The errors occurred while updating driver document",
        "Update_success": "The Driver Document has been updated successfully",
        "DocumentKey": "Document Key",
        "DocumentName": "Document Name (American English)",
        "CommandCenter": "Available In Command Center",
        "SignUp": "Available In Driver Sign Up",
        "CompanyTransport": "Company Car - Transport",
        "CompanyDelivery": "Company Car - Delivery",
        "IndividualTransport": "Individual Car - Transport",
        "IndividualDelivery": "Individual Car - Delivery",
        "Require": "Required",
        "Actions": "Actions"
    },
    "holidayTaxis": {
        "title": "HolidayTaxis API Credentials",
        "note": "Communicate with HolidayTaxis's technical team to provide you with api key to connect to their system.",
        "apiKey": "API key",
        "placeHolderApiKey": "Enter api key"
    },
    "trailNotes": {
        "maxLengthFiles": "Maximum 3 files per comment",
        "maxSizeFiles": "Maximum 10MB per file",
        "typeFiles": "File type supported: pdf, doc, docx, csv, xls, xlsx, jpeg, jpg, png, webp, txt, note",
        "maxComments": "Maximum 20 comments per booking",
        "bookingActivity": "Booking Activity",
        "viewAll": "View all",
        "operator": "Operator",
        "driver": "Driver",
        "passenger": "Customer",
        "pHInput": "Add a new comment. You can click save to save the note. You can also drag an drop multiple files here.",
        "addFiles": "Add files",
        "save": "Save",
        "inputNotes": "Please input text",
        "successDeleteTrailNotes": "Delete trail notes successfully",
        "successCreateTrailNotes": "Create trail notes successfully",
        "deleteTrailNote": "Delete a trail note",
        "msgDeleteTrailNote": "Are you sure you want to delete this?"
    },
    "supplier": {
        "permissionRequired": "Operator need to select a permission",
        "offerDetail": "Offer Detail",
        "orderDetail": "Order Detail",
        "bookingNumber": "Booking Number",
        "date": "Date",
        "pickupLocation": "Pick up Location",
        "dropoffLocation": "Drop off Location",
        "passenger": "Passenger",
        "luggage": "Luggage",
        "pickupService": "Pickup Service",
        "pickupSignage": "Pickup Signage",
        "flightNumber": "Flight Number",
        "departure": "Departure",
        "arrival": "Arrival",
        "terminal": "Terminal",
        "gate": "Gate",
        "meetGreet": "Meet & Greet",
        "specialRequests": "Special Requests",
        "additionalServices": "Additional Services",
        "price": "Price",
        "prices": "Prices",
        "guest": "Guest",
        "serviceClass": "Service Class",
        "distance": "Distance",
        "duration": "Duration",
        "acceptOffer": "Accept Offer",
        "updateBooking": "Update Booking",
        "package": "Package",
        "route": "Route",
        "assignedChauffer": "Assigned Chauffeur",
        "assignedVehicle": "Assigned Vehicle",
        "logs": "Logs",
        "link": "Links",
        "desLink": "Click to copy link",
        "trackLink": "Track link",
        "driverLink": "Driver link",
        "close": "Close",
        "links": "Links",
        "linksDes": "Click to copy link",
        "status": "Status",
        "rating": "Rating",
        "permissions": "Permissions",
        "permissionsNote": "Assign different levels of authorization to your team. You can choose whether your operators have visibility of open offers or only assignments already accepted.",
        "owner": "Owner",
        "ownerNote": "Full Access",
        "manageUsers": "Manage Users",
        "manageUsersNote": "Add/remove new operators",
        "manageDrivers": "Manage Drivers",
        "manageDriversNote": "Add/remove drivers",
        "manageVehicles": "Manage Vehicles",
        "manageVehiclesNote": "Add/remove vehicles",
        "mapView": "Live Map",
        "mapViewNote": "Track drivers and active trips live on the map.",
        "calendarView": "Calendar View",
        "calendarViewNote": "View calendar of all assigned trips.",
        "bookingView": "Booking View",
        "bookingViewNote": "View and manage current bookings, assign drivers, and allocate vehicles.",
        "offersView": "Offers View",
        "offersViewNote": "Accept new job offers.",
        "pricing": "Pricing",
        "pricingNote": "View trip prices",
        "userDetail": "User Detail",
        "addOperator": "Add Operator",
        "updateOperator": "Update Operator",
        "supplier": "Supplier",
        "zone": "Zone",
        "emails": "Emails",
        "email": "Email",
        "phoneNumber": "Phone Number",
        "Deactivate": "Deactivate",
        "Activate": "Activate",
        "ALERT_SELECT_DEACTIVATE": "Please select supplier to deactivate",
        "ALERT_SELECT_ACTIVATE": "Please select supplier to activate",
        "CONFIRM_DEACTIVATE_MULTI": "Do you want to deactivate these suppliers?",
        "CONFIRM_ACTIVATE_MULTI": "Do you want to activate these suppliers?",
        "Update_supplier_user_status_success": "Supplier status has been updated successfully",
        "Update_supplier_user_status_fail": "The errors occurred while updating supplier status",
        "agent": "Agent",
        "Supplier": "Supplier",
        "addSupplier": "Add Supplier",
        "detailSupplier": "Supplier Detail",
        "addUser": "Add User",
        "Add": "Add",
        "ContactInfo": "Contact Info",
        "policy": "Policy",
        "users": "Users",
        "setting": "Setting",
        "zones": "Zones",
        "actions": "Actions",
        "companyName": "Company Name",
        "corporationType": "Corporation Type",
        "country": "Country",
        "ADMIN_INFORMATION": "Admin Information",
        "firstName": "First Name",
        "lastName": "Last Name",
        "commissionValue": "Fleet commission",
        "commissionValueDes": "Set commissions the fleet earns for every complete order.",
        "paymentMethodsApplyDes": "Job specific payment methods that suppliers can process:",
        "bookingSettings": "Booking Settings",
        "bookingSettingsDes": "Configure job visibility, release times, and delays for efficient supplier operations.",
        "advanceBookingVisibilityTooltip": "Specify how many days in advance suppliers can see the orders.",
        "delayBroadcastToolTip": "Set a delay time from the release time if set or the start of the day (00:00). This delay controls when the jobs become visible to the suppliers after the initial release time.",
        "releaseTimeToolTip": "Set the time of day when jobs are released to suppliers, starting from the first day they can see bookings. Ensure this time allows suppliers enough preparation time.",
        "otherSettings": "Other Settings",
        "hideFare": "Driver - Always hide fare",
        "hideFareDes": "Hide the fare for all drivers of this supplier.",
        "supplierJobRequired": "Supplier Job Acceptance Required",
        "supplierJobRequiredDes": "Suppliers must acknowledge and accept jobs before they can assign a driver/vehicle or allow the job to be viewed by drivers in the marketplace.",
        "supplierApproval": "Suppliers Driver/Vehicle Approval",
        "supplierApprovalDes": "Allow supplier operators to activate drivers and vehicles within their fleet.",
        "emailsApply": "Email Notifications",
        "emailsApplyDes": "Emails to send new jobs, booking updates.",
        "address": "Address",
        "zipCode": "Zip/Postal Code",
        "city": "City",
        "state": "State/Province",
        "tax": "Tax Identification Number",
        "businessRegistration": "Business Registration Number",
        "vatNumber": "VAT ID/Number",
        "mobile": "Mobile",
        "editOperator": "Edit Operator",
        "searchEmail": "Search Email",
        "acceptOfferSuccess": "Accept offer successfully",
        "acceptOfferFail": "Booking not found or another supplier has accepted the booking",
        "driverPayout": "Driver payout",
        "payout": "Payout",
        "totalPayout": "Total payout",
        "totalPayoutDes": "The total payout includes the base payout amount plus any additional fees you have chosen to allocate to your providers.",
        "supplierPayout": "Supplier payout",
        "driverTip": "Driver tip",
        "cancel": "Cancel",
        "save": "Save",
        "activeSupplier": "Active supplier",
        "userName": "Username",
        "bookingCompletedView": "Completed Bookings",
        "bookingCompletedViewNote": "View the history of all completed bookings.",
        "driverEarning": "Driver earning"
    },
    "noResultFound": "No results found"
};
