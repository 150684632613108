export const cn_trad = {
    "name": "中文 (繁體)",
    "flagCode": "tw",
    "locale": "zh-Hant",
    "project": {
        "name": "地圖"
    },
    "Sidebar": {
        "Setting": "設置",
        "Map": "地圖",
        "Cue": "排程",
        "ImportBookings": "批量導入訂單",
        "ImportDeliveryBookings": "批量匯出訂單",
        "Trip": "行程",
        "New_booking": "新訂單",
        "City": "城市",
        "Chauffeur": "約車出行",
        "Delivery": "送貨",
        "Shuttle": "穿梭線路",
        "Intercity": "專線直達",
        "Customer": "客戶",
        "Driver": "司機",
        "REPORTS": "報告",
        "Corporate": "企業及代理",
        "mDispatcher": "個人合作夥伴",
        "Reservation": "日曆",
        "withdrawalRequest": "提款請求",
        "Driver_Settlement": "司機結算",
        "Dashboard": "儀表板",
        "SmartData": "智能數據",
        "Smart_Data": "智能數據",
        "Campaign_Builder": "活動創建",
        "Company_operator": "供應商操作員",
        "Queuing_area": "排隊區",
        "Withdrawal_Requests": "提款請求",
        "pickUp": "出發地",
        "dropOff": "目的地",
        "SignUpNotifications": "註冊通知",
        "Payout": "對外付賬",
        "Payout_label": "對外付賬",
        "payout": {
            "Pay_To_Driver": "支付司機",
            "Payout_History_For_Driver": "支付司機記錄",
            "Pay_To_Merchant": "支付商戶",
            "Payout_History_For_Merchant": "支付商戶記錄"
        },
        "number_of_records_to_export": "匯出備注數目",
        "export": "匯出",
        "Airline": "航空公司",
        "driverPayout": {
            "Pay_to_driver": "支付司機",
            "Payout_history": "支付記錄"
        },
        "Reports": {
            "Driver_Referral_history": "司機推薦乘客",
            "Driver_refers_Pax": "司機",
            "Driver_refers_Pax_Customers": "客戶",
            "Driver_refers_Pax_Bookings": "訂單",
            "Driver_refers_customer_Drivers": "司機推薦乘客（司機）",
            "Driver_refers_customer_Customers": "司機推薦乘客（客戶）",
            "Driver_refers_customer_Bookings": "司機推薦乘客（訂單）",
            "Passenger_refers_Passengers": "乘客推薦乘客",
            "Driver_refers_drivers_Referrer": "司機推薦司機（推薦人）",
            "Deletion_request": "刪除請求",
            "Driver_refers_drivers_Referee": "司機推薦司機（被薦人）",
            "Driver_refers_Driver": "司機推薦司機",
            "Driver_refers_driver_Referrer": "推薦人",
            "Driver_refers_driver_Referee": "被薦人",
            "Referral_history": "推薦記錄",
            "Booking_details": "訂單詳情",
            "Financial": "財務",
            "Financial_driver": "司機",
            "Financial_fleet": "出行運營商",
            "Financial_company": "車隊供應商",
            "Financial_Driver": "財務 (司機)",
            "Financial_Fleet": "財務 (出行運營商)",
            "Financial_Company": "財務 (車隊供應商)",
            "Financial_fleet_profit": "運營利潤",
            "Financial_Company_Tooltip": "根據車隊供應商分組的司機淨收入報告",
            "Financial_Driver_ToolTip": "每位司機的淨收入報告",
            "Financial_Fleet_Profit_ToolTip": "出行運營商利潤",
            "Driver_activity": "司機的活動",
            "Revenue_Summary": "收入概況",
            "Revenue_fleet": "出行運營商",
            "Revenue_driver": "司機",
            "Revenue_monthly": "月報表",
            "Revenue_Summary_Fleet": "收入摘要 (出行運營商)",
            "Revenue_Summary_Driver": "收入摘要 (司機)",
            "Revenue_Summary_Monthly": "收入摘要 (月報)",
            "Total_adjustment": "車費調整",
            "Promotion": "促銷",
            "Incident_Cancellation": "事故和取消",
            "Booking_logs": "訂單日誌",
            "Rating": "評分",
            "Thumbs_rating": "贊評分",
            "Stars_rating": "星級評分",
            "Operator_logs": "操作員日誌",
            "Daily": "日在線報表",
            "Daily_Driver": "司機日在線",
            "Daily_Car": "車輛日在線",
            "Daily_Driver_Car": "車輛司機日在線",
            "mDispatcher": "個人合作夥伴",
            "Corporate": "企业",
            "Driver_login_status": "司機登陸狀態",
            "Car_activity": "車輛活動",
            "Partner": "個人合作夥伴",
            "Prepaid_top_up": "企業預付",
            "Affiliation": "聯盟",
            "Affiliation_penalty_compensation": "罰款及補償",
            "Affiliation_payout_history": "結算歷史",
            "Affiliation_bookings": "聯盟訂單",
            "Booking_summary": "訂單摘要",
            "Cash_Wallet": "現金錢包",
            "Credit_Wallet": "信用錢包",
            "Driver_wallet": "司機錢包",
            "Driver_Deposit": "司機存入",
            "Driver_cash_wallet": "現金錢包",
            "Driver_Deposit_old": "司機存入",
            "Document_expiry": "文件到期",
            "Settlement": "結算",
            "Unapproved_Driver": "未批准的司機",
            "Approved_Driver": "已批准的司機",
            "Pay_to_driver": "支付給司機",
            "Settlement_history": "結算歷史",
            "Withdrawal_History": "司機提現",
            "creditWalletTooltip": "司機存款到信用錢包",
            "Incomplete_payment": "不完整付款",
            "Driver_withdrawal": "司機提現",
            "Incident_&_Cancellation": "事故和取消",
            "Passenger_wallet": "乘客錢包",
            "Accept_Cancel_rate": "司機表現",
            "Acceptance/Cancellation_rate": "司機表現",
            "driverDepositTooltip": "司機存款到信用錢包",
            "Customer_quest": "任務",
            "First_will_win": "先完成為勝",
            "Largest_will_win": "最大為勝",
            "redeemed": "兌換",
            "Code_#": "編碼",
            "Quests": "任務",
            "Merchant_Report": "商戶",
            "Merchant_Financial_Report": "財務",
            "Merchant_Cash_Report": "現金錢包",
            "Merchant_Credit_Report": "信用錢包",
            "Deletion_Request": "刪除請求",
            "Driver_Rating": "客戶評價司機",
            "Passenger_Rating": "司機評價客戶",
            "Company_settlement": "供應商結算",
            "Export": "下載歷史",
            "Third_Party_Booking": "API訂單",
            "BookingDotCom": "Booking.com",
            "Third_Party_Booking_Bookingcom": "API訂單(Booking.com)",
            "Third_Party_Booking_HolidayTaxis": "Third Party Booking (HolidayTaxis)",
            "Third_Party_Booking_Karhoo": "Third Party Booking (Karhoo)",
            "Third_Party_Booking_Hoppa": "Third Party Booking (Hoppa)"
        },
        "Settings": {
            "Fleet_info": "運營商信息",
            "Stripe_connect": "Stripe Connect",
            "stripe_title_connect": "啓用您的 Stripe Connect 帳戶以自動接收付款",
            "Payment_info": "賬單信息",
            "Permission": "權限",
            "Driver_refer_driver": "司機推薦司機",
            "Show_referal_history": "司機App顯示隱藏推薦歷史",
            "companyTooltip": "車隊供應商",
            "User": "用戶",
            "General": "一般",
            "Regular": "標準計費",
            "Sharing": "街頭拼車",
            "Flat": "固定計費",
            "Hourly": "按時計費(包車)",
            "Company": "車隊供應商",
            "Dispatch": "調度",
            "Voice_SMS": "語音和短信",
            "Rate": "費率設置",
            "Car": "車輛",
            "Car_type": "車型",
            "Car_mgmt": "車輛管理",
            "Car_Make": "車廠和型號",
            "Stripe_Connect": "STRIPE CONNECT",
            "Voice_&_SMS": "語音和短信",
            "Street-sharing_rate": "街頭拼車計費",
            "Partner_type": "個人合作夥伴類型",
            "Shift_template": "排班模板",
            "Driver_App": "司機App",
            "Shift_settings": "排班設置",
            "Operation": "運營",
            "Driver_Note": "司機備注",
            "Promotion_code": "促銷代碼",
            "Point_config": "點數配置",
            "Code_#": "編碼",
            "Voucher_code": "代金券",
            "Campaign": "推廣活動",
            "Queuing_area": "排隊區域",
            "Referral_code": "推薦碼",
            "City": "城市",
            "category": "類別",
            "3rd_party_location": "第三方地址",
            "Zone": "區域圍欄",
            "App_banner": "App橫幅",
            "App_banner_pegasus": "App橫幅",
            "Flight_integration": "航班信息對接",
            "SMS_integration": "SMS對接",
            "Invoice_Setting": "賬單",
            "Driver_refers_customer": "司機推薦乘客",
            "Pax_refers_Pax": "乘客推薦乘客",
            "Customer_refers_customer": "客戶推薦客戶",
            "Dynamic_Surcharge": "動態附加費",
            "Dynamic_Fare": "動態車費",
            "FlatZone": "固定計費圍欄",
            "IntercityZone": "專線",
            "Intercity_Zone": "專線圍欄",
            "Intercity_Rate": "專線車費",
            "Car_Color": "車輛顏色",
            "third_party_integration": "第三方對接",
            "bookingCom": "Booking.com",
            "HolidayTaxis": "HolidayTaxis",
            "Karhoo": "Karhoo",
            "Corporate": "Corporate",
            "KarhooIntegration": "Karhoo Integration",
            "holidaytaxis": "HolidayTaxis",
            "karhoo": "Karhoo",
            "hoppa": "Hoppa",
            "Hoppa": "Hoppa",
            "HoppaIntegration": "Hoppa Integration",
            "Third_Party_Integration_Hoppa": "3rd party Integration (Hoppa.com)",
            "titleHoppa": "Hoppa API Credentials",
            "noteHoppa": "Communicate with Hoppa’s technical team to provide you with client, and secret key to connect to their system.",
            "titleHoppaWebhook": "Webhook Configuration",
            "noteHoppaWebhook": "Provide the parameters below for Hoppa to configure. These credentials are used to communicate between both platforms. For any issues please contact your tech provider.",
            "Third_Party_Integration_Booking": "第三方對接(Booking.com)",
            "Third_Party_Integration_HolidayTaxis": "3rd party Integration (HolidayTaxis)",
            "Third_Party_Integration_Karhoo": "第三方對接(Karhoo.com)",
            "Third_Party_Integration_Mozio": "第三方對接(Mozio.com)",
            "invoiceBranding": "賬單品牌",
            "titleInvoice": "自定義頁眉和頁腳信息",
            "titleheader": "頁眉標題",
            "descriptionHeader": "頁眉說明",
            "placehodelHeader": "例如，添加公司詳細信息，如電子郵件、電話號碼、地址、稅號等。",
            "titleFooter": "頁腳標題",
            "placehodelFooter": "例如，添加銀行電匯信息或任何政策等",
            "descriptionFooter": "頁腳說明",
            "titleBookingCom": "Booking.com API 憑證",
            "noteBookingCom": "與 booking.com 的技術團隊溝通，為您提供客戶端以及連接到他們系統的密鑰。",
            "clientKey": "客戶端密鑰",
            "placeHolderClientKey": "輸入客戶端密鑰",
            "secretKey": "密鑰",
            "placeHolderSecretKey": "輸入客戶端密碼",
            "titleBookingComWebhook": "Webhook 配置",
            "noteBookingComWebhook": "提供以下參數供 booking.com 進行配置。 這些憑據用於在兩個運營商之間進行通信。 如有任何問題，請聯繫您的技術提供商。",
            "webhook": "運營商Webhook URL",
            "clientId": "運營商客戶端密鑰",
            "clientSecret": "運營商秘鑰",
            "driver_app": "司機App",
            "Jobs_tab": "工作標籤",
            "Email_config": "電郵配置",
            "Driver_fields": "司機字段",
            "Driver_document": "司機文件",
            "Selected": "Selected",
            "API_Management": "API管理"
        }
    },
    "APIKeySetting": {
        "APIKey": "API鍵",
        "APIKey_title": "不要與他人共享您的 API 密鑰，也不要在瀏覽器或其他客戶端代碼中公開它。 為了保護您的帳戶安全，我們還可能會自動禁用發現已公開洩露的任何 API 密鑰。",
        "newKeyBtn": "創建新密鑰",
        "updateApplication": "更新應用程序",
        "RegisterApp": "註冊一個新的應用程序",
        "RegisterBtn": "註冊申請",
        "appDescription": "應用說明",
        "appName": "應用程序名稱",
        "clientKey": "客戶端密鑰",
        "secretKey": "密鑰",
        "hostedEndPoint": "托管端點",
        "maxEndpoints": "您最多可以有 5 個端點",
        "addEndpoint": "添加端點",
        "updateEndpoint": "更新端點",
        "ManageWebhooks": "管理Webhook",
        "CreateWebhook": "創建Webhook",
        "subscriptionID": "訂閱ID",
        "webhookURL": "Webhook URL",
        "signingSecret": "簽名秘鑰",
        "eventsToSend": "要發送的事件",
        "listeningFor": "事件聆聽",
        "assignedDriver": "已安排司機",
        "bookingStatus": "預訂狀態",
        "driverLocation": "司機位置",
        "titleSigningSecret": "這個密鑰是由你的合作夥伴提供的。 如果由於任何原因密鑰已被洩露，您可以更新機密或刪除 Webhook。",
        "notUsingHTTPS": "Webhook URL 未使用 HTTPS。",
        "invalidURL": "Webhook URL 無效。",
        "clientType": "Client Type",
        "individual": "Individual",
        "corporate": "Corporate",
        "signingSecretLength": "Signing secret must be at least 30 characters long.",
        "bookingCreated": "Booking Created",
        "partner": "Partner",
        "fleetManager": "Fleet Manager"
    },
    "messages": {
        "credits": {
            "406": "操作失敗",
            "407": "操作失敗",
            "416": "優惠券已被使用",
            "417": "您的帳戶已失效， 請聯繫我們的管理員",
            "418": "付款時出現錯誤",
            "419": "促銷代碼無效！",
            "420": "此促銷代碼已應用於另一次行程，請使用其他碼。",
            "421": "卡號無效。",
            "422": "過期日期無效。",
            "429": "郵政編碼無效。",
            "430": "驗證郵政編碼不匹配",
            "431": "不支持郵政編碼驗證",
            "432": "CVV 無效。",
            "433": "CVV 不匹配",
            "434": "CVV 未驗證",
            "435": "CVV發行人不參與",
            "436": "您的信用卡遭到拒絕",
            "437": "信用卡驗證失敗， 請檢查您的信息並重試。",
            "439": "CVV 未處理",
            "441": "發行人無 CVV 數據",
            "443": "沒有來自發行人/銀行網絡的數據",
            "445": "AVS系統無法處理",
            "446": "發卡銀行不支持AVS",
            "447": "AVS不支持您的業務",
            "448": "由於多次嘗試付款失敗，該卡已被凍結，請更改您的付款方式或稍後重試",
            "452": "您的卡被拒絕， 請輸入與您的電話號碼在同一地區註冊的另一張信用卡",
            "453": "不支持此支付網關",
            "454": "AVS 地址不匹配",
            "455": "AVS 郵政編碼和地址不匹配",
            "458": "認證失敗",
            "459": "銀行卡不支持",
            "461": "不支持此卡",
            "462": "持卡人姓名無效",
            "463": "驗證碼無效",
            "464": "本行不支持生成和發送驗證碼",
            "470": "使用信用卡進行預訂之前，請輸入旅客的電子郵件地址",
            "474": "使用信用卡預訂前請輸入旅客姓名",
            "475": "電話號碼為必填項",
            "524": "資金不足，該賬戶沒有足夠的資金來支付交易金額。",
            "911": "我們無法驗證您的付款方式，請選擇其他付款方式並重試。",
            "2000": "不兌現",
            "2001": "資金不足，該賬戶沒有足夠的資金來支付交易金額。",
            "2002": "超出限制",
            "2003": "持卡人活動超出限制",
            "2004": "過期卡",
            "2005": "無效的信用卡號碼",
            "2006": "無效的到期日期",
            "2007": "沒有賬號",
            "2008": "卡帳號長度錯誤",
            "2009": "無此類發行人",
            "2010": "發卡機構拒絕 CVV",
            "2011": "需要語音授權，持卡人銀行要求商戶致電獲取特殊授權碼以完成本次交易",
            "2012": "已拒絕 - 可能丟失的卡",
            "2013": "已拒絕 - 可能被盜的卡",
            "2014": "被拒絕 - 涉嫌欺詐",
            "2015": "不允許交易",
            "2016": "重復交易",
            "2017": "持卡人停止付費",
            "2018": "持卡人停止所有付費",
            "2019": "無效交易",
            "2020": "違反",
            "2021": "違反安全規定",
            "2022": "已拒絕 - 可更新持卡人",
            "2023": "不支持此功能",
            "2024": "信用卡類型未啓用",
            "2025": "設置錯誤 - 商戶",
            "2026": "商戶ID無效",
            "2027": "設置錯誤 - 金額",
            "2028": "設置錯誤 - 層次結構",
            "2029": "設置錯誤 - 卡",
            "2030": "設置錯誤 - 終端",
            "2031": "加密錯誤",
            "2032": "不允許附加費",
            "2033": "數據不一致",
            "2034": "不採取操作",
            "2035": "在III組版本批准部分金額",
            "2036": "找不到撤銷授權",
            "2037": "已經撤銷",
            "2038": "已拒絕，客戶的銀行不願意接受該交易，需要聯繫其銀行瞭解更多詳情。",
            "2039": "授權碼無效",
            "2040": "無效商店",
            "2041": "已拒絕 - 請致電要求批准",
            "2042": "客戶端 ID 無效",
            "2043": "錯誤 - 請勿重試，請致電發卡機構",
            "2044": "拒絕 - 致電發卡人",
            "2045": "商戶號碼無效",
            "2046": "已拒絕，客戶的銀行不願意接受該交易，需要聯繫其銀行瞭解更多詳情。",
            "2047": "致電發卡機構。此錯誤表示該卡已被持卡人報告丟失或被盜。",
            "2048": "無效金額",
            "2049": "SKU 編號無效",
            "2050": "無效信用計劃",
            "2051": "信用卡號碼與付款方式不符",
            "2052": "無效的III級購買",
            "2053": "信用卡報告丟失或被盜",
            "2054": "撤銷金額與授權金額不符",
            "2055": "交易分部編號無效",
            "2056": "交易金額超過交易分割限額",
            "2057": "發卡機構或持卡人已設置限制",
            "2058": "商戶未啓用 MasterCard SecureCode。",
            "2060": "地址驗證和卡安全碼失敗",
            "2061": "無效交易數據",
            "2062": "稅額無效",
            "2064": "貨幣代碼無效",
            "2078": "無效的安全支付數據",
            "2080": "用戶憑證無效",
            "3000": "網關網絡不可用 - 再試一次",
            "4001": "結算已拒絕",
            "4006": "交易金額超過允許限度",
            "81703": "該商戶賬戶不接受信用卡類型。",
            "81706": "需要提供CVV",
            "81707": "CVV 必須是 3 或 4 位數字。",
            "81709": "需要提供到期日期。",
            "81710": "到期日期無效。",
            "81714": "需要信用卡號碼。",
            "81715": "信用卡號碼無效。",
            "81716": "信用卡號碼必須為 12-19 位數字。",
            "81717": "信用卡號不是可接受的測試號。",
            "81718": "信用卡號碼關聯到某些預訂，不能更新為不支持的信用卡類型。",
            "81723": "持卡人姓名太長。 最多 175 個字符。",
            "81809": "郵政編碼不得超過 9 個字母或數字字符",
            "81813": "郵政編碼只能包含字母、數字、空格和連字符",
            "91722": "需要支付方式令牌。",
            "91723": "更新現有令牌無效",
            "91730": "此商家帳戶不支持驗證。",
            "91734": "該商戶賬戶不接受信用卡類型。",
            "91738": "指定的付款方式不是信用卡。",
            "91745": "信用卡更新參數無效。",
            "91826": "郵政編碼無效，郵政編碼必須是 5 或 9 位數字的字符串，可以選擇用破折號或空格分隔。",
            "-1": "該客戶帳戶已被停用，請聯繫我們的管理員。"
        },
        "reBookingMsg": {
            "303": "您選擇的時間必須晚於當前時間！",
            "304": "未找到此訂單",
            "500": "某些訂單信息無效或缺失，請檢查並重試！"
        },
        "promoMsg": {
            "304": "促銷代碼無效",
            "306": "促銷代碼無效，這個促銷活動可能適用於其他區域。",
            "413": "抱歉，此促銷代碼目前不可用",
            "414": "促銷代碼無效",
            "415": "促銷代碼無效",
            "416": "該促銷代碼已被使用。",
            "419": "抱歉，此促銷代碼目前不可用",
            "420": "抱歉，您已達到促銷用途限制",
            "421": "抱歉，此促銷代碼已達到其發佈的使用限制",
            "422": "此促銷代碼無法在您的帳戶下使用",
            "423": "抱歉，您已達到有限的促銷預算",
            "424": "促銷代碼無效，這個促銷活動只適用於推薦客戶",
            "425": "此促銷代碼已達到每天的使用限制。",
            "426": "您已達到每天的促銷使用次數限制。",
            "427": "無法應用促銷代碼，該客戶的個人資料不完整。",
            "428": "該客戶已用完每月促銷代碼。",
            "430": "您已用完年度促銷代碼。",
            "431": "促銷代碼不能用於此付款方式。",
            "432": "此票價金額不符合使用促銷代碼的條件。",
            "433": "促銷代碼無法在選定時間使用。",
            "434": "抱歉，您已達到有限的促銷預算。",
            "phoneInvalid": "請在應用促銷代碼之前輸入乘客的電話號碼"
        },
        "updateBookingMsg": {
            "303": "無法更新訂單，請再試一次！",
            "304": "未找到此訂單",
            "pickUpTime": "您選擇的時間必須晚於當前時間！",
            "pickup": "該地區尚未開通服務",
            "destination": "某些訂單信息無效或缺失，請檢查並重試！",
            "dispatchType": "某些訂單信息無效或缺失，請檢查並重試！"
        },
        "sessionExpired": {
            "403": "您已註銷，因為您的帳戶已從其他設備登錄或您的會話已過期"
        },
        "not_found": {
            "404": "您請求的頁面無法找到"
        },
        "booking": {
            "303": "您選擇的時間必須晚於當前時間！",
            "304": "未找到此訂單",
            "305": "Booking.com 取消或完成訂單； 請拒絕該訂單以將其從排程中刪除。",
            "306": "沒有為所選車型套用車費，要對車型套用車費，請轉至設置 > 車輛 > 車型。",
            "307": "Can not update booking. Please try again later.",
            "407": "額外目的地車費類型",
            "500": "某些訂單信息無效或缺失，請檢查並重試！",
            "upgroup_success": "訂單已成功取消合組",
            "Please_Select_driver": "請選擇司機",
            "assign_driver_vehicle_success": "Driver/Vehicle has been assigned successfully",
            "assign_driver_success": "成功安排司機",
            "detach_driver_success": "Driver has been detached successfully",
            "detach_vehicle_success": "Vehicle has been detached successfully",
            "Group_success": "訂單已成功合組",
            "change_supplier_success": "Booking has been changed suppliers successfully",
            "change_supplier_partial": "Partial Success: Batch assignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
            "detach_driver_partial": "Partial Success: Batch assignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
            "assign_driver_partial": "Partial Success: Batch assignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
            "update_dispatch": "Update & Dispatch",
            "remove_booking_false": "刪除訂單失敗",
            "remove_booking_success": "訂單已成功刪除",
            "GROUP_NOT_FOUND": "找不到組 ID",
            "NO_SHARING_BOOKINGS": "僅當客戶的訂單允許拼車時，您才可以進行對訂單合組。",
            "DIFFERENT_CAR_TYPES": "您嘗試合組的訂單有不同的車型",
            "LIMIT_EXCEEDED_ON_SEATS_OR_LUGGAGE": "Booking cannot be grouped: Seat or luggage limit exceeded.",
            "INVALID_CAR_TYPE": "無法安排司機、檢查司機的車輛座位和行李容量。",
            "VALIDATION_ERROR": "驗證錯誤",
            "INTERNAL": "內部錯誤",
            "fare_gt_zero": "車費必須大於0！",
            "edit_total_fail": "修改總費用失敗！",
            "complete_fail": "無法完成訂單！",
            "cancle_fail": "取消此訂單時出了點問題，請再試一次！",
            "incident_fail": "處理事故失敗",
            "phone_invalid": "電話號碼無效！",
            "add_psg_fail": "無法添加新乘客",
            "REMOVE_RECIPIENT_DELIVERED": "無法刪除已送達的收件人",
            "REMOVE_LAST_ACTIVE_RECIPIENT": "無法刪除最後一個生效收件人",
            "ORDER_HAS_BEEN_FINISHED": "訂單已經完成",
            "end_booking_leave": "離開此頁面將結束您的訂單。",
            "update_booking_fail": "無法更新訂單，請再試一次！",
            "EXTERNAL_ID_EXISTED": "External ID already exist on the system.",
            "area_service_unavailable": "該地區尚未開通服務",
            "pickupSameDestination": "出發地址必須與目的地不同。",
            "time_invalid": "您選擇的時間必須晚於當前時間！",
            "psg_inactivate": "該用戶已被停用，請聯繫我們的管理員",
            "data_incorect": "出了些問題，請稍後再試。",
            "preAuthorize": "請檢查您的帳戶，因為需要 {0} 的金額來保證您的訂單",
            "booking_limited": "抱歉，您已達到每分鐘/每天的最大訂單數量，請稍後再試。",
            "minimumBookAhead": "預訂接載時間必須距當前時間至少 {0} 小時 {1} 分鐘",
            "exDoDiffDo": "額外目的地必須與目的地不同。",
            "recurringTimeRange": "日期超出範圍，您只能提前 {0} 個月創建預訂，如有任何問題請聯繫管理員。",
            "recipientsLimit": "最大收件人數量為{0}。",
            "maximumBookAhead": "預訂出發時間不能遲於當前時間 {0} 天",
            "Please_select_or_add_a_card": "請選擇或添加卡！",
            "Unable_to_process_by_fare_zero": "我們無法處理您的請求，請更改車型或訂單類型，然後重試。 如果您需要任何幫助，請聯繫我們的客服",
            "Can_not_connect_to_server": "無法連接到服務器！",
            "Check_connection_or_contact_system_support": "請檢查您的連接或聯繫客服尋求幫助！",
            "Create_new_booking_success": "已成功創建新訂單！",
            "Create_new_recurring_booking_success": "已成功創建新訂單！",
            "Update_booking_success": "已成功更新該訂單！",
            "Booking_drop_of_success": "此訂單已成功送達",
            "Cancel_booking_success": "已成功取消該訂單！",
            "Booking_detail_not_found": "未找到訂單詳情！",
            "Check_promo_error": "檢查促銷代碼錯誤！",
            "Invalid_format": "無效的格式",
            "Flight_not_found": "未找到航班！",
            "Add_new_credit_error": "添加新的信用卡出錯！",
            "Socket_server_disconnected": "SOCKET服務器已斷開連接！",
            "Please_reload_page_or_check_your_connection": "抱歉，無法處理您的請求，請再試一次。",
            "request_timeout": "抱歉，您的請求超時，請再試一次。",
            "Check_customer_error": "檢查客戶錯誤！",
            "Please_select_car_type": "請選擇車型",
            "DropOff_country": "抱歉，您所請求的目的地不在此車型允許範圍內！",
            "Different_Country": "接送地點必須位於 2 個不同的國家/地區。",
            "Please_enter_destination_for_ridesharing_booking": "請輸入拼車訂單目的地",
            "Extra_detination_must_be_difference_from_destination": "額外目的地必須與當前目的地不同",
            "Pickup_address_must_be_different_from_destination": "接客地址必須與目的地不同",
            "Best_price": "最佳票價",
            "You_may_be_charged_extra_fee_if_your_trip_excceds": "如果您的行程超過，您可能需要支付額外費用",
            "You_may_be_charged_extra_fee_if_your_trip_excceds_with_params": "如果您的行程超過 {0} {1}、{2} {3}，您可能需要支付額外費用",
            "Reject_booking_success": "此訂單已被成功拒絕！",
            "Reject_fail": "拒絕此訂單時出了點問題，請再試一次！",
            "Please_select_payment_method": "請選擇付款方式",
            "Driver_signed_out_or_deactivated": "該司機剛剛註銷或已停用",
            "reservation_booking_overlaps": "抱歉，此訂單與司機已接受的另一訂單重疊。",
            "driver_is_not_available": "抱歉，該司機目前不可用。",
            "seats_luggage_limit": "無法將訂單分派給司機。 座位或行李超出限制。",
            "full_capacity": "該訂單無法分派給司機，因為訂單已達到最大載客量。",
            "marked_failed_ok": "該訂單已被標記為失敗。",
            "warning_markFail_lastRecipient": "這是該訂單的最後一位接收者。 如果您繼續將此訂單標記為失敗，則您將需要完成此訂單。",
            "BOOKINGS_LIMIT_EXCEEDED": "每組最多只能分配 5 個訂單",
            "bookingCanceled": "Booking.com 取消或完成訂單； 請拒絕該訂單以將其從排程中刪除。"
        },
        "item_no_found": "沒有找到任何項目",
        "commonMessages": {
            "greater_than_0": "請輸入一個大於 0 的值。",
            "Required_field": "這是必填欄",
            "bankPhone_is_required": "電話號碼無效",
            "bankPhone": "電話號碼",
            "distance_from_equa_or_smaller": "距離「從」必須小於或等於「到」的距離。",
            "must_be_number": "請輸入有效號碼",
            "equal_or_greater_than_0": "請輸入一個等於或大於 0 的值。",
            "must_be_integer": "請輸入整數",
            "invalid_email_format": "請輸入有效的電郵。",
            "greater_or_equa": "請輸入一個大於或等於 {0} 的值。",
            "value_must_between": "請輸入一個介於 {0} 和 {1} 之間的值。",
            "large_10x_markup_price": "修改後的價格不得超過預計價格的 10 倍。",
            "greater_than": "請輸入一個大於 {0} 的值。",
            "less_than": "請輸入一個小於 {0} 的值。",
            "less_or_equal": "請輸入一個小於或等於 {0} 的值。",
            "driver_earnings_warning": "線下支付：司機收入不得超過收取的車費。",
            "limit_amount": "請輸入小於或等於限制金額的值",
            "Integer_from_range": "請從{0} - {1}輸入整數",
            "integer_greater_than_0": "請輸入大於0的整數，（小於100，百分號和小數點分隔符為1個字符）",
            "Error_message": "錯誤！",
            "callcenter_newbooking_message": "通過電話<span class='bold'>{0}</span>建立新訂單",
            "callcenter_existed_booking_message": "號碼 <span class='bold'>{1}</span> 存在一項有效訂單 <span class='bold'>#{0}</span>",
            "callcenter_close_newbooking_form_warning": "請在收到新客戶請求之前關閉/完成您當前的訂單",
            "Emergency_SOS_Title": "緊急SOS",
            "Please_enter_a_future_date": "請輸入未來的日期",
            "Card_holder_is_required": "需持卡人",
            "Card_number_is_required": "卡號為必填項",
            "Expired_date_is_required": "到期日為必填項",
            "Credit_code_is_required": "請輸入信用代碼（最後一次驗證）",
            "Expired_date_is_invalid": "到期日期無效。 請以 mm/yy 格式輸入到期日期",
            "cvv_is_required": "需要提供CVV",
            "City_is_required": "城市為必填項",
            "State_is_required": "州/省為必填項",
            "Address_is_required": "地址為必填項",
            "Zipcode_is_required": "郵政編碼為必填項",
            "Country_is_required": "國家/地區為必填項",
            "Over_query_limit": "地圖服務錯誤，暫時無法加載。 請稍後再試。",
            "outOfInputQueueOrder": "訂單數不能大於隊列中司機的總數。",
            "Same_pickup_destination": "接載地址必須與目的地地址不同。",
            "Max_length": "最大長度為 {0} 個字符。",
            "choose_at_least_one": "請至少選擇一個選項。",
            "choose_at_least_one_intercity_schedule": "請添加時間表或允許客戶請求他們的首選時間。",
            "startDate_greater_than_endDate": "開始日期大於結束日期"
        },
        "trip": {
            "cancel_trip_success": "此訂單已成功取消！",
            "data_incorect": "尚未指派任何司機， 從下拉列表中選擇一個司機。 <br/> 如果沒有可用的司機，請選擇「自動調度」以稍後分配。",
            "INTERNAL": "內部錯誤。",
            "NOT_FOUND": "未找到行程或狀態無效。",
            "SAME_DRIVER": "被派人必須與當前司機不同。",
            "OVERLAP": "抱歉，此訂單與該司機接受的另一行程重疊。",
            "CANNOT_ASSIGN": "該司機無效（如司機未找到、未在線或未登錄）。"
        },
        "car_mgmt": {
            "Search_car_color": "搜索車輛顏色",
            "Searching": "搜尋中"
        },
        "driver_note": {
            "Search_driver_note": "搜索司機備注",
            "Searching": "搜尋中"
        }
    },
    "errors": {
        "303": "找不到用戶",
        "453": "網關不支持",
        "553": "抱歉， 客戶訂單進行中，請稍後重試。",
        "1007": "The period between the start date and the end date must be less than 60 days!!",
        "3001": "電話號碼已存在",
        "3002": "出生日期無效",
        "3003": "到期日期無效",
        "3004": "找不到司機",
        "3005": "缺少司機結算信息（銀行地址、城市、州、郵政編碼）",
        "3006": "缺少銀行賬戶信息（賬戶名稱、賬戶類型、路由號碼、賬戶號碼、ssn）",
        "3007": "您無法編輯該司機，因為他正在工作。",
        "3008": "該司機已經登錄。請讓他登出以編輯他的信息。",
        "3009": "該司機已接受一項或多項預訂。 在更新/更改司機信息之前，請先將訂單分配給其他司機。",
        "3010": "連接調度失敗。",
        "3011": "該司機在啓用之前必須進行操作設置。",
        "3012": "該司機在啓用之前必須進行操作設置。",
        "3013": "只能刪除處於停用狀態的項目。",
        "3014": "缺少必填字段。 請檢查。",
        "3015": "出生年份無效。 請檢查。",
        "3016": "無效的郵政編碼。 請檢查。",
        "3017": "無效的州/省。 請檢查。",
        "3018": "帳戶名無效。 請檢查。",
        "3019": "路由號碼無效。 請檢查。",
        "3020": "需要生日。 請檢查。",
        "3021": "ssn 號碼無效。 請檢查。",
        "3022": "司機的銀行賬戶無法收到資金。",
        "3023": "登錄的司機無法操作，請稍後再試。",
        "3026": "司機已不復存在。",
        "3027": "司機的銀行賬戶未設立。 請前往司機模塊設置他的銀行賬戶。",
        "3028": "未能支付所選司機的費用。 請再試一次。",
        "3029": "清除司機收入數據失敗，請再試一次。",
        "3032": "該用戶名已存在於我們的系統中，請輸入另一項。",
        "3033": "BSB 無效。 該號碼應為 6 位數字，格式為 xxxxxx。",
        "3039": "需要組 ID。",
        "3040": "該電話號碼已被 %{brandName} 司機使用（即電話號碼不能重復）。 請輸入另一號碼",
        "3041": "用戶名已存在。",
        "3044": "國民身份證重復。",
        "3045": "Driver already has a vehicle, Please refresh page",
        "3046": "The company of the vehicle and the company of the driver are different.",
        "4001": "您的車牌號已在系統中存在。 請輸入另一項。",
        "4002": "到期日期無效",
        "4003": "刪除前必須停用車輛",
        "4004": "未找到車輛",
        "4005": "這輛車已經停用",
        "4006": "該車正在使用中，無法刪除或停用",
        "4007": "車牌號為必填項",
        "4009": "The vehicle is being assigned to a booking.",
        "4010": "The vehicle is being assigned to a driver.",
        "5001": "供應商已存在",
        "5004": "未找到供應商",
        "6001": "車型已存在",
        "6004": "未找到車輛型號",
        "6005": "車型正在使用中",
        "7001": "您的車輛品牌名稱已存在於系統中。 請輸入另一項。",
        "7004": "找不到車輛品牌",
        "8001": "車輛類型已存在",
        "8004": "未找到車輛類型",
        "9001": "固定計費路線存在",
        "9004": "未找到固定計費路線",
        "10001": "區域圍欄已存在",
        "10004": "未找到區域圍欄",
        "11001": "區域圍欄已存在",
        "11002": "該區域與另一區域重疊。 請再畫一次。",
        "11003": "區域圍欄必須是多邊形對象",
        "11004": "未找到區域圍欄",
        "11005": "區域圍欄正在使用中",
        "12001": "收件箱已存在",
        "12002": "請配置 SMS Twilio",
        "12004": "未找到收件箱",
        "12005": "沒有與您的過濾器匹配的收件人。 請檢查列表並重試。",
        "12006": "文件中不存在客戶。",
        "13001": "該促銷代碼已存在於系統中。 請輸入另一項",
        "13002": "無效日期",
        "13003": "私人",
        "13004": "未找到促銷代碼",
        "13005": "僅限新客戶",
        "13006": "超過數量限制",
        "13007": "超出預算限額",
        "13008": "超過總使用限制",
        "13011": "此促銷活動已停用或刪除",
        "13012": "日期範圍無效",
        "13013": "促銷代碼無效。 好像這個促銷活動也適用於其他區域",
        "13014": "您選擇的區域已被停用。",
        "14004": "未找到訂單",
        "15002": "您的服務已停用，請聯繫我們的客服",
        "15004": "沒有找到運營商",
        "16001": "您的固定計費價格包已存在於系統中，請輸入另一項",
        "16004": "未找到價格包",
        "17001": "名稱已存在",
        "17004": "未找到價格包",
        "18001": "名稱已存在",
        "18002": "使用中的價格包",
        "18004": "未找到價格包",
        "19001": "您的價格包已在系統上存在，請輸入另一項。",
        "19002": "使用中的價格包",
        "19004": "未找到價格包",
        "20001": "此用戶名已存在於我們的系統上。請輸入另一個。",
        "20002": "您的帳戶停用。請聯繫我們的客服",
        "20003": "您輸入的用戶名或密碼不正確。 請再試一次",
        "20004": "用戶名未在系統中註冊。",
        "20005": "該鏈接已過期",
        "20006": "當前密碼不匹配。 請再試一次。",
        "20007": "用戶#已存在",
        "20008": "This email already exists on our system. Please enter another one.",
        "21001": "您的權限名稱已在系統中存在。 請輸入另一項。",
        "21002": "無法停用此權限，因為它當前已套用給用戶。",
        "21003": "角色啓用",
        "21004": "未找到角色",
        "22001": "客戶已存在",
        "22004": "未找到客戶",
        "22005": "文件格式無效。",
        "24001": "電話號碼無效",
        "25001": "該名稱已存在於系統中。 請輸入另一項。",
        "25002": "時間範圍無效",
        "25004": "沒有找到排班",
        "26001": "該名稱已存在於系統中。 請輸入另一項。",
        "26002": "此排班正在使用中，請在刪除前取消套用",
        "26004": "找不到排班模板",
        "27001": "推廣活動已存在",
        "27002": "推廣活動已啓動",
        "27004": "找不到推廣活動",
        "28001": "城市已經存在",
        "28002": "城市正在啓動",
        "28003": "城市正在使用中",
        "28004": "找不到城市",
        "29002": "電話號碼已存在",
        "30001": "未找到排隊區域",
        "30002": "使用中的排隊區域",
        "30003": "您的排隊區名稱已在系統中存在。 請輸入另一項",
        "30004": "排隊區域不得相互重疊",
        "30005": "排隊區坐標無效",
        "30006": "上車區域無效",
        "31001": "您的公司ID已存在於系統中。 請輸入另一項。",
        "31002": "該用戶名已存在於我們的系統中。 請輸入另一項。",
        "31003": "未找到企業。",
        "31004": "未找到企業用戶。",
        "31005": "只有停用的企業才能被刪除。",
        "31006": "該電話屬於某企業用戶身上。請輸入另一個電話。",
        "31007": "未找到企業乘客。",
        "31008": "未找到卡。",
        "31009": "該電話已存在於系統中。 請輸入另一項。",
        "31010": "該企業已被停用， 請聯繫管理員獲取更多信息",
        "31011": "此乘客不能被啓用，因為它屬於停用的企業帳戶",
        "31013": "已存在同名企業",
        "32001": "您的服務名稱已在系統中存在。 請輸入另一項。",
        "32004": "無法提供服務",
        "33002": "時間範圍不得相互重疊",
        "34001": "代碼已存在",
        "34013": "日期範圍無效",
        "36001": "名稱已存在",
        "39001": "The document is required",
        "71001": "存在動態附加費",
        "71002": "該區域與另一區域重疊。 請再畫一次。",
        "71003": "動態附加費區域必須是比運營區域小的多邊形",
        "71004": "未找到動態附加費",
        "72001": "存在動態票價",
        "72002": "該區域與另一區域重疊。 請再畫一次。",
        "72003": "動態票價必須是比運營區域小的多邊形",
        "72004": "未找到動態票價",
        "74001": "區域已存在",
        "74002": "該區域與另一區域重疊。 請再畫一次。",
        "74003": "區域必須是多邊形",
        "74004": "未找到區域票價",
        "74005": "未找到區域票價",
        "80001": "App橫幅已存在。",
        "80002": "找不到App橫幅。",
        "80003": "只能刪除處於停用狀態的項目。",
        "81001": "文件上傳為空。",
        "81002": "文件上傳有大小限制。",
        "82002": "設置 Flightaware 時出現問題。 請稍後再試。",
        "90001": "專線區域圍欄存在",
        "90002": "該區域與另一區域重疊。 請再畫一次。",
        "90003": "專線必須是多邊形圍欄",
        "90004": "未找到專線區域圍欄",
        "90005": "專線區域正在使用中",
        "91001": "已有專線價格包",
        "91002": "專線價格包正在使用中",
        "91003": "未找到專線價格包",
        "92001": "專線名稱已存在",
        "92002": "未找到專線路線",
        "92003": "該專線路線已存在於其他路線中，請檢查並重試",
        "92004": "這條專線路線上有一個生效的行程。",
        "93001": "系統中已存在司機備注。 請輸入另一項。",
        "93002": "司機備注處於停用狀態，可以刪除。",
        "93003": "司機備注正在使用。",
        "93004": "未找到司機備注。",
        "94001": "您的車輛顏色名稱已存在於系統中。 請輸入另一項。",
        "94002": "未找到車輛顏色",
        "400000": "電話號碼無效！",
        "400002": "A validation error has occurred.",
        "400003": "沒有權限",
        "400005": "文件內容無效",
        "500000": "內部系統錯誤。",
        "500001": "請配置 SMS Twilio",
        "500002": "請求超時。 請稍後再試",
        "600001": "未找到位置",
        "600002": "該經度和緯度坐標已存在於系統中。 請添加另一個",
        "610001": "類別已存在",
        "610002": "未找到類別",
        "610003": "類別已存在於聚會地點",
        "700001": "類型正在使用中",
        "700002": "未找到個人夥伴",
        "700003": "您的個人夥伴類型名稱已在系統中存在。 請輸入另一項。",
        "800000": "該商店已分配給另一個商家帳戶。 請選擇另一項。",
        "800001": "商戶的銀行賬戶無法收到資金。",
        "800002": "商戶的銀行賬戶無法收到資金。",
        "undefined": "網絡故障，請再試一次。",
        "completeBooking": {
            "113": "我們無法驗證您的付款方式。 請選擇其他付款方式並重試。",
            "450": "The balance is insufficient to pay. Please select another method",
            "451": "The balance is insufficient to pay. Please select another method",
            "521": "錢包沒有足夠的資金。"
        },
        "upload_file_fail": "文件無效，請檢查並重試。",
        "upload_photo_fail": "文件無效，請檢查並重試。",
        "missing_some_field": "字段無效，請檢查並重試。"
    },
    "statusDisplay": {
        "supplierAccepted": "Supplier accepted",
        "delivery_supplierAccepted": "Supplier accepted",
        "completedWithoutService": "使用外部司機完成訂單",
        "confirmed": "確認訂單",
        "pending": "待辦訂單",
        "queue": "調度中",
        "offered": "調度中",
        "booked": "司機前往接載",
        "engaged": "行程中",
        "droppedOff": "送達",
        "pre": "訂單已接受",
        "recurrent": "復發",
        "vacant": "",
        "vacantTime": "",
        "arrived": "抵達接載地",
        "completed": "已完成",
        "canceled": "取消",
        "noShow": "NOSHOW",
        "action": "等待處理",
        "incident": "事故",
        "pickupTimeExceeded": "超時",
        "canceledByCC": "控制中心取消",
        "canceledByPassenger": "乘客取消",
        "canceledByDriver": "被司機取消",
        "canceledByPartner": "已被合作夥伴取消",
        "canceledBymDispatcher": "已被合作夥伴取消",
        "canceledByCorporateAdmin": "由企業管理員取消",
        "canceledByAPI": "通過 API 取消",
        "canceledByWebBooking": "通過WEB預訂取消",
        "canceledByTimeout": "超時取消",
        "accepted": "接受",
        "rejected": "拒絕",
        "canceledByCorpAd": "由企業管理員取消",
        "started": "開始",
        "intransit": "在途中",
        "empty": "空的",
        "notLive": "部分 - 非實時",
        "live": "部分 - 實時",
        "full": "完整",
        "delivering": "送貨中",
        "delivery_booked": "司機正在前往發件人的路上",
        "delivery_arrived": "已到達並等待發件人",
        "shopForYou_booked": "司機在領取現金的路上",
        "shopForYou_arrived": "已抵達並等待領取現金",
        "collecting": "司機在取貨途中",
        "confirmed_delivery": "已確認",
        "food_booked": "司機正在前往商家的路上",
        "food_collecting": "已到達並等待領取物品",
        "mart_collecting": "已到達並等待領取物品",
        "otwMerchant": "司機正在前往商家的路上",
        "arrivedAndWaitingToCollectItem": "已到達並等待領取物品",
        "canceledByAirlineAdmin": "被航空公司管理員取消",
        "partialCompleted": "部分完成",
        "failed": "失敗的",
        "allocated": "已分配"
    },
    "tripTypes": {
        "scheduled": "預定行程",
        "requested": "請求行程",
        "preferred": "首選"
    },
    "login": {
        "Username": "用戶名",
        "Password": "密碼",
        "Remember_me": "記住賬號",
        "Forgot_your_password": "忘記密碼",
        "Login": "登錄"
    },
    "invoice": {
        "createBtn": "創建賬單",
        "searchPlInvoice": "搜索客戶、聯繫人、發票號碼",
        "searchPl": "搜索訂單號、客戶名稱",
        "createInvoice": "創建賬單",
        "invoicePeriod": "賬單期限",
        "invoicePeriodNote": "您可以選擇逐月的日期範圍。",
        "viewEdit": "查看/編輯",
        "billingContact": "帳單聯繫人",
        "memo": "備忘錄",
        "memoMessage": "給客戶留言",
        "dueDate": "到期日",
        "updateInvoice": "更新賬單",
        "chargeCustomer": "向客戶收費",
        "invoiceNumber": "賬單號碼",
        "noItem": "項目",
        "issuedDate": "發佈日期",
        "refId": "參考號",
        "pickupTime": "接載時間",
        "pickup": "接載地",
        "destination": "目的地",
        "customerName": "乘客姓名",
        "totalAmount": "Amount Due",
        "amountDue": "應付金額",
        "sendInvoice": "發送賬單",
        "copyInvoice": "複製賬單",
        "downloadPDF": "下載 PDF 格式",
        "paymentLink": "付款鏈接",
        "deleteInvoice": "刪除賬單",
        "onlySave": "保存賬單",
        "saveSend": "保存併發送賬單",
        "sendEmail": "發電子郵件",
        "addRecipients": "添加收件人",
        "editInvoice": "編輯賬單",
        "check": "檢查",
        "wire": "電匯",
        "digitalWallet": "數字錢包",
        "cash": "現金",
        "completedTime": "完成時間",
        "Status": "狀態",
        "bookingStatus": "訂單狀態",
        "chargeBtn": "收費{0}",
        "pastDue": "逾期",
        "payCompleted": "此賬單已支付成功！",
        "Delete": "刪除賬單？",
        "Delete_content": "此操作將永久刪除該賬單， 如有必要，您可以為這些訂單創建一個新賬單。",
        "viewInvoice": "查看賬單",
        "pending": "待付款",
        "paid": "已付款",
        "CopyLink": "複製鏈接"
    },
    "cue": {
        "jobVisibility": "Job Visibility",
        "jobPubic": "Public",
        "jobPrivate": "Private",
        "jobSetPubic": "Set Public",
        "jobSetPrivate": "Set Private",
        "supplierAccepted": "Supplier accepted",
        "creationTime": "創建時間",
        "Pax": "人數#",
        "Luggage": "行李",
        "allocated": "已分配",
        "completedWithoutService": "使用外部司機完成訂單",
        "shuttle": "穿梭",
        "farmIn": "接收外單",
        "chargeCode": "收費代碼",
        "clientCaseMatter": "行程說明",
        "operatorNote": "內部備注",
        "farmOut": "外發訂單",
        "roaming": "漫遊",
        "FlightUpdate": "航班更新",
        "networkType": "網絡類型",
        "InHouse": "本地訂單",
        "carHailing": "叫車",
        "uploadBookings": "上傳預訂",
        "moreActions": "更多操作",
        "rideSharing": "拼車",
        "Rearrange": "重新整理",
        "GroupId": "群組 ID#",
        "Active": "進行中",
        "Finished": "已完成",
        "Home_affiliate": "歸屬/聯盟",
        "Booking_type": "訂單類型",
        "From": "從",
        "balanceWallet": "{0}：剩餘 {1}",
        "partialWallet": "使用電子錢包支付部分費用",
        "To": "至",
        "Status": "狀態",
        "Migration_Status": "遷移狀態",
        "Migrated": "已遷移",
        "Select_Status": "選擇狀態",
        "Operator": "操作員",
        "Car_types": "車型",
        "Service_class": "Service class",
        "Booking_from": "預訂自",
        "All": "全部",
        "Pending": "待辦",
        "Dispatching": "調度中",
        "Waiting_for_process": "等待處理",
        "Confirmed_reservation": "確認訂單",
        "Driver_on_the_way": "司機前往接載",
        "Arrived_and_waiting": "抵達接載地",
        "Passenger_on_board": "行程中",
        "Dropped_off": "送達",
        "combobox_select": "選擇...",
        "Search_here": "在這裡搜索",
        "Bookingid": "訂單#",
        "Pickup_Drop_Off": "接載/送達",
        "Pickup_Drop_Off_Crew": "接送時間",
        "Pickup_Drop_Off_Local_Time": "操作時間",
        "Pickup_Location_Destination": "接送地點/目的地",
        "Passenger": "乘客",
        "Intercity_Route": "專線",
        "Airport_Pickup": "機場接機",
        "Airport_Pickup_Crew": "機場接機",
        "Flight_Status": "狀態",
        "Payment": "支付",
        "Vehicle": "車輛",
        "Car_type": "車型",
        "Driver": "司機",
        "Estimated_Fare": "預計車費",
        "Notes": "備注",
        "Note": "備注",
        "Type": "類型",
        "Home": "本地",
        "Affiliate": "聯盟",
        "now": "Now (Transport)",
        "reservation": "Reservation (Transport)",
        "recurring": "復發約車",
        "No_show": "NOSHOW",
        "Canceled_by_CC": "由控制中心取消",
        "Canceled_by_passenger": "乘客取消",
        "Canceled_by_partner": "已被合作夥伴取消",
        "Canceled_by_mDispatcher": "已被合作夥伴取消",
        "Canceled_by_Corporate_Admin": "已被企業管理員取消",
        "Canceled_by_API": "通過 API 取消",
        "canceled_By_Web_Booking": "通過WEB預訂取消",
        "canceled_By_Time_Out": "超時取消",
        "Canceled": "取消",
        "Completed": "已完成",
        "Incident": "事故",
        "Payment_Method": "付款方式",
        "No_Phone": "沒有電話",
        "VIP": "VIP",
        "Non_VIP": "非VIP",
        "Passenger_type": "乘客類型",
        "Error_load_booking": "無法加載訂單，請重試或刷新頁面！",
        "Edit_total_success": "總車費編輯成功！",
        "Edit_total_fail": "總車費必須等於或大於 {0}",
        "Time_out": "超時",
        "Ride_Service": "乘車服務",
        "Regulation": "常規",
        "Ride_Sharing": "拼車",
        "Unmatching": "不匹配",
        "Matching": "匹配",
        "all": "全部",
        "matching": "匹配",
        "unmatching": "不匹配",
        "regulation": "常規",
        "Support_service": "支持服務",
        "SOS": "SOS",
        "Non_SOS": "非SOS",
        "Corporate": "企業",
        "Individual": "個人",
        "On_curb": "在外等待",
        "Meet_Greet": "舉牌服務",
        "Close": "關閉",
        "auto_refrest_cue": "自動刷新（{0} 秒）",
        "Ride_type": "乘坐類型",
        "Edit_total_note": "注：小計、技術費、預訂費、稅費和小費將在更改總金額時更新",
        "Booking": "訂單",
        "Edit_fare": "編輯車費",
        "Adjust_price": "調整價格",
        "Total": "全部的",
        "Reason": "原因",
        "Please_enter_reason": "請輸入原因。",
        "Save": "儲存",
        "Cancel": "取消",
        "dmc": "預訂面板",
        "corp": "預訂面板 (CTM)",
        "Zone": "接送區",
        "mDispatcher": "合作夥伴",
        "streetSharing": "街頭拼車",
        "Driver_No_Job_found": "等待任務...",
        "expandFilter": "顯示過濾器",
        "collapseFilter": "隱藏過濾器",
        "clearSelectedItems": "清除所選項目",
        "Accepted": "接受",
        "Yes": "是",
        "No": "否",
        "Order_accepted": "訂單已接受",
        "Confirmed": "確認",
        "spotTime": "現貨時間",
        "ETA": "預計到達時間",
        "spareTime": "空余時間",
        "expectedTime": "預計接載時間",
        "Driver_on_the_way_to_sender": "司機正在前往發件人的路上",
        "Driver_on_the_way_to_recipient": "司機正在前往收件人的路上",
        "Driver_on_the_way_to_collect_cash": "司機在領取現金的路上",
        "Driver_on_the_way_to_collect_goods": "司機在取貨途中",
        "Arrived_waiting_for_sender": "已到達並等待發件人",
        "Arrived_waiting_for_recipient": "已到達並等待收件人",
        "Arrived_waiting_to_collect_cash": "已抵達並等待領取現金",
        "Arrived_waiting_to_deliver_goods": "已到達並等待發貨",
        "Delivered": "送貨",
        "delivery": "送貨",
        "Service": "服務",
        "intercity": "專線",
        "Transport": "出行約車",
        "About_Sender": "關於發件人",
        "About_Merchant": "關於商戶",
        "About_Recipient": "關於收件人",
        "photos": "像片",
        "listOfItems": "項目清單",
        "cashOnPickUp": "取貨付款",
        "Package_information": "包裹信息",
        "otwMerchant": "司機正在前往商家的路上",
        "arrivedAndWaitingToCollectItem": "已到達並等待領取物品",
        "Save_Filter": "保存過濾器",
        "add_favorite_filter": "添加過濾器",
        "favorite_filter_name": "名字",
        "list_favorite_filter": "管理過濾器",
        "favorite_filter_actions": "操作",
        "clear_all_filters": "清除所有過濾器",
        "Delete_Filter": "刪除過濾器",
        "CONFIRM_DELETE_FILTER_TEMPLATE": "您確定要刪除此過濾器嗎？",
        "batchDelivery": "批量發貨",
        "editFare": {
            "creditTransactionFee": "信用卡費用",
            "preferredAdjustment": "首選調整",
            "editFareFee": "編輯車費和費用",
            "markupPrice": "加價",
            "quotedPrice": "報價",
            "basicFare": "基本車費",
            "preferredEarning": "首選收入",
            "difference": "不同之處",
            "rushHourFee": "附加費",
            "serviceFee": "附加服務",
            "otherFees": "其他費用",
            "subTotal": "小計",
            "airportFee": "機場費",
            "meetDriverFee": "舉牌",
            "deliveryFee": "快遞費",
            "tollFee": "路橋費",
            "parkingFee": "Parking fee",
            "gasFee": "Gas fee",
            "techFee": "技術費",
            "tip": "小費",
            "bookingFee": "預訂費",
            "tax": "稅",
            "promoAmount": "促銷金額",
            "etaFare": "全部的",
            "fleetService": "運營商服務",
            "Note_serviceFee": "(to edit additional service, visit outer list)",
            "startingFee": "Starting",
            "moveFeeInJourney": "Moving",
            "waitFeeInJourney": "Waiting (in-journey)"
        },
        "partialCompleted": "部分收款",
        "failed": "失敗的",
        "Recurring": "復發#",
        "DragAndDrop": "拖放以重新排序",
        "bookingCom": "Booking.com",
        "syncingCompleted": "同步完成",
        "groupManifest": "Group Manifest",
        "assignSuppliers": "Assign Suppliers",
        "viewActions": "View Actions",
        "holidaytaxis": "HolidayTaxis",
        "karhoo": "Karhoo",
        "pickupTime": "Pick-up time",
        "AssignSupplier": "Assign Supplier",
        "sharedTransfer": "Shared Transfer (%{id})",
        "batchDeliveryGroup": "Batch Delivery (%{id})",
        "foundersFund": "Founders Fund",
        "zeroCommission": "Zero Commission",
        "unassignDriver": "Unassign Driver",
        "notUnassignDriver": "Cannot unassign driver, Please try again",
        "notAssignDriver": "Cannot assign driver, Please try again",
        "selectDriver": "Select Driver",
        "unassignVehicle": "Unassign Vehicle",
        "unassignAll": "Unassign All",
        "notUnassignAll": "Cannot unassign all, Please try again",
        "unassignAllSuccess": "The vehicle and driver have been successfully detached.",
        "unassign_all_partial": "Partial Success: Batch unassignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
        "notUnassignVehicle": "Cannot unassign vehicle, Please try again",
        "notAssignVehicle": "Cannot assign vehicle, Please try again",
        "selectVehicle": "Select Vehicle",
        "confirmUnassign": "Are you sure you want to unassign?"
    },
    "trip": {
        "Driver_vehicle": "司機的車輛",
        "tripId": "行程 ＃",
        "tripTypes": "行程類型",
        "Start_Time": "開始時間",
        "Seats_Status": "座位狀態",
        "No_Of_Booking": "訂單數量",
        "Vehicle": "司機的車輛",
        "Estimated_Fare": "車費",
        "capacityStatus": "座位狀態",
        "Trip_Types": "行程類型",
        "scheduled": "預約行程",
        "requested": "請求行程",
        "preferred": "首選",
        "All": "全部",
        "Confirmed": "確認的",
        "Started": "開始",
        "In_Transit": "在途中",
        "Empty": "空的",
        "Partial_not_live": "部分 - 非實時",
        "Partial_live": "部分 - 實時",
        "Full": "滿的",
        "Error_load_trip": "無法加載行程，請重試或刷新頁面！"
    },
    "tripDetails": {
        "Trip_ID": "專線",
        "DRIVER_JOB": "司機信息",
        "BOOKINGS": "訂單",
        "updateTrip": "更新行程",
        "cancelTrip": "取消行程",
        "note_cancel": "在取消此行程之前，請將所有訂單分配給其他司機。",
        "TRIP_INFORMATION": "行程信息",
        "route": "線路",
        "startTime": "開始時間",
        "distance": "距離",
        "duration": "時間",
        "fare": "車費",
        "cartype": "專線車型",
        "seatStatus": "座位狀態",
        "started": "開始",
        "intransit": "在途中",
        "empty": "空的",
        "notLive": "部分 - 非實時",
        "live": "部分 - 實時",
        "full": "滿的",
        "noOfBooking": "訂單數量",
        "luggage": "行李",
        "nameDriver": "姓名",
        "vehicleType": "車輛",
        "assignDriver": "指派司機",
        "pickUpAddress": "出發地",
        "destinationAddress": "目的地",
        "customerName": "姓名",
        "customerPhone": "電話",
        "customerNumber": "座位數",
        "customerLuggageNumber": "行李",
        "additionalService": "額外服務",
        "paymentBooking": "支付",
        "note": "備注",
        "CONFIRM_UPDATE": "確認更新",
        "CONFIRM_UPDATE_MESSAGE": "您確定要更新此行程嗎？",
        "Yes": "是",
        "No": "否",
        "Update_info": "是的，更新信息"
    },
    "newbooking": {
        "Search_Vehicle": "Search for vehicle",
        "rideSharing_multi_point": "拼車訂單僅允許 1 個上車點和 1 個下車點。",
        "Pick_up_time": "接載時間",
        "Drop_off_time": "送達時間",
        "All_Suppliers": "所有供應商",
        "Recipient": "收件人",
        "All": "全部",
        "New_Booking": "新訂單",
        "GoogleMapRoute": "谷歌地圖",
        "BOOKING_INFORMATION": "訂單信息",
        "TIP_PROMO": "小費 - 促銷",
        "Pick_up": "接載",
        "instructions": "指示",
        "instructions_placeholder": "在此列出您的說明...",
        "Enter_a_location": "輸入地點",
        "Enter_a_location_delivery": "輸入地點",
        "Enter_3rd_party_location": "輸入第 3 方位置",
        "Enter_a_sender": "輸入發件人",
        "Enter_a_recipient": "輸入收件人",
        "Vehicle_Selection": "車輛選擇",
        "Destination": "目的地",
        "Pickup_time": "接載時間",
        "Note": "備注",
        "note_for_driver": "司機注意事項",
        "affiliate_note": "聯盟注意事項",
        "affiliate_note_placeholder": "輸入聯屬操作員備注",
        "operator_note": "輸入操作員的內部備注",
        "TRAVELER_INFORMATION": "乘客信息",
        "Traveler_type": "乘客類型",
        "Individual": "個人",
        "Corporation": "公司",
        "Phone_number": "電話號碼",
        "First_name": "姓氏",
        "Last_name": "名字",
        "Email_address": "電子郵件地址",
        "Corp_id": "公司編號",
        "Manager_name": "經理姓名",
        "Manager_email": "經理的電子郵件",
        "Cost_centre": "成本中心",
        "Department": "部門",
        "Corp_division": "集團事業部",
        "Tip": "小費",
        "Promo_code": "促銷代碼",
        "Apply": "套用",
        "Promo": "促銷：",
        "DISPATCH": "調度",
        "Auto_dispatch": "自動調度",
        "Assign_driver": "指派司機",
        "FarmOut": "外發訂單",
        "Driver": "司機",
        "Driver_Supplier": "Supplier/Driver",
        "PAYMENT_METHOD": "付款方式",
        "OPERATOR_NOTE": "內部備注",
        "TRIP_ESTIMATE": "行程預估",
        "Choose_a_stored_card": "選擇綁定的卡",
        "Choose_a_payment_method": "選擇付款方式",
        "Use_new_card": "使用新卡",
        "Card_holder": "持卡人",
        "Card_number": "卡號",
        "Expired_date": "有效日期（mm/yy)",
        "ccv": "CVV",
        "Distance": "距離",
        "Duration": "時間",
        "Fare": "車費",
        "Route": "線路",
        "Create": "創建",
        "Cancel": "取消",
        "Meet_driver": "舉牌",
        "Airline": "航空公司",
        "Flight": "航班 ＃",
        "FLY_INFO": "航班信息",
        "Ride_Sharing": "拼車",
        "Round_trip": "往返",
        "Car_type": "車型",
        "Address": "地址",
        "City": "城市",
        "State": "州",
        "Zip_code": "郵政編碼",
        "Remove": "去除",
        "Client_case_matter": "客戶案例/行程描述",
        "Charge_code": "費用代碼/行程代碼",
        "CANCEL_BOOKING": "關閉預訂確認",
        "CANCEL_BOOKING_CONFIRM": "您想離開此訂單嗎？ 您將丟失所有訂單數據。",
        "Search_here": "在這裡搜索",
        "No_support_location": "抱歉該地區暫時未開通服務",
        "Closest_driver_message": "最近的司機大約在 {0} 之外",
        "No_cars_available": "調度半徑內無可用車輛。",
        "processed_by_affiliate": "您的請求將由我們的合作夥伴處理",
        "FlightExample": "航班號，例如 AA1097",
        "Yes": "是",
        "No": "否",
        "dispatch_3rd": "第三方調度",
        "Company": "車隊供應商",
        "Card_required": "請選擇或添加卡！",
        "Tip_value": "N/A",
        "Calendar": "日曆",
        "Extra_Destination": "額外目的地",
        "Driver_credit_limited_balance_warning": "該司機的餘額小於或等於限額",
        "Meet_Greet_option": "舉牌服務",
        "On_Curb_option": "在外等待",
        "Extra_Services": "額外服務",
        "Addl_Services": "額外服務",
        "Save": "保存",
        "Regular": "標準計費",
        "Please_fill_in_all_the_required_fields": "請填寫所有必填字段。",
        "Please_select_weekdays": "請選擇您希望進行此預訂的復發日期",
        "Flat_rate": "固定計費",
        "All_company": "所有供應商",
        "Best_price": "最佳票價",
        "PromoTitle": "促銷",
        "Show_more": "展示更多",
        "Warning_pending_payment": "該客戶過去的訂單付款未完成：%{fare}",
        "Warning_outstanding_balance": "欠款將在行程結束時一並計算。",
        "Not_available": "無法使用",
        "Warning_assigned_rate": "請為所選車型設置並套用價格包以繼續。",
        "PaxLuggageNumber": "旅客/行李件數",
        "paxNumber": "乘客",
        "luggageNumber": "行李",
        "Date": "日期",
        "Single": "單獨",
        "Recurring": "復發訂單",
        "Date_range": "定制天數",
        "WeekDays": "復發幾天",
        "Create_Route": "創建線路",
        "Pickup_Point": "接載",
        "Drag_To_Render": "拖放以重新排序",
        "DropOff_Point": "目的地",
        "Sender": "發件人",
        "Recipients": "收件人",
        "Sender_Infomation": "發件人信息",
        "Recipient_Infomation": "收件人信息",
        "Recipient_Name": "收件人姓名 *",
        "Recipient_Phone": "電話號碼 *",
        "Room_floor_block": "房間、樓層和街區（可選）",
        "Details": "細節",
        "Package_Content": "包裹內容 *",
        "Order_ID": "訂單號（例如 Shopify 訂單號）",
        "Instructions_for_driver": "司機須知",
        "Cost_of_Goods": "貨到付款 (COD)",
        "Enter_amount": "輸入金額",
        "Additional_Information": "附加信息",
        "Insurance": "保險",
        "Special_Care": "特殊照護",
        "Delivery_Fee": "快遞費",
        "Item_fees": "項目費用",
        "Tech_Fee": "技術費",
        "Tax": "稅",
        "driverNotes": "司機注意事項",
        "rejectModal": "拒絕訂單",
        "rejectModalBody": "您確定要拒絕此訂單嗎？",
        "rejectButton": "拒絕訂單",
        "acceptModal": "接受訂單",
        "acceptModalBody": "您確定要接受此訂單嗎？",
        "ConfirmedTo": "Confirmed to ({0})",
        "proceedAction": "Are you sure you want to proceed with this action?",
        "acceptButton": "接受訂單",
        "denyModal": "拒絕訂單",
        "denyModalBody": "您確定要拒絕此訂單嗎？",
        "denyButton": "拒絕訂單",
        "Accept_booking_success": "此訂單已成功接受！",
        "Deny_booking_success": "此訂單已被成功拒絕！",
        "adjustFare": "調整車費",
        "totalFare": "總費用",
        "Profit": "利潤",
        "DriverEarning": "司機收入",
        "Payout": "Payout",
        "totalPayout": "Total payout",
        "DriverPayOut": "支付司機",
        "confirmReject": "確認拒絕",
        "confirmCancelMSG": "您確定要拒絕此訂單嗎？ 您可能會受到服務商的處罰。",
        "reasonCancel3rd": "拒絕原因",
        "welcome_psg": "歡迎乘客",
        "AmountDue": "應付金額",
        "Fleet_Company": "Fleet/Company",
        "Search_driver_hydra": "我們會盡力盡快為您找到司機。",
        "ExternalID": "External ID",
        "viewRate": "View Rate",
        "PointToPoint": "Point to point",
        "Offer_driver": "Offer Driver",
        "Assign_driver_vehicle": "Assign Driver / Vehicle",
        "notify_via_email": "Notify Customer via Email"
    },
    "bookingDetailLogs": {
        "recipient": "Recipient",
        "updatedInfo": "Updated info",
        "updatedField": "Updated field",
        "valueBefore": "Value before",
        "valueAfter": "Value after",
        "location": "Location",
        "etaFare": "Fare",
        "dispatchType": "Dispatch",
        "drvInfo": "Driver",
        "vehicleTypeRequest": "Vehicle Request",
        "clientCaseMatter": "Trip description",
        "chargeCode": "Trip code",
        "driverEarningType": "Driver Earning Type",
        "editedDriverEarning": "Driver earning",
        "reasonEditFare": "Reason",
        "bookingReference": "External ID",
        "tip": "Tip",
        "type": "Service type",
        "promo": "Promo",
        "dispatch3rd": "Dispatch 3rd",
        "rideSharing": "Ride-Sharing",
        "notes": "Driver notes",
        "operatorNote": "Internal notes",
        "welcomeMessage": "Welcome Message",
        "flightNumber": "Flight Number",
        "fullName": "Full Name",
        "email": "Email",
        "cardMask": "Card mask",
        "paymentType": "Payment method",
        "recipients": "Recipients",
        "sender": "Sender",
        "pickupName": "Pickup Name",
        "pickupPhone": "Pickup Phone",
        "pickupEmail": "Pickup Email",
        "supplierEarningType": "Payout type",
        "editedSupplierEarning": "Payout value",
        "route": "Route name",
        "markupDifference": "Markup Difference",
        "selectedDates": "Selected Dates",
        "services": "Fleet services",
        "destinationAddress": "Destination location",
        "pickupAddress": "Pick-up location",
        "tripType": "Trip Type",
        "pickUpTime": "Pickup time",
        "paxNumber": "Passenger",
        "luggageNumber": "Luggage",
        "vehicle": "Vehicle",
        "minutesToArrive": "Expected driver arrival",
        "affiliateNote": "Affiliate Note",
        "packageRateName": "Package name",
        "supplierCompanies": "Company",
        "puPoints": "Pick-up location",
        "doPoints": "Destination location",
        "promoValue": "Promo value",
        "packageInfo": "Package info",
        "orderId": "Order Id",
        "cashOnDelivery": "Cash on delivery",
        "externalId": "External Id",
        "recurring": "Recurring",
        "batchDelivery": "Batch Delivery",
        "payout": "Payout",
        "locationDetails": "Location Details",
        "name": "Name",
        "phone": "Phone",
        "address": "Address",
        "basicFare": "Basic fare",
        "airportFee": "Airport fee",
        "bookingFee": "Booking fee",
        "creditTransactionFee": "Credit card fees",
        "fleetService": "Fleet service",
        "fleetServices": "Fleet services",
        "gasFee": "Gas fee",
        "meetDriverFee": "Meet driver fee",
        "moveFeeInJourney": "Moving fee",
        "otherFees": "Other fee",
        "parkingFee": "Parking fee",
        "promoAmount": "Promo amt",
        "rushHourFee": "Rush hour fee",
        "serviceFee": "Service fee",
        "startingFee": "Starting fee",
        "subTotal": "Sub total",
        "tax": "Tax",
        "techFee": "Tech fee",
        "tollFee": "Toll fee",
        "waitFeeInJourney": "Waiting (in-journey) fee"
    },
    "bookingdetail": {
        "settings": "Settings",
        "updateSuccessJobVisibilitySingle": "Update visibility successfully",
        "updateSuccessJobVisibilityMulti": "%{number} bookings has been updated successfully",
        "failApi": "Something went wrong!",
        "settingsTooltip": "Driver already assigned or trip is currently in progress.",
        "shareJobPublicly": "Share Job Publicly",
        "groupOder": "訂單合組",
        "ExistingGroup": "現有組",
        "NewGroup": "新組",
        "Search_trip": "搜索行程#",
        "All": "全部",
        "creditTransactionFee": "信用卡費用",
        "partial_payment": "部分付款",
        "Write_off_debt": "註銷欠款",
        "Booking_detail": "訂單詳情",
        "Order_detail": "訂單詳情",
        "Completed_Booking": "已完成訂單",
        "Late_Cancel": "遲取消而罰款",
        "Cancel_Driver": "由司機取消",
        "No_Show": "NOSHOW",
        "Without_driver": "使用外部司機完成訂單",
        "Complete_driver": "司機完成訂單",
        "BOOKING_INFORMATION": "訂單信息",
        "TIP_PROMO": "小費-促銷",
        "Rearrange_Column": "重新整理列",
        "Pick_up": "出發地",
        "Enter_a_location": "輸入地點",
        "Destination": "目的地",
        "Pickup_time": "接載時間",
        "Notes": "備注",
        "TRAVELER_INFORMATION": "乘客信息",
        "Traveler_type": "乘客類型",
        "Individual": "個人",
        "Corporation": "企業",
        "Phone_number": "電話號碼",
        "First_name": "姓氏",
        "Last_name": "名字",
        "Email_address": "電子郵件地址",
        "Corp_id": "公司編號",
        "Manager_name": "經理姓名",
        "Manager_email": "經理的電子郵件",
        "Cost_centre": "成本中心",
        "Department": "部門",
        "Corp_division": "集團事業部",
        "Tip": "小費",
        "Promo_code": "促銷代碼",
        "Apply": "應用",
        "Promo": "促銷",
        "DISPATCH": "調度",
        "Auto_dispatch": "自動調度",
        "Assign_driver": "指派司機",
        "Driver": "司機",
        "PAYMENT_METHOD": "支付方式",
        "Payment_methods": "支付方式",
        "pending_payment": "待付款",
        "TRIP_ESTIMATE": "行程預估",
        "Choose_a_stored_card": "選擇綁定的卡",
        "Choose_a_payment_method": "選擇付款方式",
        "OPERATOR_NOTE": "內部備注",
        "Use_new_card": "使用新卡",
        "Card_holder": "持卡人",
        "Card_number": "卡號",
        "Expired_date": "有效日期（mm/yy）",
        "ccv": "CVV",
        "Distance": "距離",
        "Duration": "時間",
        "Fare": "票價",
        "Route": "線路",
        "Meet_driver": "舉牌",
        "Airline": "航空公司",
        "Flight": "航班 ＃",
        "FLY_INFO": "航班信息",
        "Ride_Sharing": "拼車",
        "Round_trip": "往返",
        "Car_type": "車型",
        "Address": "地址",
        "City": "城市",
        "State": "州",
        "Zip_code": "郵政編碼",
        "Remove": "去除",
        "New_Booking": "新訂單",
        "Clone_Booking": "Clone Booking",
        "New_Order": "新訂單",
        "ViewTrackLink": "查看追蹤鏈接",
        "driverLink": "Driver link",
        "ClickCopy": "Click to copy link",
        "ViewLog": "查看調度日誌",
        "Message": "Message",
        "Update_Booking": "更新訂單",
        "Cancel_Booking": "取消訂單",
        "Complete_with_payment": "完成付款",
        "Incident": "事故",
        "CONFIRM_CANCEL": "確認取消",
        "CONFIRM_CANCEL_MESSAGE": "您確定要取消此訂單嗎？",
        "CONFIRM_UPDATE": "確認更新",
        "CONFIRM_UPDATE_MESSAGE": "您確定要更新此訂單嗎？",
        "CONFIRM_RESET_EDIT_FARE_MESSAGE": "您編輯的車費已重置，請檢查後再繼續。",
        "Close": "關閉",
        "Update_info": "更新訂單",
        "Update_info_dispatch": "更新訂單並調度",
        "Update_info_tooltip": "如果您打算將此訂單重新分配給新司機，請選擇「更新訂單並調度」按鈕。",
        "BOOKING": "訂單",
        "DROPPED_OFF": "送達",
        "Payment_Type": "付款方式",
        "Total": "合計",
        "Next": "下一個",
        "Pay_here": "在此付款",
        "Complete_booking": "完成預訂",
        "Complete_booking_tooltip": "請點擊「更新訂單」保存您的更改，然後再繼續。",
        "Cancel": "取消",
        "Back": "返回",
        "Tax": "稅",
        "Booking_fees": "預訂費",
        "Tech_fees": "技術費",
        "Subtotal": "小計",
        "Other_fees": "其他費用",
        "Waiting_fee": "Waiting fee",
        "Toll_fees": "路橋費",
        "Heavy_traffic": "交通繁忙",
        "Rush_hour": "附加費",
        "Basic_fare": "基本車費",
        "Ok": "OK",
        "Input_reason": "輸入原因",
        "Client_case_matter": "客戶案例/行程描述",
        "Charge_code": "費用代碼/行程代碼",
        "Enter_3rd_party_location": "輸入第 3 方位置",
        "Company": "車隊供應商",
        "Check_promo_error": "檢查促銷代碼錯誤！",
        "Add_new_credit_error": "添加新的信用卡錯誤！",
        "Can_not_get_fare_setting": "無法獲取車費設置！",
        "Can_not_get_affilate_fare_setting": "無法獲取聯盟車費設置！",
        "Can_not_check_commisson": "無法檢查傭金！",
        "No_flight_info": "沒有航班信息！",
        "Socket_server_disconnected": "SOCKET服務器已斷開連接！",
        "Please_reload_page_or_check_your_connection": "對不起。 無法處理您的請求。 請再試一次。",
        "View_Logs": "查看日誌",
        "Bookingid": "訂單#",
        "Calendar": "日曆",
        "Extra_Destination": "額外目的地",
        "Meet_Greet_option": "舉牌服務",
        "On_Curb_option": "在外等待",
        "Credit_Card": "信用卡",
        "Charge": "收費",
        "No_charge": "免費",
        "QR_Code_method_not_allow_error_message": "請選擇其他付款方式，因為二維碼無法在儀表板上付款！",
        "change_payment_method_note": "如果您想更改付款方式，請返回預訂表格。",
        "Airport_Fee": "機場費",
        "Addi_service": "附加服務",
        "Additional_Services": "額外服務",
        "Minimum": "最低收費",
        "Search_here": "此處搜索",
        "No_Driver_Yet": "尚未指派司機",
        "Personal_Card": "個人卡",
        "Reject_booking": "拒絕訂單",
        "Confirm_reject": "確認拒絕",
        "Confirm_reject_message": "您確定要拒絕此訂單嗎？",
        "Confirm_reject_booking_with_charge": "根據我們的取消政策，您將被收取報價車費的 {0}%。 您確定要拒絕此訂單嗎？",
        "Yes": "是",
        "No": "否",
        "shortTrip": "短行程",
        "Add_new_card": "添加新卡",
        "Require_customer_info": "請輸入客戶信息以繼續。",
        "confirm_force_overlap": "確認",
        "confirm_force_overlap_message": "該訂單與司機接受的其他訂單重疊。 您確定要將其分配給該司機嗎？",
        "select": "選擇",
        "PICKUP_TIME_RANGE": "預計在 {0} 接載",
        "food": "食物",
        "mart": "市場",
        "Order_summary": "訂單摘要",
        "addOnPrice": "調整後的價格",
        "applicableFee": "總計（適用）",
        "itemValue": "物品價值",
        "promotion_note_paymentMethod": "此訂單正在應用促銷代碼，付款方式有限：%{method}。",
        "promotion_note_minimum_fare": "此訂單正在應用促銷代碼，最低車費限制：%{fare}。",
        "promotion_note_schedules": "此訂單正在應用促銷代碼，提貨時間有限。",
        "edited_fare_note": "此為票價調整日誌，僅供參考。 請訪問報告以查看最終車費和費用。",
        "view_good_receipt": "查看收貨單",
        "confirmRemoveGroupTransport": "您確定要從此清單中刪除當前訂單嗎？",
        "confirmRemoveGroupDelivery": "您確定要從此群組中刪除當前訂單嗎？",
        "confirmUngroupTransport": "您確定要取消此清單中所有訂單的合組嗎？",
        "confirmUngroupDelivery": "您確定要取消該組中所有預訂的分組嗎？",
        "view_delivery_receipt": "查看送貨照片",
        "select_time": "選擇時間",
        "InputReason": "輸入原因",
        "viewOrder": "查看訂單",
        "markFailed": "標記為失敗",
        "corporateName": "公司名稱",
        "sendMessage": "發信息",
        "AmountDueBefore": "之前到期金額",
        "AmountDueAfter": "之後到期金額",
        "chargeCustomer": "向客戶收費",
        "pending": "未支付",
        "refundPayment": "Refund Payment",
        "refundableAmount": "Refundable amount",
        "refundReason": "Add refund reason",
        "refundBtn": "Refund {0}",
        "partial": "部分支付",
        "paid": "已支付",
        "ViewPaymentLogs": "查看付款日誌",
        "pmActivity": "付款活動",
        "paidStatus": "付費狀態",
        "paymentLog": "付款記錄",
        "extraWaitFee": "Waiting fee Initial",
        "extraWaitTime": "Wait time (Initial)",
        "copied": "Copied",
        "warnningCancelCorporate": "Please note, you may incur a charge of amount in accordance with our cancellation policy."
    },
    "map": {
        "Enter_a_location": "輸入地點",
        "All_company": "所有車隊供應商",
        "All_cars": "所有車輛",
        "Show_all_cars": "顯示所有車輛",
        "Hide_all_cars": "隱藏所有車輛",
        "Vacant": "空閒中",
        "Dispatching": "調度中",
        "Driver_on_the_way": "司機前往接載",
        "Arrived_and_waiting": "抵達接載地",
        "P_O_B": "行程中",
        "Dropped_off": "送達",
        "Waiting_for_process": "等待處理",
        "Unavailable": "暫停接單",
        "Total_Drivers": "總司機",
        "Estimate": "估計",
        "Destination": "目的地",
        "Pickup": "接載",
        "Start_point": "起點",
        "Passenger": "乘客",
        "Driver_name": "司機姓名",
        "Search_car_here": "搜索車輛/司機姓名",
        "Error_load_booking": "無法加載訂單，請重試或刷新頁面！",
        "Show_all": "顯示所有",
        "driversLiveMap": "司機地圖",
        "heatMap": "需求圖",
        "Trip": "行程中",
        "traffic": "交通",
        "bicyclingLayers": "單車",
        "layer": "層",
        "trafficBicycling": "交通、自行車",
        "All_airline": "所有航空公司"
    },
    "customer": {
        "Customers": "顧客",
        "Points": "積分",
        "Migration_Status": "遷移狀態",
        "ADD_CUSTOMER": "添加客戶",
        "EDIT_CUSTOMER": "編輯客戶",
        "Add": "添加",
        "Activate": "啓用",
        "Deactivate": "停用",
        "Delete": "刪除",
        "Import": "導入",
        "Download_template": "下載模板",
        "View_Message": "信息",
        "Send_Message": "發信息",
        "Export_to_excel": "導出至 Excel",
        "Filter": "過濾",
        "Apply": "應用",
        "Clear": "清除",
        "Name": "名稱",
        "Phone_Number": "電話號碼",
        "Email": "電子郵件",
        "Vip": "貴賓",
        "companies": "公司",
        "File": "文件",
        "Number_successful_imports": "成功導入數量：",
        "Number_skipped": "跳過的數量：",
        "Number_duplicate_phone_numbers": "重復電話號碼的數量：",
        "Number_wrong_phone_numbers": "錯誤電話號碼數量：",
        "Corporate_Name": "公司",
        "Registered_From": "註冊自",
        "Registration_Date": "註冊日期",
        "Registration_date_from": "註冊日期自",
        "Registration_date_to": "註冊日期至",
        "IOS_Last_Login_Version": "IOS最後登錄版本",
        "Android_Last_Login_Version": "Android 最後登錄版本",
        "Last_Active_Date": "最後活動日期",
        "Verification": "驗證",
        "Support_ID": "服務ID",
        "Days_Since_Last_Active": "自上次活動以來的天數",
        "Completed_Bookings": "已完成訂單",
        "Outstanding_amount": "未結金額",
        "Status": "狀態",
        "Actions": "操作",
        "Action": "執行",
        "Inactive": "停用",
        "Active": "啓用",
        "Edit": "編輯",
        "View": "查看",
        "ALERT_SELECT_ACTIVATE": "請選擇要啓用的客戶。",
        "ALERT_SELECT_DEACTIVATE": "請選擇要停用的客戶。",
        "ALERT_SELECT_DELETE": "請選擇要刪除的客戶。",
        "CONFIRM_DELETE": "您確定要刪除該帳戶嗎？ 帳戶刪除後，客戶可以使用空數據註冊新的個人資料。",
        "CONFIRM_DELETE_MULTI": "您確定要刪除這些客戶嗎？ 只有停用且不是通過App註冊的用戶才能刪除。",
        "CONFIRM_DEACTIVATE_MULTI": "您想停用這些客戶嗎？",
        "CONFIRM_ACTIVATE_MULTI": "您想啓用這些客戶嗎？",
        "CONFIRM_EXPORT_TO_EXCEL": "您確定要將所有數據導出為 .xlsx 文件嗎？",
        "Add_card_top_up_success": "預付卡已充值成功",
        "Add_card_success": "卡已添加成功！",
        "Add_customer_success": "添加客戶成功",
        "Update_customer_success": "更新客戶成功",
        "Deleted_customer_success": "已成功刪除客戶",
        "Create_customer_fail": "創建客戶失敗",
        "Update_customer_fail": "更新客戶失敗",
        "ERROR_EXISTED_PHONE": "該電話已存在於系統中。 請輸入另一項",
        "ERROR_INPUT_FIRSTNAME": "請輸入姓名。",
        "ERROR_INPUT_PHONE": "請輸入電話號碼。",
        "ERROR_INPUT_VALIDPHONE": "請輸入一個有效的電話號碼。",
        "ERROR_INPUT_VALIDEMAIL": "請輸入有效的電子郵件地址。",
        "CONFIRM_DELETE_CARD": "您確定要刪除此卡嗎？",
        "First_name": "姓氏",
        "Last_name": "名字",
        "Phone_number": "電話號碼",
        "Email_address": "電子郵件地址",
        "Notes": "備注",
        "Account": "帳戶",
        "Card_management": "卡片管理",
        "Home": "歸屬",
        "Affiliate": "聯盟",
        "SHOW_PERSONAL_CARD": "顯示信用卡",
        "ADD_PERSONAL_CARD": "添加個人信用卡",
        "SHOW_mDISPATCHER_CARD": "顯示合作夥伴卡",
        "ADD_mDISPATCHER_CARD": "添加合作夥伴卡",
        "Personal_card": "個人卡",
        "mDispatcher_card": "合作夥伴卡",
        "Card_holder": "持卡人",
        "Card_number": "卡號",
        "Credit_type": "信用類型",
        "Credit_code": "信用代碼",
        "Sms_verification": "短信驗證",
        "Sms_verification_placeholder": "輸入驗證碼",
        "Expired_date": "失效日期",
        "CVV": "CVV",
        "Address": "地址",
        "Country": "國家",
        "City": "城市",
        "State": "州",
        "Zip_code": "郵政編碼",
        "postal_code": "郵政編碼",
        "Required_fields": "必填字段",
        "Cancel": "取消",
        "Save": "保存",
        "ERROR_INPUT_CARD_HOLDER": "請輸入持卡人信息",
        "ERROR_INPUT_CARD_NUMBER": "請輸入卡號",
        "ERROR_INPUT_EXPIRED_DATE": "請輸入到期日",
        "ERROR_VAILD_EXPIRED_DATE": "到期日無效",
        "ERROR_INPUT_CVV": "請輸入CVV",
        "ERROR_INPUT_ADDRESS": "請輸入地址",
        "ERROR_INPUT_CITY": "請輸入城市",
        "ERROR_INPUT_ZIPCODE": "請輸入郵政編碼",
        "ERROR_INPUT_COUNTRY": "請選擇國家",
        "Info_edit_error": "錯誤！",
        "Gender": "性別",
        "Select_Gender": "選擇",
        "Select_Idtype": "選擇 ID 類型",
        "Male": "男性",
        "Female": "女性",
        "Other": "其他",
        "Date_of_birth": "出生日期",
        "Street_address": "街道地址",
        "Select_Zone": "選擇區域",
        "ERROR_GET_GATEWAYS": "獲取網關錯誤",
        "Require_email": "添加新卡前請輸入電子郵件",
        "Require_lastName": "添加新卡前請輸入名字",
        "Require_phone_number": "使用信用卡預訂前請輸入乘客的電話號碼",
        "IMPORT_CUSTOMER_SUCCESS": "導入客戶成功",
        "No_card": "找不到信用卡",
        "No_Support": "該區域暫不支持添加新卡",
        "Card_Management": "信用卡管理",
        "Emergency_contacts": "緊急聯繫人",
        "DETAIL_CUSTOMER": "客戶詳情",
        "Select_Country": "選擇國家",
        "Customer_ID": "ID# / 國家身份證#",
        "Credit_balance": "信用額度",
        "required_email_payment": "客戶的電子郵件是必需的。 請更新個人資料以繼續。",
        "Yes": "是",
        "No": "否",
        "WriteOffDebt": "註銷欠款",
        "selectPayment": "請選擇一種付款方式以關閉所有未付款的預訂",
        "writeOffDebtSuccess": "成功核銷欠款",
        "writeOffDebtNotes": "注：本次衝銷應在所有未付款項全部付清後才能確認。",
        "totalCustomer": "客戶總數",
        "Personal_Card": "個人卡",
        "INFO_ADD_BALANCE_SUCCESS": "乘客餘額已成功更新",
        "INFO_UPDATE_POINT_SUCCESS": "乘客積分已成功更新",
        "currency": "貨幣",
        "balance": "結餘",
        "changeBalance": "更改餘額",
        "change": "變更",
        "WALLET_DEPOSIT": "錢包",
        "AccessDenied": "您沒有訪問此模塊的權限！",
        "referral_code": "推薦碼"
    },
    "importBookings": {
        "no": "否",
        "status": "狀態",
        "pickupDate": "出行日期",
        "pickup": "出發地址",
        "dropoff": "目的地",
        "passenger": "乘客",
        "phone": "電話號碼",
        "seats": "座位數",
        "luggages": "行李",
        "rideSharing": "拼車",
        "flightNumber": "航班號。",
        "notes": "備注",
        "externalId": "外部ID",
        "operatorNote": "內部備注",
        "clientCaseMatter": "行程描述",
        "chargeCode": "行程代碼",
        "paymentType": "支付",
        "vehicleTypeRequest": "車型",
        "importBookings": "導入訂單",
        "corporation": "企業及代理",
        "clickOrDropCsvFile": "單擊或選擇 .csv 文件",
        "importingFileForReview": "正在導入文件以供審閱...",
        "import": "導入",
        "submit": "提交",
        "downloadTemplate": "下載模板",
        "learnMore": "瞭解更多",
        "total": "總數",
        "valid": "有效的",
        "invalid": "無效的",
        "failed": "失敗的",
        "processing": "處理中",
        "success": "成功",
        "confirm": "確認",
        "confirmMessage": "將創建訂單，您確定要繼續嗎？",
        "create": "創建",
        "cancel": "取消",
        "close": "關閉",
        "leavingConfirmMessage": "您的工作未保存！ 您確定要離開嗎？",
        "resultMessage": "${success} 訂單已成功創建，${failed} 失敗。",
        "limitExceededBookings": "最大導入文件大小：100 個預訂",
        "delivery": {
            "no": "單號#",
            "pickupDate": "送貨日期",
            "senderName": "發件人名稱",
            "senderPhone": "發件人電話",
            "senderMail": "發件人電子郵件",
            "senderAddress": "發件人地址",
            "recipients": "收件人",
            "senderDistrictOptional": "區",
            "senderLocationDetails": "地點詳情",
            "recipientName": "收件人姓名",
            "recipientPhone": "收件人電話",
            "recipientAddress": "收件人地址",
            "recipientDistrictOptional": "區",
            "recipientLocationDetailsOptional": "地點詳情",
            "packageContent": "包裹內容",
            "orderNumber": "單號 ＃",
            "instructions": "司機須知",
            "cod": "貨到付款項目價值",
            "rideShare": "拼車",
            "paymentType": "付款方式",
            "carType": "車型",
            "recipientLocationDetails": "地點詳情",
            "operatorNote": "內部備注",
            "rideSharing": "批量發貨"
        }
    },
    "merchant": {
        "INFO_ADD_BALANCE_SUCCESS": "商戶餘額已成功更新",
        "ADD_MERCHANT_USER": "添加商戶賬戶",
        "EDIT_MERCHANT_USER": "編輯商戶帳戶",
        "MERCHANT_USER_DETAILS": "商戶賬戶詳細信息",
        "CASH_WALLET": "現金錢包",
        "CREDIT_WALLET": "信用錢包",
        "ADD_MERCHANT": "添加商戶",
        "ACCOUNT_INFO": "帳戶信息",
        "BILLING_ADDRESS": "銀行信息",
        "user": {
            "fullName": "商戶用戶",
            "Phone_Number": "電話號碼",
            "Email": "電子郵件",
            "storeName": "商號",
            "status": "狀態",
            "actions": "操作",
            "First_name": "姓氏",
            "Last_name": "名字",
            "Select_store": "選擇商戶",
            "accountHolder": "賬戶持有人名稱",
            "First_M_Last": "姓氏名字",
            "accountNumber": "帳號",
            "ibanNumber": "IBAN號碼",
            "bankName": "銀行名稱",
            "IDtype": "身份證類型",
            "IDnumber": "ID# / 國家身份證#",
            "routingNumber": "匯款路徑/中轉/分行號碼",
            "checkNumber": "支票號碼",
            "ssn": "SSN/NI",
            "sortCode": "排序代碼",
            "transitNumber": "中轉號碼",
            "institutionNumber": "機構編號",
            "birthDay": "生日",
            "address": "地址",
            "city": "城市",
            "state": "州",
            "postalCode": "郵政編碼",
            "beneficiaryIDIC": "受益人 ID# / IC#",
            "relationshipOtherName": "與其關係？",
            "verificationDocumentFront": "驗證文件（正面）",
            "verificationDocumentBack": "驗證文件（背面）",
            "Verification_document_tooltip": "可接受的身份證明形式：\n  - 護照\n  - 駕駛執照\n  - 居留證身份證件\n  - 公民卡\n  - 身份證",
            "additionalDocumentFront": "附加文件（正面）",
            "additionalDocumentBack": "附加文件（背面）",
            "Additional_document_tooltip": "可接受的地址驗證形式：\n  - 駕駛執照\n  - 水電費賬單\n  - 政府發出的信函\n  - 金融機構的聲明\n  - 選舉登記冊條目",
            "please_select": "請選擇",
            "commission": "傭金",
            "ERROR_INPUT_VALIDPHONE": "請輸入一個有效的電話號碼。",
            "ERROR_EXISTED_PHONE": "該電話已存在於系統中。 請輸入另一項",
            "Activate": "啓用",
            "Deactivate": "停用",
            "ALERT_SELECT_ACTIVATE": "請選擇要啓用的用戶。",
            "ALERT_SELECT_DEACTIVATE": "請選擇要停用的用戶。",
            "CONFIRM_ACTIVATE_MULTI": "您想啓用這些用戶嗎？",
            "CONFIRM_DEACTIVATE_MULTI": "您想停用這些用戶嗎？",
            "RESET_PASSWORD": "您想重置密碼嗎？",
            "CONFIRM_RESET_PASSWORD": "您想重置密碼嗎？",
            "REMOVE_BANK_INFO": "確認刪除銀行信息",
            "CONFIRM_REMOVE_BANK_INFO": "您想刪除銀行信息嗎？",
            "note_account_password_default": "注：對於新商戶帳戶，默認密碼為「12345678」",
            "Cash_Balance": "現金餘額",
            "Credit_Balance": "信用額度"
        },
        "Update_merchant_user_success": "商戶用戶已更新成功",
        "Update_merchant_user_fail": "更新商戶用戶失敗",
        "Create_merchant_user_success": "新商戶用戶創建成功",
        "Create_merchant_user_fail": "創建商戶用戶失敗",
        "Update_merchant_user_status_success": "商戶用戶已更新成功",
        "Update_merchant_user_status_fail": "更新商戶用戶失敗",
        "Reset_password_merchant_user_success": "重置密碼成功",
        "Reset_password_merchant_user_fail": "重置密碼失敗",
        "Deleted_merchant_bank_info_success": "成功刪除銀行信息",
        "Deleted_merchant_bank_info_fail": "刪除銀行信息失敗"
    },
    "table_header": {
        "Items_per_page": "每頁項目",
        "Page": "頁",
        "Search_here": "在這裡搜索",
        "Shown_Active": "顯示 %{start} - %{end}（共 %{total}）",
        "Active": "%{active}啓用",
        "OF": "的"
    },
    "driver": {
        "Driver": "Driver",
        "Add": "添加",
        "addDriver": "Add driver",
        "Detail": "Detail",
        "detailDriver": "Driver Details",
        "addNewVehicle": "Add Vehicle",
        "serviceType": "Service Type",
        "transport": "出行約車",
        "delivery": "Delivery",
        "stripeConnect": "Stripe Connect",
        "stripeConnectDes": "Add a stripe connect account for easier payouts",
        "bankAccount": "Banking Info",
        "bankAccountDes": "Drivers bank info to handle payouts",
        "supplierComission": "Supplier Commission",
        "supplierComissionDes": "Set commissions the supplier earns for every complete order.",
        "fleetCommission": "Fleet/Supplier commission",
        "fleetCommissionDes": "Set commissions the fleet or supplier earns for every complete order. If the driver is a supplier’s driver, the commission will be deducted from the supplier’s earning.",
        "operationZones": "Operation Zones",
        "operationZonesDes": "Zones that suppliers can receive jobs from:",
        "notes": "Notes",
        "notesDes": "Internal notes about this driver:",
        "searchNotes": "Add notes for driver",
        "marketplace": "Marketplace",
        "marketplaceDes": "Empower drivers to accept jobs they want to do.",
        "otherSettings": "Other Settings",
        "topDriver": "TOP Driver",
        "topDriverDes": "Prioritize top performers with the \"Top Driver\" tag, ensuring they get more rides.",
        "walletTransferBetweenDriver": "Wallet Transfers Between Drivers",
        "walletTransferBetweenDriverDes": "Enable drivers to transfer funds directly to each other’s wallets, facilitating easy distribution of credits by team leaders or ambassadors.",
        "rideSharing": "Ride-sharing",
        "rideSharingDes": "Enable drivers to perform ride-sharing jobs. Contact your account manager for this advance feature.",
        "individual_vehicle": "Individual vehicle",
        "company_vehicle": "Company vehicle",
        "ContactInfo": "Contact Info",
        "name": "Name",
        "expiryDate": "Expiry Date",
        "action": "Action",
        "editDocument": "Edit Document",
        "documentToUpload": "Document to upload",
        "addFiles": "Click or drag file to this area to upload.",
        "fileSize": "Maximum file size 10 MB",
        "fileSizeDes": "The file extension must be .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip",
        "removeOldFile": "Please remove the old file before uploading a new one.",
        "maxLengthFiles": "Please upload max length 1 file",
        "maxSizeFiles": "Please upload file size less than 10MBs",
        "typeFiles": "Please upload file type .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip",
        "maxAvatarSizeFiles": "Please upload avatar size less than 8MBs",
        "IFSCCode": "IFSC 代碼",
        "Drivers": "司機",
        "default": "默認",
        "customize": "定制",
        "Activate": "啓用",
        "Stripe_connect": "STRIPE CONNECT",
        "status_stripe": "狀態：{0}",
        "stripeConnectStatus": "STRIPE CONNECT",
        "stripeStatus": "Stripe 賬戶狀態",
        "Deactivate": "停用",
        "Delete": "刪除",
        "acceptInDay": "司機一天可以接受的最大預訂量",
        "acceptATime": "司機一次可以接受的預訂總數",
        "Commission": "設置運營商傭金",
        "Title_Commission": "為所有司機設置傭金",
        "Confirm_Set_Commission": "您確定要為所有司機設置此傭金值嗎？",
        "Commission_Successfully": "司機傭金已成功更新",
        "Apply": "套用",
        "Type": "類型",
        "Currency": "貨幣",
        "View_Message": "信息",
        "Send_Message": "發信息",
        "Driver_Name": "司機姓名",
        "Driver_ID": "ID# / 國家身份證#",
        "Phone_Number": "電話號碼",
        "Driver_Type": "司機類型",
        "TOP": "頂級",
        "Company": "車隊供應商",
        "City": "城市",
        "Driver_License_Number": "駕駛執照號碼",
        "License_Plate": "牌照",
        "Car_Type": "車型",
        "Zone": "區域",
        "Registered_From": "註冊自",
        "Registration_Date": "註冊日期",
        "IOS_Last_Login_Version": "IOS最後登錄版本",
        "Android_Last_Login_Version": "Android 最後登錄版本",
        "Last_Active_Date": "最後活動日期",
        "Days_Since_Last_Active": "自上次活動以來的天數",
        "Completed_bookings": "已完成訂單",
        "Status": "狀態",
        "Actions": "執行",
        "Action": "操作",
        "Inactive": "停用",
        "Active": "啓用",
        "Edit": "編輯",
        "View": "查看",
        "ALERT_SELECT_ACTIVATE": "請選擇要啓用的司機。",
        "ALERT_SELECT_DEACTIVATE": "請選擇要停用的司機。",
        "ALERT_SELECT_DELETE": "請選擇要刪除的司機。",
        "CONFIRM_DELETE": "您想刪除該司機嗎？",
        "CONFIRM_DEACTIVATE_MULTI": "您確定要停用所選司機嗎？ 他們連接的咪表也將被禁用。 只有當前沒有訂單的司機才能被停用。",
        "CONFIRM_ACTIVATE_MULTI": "您想啓用這些司機嗎？ 只有完全設置好的司機才能被啓用。",
        "CONFIRM_DELETE_MULTI": "您想刪除這些司機嗎？ 只能刪除處於停用狀態的司機。",
        "CONFIRM_REMOVE_BANKING_INFOR": "您確定要刪除銀行信息嗎？",
        "REMOVE_BANKING_INFOR": "刪除銀行信息",
        "ADD_DRIVER": "添加司機",
        "Avatar": "照片",
        "Individual_Driver": "出行 - 公司車輛",
        "Owner_Operator": "出行 - 個人車輛",
        "deliveryCompany": "送貨 - 公司車輛",
        "deliveryIndividual": "送貨 - 個人車輛",
        "CONTACT_INFO": "聯繫方式",
        "Home_address": "家庭地址",
        "Username": "用戶名",
        "First_name": "姓氏",
        "Last_name": "名字",
        "Phone_number": "電話號碼",
        "Email_address": "電子郵件地址",
        "Select_city": "選擇城市",
        "State": "州",
        "Zip_code": "郵政編碼",
        "Date_of_birth": "出生日期",
        "placeholderDate": "yyyy/mm/dd",
        "City_driver_want_to_drive": "司機想在何城市接單",
        "Driver_license_number": "駕駛執照號碼",
        "Driver_license_state": "駕駛執照所在州/省",
        "Driver_license_expiry": "駕駛執照到期",
        "FOR_INSPECTOR": "給檢查員",
        "Document": "文件",
        "Document_Id": "文件編號",
        "Link": "鏈接",
        "Notes": "備注",
        "UPLOAD_DOCUMENT": "上傳文件",
        "Click_here_to_download": "點擊下載",
        "Click_here_to_view": "點擊查看",
        "EXTENSION_REQUIREMENT": "文件擴展名必須為 .jpg、.jpeg、.png、.doc、.docx、.xls、.xlsx、.pdf、.7z、.rar、.zip 且小於 5Mb。",
        "Invalid_file": "無效文件",
        "File_size_larger_than_5Mb": "文件大小必須大於 5Mb",
        "BANK_ACCOUNT": "銀行賬戶",
        "Name_of_bank": "銀行名稱",
        "Name_of_account": "賬戶名稱",
        "First_M_Last": "姓氏名字",
        "RTBnumber": "匯款線路/中轉/分行號碼",
        "bankName": "銀行名",
        "Select_a_bank": "選擇銀行",
        "Account_number": "帳號",
        "IBAN_number": "IBAN號碼",
        "Check_number": "支票號碼",
        "Swift_code_number": "Swift 代碼",
        "SSNNI": "SSN / NI",
        "sortCode": "排序代碼",
        "Verification_document": "驗證文件（正面）",
        "Additional_document": "附加文件（正面）",
        "Verification_document_back": "驗證文件（背面）",
        "Additional_document_back": "附加文件（背面）",
        "others": "其他",
        "signedW9Form": "已簽署的 W-9 表格",
        "driverLicense": "駕駛執照",
        "Verification_document_tooltip": "可接受的身份證明形式：\n  - 護照\n  - 駕駛執照\n  - 居留證身份證件\n  - 公民卡\n  - 身份證",
        "Additional_document_tootip": "可接受的地址驗證形式：\n  - 駕駛執照\n  - 水電費賬單\n  - 政府發出的信函\n  - 金融機構的聲明\n  - 選舉登記冊條目",
        "isBankAccountOwner": "這是你的賬戶嗎？",
        "yes": "是",
        "no": "否",
        "beneficiary_name": "收款人姓名",
        "beneficiary_idic": "受益人身份證/身份證",
        "relationship": "關係",
        "select_relationship": "選擇關係",
        "spouse": "配偶",
        "daughterOrSon": "女兒/兒子",
        "brotherOrSister": "兄弟姐妹",
        "relative": "有相關",
        "friends": "朋友們",
        "other": "其他",
        "relationshipOtherName": "與他人的關係？",
        "OPERATION": "運營",
        "CAR_INSURANCE": "車輛保險",
        "Policy_number": "保單號碼",
        "Effective_date": "生效日期",
        "Expired_date": "到期日期",
        "Select_company": "選擇車隊供應商",
        "All_company": "所有車隊供應商",
        "All_Car_Type": "所有車型",
        "Car": "車輛",
        "Existing_car": "現有車輛",
        "New_car": "新車輛",
        "License_plate": "牌照",
        "Select_license_plate": "选择车牌",
        "Select_Car_Type": "選擇車型",
        "vehicleOwner": "車主姓名",
        "Make": "廠家",
        "Select_Car_Make": "選擇車輛品牌",
        "Model": "型號",
        "Select_Car_Model": "選擇車型",
        "Year": "年",
        "Select_Year": "選擇年份",
        "Color": "顏色",
        "Passengers": "乘客",
        "Luggage": "行李",
        "License_expiry": "許可證到期",
        "Shift": "排班",
        "Select_Shift_Template": "選擇排班模板",
        "Fleet_commission": "運營商傭金（報告）",
        "Company_commission": "供應商傭金（報告）",
        "rideHailing": "街道打車",
        "intercity": "專線",
        "food": "送餐",
        "mart": "市場",
        "streetSharing": "攔車拼車",
        "Shuttle": "穿梭",
        "parcel": "包裹",
        "driverInsurance": "司機保險",
        "Percentage": "百分比",
        "Amount": "金額",
        "transaction": "交易",
        "Terminal_ID": "終端ID",
        "Auth_key": "授權鍵",
        "CREDIT_DEPOSIT": "信用錢包",
        "Credit_Balance": "信用額度",
        "Balance": "餘額",
        "Change_balance": "更改餘額",
        "Select_balance": "選擇餘額",
        "Adjust_amount": "調整金額",
        "Adjust_value": "調整值",
        "New_balance": "新的餘額",
        "New_point": "新點數",
        "Enter_reason": "輸入原因",
        "ERROR_INPUT_BALANCE": "請選擇餘額。",
        "ERROR_INPUT_AMOUNT": "請輸入該值。",
        "ERROR_INPUT_REASON": "請輸入原因。",
        "ERROR_INPUT_VALID_AMOUNT": "請輸入一個大於或等於 0 的值。",
        "INFO_ADD_BALANCE_SUCCESS": "司機餘額已成功更新",
        "INFO_ADD_CASH_BALANCE_SUCCESS": "司機現金餘額已成功更新",
        "Force_meter": "強制咪表",
        "Force_meter_tooltip": "司機App必須連接咪表，適用於Pulsar和Centrodyne的咪表",
        "ERROR_EXISTED_PHONE": "該電話已存在於系統中。 請輸入另一項",
        "TOP_driver": "頂級司機",
        "Ride_sharing": "拼車",
        "Note_password": "注意：新司機帳戶，默認密碼為「12345678」",
        "Required_fields": "必填字段",
        "Cancel": "取消",
        "Save": "保存",
        "SaveAndInProgress": "保存並進行中",
        "SaveAndDeactivate": "保存並停用",
        "ERROR_REQUIRE_": "請輸入",
        "ERROR_INPUT_AVATAR": "請上傳頭像。",
        "ERROR_INPUT_DRIVER_TYPE": "請選擇司機類型。",
        "ERROR_INPUT_DOCUMENT": "請上傳",
        "ERROR_INPUT_USERNAME": "請輸入用戶名。",
        "ERROR_INPUT_VALID_USERNAME": "用戶名只能包含小寫字母和數字，長度必須在 3 到 20 個字符之間",
        "ERROR_INPUT_PHONE": "請指定有效的電話號碼。",
        "ERROR_INPUT_VALID_EMAIL": "請輸入有效的電子郵件。",
        "ERROR_INPUT_EMAIL": "請輸入電子郵件。",
        "ERROR_INPUT_FIRSTNAME": "請輸入姓氏。",
        "ERROR_INPUT_LASTNAME": "請輸入名字。",
        "ERROR_INPUT_ADDRESS": "請輸入家庭住址。",
        "ERROR_INPUT_CITY": "請輸入城市。",
        "ERROR_INPUT_STATE": "請輸入狀態。",
        "ERROR_INPUT_ZIPCODE": "請輸入郵政編碼。",
        "ERROR_INPUT_BIRTHDAY": "請輸入生日。",
        "ERROR_INPUT_NAME_OF_BANK": "請輸入銀行名稱。",
        "ERROR_INPUT_NAME_OF_ACCOUNT": "請輸入賬戶持有人姓名。",
        "ERROR_INPUT_ROUTING_NUMBER": "請輸入路由號碼。",
        "ERROR_INPUT_BANK_NAME": "請選擇銀行。",
        "ERROR_INPUT_ACCOUNT_NUMBER": "請輸入帳號",
        "ERROR_INPUT_CHECK_NUMBER": "請輸入支票號碼。",
        "ERROR_INPUT_SSN": "請輸入SSN。",
        "ERROR_INPUT_DocumentId": "請輸入文檔ID。",
        "ERROR_INPUT_SORTCODE": "請輸入排序代碼。",
        "ERROR_INPUT_COMPANY": "請選擇要啓用的供應商。",
        "ERROR_INPUT_LICENSE_PLATE": "請選擇車牌。",
        "ERROR_INPUT_ZONE": "請選擇要啓用的區域。",
        "ERROR_INPUT_SHIFT": "請選擇班次來啓用。",
        "ERROR_ACCOUNT_NUMBER_ONLY_NUMBERS": "這必須僅包含數字",
        "ERROR_SSN_ONLY_NUMBERS": "這必須僅包含數字",
        "ERROR_SSN_LENGTH": "SSN 必須至少有 4 個字符",
        "ERROR_COMMISSION_NUMBERS": "請輸入有效號碼",
        "INFO_DEACTIVAED_SUCCESS": "司機已成功停用。",
        "INFO_ACTIVAED_SUCCESS": "司機已成功啓用。",
        "INFO_DEACTIVAED_SUCCESS_BUT_HARDWARE": "司機已停用，但由於某些事故原因，咪表無法關閉",
        "INFO_MULTIDEACTIVAED_SUCCESS": "{0}選定的司機已成功停用",
        "INFO_MULTIDEACTIVAED_HARDWARE_SUCCESS": "{0}選定的司機已成功停用。 {1}連接的咪表被禁用",
        "INFO_MULTIDEACTIVAED_SUCCESS_BUT_HARDWARE": "[3]選定的司機已成功停用。 [2]連接的咪表被禁用。 [1]不是由於一些意想不到的原因",
        "INFO_MULTIACTIVAED_SUCCESS": "{0}選定的司機已成功啓用",
        "DRIVER_UPDATE_SUCCESS": "司機已成功更新。",
        "DRIVER_CREATE_SUCCESS": "司機信息已成功添加",
        "Expiry_Date": "到期日",
        "Case_number": "案件編號",
        "Export_to_csv": "導出為 CSV",
        "CONFIRM_EXPORT_TO_CSV": "您確定要導出 .csv 格式的所有數據嗎？",
        "Ride_sharing_tooltip": "拼車是一項高級功能，請聯繫我們的支持團隊以獲取更多信息",
        "In_Review": "審查中",
        "Reset_password_success": "重置密碼成功",
        "Topup_note": "（啓用司機後可以充值司機餘額）",
        "cash_balance": "現金餘額",
        "cash_wallet": "現金錢包",
        "total_balance": "總餘額",
        "total_cash_balance": "現金餘額總額",
        "total_credit_balance": "信用餘額總額",
        "banking_status": "銀行狀況",
        "bankingStatus": {
            "verified": "已驗證",
            "not_verified": "未經審核的"
        },
        "INVALID_FILE_SIZE": "文件大小必須小於 8Mb。",
        "totalDrivers": "總司機數",
        "profileStatus": "個人資料狀態",
        "profile_completed": "個人資料已完成",
        "profile_incomplete": "個人資料不完整",
        "profile_inProgress": "個人資料正在進行中",
        "in_review_completed": "審核中（個人資料已完成）",
        "in_review_incomplete": "審核中（個人資料不完整）",
        "in_review_inProgress": "審核中（個人資料正在進行中）",
        "CASH_WALLET_DEPOSIT": "現金錢包",
        "payoutSelect": "付款（付款後將發送給司機電郵和App收件箱）",
        "Confirm_update_cash_balance": "更改現金餘額",
        "Confirm_update_cash_balance_msg": "您確定要更改司機餘額嗎？",
        "cashBalance": "現金餘額",
        "cashBalance_unavailable": "0.00",
        "cashBalance_active": "正數",
        "cashBalance_negative": "負數",
        "creditBalance": "信用餘額",
        "ERROR_INPUT_DOCUMETID": "請輸入文檔編號。",
        "ERROR_INPUT_EXPIRYDATE": "請輸入到期日期。",
        "driverType": "司機類型",
        "tooltip_in_review_completed": "發送通知給完成個人資料的「審核中」司機。",
        "tooltip_in_review_incomplete": "發送通知給尚未完成個人資料的「審核中」司機。",
        "tooltip_in_review_inProgress": "發送通知給已完成個人資料但需要額外驗證的「審核中」司機。",
        "tooltip_active": "發送通知給「啓用」司機。",
        "tooltip_inactive": "發送通知給已重新啓用帳戶的司機。",
        "active": "啓用",
        "inactive": "停用",
        "inReviewInProgress": "審查中",
        "Market_place": "訂單市集",
        "Market_place_tooltip": "司機搶單，接受合適自己的訂單",
        "selectDriverType": "選擇司機類型",
        "PlsSelectDriverType": "請選擇司機類型",
        "emergencyContacts": "Emergency Contacts",
        "switchVehicle": "Switch to any vehicle",
        "switchVehicleNote": "Allow driver to switch to any vehicle of their assigned supplier company.",
        "vehicleModel": "Model",
        "licensePlateNumber": "Vehicle Plate",
        "addVehicle": "Add Vehicle",
        "newVehicle": "New Vehicle",
        "linkVehicle": "Link Vehicle",
        "modal": "Modal",
        "selectVehicle": "Select Vehicle",
        "selectVehicleNote": "Enable driver to select or change vehicle for a booking.",
        "Warning_change_company": "Warning: Reassigning this driver to another company will unlink all associated vehicles."
    },
    "corporate": {
        "User_Name": "用戶名",
        "Edit_Balance": "編輯餘額",
        "Corporates": "企業或代理",
        "Supplier": "供應商",
        "CarTypes": "車型",
        "Add": "添加",
        "Balance": "餘額",
        "Activate": "啓用",
        "Deactivate": "停用",
        "Delete": "刪除",
        "Company_Name": "公司名稱",
        "Admin_Name": "管理員名稱",
        "Username": "用戶名",
        "Phone_Number": "電話號碼",
        "importBooking": "能夠導入預訂",
        "Email": "電子郵件",
        "Registered_From": "註冊自",
        "Registration_Date": "註冊日期",
        "Status": "狀態",
        "Actions": "操作",
        "Action": "操作",
        "Inactive": "停用",
        "Active": "啓用",
        "In_Review": "審查中",
        "Edit": "編輯",
        "View": "查看",
        "ADD_CORPORATE_CARD": "添加公司卡",
        "selectCountry": "選擇國家",
        "CONFIRM_DELETE_CARD": "您確定要刪除此卡嗎？",
        "ALERT_SELECT_ACTIVATE": "請選擇要啓用的公司。",
        "ALERT_SELECT_DEACTIVATE": "請選擇要停用的公司。",
        "ALERT_SELECT_DELETE": "請選擇要刪除的公司。",
        "CONFIRM_DELETE": "您想刪除該公司嗎？",
        "CONFIRM_DELETE_USER": "您想刪除該公司用戶嗎？",
        "CONFIRM_DEACTIVATE_MULTI": "您想停用這些公司嗎？",
        "CONFIRM_ACTIVATE_MULTI": "您想啓用這些公司嗎？",
        "CONFIRM_DELETE_MULTI": "你想刪除這些公司嗎？ **只有停用的企業才能被刪除。",
        "ERROR_INPUT_PHONE": "請指定有效的電話號碼。",
        "ERROR_INPUT_VALIDPHONE": "請輸入一個有效的電話號碼。",
        "ERROR_EXISTED_PHONE": "該電話已存在於系統中。 請輸入另一項",
        "Account": "帳戶",
        "Corp_traveler": "企業乘客",
        "Payment_methods": "支付方式",
        "Corp_operators": "企業操作員",
        "Pricing": "價格",
        "COMPANY_INFORMATION": "公司信息",
        "Company_name": "公司名稱",
        "Company_ID": "公司 ID（用於公司用戶註冊）",
        "Company_address": "公司地址",
        "Invoice_email": "發票電子郵件",
        "Confirmation_email": "確認郵件",
        "colorCodeBooking": "色標",
        "ADMIN_ACCOUNT": "管理員帳戶",
        "First_name": "姓氏",
        "Last_name": "名字",
        "Commission": "傭金",
        "commissionBooking": "從 app/pwa 進行預訂獲取傭金",
        "Required_fields": "必填字段",
        "Save": "保存",
        "Cancel": "取消",
        "Reset_password": "重設密碼",
        "Employee_Name": "員工姓名",
        "Created_Date": "創建日期",
        "Add_corporate_card": "添加公司卡",
        "Corp_Users": "企業用戶",
        "Pricing_Default": "默認（與普通乘客相同的定價計劃）",
        "Pricing_Discount": "百分比折扣（僅適用於企業用戶）",
        "Markup_By_Percent": "按百分比加價（僅適用於企業用戶）",
        "Pricing_Add_different_rates": "套用不同的價格包",
        "ERROR_INPUT_COMPANYNAME": "請輸入公司名稱。",
        "ERROR_INPUT_USERNAME": "請輸入用戶名。",
        "ERROR_INPUT_FIRSTNAME": "請輸入姓名。",
        "ERROR_INPUT_EMAIL": "請輸入電子郵件。",
        "ERROR_INPUT_VALID_EMAIL": "請輸入有效的電子郵件地址。",
        "ERROR_INPUT_VALID_NUMBER": "請輸入有效號碼",
        "ERROR_INPUT_VALID_RANGE_AMOUNT": "請輸入一個介於 {0} 和 {1} 之間的值。",
        "INFO_ADD_CORPORATE_SUCCESS": "帳戶 {0} 已成功創建。 請檢查電子郵件 {1} 以獲取登錄憑據詳細信息。",
        "INFO_UPDATE_CORPORATE_SUCCESS": "公司信息已更新成功。",
        "INFO_UPDATE_PRICING_SUCCESS": "定價已成功更新",
        "INFO_RESET_PASSWORD_SUCCESS": "帳戶 {0} 已成功重置。 請檢查電子郵件 {1} 以獲取登錄憑據詳細信息。",
        "COMFIRM_RESET_PASSWORD": "您想重置密碼嗎？",
        "Traveler_signature": "乘客簽名",
        "Parcel_Delivery": "啓用送貨",
        "Tracking_log": "追蹤日誌",
        "Rating": "評分",
        "Email_address": "電子郵件地址",
        "Assistant_email": "助理郵箱",
        "Corp_PO": "公司訂單號",
        "Cost_centre": "成本中心",
        "Corp_division": "集團事業部",
        "Corp_id": "公司編號",
        "Department": "部門",
        "Manager_email": "經理的電子郵件",
        "Manager_name": "經理姓名",
        "Corporate_card": "公司卡",
        "Direct_invoicing": "月結",
        "Corporate_Prepaid": "企業預付",
        "Cash": "現金",
        "Car_Type_ID": "車型 ID",
        "App_Display_Name": "App顯示名稱",
        "Normal_Fare": "標準計費",
        "Flat_Fare": "固定計費",
        "Hourly_Daily_Rate": "按時計費",
        "Delivery_Rate": "送貨",
        "Assign_rate": "套用",
        "Zone": "區",
        "Rate": "價格包",
        "Zone_Rate": "區域 - 價格包",
        "Please_select_rate": "請選擇價格包",
        "Paid_by": "由...支付",
        "Enter_new_credit_card": "輸入新信用卡",
        "TOP_UP": "充值",
        "Top_up": "充值",
        "Topup_amount": "充值金額",
        "Currency": "貨幣",
        "ERROR_INPUT_AMOUNT": "請輸入有效金額。",
        "VAT_number": "增值稅號",
        "Gender": "性別",
        "Select_Gender": "選擇",
        "Male": "男性",
        "Female": "女性",
        "Date_of_birth": "出生日期",
        "Street_address": "街道地址",
        "City": "城市",
        "State": "州",
        "Zip_code": "郵政編碼",
        "Country": "國家",
        "adding_new_card": "您正在添加一張新卡以使用 %{zoneName} 區域的服務",
        "No_Support": "該區域暫不支持添加新卡",
        "Job_title": "職稱",
        "required_phone_number_payment": "需要提供公司電話號碼。 請更新個人資料以繼續。",
        "Yes": "是",
        "No": "否",
        "AddCardBeforeTopup": "未找到信用卡。 請添加新卡以繼續。",
        "flightNumber": "航班號",
        "Company_Type": "公司類型",
        "Standard": "標準企業",
        "Airline": "航空公司",
        "Branding": "品牌",
        "bgImage": "背景圖片 (1600x900)",
        "logoImage": "形象標識",
        "aliasTitle": "別名 (URL) 參數",
        "importChauffeurBooking": "能夠導入出行約車訂單",
        "isCorporateVip": "Corporate VIP",
        "isCorporateVipToolTip": "Enable this setting to automatically assign VIP status to all corporate travelers upon creation, you may turn it off for each individual user.",
        "qrTitle": "QR Web booking link",
        "qrNote": "Your personalized partner booking link is here! Display it prominently in bars, hotel receptions, guest rooms, and on your website—anywhere your customers might need a ride or delivery. Consider designing standees with your unique QR code, allowing customers a quick and easy scan to book their journey!"
    },
    "airline": {
        "Airlines": "航空公司",
        "Add": "添加",
        "Activate": "啓用",
        "Deactivate": "停用",
        "Delete": "刪除",
        "Company_Name": "公司名稱",
        "Admin_Name": "管理員名稱",
        "Username": "用戶名",
        "Phone_Number": "電話號碼",
        "Email": "電子郵件",
        "Registered_From": "註冊自",
        "Registration_Date": "註冊日期",
        "Status": "狀態",
        "Actions": "操作",
        "Action": "操作",
        "Inactive": "停用",
        "Active": "啓用",
        "In_Review": "審查中",
        "Edit": "編輯",
        "View": "查看",
        "ADD_CORPORATE_CARD": "添加航空卡",
        "selectCountry": "選擇國家",
        "CONFIRM_DELETE_CARD": "您確定要刪除此卡嗎？",
        "ALERT_SELECT_ACTIVATE": "請選擇要啓用的航空公司。",
        "ALERT_SELECT_DEACTIVATE": "請選擇要停用的航空公司。",
        "ALERT_SELECT_DELETE": "請選擇要刪除的航空公司。",
        "CONFIRM_DELETE": "您想刪除該航空公司嗎？",
        "CONFIRM_DELETE_USER": "您想刪除該航空公司用戶嗎？",
        "CONFIRM_DEACTIVATE_MULTI": "您確定要停用這些公司嗎？",
        "CONFIRM_ACTIVATE_MULTI": "您確定要啓用這家公司嗎？",
        "CONFIRM_DELETE_MULTI": "您確定要刪除該公司嗎？\n  ** 只有停用的公司才能被刪除。",
        "ERROR_INPUT_PHONE": "請指定有效的電話號碼。",
        "ERROR_INPUT_VALIDPHONE": "請輸入一個有效的電話號碼。",
        "ERROR_EXISTED_PHONE": "該電話已存在於系統中。 請輸入另一項",
        "Airline": "航空公司",
        "Crew": "空服人員",
        "Payment_methods": "支付方式",
        "Airline_operators": "航空公司運營商",
        "Pricing": "價錢",
        "COMPANY_INFORMATION": "公司信息",
        "Company_name": "公司名稱",
        "Company_ID": "公司編號",
        "Company_address": "公司地址",
        "Invoice_email": "發票電子郵件",
        "Confirmation_email": "確認郵件",
        "colorCodeBooking": "色標",
        "ADMIN_ACCOUNT": "管理員帳戶",
        "First_name": "姓氏",
        "Last_name": "名字",
        "Commission": "傭金",
        "Required_fields": "必填字段",
        "Required_fields_crews": "注意：對於新的空服帳戶，默認密碼為「12345678」",
        "Save": "儲存",
        "Cancel": "取消",
        "Reset_password": "重設密碼",
        "Crew_Name": "空服人員",
        "Created_Date": "創建日期",
        "Add_corporate_card": "添加航空卡",
        "Corp_Users": "用戶",
        "Pricing_Default": "默認",
        "Pricing_Discount": "按百分比折扣",
        "Markup_By_Percent": "按百分比加價",
        "Pricing_Add_different_rates": "套用不同的價格包",
        "ERROR_INPUT_COMPANYNAME": "請輸入公司名稱。",
        "ERROR_INPUT_USERNAME": "請輸入用戶名。",
        "ERROR_INPUT_FIRSTNAME": "請輸入姓名。",
        "ERROR_INPUT_EMAIL": "請輸入電子郵件。",
        "ERROR_INPUT_VALID_EMAIL": "請輸入有效的電子郵件地址。",
        "ERROR_INPUT_VALID_NUMBER": "請輸入有效號碼",
        "ERROR_INPUT_VALID_RANGE_AMOUNT": "請輸入一個介於 {0} 和 {1} 之間的值。",
        "INFO_ADD_CORPORATE_SUCCESS": "帳戶 {0} 已成功創建。",
        "INFO_UPDATE_CORPORATE_SUCCESS": "航空公司信息已更新成功。",
        "INFO_UPDATE_PRICING_SUCCESS": "定價已成功更新",
        "INFO_RESET_PASSWORD_SUCCESS": "帳戶 {0} 已成功重置。",
        "COMFIRM_RESET_PASSWORD": "您想重置密碼嗎？",
        "Traveler_signature": "乘客簽名",
        "Tracking_log": "追蹤日誌",
        "Rating": "評分",
        "Email_address": "電子郵件地址",
        "Assistant_email": "助理郵箱",
        "Corp_PO": "公司訂單號",
        "Cost_centre": "成本中心",
        "Corp_division": "集團事業部",
        "Corp_id": "公司編號",
        "Department": "部門",
        "Manager_email": "經理的電子郵件",
        "Manager_name": "經理姓名",
        "Corporate_card": "航空卡",
        "Direct_invoicing": "月結",
        "Corporate_Prepaid": "航空公司預付",
        "Cash": "現金",
        "Car_Type_ID": "車型 ID",
        "App_Display_Name": "App顯示名稱",
        "Normal_Fare": "標準計費",
        "Flat_Fare": "固定計費",
        "Hourly_Daily_Rate": "按時計費",
        "Delivery_Rate": "送貨",
        "Assign_rate": "套用價格包",
        "Zone": "區",
        "Rate": "價格包",
        "Zone_Rate": "區域 - 價格包",
        "Please_select_rate": "請選擇費率",
        "Paid_by": "由...支付",
        "Enter_new_credit_card": "輸入新信用卡",
        "TOP_UP": "充值",
        "Top_up": "充值",
        "Topup_amount": "充值金額",
        "Currency": "貨幣",
        "ERROR_INPUT_AMOUNT": "請輸入有效金額。",
        "VAT_number": "增值稅號",
        "Gender": "性別",
        "Select_Gender": "選擇",
        "Male": "男性",
        "Female": "女性",
        "Date_of_birth": "出生日期",
        "Street_address": "街道地址",
        "City": "城市",
        "State": "州",
        "Zip_code": "郵政編碼",
        "Country": "國家",
        "adding_new_card": "您正在添加一張新卡以使用 %{zoneName} 區域的服務",
        "No_Support": "該區域暫不支持添加新卡",
        "Job_title": "職稱",
        "required_phone_number_payment": "需要提供公司電話號碼。 請更新個人資料以繼續。",
        "Yes": "是",
        "No": "否",
        "AddCardBeforeTopup": "未找到信用卡。 請添加新卡以繼續。"
    },
    "message": {
        "Add": "添加",
        "Delete": "刪除",
        "Items_per_page": "每頁項目",
        "Page": "頁",
        "Search_here": "在這裡搜索",
        "Shown_Active": "顯示了 {0} - {1} 個（共 {2} 個）",
        "Date": "日期",
        "Subject": "主題",
        "To": "至",
        "Type": "類型",
        "Result": "結果",
        "Actions": "操作",
        "New_message": "新消息",
        "MESSAGE_DETAILS": "信息詳情",
        "All": "全部",
        "Company": "車隊供應商",
        "Active": "啓用",
        "In_Active": "停用",
        "In_Review": "審查中",
        "Send_to": "發給",
        "All_drivers": "所有司機",
        "All_customers": "所有客戶",
        "All_merchants": "所有商戶",
        "Customized_driver": "選擇司機",
        "Customized_customer": "選擇客戶",
        "Customized_merchant": "選擇商戶",
        "Receiver_list": "收件人列表",
        "Content": "內容",
        "Required_fields": "必填字段",
        "Search_driver_name_driver_phone_number": "搜索司機姓名、司機電話號碼",
        "Search_category_name": "搜索類別名稱",
        "Search_customer_name_customer_phone_number": "搜索客戶姓名、客戶電話",
        "Search_merchant_name_merchant_phone_number": "搜尋商戶名稱、商戶電話號碼",
        "Search_car_type": "搜索車型",
        "Searching": "正在尋找...",
        "No_results": "沒有結果",
        "Send": "發送",
        "Cancel": "取消",
        "SMS_failed_Inbox_succeeded": "短信失敗，收件箱成功",
        "Failed": "失敗",
        "Succeeded": "成功",
        "CONFIRM_DELETE": "您想刪除此消息嗎？",
        "CONFIRM_DELETE_MULTI": "您想刪除這些消息嗎？",
        "ALERT_SELECT_DELETE": "請選擇要刪除的消息。",
        "ERROR_INPUT_RECEIVERLIST": "請至少選擇一位司機發送消息",
        "ERROR_INPUT_CUSTOMER_RECEIVERLIST": "請至少選擇一位客戶發送消息",
        "ERROR_INPUT_SUBJECT": "請輸入主題",
        "ERROR_INPUT_CONTENT": "請輸入內容",
        "ERROR_INPUT_LEAST_ONE_SUBJECT_CONTENT": "請設置至少一種語言才能繼續",
        "ERROR_INPUT_SUBJECT_MULTI_LANGUAGE": "請輸入 {0} 語言的主題",
        "ERROR_INPUT_CONTENT_MULTI_LANGUAGE": "請輸入{0}語言的內容",
        "ERROR_INPUT_URL_MULTI_LANGUAGE": "請輸入 {0} 語言的網址",
        "ERROR_INPUT_TITLE_MULTI_LANGUAGE": "請輸入 {0} 語言的標題",
        "ERROR_INPUT_BODY_MULTI_LANGUAGE": "請輸入 {0} 語言的正文",
        "import_fail_success_driver": "%{success} 個司機已成功導入，%{fail} 個失敗。",
        "import_fail_success_passenger": "%{success} 個客戶已成功導入，%{fail} 個客戶失敗。",
        "import_fail_success_merchant": "%{success} 個商戶已成功導入，%{fail} 個商戶導入失敗。",
        "CONFIRM_SEND_SUCCESS": "您的信息已發送。 這可能需要幾分鐘的時間。 系統正在處理您的請求，請稍候。",
        "Load_message_and_sms_fail": "加載消息和SMS詳細信息時發生錯誤",
        "Update_message_and_sms_fail": "更新消息和SMS詳細信息時發生錯誤",
        "Update_message_and_sms_success": "消息和SMS詳細信息已成功更新",
        "Driver_List": "司機列表",
        "Car_Type_List": "車型列表",
        "Search_vehicle_type_name": "搜索車輛類型名稱",
        "ALERT_PROCESSING_DELETE": "您的請求正在處理中。 這可能需要幾秒鐘",
        "Customer": "客戶",
        "Driver": "司機",
        "StatusOfCustomer": "客戶狀態",
        "StatusOfDriver": "司機狀態",
        "StatusOfMerchant": "商戶狀態",
        "SendTo": "發給",
        "DownloadReceiverList": "下載接收者列表",
        "warningInputChangeOver": "最近 2 個輸入之間的相對變化超過 20%。 請考慮！",
        "NoteDownloadReveverList": "1 個月後，將禁用下載接收者列表。",
        "Load_voip_fail": "加載 VOIP 詳細信息時發生錯誤。",
        "Update_voip_success": "VOIP 詳細信息已成功更新。",
        "Update_voip_fail": "更新 VOIP 詳細信息時發生錯誤。",
        "expiryDate": "發送給新用戶直到",
        "expiryDate_placeholder": "到期日期（月/日/年）",
        "NA": "不適用",
        "PleaseSelectStatusOfDriver": "請選擇司機狀態",
        "versionApp": "App版本",
        "platform": "運營商",
        "contentType": "內容類型",
        "url": "網址",
        "title": "標題",
        "contentId": "內容ID",
        "body": "內容",
        "image_notification": "圖像",
        "platformItem": {
            "all": "全部",
            "iOS": "iOS",
            "android": "Android"
        },
        "comparisonVersionApp": {
            "is": "是",
            "is_not": "不是",
            "is_one_of": "是其中之一",
            "is_not_one_of": "不是其中之一",
            "greater_or_equal": "大於或等於",
            "less_or_equal": "小於或等於"
        },
        "contentTypeItem": {
            "html": "HTML",
            "link": "鏈接"
        },
        "Please_input": "此字段是必需的"
    },
    "fleetInfo": {
        "Fleet_name": "運營商名字",
        "Phone_number": "電話號碼",
        "Email_address": "電子郵件地址",
        "Country": "國家",
        "Time_zone": "時區",
        "Address": "地址",
        "Website": "網站",
        "Currency": "貨幣",
        "Distance_unit": "距離單位",
        "Load_fleet_info_fail": "加載運營商信息時發生錯誤"
    },
    "generalSetting": {
        "alertNotifications": {
            "title": "Alert notifications",
            "emails": "Emails",
            "emailsPlaceholder": "Enter email",
            "slack": "Slack Channel (Instructions)",
            "slackPlaceholder": "Slack POST URL",
            "discord": "Discord Channel (Instructions)",
            "discordPlaceholder": "Enter Discord Channel",
            "telegram": "Telegram Channel (Instructions)",
            "telegramPlaceholder": "Enter Telegram Channel"
        },
        "showFare": {
            "title": "司機顯示票價",
            "totalFare": "總票價",
            "estimateGross": "預估總收入"
        },
        "carHailing": "街頭打車",
        "regular": "標準",
        "cancelTimeout": "自動取消超時訂單",
        "allowStripePaymentLink": "允許生成 Stripe 付款鏈接",
        "flat": "固定",
        "techFee": "每筆交易的技術費",
        "customizeLogo": "自定義儀表板徽標",
        "logo": "標識",
        "customerApp": "客戶App",
        "hourly": "按時",
        "shuttle": "穿梭",
        "parcel": "包裹",
        "freeCancelTime": "每天免費取消次數",
        "AM": "上午",
        "PM": "下午",
        "RUSH_HOUR": "附加費",
        "Name": "名稱",
        "Date": "日期",
        "Mon": "週一",
        "Tue": "週二",
        "Wed": "週三",
        "Thu": "週四",
        "Fri": "週五",
        "Sat": "週六",
        "Sun": "周日",
        "Time_range": "時間範圍",
        "From": "從",
        "To": "至",
        "Rush_hour_time_calculation": "附加費時間計算",
        "Rush_hour_surcharge": "附加費",
        "Required_fields": "必填字段",
        "Save": "保存",
        "Cancel": "取消",
        "MDISPATCHER_CANCELLATION": "App / 個人合作夥伴 / WEB取消訂單",
        "Book_now": "實時用車",
        "MDISPATCHER_CANCELLATION_TOOLTIP": "App / 個人合作夥伴 / WEB取消訂單",
        "Book_now_toolTip": "如果實時訂單在以下時間內取消，則需支付取消費",
        "Book_later_toolTip": "如果在接載時間之前取消預訂，則需支付取消費",
        "Drv_Book_now_toolTip": "如果實時訂單在接受時間後的規定時間內取消，則需支付取消費",
        "Drv_Book_later_toolTip": "如果預訂訂單在預計接載時間之前的規定時間內取消，則需支付取消費",
        "Free_cancel": "免費取消期限",
        "Hide_cancel_btn": "在設定時間後隱藏取消按鈕",
        "Driver_cancellation": "司機取消",
        "Time_day": "每天次數",
        "Duration": "持續時間",
        "Times": "時間",
        "Book_later": "預約訂單",
        "Intercity": "專線",
        "Intercity_tooltip": "如果在接送時間之前的規定時間內取消專線訂單，則需支付取消費。",
        "MDISPATCHER_NO_SHOW": "NOSHOW",
        "AIRPORT_FEE": "機場費",
        "From_airport": "接機",
        "To_airport": "送機",
        "MEET_DRIVER": "司機舉牌",
        "On_curb": "在外等候",
        "Meet_Greet": "司機舉牌",
        "Add": "添加",
        "Edit": "編輯",
        "DELETE_SURCHARGE": "刪除附加費",
        "DELETE_SERVICE": "刪除服務",
        "CHANGE_APPLY_SERVICE_FEES": "變更適用服務費",
        "DELETE_CONFIRM": "你確定要刪除這個項目嗎？",
        "DELETE_SURCHARGE_CONFIRM": "您確定要刪除所選的附加費嗎？",
        "DELETE_ADDITIONAL_SERVICES_CONFIRM": "您確定要刪除所選服務嗎？",
        "CHANGE_APPLY_SERVICE_FEES_CONFIRM_CUSTOM": "您確定要對不同的區域應用不同的費用嗎？",
        "CHANGE_APPLY_SERVICE_FEES_CONFIRM_ALL": "您確定要對所有區域應用相同的費用嗎？",
        "EDIT_SERVICE_FEES": "編輯服務費",
        "EDIT_SERVICE_FEES_CONFIRM": "您確定要離開而不保存 %{currentZone} 的設置嗎？",
        "HEAVY_TRAFFIC": "交通繁忙",
        "Heavy_traffic_surcharge": "交通繁忙附加費",
        "TOLL_FEE": "路橋費",
        "parkingFee": "Parking fee",
        "gasFee": "Gas fee",
        "OTHER_FEES": "其他費用",
        "Can_edit": "能夠編輯",
        "Can_add_note": "可以添加備注",
        "Other_fees_amount": "其他費用金額",
        "TAX": "稅",
        "Tax_surcharge": "附加稅",
        "TIP": "小費",
        "Default_tip": "默認小費",
        "RESERVATION_REMINDER": "預約提醒",
        "Driver_app_SMS_alert_before": "司機App - 提前短信和提醒",
        "Pax_app_alert_before": "乘客app -之前提醒",
        "Confirm_driver_info_via_email_before": "之前向客戶發送電子郵件/短信預訂確認",
        "Confirm_driver_info_via_email_before_hint": "系統將根據您設定的時間，在接送時間之前向客戶發送預訂確認信息。",
        "Show_request_tab_on_driver_app": "在司機App上顯示「請求」選項卡",
        "Show_preferred_tab_on_driver_app": "在司機App上顯示「首選」選項卡",
        "Sound_notification": "聲音通知",
        "Advance_information": "企業乘客額外信息",
        "Load_general_fail": "加載常規設置時發生錯誤",
        "Update_general_success": "常規設置已成功更新",
        "Update_general_fail": "更新常規設置時發生錯誤",
        "Time_over_lap": "時間範圍不得相互重疊",
        "Allow_passenger_to_register_as_corp_traveler": "允許乘客註冊為企業用戶",
        "Not_Allow_Make_Booking_Without_Assign_Fare": "如果沒有配置價格包，則不在 PWA 和App上顯示車型（v4.6.60 及更高版本）",
        "Not_Allow_show_car_type_on_cc": "如果沒有配置價格包，則不會在控制中心上顯示車型",
        "Disable_Direct_Invoicing_Receipt_Email": "禁用月結收據",
        "Additional_services": "額外服務",
        "Edit_additional_services": "編輯附加服務",
        "Service_Fee_By_Currencies": "服務費",
        "Service_type": "服務類型",
        "Optional": "可選的",
        "Compulsory": "強制",
        "Vehicle_type": "車輛類型",
        "Adding_additional_service_success": "添加服務成功",
        "Update_additional_service_success": "更新服務成功",
        "Delete_additional_service_success": "服務已刪除成功",
        "Adding_additional_service_fail": "添加服務失敗",
        "Update_additional_service_fail": "更新服務失敗",
        "Other_settings": "其他設置",
        "Operator_assign_error": "套用操作員錯誤",
        "Create_surcharge_success": "創造附加費成功",
        "Update_surcharge_success": "更新附加費成功",
        "Delete_surcharge_success": "刪除附加費成功",
        "Create_surcharge_fail": "創建附加費失敗",
        "Update_surcharge_fail": "更新附加費失敗",
        "Delete_surcharge_fail": "刪除附加費失敗",
        "Get_surcharge_detail_fail": "獲取附加費細節失敗",
        "Repeat": "復發",
        "DayOfWeek": "星期幾",
        "SingleDay": "單日",
        "Yearly": "每年",
        "Backup_phone_number": "備用電話號碼",
        "Backup_phone_number_hint": "司機和乘客不在操作區域時直接撥打此號碼",
        "Apply_countdown_clock": "應用倒計時",
        "No_show_duration_tooltip": "在司機 App上點擊「到達」按鈕後，設置NOSHOW倒計時按鈕",
        "Limit_radius": "限制半徑",
        "Limit_radius_tooltip": "司機可以將在此半徑範圍內的訂單報告為NOSHOW",
        "Limit_radius_arrive_pickup": "限制半徑以讓司機點擊到達和接載訂單",
        "Booking_validation": "訂單驗證",
        "Invalid_period_of_time": "無效時間段",
        "hide_Provider_Filter_From_Corporate_Board": "從公司管理頁面隱藏服務商過濾器",
        "customer_email_is_optional": "乘客App的客戶電子郵件是可選的",
        "disable_hourly_rate": "當按時計費和固定收費均可用時禁用按時計費",
        "customer_gender_is_compulsory": "乘客App上必須輸入客戶性別",
        "SHOW_MAP_STATUS": "顯示地圖狀態",
        "Pay_to": "付款至",
        "EMERGENCY_SOS": "緊急SOS",
        "customer_address_is_compulsory": "乘客App上必須提供客戶地址",
        "customer_id_is_compulsory": "乘客App必須提供客戶的 ID#/國家 IC#",
        "allowPassengerToUploadDocument": "允許乘客上傳 ID# / 國家 IC# 文件",
        "compulsoryNationalIcDocumentInPaxApp": "乘客App上必須提供客戶的 ID# / 國家 IC# 文件",
        "doNotAllowAddDriverWhenNationalIdDuplicated": "當身份證重復時不允許操作員添加司機",
        "forceCustomerToUseLiveLocation": "強制用戶使用實時位置",
        "disableMarketPlace": "新司機不可使用訂單市集",
        "dontShowSecondStageImmediately": "隱藏第 2 階段註冊",
        "compulsoryCarInsurance": "車輛保險是強制性的",
        "marketPlace": "訂單市集",
        "Enable": "可以",
        "Disable": "禁用",
        "showTotalFareWithoutPromotionOnDriverApp": "在司機App上顯示無促銷的總票價",
        "showTotalFareWithoutPromotionOnDriverApp_hint": "出行約車：在司機付款之前應用此設置。 <br/> - 送貨和專線：在司機開始工作之前應用此設置。",
        "hideFareOnPaxAppForAllCorporateBookings": "在乘客App上隱藏所有企業訂單的票價",
        "hidePaxNameAvatarOnDispatchScreen": "司機調度屏幕上隱藏客戶姓名和頭像",
        "showFullCustomerNameOnDriverApp": "在司機App顯示完整的客戶姓名",
        "hideDriverLogoutBtn": "隱藏司機App上的註銷按鈕",
        "showMeetAndGreetButtonForEveryBooking": "Show Meet & Greet button for every booking",
        "settings": "設置",
        "Default_value": "默認值",
        "limitTopupAmount": {
            "valueByCurrencies": "警告金額",
            "minimumByCurrencies": "最低充值金額",
            "maximumByCurrencies": "最大充值金額",
            "title": "最小和最大充值金額"
        },
        "driverApp": "司機App",
        "passengerApp": "乘客App",
        "Default_Topup_Values": "默認充值金額",
        "service_fees": "服務費",
        "commission_fleet": "默認運營商傭金",
        "apply_all": "所有操作區均相同",
        "apply_custom_zone": "區分所有運營區域",
        "apply": "套用",
        "tollFeeDriverCanInput": "限制司機可以輸入金額",
        "unlimited": "無限",
        "limited": "有限",
        "sameFee": "費用相同",
        "customizedAirportfee": "定制機場費",
        "noteAirportFeeModal": "注意：機場費僅適用於列表中的機場。",
        "intercity_schedule_trip_time_range": "為確保預訂時間的順序，請確保首選/請求行程的時間範圍大於預定行程",
        "airportFee": {
            "tooltipApply": "默認機場費將適用於每次往返機場的行程。 如果定制機場費用，則該地點將適用新的費用。",
            "titleSearchAiportZone": "請搜索您的機場並添加您的費用",
            "airport": "機場",
            "currency": "貨幣",
            "fromAirportFee": "接機費",
            "toAirportFee": "送機費",
            "action": "操作"
        },
        "Yes": "是",
        "No": "否",
        "tipPaymentMethod": "乘客可以在下單後向司機小費的付款方式",
        "tipDefaultValues": "完成訂單後小費的默認值",
        "creadit": "信用卡（個人卡/公司卡）",
        "wallet": "錢包",
        "tnGeWallet": "TnG電子錢包",
        "vippseWallet": "VIPPS",
        "DurationRegularBooking": "持續時間（標準訂單）",
        "googlePay": "Google Pay",
        "applePay": "Apple Pay",
        "DurationRegularBookingToolTip": "司機點擊司機App的「到達」按鈕後，將顯示「NOSHOW」按鈕。",
        "noShowCountDown": {
            "customizedNoShow": "針對特定地點的定制（例如機場...）",
            "customizedNoShowTitle": "對特定地點（例如機場...）NOSHOW倒計時持續時間"
        },
        "maxWaitTime": "Max extra wait time",
        "maxWaitTimeToolTip": "This is the maximum extra waiting time that will be charged to the customer. You have the flexibility to customize the per-minute fee in your rate settings.",
        "maxWaitTimePlaceholder": "Maximum charge wait time",
        "HEAT_MAP": "需求圖",
        "heatMap": {
            "defaultMode": "默認模式",
            "radius": "半徑",
            "intensity": "強度",
            "opacity": "不透明度",
            "requestedBookings": "請求訂單",
            "activePassengers": "活躍乘客",
            "completedBookings": "已完成訂單",
            "timeoutBookings": "超時訂單",
            "tooltipRadius": "每個數據點的影響半徑（以像素為單位）。",
            "tooltipIntensity": "熱圖的最大強度。",
            "tooltipOpacity": "熱圖的不透明度，表示為 0 到 1 之間的數字。"
        },
        "schedule_trip": "安排行程",
        "driver_starts_trip_before_set_time": "司機在設定時間之前開始行程",
        "request_trip": "請求行程",
        "preferred_trip": "首選行程",
        "time_range": "請求的時間範圍和首選行程",
        "working_time": "工作時間",
        "hour": "小時",
        "hours": "小時",
        "minutes": "分鐘",
        "indicate_pickup_time_in_advance_to_customer": "提前向客戶告知接送時間。",
        "customers_can_select_start_time_and_request_intercity_trip": "客戶可以選擇出發時間並請求專線。 司機還可以根據自己的時間表創建自己喜歡的行程。 示例：1 小時的時間範圍允許客戶和司機創建從上午 8:00 - 9:00、9:00 - 10:00 或 10:00 - 11:00 開始的專線 。",
        "this_setting_limits_customers_to_create_request_trip_within_working_hours_only": "此設置限制客戶只能在工作時間內創建請求行程。",
        "face_mask_verify": "口罩驗證",
        "faceMask_allZones": "所有區域",
        "faceMask_customized": "定制專區",
        "turn_on_verify": "開啓驗證",
        "force_to_wear_face_mask": "強制戴口罩",
        "time_range_to_verify": "請勿再次驗證",
        "customizedFaceMaskZone": "定制口罩驗證",
        "zoneOperation": "運營區域",
        "FaceMaskCustomized": "定制",
        "time": "時間",
        "day": "天",
        "days": "天",
        "allow_driver_to_skip_verification_after": "允許司機在之後跳過驗證",
        "driver_need_to_verify": "當司機需要驗證時",
        "open_app": "打開應用程序",
        "start_reservation": "開始預訂",
        "choose_at_least_one": "您必須至少選擇一項操作才能繼續。",
        "face_mask_customized_fail": "您必須至少選擇一項操作才能繼續 ({0})。",
        "vehicleAgeLimit": "車齡限制",
        "vehicleAgeLimit_years": "年",
        "founderFund_firstBooking": "創始人基金：僅限首次預訂",
        "distance_diversity": "距離分集",
        "Add_Surcharge_Distance": "添加",
        "from": "從",
        "to": "到",
        "surchargeValue": "附加費",
        "actions": "操作",
        "please_enter_distance_range_valid": "距離 %{name} 無效！",
        "please_enter_distance_to": "請輸入距離「到」",
        "please_enter_surchargeValue": "請為距離範圍 %{name} 輸入距離「附加費」",
        "please_enter_surchargeValue_is_number": "請輸入距離範圍 %{name} 的有效數字「附加費」",
        "Update_service_fee_success": "服務費已成功更新",
        "Update_service_fee_fail": "更新服務費用時發生錯誤",
        "Update_fleet_commission_success": "默認運營商傭金已成功更新",
        "Update_fleet_commission_fail": "更新默認運營商傭金時發生錯誤",
        "RESERVATION_POLICY": "預訂政策",
        "default_expected_pickup_time": "默認預計接載時間",
        "default_expected_pickup_time_hint": "您期望司機接載時間的默認值。",
        "same_original_pickup_time": "與原定接送時間相同",
        "quarter_before": "15分鐘前",
        "half_before": "30分鐘前",
        "quarter_after": "15分鐘後",
        "half_after": "30分鐘後",
        "custom": "定制",
        "min_before": "分鐘之前",
        "min_after": "分鐘後",
        "TransactionFee": "交易費",
        "creditCardPercent": "信用卡/預付卡",
        "directBilling": "月結",
        "externalCard": "External Card"
    },
    "social": {
        "pax_app": "乘客App",
        "driver_app": "司機App",
        "default": "默認",
        "customized": "定制",
        "html": "HTML",
        "about": "關於",
        "email": "電子郵件支持",
        "enablePhone": "電話號碼",
        "phone": "",
        "policy": "鏈接隱私政策",
        "termOfUse": "鏈接使用條款",
        "site": "網站",
        "logoDriverIOS": "標誌-IOS",
        "logoDriverAndroid": "標誌 - 安卓",
        "htmlUrl": "HTML 文件",
        "Update_fail": "更新社交時發生錯誤",
        "Update_success": "社交已更新成功"
    },
    "user": {
        "Show_per_page": "每頁項目",
        "Page": "頁",
        "Search_here": "在這裡搜索",
        "show_pages": "顯示了 {0} - {1} 個（共 {2} 個）",
        "Username": "用戶名",
        "Name": "名稱",
        "Phone_number": "電話號碼",
        "Email": "電子郵件",
        "Permission": "權限",
        "Status": "狀態",
        "Actions": "操作",
        "Active": "啓用",
        "Inactive": "停用",
        "Edit": "編輯",
        "Deactivate": "停用",
        "Delete": "刪除",
        "Add": "添加",
        "ADD_USER": "添加用戶",
        "First_name": "姓氏",
        "Last_name": "名字",
        "AgendId": "用戶#",
        "Email_address": "電子郵件地址",
        "Address": "地址",
        "Required_fields": "必填字段",
        "Save": "保存",
        "Cancel": "取消",
        "EDIT_USER": "編輯用戶",
        "Yes": "是",
        "DELETE_USER": "刪除用戶",
        "DETAIL_USER": "詳細用戶",
        "DELETE_CONFIRM": "您確定要刪除所選用戶嗎？",
        "Update_user_fail": "更新用戶時發生錯誤",
        "Update_user_success": "用戶已更新成功",
        "Create_user_fail": "創建用戶時發生錯誤",
        "Create_user_success": "帳戶 {0} 已成功創建。 請檢查電子郵件 {1} 以獲取登錄憑據詳細信息。",
        "Load_user_fail": "加載用戶時發生錯誤",
        "Update_user_status_fail": "更新用戶狀態時發生錯誤",
        "Delete_user_fail": "刪除用戶時發生錯誤",
        "Delete_user_success": "該用戶已成功刪除",
        "CONFIRM_RESET_PASSWORD": "您想重置密碼嗎？",
        "RESET_PASSWORD": "您想重置密碼嗎？",
        "Reset_password": "重設密碼",
        "Select_permission": "選擇權限",
        "Reset_password_success": "重置密碼成功"
    },
    "report": {
        "query": {
            "errorMessage": {
                "bookFrom": "請選擇訂單",
                "paymentMethod": "請選擇付款方式",
                "bookingType": "請選擇訂單類型",
                "ratingType": "請選擇評級類型",
                "stars": "請選擇評級類型",
                "type": "類型",
                "transactionType": "請選擇交易類型",
                "serviceType": "請選擇服務類型",
                "rideService": "請選擇乘車服務",
                "invalidEmail": "請輸入有效的電子郵件地址。"
            },
            "search": {
                "driverReferalPax": "搜索：司機姓名、電話號碼",
                "passengerRefersPassengers": "搜索：客戶姓名、電話號碼",
                "driverReferalPaxDetail": "搜索：乘客姓名",
                "bookingDetails": "搜索：訂單ID，操作員，客戶電話，客戶姓名，司機姓名，駕駛證/許可證，車牌，排隊區域，備注",
                "financial": "搜索：司機姓名、電話",
                "completedTime": "完成時間",
                "collectedTime": "採集時間",
                "financialDriver": "搜索：訂單 ID、司機姓名、ID#/國家身份證#",
                "carActivity": "搜索： 車牌",
                "affiliation": "搜索：訂單 ID",
                "prepaidTopUp": "搜索：供應商名稱、充值ID、交易ID",
                "operatorLogs": "搜索：操作員名稱、用戶名、描述",
                "corporate": "搜索：訂單 ID、司機姓名、公司用戶、客戶案例、費用代碼",
                "bookingLogs": "搜索：訂單 ID、客戶姓名、支持 ID、司機姓名、ID#/國家 IC#",
                "rating": "搜索：訂單 ID、客戶電話、客戶姓名、車牌號",
                "mDispatcher": "搜索：訂單 ID、合作夥伴名稱",
                "totalAdjustment": "搜索：訂單 ID",
                "incidentCancellation": "搜索：訂單 ID、客戶電話、支持 ID、客戶姓名",
                "acceptanceCancellationRate": "搜索：司機姓名、電話號碼",
                "revenueDriver": "搜索： 司機姓名",
                "dailyDriver": "搜索：司機電話、司機姓名、ID# / 國家 IC#",
                "dailyCar": "搜索：車牌、許可",
                "dailyDriverCar": "Search:  Driver phone, Driver name, ID# / National IC#, License plate",
                "driverLoginStatus": "Search:  Driver phone, Driver name, ID# / National IC#, License plate",
                "bookingSummary": "搜索：操作員名、用戶名、訂單 ID",
                "creditWallet": "搜索：供應商名稱、司機姓名、訂單 ID、交易 ID、操作員",
                "cashWallet": "搜索：供應商名稱、司機姓名、訂單 ID、電話號碼",
                "settlement": "搜索：司機姓名、ID# / 國家 IC#",
                "driverRating": "搜索：訂單 ID、客戶電話、客戶姓名、車牌號、支持 ID",
                "driverWithdrawal": "司機姓名、電話、供應商、交易 ID、參考編號",
                "penaltyCompensation": "搜索：訂單 ID、司機姓名、乘客姓名",
                "incompletePayment": "搜索：訂單 ID、支持 ID、客戶姓名",
                "passengerWallet": "搜索：訂單 ID、交易 ID、客戶姓名",
                "driverDeposit": "搜索：供應商名稱、司機姓名、訂單 ID、操作員",
                "refund": "搜索：訂單 ID、行程 ID、支持 ID、客戶姓名",
                "driverRefersCustomerBookings": "搜索：推薦代碼、支持 ID",
                "driverHistory": "搜索：付款 ID",
                "merchantHistory": "搜索：付款 ID",
                "firstWillWin": "搜索：客戶姓名、客戶電話、客戶 ID",
                "firstWillWinDriver": "搜索：司機姓名、司機電話、用戶名",
                "merchant": "搜索： 商店",
                "merchantCashTransactionHistory": "搜索：訂單 ID、交易 ID、商戶名稱",
                "merchantCreditTransactionHistory": "搜索：訂單 ID、交易 ID、商戶名稱",
                "deleteAccountRequest": "搜索： 電話",
                "affiliationPayoutHistory": "搜索：付款 ID",
                "thirdPartyBooking": "搜索：訂單 ID、外部 ID"
            },
            "common": {
                "all": "全部"
            },
            "today": "今天",
            "sendDownloadLink": "收件人電子郵件地址",
            "generaltingYourExcel": "請注意，生成 Excel 文件可能需要一些時間。 一旦準備好，我們會立即通過電子郵件發送給您。",
            "messageSuccess": "文件正在進行中。 準備好後您會收到一封電子郵件。 跟蹤進度或直接從報告/導出歷史記錄下載",
            "thisMonth": "這個月",
            "lastMonth": "上個月",
            "past30Days": "過去 30 天",
            "thisYear": "今年",
            "custom": "自定義",
            "customize": "定制",
            "thisWeek": "本週",
            "past7Days": "過去 7 天",
            "weekly": "每周",
            "monthly": "每月",
            "allTime": "全部時間",
            "tomorrow": "明天",
            "nextWeek": "下周",
            "lastWeek": "上周",
            "nextMonth": "下個月",
            "yesterday": "昨天",
            "fromDate": "從日期",
            "toDate": "至日期",
            "driver": "司機：%{name}",
            "customer": "乘客姓名: %{name}",
            "referralCode": "推薦碼：%{name}",
            "referer": "推薦人姓名:%{name}",
            "referee": "被推薦人員：%{name}",
            "usedPromoCode": "促銷：%{name}",
            "voucherCode": "代碼: %{name}",
            "city": "城市",
            "company": "車隊供應商",
            "company_report": "供應商：%{name}",
            "campaign_report": "活動：%{name}",
            "operator": "操作員",
            "farmType": "供應類型",
            "carLicensePlate": "車輛車牌: %{name}",
            "settlementType": "類型",
            "bookingService": "網絡類型",
            "payoutOption": "支付選項",
            "currency": "貨幣",
            "bookingFrom": "預訂自",
            "thirdPartyAction": "操作",
            "paymentMethod": "付款方式",
            "paymentStatus": "支付狀態",
            "bookingType": "訂單類型",
            "adjustPrice": "調整價格",
            "intercityRoute": "專線",
            "ratingType": "評級類型",
            "stars": "評分",
            "driverRating": "司機評級",
            "vehicleRating": "車輛評級",
            "mDispatcherType": "合作夥伴類型",
            "mDispatcher": "合作夥伴名稱",
            "campaign": "活動",
            "promocode": "促銷代碼",
            "canceledBy": "取消日期：",
            "timezone": "時區",
            "month": "月",
            "year": "年",
            "view": "查看",
            "action": "操作",
            "status": "狀態",
            "module": "模塊",
            "driverType": "類型",
            "vehicleType": "車型",
            "serviceType": "服務類型",
            "expireDate": "到期日",
            "rideSharing": "拼車",
            "rideService": "乘車服務",
            "transactionType": "交易類型",
            "completeFromCC": "由客服完成",
            "withDrawalTransactionType": "交易類型",
            "bookingStatus": "訂單狀態",
            "withdrawalStatus": "狀態",
            "originalStatus": "原始付款狀態",
            "currentStatus": "當前付款狀態",
            "tip": "小費",
            "type": "類型",
            "userType": "用戶類型",
            "eventType": "事件類型",
            "eventState": "事件狀態",
            "eventName": "活動名稱",
            "merchantType": "商戶類型",
            "merchantService": "商戶服務",
            "storeName": "店鋪",
            "merchant": "商號",
            "event": "事件名稱：%{name}",
            "store": "商店名稱：%{name}",
            "selectedMerchant": "商家名稱}",
            "associatedApps": "關聯應用程序",
            "settlement": "清算",
            "rideServiceItem": {
                "all": "全部",
                "regulation": "常規",
                "rideSharing": "拼車"
            },
            "actionItem": {
                "all": "全部",
                "login": "登錄",
                "logout": "登出",
                "bookIn": "可用的",
                "bookOff": "無法使用",
                "deny": "拒絕",
                "ignore": "忽略",
                "accept": "接受",
                "startTrip": "在途中",
                "updateBooking": "更新",
                "arrive": "到達",
                "pickup": "接載",
                "drop": "送達",
                "complete": "完成",
                "noShow": "NOSHOW",
                "rqJob": "調度",
                "cancel": "取消"
            },
            "loginStatusItem": {
                "all": "全部",
                "online": "已登錄",
                "offline": "未登錄"
            },
            "payoutType": "支付類型",
            "affiliationPayoutTypeItem": {
                "auto": "自動付款",
                "manual": "手動支付"
            },
            "affiliationSettlementItem": {
                "payable": "應付",
                "receivable": "應收賬款"
            },
            "affiliationPaymentMethod": {
                "online": "在線的",
                "directInvoicing": "月結"
            },
            "payoutPaymentStatusItem": {
                "paid": "已付",
                "pending": "待付"
            },
            "farmTypeItem": {
                "all": "全部",
                "farmIn": "接收外單",
                "farmOut": "訂單外發",
                "roaming": "漫遊",
                "affiliationOwner": "聯盟服務商",
                "farmInAndAffiliationOwner": "接受聯盟供應",
                "farmOutAndAffiliationOwner": "發出聯盟服務商"
            },
            "settlementDriverTypeItem": {
                "all": "所有司機",
                "individual": "公司車輛"
            },
            "shortTrip": {
                "all": "全部",
                "shortTrip": "短行程",
                "nonShortTrip": "非短行程"
            },
            "paymentMethodItem": {
                "B2BTerminal": "B2B終端",
                "webBooking": "網上預訂",
                "pendingPayment": "待付款",
                "all": "全部",
                "cash": "現金",
                "cashBySender": "發件人付現",
                "cashByRecipient": "收件人付現",
                "personalCard": "個人卡",
                "corporateCard": "企業卡",
                "qrCodePayment": "QR碼付款",
                "directBilling": "月結",
                "externalCard": "外部卡",
                "mDispatcherCard": "合作夥伴卡",
                "corporatePrepaid": "企業預付",
                "canceled": "取消",
                "noShow": "NOSHOW",
                "incident": "事故",
                "fleetCard": "外部卡",
                "applePay": "Apple Pay",
                "creditCardExternal": "信用卡",
                "paxWallet": "錢包",
                "tngeWallet": "TnG電子錢包",
                "vippseWallet": "VIPPS",
                "zainCash": "ZAINCASH",
                "xendit": "Xendit",
                "FIB": "FIB",
                "SantimPay": "SantimPay",
                "gCash": "GCASH",
                "completedWithoutService": "使用外部司機完成訂單",
                "BankOfGeorgia": "Georgia銀行",
                "partialCompleted": "部分付款",
                "failed": "失敗的",
                "googlePay": "Google Pay",
                "paymentLink": "支付鏈接",
                "easyPaisa": "Easy Paisa",
                "jazzCash": "Jazz Cash"
            },
            "paymentStatusItem": {
                "all": "全部",
                "fullPayment": "全額付款",
                "partialPayment": "部分付款",
                "pendingPayment": "待付款",
                "partialRefund": "退還部分款項",
                "fullRefund": "全額退款"
            },
            "companySettlementTypeItem": {
                "all": "全部",
                "fleetOweCompany": "運營商欠供應商",
                "companyOweFleet": "供應商欠運營商"
            },
            "bookingStatusItem": {
                "all": "全部",
                "completed": "完成",
                "incident": "事故",
                "paxNoShow": "乘客NOSHOW",
                "driverNoShow": "司機NOSHOW",
                "rejected": "拒絕",
                "canceledByTimeOut": "因超時而取消",
                "canceledByTimeOutRejected": "因超時取消（拒絕）",
                "canceledByBookingBoard": "被預訂平台取消",
                "canceledByCustomer": "被客戶取消",
                "canceledBySupplier": "被供應商取消"
            },
            "bookingFromItem": {
                "all": "全部",
                "paxApp": "乘客App",
                "mDispatcher": "個人夥伴",
                "kiosk": "自助機",
                "cc": "控制中心",
                "webBooking": "網上預訂",
                "carHailing": "網約車",
                "streetSharing": "街道街頭拼車",
                "dashboard": "預訂板",
                "corp": "預訂板 (CTM)",
                "dmc": "預訂板 (DMC)",
                "Boost_web_link": "BOOST鏈接"
            },
            "thirdPartyActionItem": {
                "all": "全部",
                "searched": "已搜索",
                "allocated": "已分配",
                "updated": "更新",
                "cancelled": "取消"
            },
            "bookingTypeItem": {
                "batchDelivery": "批量發貨",
                "all": "全部",
                "now": "實時訂單（出行）",
                "reservation": "預約訂單（出行）",
                "intercity": "專線",
                "delivery": "送貨",
                "shuttle": "穿梭"
            },
            "adjustPrices": {
                "all": "全部",
                "normalPrice": "正常價格",
                "addonPrice": "附加價格"
            },
            "bookingServiceItem": {
                "all": "全部",
                "roaming": "漫遊",
                "homeFleet": "對外發單",
                "localBooking": "本地預訂",
                "farmIn": "接收外單",
                "farmInAndAffiliationOwner": "提供服務和聯盟服務商",
                "provideService": "提供服務和聯盟服務商"
            },
            "ratingTypeItem": {
                "all": "全部",
                "like": "喜歡",
                "dislike": "不喜歡"
            },
            "starsItem": {
                "all": "全部",
                "star1": "1 星",
                "star2": "2 星",
                "star3": "3 星",
                "star4": "4 星",
                "star5": "5 星"
            },
            "incidentCanceledTypes": {
                "all": "全部",
                "incident": "事故",
                "canceled": "取消"
            },
            "canceledByItem": {
                "all": "全部",
                "CorpAD": "企業管理員",
                "passenger": "乘客",
                "driver": "司機",
                "merchant": "商號",
                "CC": "控制中心",
                "mDispatcher": "個人夥伴",
                "Partner": "個人夥伴",
                "webBooking": "網上預訂",
                "timeout": "超時"
            },
            "bookingSummaryActionItem": {
                "all": "全部",
                "create": "創建",
                "update": "更新",
                "cancel": "取消",
                "complete": "完成",
                "incident": "事故"
            },
            "transactionTypeItem": {
                "bankAccount": "轉入銀行賬戶",
                "sentToCustomer": "發送給客戶",
                "receivedFromDriver": "收到司機發來的信息",
                "sentToDriver": "發送給司機",
                "creditWallet": "轉移至信用錢包",
                "netEarning": "淨收入",
                "referralEarning": "推薦收入",
                "all": "全部",
                "topUp": "充值",
                "editBalance": "編輯餘額",
                "bookingDeduction": "訂單扣除",
                "bookingPromo": "預訂促銷",
                "topUpGCash": "通過GCash充值",
                "cashWallet": "現金錢包",
                "topUpMOLPay": "通過 Razer Pay 充值",
                "topUpZainCash": "通過 ZainCash 充值",
                "topUpXendit": "Top up by Xendit",
                "topUpFIB": "Top up by FIB",
                "topUpSantimPay": "Top up by SantimPay",
                "topUpTelebirr": "通過 Telebirr 充值",
                "topUpKsher": "由 Ksher 充值",
                "topUpWallet": "{0}充值",
                "TnG": "通過 TnG 充值",
                "credit": "通過信用卡充值",
                "ride": "用車",
                "tipAfterCompleted": "用車後小費",
                "debtWriteOff": "欠款衝銷",
                "refund": "退款",
                "excessOffsetAmount": "超過現金信用",
                "excessDeductionAmount": "超過現金借記",
                "cashExcess": "超過現金借記",
                "gateway": "從網關充值",
                "driverTopUpForPax": "超過現金借記",
                "updatedBalance": "更新餘額",
                "partialEarning": "收入",
                "payout": "支出",
                "payoutManual": "付款手冊",
                "payoutDriver": "付款",
                "itemValue": "項目值",
                "redeemVoucher": "兌換碼#",
                "topUpReferrer": "推薦獎勵",
                "driverInsurance": "司機保險",
                "cancellationPenalty": "取消罰款",
                "WingBank": "轉賬至 Wingbank 賬戶",
                "customerDebt": "客戶欠款",
                "googlePay": "通過 Google Pay 充值",
                "applePay": "通過 Apple Pay 充值",
                "merchantCommission": "商戶傭金",
                "eWallet": "Transferred to external e-wallet",
                "reversed": "Withdrawal reversed"
            },
            "moduleItem": {
                "all": "全部",
                "bookings": "預訂",
                "customers": "客戶",
                "dispatch": "調度",
                "general": "一般",
                "promotion": "促銷",
                "quest": "任務",
                "rate": "價格包",
                "car": "車輛",
                "city": "城市",
                "company": "供應商",
                "drivers": "司機",
                "driverSettlement": "司機結算",
                "login": "登錄",
                "logout": "登出",
                "mDispatcher": "夥伴",
                "mDispatcherType": "合作夥伴類型",
                "operation": "運營",
                "partners": "合作夥伴",
                "permission": "權限",
                "queuingArea": "排隊區",
                "report": "報告",
                "shift": "排班",
                "user": "用戶",
                "voiceSms": "語音和短信",
                "intercityZone": "區域（專線）",
                "Payout": "支付",
                "code#": "代碼 ＃",
                "ApiManagement": "API管理"
            },
            "serviceTypeItem": {
                "all": "全部",
                "oneWay": "單程",
                "fromAirport": "接機",
                "toAirport": "送機",
                "hourly": "按時計費",
                "roundTrip": "往返",
                "fromAirportHourlyDaily": "接機 - 按時計費",
                "fromAirportRoundTrip": "接機 - 往返",
                "toAirportHourlyDaily": "送機 - 按時計費",
                "toAirportRoundTrip": "送機 - 往返"
            },
            "merchantTransactionTypeItem": {
                "all": "全部",
                "editBalance": "編輯餘額",
                "payout": "支付",
                "earning": "收入",
                "itemValue": "物品價值",
                "orderDeduction": "訂單扣除"
            },
            "withdrawalTransactionType": {
                "WingBank": "轉入Wingbank賬戶",
                "bankAccount": "轉入銀行賬戶",
                "cashWallet": "轉入現金錢包",
                "creditWallet": "轉移至信用錢包"
            },
            "payoutDriver": {
                "driversHaveBankAccount": "司機有銀行賬戶",
                "driversDontHaveBankAccount": "司機沒有銀行賬戶",
                "payoutEndTime": "支付結束時間",
                "driverHaveInvalidBankAccount": "司機的銀行賬戶無效"
            },
            "payoutMerchant": {
                "HaveBankAccount": "商戶有銀行賬戶",
                "DontHaveBankAccount": "商戶沒有銀行賬戶",
                "HaveInvalidBankAccount": "商戶銀行賬戶無效",
                "payoutEndTime": "支付結束時間"
            }
        },
        "error": {
            "23005": "由於優化系統性能，您只能查看 %{interval} %{unitLabel} 內的報告，請調整您的日期範圍"
        },
        "result": {
            "idPermitType": "證件類型",
            "noResultFound": "沒有找到結果",
            "page": "頁",
            "itemsPerPage": "每頁項目",
            "showPages": "顯示了 %{from} - %{to}，共 %{total}",
            "exportToExcel": "導出至 Excel",
            "exportToCSV": "導出為 csv",
            "exportToPaymentFile": "導出至付款文件",
            "pay": "支付",
            "paySelected": "支付選擇",
            "payAll": "全部付清",
            "invalidBankAccount": "銀行賬戶無效",
            "validBankAccount": "有效的銀行賬戶",
            "bookingDetails": {
                "successSendMessage": "您的留言已發送",
                "packageName": "包裹名字",
                "transactionId": "交易ID",
                "proofOfFailure": "失敗證明",
                "recipientsNumber": "收件人人數",
                "recipientsInfo": "收件人信息",
                "creditTransactionFee": "信用卡費用",
                "carTypeRequest": "請求的車型",
                "bookingId": "訂單#",
                "bookingStatus": "訂單狀態",
                "tripId": "行程＃",
                "bookFrom": "預訂自",
                "bookingService": "網絡類型",
                "bookingType": "訂單類型",
                "serviceType": "服務類型",
                "operator": "操作員",
                "customerPhone": "乘客電話號碼",
                "customerName": "乘客姓名",
                "customerIdNumber": "乘客 ID# / IC#",
                "corpDivision": "處",
                "corpDept": "部門",
                "chargeCode": "收費代碼",
                "tripDescription": "行程描述",
                "rideSharing": "拼車",
                "queueName": "排隊區",
                "bookingTime": "創建時間",
                "pickupTime": "接載時間",
                "expectedPickupTime": "預計接載時間",
                "dispatchTime": "調度時間",
                "onBoardTime": "上車時間",
                "droppedOffTime": "送達時間",
                "onBoardDuration": "行程持續時間",
                "dispatch": "調度 ＃",
                "completedTime": "完成時間",
                "driverName": "司機姓名",
                "driverIdPermit": "ID# / 國家身份證#",
                "driverLicenseNumber": "駕駛執照號碼",
                "companyName": "供應商",
                "carType": "車型",
                "licensePlate": "车牌",
                "intercityRoute": "專線",
                "pickupLocation": "出發地址",
                "geoPickup": "出發地址經緯度",
                "extraDestination": "額外目的地",
                "originDestination": "原目的地",
                "originEstDistance": "原始估計距離",
                "destination": "實際目的地",
                "geoDestination": "實際目的地經緯度",
                "pickupFrom": "出發地址",
                "distanceTour": "行程距離",
                "internalNotes": "內部備注",
                "notes": "備注",
                "basicFareCalculator": "基本車費計算器",
                "basicFare": "基本車費",
                "editFare": "編輯基本車費",
                "oldBasicFare": "原基本車費",
                "airportFee": "機場費",
                "meetDriver": "舉牌",
                "rushHour": "附加費",
                "surchargeParameter": "動態附加費",
                "dynamicFare": "動態票價",
                "heavyTraffic": "交通繁忙",
                "tollFee": "路橋費",
                "parkingFee": "Parking fee",
                "gasFee": "Gas fee",
                "otherFees": "其他費用",
                "subtotal": "小計",
                "subTotalFF": "小計 FF",
                "techFee": "技術費",
                "serviceFee": "額外服務",
                "fleetServiceFee": "車隊服務",
                "bookingFee": "預訂費",
                "salesTax": "銷售稅",
                "tipAmt": "小費金額",
                "promoCode": "促銷代碼",
                "promoAmt": "促銷金額",
                "requestedTotalAmount": "要求的總金額",
                "total": "總金額",
                "totalNotRounded": "回合（總金額）",
                "actualReceived": "實際收到",
                "differenceAmt": "差異（收到）",
                "customerDebt": "客戶欠款",
                "markupDifference": "金額差別",
                "receivedWhenDropOff": "下車時收到",
                "refundAmount": "退款金額",
                "requestedPaymentMethod": "請求的付款方式",
                "paidBy": "由...支付",
                "splitPayment": "分賬付款",
                "paidByWallet": "通過錢包支付",
                "paidByOtherMethod": "通過其他方式支付",
                "cashReceived": "收到現金",
                "transferredChangeByWallet": "通過錢包轉賬找零",
                "returnedChangeByCash": "現金找零",
                "instructions": "指示",
                "additionalServiceName": "姓名",
                "additionalServiceValue": "價值",
                "sendReceiptHeader": "收據頁眉",
                "sendReceipt": "發送收據",
                "send": "發送",
                "cancel": "取消",
                "sentReceiptSuccess": "收據已成功發送",
                "sentReceiptFailed": "目前無法發送收據。 請再試一次。",
                "coRider": "拼車者",
                "coriderName": "姓名",
                "stopAddress": "額外目的地",
                "stopGeo": "緯度/經度",
                "addonPrice": "客戶報價",
                "oldSubTotal": "調整前小計",
                "view_good_receipt": "貨物收據",
                "view_delivery_receipt": "發貨照片",
                "view": "查看",
                "tipAfterCompletedDetails": "已轉移：{0}{1}； 交易費用：{0}{2}",
                "fleetMarkup": "利潤",
                "editedDriverEarning": "編輯司機收入",
                "driverEarning": "司機收入",
                "pickUpZoneName": "接送區",
                "dynamicFactor": "Dynamic fare (factor)"
            },
            "financial": {
                "driverName": "司機姓名",
                "driverPhone": "電話號碼",
                "driverUserName": "用戶名",
                "companyName": "供應商",
                "bookingList": "訂單列表",
                "totalFare": "不含促銷的總票價",
                "netEarning": "淨利潤",
                "showBookingList": "顯示",
                "referralEarning": "推薦收入",
                "totalEarningNet": "總收入（淨額）",
                "totalEarningNetTooltip": "將所有費用退還給運營商後您賺取的錢。 總收入（淨收入）=淨收入+行程後小費",
                "totalEarningNetTooltipRefferal": "將所有費用退還給運營商後您賺取的錢。 總收入（淨收入）=推薦收入+淨收入+乘車後小費",
                "totalEarningGross": "總收入（毛額）",
                "totalEarning": "總收入",
                "totalEarningGrossTooltip": "您通過訂單車費和小費賺取的錢。 總收入（毛收入） = 毛收入 + 行程後小費",
                "totalEarningGrossTooltipRefferal": "您通過訂單車費和小費賺取的錢。 總收入（毛收入）= 推薦收入 + 毛收入 + 乘車後小費",
                "note": "備注",
                "shortTrip": "短行程"
            },
            "financialDriver": {
                "supplierAmount": "Supplier commission",
                "payoutType": "支付類型",
                "date": "日期",
                "driverTipAfterCompleted": "行程後小費",
                "bookingId": "訂單ID",
                "paidBy": "由...支付",
                "bookFrom": "預訂自",
                "bookingType": "訂單類型",
                "companyName": "供應商",
                "driverName": "司機",
                "driverIdPermit": "ID# / 國家身份證#",
                "totalFare": "不含促銷的總票價",
                "subtotal": "小計",
                "airportFee": "機場費",
                "meetDriver": "舉牌",
                "tollFee": "路橋費",
                "parkingFee": "Parking fee",
                "gasFee": "Gas fee",
                "rushHour": "高峰時間",
                "transactionFee": "交易費",
                "techFee": "技術費",
                "salesTax": "銷售稅",
                "tipAmt": "小費金額",
                "bookingFee": "預訂費",
                "commission": "傭金",
                "deduction": "扣除",
                "promoAmt": "促銷金額",
                "ridePayment": "乘車支付",
                "driverTax": "司機稅",
                "grossEarning": "毛收入",
                "driverDeduction": "司機扣除",
                "netEarning": "淨利潤",
                "payToDriver": "支付給司機",
                "payToFleet": "支付給運營商",
                "totalNotRounded": "回合（總金額）",
                "fleetServiceFee": "運營商服務費",
                "editedDriverEarning": "編輯司機收入",
                "stripeFee": "Stripe Fee"
            },
            "financialCompany": {
                "companyName": "供應商",
                "homeFleetNetEarning": "運營商淨利潤",
                "affiliateNetEarning": "聯營公司淨利潤",
                "totalNetEarning": "司機淨收入總額",
                "status": "狀態",
                "companyAmount": "供應商利潤"
            },
            "companySettlement": {
                "companyName": "供應商",
                "pendingSettlement": "待結算",
                "viewDetails": "查看詳情",
                "settlementType": "類型"
            },
            "companySettlementDetails": {
                "bookId": "訂單ID",
                "dateTime": "時間日期",
                "originalPaymentStatus": "原始付款狀態",
                "subTotal": "小計",
                "fleetAmount": "運營商金額",
                "driverAmount": "司機金額",
                "companyAmount": "供應商金額",
                "companyOweFleet": "供應商欠運營商",
                "fleetOweCompany": "運營商欠供應商",
                "notes": "備注"
            },
            "financialProfit": {
                "profit": "利润",
                "bookingId": "訂單#",
                "subTotal": "小計",
                "subTotalFF": "小計 FF",
                "fleetCommission": "運營商傭金",
                "techFee": "技術費",
                "promotion": "促銷",
                "markupDifference": "金額差異",
                "Profit": "利潤",
                "currency": "貨幣",
                "fleetServiceFee": "運營商服務（付運營商）",
                "fleetServiceFeeTooltip": "運營商從服務費中收取傭金的金額"
            },
            "promotion": {
                "date": "日期",
                "promoCode": "促銷代碼",
                "users": "用戶",
                "uses": "用途",
                "checked": "已檢查",
                "applied": "應用",
                "totalPromoAmount": "促銷總額",
                "totalReceipt": "總收據",
                "numberOfUsers": "用戶數量",
                "numberOfPromoCodeAppliance": "促銷代碼設備數量",
                "numberOfSuccessfulBookings": "成功訂單數量",
                "numberOfCompletedBookings": "已完成訂單數量",
                "numberOfCompletedUsers": "已完成用戶數",
                "numberOfUsersApplyPromotion": "申請促銷的用戶數",
                "numberOfUsersApplyPromotionAndCompleteBooking": "申請促銷並完成預訂的用戶數",
                "numberOfPromoCodeApplianceTooltip": "所有用戶使用促銷代碼的數量",
                "numberOfSuccessfulBookingsTooltip": "使用促銷代碼成功創建的訂單數量",
                "numberOfCompletedBookingsTooltip": "使用促銷代碼完成的訂單數量"
            },
            "export": {
                "createdDate": "日期",
                "sessionName": "會話名稱",
                "download": "下載",
                "delete": "刪除",
                "percent": "百分",
                "status": "狀態",
                "name": "模塊名稱"
            },
            "redeemed": {
                "date": "日期",
                "code": "代碼",
                "customers": "顧客",
                "phone": "電話號碼",
                "value": "價值",
                "currency": "貨幣",
                "campaignName": "推廣活動"
            },
            "carActivity": {
                "plateNumber": "車輛車牌",
                "vehicleType": "車型",
                "companyName": "供應商",
                "day1st": "1日",
                "day2nd": "2日",
                "day3rd": "3日",
                "day4th": "4日",
                "day5th": "5日",
                "day6th": "6日",
                "day7th": "7日",
                "day8th": "8日",
                "day9th": "9日",
                "day10th": "10日",
                "day11th": "11日",
                "day12th": "12日",
                "day13th": "13日",
                "day14th": "14日",
                "day15th": "15日",
                "day16th": "16日",
                "day17th": "17日",
                "day18th": "18日",
                "day19th": "19日",
                "day20th": "20日",
                "day21st": "21日",
                "day22nd": "22日",
                "day23rd": "23日",
                "day24th": "24日",
                "day25th": "25日",
                "day26th": "26日",
                "day27th": "27日",
                "day28th": "28日",
                "day29th": "29日",
                "day30th": "30日",
                "day31st": "31日",
                "operationDays": "運營日",
                "activeCars": "啓用車輛總數："
            },
            "carActivityLogsTitle": "日誌",
            "carActivityLogs": {
                "licensePlate": "车辆车牌",
                "companyName": "供應商",
                "bookingId": "訂單ID",
                "driverName": "司機姓名",
                "driverIDNumber": "ID# / 國家身份證#",
                "date": "受理時間",
                "bookFrom": "預訂自"
            },
            "affiliation": {
                "date": "接機時間",
                "bookingId": "訂單ID",
                "bookingType": "訂單類型",
                "homeFleet": "運營商",
                "providerFleet": "供應商車隊",
                "driver": "司機",
                "passenger": "乘客",
                "paidBy": "由...支付",
                "airportFee": "機場費",
                "meetDriver": "舉牌",
                "rushHour": "附加費",
                "tollFee": "路橋費",
                "parkingFee": "Parking fee",
                "gasFee": "Gas fee",
                "heavyTraffic": "交通繁忙",
                "otherFees": "其他費用",
                "provider": "供應商",
                "basicFareProvider": "基本車費提供商",
                "subTotalProvider": "供應商小計",
                "techFeeProvider": "技術費提供商",
                "bookingFeeProvider": "預訂費提供商",
                "taxValueProvider": "稅值提供者",
                "tipProvider": "小費提供者",
                "totalFareProvider": "總票價提供商",
                "basicFare": "基本車費",
                "subtotal": "小計",
                "techFee": "技術費",
                "bookingFee": "預訂費",
                "tax": "稅",
                "tip": "小費",
                "totalFare": "總票價",
                "payoutInLocalCurrency": "以當地貨幣計算的乘車收入",
                "payoutInUSD": "乘車收入（美元）",
                "fleetCommInUSD": "車隊傭金（美元）",
                "bookingFeeInUSD": "預訂費以美元計算",
                "totalPayoutInUSD": "總支出（美元）",
                "subTotal": "小計",
                "bookingStatus": "訂單狀態",
                "vehicle": "車輛",
                "customerPhone": "客戶電話號碼",
                "driverPhone": "司機電話號碼",
                "total": "全部的",
                "totalInUSD": "總票價（美元）",
                "supplierPayout": "供應商付款",
                "supplierPayoutInUSD": "供應商付款（美元）",
                "buyerPayout": "買家傭金",
                "buyerPayoutInUSD": "傭金（美元）",
                "buyerCommission": "傭金",
                "buyerCommissionInUSD": "傭金（美元）",
                "receivableInUSD": "應收賬款（美元）",
                "payableInUSD": "應付金額（美元）",
                "fleetMarkupInUSD": "利潤（美元）",
                "paymentMethod": "付款方式",
                "paymentStatus": "支付狀態"
            },
            "prepaidTopUp": {
                "date": "日期時間",
                "topUpId": "充值ID",
                "transactionId": "交易ID",
                "name": "名稱",
                "company": "供應商",
                "paidBy": "由...支付",
                "amount": "數量",
                "newAmount": "新餘額",
                "transactionType": "交易類型"
            },
            "operatorLogs": {
                "date": "日期",
                "operator": "操作員",
                "username": "用戶名",
                "module": "模塊",
                "description": "描述"
            },
            "corporate": {
                "date": "日期",
                "bookingId": "訂單ID",
                "operatorName": "企業操作員",
                "corporateName": "供應商",
                "corpTraveler": "企業用戶",
                "travelerSignature": "乘客簽名",
                "corpId": "公司編號",
                "corpDivision": "集團事業部",
                "costCentre": "成本中心",
                "corpPO": "公司採購訂單",
                "managerName": "經理姓名",
                "managerEmail": "經理的電子郵件",
                "title": "職銜",
                "department": "部門",
                "clientCaseMatter": "客戶案例/行程描述",
                "chargeCode": "費用代碼/行程代碼",
                "driverName": "司機",
                "pickupLocation": "出發地",
                "destination": "目的地",
                "total": "總金額",
                "subtotal": "小計",
                "subTotalFF": "小計 FF",
                "commission": "傭金",
                "commissionBooking": "從 app/pwa 進行預訂申請傭金",
                "paidBy": "由...支付",
                "statusDisplay": "狀態",
                "flightNumber": "航班號"
            },
            "bookingLogs": {
                "bookingId": "訂單ID",
                "bookingService": "網絡類型",
                "bookingType": "訂單類型",
                "driverName": "司機",
                "driverIdPermit": "ID# / 國家身份證#",
                "customerName": "乘客",
                "pickupTime": "接機時間",
                "onBoardTime": "上車時間",
                "droppedOffTime": "送達時間",
                "pickupLocation": "上車位置",
                "destination": "目的地",
                "dispatchHistory": "調度歷史",
                "otwDistance": "往出發地距離",
                "distanceTour": "行程距離",
                "duration": "行程時長",
                "paidBy": "由...支付",
                "bookFrom": "預訂自",
                "map": "地圖",
                "showDispatchHistory": "顯示",
                "showMap": "顯示",
                "paymentActivities": "支付活動"
            },
            "rating": {
                "date": "日期",
                "bookingId": "訂單ID",
                "driverName": "司機",
                "driverIdPermit": "ID# / 國家身份證#",
                "customerName": "乘客姓名",
                "customerPhone": "客戶電話",
                "like": "喜歡",
                "dislike": "反感",
                "notes": "評論",
                "stars": "評分",
                "vehicleRating": "車輛評級",
                "driverRating": "司機評級",
                "plateNumber": "車牌號碼"
            },
            "mDispatcher": {
                "date": "日期",
                "bookingId": "訂單ID",
                "mDispatcherName": "合作夥伴名稱",
                "mDispatcherType": "合作夥伴類型",
                "subtotal": "小計",
                "commission": "傭金",
                "paidBy": "由...支付"
            },
            "totalAdjustment": {
                "date": "日期",
                "bookingId": "訂單ID",
                "oldAmount": "全部的",
                "type": "類型",
                "markupDifference": "金額差別",
                "newAmount": "編輯總計/加價",
                "username": "操作員",
                "reason": "原因"
            },
            "incidentCancellation": {
                "date": "日期",
                "bookingId": "訂單ID",
                "customerName": "乘客姓名",
                "customerPhone": "客戶電話",
                "bookFrom": "預訂自",
                "canceledBy": "取消日期：",
                "type": "類型",
                "reason": "原因",
                "reasonCode": {
                    "0": "我找到了另一種行程選擇",
                    "1": "司機距離太遠",
                    "2": "我輸入了錯誤的位置",
                    "3": "我等了太久",
                    "4": "司機要求取消",
                    "5": "我改變了計劃",
                    "6": "我想改變位置",
                    "101": "大部分或全部商品不可用",
                    "102": "我們現在太忙了",
                    "103": "我們現在關門了",
                    "104": "我們即將關門"
                },
                "canceller": "取消人"
            },
            "acceptanceCancellationRate": {
                "companyId": "供應商ID",
                "driverId": "司機 ID",
                "driverName": "司機姓名",
                "phoneNumber": "電話號碼",
                "company": "供應商",
                "acceptanceRate": "接單率",
                "cancellationRate": "取消率",
                "rating": "評分"
            },
            "revenueFleet": {
                "date": "日期",
                "fareRevenue": "車費收入",
                "trip": "行程",
                "avgFareTrip": "平均車費/行程",
                "drivers": "# 司機",
                "avgTripPerDriver": "平均行程/司機",
                "dispatcher": "# 調度員"
            },
            "revenueDriver": {
                "date": "日期",
                "driverName": "司機",
                "fareRevenue": "車費收入",
                "trip": "＃ 行程",
                "avgFareTrip": "平均車費/行程"
            },
            "revenueMonthly": {
                "month": "月",
                "trip": "已完成行程",
                "subtotal": "小計",
                "tipAmt": "小費",
                "techFee": "技術費",
                "salesTax": "稅",
                "partnerCommission": "合作夥伴傭金/ODD",
                "totalFare": "不含促銷的總票價",
                "netEarning": "淨利潤",
                "fleetServiceFee": "運營商服務費"
            },
            "dailyDriver": {
                "driverName": "司機",
                "driverIDPermit": "ID# / 國家身份證#",
                "driverPhone": "電話",
                "companyName": "供應商",
                "workingHoursOnline": "在線（小時）",
                "workingHoursAvailable": "接單（小時）",
                "driverWorkingHours": "司機工作時間",
                "distance": "司機行駛距離",
                "CC_activity": "控制中心活動",
                "fare_summary": "車費概況",
                "workingHoursNotAvailable": "不接單（小時）",
                "workingHoursPob": "載客（小時）",
                "workingHoursPobToolTip": "載客時長",
                "workingHoursAccepted": "接單",
                "workingHoursReject": "拒絕",
                "workingHoursIgnore": "忽略",
                "workingHoursCancel": "取消",
                "completedTripsTotal": "合計",
                "completedTripsCc": "來自控制中心",
                "completedTripsCarHailing": "從網約車",
                "completedTripsStreetSharing": "來自街頭拼車",
                "completedTripsPaxApp": "來自乘客App",
                "completedTripsWebBooking": "從網上預訂",
                "completedTripsMDispatcher": "來自合作夥伴",
                "completedTripsKiosk": "從自助機",
                "completedTripsApi": "來自API",
                "ccActivityDispatchedTrips": "調度行程",
                "ccActivityAccepted": "接單",
                "ccActivityReject": "拒絕",
                "ccActivityIgnore": "忽略",
                "ccActivityCancelByPassenger": "被乘客取消",
                "ccActivityCancelByDriver": "被司機取消",
                "ccActivityCancelByCc": "控制中心取消",
                "ccActivityCancelByAPI": "通過 API 取消",
                "ccActivityNoShow": "NOSHOW",
                "fareSummaryTotalCash": "現金",
                "fareSummaryTotalCreditCard": "信用卡",
                "fareSummaryTotalExternalCard": "外置卡",
                "fareSummaryTotalPaxWallet": "錢包",
                "fareSummaryTotalDirectBilling": "月結",
                "fareSummaryTotalQrCode": "二維碼",
                "fareSummaryTotalPrePaid": "企業預付",
                "fareSummaryTotalApplePay": "Apple Pay",
                "fareSummaryTotalTngeWallet": "TNG電子錢包",
                "fareSummaryTotalVippseWallet": "VIPPS",
                "fareSummaryTotal": "全部的",
                "ratingNotes": "備注",
                "ratingDislike": "不喜歡的數量",
                "ratingLike": "喜歡的數量",
                "fareDetailsBasicFare": "基本車費",
                "fareDetailsAirportFee": "機場費",
                "fareDetailsRushHour": "附加費",
                "fareDetailsHeavyTraffic": "交通繁忙",
                "fareDetailsOtherFees": "其他費用",
                "fareDetailsSubTotal": "小計",
                "fareDetailsSubTotalFF": "小計 FF",
                "fareDetailsTechFee": "技術費",
                "fareDetailsBookingFee": "預訂費",
                "fareDetailsSalesTax": "銷售稅",
                "fareDetailsTip": "小費",
                "fareDetailsPromoAmount": "促銷金額",
                "fareDetailsTotalAmt": "總金額",
                "availableDistance": "可用的",
                "otwDistance": "往出發地",
                "otwDistanceToolTip": "往出發地距離",
                "pobDistance": "載客",
                "pobDistanceToolTip": "載客距離",
                "ratingStars1": "1星",
                "ratingStars2": "2星",
                "ratingStars3": "3星",
                "ratingStars4": "4星",
                "ratingStars5": "5星",
                "ratingAvg": "平均的",
                "ssnNumber": "社會安全號#",
                "driverEmail": "電子郵件地址",
                "driverAddress": "家庭地址",
                "fareDetailsAddonPrice": "客戶報價",
                "fareDetailsOldSubTotal": "調整前小計"
            },
            "dailyCar": {
                "carInfo": "車輛信息",
                "fareDetails": "車費詳情",
                "completedTrips": "已完成行程",
                "carInfoCompanyName": "供應商",
                "carInfoPermission": "權限",
                "carInfoVehiclePlateNumber": "车牌号",
                "carInfoVhcId": "車輛 ＃",
                "carInfoCarType": "車型",
                "completedTripsTotal": "全部的",
                "completedTripsCc": "來自控制中心",
                "completedTripsCarHailing": "從網約車",
                "completedTripsStreetSharing": "來自街頭拼車",
                "completedTripsPaxApp": "來自乘客App",
                "completedTripsWebBooking": "來自網絡預訂",
                "completedTripsMDispatcher": "來自合作夥伴",
                "completedTripsKiosk": "從自助機",
                "completedTripsAPI": "來自API",
                "fareDetailsBasicFare": "基本車費",
                "fareDetailsAirportFee": "機場費",
                "fareDetailsMeetDriver": "舉牌",
                "fareDetailsRushHour": "附加費",
                "fareDetailsHeavyTraffic": "交通繁忙",
                "fareDetailsTollFee": "路橋費",
                "fareDetailsParkingFee": "Parking fee",
                "fareDetailsGasFee": "Gas fee",
                "fareDetailsOtherFees": "其他費用",
                "fareDetailsSubTotal": "小計",
                "fareDetailsSubTotalFF": "小計 FF",
                "fareDetailsTechFee": "技術費",
                "fareDetailsBookingFee": "預訂費",
                "fareDetailsSalesTax": "銷售稅",
                "fareDetailsTip": "小費",
                "fareDetailsPromoAmount": "促銷金額",
                "fareDetailsTotalAmt": "總金額",
                "fareDetailsAddonPrice": "客戶報價",
                "fareDetailsOldSubTotal": "調整前小計"
            },
            "dailyDriverCar": {
                "driverInfo": "司機信息",
                "driverName": "司機姓名",
                "driverIDPermit": "ID# / 國家身份證#",
                "driverPhone": "電話號碼",
                "companyName": "供應商",
                "actionInfo": "操作",
                "date": "日期",
                "action": "操作",
                "bookingId": "訂單ID",
                "carInfo": "車輛信息",
                "permission": "權限",
                "licensePlate": "车牌",
                "carId": "車輛#",
                "carType": "車型"
            },
            "driverLoginStatus": {
                "driver": "司機",
                "driverIdPermit": "ID# / 國家身份證#",
                "phone": "電話號碼",
                "username": "用戶名",
                "company": "供應商",
                "carType": "車型",
                "licensePlate": "牌照",
                "status": "狀態"
            },
            "bookingSummary": {
                "date": "日期",
                "operator": "操作員",
                "username": "用戶名",
                "action": "操作",
                "bookingId": "訂單ID"
            },
            "cashWallet": {
                "bankAccount": "轉入銀行賬戶",
                "creditWallet": "轉移至信用錢包",
                "tipAfterCompleted": "完成後提示",
                "netEarning": "淨利潤",
                "referralEarning": "推薦收入",
                "receiverAccount": "收款賬戶",
                "dateAndTime": "日期時間",
                "driverName": "司機姓名",
                "phoneNumber": "電話號碼",
                "userName": "用戶名",
                "company": "供應商",
                "type": "類型",
                "bookingId": "訂單#",
                "operator": "操作員",
                "reason": "原因",
                "amount": "金額",
                "newAmount": "新餘額",
                "currency": "貨幣",
                "topUpGCashReason": "通過 Gcash 充值",
                "topUpReason": "通過信用卡充值 *{0}",
                "cashWalletReason": "從現金錢包充值",
                "topUpMOLPayReason": "Razer Pay [ {0} ]",
                "chargedAmount": "收費金額",
                "differentPercent": "折扣/額外費用 (%)",
                "operatorName": "操作員",
                "sendPayout": "操作",
                "sendPayoutTitle": "重新發送付款電子郵件",
                "payoutReason": "{0}（付款 {1} {2}）"
            },
            "creditWallet": {
                "receiverType": "接收人類型",
                "receiverName": "接收人姓名",
                "receiverPhone": "接收人電話號碼",
                "senderPhone": "發送人電話號碼",
                "senderName": "發送人名稱",
                "sentToCustomer": "發送給客戶",
                "sentToDriver": "發送給司機",
                "dateAndTime": "日期時間",
                "driverName": "司機姓名",
                "phoneNumber": "電話號碼",
                "userName": "用戶名",
                "company": "供應商",
                "type": "類型",
                "bookingId": "訂單#",
                "operator": "操作員",
                "reason": "原因",
                "amount": "數量",
                "newAmount": "新餘額",
                "currency": "貨幣",
                "transactionId": "交易ID",
                "topUpGCashReason": "通過 Gcash 充值",
                "topUpReason": "通過信用卡充值 *{0}",
                "cashWalletReason": "從現金錢包充值",
                "topUpMOLPayReason": "Razer Pay [ {0} ]",
                "topUpWalletReason": "{0} [ {1} ]",
                "chargedAmount": "收費金額",
                "differentPercent": "折扣/額外費用 (%)",
                "cashExcessReason": "客戶必須支付{0}； 收到現金{1}",
                "referralEarningReason": "客戶 {0} {1} 完成了首次預訂 {2}",
                "referralEarningReason_2": "客戶{0} {1}已完成預訂{2}",
                "insurance": "司機保險"
            },
            "passengerWallet": {
                "dateTime": "日期時間",
                "customerName": "乘客姓名",
                "phoneNumber": "電話號碼",
                "type": "類型",
                "bookingId": "訂單#",
                "changedAmount": "變更金額",
                "amount": "變更金額",
                "newBalance": "新餘額",
                "currency": "貨幣",
                "transactionId": "交易ID",
                "Note": "備注",
                "credit": "通過信用卡充值 *{0}",
                "TnG": "由 TnG #{0} 充值",
                "MOLPay": "從 [RazerPay - #{0}] 充值",
                "gatewayName": "從 [{0} - #{1}] 充值",
                "cashExcess": "客戶必須支付 {0}，給予現金 {1}",
                "editBalance": "編輯餘額",
                "ride": "行程",
                "topUp": "充值",
                "topUpReferee": "推薦獎勵 ({0})",
                "topUpReferrer": "新被推薦人 (#{0})：{1} ({2})"
            },
            "recipientsInfo": {
                "order": "號碼",
                "orderId": "號碼#",
                "name": "收件人姓名",
                "phone": "收件人電話#",
                "address": "收件人地址",
                "deliveryFee": "快遞費",
                "ItemValue": "商品價值（貨到付款）",
                "status": "狀態",
                "Performer": "執行人",
                "Reason": "原因",
                "proofOfFailure": "失敗證明",
                "referenceNumber": "參考編號"
            },
            "dispatchHistory": {
                "performer": "執行人",
                "name": "姓名",
                "action": "操作",
                "date": "日期",
                "location": "司機所在位置",
                "estimate": "車輛到出發地估計",
                "passenger": "顧客",
                "detachVehicle": "Detach Vehicle",
                "assignVehicle": "Assign Vehicle"
            },
            "payToDriver": {
                "driverName": "司機",
                "totalUnsettledTransactions": "未結算交易總額",
                "totalUnsettledAmount": "未結算總額",
                "actions": "操作",
                "pay": "支付",
                "remove": "消除",
                "clearSuccess": "選定司機的收入已成功結清。",
                "clearFailed": "清除司機收入數據失敗。 請再試一次。",
                "selectDriver": {
                    "title": "",
                    "body": "請選擇支付司機。",
                    "buttonTitle": "好"
                },
                "confirmClear": {
                    "title": "",
                    "body": "您確定要清除該司機的收入數據嗎？ 此操作無法撤消。",
                    "buttonTitle": "是",
                    "closeButtonText": "否"
                },
                "confirmPay": {
                    "body": "您確定要向選定的司機付款嗎？",
                    "buttonTitle": "是",
                    "closeButtonText": "否"
                },
                "paymentSuccessfully": "付款成功支付給司機"
            },
            "customerQuest": {
                "name": "姓名",
                "phoneNumber": "電話號碼",
                "supportId": "支持 ID",
                "ranking": "排行",
                "points": "積分",
                "numberOfReservation": "預約數量",
                "driver": "司機",
                "customer": "顧客",
                "firstWillWin": "先者為勝",
                "firstWillWinLuckyDraw": "先者為勝（幸運抽獎）",
                "largestWillWin": "最大者獲勝（排行榜）",
                "qualified": "合格的",
                "notQualified": "不合格",
                "userName": "用戶名",
                "completedDate": "完成日期",
                "isQualified": "狀態",
                "ticket": "票",
                "numOfComplete": "已完成數量",
                "bookingDistance": "載客距離",
                "numberOfIntercity": "專線數量",
                "numberOfDelivery": "發貨數量",
                "pleaseSelectEventName": "請選擇活動名稱。",
                "numberOfQualifiedSetting": "合格數（設）：",
                "numberOfActualQualified": "實際合格人數：",
                "driverRating": "司機評級",
                "acceptanceRating": "接單評級",
                "cancellationRating": "取消評級",
                "period": "時期："
            },
            "payoutToDriver": {
                "IFSCCode": "IFSC 代碼",
                "payoutID": "付款ID",
                "driverName": "司機",
                "driverPhone": "電話號碼",
                "companyName": "供應商",
                "period": "時期",
                "driverId": "ID# / 國家身份證#",
                "cashWalletBalance": "現金錢包餘額",
                "creditBalance": "信用額度",
                "holdAmount": "保留金額",
                "minPayout": "最少支付",
                "enterMinimumPayoutAmount": "輸入最低付款金額",
                "payoutAmount": "支付金額",
                "payoutDate": "付款日期",
                "actions": "操作",
                "pay": "支付",
                "remove": "消除",
                "payoutEndTime": "支付結束時間",
                "clearSuccess": "選定司機的收入已成功結清。",
                "clearFailed": "清除司機收入數據失敗。 請再試一次。",
                "amountKeepInDriverCashWallet": "司機現金錢包中保留的金額",
                "selectDriver": {
                    "title": "",
                    "body": "請選擇支付司機。",
                    "buttonTitle": "OK"
                },
                "confirmClear": {
                    "title": "",
                    "body": "您確定要清除該司機的收入數據嗎？ 此操作無法撤消。",
                    "buttonTitle": "是",
                    "closeButtonText": "否"
                },
                "confirmPay": {
                    "body": "您確定要向選定的司機付款嗎？ <br> - 司機數量：<b>%{drivers}</b> <br> - 每個司機的最低支出：%{currency} <b>%{min}</b> <br> - 保留 金額：%{currency}<b> %{hold}</b> <br> - 支出總額：%{currency} <b>%{total}</b>",
                    "buttonTitle": "是",
                    "closeButtonText": "否"
                },
                "paymentSuccessfully": "付款成功支付給司機",
                "paymentCompleted": "付款已完成：<br> - 付款成功：%{success} <br> - 失敗：%{failed} <br> - 成功付款總額：%{currency} %{total}",
                "noteSelectDrivers": "注意：選擇有銀行賬戶付款的司機。",
                "sendPayout": "發送付款成功通知至電子郵件地址",
                "accountNumber": "帳號",
                "iBanNumber": "IBAN號碼",
                "accountHoldName": "賬戶持有人名稱",
                "bankName": "銀行名",
                "selelctedDriverHasBeenPaid": "選定的司機已付款",
                "selelctedDriverHasBeenFailed": "所選司機失敗",
                "selelctedDriverHasBeenUpdateAsInvalid": "所選司機已更新為「無效銀行帳戶」",
                "selelctedDriverHasBeenUpdateAsValid": "所選司機已更新為「有效銀行帳戶」",
                "isBankAccountOwner": "受益人與司機相同",
                "isBankAccountOwner_true": "是",
                "isBankAccountOwner_false": "否",
                "beneficiaryIDIC": "受益人 ID# / IC#",
                "bankRelationship": "關係",
                "bankRelationship_1": "配偶",
                "bankRelationship_2": "女兒/兒子",
                "bankRelationship_3": "兄弟姐妹",
                "bankRelationship_4": "有關係的",
                "bankRelationship_5": "朋友們",
                "bankRelationship_6": "其他：{0}"
            },
            "payoutToMerchant": {
                "payoutId": "付款ID",
                "merchantName": "商家名稱",
                "phone": "電話號碼",
                "accountNumber": "帳號",
                "iBanNumber": "IBAN號碼",
                "bankAccountHolder": "賬戶持有人名稱",
                "bankName": "銀行名",
                "pendingAmount": "待付款金額",
                "payoutAmount": "支付金額",
                "payoutDate": "付款日期",
                "holdAmount": "保留金額",
                "minPayout": "最少支付",
                "selelctedMerchantHasBeenPaid": "所選商戶已付款",
                "selelctedMerchantHasBeenFailed": "所選商戶已失敗",
                "pay": "支付",
                "selectMerchant": {
                    "title": "警告",
                    "body": "請選擇商戶進行支付。",
                    "buttonTitle": "好的"
                },
                "confirmPay": {
                    "body": "您確定要向選定的商戶付款嗎？ <br> - 商家數量：<b>%{merchants}</b> <br> - 每個司機的最低支出：%{currency} <b>%{min}</b> <br> - 保留 金額：%{currency}<b> %{hold}</b> <br> - 支出總額：%{currency} <b>%{total}</b>",
                    "buttonTitle": "是",
                    "closeButtonText": "否"
                },
                "sendPayout": "發送付款成功通知至電子郵件地址",
                "HasBeenUpdateAsInvalid": "所選商戶已更新為「銀行賬戶無效」",
                "HasBeenUpdateAsValid": "所選商戶已更新為「有效銀行賬戶」"
            },
            "settlementHistory": {
                "date": "日期",
                "driverName": "司機姓名",
                "dateRange": "日期範圍",
                "totalTransaction": "已結算交易總額",
                "paidAmount": "總結算金額"
            },
            "settlement": {
                "driverName": "司機",
                "driverIdPermit": "ID# / 國家身份證#",
                "totalBookings": "總訂單",
                "total": "總計",
                "approved": "經核准的",
                "acct": "ACCT",
                "gross": "總值",
                "percent": 0.03,
                "net": "淨"
            },
            "documentExpiry": {
                "driverName": "司機姓名",
                "driverPhone": "司機電話",
                "company": "供應商",
                "documentName": "文件名稱",
                "expiredDate": "過期日期",
                "status": "狀態",
                "statusStr": {
                    "NA": "不適用",
                    "expired": "已到期",
                    "active": "啓用",
                    "expireSoon": "即將到期"
                }
            },
            "referralDetail": {
                "bookingId": "訂單#",
                "completedDate": "完成時間",
                "paidBy": "由...支付",
                "bookFrom": "預訂自",
                "driverName": "司機姓名",
                "driverIdPermit": "ID# / 國家身份證#",
                "companyName": "供應商",
                "Passenger": "乘客",
                "Customer": "顧客",
                "subtotal": "小計",
                "subTotalFF": "小計 FF",
                "subtotalWithPromo": "促銷小計",
                "referralEarning": "推薦收入",
                "Amount": "數量",
                "Note": "備注",
                "Currency": "貨幣",
                "PercentOfSubTotal": "小計的 {0}%",
                "max": "最大{0}",
                "referrerInfo": "司機介紹顧客",
                "phoneReferral": "司機電話#",
                "driverNumber": "ID# / 國家身份證#",
                "referralCode": "推薦碼"
            },
            "referralHistory": {
                "driverName": "司機姓名",
                "phoneNumber": "電話號碼",
                "companyName": "供應商",
                "referralCode": "推薦碼",
                "commission": "傭金總額",
                "expectedReferralEarning": "期望獲得推薦收入",
                "activePaxTooltip": "已完成一趟訂單以上且已向司機兌換推薦碼的乘客。",
                "referredPaxTooltip": "已向司機兌換推薦碼但尚未完成訂單的乘客。",
                "activePax": "活躍客戶總數",
                "referredPax": "推薦客戶總數",
                "detailFields": {
                    "passengerId": "乘客證件",
                    "passengerName": "乘客姓名",
                    "firstBookingDate": "第一個完成預訂日期",
                    "firstBookingDateGMT": "第一個完成預訂日期（格林威治標準時間）",
                    "passengerPhone": "客戶電話號碼",
                    "appliedDate": "推薦日期",
                    "referredByDriver": "由司機推薦",
                    "driverPhone": "司機電話號碼",
                    "referralCode": "推薦碼"
                },
                "showDetail": "展示",
                "detail": "細節"
            },
            "paxReferralHistory": {
                "customerName": "乘客姓名",
                "phoneNumber": "電話號碼",
                "referralCode": "推薦碼",
                "commission": "傭金總額",
                "activePaxTooltip": "已兌換推薦代碼並至少成功完成一次預訂的乘客。",
                "referredPaxTooltip": "已兌換推薦代碼但尚未預訂的乘客。",
                "activePax": "活躍客戶總數",
                "referredPax": "推薦客戶總數",
                "detailFields": {
                    "passengerId": "乘客證件",
                    "passengerName": "乘客姓名",
                    "firstBookingDate": "第一個完成預訂日期",
                    "firstBookingDateGMT": "第一個完成預訂日期（格林威治標準時間）"
                },
                "showDetail": "展示",
                "detail": "細節"
            },
            "driverRefersDriver": {
                "companyName": "供應商",
                "phoneNumber": "電話號碼",
                "username": "用戶名",
                "referralCode": "推薦碼",
                "referrer": "推薦人",
                "numberOfReferees": "被推薦人員人數",
                "activeReferees": "活躍被推薦人",
                "referee": "被推薦人",
                "refereePhone": "被推薦人電話",
                "refereeUsername": "被推薦人用戶名",
                "referredDate": "推薦日期",
                "activatedDate": "啓用日期",
                "referrerByDriver": "推薦人",
                "referrerPhone": "推薦人電話號碼",
                "referralStatus": "推薦狀態",
                "lifeTimeBookings": "終身訂單",
                "inReview": "審核中",
                "activated": "活性"
            },
            "driverWithdrawal": {
                "transferToEWallet": "Transferred to external e-wallet *{0}",
                "channelCode": "Channel code",
                "channelName": "Channel name",
                "reversed": "Reversed",
                "currentBalanceTooltip": "提款請求前司機現金錢包的當前餘額",
                "paymentTime": "付款時間",
                "requestTime": "請求時間",
                "referenceId": "參考 ＃",
                "transactionId": "交易ID",
                "description": "描述",
                "transferAmount": "轉賬金額",
                "currency": "貨幣",
                "approved": "得到正式認可的",
                "rejected": "拒絕",
                "failed": "失敗的",
                "reason": "原因"
            },
            "penaltyCompensation": {
                "penaltyAmount": "懲罰",
                "totalCollect": "需要用當地貨幣支付",
                "totalCollectInUSD": "需要用美元支付",
                "compensationAmount": "補償",
                "compensationAmountInUSD": "補償（美元）",
                "penaltyReason": "處罰理由",
                "estimatedPrice": "預估車費",
                "estimatedPriceInUSD": "預計車費（美元）",
                "penaltyAmountInUSD": "罰款（美元）",
                "amount": "數量",
                "amountInUSD": "金額（美元）"
            },
            "imcompletePayment": {
                "bookingId": "訂單ID",
                "bookFrom": "預訂自",
                "bookingTime": "預訂日期/時間",
                "completedTime": "預訂完成的日期/時間",
                "customerName": "乘客姓名",
                "requestedPaymentMethod": "請求的付款方式",
                "originalPaymentStatus": "原始付款狀態",
                "currentPaymentStatus": "當前付款狀態",
                "updatedDateTime": "餘額收款日期/時間",
                "totalAmt": "總金額",
                "originalOutstandingAmount": "原始未償還金額",
                "currentOutstandingAmount": "當前未償還金額",
                "outstandingAmountPaidBy": "未付金額收集者",
                "writeOffDebtMethod": "未付金額收集者",
                "reason": "原因",
                "customerPhone": "電話號碼",
                "amountCollected": "收集金額",
                "partialPaymentAmount": "送達時支付部分費用"
            },
            "driverDeposit": {
                "dateAndTime": "日期時間",
                "userName": "用戶名",
                "type": "類型",
                "newAmount": "新餘額",
                "topUpGCashReason": "通過 Gcash 充值",
                "topUpReason": "通過信用卡充值 *{0}",
                "cashWalletReason": "從現金錢包充值",
                "topUpMOLPayReason": "Razer Pay [ {0} ]"
            },
            "refund": {
                "fullRefund": "全額退款",
                "partialRefund": "退還部分款項",
                "tripId": "行程ID",
                "route": "路線",
                "refundAmount": "退款金額",
                "refundTime": "退款時間",
                "paymentStatus": "支付狀態"
            },
            "merchant": {
                "store": "店鋪",
                "name": "姓名",
                "phoneNumber": "電話號碼",
                "bookingId": "訂單#",
                "totalOrders": "訂單總數",
                "completedOrders": "已完成訂單",
                "canceledOrders": "取消訂單",
                "incidentOrders": "事故",
                "revenue": "收入",
                "commission": "傭金",
                "earning": "收入",
                "food": "食物",
                "mart": "市場",
                "preferredMerchant": "首選商戶",
                "regularMerchant": "正規商戶",
                "companyName": "Company"
            },
            "merchantCashTransactionHistory": {
                "companyName": "Company",
                "dateTime": "日期時間",
                "merchantUserName": "商戶用戶",
                "merchantUserPhone": "電話號碼",
                "merchantUserEmail": "電子郵件",
                "merchantName": "商人",
                "transactionType": "類型",
                "bookId": "訂單#",
                "transactionId": "交易ID",
                "operatorName": "操作員",
                "reason": "原因",
                "amount": "數量",
                "newBalance": "新餘額",
                "currencyISO": "貨幣"
            },
            "merchantCreditTransactionHistory": {
                "dateTime": "日期時間",
                "merchantUserName": "商戶用戶",
                "merchantUserPhone": "電話號碼",
                "merchantUserEmail": "電子郵件",
                "merchantName": "商人",
                "transactionType": "類型",
                "bookId": "訂單#",
                "transactionId": "交易ID",
                "operatorName": "操作員",
                "reason": "原因",
                "amount": "數量",
                "newBalance": "新餘額",
                "currencyISO": "貨幣"
            },
            "deleteAccountRequest": {
                "date": "日期",
                "phone": "電話",
                "username": "用戶名",
                "supportId": "支持 ID",
                "requestFrom": "請求來自",
                "associatedApps": "關聯應用程序",
                "reason": "原因"
            },
            "affiliationPayoutHistory": {
                "payoutId": "付款ID",
                "dateTime": "日期時間",
                "payoutType": "類型",
                "accountHolderName": "賬戶持有人名稱",
                "bankName": "銀行名",
                "accountNumber": "帳號",
                "totalPayout": "付款總額（美元）",
                "viewDetails": "查看詳情",
                "notes": "備注",
                "bookId": "訂單#",
                "transactionType": "交易類型",
                "transactionId": "交易ID（自動轉賬）",
                "settlement": "結算",
                "paymentMethod": "類型",
                "networkType": "網絡類型"
            },
            "thirdPartyBooking": {
                "date": "日期",
                "bookingId": "訂單ID",
                "externalId": "外部ID",
                "psgPhone": "乘客電話號碼",
                "psgName": "乘客姓名",
                "pickupLocation": "接人的地方",
                "pickupLocationLatLong": "上車地點緯度/經度",
                "dropoffLocation": "送達地點",
                "dropoffLocationLatLong": "下車地點緯度/經度",
                "pickupTime": "接機時間",
                "numOfPassenger": "乘客人數",
                "action": "操作"
            }
        }
    },
    "zoneSetting": {
        "Add": "添加",
        "Import": "導入",
        "Name": "名稱",
        "Status": "狀態",
        "Active": "啓用",
        "Inactive": "停用",
        "Deactivate": "停用",
        "Activate": "啓用",
        "Delete": "刪除",
        "Cancel": "取消",
        "Input_zone_name": "輸入區域名稱",
        "Save_zone": "保存區",
        "Clear_draw": "清除",
        "Edit": "編輯",
        "Delete_zone_success": "區域已成功刪除",
        "Delete_zone_fail": "刪除區域時發生錯誤",
        "Update_zone_fail": "更新區域時發生錯誤",
        "Update_zone_success": "區域已成功更新",
        "Create_zone_fail": "創建區域時發生錯誤",
        "Create_zone_success": "新區域已成功創建",
        "Draw_zone": "請在地圖上畫出區域",
        "Import_zones_successful": "導入區域成功",
        "Import_zones_fail": "導入區域失敗",
        "DELETE_ZONE": "刪除區域",
        "ZONE_CONFIRM": "您確定要刪除所選區域嗎？",
        "Name_zone_required": "名稱和區域為必填項！",
        "Select_file": "選擇文件",
        "No": "否",
        "Yes": "是",
        "Import_zones_intercity_successful": "[{0}] 區域已成功導入，[{1}] 失敗。",
        "Import_zones_intercity_successful_failed": "[{0}] 區域已成功導入，[{1}] 失敗。",
        "Flat_zone": "固定計價區",
        "Json_file": "Json文件",
        "Import_zones": "導入區域",
        "timezone": "時區"
    },
    "companySetting": {
        "DELETE_COMPANY": "刪除供應商",
        "DELETE_CONFIRM": "您想刪除供應商嗎？",
        "DETAIL_COMPANY": "供應商詳情",
        "commissionValue": "公司傭金",
        "connectStatus": "STRIPE CONNECT",
        "connectStripe": "鏈接",
        "stripeConnectId": "STRIPE帳戶 ID",
        "loginStripe": "儀表板",
        "disconnectStripe": "斷開",
        "Yes": "是",
        "No": "否",
        "Cancel": "取消",
        "EDIT_COMPANY": "編輯供應商",
        "ADD_COMPANY": "添加供應商",
        "Name": "姓名",
        "Required_fields": "必填字段",
        "Save": "保存",
        "Actions": "操作",
        "Edit": "編輯",
        "Add": "添加",
        "Show_per_page": "每頁項目",
        "Page": "頁",
        "show_pages": "顯示了 {0} - {1} 個（共 {2} 個）",
        "Create_company_success": "新供應商創建成功",
        "Create_company_fail": "創建供應商時出現錯誤",
        "Update_company_success": "供應商已更新成功",
        "Update_company_fail": "更新供應商時發生錯誤",
        "Load_company_fail": "加載供應商時發生錯誤",
        "Delete_company_success": "供應商已刪除成功",
        "Delete_company_fail": "刪除供應商時發生錯誤",
        "hideFare": "始終隱藏票價",
        "Broadcast_orders": "Broadcast all orders",
        "paymentMethodsApply": "支持付款方式",
        "emailsNotification": "Emails Notification",
        "CopySignUpLink": "Copy sign up link",
        "operationZone": "Operation Zone",
        "delayBroadcast": "Show order after",
        "releaseTime": "Release Time",
        "advanceBookingVisibility": "Advance booking visibility",
        "advanceBookingVisibilityTooltip": "Set the number of days before the pickup date that a supplier can see the booking. This ensures that bookings are released to drivers in a timely manner, based on each supplier."
    },
    "dispatchSetting": {
        "GENERAL": "一般",
        "Offer_interval": "請求間隔（司機）",
        "Offer_interval_merchant": "請求間隔（商戶）",
        "Offer_interval_merchant_toolTip": "商戶接受訂單的最長時間",
        "Offer_interval_toolTip": "司機接受預訂的最長時間",
        "soundMarketplace": "聲音",
        "sound": "聲音",
        "Auto_re_dispatch_interval_toolTip": "2輪調度之間的休息時間",
        "Maximum_auto_dispatch_duration_toolTip": "向司機發送預訂的最長時間",
        "Seconds": "秒",
        "Auto_re_dispatch_interval": "自動重新調度間隔",
        "Maximum_auto_dispatch_duration": "最大自動調度持續時間",
        "Time_out": "超時",
        "mins": "分鐘",
        "minTimeBeforeReservation": "不允許司機在接載時間之前開始預訂",
        "IN_ADVANCE": "預約",
        "Assign_reservation_to_driver": "將訂單指派給司機",
        "Dispatcher_confirms_job_for_driver": "調度員為司機確認訂單",
        "Dispatcher_confirms_job_for_offline_driver": "調度員為離線司機確認訂單",
        "Broadcast_orders": "Broadcast orders to all companies",
        "Broadcast_orders_tooltip_1": "Enable this setting if you just want to broadcast all trips to all company suppliers. \n Keep in mind you need to enable broadcast mode on in each company’s profile.",
        "Minimum_book_ahead": "最少提前預訂",
        "Maximum_book_ahead": "最多提前預訂 - 單筆訂單",
        "Maximum_period_recurring_oders_fleet": "復發訂單的最長期限（運營商）",
        "Maximum_period_recurring_oders_corp": "復發訂單的最長期限（企業）",
        "Day": "天",
        "Week": "周",
        "Month": "月",
        "hrs": "小時",
        "Driver_cancellation_duration": "司機取消時長",
        "Mins": "分鐘",
        "Maximum_Day": "司機一天可以接受的最大預訂量。",
        "Maximum_Accept_Reservation": "司機一次可以接受的預訂總數。",
        "Local_notification_before": "之前本地通知",
        "Dispatch_mode": "調度方式",
        "Manual": "手動",
        "Immediate_auto_dispatch": "立即自動調度",
        "Delayed_auto_dispatch": "延遲自動調度",
        "Auto_dispatch_before_pickup_time": "接載時間前自動調度",
        "Required_fields": "必填字段",
        "Shuttle": "穿梭",
        "Shuttle_Dispatch_mode": "調度方式",
        "Dispatch_setting_Shuttle_Dispatch_mode_tooltip": "手動模式：一旦創建預訂，預訂將被保存並保留在指揮中心的排程上，處於「待處理」狀態。 在預訂詳細信息中點擊「更新預訂」後，預訂將發送給您指定的司機或在沒有司機分配的情況下發送給所有司機。 它將集成到司機設備日曆中並提供提醒。 <br>- 延遲模式：一旦創建預訂，預訂將被保存並保留在指揮中心的排程上，處於「待處理」狀態。 預訂的車輛將在接載時間的「提前處理」時間值之前自動調度至指定司機，或在沒有司機指定的情況下，自動調度至最近的可用司機。 <br>- 立即自動調度模式：一旦創建預訂，預訂將自動調度給所有司機。 它將集成到司機設備日曆中並提供提醒。",
        "Save": "保存",
        "Process_in_advance": "提前處理",
        "Save_dispatch_success": "調度已成功更新",
        "Save_dispatch_fail": "更新調度時出現錯誤",
        "Dispatch_setting_general_tooltip": "適用於實時訂單，預約訂單將延遲自動調度",
        "Dispatch_setting_Assign_reservation_to_driver_tooltip": "啓用此選項後，將自動為司機接受預約訂單",
        "Dispatch_setting_Driver_cancellation_duration_tooltip": "時長是從接載時間到司機取消預訂的時間",
        "Dispatch_setting_Dispatch_mode_tooltip": "手動模式：一旦創建預訂，訂單將被保存並保留在指揮中心的排程上，處於「待處理」狀態。 在預訂詳細信息中點擊「更新預訂」後，預訂將發送給您指定的司機或在沒有司機分配的情況下發送給所有司機。 它將集成到司機設備日曆中並提供提醒。 <br>- 延遲模式：一旦創建預訂，預訂將被保存並保留在指揮中心的 排程上，處於「待處理」狀態。 預訂的車輛將在接載時間的「提前處理」時間值之前自動調度至指定司機，或在沒有司機指定的情況下，自動調度至最近的可用司機。 <br>- 立即自動調度模式：一旦創建預訂，預訂將自動調度給所有司機。 它將集成到司機設備日曆中並提供提醒。",
        "Notification_nearby_drivers_before_pickup_time": "在接載時間之前通知附近的司機",
        "Dispatch_setting_Maximum_Day_tooltip": "這是為了限制司機在同一天預約過多。 適用於市場和專線。",
        "Maximum_Accept_Reservation_tooltip": "司機接受的訂單數量不能超過此數量。 這限制了司機一次接受太多。 適用於市場和專線。",
        "Notification_nearby_drivers_before_pickup_time_tooltip": "如果客戶的預訂在規定時間之前沒有被任何司機接受，則在接載時間之前，系統將通知附近的司機。 這有望促使他們檢查市場以接受預訂。",
        "marketPlace": "MARKET PLACE",
        "howFarDriverSeeBookingMarketplace": "How far out the driver can see bookings in the marketplace",
        "shortNoticeOrder": "Short notice order",
        "shortNoticeOrderTooltip": "Set the time frame for bookings to be considered urgent and visible to all tiers immediately."
    },
    "voiceSMSSetting": {
        "ACCOUNT_SETTINGS": "帳號設定",
        "Account_SID": "賬戶SID",
        "Auth_Token": "身份驗證令牌",
        "Application_ID": "應用程序ID",
        "SMS_number_1": "短信號碼1",
        "SMS_number_2": "短信號碼2",
        "SMS_SETTINGS": "短信設置",
        "MASKED_PHONE_SETTINGS": "屏蔽電話設置",
        "SMS_MARKETING_SETTINGS": "短信營銷設置",
        "Phone_number": "電話號碼",
        "Save": "保存",
        "voiceSMS": "TWILIO",
        "DRIVER_APP": "司機App",
        "PASSENGER_APP": "乘客App",
        "allowDriverToCallCC": "允許司機通過以下方式呼叫服務台：",
        "allowDriverToCallHelpdesk": "允許司機通過以下方式呼叫服務台：",
        "allowDriverToCallPassenger": "允許司機通過以下方式呼叫乘客：",
        "allowPassengerToCallCC": "允許乘客通過以下方式致電服務台：",
        "allowPassengerToCallHelpdesk": "允許乘客通過以下方式致電服務台：",
        "allowPassengerToCallDriver": "允許乘客通過以下方式呼叫司機：",
        "voip": "VOIP",
        "mobile": "手機",
        "bookingSMS": "留言模板",
        "bookingSMSTemplate": {
            "name": "名稱",
            "content": "內容",
            "action": "操作",
            "DELETE": "刪除消息模板",
            "DELETE_CONFIRM": "您確定要刪除所選的消息模板嗎？",
            "EDIT": "編輯消息模板",
            "ADD": "添加消息模板",
            "DETAIL": "詳細信息模板",
            "Update_success": "消息模板已更新成功",
            "Create_success": "消息模板創建成功",
            "Delete_success": "消息模板已成功刪除",
            "Delete_fail": "刪除消息模板時發生錯誤",
            "Load_fail": "加載消息模板時發生錯誤",
            "Update_fail": "更新消息模板時發生錯誤",
            "Create_fail": "創建消息模板時發生錯誤"
        },
        "bookingSMSModal": {
            "title": "添加短信模板",
            "save": "保存",
            "cancel": "取消",
            "titleMessage": "發信息",
            "template": "模板",
            "phoneNumber": "電話號碼",
            "content": "內容",
            "messageType": "消息類型",
            "inbox": "收件箱",
            "sms": "短信",
            "sendTo": "發給",
            "passenger": "乘客",
            "driver": "司機"
        }
    },
    "regularSetting": {
        "extraWaitTime": "Extra wait time",
        "extraWaitTimeTooltip": "Fee to compensate the driver when he has to wait additional time for the customer till they are onboard.",
        "extraWaitTimeTooltip2": "To set the maximum wait time, go to general settings.",
        "fareWaitTimeLater": "Fare per minute (Reservation)",
        "fareWaitTimeNow": "Fare per minute (Now)",
        "serviceType": "服務類型",
        "transport": "出行約車",
        "delivery": "送貨",
        "Fer_Stop": "停站費",
        "First_Stop": "第一站費用",
        "Each_Stop": "每個額外站點的費用",
        "Name": "姓名",
        "Pay_to_driver_Cash": "支付給司機（現金預訂）",
        "Last_Update": "最後更新",
        "Status": "狀態",
        "Required_fields": "必填字段",
        "Yes": "是",
        "No": "否",
        "Cancel": "取消",
        "Save": "保存",
        "Actions": "操作",
        "Edit": "編輯",
        "Add": "添加",
        "Show_per_page": "每頁項目",
        "Page": "頁",
        "show_pages": "顯示了 {0} - {1} 個（共 {2} 個）",
        "DELETE_CONFIRM": "您想刪除嗎？",
        "ADD_REGULAR_RATE": "添加標準價格包",
        "EDIT_REGULAR_RATE": "編輯標準價格包",
        "DETAIL_REGULAR_RATE": "詳細標準價格包",
        "DELETE_REGULAR_RATE": "刪除標準價格包",
        "Delete_confirm_regular_rate": "您想刪除該項目嗎？",
        "STARTING": "起步",
        "Starting_Now": "起步（實時）",
        "Starting_Reservation": "起步（預約）",
        "FIRST_DISTANCE_FEE": "首程費",
        "First_distance_fee": "第一個距離",
        "From": "從",
        "To": "到",
        "Fee_for_first_distance": "首段距離費用",
        "SECOND_DISTANCE_FEE": "第二距離費",
        "Second_distance_fee": "第二距離",
        "AFTER_SECOND_DISTANCE": "第二個距離後",
        "Fee_after_second_distance": "第二次距離後的費用",
        "PER_MINUTE": "每分鐘",
        "Fee_per_minute": "每分鐘票價",
        "mi": "英里",
        "hour": "小時",
        "MINIMUM": "最低收費",
        "Minimum_Now": "最低（實時）",
        "Minimum_Reservation": "最低（預訂）",
        "PAX_MDISPATCHER_CANCELLATION": "旅客/合作夥伴取消",
        "On_demand": "乘客/合作夥伴取消 - 實時",
        "Penalty": "罰款金額",
        "In_advance": "旅客/合作夥伴取消 - 預約",
        "PAX_MDISPATCHER_NO_SHOW": "乘客/夥伴NOSHOW",
        "Driver_cancel_demand": "司機取消政策 - 實時",
        "Driver_cancel_advance": "司機取消政策 - 預約",
        "Assign_car_type": "指定車輛類型",
        "Select_car_type": "選擇車型",
        "Active": "啓用",
        "Inactive": "停用",
        "Deactivate": "停用",
        "DELETE_FLATE_RATE": "刪除固定計費",
        "Delete_confirm_flat_rate": "您想刪除該項目嗎？",
        "EDIT_FLAT_RATE": "編輯固定計費",
        "ADD_FLAT_RATE": "添加固定計費",
        "DELETE_FLAT_RATE": "刪除固定計費",
        "DELETE_HOURLY_RATE": "刪除小時計費",
        "Delete_confirm_hour_rate": "您想刪除該項目嗎？",
        "EDIT_HOURLY_RATE": "編輯按時計費",
        "ADD_HOURLY_RATE": "添加按時計費",
        "DELETE_FLAT_ROUTE": "刪除固定收費路線",
        "DELETE_ROUTE_CONFIRM": "您確定要刪除路線嗎？",
        "EDIT_ROUTE": "編輯路線",
        "ADD_ROUTE": "添加路線",
        "Route_name": "路線名稱",
        "Route_type": "路線類型",
        "Zone_to_zone": "區域到區域",
        "Zipcode_to_zipcode": "郵政編碼到郵政編碼",
        "First_location": "第一個地點",
        "Second_location": "第二個地點",
        "First_location_zip_note": "*注意：您可以使用「,」符號同時輸入多個郵政編碼。 對於連續的代碼，請使用'->'符號而不是一一輸入（例如：90001 -> 90099, 90101, 90102, 90103, 90174, 90185）",
        "Second_location_zip_note": "*注意：您可以使用「,」符號同時輸入多個郵政編碼。 對於連續的代碼，請使用'->'符號而不是一一輸入（例如：90001 -> 90099, 90101, 90102, 90103, 90174, 90185）",
        "SINGLE_TRIP_SETUP": "單程設置",
        "Route_1": "路線1（第一個地點->第二個地點）",
        "Route_2": "路線2（第二個地點->第一個地點）",
        "Limitation": "限制",
        "Unlimited": "無限",
        "Limited": "有限",
        "Time_coverage": "時間覆蓋範圍",
        "Extra_time": "額外的時間",
        "ROUND_TRIP_SETUP": "往返設置",
        "Round_trip": "往返",
        "ROUTES": "路線",
        "Route_1_Fare": "路線1票價",
        "Route_2_Fare": "路線2票價",
        "Round_Trip_Fare": "往返票價",
        "Select_a_zone": "選擇一個區域",
        "minute": "分鐘",
        "VIEW_REGULAR_RATE": "查看詳細標準價格包",
        "VIEW_FLAT_RATE": "查看固定計費價格包",
        "VIEW_HOURLY_RATE": "查看按時計費價格包",
        "HOURLY_PACKAGE_RATE": "包車價格名字",
        "Package_name": "價格包名字",
        "Package_duration": "套餐時長",
        "PackageId": "Package ID",
        "Base_rate": "基本費率",
        "Distance_coverage": "距離覆蓋",
        "Extra_duration": "額外時長",
        "Extra_distance": "額外距離",
        "DELETE_HOURLY_PACKAGE": "刪除按時計費價格包",
        "DELETE_PACKAGE_CONFIRM": "您想刪除價格包嗎？",
        "EDIT_PACKAGE": "編輯包",
        "VIEW_PACKAGE": "查看價格包詳情",
        "ADD_PACKAGE": "添加包",
        "Fee_per_extra_duration": "每延長一段時間的費用",
        "Fee_per_extra_distance": "每額外距離收費",
        "hours": "小時",
        "days": "天",
        "Update_flat_rate_success": "固定計費價格包已成功更新",
        "Update_flat_rate_fail": "更新固定計費時發生錯誤",
        "Created_flat_rate_success": "固定計費已成功創建",
        "Created_flat_rate_fail": "創建固定計費時發生錯誤",
        "Update_flat_rate_route_success": "固定計費路線已成功更新",
        "Update_flat_rate_route_fail": "更新固定計費路線時發生錯誤",
        "Created_flat_rate_route_success": "固定計費路線已成功創建",
        "Created_flat_rate_route_fail": "創建固定計費路線時發生錯誤",
        "Loading_flat_rate_fail": "加載固定計費詳細信息時發生錯誤",
        "Delete_flat_rate_route_fail": "刪除固定計費路線時發生錯誤",
        "Delete_flat_rate_success": "固定計費已成功刪除",
        "Delete_flat_rate_fail": "刪除固定計費時發生錯誤",
        "Update_intercity_rate_success": "專線價格包已成功更新",
        "Update_intercity_rate_fail": "更新專線價格包時發生錯誤",
        "Created_intercity_rate_success": "專線價格包創建成功",
        "Created_intercity_rate_fail": "創建專線價格包時出現錯誤",
        "Update_intercity_rate_route_success": "專線價格包路線已成功更新",
        "Update_intercity_rate_route_fail": "更新專線價格包路線時發生錯誤",
        "Created_intercity_rate_route_success": "專線價格包路線創建成功",
        "Created_intercity_rate_route_fail": "創建專線價格包路線時發生錯誤",
        "Loading_intercity_rate_fail": "加載專線價格包詳細信息時發生錯誤",
        "Delete_intercity_rate_route_fail": "刪除專線價格包路線時發生錯誤",
        "Delete_intercity_rate_success": "專線價格包已成功刪除",
        "Delete_intercity_rate_fail": "刪除專線價格包時出現錯誤",
        "Update_regular_rate_success": "標準價格包已成功更新",
        "Update_regular_rate_fail": "更新標準價格包時發生錯誤",
        "Create_regular_rate_success": "標準價格包已成功創建",
        "Create_regular_rate_fail": "創建標準價格包時發生錯誤",
        "Update_hourly_rate_success": "小時價格包已成功更新",
        "Update_hourly_rate_fail": "更新每小時價格包時發生錯誤",
        "Create_hourly_rate_success": "小時價格包已成功創建",
        "Create_hourly_rate_fail": "創建小時價格包時發生錯誤",
        "Update_hourly_rate_package_success": "小時價格包套餐已成功更新",
        "Update_hourly_rate_package_fail": "更新每小時價格包包時發生錯誤",
        "Create_hourly_rate_package_success": "小時價格包已成功創建",
        "Create_hourly_rate_package_fail": "創建小時價格包包時發生錯誤",
        "Load_hourly_rate_fail": "加載每小時價格包詳細信息時發生錯誤",
        "Update_hourly_status": "更新每小時價格包狀態時發生錯誤",
        "Delete_hourly_fail": "刪除小時價格包時發生錯誤",
        "Delete_hourly_success": "小時價格包已成功刪除",
        "Loading_regular_rate_fail": "加載標準價格包時發生錯誤",
        "Update_regular_status_fail": "更新標準價格包狀態時發生錯誤",
        "Delete_regular_rate_success": "標準價格包已成功刪除",
        "Delete_regular_rate_fail": "刪除標準價格包時發生錯誤",
        "Please_go_to_Car_Type_settings": "要將此費率應用於任何車型，請轉至「設置」>「車輛」>「車型」。",
        "Car_type_filter": "車型過濾器",
        "Delete_package_error": "刪除包時出錯！",
        "Pax_mDispatcher_cancellation_tooltip": "適用於乘客的取消政策",
        "Penalty_tooltip": "將向客戶收取的金額。 除了罰款之外，您還可以添加稅費或技術費。",
        "Pax_mDispatcher_no_show_tooltip": "適用於乘客的誤機政策。 如果司機在上車地點沒有看到乘客時點擊「NOSHOW」，則將收取此費用。",
        "detail_route": "線路詳情",
        "detail_package": "詳細套餐",
        "THIRD_DISTANCE_FEE": "第三距離費",
        "AFTER_DISTANCE_FEE": "第三次距離費後",
        "Loading_delivery_rate_fail": "加載送貨計費詳細信息時發生錯誤",
        "Update_delivery_rate_fail": "更新送貨計費時發生錯誤",
        "Update_delivery_rate_success": "送貨計費已成功更新",
        "Created_delivery_rate_success": "送貨計費已成功創建",
        "Created_delivery_rate_fail": "創建送貨計費時發生錯誤",
        "Starting_fee": "起始費",
        "First_distance": "第一個距離",
        "Second_distance": "第二距離",
        "Third_distance": "第三距離",
        "Fee_for_second_distance": "第二距離費用",
        "Fee_for_third_distance": "第三距離費用",
        "Fee_after_third_distance": "第三次距離後的費用",
        "Assign_rate_to_car_type": "為車型指定費率",
        "car_type": "車型",
        "PRICE_ADJUSTABLE": "價格可調",
        "minimum_Percent_Adjust": "預計票價的最低百分比",
        "maximum_Percent_Adjust": "預計票價的最大百分比",
        "Amount_Step": "價格可調",
        "Tax": "稅",
        "TechFee": "技術費",
        "Pay_to_driver": "支付給司機",
        "Pay_to_driver_tooltip": "補償司機的選項：\n  - 固定金額：司機將收到的固定金額。\n  - 傭金：司機設定的傭金適用於罰款金額。",
        "Fix_amount": "固定值",
        "Commission": "傭金",
        "fareFlat": "固定計費",
        "flatRoute": "固定計費線路",
        "Mode": "Mode",
        "speedThresholdKM": "Speed threshold (km/h)",
        "speedThresholdMI": "Speed threshold (mi/h)",
        "timeAndMileage": "Time and mileage",
        "timeOrMileage": "Time or mileage",
        "perDistanceKM": "Per kilometer",
        "perDistanceMI": "Per mile",
        "perTime": "Per minute"
    },
    "flatRateSettings": {
        "flatZoneNote": "您在此處創建的區域僅用於設置您的固定計費。"
    },
    "deliverySetting": {
        "Name": "姓名",
        "Last_Update": "最後更新",
        "Status": "狀態",
        "Actions": "操作"
    },
    "sharingSetting": {
        "EDIT_RATE": "編輯街頭拼車計費",
        "ADD_RATE": "添加街頭拼車計費",
        "VIEW_RATE": "查看街頭拼車計費",
        "Name": "姓名",
        "startingFee": "起步價",
        "minimumFee": "最低票價",
        "Update_streetSharing_rate_fail": "更新街頭拼車計費時發生錯誤",
        "Update_streetSharing_rate_success": "街頭拼車費率已成功更新",
        "Created_streetSharing_rate_success": "街頭拼車計費創建成功",
        "Created_streetSharing_rate_fail": "創建街頭拼車計費時發生錯誤",
        "Loading_streetSharing_rate_fail": "加載街頭拼車計費詳細信息時發生錯誤",
        "Delete_streetSharing_rate_success": "街頭拼車計費已成功刪除",
        "Delete_streetSharing_rate_fail": "刪除街頭拼車計費時發生錯誤",
        "Delete_streetSharing_rate": "刪除街頭拼車計費",
        "Assign_Car": "指定車輛類型"
    },
    "carTypeSetting": {
        "newService": "New Service",
        "editService": "Edit Service",
        "serviceInfo": "Service Info",
        "name": "Name",
        "namePH": "Car-type name",
        "enterNumber": "Enter a number",
        "appDisplayName": "App display name",
        "shortDescription": "Short description",
        "maxPassengers": "Max passengers",
        "maxLuggage": "Max luggages",
        "vehiclePhoto": "Vehicle Photos",
        "iconShowList": "Icon to show in list",
        "imageShowWeb": "Image to show on web",
        "carPinOnMap": "Car PIN on map",
        "recommendedSize": "Recommended size:",
        "serviceSetting": "Service Settings",
        "serviceType": "Service type",
        "reservation": "Reservation",
        "onDemand": "On-demand",
        "destinationSetting": "Destination setting",
        "requireDestination": "Require destination",
        "extraDestination": "Extra destination",
        "calculatorFareMode": "Calculate fare mode",
        "hideDestinationOnDemand": "On-demand",
        "meteredFare": "Metered fare",
        "quotedFare": "Quoted fare",
        "availableZones": "Available Zones",
        "dispatchAssociation": "Dispatch Association",
        "Save": "Save",
        "Edit": "Edit",
        "Cancel": "Cancel",
        "validateName": "Please input name",
        "validateAppDisplayName": "Please input app display name",
        "validateSelectImage": "Please select an image",
        "updateSuccess": "Update successfully",
        "createSuccess": "Create successfully",
        "failApi": "Something went wrong!",
        "validateRequire": "Please specify passenger count and double-check luggage."
    },
    "carSetting": {
        "Details": "Details",
        "vehicleInfo": "Vehicle Info",
        "Documents": "Documents",
        "vehicle": "Vehicle",
        "addVehicle": "Add Vehicle",
        "detailVehicle": "Vehicle Details",
        "additionalVehicleTitle": "Additional Vehicle Information",
        "Phone_Number_Tooltip": "Enter a vehicle-specific phone number for customer use. If not provided, calls will default to the driver’s personal number. Ideal for suppliers who equip vehicles with dedicated contact phones.",
        "discardChange": "Discard changes",
        "model_name_is_required": "型號名稱為必填項",
        "DELETE_CAR": "刪除車輛",
        "DELETE_CONFIRM": "您想刪除選定的車輛嗎？",
        "Yes": "是",
        "Cancel": "取消",
        "EDIT_CAR": "編輯車輛",
        "CREATE_CAR": "創造車輛",
        "Plate_Number": "車牌號碼",
        "Car_Type": "車型",
        "Select_car_type": "選擇車型",
        "Make": "製作",
        "Select_car_make": "選擇車輛品牌",
        "Model": "模型",
        "Select_a_car_model": "選擇車型",
        "Year": "年",
        "Select_year": "選擇年份",
        "Hardware_Meter": "硬件儀表",
        "VIN": "車輛識別碼 (VIN)",
        "Company": "供應商",
        "Select_company": "選擇供應商",
        "Max_Passengers": "最大載客量",
        "Max_Luggage": "最大行李數",
        "Color": "顏色",
        "License_expiry": "許可證到期",
        "Required_fields": "必填字段",
        "Save": "保存",
        "Add": "添加",
        "Car_Model": "車款",
        "Created_Date": "創建日期",
        "Status": "狀態",
        "Actions": "操作",
        "Show_per_page": "每頁項目",
        "Page": "頁",
        "show_pages": "顯示了 {0} - {1} 個（共 {2} 個）",
        "Active": "啓用",
        "activeVehicle": "Active vehicle",
        "Inactive": "停用",
        "Edit": "編輯",
        "Deactivate": "停用",
        "Activate": "啓用",
        "Delete": "刪除",
        "Name": "姓名",
        "MODEL_LIST": "型號列表",
        "Model_Name": "型號名稱",
        "Add_Model": "添加型號",
        "DELETE_CAR_MODEL": "刪除車型",
        "DELETE_CONFIRM_MODEL": "您想刪除車型嗎？",
        "DELETE_CAR_MAKE": "刪除車輛品牌",
        "DELETE_CONFIRM_CAR_MAKE": "您想刪除該車輛品牌嗎？",
        "EDIT_CAR_MAKE": "編輯車輛品牌",
        "CREATE_CAR_MAKE": "添加車輛品牌",
        "Assign_Rate": "分配率",
        "ASSIGN_RATE": "分配率",
        "Car_type_ID": "車型ID",
        "App_Display_Name": "應用程序顯示名稱",
        "Affiliate_car_type": "附屬車型",
        "Regular_rate": "標準計費",
        "Zone": "區域",
        "Rate": "價格包",
        "Zone_Rate": "區域 - 費率",
        "Flat_Fare": "固定計費",
        "Flat_Delivery": "送貨固定收費",
        "Select_flat_rate": "選擇固定計費",
        "Hourly_daily_rate": "按時計費",
        "Dispatch_To": "調度到",
        "Normal_Fare": "標準計費",
        "Upload_an_image": "上傳圖片",
        "DETAIL_CAR": "車輛細節",
        "DELETE_MULTI_CAR": "刪除選定的車輛",
        "DELETE_MULTI_CAR_CONFIRM": "是否要刪除這些車輛？只有處於停用狀態的車輛才能刪除嗎？",
        "DETAIL_CAR_MAKE": "車輛製造商",
        "Car_Make": "車輛製造商",
        "Create_car_success": "新車已創建成功",
        "Update_car_success": "該車已更新成功。",
        "Update_car_fail": "更新車輛時出現錯誤",
        "Create_car_fail": "創建車輛時發生錯誤",
        "Load_car_fail": "裝車時發生錯誤",
        "Delete_car_fail": "刪除車輛時發生錯誤",
        "Delete_car_success": "車輛已成功刪除",
        "Change_cars_status_success": "所有車輛狀態已更改成功",
        "Change_cars_status_fail": "更改車輛狀態時發生錯誤",
        "Delete_cars_success": "所有選定的車輛已成功刪除",
        "Delete_cars_fail": "刪除車輛時發生錯誤",
        "Update_car_type_success": "車型已成功更新",
        "Update_car_type_fail": "更新車型時發生錯誤",
        "Load_car_type_details_fail": "無法加載車型詳細信息",
        "Update_car_make_fail": "更新車輛品牌時發生錯誤",
        "Update_car_make_success": "車輛品牌已成功更新",
        "Create_car_make_fail": "創建車輛品牌時發生錯誤",
        "Create_car_make_success": "新車輛品牌已成功創建",
        "Update_car_model_fail": "更新車型時出現錯誤",
        "Update_car_model_success": "車型已更新成功",
        "Create_car_model_fail": "創建車款時出現錯誤",
        "Create_car_model_success": "新車型已成功創建",
        "Loading_car_model_fail": "加載車款時發生錯誤",
        "Delete_car_make_success": "車輛品牌已成功刪除",
        "Delete_cat_make_fail": "刪除車輛品牌時發生錯誤",
        "Delete_regular_rate_success": "刪除標準價格包成功",
        "Delete_regular_rate_fail": "刪除標準價格包錯誤",
        "Update_status_error": "更新狀態錯誤",
        "Case_number": "案件編號",
        "Phone_Number": "電話號碼",
        "ERROR_INPUT_VALIDPHONE": "請輸入一個有效的電話號碼。",
        "Car_type_filter": "車用濾清器",
        "No": "否",
        "Intercity_Rate": "專線價格",
        "Select_intercity_rate": "選擇專線價格包",
        "Delivery_Rate": "送貨計費",
        "Select_delivery_rate": "選擇送貨價格包",
        "vehicleOwner": "車主姓名",
        "EDIT_CAR_COLOR": "編輯車輛顏色",
        "DETAIL_CAR_COLOR": "車輛顏色細節",
        "CREATE_CAR_COLOR": "添加車輛顏色",
        "Car_Color": "車輛顏色",
        "Update_car_color_fail": "更新車輛顏色時發生錯誤。",
        "Update_car_color_success": "車輛顏色已成功更新。",
        "Create_car_color_fail": "創建車輛顏色時發生錯誤。",
        "Create_car_color_success": "新車顏色已創建成功",
        "Delete_car_color_success": "車輛顏色已成功刪除。",
        "Delete_car_color_fail": "刪除車輛顏色時發生錯誤。",
        "DELETE_CAR_COLOR": "刪除車輛顏色",
        "DELETE_CONFIRM_CAR_COLOR": "您想刪除此車輛顏色嗎？",
        "sharing_Rate": "街頭拼車計費",
        "bookingAffiliate": "Booking.com 聯盟車型",
        "streetSharing": "街頭拼車"
    },
    "operationSetting": {
        "Driver": "司機",
        "Search_driver_name": "搜索司機名稱",
        "Company": "供應商",
        "License_plate": "牌照",
        "Select_license_plate": "选择车牌",
        "Shift": "排班",
        "Zone": "區",
        "Required_fields": "必填字段",
        "Save": "保存",
        "No": "不。",
        "Car_Type": "車型",
        "Plate_Number": "車牌號碼",
        "Unassign": "取消分配",
        "Please_choose_driver": "請選擇司機。",
        "Please_choose_company": "請選擇供應商。",
        "Please_license_plate": "請選擇車牌。",
        "Please_choose_shift": "請選擇班次。",
        "Please_choose_zone": "請選擇區域。",
        "CONFIRM_UNASSIGN": "您想取消分配該司機嗎？",
        "Assign_success": "運營已成功套用",
        "Unassign_success": "司機已成功取消套用。"
    },
    "paymentMethod": {
        "cash": "現金",
        "easyPaisa": "Easy Paisa",
        "jazzCash": "Jazz Cash",
        "cashBySender": "發件人付現",
        "cashByRecipient": "收件人付現",
        "personalCard": "個人卡",
        "corporateCard": "企業卡",
        "qrCodePayment": "二維碼支付",
        "directBilling": "月結",
        "externalCard": "外置卡",
        "mDispatcherCard": "合作夥伴卡",
        "corporatePrepaid": "企業預付費",
        "fleetCard": "外置卡",
        "applePay": "Apple Pay",
        "creditExternalCard": "信用卡",
        "googlePay": "Google Pay",
        "wallet": "錢包",
        "BankOfGeorgia": "Georgia銀行",
        "tnGeWalletlet": "TnG電子錢包",
        "paymentMethod_1": "現金",
        "paymentMethod_2": "個人卡",
        "paymentMethod_3": "合作夥伴卡",
        "paymentMethod_4": "企業卡",
        "paymentMethod_5": "月結",
        "paymentMethod_6": "外置卡",
        "paymentMethod_7": "企業預付費",
        "paymentMethod_8": "二維碼支付",
        "paymentMethod_9": "Apple Pay",
        "paymentMethod_11": "航空支付",
        "paymentMethod_12": "信用卡",
        "paymentMethod_13": "錢包",
        "paymentMethod_14": "TnG電子錢包",
        "paymentMethod_16": "發件人付現",
        "paymentMethod_17": "收件人付現",
        "paymentMethod_19": "Boost電子錢包",
        "paymentMethod_20": "VIPPS",
        "paymentMethod_21": "ZainCash",
        "paymentMethod_23": "Google Pay",
        "paymentMethod_25": "Easy Paisa",
        "paymentMethod_26": "Jazz Cash",
        "vippseWallet": "VIPPS",
        "paymentMethod_credit": "個人卡",
        "paymentMethod_razerPay": "Razerpay",
        "paymentMethod_gCash": "GC現金",
        "paymentMethod_zainCash": "ZainCash",
        "paymentMethod_xenDit": "Xendit",
        "paymentMethod_FIB": "FIB",
        "paymentMethod_SantimPay": "SantimPay",
        "paymentMethod_ksher": "Ksher",
        "paymentMethod_paydunya": "PayDunya",
        "paymentMethod_fawry": "Fawry",
        "paymentMethod_BankOfGeorgia": "Bank Of Georgia",
        "paymentLink": "付款鏈接",
        "paymentMethod_xendit": "Xendit",
        "paymentMethod_easyPaisa": "Easy Paisa",
        "paymentMethod_jazzCash": "Jazz Cash",
        "paymentMethod_flooz": "Flooz",
        "paymentMethod_tmoney": "Tmoney",
        "paymentMethod_corporateCard": "Corporate card"
    },
    "changePassword": {
        "Change_password": "更改密碼",
        "Current_password": "當前密碼",
        "New_password": "新密碼",
        "Confirm_password": "確認密碼",
        "ERROR_INPUT_PASSWORD": "請輸入密碼",
        "ERROR_INPUT_VALID_PASSWORD": "密碼必須包含至少 8 個字符、1 個數字和 1 個特殊字符",
        "ERROR_INPUT_MATCH_PASSWORD": "密碼不匹配。 請再試一次",
        "Save": "保存",
        "Cancel": "取消"
    },
    "userProfile": {
        "My_account": "我的賬戶",
        "Username": "用戶名",
        "First_name": "姓氏",
        "Last_name": "名字",
        "Phone_number": "電話號碼",
        "Email_address": "電子郵件地址",
        "Address": "地址",
        "Save": "保存",
        "Edit_profile": "編輯個人資料",
        "Change_password": "更改密碼",
        "ERROR_INPUT_USERNAME": "請輸入用戶名。",
        "ERROR_INPUT_FIRSTNAME": "請輸入姓名。",
        "ERROR_INPUT_VALID_EMAIL": "請輸入有效的電子郵件。",
        "Required_fields": "必填字段"
    },
    "General": {
        "searchQueuingDriver": "搜索：司機姓名、電話號碼",
        "search": "搜索",
        "User": "用戶",
        "My_Account": "我的賬戶",
        "Sign_out": "登出",
        "My_site": "我的網站",
        "Command_center": "控制中心",
        "Notifications": "通知",
        "Show_all": "顯示所有",
        "SOS_Notifications": "求救通知",
        "You_dont_have_any_message": "您還沒有任何留言",
        "Mon": "週一",
        "Tue": "週二",
        "Wed": "週三",
        "Thu": "週四",
        "Fri": "週五",
        "Sat": "週六",
        "Sun": "周日",
        "Now": "現在",
        "NowComplete": "Now (Acttual Pickup Time)",
        "hrs": "小時",
        "patmentMethod_1": "現金",
        "patmentMethod_2": "個人卡",
        "patmentMethod_3": "合作夥伴卡",
        "patmentMethod_4": "公司卡",
        "patmentMethod_5": "月結",
        "patmentMethod_6": "外置卡",
        "patmentMethod_7": "企業預付",
        "patmentMethod_8": "二維碼",
        "patmentMethod_9": "Apple Pay",
        "patmentMethod_10": "Apple Pay",
        "patmentMethod_12": "信用卡",
        "patmentMethod_13": "錢包",
        "patmentMethod_14": "TnG電子錢包",
        "patmentMethod_16": "發件人付現",
        "patmentMethod_17": "收件人付現",
        "patmentMethod_19": "BOOST",
        "patmentMethod_20": "VIPPS",
        "patmentMethod_23": "谷歌遊戲",
        "patmentMethod_24": "支付鏈接",
        "patmentMethod_25": "Easy Paisa",
        "patmentMethod_26": "Jazz Cash",
        "patmentMethod_ZainCash": "ZainCash",
        "patmentMethod_Xendit": "Xendit",
        "patmentMethod_FIB": "FIB",
        "patmentMethod_SantimPay": "SantimPay",
        "patmentMethod_GCash": "GCASH",
        "patmentMethod_cash": "現金",
        "patmentMethod_credit": "個人卡",
        "patmentMethod_mDispatcher": "合作夥伴卡",
        "patmentMethod_corpCredit": "公司卡",
        "patmentMethod_directBilling": "月結",
        "patmentMethod_fleetCard": "外置卡",
        "patmentMethod_prepaid": "企業預付",
        "patmentMethod_QRCode": "二維碼",
        "patmentMethod_applePay": "Apple Pay",
        "patmentMethod_easyPaisa": "Easy Paisa",
        "patmentMethod_jazzCash": "Jazz Cash",
        "patmentMethod_prePaid": "Corporate Prepaid",
        "patmentMethod_GooglePay": "Google Pay",
        "patmentMethod_creditCardExternal": "信用卡",
        "patmentMethod_PaxWallet": "錢包",
        "patmentMethod_TnGeWallet": "TnG電子錢包",
        "patmentMethod_boosteWallet": "BOOST",
        "patmentMethod_Vipps": "VIPPS",
        "patmentMethod_exZainCash": "ZainCash",
        "patmentMethod_exGCash": "GCASH",
        "patmentMethod_exPayTM": "PayTM",
        "patmentMethod_exFlooz": "Flooz",
        "patmentMethod_exTmoney": "Tmoney",
        "patmentMethod_exTelebirr": "GCash",
        "paymentMethod_exzainCash": "ZainCash",
        "paymentMethod_exXendit": "Xendit",
        "paymentMethod_exFIB": "FIB",
        "paymentMethod_exSantimPay": "SantimPay",
        "patmentMethod_exKsher": "Ksher",
        "paymentMethod_paydunya": "PayDunya",
        "paymentMethod_exfawry": "Fawry",
        "patmentMethod_exBankOfGeorgia": "Bank Of Georgia",
        "patmentMethod_exPayDunya": "PayDunya",
        "patmentMethod_21": "ZainCash",
        "patmentMethod_paymentLink": "支付鏈接",
        "patmentMethod_card": "個人卡",
        "patmentMethod_corporateCard": "企業卡",
        "patmentMethod_paxWallet": "Wallet",
        "patmentMethod_externalWallet": "External Wallet",
        "patmentMethod_googlePay": "Google Pay",
        "patmentAirlineMethod_5": "月結",
        "patmentAirlineMethod_4": "航空卡",
        "patmentAirlineMethod_7": "航空公司預付",
        "Name": "姓名",
        "Delete": "刪除",
        "App_SOS_dialog_form_message_1": "{0}緊急求救！",
        "App_SOS_dialog_form_message_2": "我需要幫助",
        "App_SOS_dialog_form_message_3": "預訂：{0}，司機：{1}，乘客：{2}，車牌：{3} ({4})",
        "mins": "分鐘",
        "Credit_Card": "信用卡",
        "ALL": "全部",
        "NotFound": "您請求的頁面無法找到",
        "ServerError": "出了些問題",
        "AccessDenied": "您沒有訪問此模塊的權限！",
        "Retry": "重試",
        "Can_not_get_data": "無法獲取數據"
    },
    "shiftSettings": {
        "Add": "添加",
        "Show_per_page": "每頁項目",
        "Page": "頁",
        "Name": "姓名",
        "Time_Range": "時間範圍",
        "Actions": "操作",
        "show_pages": "顯示了 {0} - {1} 個（共 {2} 個）",
        "Edit": "編輯",
        "Delete": "刪除",
        "EDIT_SHIFT_SETTING": "編輯班次設置",
        "ADD_SHIFT_SETTING": "添加班次設置",
        "DETAIL_SHIFT_SETTING": "詳細班次設置",
        "DELETE_SHIFT_SETTING": "刪除班次設置",
        "Delete_confirm_shift_setting": "您想刪除該項目嗎？",
        "Required_fields": "必填字段",
        "Save": "保存",
        "Cancel": "取消",
        "From": "從",
        "To": "到",
        "Edit_shift_template": "編輯排班模板",
        "Add_shift_template": "添加排班模板",
        "Detail_shift_template": "輪班模板詳細",
        "Delete_shift_template": "刪除輪班模板",
        "Delete_confirm_shift_template": "你想刪除這個項目嗎？",
        "Working_time": "工作時間",
        "Create_shift_settings_success": "新排班設置已成功創建",
        "Create_shift_settings_fail": "創建排班設置時發生錯誤",
        "Update_shift_settings_success": "排班設置已成功更新",
        "Update_shift_settings_fail": "更新排班設置時發生錯誤",
        "Delete_shift_settings_success": "排班設置已成功刪除",
        "Delete_shift_settings_fail": "刪除排班設置時發生錯誤",
        "Create_shift_template_success": "新的排班模板已成功創建",
        "Create_shift_template_fail": "創建輪班模板時發生錯誤",
        "Update_shift_template_success": "排班模板已成功更新",
        "Update_shift_template_fail": "更新排班模板時發生錯誤",
        "Delete_shift_template_success": "排班模板已成功刪除",
        "Delete_shift_template_fail": "刪除排班模板時發生錯誤",
        "Yes": "是",
        "No": "否"
    },
    "promotionSettings": {
        "Detail_campaign": "促銷活動詳情",
        "Edit_campaign": "編輯推廣活動",
        "Add_campaign": "添加推廣活動",
        "Delete_campaign": "刪除推廣活動",
        "Delete_confirm_campaign": "您是否要刪除選定的推廣活動？",
        "Add": "添加",
        "Corporate_list": "企業名單",
        "permittedCorporate": "獲許可企業名單",
        "CorporateCustomers": "企業客戶",
        "auto_add_corporate": "自動添加到企業用戶的促銷列表",
        "Show_per_page": "每頁項目",
        "Page": "頁",
        "Name": "姓名",
        "Actions": "操作",
        "Private": "私人的",
        "show_pages": "顯示了 {0} - {1} 個（共 {2} 個）",
        "Edit": "編輯",
        "Delete": "刪除",
        "Send_to_inbox": "發送至收件箱",
        "Save": "保存",
        "Duplicate": "複製",
        "Cancel": "取消",
        "Required_fields": "必填字段",
        "Promotion_code": "促銷代碼",
        "Promotion_type": "促銷類型",
        "Campaign": "活動",
        "Type": "類型",
        "Value": "價值",
        "keepMinFee": "促銷後的總票價必須大於最低票價",
        "Zone": "區域",
        "paymentMethodsApply": "支付方式",
        "schedules": "時間表",
        "minimumEstFareApply": "應用代碼的最低預估總票價",
        "quantityLimitPerYear": "最大使用次數/用戶/年",
        "termAndCondition": "條款和條件",
        "Valid_from": "有效期自",
        "Valid_to": "有效",
        "Total_uses_user": "總使用量/用戶",
        "Released_quantity": "發佈數量",
        "Number_of_sending_inbox": "發送收件箱數量",
        "Status": "狀態",
        "Activate": "啓用",
        "Deactivate": "停用",
        "Add_promotion_code": "添加促銷代碼",
        "Edit_promotion_code": "編輯促銷代碼",
        "Detail_promotion_code": "詳細促銷代碼",
        "Delete_promotion_code": "刪除促銷代碼",
        "Delete_confirm_promotion_code": "您想刪除選定的促銷代碼嗎？",
        "New_customer": "新客戶",
        "Budget_per_user": "預算/用戶（整個促銷期間）",
        "Budget_per_user_day": "預算/用戶/天",
        "Budget_per_user_month": "預算/用戶/月",
        "Budget_per_user_year": "預算/用戶/年",
        "Unlimited": "無限",
        "Limited": "有限",
        "Total_uses_per_user": "每個用戶的最大使用次數（整個促銷期間）",
        "Send_an_in_box_automatically_to_new_customers": "自動發送到新客戶App收件箱",
        "Notes": "備注",
        "Public": "民眾",
        "Create_campaign_success": "新推廣活動已成功創建",
        "Create_campaign_fail": "創建推廣活動時發生錯誤",
        "Update_campaign_success": "推廣活動已成功更新",
        "Update_campaign_fail": "更新推廣活動時發生錯誤",
        "Delete_campaign_success": "促銷活動已成功刪除",
        "Delete_campaign_fail": "刪除促銷活動時發生錯誤",
        "Loading_campaign_fail": "加載推廣活動時發生錯誤",
        "Update_campaign_status_success": "推廣活動狀態已成功更新",
        "Update_campaign_status_fail": "更新推廣活動狀態時發生錯誤",
        "Loading_promotion_code_fail": "加載促銷代碼時發生錯誤",
        "Select_campaign": "選擇促銷活動",
        "Create_promotion_code_success": "新的促銷代碼已成功創建",
        "Create_promotion_code_fail": "創建促銷代碼時發生錯誤",
        "Update_promotion_code_status_success": "促銷代碼狀態已成功更新",
        "Update_promotion_code_status_fail": "更新促銷代碼狀態時發生錯誤",
        "Delete_promotion_code_confirmation": "你想刪除這些促銷代碼嗎？",
        "Just_delete_inactive": "** 只能刪除處於停用狀態的促銷代碼。",
        "Send_inbox_confirmation": "您確定要將此促銷代碼發送到乘客收件箱嗎？",
        "Send_inbox_success": "促銷代碼已成功發送。",
        "Send_inbox_fail": "促銷代碼發送失敗。 請再試一次！",
        "Confirmation": "確認",
        "Delete_promotion_codes_success": "促銷代碼已成功刪除",
        "Delete_promotion_codes_fail": "刪除促銷代碼時發生錯誤",
        "Delete_multiple_promotion_codes_success": "促銷代碼已成功刪除",
        "Delete_multiple_promotion_codes_fail": "刪除促銷代碼時發生錯誤",
        "Delete_promotion_code_success": "促銷代碼已成功刪除",
        "Delete_promotion_code_fail": "刪除促銷代碼時發生錯誤",
        "Update_multiple_promotion_code_status_success": "促銷代碼狀態已成功更新",
        "Update_multiple_promotion_code_status_fail": "刪除促銷代碼狀態時發生錯誤",
        "Search_here": "在這裡搜索",
        "Please_input_campaign_name": "請輸入推廣活動名稱",
        "Please_input_promotion_code": "請輸入促銷代碼",
        "Please_input_promotion_code_at_least_4_characters": "請輸入至少 4 個字符。",
        "Please_select_campaign": "請選擇推廣活動",
        "Please_input_value": "請輸入值",
        "Please_select_date": "請選擇日期",
        "Please_add_schedules": "請添加時間表",
        "Please_add_corporate": "請添加企業",
        "Please_choose_at_least_one_customer": "請至少選擇一位客戶。",
        "This_campaign_name_has_been_existing_on_the_system_Please_input_another_one": "此推廣活動名稱已存在於系統中。請輸入另一個。",
        "This_promotion_code_has_been_existing_on_the_system_Please_input_another_one": "該促銷代碼已存在於系統中。 請輸入另一項。",
        "Invalid_file": "文件內容無效",
        "File_size_larger_than_5Mb": "文件大小大於 5Mb",
        "Import_promotions_success": "文件已成功導入",
        "Import_Promotions": "導入文件",
        "Number_successful_imports": "導入商品",
        "Number_skipped": "跳過項目",
        "File": "文件",
        "Phone_Numbers": "電話號碼",
        "Import_Promotion_code_success": "促銷代碼 <b>{0}</b> 已成功導入",
        "Import_Promotion_code_fail": "促銷代碼<b>{0}</b>導入失敗。 請再試一次",
        "Customer_type": "客戶類型",
        "All": "全部",
        "Referred_customers": "推薦客戶",
        "Common_customers": "普通客戶",
        "Operation_zone": "運營區域",
        "totalUsesLimitPerDay": "最大使用次數/用戶/天",
        "quantityLimitPerDay": "最大數量/天",
        "quantityLimitPerMonth": "最大數量/用戶/月",
        "applyVerifiedCustomers": "僅限經過驗證的馬來西亞客戶申請",
        "applyWhenBudgetLimit": "用戶可以應用代碼直到預算限制結束",
        "applyVerifiedCustomersTooltip": "此促銷活動僅適用於已驗證國民身份證並將身份證上傳到系統的馬來西亞人。",
        "valueLimitPerUse": "最大價值/使用（適用於應用程序v48及以上）",
        "Promotion_Services": "服務",
        "transport": "出行約車",
        "shuttle": "穿梭",
        "parcel": "包裹",
        "food": "送餐",
        "mart": "市場",
        "intercity": "專線",
        "addSchedule": "添加時間表",
        "editSchedule": "編輯日程",
        "date": "日期",
        "Time_range": "時間範圍",
        "startTime": "開始時間",
        "endTime": "時間結束",
        "applyForOtherDays": "申請其他日期",
        "scheduleTimeOverlap": "計劃時間不得相互重疊",
        "schedule_startDate_greater_than_endDate": "開始日期大於結束日期",
        "scheduleTimeDuplicate": "安排時間不得重復",
        "promoIcon": "促銷圖標",
        "bannerImage": "橫幅圖片",
        "Upload_an_image": "上傳圖片",
        "Click_here_to_view": "點擊此處查看。"
    },
    "quest_event": {
        "name": "姓名",
        "userType": "用戶類型",
        "carType": "車型",
        "operationZones": "運營區域",
        "type": "事件類型",
        "isLuckyDraw": "幸運抽獎輪",
        "start_date": "開始日期",
        "end_date": "結束日期",
        "numOfQualify": "合格人數",
        "event_progress": "活動進展",
        "isActive": "狀態",
        "Actions": "操作",
        "passenger": "用戶",
        "driver": "司機",
        "first_will_win": "先者獲勝",
        "largest_will_win": "最大者獲勝（排行榜）",
        "not_start": "未啓動",
        "active": "啓用",
        "closed": "關閉",
        "event_details": "任務詳情",
        "add_event": "添加任務",
        "limited": "有限的",
        "unlimited": "無限",
        "eventImage": "活動圖片",
        "sharedImage": "共享圖像",
        "qualifiedImage": "合格形象",
        "expiredImage": "過期圖片",
        "luckyDrawImage": "抽獎圖片",
        "Upload_an_image": "上傳圖片",
        "Required_Image": "圖像擴展名（.jpg、.png、.gif）",
        "ok": "好的",
        "eventId": "事件ID",
        "passengerMetric": {
            "booking_distance": "預訂距離",
            "booking_time": "預訂時間",
            "booking_amount_spent": "預訂花費金額",
            "num_of_ondemand": "實時數量",
            "num_of_reservation": "預約數量",
            "num_of_intercity": "專線班次",
            "num_of_delivery": "發貨數量",
            "num_of_referral": "推薦數量",
            "num_of_sharing": "分享數量",
            "topup_wallet_amount": "充值錢包金額",
            "num_of_complete_wallet": "完整訂單數量（錢包）",
            "num_of_complete_booking_by_referee": "完整訂單數量（由被推薦人提供）",
            "number_of_complete_booking": "完整訂單數量",
            "number_of_food_mart": "食品數量/集市"
        },
        "driverMetric": {
            "booking_distance": "工作距離",
            "booking_time": "工作時間",
            "num_of_referral": "推薦數量",
            "num_of_complete": "完整訂單數量（唯一客戶）",
            "num_of_referral_driver": "推薦人數（司機）",
            "num_of_accept": "接受預訂人數",
            "num_of_receive": "接收訂單數量",
            "num_of_cancel": "司機取消預訂次數",
            "sum_of_rating": "評級星級總數",
            "total_rating": "總評分次數",
            "earnings_amount": "盈利金額",
            "num_of_complete_wallet": "完整訂單數量（錢包）",
            "num_of_complete_booking_by_referee": "完整訂單數量（由被推薦人提供）",
            "available_time": "在線時間"
        },
        "Click_here_to_view": "點擊此處查看。",
        "view": "點擊此處查看。",
        "metricType": "類型",
        "task": "任務",
        "value": "價值",
        "quantity": "數量",
        "quests_list": "任務",
        "rankImages": "排名圖像（點）",
        "qualifiedPrizeOrder": "合格獎品",
        "luckyDrawPrizeOrder": "幸運抽獎獎品",
        "reward": "物品",
        "from": "從",
        "to": "到",
        "image": "圖像",
        "Add_Prize": "添加",
        "bannerText": "橫幅文字",
        "buttonText": "按鈕文字",
        "bannerLink": "橫幅鏈接",
        "shortLink": "短鏈接",
        "buttonLinkDesktop": "按鈕鏈接（桌面）",
        "buttonLinkAndroid": "按鈕鏈接（Android）",
        "buttonLinkiOS": "按鈕鏈接 (iOS)",
        "termsURL": "條款及條件鏈接",
        "metadataTitle": "元數據標題",
        "metadataDescription": "元數據描述",
        "isDarkMode": "深色模式",
        "isActive_details": "啓用",
        "Required_fields": "必填字段",
        "change": "變更",
        "Please_input_name": "請輸入姓名",
        "Please_select_date": "請選擇日期",
        "endDate_invalid": "結束日期無效",
        "startDate_greater_than_endDate": "開始日期大於結束日期",
        "Please_input_termsURL": "請輸入條款及細則鏈接",
        "Please_input_metadataTitle": "請輸入元數據標題",
        "Please_input_metadataDescription": "請輸入元數據描述",
        "Please_input_bannerText": "請輸入橫幅文字",
        "Please_input_buttonText": "請輸入按鈕文字",
        "Please_input_bannerLink": "請輸入橫幅鏈接",
        "Please_input_shortLink": "請輸入短鏈接",
        "Please_input_buttonLinkDesktop": "請輸入按鈕鏈接（桌面）",
        "Please_input_buttonLinkAndroid": "請輸入按鈕鏈接（Android）",
        "Please_input_buttonLinkiOS": "請輸入按鈕鏈接（iOS）",
        "the_range_1_to_5": "請為 %{name}! 輸入 1 到 5 範圍內的值",
        "the_range_1_to_100": "請為 %{name}! 輸入 1 到 100 範圍內的值",
        "task_is_empty": "請輸入%{name}的任務！",
        "task_limit_50_characters": "%{name}! 的任務最大長度為 50 個字符！",
        "please_input_points": "請輸入%{name}的分數！",
        "please_input_value": "請輸入 %{name} 的值！",
        "value_is_invalid": "%{name}，值必須是數字",
        "please_upload_image": "請上傳商品 %{name} 的圖片！",
        "rank_invalid": "排名 %{name} 無效！",
        "rank_invalid_to": "排名「至」必須是數字",
        "deepLink": "深層鏈接",
        "point_per_task": "每個任務的分數",
        "points": "積分",
        "points_per_some_think": "每%{單位}的分數",
        "criterias": "標準（可選）",
        "criterias_list": "標準",
        "criteriaType": "類型",
        "criteriaValue": "價值",
        "acceptance_rating": "接單等級（大於或等於）",
        "cancellation_rating": "取消評級（小於或等於）",
        "driver_rating": "司機評級（大於或等於）",
        "please_upload_image_rank": "請上傳排名 %{name} 的圖片！",
        "Update_event_success": "任務已成功更新",
        "Update_event_fail": "更新任務時發生錯誤",
        "Create_event_success": "新任務已成功創建",
        "Create_event_fail": "創建任務時發生錯誤",
        "suspend_list": "超時名單"
    },
    "voucherSettings": {
        "Search_here": "在這裡搜索",
        "Voucher_code": "代碼 ＃",
        "Campaign": "活動",
        "Type": "類型",
        "Value": "價值",
        "Valid_from": "有效期自",
        "Valid_to": "有效",
        "isActive": "狀態",
        "Activate": "啓用",
        "Deactivate": "停用",
        "Actions": "操作",
        "Active": "啓用",
        "Inactive": "停用",
        "redeemed": "已兌換",
        "expired": "已到期",
        "Add_Voucher_code": "添加代碼",
        "Add": "添加",
        "Show_per_page": "每頁項目",
        "Page": "頁",
        "Name": "姓名",
        "show_pages": "顯示了 {0} - {1} 個（共 {2} 個）",
        "Edit": "編輯",
        "Delete": "刪除",
        "Save": "保存",
        "Duplicate": "複製",
        "Cancel": "取消",
        "Required_fields": "必填字段",
        "Detail_voucher_code": "詳細優惠券代碼",
        "Delete_voucher_code": "刪除優惠券代碼",
        "Confirmation": "確認",
        "Delete_confirm_voucher_code": "您想刪除選定的優惠券代碼嗎？",
        "Delete_voucher_code_confirmation": "您想刪除這些優惠券代碼嗎？",
        "Just_delete_inactive": "** 只能刪除處於停用狀態的優惠券代碼。",
        "Loading_voucher_code_fail": "加載優惠券代碼時發生錯誤",
        "Select_campaign": "選擇活動",
        "Create_voucher_code_success": "新優惠券代碼已成功創建",
        "Create_voucher_code_fail": "創建優惠券代碼時發生錯誤",
        "Update_voucher_code_status_success": "優惠券代碼狀態已成功更新",
        "Update_voucher_code_status_fail": "更新優惠券代碼狀態時發生錯誤",
        "Delete_voucher_codes_success": "優惠券代碼已成功刪除",
        "Delete_voucher_codes_fail": "刪除優惠券代碼時發生錯誤",
        "Delete_multiple_voucher_codes_success": "優惠券代碼已成功刪除",
        "Delete_multiple_voucher_codes_fail": "刪除優惠券代碼時發生錯誤",
        "Delete_voucher_code_success": "優惠券代碼已成功刪除",
        "Delete_voucher_code_fail": "刪除優惠券代碼時發生錯誤",
        "Update_multiple_voucher_code_status_success": "優惠券代碼狀態已成功更新",
        "Update_multiple_voucher_code_status_fail": "刪除優惠券代碼狀態時發生錯誤",
        "Please_input_voucher_code": "請輸入優惠券代碼",
        "Please_input_voucher_code_at_least_4_characters": "請輸入至少 4 個字符。",
        "Please_select_campaign": "請選擇活動",
        "Please_input_value": "請輸入值",
        "Please_select_date": "請選擇日期",
        "Invalid_file": "文件內容無效",
        "File_size_larger_than_5Mb": "文件大小大於 5Mb",
        "Import_vouchers_success": "文件已成功導入",
        "Import_vouchers": "導入文件",
        "Number_successful_imports": "導入商品",
        "Number_skipped": "跳過項目",
        "File": "文件",
        "Phone_Numbers": "電話號碼",
        "Import_voucher_code_success": "優惠券代碼 <b>{0}</b> 已成功導入",
        "Import_voucher_code_fail": "優惠券代碼 <b>{0}</b> 導入失敗。 請再試一次",
        "All": "全部"
    },
    "pointConfig": {
        "point_config": "點配置",
        "Convert_spent_amount_to_points": "將消費金額轉換為積分",
        "Convert_topup_amount_to_points": "將充值金額轉換為積分",
        "Redeem_points_to_voucher": "積分兌換優惠券",
        "others": "其他",
        "Online_Payment": "網上支付",
        "transport": "出行約車",
        "parcel": "包裹",
        "food": "食物",
        "mart": "市場",
        "delivery": "送貨",
        "intercity": "專線",
        "applyCash": "申請現金",
        "point": "觀點",
        "vourcherName": "姓名",
        "pointsToRedeem": "積分兌換",
        "vourcherValue": "券的價值",
        "validTime": "有效時間",
        "month": "月份",
        "day": "天",
        "termAndCondition": "條款和條件",
        "Actions": "操作",
        "add_voucher": "添加",
        "instructionURL": "說明鏈接",
        "displayName": "在超級應用上顯示名稱",
        "isActive": "啓用",
        "EDIT_VOUCHER": "更新優惠券",
        "DETAIL_VOUCHER": "優惠券詳情",
        "CREATE_VOUCHER": "添加優惠券",
        "update_successfully": "點配置設置已成功更新",
        "Create_voucher_successfully": "新優惠券創建成功",
        "Create_voucher_fail": "創建優惠券時發生錯誤",
        "Update_voucher_successfully": "優惠券已成功更新",
        "Update_voucher_fail": "更新優惠券時發生錯誤",
        "Delete_voucher_successfully": "優惠券已成功刪除",
        "Delete_voucher_fail": "刪除憑證時發生錯誤",
        "Confirmation": "確認",
        "Delete_voucher_confirmation": "您想刪除此優惠券嗎？",
        "Please_enter_value_least_one": "需要轉化率！ 設置消費金額或充值金額以繼續。",
        "Please_enter_value_is_number_spentAmount": "請在「轉換消費金額」服務「{0}」中輸入有效金額。",
        "Please_enter_value_is_number_topUp": "請在「轉換充值金額」方法「{0}」中輸入有效金額。"
    },
    "resetPassword": {
        "New_Password": "新密碼",
        "New_Comfirm_Password": "新確認密碼",
        "Save": "保存",
        "INFO_SUCCESS": "重置密碼鏈接已發送至您的電子郵件",
        "TOKEN_EXPIRED": "您的重置密碼令牌已過期",
        "Back_To_Login_Page": "返回登錄頁面"
    },
    "permission": {
        "editBooking": "編輯外賣預訂",
        "companyOperator": "供應商操作員",
        "Third_Party_Integration_Booking": "第三方集成（Booking.com）",
        "Third_Party_Integration_HolidayTaxis": "3rd party Integration (HolidayTaxis)",
        "supplierPermission": "Supplier permission",
        "Hint_edit_booking": "允許供應商編輯外發訂單。",
        "View": "查看",
        "Actions": "操作",
        "EditFare": "編輯票價",
        "EditDriverEarning": "編輯司機收入",
        "EditTotal": "編輯總計",
        "ImportBookings": "導入出行訂單",
        "ImportDeliveryBookings": "導入送貨訂單",
        "ShowFare": "顯示票價",
        "delete_permission": "刪除權限",
        "DELETE_CONFIRM": "您確定要刪除所選權限嗎！",
        "Yes": "是",
        "No": "否",
        "Cancel": "取消",
        "DRIVERS": "司機",
        "Driver_list": "司機列表",
        "Enter_to_search": "輸入搜索",
        "Permitted_driver_list": "允許的司機名單",
        "Save": "保存",
        "CAR": "車輛",
        "Car_list": "車清單",
        "Permitted_car_list": "允許車輛清單",
        "Edit_permission": "編輯權限",
        "Add_permission": "添加權限",
        "Detail_permission": "詳細權限",
        "Name": "名稱",
        "Driver_Car": "司機/車輛",
        "All": "全部",
        "Company": "車隊供應商",
        "By_specific": "按特定",
        "Drivers": "司機",
        "Cars": "車輛",
        "Show_per_page": "每頁項目",
        "Page": "頁",
        "show_pages": "顯示了 {0} - {1} 個（共 {2} 個）",
        "Delete": "刪除",
        "Edit": "編輯",
        "Status": "狀態",
        "Action": "操作",
        "Inactive": "停用",
        "Active": "啓用",
        "Update_status_error": "更新狀態錯誤",
        "All_tooltip": "所有司機都將顯示在地圖、提示、司機、運營和報告中。 所有車輛都將顯示在 Car mgmt 上",
        "Company_tooltip": "該供應商獲得許可的司機將顯示在地圖、提示、司機、運營和報告中。 該公司允許使用的車輛將顯示在車輛管理",
        "By_specific_tooltip": "此列表中允許的司機將顯示在「司機」，「運營」中。 此列表中允許的車輛將顯示在車輛管理",
        "Full_permissions": "完整權限",
        "ChangeBalance": "更改信用餘額",
        "ChangeCashBalance": "更改現金餘額",
        "TotalBalance": "總餘額",
        "WriteOffDebt": "註銷欠款",
        "topupPaxWallet": "改變餘額",
        "SeFleetCommission": "設置車隊傭金（所有司機）",
        "EditFleetCommission": "編輯車隊傭金",
        "EditBalance": "編輯餘額",
        "ViewHeatMap": "查看熱圖",
        "Delete_permission_success": "權限已刪除成功",
        "Delete_permission_fail": "刪除權限時發生錯誤",
        "Create_permission_fail": "創建新權限時發生錯誤",
        "Create_permission_success": "新權限已創建成功",
        "Update_permission_fail": "更新權限時發生錯誤",
        "Update_permission_success": "權限已更新成功",
        "No_action_selected": "請在操作列表中至少勾選一項操作",
        "Required_fields": "必填字段",
        "sendSMS": "發送消息（短信）",
        "sendInbox": "發送消息（收件箱）",
        "PayToDriver": "查看「支付給司機」",
        "PayoutHistory": "查看「付款歷史記錄」",
        "Pay": "支付司機費用",
        "ActivateDriver": "啓用司機",
        "Export": "導出",
        "Export_csv": "導出.csv",
        "Street-sharing_rate": "街頭拼車計費"
    },
    "thirdPartySettings": {
        "category": "類別",
        "Not_over_limit_subs": "您不能創建超過 10 個子位置",
        "Show_per_page": "每頁項目",
        "Page": "頁",
        "show_pages": "顯示了 {0} - {1} 個（共 {2} 個）",
        "Delete": "刪除",
        "Edit": "編輯",
        "Status": "狀態",
        "Actions": "操作",
        "Action": "操作",
        "Inactive": "停用",
        "Active": "啓用",
        "website": "網站",
        "Check_Google_Address": "從緯度和經度檢查 Google 地址",
        "Delete_Category_success": "類別已成功刪除",
        "Delete_category_fail": "刪除類別時發生錯誤",
        "availableTime": "接單時間",
        "Please_choose_at_least_one_category": "請至少選擇一個類別",
        "Please_input_category_name": "請輸入類別名稱",
        "Create_category_success": "類別創建成功",
        "Create_category_fail": "創建類別時發生錯誤",
        "Update_third_party_success": "位置已成功更新",
        "Update_category_fail": "更新類別時發生錯誤",
        "All_Category": "所有類別",
        "Delete_Category": "編輯類別",
        "Edit_Category": "編輯類別",
        "Add_Category": "添加類別",
        "Add_Third_Party_location": "添加第三方位置",
        "Add": "添加",
        "Edit_Third_Party_location": "編輯第三方地點",
        "Location_Not_Found": "未找到位置",
        "Create_third_party_success": "位置已成功創建",
        "Create_third_party_fail": "創建位置時發生錯誤",
        "Update_third_party_fail": "更新位置時發生錯誤",
        "Alias": "別名",
        "Address": "地址",
        "Latitude": "緯度",
        "Longitude": "經度",
        "Please_input_address": "請輸入地址",
        "Please_input_name": "請輸入地點名稱",
        "Please_input_latitude": "請輸入緯度",
        "Please_input_longitude": "請輸入經度",
        "Save": "保存",
        "Cancel": "取消",
        "Required_fields": "必填字段",
        "Last_update": "最後更新",
        "Username": "用戶名",
        "locationType": "位置類型",
        "single": "單一地點",
        "multiple": "多個地點",
        "AddMore": "添加更多",
        "locationList": "地點列表",
        "instructionLink": "說明鏈接",
        "location": "地點",
        "checkOnMap": "查看地圖",
        "Remove": "消除",
        "placeType": "類型",
        "all": "全部",
        "PU": "出發地",
        "DO": "目的地",
        "namePlaceholder": "例如 1號航站樓",
        "instructionLinkPlaceholder": "包含完整說明的 Html 頁面",
        "instructionLinkTooltip": "請輸入說明頁面的鏈接。 乘客可以按照此鏈接上的說明找到上車地點。",
        "longitudePlaceholder": 108.1837969,
        "latitudePlaceholder": 16.0393381,
        "Error_message_lat_in_range": "緯度必須介於 -90.0 和 90.0 之間",
        "Error_message_lng_in_range": "經度必須介於 -180.0 和 180.0 之間。",
        "Delete_third_party_success": "位置已成功刪除",
        "Delete_third_party_fail": "刪除位置時發生錯誤",
        "Delete_location": "刪除位置",
        "Note_guide": "注意：在地圖上搜索時，您的位置將很快用圖釘標記出來。"
    },
    "city": {
        "DELETE_CITY": "刪除城市",
        "DELETE_CONFIRM": "您確定要刪除所選城市嗎？",
        "Yes": "是",
        "No": "否",
        "Cancel": "取消",
        "EDIT_CITY": "編輯城市",
        "ADD_CITY": "添加城市",
        "Name": "姓名",
        "Required_fields": "必填字段",
        "Save": "保存",
        "Add": "添加",
        "Show_per_page": "每頁項目",
        "Page": "頁",
        "show_pages": "顯示了 {0} - {1} 個（共 {2} 個）",
        "Delete": "刪除",
        "Edit": "編輯",
        "DETAIL_CITY": "詳細城市",
        "Update_city_success": "城市已更新成功",
        "Create_city_success": "新城市創建成功",
        "Delete_city_success": "城市已刪除成功",
        "cityName": "城市名",
        "Service": "服務",
        "serviceTransportation": "出行約車",
        "serviceAssistant": "跑腿",
        "serviceDelivery": "送貨",
        "isShowDriverSignUp": "在提供商註冊中顯示",
        "note_isShowDriverSignUp": "(從4.6.46及以上版本開始申請新的註冊模塊)"
    },
    "jobTab": {
        "DELETE_JOBTAB": "刪除作業選項卡",
        "DELETE_CONFIRM": "您確定要刪除選定的「作業」選項卡嗎？",
        "Yes": "是",
        "No": "否",
        "Cancel": "取消",
        "EDIT_JOBTAB": "編輯工作標籤",
        "ADD_JOBTAB": "添加工作標籤",
        "Name": "姓名",
        "Required_fields": "必填字段",
        "Save": "保存",
        "Add": "添加",
        "Show_per_page": "每頁項目",
        "Page": "頁",
        "show_pages": "顯示了 {0} - {1} 個（共 {2} 個）",
        "Delete": "刪除",
        "Edit": "編輯",
        "DETAIL_JOBTAB": "詳細工作標籤",
        "Update_JOBTAB_success": "職位標籤已成功更新",
        "Create_JOBTAB_success": "新工作標籤已成功創建",
        "Delete_JOBTAB_success": "工作標籤已成功刪除",
        "JOBTABName": "工作標籤名稱",
        "jobsLink": "關聯"
    },
    "driverNote": {
        "name": "司機注意事項",
        "DELETE": "刪除司機備注",
        "DELETE_CONFIRM": "您確定要刪除所選的司機備注嗎？",
        "EDIT": "編輯司機備注",
        "ADD": "添加司機備注",
        "DETAIL": "詳細司機注意事項",
        "Update_success": "司機備注已成功更新",
        "Create_success": "新的司機備注已成功創建",
        "Delete_success": "司機備注已成功刪除",
        "Delete_fail": "刪除司機備注時發生錯誤",
        "Load_fail": "加載司機備注時發生錯誤",
        "Update_fail": "更新司機備注時發生錯誤",
        "Create_fail": "創建司機備注時發生錯誤"
    },
    "flightIntegration": {
        "Username": "用戶名",
        "provider": "航班提供商",
        "Key": "API密鑰",
        "apiId": "API ID",
        "Save": "保存",
        "Update_flight_integration_success": "航班集成已成功更新",
        "Update_flight_integration_fail": "更新航班集成時發生錯誤"
    },
    "smsIntegration": {
        "provider": "短信提供商",
        "apiToken": "API令牌",
        "subAccountId": "子賬戶ID",
        "senderIdSource": "發件人 ID（來源）：",
        "apiKey": "API密鑰",
        "username": "用戶名",
        "shortCode": "短代碼",
        "key": "鑰匙",
        "webService": "網絡服務",
        "bearerToken": "不記名令牌",
        "keyId": "鑰匙編號",
        "email": "電子郵件",
        "appKey": "應用程序密鑰",
        "sender": "發件人",
        "password": "密碼",
        "maskedPhone": "屏蔽電話號碼",
        "senderId": "發件人ID",
        "webservice": "網絡服務",
        "brandname": "品牌",
        "appSecret": "應用秘密",
        "consumerKey": "消費者鑰匙",
        "requestUrl": "請求網址",
        "authId": "授權ID",
        "authToken": "身份驗證令牌",
        "phloId": "PHLO ID",
        "dlcNumber": "10DLC 編號",
        "app_key": "應用程序密鑰",
        "api_key": "API密鑰",
        "accountSID": "賬戶SID",
        "applicationId": "應用程序ID",
        "phoneNumber": "短信號碼1",
        "phoneNumber2": "短信號碼2",
        "SenderID_1": "SenderID 1",
        "SenderID_2": "SenderID 2",
        "smsMarketingNumber": "短信製作電話號碼",
        "updateSMSSuccess": "短信集成已成功更新",
        "Deleted_success": "The SMS has been deleted successfully",
        "receiverCountries": "Receiver Countries",
        "error": {
            "ERROR_CREATE": "The errors occurred while creating sms",
            "COUNTRY_EXISTED": "Country already exist on system",
            "ERROR_DELETE": "Failed to delete SMS",
            "ERROR_UPDATE": "Failed to update SMS"
        },
        "identifierId": "Identifier ID"
    },
    "mDispatcher": {
        "mDispatchers": "夥伴",
        "Active": "啓用",
        "Inactive": "停用",
        "Deactivate": "停用",
        "Activate": "啓用",
        "Delete": "刪除",
        "Save": "保存",
        "Edit": "編輯",
        "ADD_MDISPATCHER": "添加合作夥伴",
        "EDIT_MDISPATCHER": "編輯合作夥伴",
        "Phone_number": "電話號碼",
        "first_name": "姓氏",
        "last_name": "名字",
        "email": "電子郵件",
        "address": "地址",
        "mDispatcher_type": "合作夥伴類型",
        "mDispatcher_type_toolTip": "如需添加更多合作夥伴類型，請前往「設置」>「合作夥伴類型」",
        "queuing_area_toolTip": "要添加更多排隊區，請進入排隊區菜單。",
        "mDispatcher": "夥伴",
        "card_management": "信用卡管理",
        "Select_mDispatcher_type": "選擇合作夥伴類型",
        "Amount": "金額",
        "Percent": "百分比",
        "Yes": "是",
        "Required_fields": "必填字段",
        "Cancel": "取消",
        "commission_type": "傭金類型",
        "commission_value": "傭金價值",
        "Name": "名稱",
        "Phone_Number": "電話號碼",
        "queuing_area": "排隊區",
        "mdispatcher_type": "合作夥伴類型",
        "registered_from": "註冊自",
        "registration_date": "註冊日期",
        "status": "狀態",
        "actions": "操作",
        "Select_queue_area": "選擇排隊區域",
        "for_new_mDipatcher_account_password_default_is_password": "對於新的合作夥伴帳戶，默認密碼為「12345678」",
        "Create_mDispatcher_success": "新合作夥伴創建成功",
        "Create_mDispatcher_fail": "創建合作夥伴時發生錯誤",
        "Load_mDispatcher_fail": "加載合作夥伴時發生錯誤",
        "Update_mDispatcher_success": "合作夥伴已成功更新",
        "Update_mDispatcher_fail": "更新合作夥伴時發生錯誤",
        "Update_mDispatcher_status_success": "合作夥伴狀態已成功更新",
        "Update_mDispatcher_status_fail": "更新合作夥伴狀態時發生錯誤",
        "Update_mDispatcher_statuses_success": "合作夥伴狀態已成功更新",
        "Update_mDispatcher_statuses_fail": "更新合作夥伴狀態時發生錯誤",
        "Delete_mDispatcher_success": "合作夥伴已成功刪除",
        "Delete_mDispatcher_fail": "刪除合作夥伴時發生錯誤",
        "Delete_mDispatchers_success": "合作夥伴已成功刪除",
        "Delete_mDispatchers_fail": "刪除合作夥伴時發生錯誤",
        "Do_you_want_to_delete_these_mDipatchers": "您想刪除這些合作夥伴嗎？",
        "Only_mDistcher_in_the_inactive_status_can_be_deleted": "***只能刪除處於停用狀態的夥伴",
        "No_card": "沒有找到卡",
        "Reset_Password": "重設密碼",
        "Reset_password_success": "合作夥伴密碼已成功重置",
        "Reset_password_fail": "重置合作夥伴密碼時發生錯誤",
        "Do_you_want_to_reset_password": "您想重置密碼嗎？",
        "ERROR_EXISTED_PHONE": "該電話已存在於系統中。 請輸入另一項",
        "ERROR_INPUT_VALIDPHONE": "請輸入一個有效的電話號碼。",
        "Partner_detail": "合作夥伴詳情",
        "ALERT_SELECT_ACTIVATE": "請選擇合作夥伴進行啓用。",
        "ALERT_SELECT_DEACTIVATE": "請選擇要停用的合作夥伴。",
        "CONFIRM_DEACTIVATE_MULTI": "您想停用這些合作夥伴嗎？",
        "CONFIRM_ACTIVATE_MULTI": "您想啓用這些合作夥伴嗎？",
        "ERROR_COMMISSION_NUMBERS": "請輸入有效號碼",
        "No_Support": "該區域暫不支持添加新卡",
        "Add": "添加",
        "Default_zone": "默認區域",
        "required_email_payment": "需要合作夥伴的電子郵件。 請更新個人資料以繼續。",
        "titleGuestQR": "合作夥伴嘉賓-二維碼鏈接",
        "questQRContent": "您的個性化合作夥伴預訂鏈接就在這裡！ 將其顯著地展示在酒吧、酒店接待處、客房和您的網站上（任何客戶可能需要乘車或送貨的地方）。 考慮使用您獨特的二維碼設計，讓客戶可以快速輕鬆地掃描以預訂旅程！",
        "View_Guest_QR": "查看乘客二維碼"
    },
    "mDispatcherType": {
        "Name": "姓名",
        "Required_fields": "必填字段",
        "Save": "保存",
        "Cancel": "取消",
        "No": "否",
        "Add": "添加",
        "Show_per_page": "每頁項目",
        "Page": "頁",
        "show_pages": "顯示了 {0} - {1} 個（共 {2} 個）",
        "Delete": "刪除",
        "Edit": "編輯",
        "DELETE_MDISPATCHER_TYPE": "刪除合作夥伴類型",
        "DELETE_CONFIRM": "您確定要刪除合作夥伴類型嗎？",
        "Yes": "是",
        "EDIT_MDISPATCHER_TYPE": "編輯合作夥伴類型",
        "ADD_MDISPATCHER_TYPE": "添加合作夥伴類型",
        "DETAIL_MDISPATCHER_TYPE": "詳細合作夥伴類型",
        "Actions": "操作",
        "Delete_mDispatcherType_success": "合作夥伴類型已成功刪除",
        "Delete_mDispatcherType_fail": "刪除合作夥伴類型時發生錯誤",
        "Load_mDispatcherType_fail": "加載合作夥伴類型時發生錯誤",
        "Create_mDispatcherType_success": "新合作夥伴類型已成功創建",
        "Create_mDispatcherType_fail": "創建合作夥伴類型時發生錯誤",
        "Update_mDispatcherType_success": "合作夥伴類型已成功更新",
        "Update_mDispatcherType_fail": "更新合作夥伴類型時發生錯誤"
    },
    "queuingArea": {
        "queuing_area_info": "排隊區信息",
        "queuingDrivers": "啓用的司機",
        "Name": "姓名",
        "Required_fields": "必填字段",
        "Save": "保存",
        "Cancel": "取消",
        "Yes": "是",
        "No": "否",
        "Add": "添加",
        "Show_per_page": "每頁項目",
        "Enter_to_search": "輸入搜索",
        "Page": "頁",
        "show_pages": "顯示了 {0} - {1} 個（共 {2} 個）",
        "Delete": "刪除",
        "Edit": "編輯",
        "Active": "啓用",
        "Inactive": "停用",
        "Deactivate": "停用",
        "Activate": "啓用",
        "drivers": {
            "order": "命令",
            "Driver_name": "司機姓名",
            "Phone_Number": "電話號碼",
            "driverLicenseNumber": "牌照",
            "Car_Type": "車型",
            "checkInTime": "登記時間",
            "duration": "期間",
            "timestampScore": "時間戳分數",
            "changeOrderDriver": "改變順序",
            "detailOrderDriver": "司機訂單",
            "Yes": "是",
            "No": "否",
            "insert": "插入",
            "refresh": "刷新",
            "insertDriver": "將司機插入隊列",
            "orderDriver": "訂單#",
            "reason": "原因",
            "removeDriver": "從隊列中刪除司機",
            "removeDriverConfirm": "您想從隊列中刪除該司機嗎？",
            "driverNotStay": "該司機沒有停留在排隊區域內。 請稍後再試。",
            "overOrder": "訂單號不能大於隊列中司機的總數。",
            "noDriverFound": "沒有找到司機",
            "driverUpdateErrRs": "司機不在排隊區。 請稍後再試。",
            "orderUpdateErrRs": "訂單號不能大於隊列中司機的總數。",
            "removeDriverSuccess": "司機已成功被移出排隊區。",
            "removeDriverFail": "從隊列區域刪除司機時發生錯誤。",
            "operationInsertFailRs": "司機不存在或不可用。",
            "versionInsertFailRs": "要應用此功能，司機需要將其司機應用程序升級到最新版本。",
            "queueInsertFailRs": "隊列不存在或司機不在隊列區域。 請稍後再試。",
            "vehicleInsertFailRs": "隊列不支持司機的車型。",
            "orderInsertFailRs": "訂單號不能大於隊列中司機的總數。",
            "positionInsertFailRs": "將司機插入隊列區域時發生錯誤。",
            "insertDriverSuccess": "司機已成功插入隊列區。",
            "changeOrderSuccess": "司機位置已成功更改。",
            "insertDriverFail": "從隊列區域插入司機時發生錯誤。",
            "changeOrderFail": "從隊列區域更改司機位置時發生錯誤。",
            "selectToInsert": "搜索：司機姓名、電話號碼",
            "updateBy": "由操作員更新",
            "changeOrder": "改變順序",
            "remove": "消除"
        },
        "keepDriverPlaceCancelNoShow": "取消預訂/NOSHOW時保留司機的位置",
        "keepDriverPlaceUnavailable": "當司機不在時，保留司機的位置",
        "range": "隊列範圍",
        "queueRangeTooltip": "這是司機在司機App上看到其排隊位置編號的方式。例如：\n 將隊列範圍設置為1：司機將看到他們在隊列中的位置為單個數字：1、2、3、4等。\n 將隊列範圍設置為5：司機將在隊列中看到自己的位置，範圍為1-5、6-10、11-15、16-20等。\n 將隊列範圍設置為10：司機將在隊列中看到自己的位置：1-10、11-20、21-30、31-40等。",
        "queueCanceledTooltip": "「- 當乘客取消或NOSHOW時，如果司機在設定時間內返回隊列，司機將保持在最前面的位置。」\n  - 如果司機在完成短途預訂後在規定時間內返回隊列，則司機的位置將被設置為高優先級。",
        "DELETE_QUEUING_AREA": "刪除排隊區",
        "DELETE_CONFIRM": "您想刪除排隊區嗎？",
        "EDIT_QUEUING_AREA": "編輯排隊區",
        "ADD_QUEUING_AREA": "添加排隊區",
        "DETAIL_QUEUING_AREA": "詳細排隊區",
        "Queuing_area": "排隊區",
        "Address": "地址",
        "Enter_a_location": "輸入地點",
        "Entered_Address_is_incorrect": "系統無法確定您的位置。 請使用建議的地址。",
        "Not_supported_zone": "該隊列區域不在受支持的區域內",
        "Active_mDispatcher_list": "啓用的合作夥伴名單",
        "Queue_list": "隊列列表",
        "Allow_radius": "允許半徑",
        "Min_drivers_required": "# 所需的最少司機",
        "Open_queuing_area": "開放排隊區",
        "Auto_dispatch": "自動調度給隊列中的司機（無需使用合作夥伴應用程序）",
        "Active_car_list": "啓用的車輛列表",
        "mDispatcher": "合作夥伴",
        "Google_Address": "谷歌地址",
        "Status": "狀態",
        "Update_status_error": "更新狀態錯誤",
        "driver_checkin_area": "司機報到區",
        "EDIT_POLYGONS": "編輯區域",
        "pax_pickup_area": "乘客上車區",
        "pickup_area": "接送區",
        "your_chosen_polygon": "您選擇的多邊形",
        "other_queues": "其他隊列",
        "operation_zones": "運營區域",
        "delete_pickup_area_confirm": "您確定要刪除此接送區域嗎？",
        "delete_pickup_area": "刪除接送區域",
        "please_choose_driver_check_in_area": "請選擇司機報到區域。",
        "please_choose_pick_up_area": "請選擇接送區域。",
        "Load_queuing_area_fail": "加載排隊區域時發生錯誤",
        "Create_queuingArea_success": "排隊區創建成功",
        "Update_queuingArea_success": "排隊區已成功更新",
        "Delete_queuingArea_success": "排隊區已成功刪除",
        "Delete_queuingArea_fail": "刪除排隊區時發生錯誤",
        "Create_queuingArea_fail": "創建排隊區時發生錯誤",
        "Load_queuingArea_fail": "加載排隊區域時發生錯誤",
        "Update_queuingArea_fail": "更新排隊區域時發生錯誤"
    },
    "appBanner": {
        "Save": "保存",
        "Pax_app": "乘客App",
        "Apply_to": "適用於",
        "Please_select_view": "請選擇視圖",
        "Select_Type": "請選擇橫幅類型",
        "Home_view": "首頁",
        "Other_view": "其他",
        "Banner_1": "橫幅 #1",
        "Upload_an_image": "未選擇圖像",
        "Action": "操作",
        "NA": "不適用",
        "Link_to_website": "鏈接到網站",
        "Make_a_call": "打個電話",
        "Send_an_email": "發送電子郵件",
        "Banner_2": "橫幅 #2",
        "Banner_3": "橫幅#3",
        "Driver_app": "司機App",
        "pax": "乘客App",
        "driver": "司機App",
        "Name": "姓名",
        "viewType": "類型",
        "status": "狀態",
        "image": "圖像",
        "html": "超文本標記語言",
        "Delete_Banner": "刪除橫幅",
        "Delete_confirm_banner": "您確定要刪除選定的橫幅嗎？",
        "Delete_banner_success": "橫幅已成功刪除",
        "Delete_banner_fail": "刪除橫幅時發生錯誤",
        "edit_banner": "編輯橫幅",
        "detail_banner": "詳細橫幅",
        "create_banner": "添加橫幅",
        "note_1": "* 文件擴展名必須為 .jpg、.jpeg、.png、.gif，且小於 %{limit}MB。",
        "note_2": "* 圖像的長寬比應為 16:9。",
        "note_3": "* 其他視圖包括收件箱、推薦朋友、收據、我的訂單、促銷代碼、添加信用卡/付款方式、個人資料、等待流程/確認預訂、已完成視圖。",
        "Update_app_banner_success": "應用橫幅已成功更新",
        "Update_app_banner_fail": "無法更新應用橫幅！",
        "Create_app_banner_success": "應用橫幅已成功創建",
        "Create_app_banner_fail": "創建應用橫幅失敗！",
        "Note": "請使用 .png 格式的文件（透明背景，大小 400:200px）以獲得更好的應用性能",
        "Limit_size_upload": "文件大小必須小於 8Mb。",
        "display_duration": "顯示時長",
        "playback_settings": "播放設置",
        "display_banner_again_after": "之後再次顯示橫幅",
        "stop_display_banner_after": "之後停止顯示橫幅",
        "enable_playback": "啓用播放",
        "Update_playback_success": "播放設置已成功更新",
        "Update_playback_fail": "更新播放設置失敗！",
        "language": "語言",
        "single": "單一語言",
        "multi": "多種語言",
        "es-ES": "Spanish",
        "en-US": "English",
        "fr-FR": "French",
        "id-ID": "Bahasa indonesia",
        "vi-VN": "Vietnamese",
        "ur-PK": "Urdu",
        "ms-MY": "Malaysia",
        "ar-AR": "Arabic",
        "nb-NO": "Norwegian",
        "en-GB": "English (United Kingdom)",
        "am-ET": "Amharic",
        "ro-RO": "Romanian",
        "version": "App版本",
        "comparisonVersionApp": {
            "is": "是",
            "is_not": "不是",
            "is_one_of": "是其中之一",
            "is_not_one_of": "不是其中之一",
            "greater_or_equal": "大於或等於",
            "less_or_equal": "小於或等於"
        },
        "platform": "平台",
        "platformItem": {
            "all": "全部",
            "iOS": "iOS系統",
            "android": "安卓"
        },
        "userStatus": "用戶狀態",
        "Active": "啓用",
        "Inactive": "停用",
        "in_review_completed": "審核中（簡介已完成）",
        "in_review_incomplete": "審核中（個人資料不完整）",
        "in_review_inProgress": "審核中（簡介正在進行中）",
        "zones": "區域"
    },
    "reservation": {
        "name": "預訂",
        "company": "供應商",
        "carType": "車型",
        "licensePlate": "車牌：%{name}",
        "driver": "司機：%{姓名}",
        "showAllDrivers": "顯示所有司機",
        "available": "可用的",
        "reserved": "被預訂",
        "search_holder": "供應商、司機姓名、車型、車牌",
        "all": "全部",
        "today": "今天",
        "thisWeek": "本星期",
        "day": "天",
        "week": "周",
        "bookingID": "訂單ID",
        "tripId": "行程ID",
        "pickupTime": "接載時間",
        "pickupLocation": "上客位置",
        "monday": "週一",
        "tuesday": "週二",
        "wednesday": "週三",
        "thursday": "週四",
        "friday": "週五",
        "saturday": "週六",
        "sunday": "周日",
        "overlapped": "重疊",
        "noData": "沒有找到任何數據。",
        "bookingDetails": "預訂詳情",
        "reservedHint": "預訂已確認",
        "availableHint": "沒有任何已確認的預約訂單",
        "overlappedHint": "兩個或多個已確認的預訂重疊"
    },
    "dashboard": {
        "API": "API",
        "cc": "控制中心",
        "dmc": "預訂板",
        "streetSharing": "街頭拼車",
        "kiosk": "自助機",
        "mDispatcher": "合作夥伴",
        "paxApp": "乘客App",
        "webBooking": "網上預訂",
        "carHailing": "網約車",
        "acceptance": "接單",
        "booking_com": "Booking.com",
        "completed": "完成",
        "noShow": "NOSHOW",
        "canceled": "取消",
        "incident": "事故",
        "rate1": "1 星",
        "rate2": "2 星",
        "rate3": "3 星",
        "rate4": "4 星",
        "rate5": "5 星",
        "Export_to_Excel": "導出到 Excel",
        "Profit": "利潤",
        "day_change": "每日變化",
        "week_change": "每周變化",
        "month_change": "每月變化",
        "Booking_requests": "預訂請求",
        "Complete_bookings": "完成訂單",
        "New_customers": "新客戶",
        "Booking_dashboard": "預訂板",
        "others": "其他的",
        "Revenue_booking": "利潤及完成訂單",
        "Profit_finish_booking": "利潤及完成訂單",
        "Booking_request": "建立訂單",
        "Finished_booking": "完成訂單",
        "failed": "失敗的",
        "partialCompleted": "部分完成",
        "Finished_booking_char_header": "已完成預訂 ({0})",
        "Bookings": "預訂 ({0})",
        "Ratings": "評分 ({0})",
        "Daily": "日常的",
        "Weekly": "每周",
        "Monthly": "每月",
        "Total_customer": "客戶總數{0}",
        "ProfitToolTips": "扣除所有費用後車隊賺到的錢",
        "RevenueToolTips": "車隊在選定期間實際收到的錢，包括促銷、司機扣除、稅收等。",
        "ProfitChangeToolTips": "與上期相比機隊利潤變化百分比",
        "BookingRequestsToolTips": "選定時間段內創建的實時訂單和訂單總數",
        "PercentageOfAcceptanceToolTips": "所選時間段內接受訂單的百分比",
        "BookingChangeHint": "與上一期間相比，已創建預訂的變化百分比",
        "CompletedBookingHint": "選定期間內完成的實時訂單和預訂總數",
        "PercentageOfSatisfactionHint": "選定時間段內的滿意度百分比",
        "CompleteBookingDailyChangeHint": "與上一時期相比，已完成預訂的變化百分比",
        "NewCustomersHint": "選定期間內註冊的新客戶總數",
        "CustomerChangeHint": "與上期相比客戶數量及變化比例",
        "TotalCustomer": "系統內客戶總數",
        "ProfitAndFinishedBookingHint": "選定期間機隊的利潤和完成預訂量",
        "BookingRequestHint": "選定時間段內創建的實時訂單和預訂總數",
        "FinishedBookingChartsHint": "選定時間段內實時訂單和已關閉的預訂總數，包括取消、未入住、事件和已完成",
        "Customer_count": "{0} 客戶數量",
        "Revenue": "收入"
    },
    "referral": {
        "first_package": "第一個套餐（即第一個完成的 Pax 預訂）",
        "driver_refers_to_pax": "司機介紹客戶",
        "driver_will_get": "司機將得到",
        "money": "錢",
        "amount": "金額",
        "percent": "百分",
        "settlement": "傭金（每筆已完成預訂小計金額的百分比）",
        "pax_will_get": "乘客獲得",
        "promotion_code": "促銷代碼",
        "select_a_promotion": "選擇促銷代碼",
        "maximum": "最大限度",
        "second_package": "第二套餐",
        "from": "從",
        "to": "到",
        "After_2nd_package": "第二套餐後",
        "from_end_value_of_above_package": "從上述最後一個套餐價值 + 1",
        "Expiration_date": "截止日期",
        "Never": "絕不",
        "Customized_date": "定制日期",
        "limited": "有限的",
        "unlimited": "無限",
        "Valid_from": "有效期自",
        "Valid_to": "有效",
        "Invitation_limit": "被推薦人員限制/車手",
        "Status": "狀態",
        "Active": "啓用",
        "Inactive": "停用",
        "note": "* 請注意，一旦活動開始，任何獎勵價值的更新都只會影響新客戶。",
        "Required_fields": "必填字段",
        "refferal_update_successfully": "推薦設置已成功更新",
        "distributeIncentiveToDriver": "向司機發放獎勵",
        "Immediate": "即時",
        "Periodic": "定期（手動）",
        "Period": "時期",
        "startDate": "開始日期",
        "nextDistributionDate": "下次分發日期",
        "oneMonth": "1個月",
        "threeMonth": "3個月",
        "sixMonth": "6個月",
        "passenger_refers_to_pax": "客戶指的是客戶",
        "referer_will_get": "推薦人將獲得",
        "referee_will_get": "被推薦人將獲得",
        "promo": "推廣",
        "Pax_invitation_limit": "邀請限制/用戶",
        "promoPaidBy": "促銷費用由",
        "fleet": "運營商",
        "driver": "司機",
        "promoCode": "促銷代碼",
        "isSendInbox": "發送收件箱",
        "isDefaultCode": "在應用程序上自動應用",
        "autoApplyOnWeb": "網上自動申請",
        "isDefaultCode_hint": "將促銷代碼添加到客戶列表中，並在客戶創建訂單時自動應用於預訂",
        "Actions": "操作",
        "add_promoCode": "添加",
        "Send_an_in_box_automatically_to_new_customers": "發送給客戶App收件箱",
        "set_default_promo_code": "在App上自動套用",
        "EDIT_PROMO_CODE": "編輯促銷代碼",
        "DETAIL_PROMO_CODE": "促銷代碼詳細信息",
        "CREATE_PROMO_CODE": "創建促銷代碼",
        "DELETE_PROMO_CODE": "刪除促銷代碼",
        "DELETE_CONFIRM_PROMO_CODE": "您想刪除此促銷代碼嗎？",
        "ERROR_INPUT_SOURCE": "請至少選擇一項。"
    },
    "upload_document": {
        "NO_FILE_CHOSEN": "沒有選中任何文件",
        "Limit_size_upload": "文件大小超出允許的限制 {0}Mb。"
    },
    "payment_info": {
        "member_since": "會員自",
        "package_name": "包名字",
        "last_payment": "最後一次付款",
        "credit_card": "信用卡",
        "last_digits": "最後 4 位數字",
        "default": "默認",
        "add_new_card": "添加新卡",
        "history": "歷史",
        "transaction_date": "交易日期",
        "transaction_id": "交易ID",
        "billing_cycle": "結算週期",
        "amount": "數量",
        "payment_history": "賬單歷史記錄",
        "card_information": "卡信息",
        "card_holder": "持卡人",
        "card_number": "卡號",
        "expiration_date": "截止日期",
        "cvv": "CVV"
    },
    "search_by": {
        "group_id": "組號",
        "booking_id": "訂單#",
        "recurring_id": "復發訂單#",
        "ExternalID": "外部單號#",
        "tripId": "行程#",
        "driver_name": "司機姓名",
        "driver_phone_number": "司機電話號碼",
        "license_plate": "牌照",
        "vehicle": "車輛",
        "operator": "操作員",
        "passenger_email": "旅客郵箱",
        "passenger_name": "乘客姓名",
        "passenger_phone_number": "乘客電話號碼",
        "destination": "目的地",
        "pick_up": "接載",
        "flight_number": "航班 ＃",
        "book_from": "預訂自",
        "note": "備注",
        "email": "電子郵件",
        "phone": "電話"
    },
    "withdrawal": {
        "transferToEWallet": "Transferred to external e-wallet *{0}",
        "channelCode": "Channel code",
        "channelName": "Channel name",
        "reversed": "Reversed",
        "requestTime": "請求時間",
        "referenceId": "參考 ＃",
        "driverName": "司機姓名",
        "phoneNumber": "電話號碼",
        "company": "供應商",
        "description": "描述",
        "requestAmount": "請求金額",
        "transferAmount": "轉賬金額",
        "currentBalance": "當前餘額",
        "currency": "貨幣",
        "action": "操作",
        "approve": "批准",
        "reject": "拒絕",
        "transferToBankAccount": "轉入銀行帳戶 *{0}",
        "transferToWingBank": "已轉移至 Wing 銀行 ID *{0}",
        "transferToCreditWallet": "轉移至信用錢包",
        "reason": "原因",
        "search": "司機姓名、電話、供應商、交易 ID、參考編號",
        "message": {
            "approve": "您確定要向該司機支付 {0} 的費用嗎",
            "approveMulti": "您確定要向選定的司機付款嗎？",
            "approveSuccess": "所選請求已付款",
            "approveFail": "所選請求未成功。 請再試一次。",
            "approveMultiSuccess": "已支付 {1} 個所選請求中的 {0} 個。",
            "approveMultiFail": "{1} 個選定請求中的 {0} 個未成功。 請再試一次。",
            "buttonConfirmTitle": "是",
            "closeButtonText": "否",
            "pleaseInputReason": "請輸入原因",
            "rejectSuccess": "司機的請求被拒絕",
            "rejectFail": "無法拒絕所選請求"
        },
        "getDataFailure": "無法獲取數據",
        "rejectRequest": "拒絕請求"
    },
    "dynamicSurcharge": {
        "operationZone": "運營區域",
        "activeSurcharge": "啓用附加費",
        "surcharge": "附加費",
        "surchargeName": "附加費名稱",
        "parameter": "參數（例如 1.x）",
        "pickupPoint": "接送區",
        "dropOffPoint": "下車區",
        "delete_surcharge_success": "附加費已成功刪除",
        "delete_surcharge_fail": "刪除附加費時發生錯誤",
        "update_surcharge_fail": "更新附加費時發生錯誤",
        "update_surcharge_success": "附加費已成功更新",
        "create_surcharge_fail": "創建附加費時發生錯誤",
        "create_surcharge_success": "附加費已成功創建",
        "active_surcharge_fail": "啓用附加費時發生錯誤",
        "active_surcharge_success": "附加費已成功啓用",
        "deactive_surcharge_fail": "停用附加費時發生錯誤",
        "deactive_surcharge_success": "附加費已成功停用",
        "delete_surcharge": "刪除附加費",
        "delete_confirm_message": "您確定要刪除所選的附加費嗎？",
        "note": "*注意：這是您的基本車費所乘的金額。 示例：當參數為 1.5x 時，票價 20 美元將變為 30 美元"
    },
    "dynamicFare": {
        "operationZone": "運營區域",
        "activeFare": "啓用票價",
        "fare": "票價",
        "fareName": "票價名稱",
        "factor": "乘以因子",
        "fix_amount": "固定值",
        "parameter": "參數（例如 1.x）",
        "delete_fare_success": "票價已成功刪除",
        "delete_fare_fail": "刪除票價時發生錯誤",
        "update_fare_fail": "更新票價時發生錯誤",
        "update_fare_success": "票價已成功更新",
        "create_fare_fail": "創建票價時發生錯誤",
        "create_fare_success": "票價已成功創建",
        "active_fare_fail": "啓用票價時發生錯誤",
        "active_fare_success": "票價已成功啓用",
        "deactive_fare_fail": "停用票價時發生錯誤",
        "deactive_fare_success": "票價已成功停用",
        "delete_fare": "刪除票價",
        "delete_confirm_message": "您確定要刪除所選票價嗎？",
        "note": "*注意：這是您的基本車費所乘的金額。 示例：當參數為 1.5x 時，票價 20 美元將變為 30 美元",
        "note_amount": "*注意：這是您的基本車費將被添加的金額。 示例：當設定金額為 5 美元時，票價 20 美元將變為 25 美元"
    },
    "notifications": {
        "search": "搜索預訂 ID、外部 ID",
        "searchProviderName": "搜索供應商名稱",
        "statusItem": {
            "all": "所有通知",
            "canceledByDriver": "出行約車 - 司機取消",
            "canceledByPartner": "聯屬網絡營銷 - 已被合作夥伴取消",
            "reservationReminder": "預約提醒",
            "localPending": "本地 - 待定",
            "reservationIsNotStarted": "預約尚未開始",
            "partnerRequestsCars": "合作夥伴請求車輛",
            "timeout": "超時",
            "noShow": "聯盟 - NOSHOW",
            "affiliatePending": "聯盟 - 待辦",
            "affiliatePaymentFailed": "會員付款失敗",
            "affiliateTimeout": "聯盟超時",
            "flightApi": "航班API",
            "DebtWriteOff": "欠款註銷",
            "driverCancelTrip": "專線 - 司機取消",
            "minimumSeatRequired": "所需最少座位數",
            "newFarmInJobFrom3rd": "Booking.com - 新預訂",
            "canceledJobBy3rd": "Booking.com - 已取消",
            "updatedJobBy3rd": "Booking.com - 更新",
            "newFarmInJobFromHolidayTaxis": "HolidayTaxis - New booking",
            "canceledJobByHolidayTaxis": "HolidayTaxis - Canceled",
            "updatedJobByHolidayTaxis": "HolidayTaxis - Updated",
            "newFarmInJobFromKarhoo": "Karhoo - New booking",
            "canceledJobByKarhoo": "Karhoo - Canceled",
            "updatedJobByKarhoo": "Karhoo - Updated"
        },
        "sos": {
            "sosSearch": "搜索預訂 ID、乘客姓名、司機姓名",
            "statusItem": {
                "all": "全部",
                "driver": "司機",
                "passenger": "乘客",
                "crew": "機組人員"
            }
        }
    },
    "smartData": {
        "No": "NO",
        "driverName": "姓名",
        "phone": "電話",
        "rating": "評分",
        "completed": "完成",
        "noShow": "NOSHOW",
        "incident": "事故",
        "canceled": "被司機取消",
        "accepted": "接單",
        "reject": "拒絕",
        "ignore": "忽略",
        "earning": "收入",
        "query": {
            "top20": "前20名",
            "top50": "前 50 名",
            "top100": "前100名",
            "bottom20": "後 20 名",
            "bottom50": "後 50 名",
            "bottom100": "後 100 名"
        },
        "status": {
            "canceled": "被司機取消",
            "noShow": "NOSHOW",
            "incident": "事故",
            "completed": "完成",
            "earning": "收入",
            "reject": "拒絕",
            "ignore": "忽略",
            "accepted": "接單"
        }
    },
    "Reject": "NOSHOW",
    "Do not allow Driver to start reservat": "聯繫",
    "intercityRate": {
        "Delete_intercity_rate": "刪除專線價格包",
        "edit_intercity_rate": "編輯專線價格包",
        "view_intercity_rate": "查看專線價格包",
        "add_intercity_rate": "添加專線價格包",
        "Delete_confirm_intercity_rate": "您想刪除該項目嗎？",
        "Update_intercity_rate_success": "專線價格包已成功更新",
        "Update_intercity_rate_fail": "更新專線價格包時發生錯誤",
        "Created_intercity_rate_success": "專線價格包創建成功",
        "Created_intercity_rate_fail": "創建專線價格包時出現錯誤",
        "Update_intercity_rate_route_success": "專線價格包路線已成功更新",
        "Update_intercity_rate_route_fail": "更新專線時發生錯誤",
        "Created_intercity_rate_route_success": "專線價格包路線創建成功",
        "Created_intercity_rate_route_fail": "創建專線時出現錯誤",
        "Loading_intercity_rate_fail": "加載專線價格包詳細信息時發生錯誤",
        "Delete_intercity_rate_route_fail": "刪除專線價格包路線時發生錯誤",
        "Delete_intercity_rate_success": "專線價格包已成功刪除",
        "Delete_intercity_rate_fail": "刪除專線價格包時出現錯誤"
    },
    "intercityRoute": {
        "alias": "別名",
        "routeNameHint": "如需更改路線名稱，請進入設置 > 區域 > 專線，並相應更改城市區域名稱。",
        "pricePerSeat": "每個座位的價格（不含稅）",
        "distance": "距離",
        "duration": "時長",
        "cancellationPenalty": "每個席位的罰款金額",
        "noShow": "乘客/夥伴NOSHOW",
        "penalty": "每個席位的罰款金額",
        "no_show_tooltip": "每個座位將向客戶收取的費用。 除了罰款外，如果適用，客戶還將繳納稅款或技術費",
        "firstLocation": "第一個地點",
        "secondLocation": "第二個地點",
        "routeOneSetup": "設置路線1",
        "routetwoSetup": "設置路線2",
        "schedules": "時間表",
        "date": "日期",
        "time": "時間",
        "editSchedule": "編輯日程",
        "addSchedule": "添加日程",
        "requestTimeEnable": "允許客戶設置請求時間",
        "extraFee": "附加費",
        "hideRouteEnable": "隱藏該路線",
        "timeRange": "時間範圍",
        "workingTime": "工作時間",
        "applyForOtherDays": "申請其他日期",
        "limitSeatLuggage": "限制座位/行李",
        "warningMinimumSeats": "所需最少座位數",
        "warningMinimumSeatsHint": "行程所需的最低座位數",
        "allowStartLessMinRequiredSeats": "無論最低座位要求如何，司機都會開始行程",
        "allowStartLessMinRequiredSeatsHint": "即使請求的座位數少於所需的最低座位數，也允許司機開始行程",
        "freeLuggagePerSeat": "每個座位免費行李",
        "freeLuggagePerSeatHint": "每個座位包含的免費行李數量",
        "allowToAddExtraLuggage": "添加額外行李",
        "allowToAddExtraLuggageHint": "允許乘客添加額外的行李",
        "feePerExtraLuggage": "每件額外行李的費用",
        "Delete_intercity_route": "刪除專線",
        "Delete_confirm_intercity_route": "您想刪除這條路線嗎？",
        "Update_intercity_route_fail": "更新專線時發生錯誤",
        "Update_intercity_route_success": "專線已成功更新",
        "created_intercity_route_success": "專線創建成功",
        "created_intercity_route_fail": "創建專線時出現錯誤",
        "Delete_intercity_route_success": "專線刪除成功",
        "Delete_intercity_route_fail": "刪除專線時出現錯誤",
        "penalty_less_than_or_equal_to_price": "請輸入小於或等於每個座位價格的值。",
        "scheduleTimeOverlap": "計劃時間不得相互重疊",
        "allows_adding_extra": "當客戶請求車隊未安排的時間時，允許添加額外費用。",
        "this_is_to_limit_users": "這是為了限制用戶（即客戶、司機、操作員）在此路線上創建新的訂單或行程。 現有訂單或行程仍保留"
    },
    "dayOfWeek": {
        "0": "周日",
        "1": "週一",
        "2": "週二",
        "3": "週三",
        "4": "週四",
        "5": "週五",
        "6": "週六"
    },
    "intercityBooking": {
        "route": "路線",
        "departureDate": "出發日期",
        "departureTime": "出發時間",
        "Departure": "出發",
        "Arrival": "到達",
        "RefundFare": "退票費",
        "intercityZoneNote": "您在此處創建的區域僅用於設置您的專線價格包。",
        "zoneNameHint": "請保持您的區域名稱簡短、清晰，以便您的客戶可以輕鬆搜索和選擇。",
        "zoneUploadNote_2": "* 建議圖片分辨率為1095x360，文件大小小於5MB。",
        "Complete_booking": "完成預訂",
        "ConfirmCancel": "確認取消"
    },
    "deliveryRate": {
        "Delete_delivery_rate": "刪除送貨計費",
        "edit_delivery_rate": "編輯送貨計費",
        "view_delivery_rate": "查看送貨計費",
        "add_delivery_rate": "添加送貨計費",
        "Delete_confirm_delivery_rate": "您想刪除該項目嗎？",
        "Update_delivery_rate_success": "送貨計費已成功更新",
        "Update_delivery_rate_fail": "更新送貨計費時發生錯誤",
        "Created_delivery_rate_success": "送貨計費已成功創建",
        "Created_delivery_rate_fail": "創建送貨計費時發生錯誤",
        "Update_delivery_rate_route_success": "送貨計費路線已成功更新",
        "Update_delivery_rate_route_fail": "更新送貨路線時發生錯誤",
        "Created_delivery_rate_route_success": "送送貨計價路線已成功創建",
        "Created_delivery_rate_route_fail": "創建送貨路線時發生錯誤",
        "Loading_delivery_rate_fail": "加載送貨計費詳細信息時發生錯誤",
        "Delete_delivery_rate_route_fail": "刪除送貨計價路線時發生錯誤",
        "Delete_delivery_rate_success": "送貨計費包已成功刪除",
        "Delete_delivery_rate_fail": "刪除送貨計費時發生錯誤",
        "distance_km": "每公里費用",
        "distance_mile": "每英里費用",
        "flat": "固定費用",
        "startingFee": "起始費",
        "feeFirstDistance": "費首距離",
        "feeSecondDistance": "費秒距離",
        "feeThirdDistance": "費第三距離",
        "feeAfterThirdDistance": "第三次距離後的費用",
        "feePerMinute": "每分鐘票價",
        "feeMinimum": "最低費用",
        "feeFirstStop": "第一站費用",
        "feeExtraStop": "每個額外站點的費用"
    },
    "pwaSettings": {
        "pwa": "漸進式網頁應用",
        "iconName": "圖標和名稱",
        "logoBackgroundColor": "標誌、背景和顏色",
        "longName": "長名字",
        "shortName": "簡稱",
        "icon192": "主屏幕上的圖標 (192x192)",
        "icon512": "啓動屏幕上的圖標",
        "logo": "徽標 (300x300)",
        "logoMenu": "徽標菜單 (300x300)",
        "backgroundImage": "背景圖片 - 出行約車 (1024x500)",
        "backgroundImageDelivery": "背景圖片 - 送貨 (1024x500)",
        "mainColor": "主色",
        "secondaryColor": "次要顏色",
        "showMainColor": "顯示主顏色而不是背景圖像",
        "success": "PWA 設置已成功更新",
        "failed": "無法更新 PWA 設置。 請再試一次！",
        "errorUploadFileSize": "所需尺寸為 {0}x{0} 像素。",
        "hideWBInstallAppBtn": "隱藏安裝應用程序按鈕",
        "enableBookNow": "現在",
        "enableGuestCheckout": "客人結帳",
        "enableBookLater": "之後",
        "enableHideRecipient": "隱藏收件人信息（包裹內容、遞送說明）",
        "pwaLink": "乘客 PWA",
        "partnerLink": "合作夥伴 PWA",
        "pwaDriverLink": "PWA for Driver",
        "providerSignUp": "Driver sign-up link",
        "AdsTracking": "Ads Tracking",
        "googleId": "Google Tag ID",
        "placeholdergoogleId": "Enter Google Tag ID",
        "metaId": "Meta Pixel ID",
        "placeholdermetaId": "Enter Meta Pixel ID"
    },
    "emailConfig": {
        "emailServer": "電子郵件服務器",
        "default": "默認",
        "customized": "定制",
        "fromName": "來自名字",
        "email": "來自電子郵件",
        "replyTo": "回復",
        "userAuth": "用戶名",
        "password": "密碼",
        "smtpServer": "SMTP服務器",
        "port": "SMTP端口",
        "isAuthenticated": "已認證",
        "option": "啓用選項",
        "none": "無",
        "tls": "TLS",
        "ssl": "SSL",
        "save": "保存",
        "cancel": "取消",
        "success": "更新郵件服務器成功",
        "fail": "更新電子郵件服務器失敗",
        "loadFail": "加載電子郵件服務器詳細信息失敗"
    },
    "driverFields": {
        "Load_fail": "",
        "Edit": "編輯",
        "View": "查看",
        "Cancel": "取消",
        "show": "展示",
        "hide": "隱藏",
        "EDIT_DRIVER_FIELDS": "編輯司機字段",
        "DETAIL_DRIVER_FIELDS": "詳細司機字段",
        "ADD_DRIVER_FIELDS": "添加司機字段",
        "fieldKey": "字段鍵",
        "fieldName": "字段名稱",
        "availableCC": "在指揮中心可用",
        "availableDrvSignUp": "可在司機註冊中找到",
        "availableCompanyCar": "公司車輛 - 出行約車司機",
        "deliveryCompany": "公司車輛 - 送貨司機",
        "availableIndividualCar": "個人車輛 - 出行約車司機",
        "deliveryIndividual": "個人車輛 - 送貨司機",
        "required": "必需的",
        "Update_fail": "更新司機字段時發生錯誤",
        "Update_success": "司機字段已成功更新",
        "FieldKey": "字段鍵",
        "FieldName": "字段名稱（美式英語）",
        "CommandCenter": "可在指揮中心使用",
        "SignUp": "可在司機註冊中使用",
        "CompanyTransport": "公司車輛 - 約車出行",
        "CompanyDelivery": "公司車輛 - 送貨",
        "IndividualTransport": "個人車輛 - 約車出行",
        "IndividualDelivery": "個人車輛 - 送貨",
        "Require": "必需的",
        "Actions": "操作",
        "fieldType": "字段類型",
        "default": "默認",
        "customized": "定制"
    },
    "driverDocument": {
        "Load_fail": "",
        "Edit": "編輯",
        "View": "查看",
        "Cancel": "取消",
        "show": "展示",
        "hide": "隱藏",
        "EDIT_DRIVER_DOCUMENT": "編輯司機文檔",
        "DETAIL_DRIVER_DOCUMENT": "詳細司機文檔",
        "ADD_DRIVER_DOCUMENT": "添加司機文檔",
        "documentKey": "文檔密鑰",
        "documentName": "文件名稱",
        "documentNameDetail": {
            "zh-HK": "繁體中文",
            "it-IT": "Standard Italian",
            "es-ES": "Spanish",
            "en-US": "American English",
            "fr-FR": "Standard French",
            "zh-CN": "簡體中文",
            "id-ID": "Bahasa Indonesia",
            "vi-VN": "Vietnamese",
            "ur-PK": "Urdu (Islamic Republic of Pakistan)",
            "ms-MY": "Malay (Malaysia)",
            "ar-AR": "Arabic",
            "en-GB": "English (United Kingdom)",
            "nb-NO": "Norwegian",
            "am-ET": "Amharic",
            "ro-RO": "Romanian",
            "th-TH": "Thai",
            "ta-IN": "Tamil",
            "hi-IN": "Hindi",
            "te-IN": "Telugu",
            "ka-GE": "Georgian",
            "ru-RU": "Russian",
            "kn-IN": "Kannada"
        },
        "isIndividual": "公司車輛 - 約車出行司機",
        "deliveryCompany": "公司車輛 - 送貨司機",
        "isOwner": "個人車輛 - 約車出行司機",
        "deliveryIndividual": "個人車輛 - 送貨司機",
        "availableCC": "在指揮中心可用",
        "availableDrvSignUp": "可在司機註冊中找到",
        "isRequired": "必需的",
        "isExpiredDate": "到期日期 - 指揮中心",
        "expiredDateForDrvSignUp": "到期日 - 司機註冊",
        "requireExpiredDate": "需要到期日",
        "requireExpiredDateForDrvSignUp": "需要到期日",
        "requireDocumentId": "需要文檔 ID",
        "documentId": "文檔 ID - 指揮中心",
        "documentIdForDrvSignUp": "文檔 ID - 司機註冊",
        "Update_fail": "更新司機文檔時出現錯誤",
        "Update_success": "司機文檔已更新成功",
        "DocumentKey": "文檔密鑰",
        "DocumentName": "文件名稱（美式英語）",
        "CommandCenter": "可在指揮中心使用",
        "SignUp": "可在司機註冊中使用",
        "CompanyTransport": "公司車輛 - 約車出行",
        "CompanyDelivery": "公司車輛 - 送貨",
        "IndividualTransport": "個人車輛 - 約車出行",
        "IndividualDelivery": "個人車輛 - 送貨",
        "Require": "必需的",
        "Actions": "操作"
    },
    "holidayTaxis": {
        "title": "HolidayTaxis API Credentials",
        "note": "Communicate with HolidayTaxis's technical team to provide you with api key to connect to their system.",
        "apiKey": "API key",
        "placeHolderApiKey": "Enter api key"
    },
    "trailNotes": {
        "maxLengthFiles": "Maximum 3 files per comment",
        "maxSizeFiles": "Maximum 10MB per file",
        "typeFiles": "File type supported: pdf, doc, docx, csv, xls, xlsx, jpeg, jpg, png, webp, txt, note",
        "maxComments": "Maximum 20 comments per booking",
        "bookingActivity": "Booking Activity",
        "viewAll": "View all",
        "operator": "Operator",
        "driver": "Driver",
        "passenger": "Customer",
        "pHInput": "Add a new comment. You can click save to save the note. You can also drag an drop multiple files here.",
        "addFiles": "Add files",
        "save": "Save",
        "inputNotes": "Please input text",
        "successDeleteTrailNotes": "Delete trail notes successfully",
        "successCreateTrailNotes": "Create trail notes successfully",
        "deleteTrailNote": "Delete a trail note",
        "msgDeleteTrailNote": "Are you sure you want to delete this?"
    },
    "supplier": {
        "permissionRequired": "Operator need to select a permission",
        "offerDetail": "Offer Detail",
        "orderDetail": "Order Detail",
        "bookingNumber": "Booking Number",
        "date": "Date",
        "pickupLocation": "Pick up Location",
        "dropoffLocation": "Drop off Location",
        "passenger": "Passenger",
        "luggage": "Luggage",
        "pickupService": "Pickup Service",
        "pickupSignage": "Pickup Signage",
        "flightNumber": "Flight Number",
        "departure": "Departure",
        "arrival": "Arrival",
        "terminal": "Terminal",
        "gate": "Gate",
        "meetGreet": "Meet & Greet",
        "specialRequests": "Special Requests",
        "additionalServices": "Additional Services",
        "price": "Price",
        "prices": "Prices",
        "guest": "Guest",
        "serviceClass": "Service Class",
        "distance": "Distance",
        "duration": "Duration",
        "acceptOffer": "Accept Offer",
        "updateBooking": "Update Booking",
        "package": "Package",
        "route": "Route",
        "assignedChauffer": "Assigned Chauffeur",
        "assignedVehicle": "Assigned Vehicle",
        "logs": "Logs",
        "link": "Links",
        "desLink": "Click to copy link",
        "trackLink": "Track link",
        "driverLink": "Driver link",
        "close": "Close",
        "links": "Links",
        "linksDes": "Click to copy link",
        "status": "Status",
        "rating": "Rating",
        "permissions": "Permissions",
        "permissionsNote": "Assign different levels of authorization to your team. You can choose whether your operators have visibility of open offers or only assignments already accepted.",
        "owner": "Owner",
        "ownerNote": "Full Access",
        "manageUsers": "Manage Users",
        "manageUsersNote": "Add/remove new operators",
        "manageDrivers": "Manage Drivers",
        "manageDriversNote": "Add/remove drivers",
        "manageVehicles": "Manage Vehicles",
        "manageVehiclesNote": "Add/remove vehicles",
        "mapView": "Live Map",
        "mapViewNote": "Track drivers and active trips live on the map.",
        "calendarView": "Calendar View",
        "calendarViewNote": "View calendar of all assigned trips.",
        "bookingView": "Booking View",
        "bookingViewNote": "View and manage current bookings, assign drivers, and allocate vehicles.",
        "offersView": "Offers View",
        "offersViewNote": "Accept new job offers.",
        "pricing": "Pricing",
        "pricingNote": "View trip prices",
        "userDetail": "User Detail",
        "addOperator": "Add Operator",
        "updateOperator": "Update Operator",
        "supplier": "Supplier",
        "zone": "Zone",
        "emails": "Emails",
        "email": "Email",
        "phoneNumber": "Phone Number",
        "Deactivate": "Deactivate",
        "Activate": "Activate",
        "ALERT_SELECT_DEACTIVATE": "Please select supplier to deactivate",
        "ALERT_SELECT_ACTIVATE": "Please select supplier to activate",
        "CONFIRM_DEACTIVATE_MULTI": "Do you want to deactivate these suppliers?",
        "CONFIRM_ACTIVATE_MULTI": "Do you want to activate these suppliers?",
        "Update_supplier_user_status_success": "Supplier status has been updated successfully",
        "Update_supplier_user_status_fail": "The errors occurred while updating supplier status",
        "agent": "Agent",
        "Supplier": "Supplier",
        "addSupplier": "Add Supplier",
        "detailSupplier": "Supplier Detail",
        "addUser": "Add User",
        "Add": "Add",
        "ContactInfo": "Contact Info",
        "policy": "Policy",
        "users": "Users",
        "setting": "Setting",
        "zones": "Zones",
        "actions": "Actions",
        "companyName": "Company Name",
        "corporationType": "Corporation Type",
        "country": "Country",
        "ADMIN_INFORMATION": "Admin Information",
        "firstName": "First Name",
        "lastName": "Last Name",
        "commissionValue": "Fleet commission",
        "commissionValueDes": "Set commissions the fleet earns for every complete order.",
        "paymentMethodsApplyDes": "Job specific payment methods that suppliers can process:",
        "bookingSettings": "Booking Settings",
        "bookingSettingsDes": "Configure job visibility, release times, and delays for efficient supplier operations.",
        "advanceBookingVisibilityTooltip": "Specify how many days in advance suppliers can see the orders.",
        "delayBroadcastToolTip": "Set a delay time from the release time if set or the start of the day (00:00). This delay controls when the jobs become visible to the suppliers after the initial release time.",
        "releaseTimeToolTip": "Set the time of day when jobs are released to suppliers, starting from the first day they can see bookings. Ensure this time allows suppliers enough preparation time.",
        "otherSettings": "Other Settings",
        "hideFare": "Driver - Always hide fare",
        "hideFareDes": "Hide the fare for all drivers of this supplier.",
        "supplierJobRequired": "Supplier Job Acceptance Required",
        "supplierJobRequiredDes": "Suppliers must acknowledge and accept jobs before they can assign a driver/vehicle or allow the job to be viewed by drivers in the marketplace.",
        "supplierApproval": "Suppliers Driver/Vehicle Approval",
        "supplierApprovalDes": "Allow supplier operators to activate drivers and vehicles within their fleet.",
        "emailsApply": "Email Notifications",
        "emailsApplyDes": "Emails to send new jobs, booking updates.",
        "address": "Address",
        "zipCode": "Zip/Postal Code",
        "city": "City",
        "state": "State/Province",
        "tax": "Tax Identification Number",
        "businessRegistration": "Business Registration Number",
        "vatNumber": "VAT ID/Number",
        "mobile": "Mobile",
        "editOperator": "Edit Operator",
        "searchEmail": "Search Email",
        "acceptOfferSuccess": "Accept offer successfully",
        "acceptOfferFail": "Booking not found or another supplier has accepted the booking",
        "driverPayout": "Driver payout",
        "payout": "Payout",
        "totalPayout": "Total payout",
        "totalPayoutDes": "The total payout includes the base payout amount plus any additional fees you have chosen to allocate to your providers.",
        "supplierPayout": "Supplier payout",
        "driverTip": "Driver tip",
        "cancel": "Cancel",
        "save": "Save",
        "activeSupplier": "Active supplier",
        "userName": "Username",
        "bookingCompletedView": "Completed Bookings",
        "bookingCompletedViewNote": "View the history of all completed bookings.",
        "driverEarning": "Driver earning"
    },
    "noResultFound": "No results found"
};
